<form [formGroup]="twoFactorData" (ngSubmit)="onSubmit()">
    <div class="modal-header text-white">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close text-light" (click)="onCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Step 3 -->
        <div class="d-flex py-2" *ngIf="Action !== 'disable'">
            <div>
                <h5 class="mb-1">Enter your Authentication Code</h5>
                <small class="text-muted">Enter the 6 digit PIN generated by the application.</small>
                <input
                    formControlName="pin"
                    type="text"
                    class="form-control w-25 mt-2"
                    id="twoFactorPin"
                    aria-describedby="twoFactorPinHelp"
                    placeholder="PIN"
                    maxlength="6"
                    minlength="6"
                    required
                    autofocus
                    ngbAutofocus
                />
            </div>
        </div>

        <!-- Turn off 2FA-->
        <div class="d-flex py-2" *ngIf="Action === 'disable'">
            <!-- <div class="mr-3">
        <div class="step-circle">3</div>
      </div> -->
            <div>
                <small class="text-muted">{{ modalInstructions }}</small>
                <input
                    formControlName="pin"
                    type="text"
                    class="form-control w-25 mt-2"
                    id="twoFactorPin"
                    aria-describedby="twoFactorPinHelp"
                    placeholder="PIN"
                    maxlength="6"
                    minlength="6"
                    required
                    autofocus
                    ngbAutofocus
                />
            </div>
        </div>

        <ngb-alert *ngIf="errorMessage.length" [dismissible]="false" [type]="'danger'" class="mb-0">
            {{ errorMessage }}
        </ngb-alert>
    </div>
    <div class="modal-footer">
        <button type="button" id="closeBtn" class="btn btn-light" (click)="onCancel()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="verifyingPin">
            {{ verifyingPin ? actionBtnInProgress : actionBtn }}
            <span class="ml-2" *ngIf="verifyingPin"><i class="fas fa-circle-notch fa-spin-fast"></i></span>
        </button>
    </div>
</form>
