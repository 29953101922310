import { Component, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MessagingService } from "../../services/messaging.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
@Component({
    selector: "app-bad-word-add-modal",
    templateUrl: "./bad-word-add-modal.component.html",
    styleUrls: ["./bad-word-add-modal.component.css"],
})
export class BadWordAddModalComponent {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public badword = "";
    public action = "add";

    constructor(public activeModal: NgbActiveModal, private messagingService: MessagingService, private snackbar: SnackbarService) {}
    public onCancel() {
        this.activeModal.close();
    }
    public onSave() {
        if (this.badword.trim() !== "") {
            return this.messagingService.addBadWord(this.badword).subscribe(
                (data) => {
                    this.Response.emit({ success: true, message: "Bad word added successfully.", action: "add" });
                    this.activeModal.close();
                },
                (err) => {
                    this.Response.emit({ success: false, message: err, action: "add" });
                }
            );
        } else {
            this.snackbar.show("Bad word is required.", "danger");
        }
    }
}
