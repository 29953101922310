import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../services/notification.service";
import { Helper } from "../../providers/helper";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EmployersService } from "../../services/employer.service";
import { UntypedFormBuilder } from "@angular/forms";
import { CareTeamService } from "../../services/careteam.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-push",
    templateUrl: "./push.component.html",
    styleUrls: ["./push.component.scss"],
})
export class PushComponent implements OnInit {
    constructor(
        private notificationService: NotificationService,
        private helper: Helper,
        private snackbar: SnackbarService,
        private modalService: NgbModal,
        private employersService: EmployersService,
        private fb: UntypedFormBuilder,
        private careTeamService: CareTeamService,
        private sessionService: SessionService
    ) {}

    loadingPage: boolean = true;
    successMessage: string = "";
    errorMessage: string = "";
    showSuccessAlert: boolean = false;
    showErrorAlert: boolean = false;
    userList: any = [];
    reciever: any = { email: "all", id: -1 };
    showReciever: string = "";
    message: string = "";
    employers: any;

    selectedUser = -1;
    pushMessageForm: any;
    isAdmin = false;
    selectedCareTeam = "";
    careteams = [];

    ngOnInit() {
        this.isAdmin = this.sessionService.isAdmin();
        let careTeam = this.sessionService.activeCareTeam;
        if (careTeam !== null) {
            this.selectedCareTeam = careTeam.careteamid;
        }

        if (this.isAdmin) {
            this.getCareTeams();
        }
        this.loadingPage = true;

        this.loadData();
        this.getEmployers();
        this.resetFormData();
    }

    public resetFormData() {
        this.pushMessageForm = this.fb.group({
            careteam: [""],
            users: ["all"],
            employer: [""],
            disease: [""],
            minage: [""],
            maxage: [""],
            gender: ["both"],
            message: [""],
        });
    }

    public loadData() {
        this.notificationService.getPushUsers().subscribe(
            (data) => {
                this.userList = data;
                this.sortUserData().then(() => {
                    this.loadingPage = false;
                });
            },
            (error) => {
                this.snackbar.show("Error loading data", "danger");
            }
        );
    }

    public sortUserData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userList = this.userList.filter((user) => {
                return user.email.substring(0, 4) !== "fake";
            });
            this.userList = this.helper.sort(this.userList, "email");
            resolve(null);
        });
    }

    public getEmployers() {
        this.employersService.getEmployers().subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.employers = data[0].attributes;
                }
            },
            (err) => {
                console.log(err[0].detail, 1);
            }
        );
    }

    public sendPush() {
        this.notificationService.sendPush(this.pushMessageForm.value, true).subscribe(
            (data) => {
                const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
                modalRef.componentInstance.Title = "Send Push message";
                modalRef.componentInstance.Message = "Are you sure you want to send push message to " + data.data[0].attributes.count + " user(s)?";
                modalRef.componentInstance.OKButton = "Yes";
                // modalRef.componentInstance.header_class = "bg-primary text-white";
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    if (receivedEntry.proceed) {
                        this.notificationService.sendPush(this.pushMessageForm.value, false).subscribe(
                            (data) => {
                                this.snackbar.show(data.data[0].attributes.message);
                                this.resetFormData();
                            },
                            (err) => {
                                this.snackbar.show(err[0].detail, "danger");
                            }
                        );
                    }
                });
            },
            (error) => {
                this.snackbar.show(error[0].detail, "danger");
            }
        );
    }

    public getCareTeams() {
        this.careTeamService.getCareteams().subscribe(
            (data) => {
                this.careteams = data[0].attributes;

                if (!this.selectedCareTeam) {
                    if (this.careteams.length > 0) {
                        this.selectedCareTeam = this.careteams[0].careteamid;
                    }
                } else {
                    this.pushMessageForm.controls["careteam"].setValue(this.selectedCareTeam);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    public onChangeCareTeam() {
        const active = this.careteams.find((el) => el.careteamid === this.pushMessageForm.get("careteam").value);
        // console.log(active);

        this.selectedCareTeam = active.careteamid;

        // this.sessionService.activeCareTeam = active;
    }
}
