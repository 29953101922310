<div style="margin-top: 50px" class="container">
    <div class="divApp ">
        <div class="table-responsive" id="collapseApp">
            <!-- <div style="position: relative" *ngFor="let a of apps" class="app-card card float-left">
                          <div class="card-body"><img class="mr-2" src="{{ a.imageurl }}" style="max-height:25px" />{{ a.title }}</div>
                          <i
                              style="position: absolute;position: absolute;
                          right: 3px;
                          top: 2px;
                          color: gray;cursor: pointer;"
                              class="fa fa-times"
                              aria-hidden="true"
                          ></i>
                      </div> -->

            <div *ngFor="let a of appList" class="app-card card float-left">
                <div class="card-header">
                    <img class="mr-2" src="{{ a.icon }}" />
                    {{ a.title }}
                </div>
                <div class="card-body">
                    <p>{{ a.description }}</p>
                    <a *ngIf="a.launchtext.length" (click)="showApp(a.launchurl, a.title)" class="btn cursor-pointer">{{ a.launchtext }}</a>
                    <!-- <a href="#" class="dot-btn"><span></span><span></span><span></span></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
