<form [formGroup]="scheduleReportForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <h5 class="modal-title">Schedule Report</h5>
        <div class="form-row">
            <div class="col-4 text-right">
                <label>Report Name:</label>
            </div>
            <div class="col-8">
                <input formControlName="reportName" type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.reportName.errors }">
            </div>
        </div>
        <div class="form-row align-top">
            <div class="col-4 text-right">
                <label> Favorites to report:</label>
            </div>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="surveyData" [checked]="surveyData"
                        [ngModelOptions]="{standalone: true}" id="surveyData">
                    <label class="form-check-label" for="surveyData">
                        Survey Data
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="visitData" [checked]="visitData"
                        [ngModelOptions]="{standalone: true}" id="visitData">
                    <label class="form-check-label" for="visitData">
                        Visit Data
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="billingData"
                        [ngModelOptions]="{standalone: true}" [checked]="billingData" id="billingData">
                    <label class="form-check-label" for="billingData">
                        Billing Data
                    </label>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-4 text-right">
                <label> Send frequency:</label>
            </div>
            <div class="col-8">
                <select formControlName="frequency" class="form-control">
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="annually">Annually</option>
                    <option value="never">Never</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="col-4 text-right">
                <label>Recipients:</label>
            </div>
            <div class="col-8">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="sendToMe" [(ngModel)]="sendToMe"
                        [ngModelOptions]="{standalone: true}" [checked]="sendToMe">
                    <label class="form-check-label" for="sendToMe">
                        Send to me
                    </label>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-4">
            </div>
            <div class="col-8">
                <textarea formControlName="recipients" class="form-control" row="10"
                    [ngClass]="{ 'is-invalid': submitted && f.recipients.errors }"></textarea>
                <p class="text-muted">Separate emails with a comma.</p>
            </div>
        </div>

        <div class="text-center">
            <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
            <button (click)="onSubmit()" type="button" class="btn btn-primary">Save</button>
        </div>
    </div>

</form>