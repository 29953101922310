<!--<nav class="navbar navbar-dark bg-dark">
    <ul class="navbar nav">
        <a class="navbar-brand ml-2" style="color: white">{{ environment.dashboard_name }}</a>
    </ul>
</nav>-->
<div class="logon-back" *ngIf="!loadingPage">
    <div class="container-login">
        <div class="d-flex flex-column justify-content-center h-100">
            <div class="text-center">
                <img src="../../../assets/logo-dashboard-login.svg" />
            </div>
            <div class="card">
                <div class="card-body">
                    <h3>Dashboard Login</h3>
                    <div class="form-group">
                        <label for="email">Username</label>
                        <input type="text" class="form-control" name="username" id="username" placeholder="Username"
                            (keyup.enter)="login()" [(ngModel)]="model.email" />
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <!-- Hidden password field to fool browser credential storage -->
                        <!-- <input type="password" hidden /> -->
                        <input type="password" class="form-control secure-text" name="password" id="password"
                            placeholder="Password" (keyup.enter)="login()" [(ngModel)]="model.password" />
                    </div>

                    <!-- Two-Factor pin field, revealed if pin needed -->
                    <div *ngIf="twoFactorRequired" class="input-group form-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-lock"></i></span>
                        </div>
                        <input type="text" class="form-control" name="twoFactorPin" id="twoFactorPin"
                            placeholder="2FA PIN" (keyup.enter)="login()" [(ngModel)]="model.pin" maxlength="6"
                            minlength="6" />
                    </div>
                    <div class="text-center links">
                        <a class="reset-link" (click)="resetPassword()"> Forgot Password?</a>
                    </div>
                    <div class="text-center">
                        <!-- <div class="text-center" [ngClass]="twoFactorRequired ? 'd-flex' : ''"> -->
                        <!--<div [ngStyle]="{ width: twoFactorRequired ? '80%' : '' }" class="custom-control custom-switch">
                            <input [(ngModel)]="login_with_MedStatix" type="checkbox" class="custom-control-input"
                                id="customSwitch" />
                            <label class="custom-control-label" for="customSwitch">Log In with MedStatix</label>
                        </div>-->
                        <button class="btn" (click)="login()">
                            <span *ngIf="!loading">Continue</span>
                            <div *ngIf="loading" class="loading-spinner-container">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <p>Use of this site constitutes acceptance of our <a href="https://dashboard.medstatix.com/EULA.html"
                    target="_blank">User Agreement</a>,<br> <a href="https://dashboard.medstatix.com/TermsOfUse.html"
                    target="_blank">Terms and Conditions</a> and <a
                    href="https://dashboard.medstatix.com/PrivacyPolicy.html" target="_blank">Privacy Policy</a>.<br>
                Updated October 4, 2019</p>
            <p>&copy; 2024 - MedStatix Inc.</p>
        </div>
    </div>
</div>