<div class="filter-section">
    <div class="filter-section-left">
        <i (click)="clear()" style="cursor: pointer" class="fa fa-redo-alt left-chevron"></i>
        <input class="filter-input" [(ngModel)]="searchByName" [(ngModel)]="tableService.searchTerm" placeholder="Search..." />
    </div>

    <app-loader style="position: absolute; top: 200px; right: 45%; z-index: 100" class="loader" *ngIf="showLoader"></app-loader>
</div>

<div *ngIf="!showLoader" class="container-fluid">
    <div class="table-badfilter">
        <table class="table table-striped datatable">
            <thead>
                <tr>
                    <th *ngFor="let c of displayedColumns">
                        {{ c.displayname }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of resultList$ | async">
                    <td style="width: 250px">
                        <ngb-highlight [result]="r.name" [term]="tableService.searchTerm"></ngb-highlight>
                    </td>
                    <td style="width: 250px">
                        {{ r.description }}
                    </td>
                    <td>
                        {{ r.telerxtype }}
                    </td>
                    <td style="width: 250px">
                        {{ r.adminemail }}
                    </td>
                    <td style="width: 100px">
                        <i title="Edit" (click)="onEdit(r)" class="fa fa-pen graycolor mr-3 cursor-pointer ml-2" aria-hidden="true"> </i>

                        <i title="Delete" (click)="onDelete(r)" class="fa fa-trash graycolor ml-2" aria-hidden="true"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page" [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

            <select class="custom-select pagination" style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important" [(ngModel)]="tableService.pageSize">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>
