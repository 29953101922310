<!-- <h5 class="modal-title" id="exampleModalLongTitle">{{ Title }}</h5> -->
<div class="modal-body">

    <h5 class="modal-title">{{SubmitReview?"Submit Response":"Respond to Facebook Review"}}</h5>
    <form>
        <div class="form-row">
            <label class="col-sm-2 text-right">Date</label>
            <div class="col-sm-10">
                {{Review.createdat| date: "MM/dd/yyyy"}}
            </div>
        </div>
        <div class="form-row">
            <label class="col-sm-2 text-right">Time</label>
            <div class="col-sm-10">
                {{Review.createdat| date: "h:mm a"}}
            </div>
        </div>
        <div class="form-row">
            <label class="col-sm-2 text-right">Provider</label>
            <div class="col-sm-10">
                {{Review.provider_name}}
            </div>
        </div>
        <div class="form-row">
            <label class="col-sm-2 text-right">Practice</label>
            <div class="col-sm-10">
                {{Review.practice_name}}
            </div>
        </div>
        <div class="form-row">
            <label class="col-sm-2 text-right">Review</label>
            <div class="col-sm-10">
                {{Review.review}}
            </div>
        </div>
        <div *ngIf="Reply!==''" class="form-row">
            <label class="col-sm-2 text-right">Response</label>
            <div class="col-sm-10">
                {{Reply}}
            </div>
        </div>
        <div *ngIf="!SubmitReview" class="form-row">
            <label class="col-sm-2 text-right">Response</label>
            <div class="col-sm-10">
                <textarea [ngModelOptions]="{standalone: true}" [(ngModel)]="response" class="form-control"
                    rows="4"></textarea>
                <button (click)="addToResponseList()" [disabled]="response==''"
                    class="btn btn-default mt-2 float-right">Add to Saved Response</button>
            </div>
        </div>

        <div *ngIf="!SubmitReview" class="form-row">
            <label *ngIf="savedResponse.length" class="col-sm-2 text-right">Saved Response</label>
            <div class="col-sm-10">
                <table class="table-striped datatable">
                    <tbody>
                        <tr *ngFor="let r of savedResponse">
                            <td style="width:82%">
                                {{r}}
                            </td>
                            <td class="text-right">
                                <button (click)="addToResponse(r)" class="btn">
                                    <i class="fa fa-arrow-up"></i>
                                </button>
                                <button (click)="delete(r)" class="btn">
                                    <img src="assets/msx/delete.svg">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
    <div class="text-center">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">{{SubmitReview?"Go Back": CancelButton
            }}</button>
        <button [disabled]="response==''" *ngIf="!SubmitReview" type="button" class="btn btn-primary"
            (click)="onOK()">{{ OKButton }}</button>
        <button *ngIf="SubmitReview" type="button" class="btn btn-primary" (click)="onSubmit()">{{"Submit" }}</button>
    </div>
</div>