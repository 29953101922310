import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommunicationService } from "../../services/communications.service";
import { ChatModalComponent } from "../../modals/chat-modal/chat-modal.component";
import { Helper } from "../../providers/helper";
import { MessagingService } from "../../services/messaging.service";
import { SessionService } from "../../services/session.service";
import { take } from "rxjs/operators";
@Component({
    selector: "app-patient-chat-window",
    templateUrl: "./patient-chat-window.component.html",
    styleUrls: ["./patient-chat-window.component.scss"],
})
export class PatientChatWindowComponent implements OnInit {
    constructor(
        public sessionService: SessionService,
        private helper: Helper,
        private modalService: NgbModal,
        private messagingService: MessagingService,
        private communication: CommunicationService
    ) { }
    @Input() patient: any;
    @Input() patients: any = [];
    @Input() patientDashboardSetting: any;
    @Output() readonly myEvent = new EventEmitter();
    currentPatient: any = {};
    public show = true;
    patientId: string = "";
    public non_priorities_tags = [];
    public channel = "";
    ngOnInit() {
        this.currentPatient = this.patient;
        this.patientId = this.currentPatient.patientid;
        document.addEventListener("RESET_FILTER", (event: any) => {
            this.channel = "";
        });
    }

    public isOpenedTab(name) {
        this.helper.isOpenedTab(name);
    }
    public openChatWindow() {
        this.show = false;
        let hideProviderOnly = new CustomEvent("HIDE_PROVIDER_ONLY", { detail: this.currentPatient });
        document.dispatchEvent(hideProviderOnly);
        const modalRef = this.modalService.open(ChatModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.showForPatientDashBoard = false;
        modalRef.componentInstance.markAsUnreadButton = true;
        modalRef.componentInstance.currentPatient = this.currentPatient;
        modalRef.componentInstance.patients = this.patients;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.show = true;
            }
        });
    }
    public getTagList(tags) {
        this.non_priorities_tags = tags;
    }
    public scrollDown() {
        let filterMessageByTagName = new CustomEvent("FILTER_MESSAGE_BY_TAG", { detail: this.channel });
        document.dispatchEvent(filterMessageByTagName);
    }

    public markAsUnread() {
        this.patientId = this.currentPatient.patientid;
        this.currentPatient.provider_unread_messages_count += 1;
        this.communication.markMessagesAsUnRead(this.currentPatient.careteamid, this.currentPatient.patientid).pipe(take(1)).subscribe();
        let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_UNREAD_MESSAGE_COUNT", { detail: { reload: true } });
        document.dispatchEvent(updateUnreadMessagesCountEvent);
    }
}
