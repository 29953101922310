import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { DatePipe } from "@angular/common";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { WaitingRoomMoveModalComponent } from "../../modals/waiting-room-move-modal/waiting-room-move-modal.component";
import { ViewOptionsModalComponent } from "../../modals/view-options-modal/view-options-modal.component";
import { WaitingRoomReferModalComponent } from "../../modals/waiting-room-refer-modal/waiting-room-refer-modal.component";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { PatientDashboardModalComponent } from "../../modals/patient-dashboard-modal/patient-dashboard-modal.component";

import { ChatModalComponent } from "../../modals/chat-modal/chat-modal.component";
import { UntypedFormControl } from "@angular/forms";
import { SessionService } from "../../services/session.service";
import { ViewNotesModalComponent } from "../../modals/view-notes-modal/view-notes-modal.component";

import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";

export interface SearchElements {
    username: string;
    status: string;
    chiefcomplaint: string;
    refferedtelerxname: string;
}

@Component({
    selector: "waiting-room",
    templateUrl: "./waiting-room.component.html",
    styleUrls: ["./waiting-room.component.css"],
})
export class WaitingRoomComponent implements OnInit {
    public patientData: any = { patient: {} };
    public patientReferData: any = { patient: {} };
    public viewOptionsData: any = {};
    public notesData: any = {};
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    constructor(
        public waitingRoomService: WaitingRoomService,
        private snackbar: SnackbarService,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private router: Router,
        private modalService: NgbModal,
        private datepipe: DatePipe,
        public tableService: TableService
    ) {
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
        this.tableService.searchOnColumn = "";
        this.tableService.pageSize = 10;
        this.tableService.searchTerm = "";
        route.params.subscribe((val) => {
            this.setUpUI();
        });
    }
    activeCareTeam = this.sessionService.activeCareTeam;
    roomName = "";
    wrid = "";
    filterText = "";
    patients: any;
    viewOptions: any;
    //patients: any = [];
    waitingRooms: any = [];
    viewAllDropdown = [];
    isViewAll: boolean = false;
    hasLink: boolean = false;
    public showLoader: boolean = false;
    displayedColumns: string[];
    statusFilter = new UntypedFormControl("");
    nameFilter = new UntypedFormControl();
    globalFilter = "";
    fullData = [];
    filteredValues = {
        createdat: "",
        username: "",
        status: "",
        chiefcomplaint: "",
        refferedtelerxname: "",
    };

    ngOnInit() {
        this.setUpUI();
    }

    public setUpUI() {
        this.viewOptions = {
            pageid: "",
            columns: [
                {
                    name: "createdat",
                    display: "Date",
                    show: false,
                    order: 0,
                },
                {
                    name: "username",
                    display: "Name",
                    show: false,
                    order: 1,
                },
                {
                    name: "status",
                    display: "Status",
                    show: false,
                    order: 2,
                },
                {
                    name: "chiefcomplaint",
                    display: "Reason For Visit",
                    show: false,
                    order: 3,
                },
                {
                    name: "age",
                    display: "Age",
                    show: false,
                    order: 4,
                },
                {
                    name: "temp",
                    display: "Temp",
                    show: false,
                    order: 5,
                },
                {
                    name: "daterange",
                    display: "Date Range",
                    show: false,
                    order: 6,
                },
                {
                    name: "timing",
                    display: "Timing",
                    show: false,
                    order: 7,
                },
                {
                    name: "refills",
                    display: "Rx Request",
                    show: false,
                    order: 8,
                },
                {
                    name: "comments",
                    display: "Comments",
                    show: false,
                    order: 9,
                },
                {
                    name: "refferedtelerxname",
                    display: "Referred To",
                    show: false,
                    order: 10,
                },
                {
                    name: "actions",
                    display: "Actions",
                    show: false,
                    order: 11,
                },
            ],
        };
        this.displayedColumns = ["createdat", "username", "status", "chiefcomplaint", "age", "temp", "daterange", "timing", "refills", "comments", "refferedtelerxname", "actions"];
        this.showLoader = true;
        this.wrid = this.route.snapshot.paramMap.get("id");
        if (this.wrid === "00000000-0000-0000-0000-000000000000") {
            this.isViewAll = true;
        } else {
            this.isViewAll = false;
        }

        this.getWaitingRoomPatients();
        this.getWaitingRooms();
    }
    public setUpViewAll() {
        this.viewAllDropdown = this.waitingRooms.map((a) => a.roomname.toLowerCase());
        this.viewAllDropdown.push("referred");
        this.viewAllDropdown.push("resolved");
        this.viewAllDropdown = this.viewAllDropdown.sort(function (a, b) {
            var nameA = a.toLowerCase(),
                nameB = b.toLowerCase();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
        });
    }
    public onRefresh() {
        // this.tableService.searchTerm = "";
        // this.tableService.searchOnColumn = "";
        // this.statusFilter = new FormControl("");
        // this.patients.filter = "";
        //  this.filterText = "";
        this.tableService.DATA = [];
        this.getWaitingRoomPatients();
    }
    defaultColumns() {
        if (this.roomName.toLowerCase() === "appointment requests") {
            this.displayedColumns = ["createdat", "username", "chiefcomplaint", "age", "temp", "actions"];
        } else if (this.roomName.toLowerCase() === "annual wellness") {
            this.displayedColumns = ["createdat", "username", "daterange", "timing", "age", "actions"];
        } else if (this.roomName.toLowerCase() === "rx refills") {
            this.displayedColumns = ["createdat", "username", "refills", "comments", "age", "actions"];
        } else if (this.roomName.toLowerCase() === "view all") {
            this.displayedColumns = ["createdat", "username", "status", "chiefcomplaint", "refferedtelerxname", "actions"];
        } else {
            this.displayedColumns = ["createdat", "username", "chiefcomplaint", "age", "temp", "actions"];
        }
        this.defaultViewOptions();
    }
    defaultViewOptions() {
        this.viewOptions.columns.forEach((c, i) => {
            if (this.displayedColumns.includes(c.name)) {
                c.show = true;
            }
        });
    }
    displayName(name) {
        return this.viewOptions.columns.find((o) => o.name === name).display;
    }

    getExtData(other, duration) {
        let data = "";
        if (other && other !== "") {
            data = other;
        }
        if (duration && duration !== "") {
            if (data !== "") {
                data += ", " + duration;
            } else {
                data = duration;
            }
        }
        if (data !== "") {
            return " - (" + data + ")";
        } else {
            return "";
        }
    }

    getWaitingRoomPatients() {
        this.showLoader = true;
        // this.tableService.searchTerm = "";
        return this.waitingRoomService.getWaitingRoomsPatients(this.wrid, this.activeCareTeam["careteamid"]).subscribe(
            (data) => {
                if (data[0].attributes) {
                    if (!this.isViewAll) {
                        this.roomName = data[0].attributes.waitingroom.roomname;
                    } else {
                        this.roomName = "VIEW ALL";
                    }
                    let displayedCols = [];
                    let localStorageExists = false;
                    let existingColumnsOptions = JSON.parse(localStorage.getItem("waiting-room-columns"));
                    if (existingColumnsOptions) {
                        let pageColumns = existingColumnsOptions.filter((x) => x.pageid === this.wrid);
                        if (pageColumns && pageColumns.length > 0) {
                            localStorageExists = true;
                            this.viewOptions = pageColumns[0];
                            this.viewOptions.columns.forEach((c, i) => {
                                if (c.show) {
                                    displayedCols.push(c.name);
                                }
                            });
                        }
                    }
                    if (displayedCols.length === 0 && !localStorageExists) {
                        this.defaultColumns();
                    } else {
                        this.displayedColumns = displayedCols;
                    }
                    data[0].attributes.list.forEach((d, i) => {
                        if (d["dob"]) {
                            var timeDiff = Math.abs(Date.now() - new Date(d["dob"]).getTime());
                            d["age"] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
                        } else {
                            d["age"] = 0;
                        }
                        //Refills
                        if (this.displayedColumns.includes("refills")) {
                            if (d["requestdata"] && d["requestdata"].medicines) {
                                d["refills"] = Array.prototype.map
                                    .call(d["requestdata"].medicines, function (item) {
                                        return item.name;
                                    })
                                    .join(", ");
                            } else {
                                d["refills"] = "";
                            }
                        }
                        //Comments
                        if (this.displayedColumns.includes("comments")) {
                            if (d["requestdata"] && d["requestdata"].comments) {
                                d["comments"] = d["requestdata"].comments;
                            } else {
                                d["comments"] = "";
                            }
                        }
                        //ChiefComplaint
                        if (this.displayedColumns.includes("chiefcomplaint")) {
                            d["chiefcomplaint"] = "";
                            if (d["requestdata"] && d["requestdata"].symptoms) {
                                let icdCodes = [];
                                let symptoms = d["requestdata"]?.symptoms;
                                Object.keys(symptoms).forEach((key) => {
                                    if (symptoms[key].reasons && symptoms[key].reasons.length > 0) {
                                        let icdcode = symptoms[key].reasons
                                            .map((elem) => {
                                                let durationValue = symptoms[key]["duration"]
                                                    ? symptoms[key]["duration"].since +
                                                    " " +
                                                    (parseInt(symptoms[key]["duration"].since) > 1 ? symptoms[key]["duration"].sinceUnit : symptoms[key]["duration"].sinceUnit.slice(0, -1))
                                                    : "";
                                                let value = elem.icdcode
                                                    ? elem.icdcode + " - " + (key ? key + " - " : "") + elem.generalsymptoms + this.getExtData(symptoms[key]["other"], durationValue)
                                                    : "user - " + (key ? key + " - " : "") + elem.generalsymptoms + this.getExtData(symptoms[key]["other"], durationValue);
                                                return value;
                                            })
                                            .join("<br />");
                                        icdCodes.push(icdcode);
                                    } else {
                                        let durationValue = symptoms[key]["duration"]
                                            ? symptoms[key]["duration"].since +
                                            " " +
                                            (parseInt(symptoms[key]["duration"].since) > 1 ? symptoms[key]["duration"].sinceUnit : symptoms[key]["duration"].sinceUnit.slice(0, -1))
                                            : "";
                                        let value = key + this.getExtData(symptoms[key]["other"], durationValue);
                                        icdCodes.push(value);
                                    }
                                });
                                if (d["requestdata"].other) {
                                    icdCodes.push("Other: " + d["requestdata"].other);
                                }

                                d["chiefcomplaint"] = icdCodes
                                    .map(function (elem) {
                                        return elem;
                                    })
                                    .join("<br />");
                            }
                            if (d["requestdata"] && d["requestdata"].general && d["requestdata"].general.reasons) {
                                d["chiefcomplaint"] = Array.prototype.map
                                    .call(d["requestdata"].general.reasons, function (item) {
                                        return item.name;
                                    })
                                    .join(", ");
                                if (d["requestdata"].general.other && d["requestdata"].general.other !== "") {
                                    if (d["chiefcomplaint"]) {
                                        d["chiefcomplaint"] = d["chiefcomplaint"] + ", " + d["requestdata"].general.other;
                                    } else {
                                        d["chiefcomplaint"] = d["requestdata"].general.other;
                                    }
                                }
                            }
                        }
                        if (this.displayedColumns.includes("temp")) {
                            if (d["requestdata"] && d["requestdata"].general && d["requestdata"].general.reasons) {
                                let feverTemp = d["requestdata"].general.reasons.filter((x) => x.name.toLowerCase() === "fever" && x.value !== true);
                                if (feverTemp && feverTemp.length > 0) {
                                    d["temp"] = feverTemp[0].value;
                                }
                            } else {
                                d["temp"] = "";
                            }
                        }
                        //Date Range
                        if (this.displayedColumns.includes("daterange")) {
                            if (d["requestdata"] && d["requestdata"].dateRange && d["requestdata"].dateRange.from && d["requestdata"].dateRange.to) {
                                let dateSplitFrom = d["requestdata"].dateRange.from.split("-");
                                let dateSplitTo = d["requestdata"].dateRange.to.split("-");
                                let from = dateSplitFrom[1] + "/" + dateSplitFrom[2] + "/" + dateSplitFrom[0].substr(2, 2); //this.datepipe.transform(new Date(d["requestdata"].dateRange.from), "MM/dd/yy");
                                let to = dateSplitTo[1] + "/" + dateSplitTo[2] + "/" + dateSplitTo[0].substr(2, 2); //this.datepipe.transform(new Date(d["requestdata"].dateRange.to), "MM/dd/yy");
                                d["daterange"] = from + " - " + to;
                            } else {
                                d["daterange"] = "";
                            }
                        }
                        //Timing
                        if (this.displayedColumns.includes("timing")) {
                            if (d["requestdata"] && d["requestdata"].dateRange && d["requestdata"].timeOfDay) {
                                d["timing"] = d["requestdata"].timeOfDay;
                            } else {
                                d["timing"] = "";
                                if (d.requestdata.otherDetails) {
                                    let timing = "";
                                    d.requestdata.otherDetails.dayValues.forEach((element) => {
                                        timing += element.day + "[" + element.time + "]" + "<br>";
                                    });
                                    d["timing"] = timing;
                                }
                            }
                        }
                        //Status
                        if (d["status"] && d["status"] === "active") {
                            d["status"] = d["towaitingroom"];
                        } else if (d["status"] && d["status"].toLowerCase() === "closed") {
                            d["towrid"] = null;
                            d["status"] = "resolved";
                        }
                        if (d["status"].includes("referred")) {
                            d["towrid"] = null;
                        }
                        if (d["requestdata"] && d["requestdata"].notes && d["requestdata"].notes) {
                            d["notes"] = d["requestdata"].notes;
                        }
                        if (d["requestdata"] && d["requestdata"].referralNotes && d["requestdata"].referralNotes.notes) {
                            d["referralnotes"] = d["requestdata"].referralNotes.notes;
                        }
                        d["status"] = d["status"].replace("cancelled", "declined");
                    });
                    this.fullData = data[0].attributes.list;
                    this.patients = data[0].attributes.list;
                    this.tableService.DATA = data[0].attributes.list;
                    this.tableService.pageSize = 10;
                    this.sorting("createdat", "desc");
                    this.statusFilter.valueChanges.subscribe((positionFilterValue) => {
                        this.tableService.searchOnColumn = positionFilterValue;
                    });
                    // this.patients.filterPredicate = this.customFilterPredicate();
                    // this.sortData(defaultSort);
                    this.showLoader = false;
                }
            },
            (err) => {
                this.showLoader = false;
                this.sorting("createdat", "desc");
            }
        );
    }

    getWaitingRooms() {
        return this.waitingRoomService.getWaitingRooms(this.activeCareTeam["careteamid"]).subscribe(
            (data) => {
                if (data[0].attributes) {
                    this.waitingRooms = data[0].attributes.list;
                    if (this.isViewAll) {
                        this.setUpViewAll();
                    }
                }
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public onMovePatient(patient) {
        let filteredWaitingRooms = [];
        filteredWaitingRooms = this.waitingRooms.filter((x) => x.wrid !== patient.towrid);
        this.patientData = {
            action: "move",
            page: "WaitingRoom",
            wruid: patient.wruid,
            fromwrid: patient.towrid,
            waitingRooms: filteredWaitingRooms,
            patientId: patient.userid,
        };
        const modalRef = this.modalService.open(WaitingRoomMoveModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.PatientData = this.patientData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.moveModalResponse(receivedEntry);
        });
    }
    public moveModalResponse(event) {
        if (event.success && event.action === "move") {
            this.snackbar.show(event.message);
            this.getWaitingRoomPatients();
        } else if (event.success && event.action === "resolved") {
            this.snackbar.show(event.message);
            this.getWaitingRoomPatients();
        } else if (event.success && event.action === "refer") {
            let membershipid = this.sessionService.getCurrentUserMembershipId();
            this.patientReferData = {
                action: "refer",
                page: "PatientDashboard",
                wruid: event.wruid,
                wrid: this.wrid,
                membershipid: membershipid,
                patientId: event.patientId,
            };
            const modalRef = this.modalService.open(WaitingRoomReferModalComponent, { windowClass: "modal-small-center", backdrop: "static" });
            modalRef.componentInstance.PatientReferData = this.patientReferData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.referModalResponse(receivedEntry);
            });
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    public referModalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
            this.getWaitingRoomPatients();
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    public modalResponse(event) {
        if (event.success) {
            if (event.message && event.message !== "") {
                this.snackbar.show(event.message);
            }
            this.getWaitingRoomPatients();
        } else {
            this.snackbar.show(event.message, "danger");
        }
        if (event.reset) {
            this.getWaitingRoomPatients();
        }
    }
    public onViewOptions() {
        this.viewOptionsData = {
            action: "viewoptions",
            page: "WaitingRoom",
            pageid: this.wrid,
            viewOptions: this.viewOptions,
        };
        const modalRef = this.modalService.open(ViewOptionsModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.ViewOptionsData = this.viewOptionsData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.ViewOptionsModalResponse(receivedEntry);
        });
    }
    public onViewPatient(p) {
        p.patientid = p.userid;
        const modalRef = this.modalService.open(ChatModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.currentPatient = p;
        modalRef.componentInstance.patients = [];
        modalRef.componentInstance.Response.subscribe((receivedEntry) => { });
    }
    public ViewOptionsModalResponse(event) {
        if (event.success) {
            this.getWaitingRoomPatients();
        } else {
            this.snackbar.show(event.message, "danger");
        }
        if (event.reset) {
            this.getWaitingRoomPatients();
        }
    }
    public onCancel(patient) {
        let warningMessage = "Are you sure you want to cancel this member request?";
        let successMessage = "Request cancelled for member.";

        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = warningMessage;
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                let requestData = {
                    cwruid: patient.wruid,
                    action: "cancelled",
                };
                this.waitingRoomService.referAction(requestData).subscribe(
                    (data) => {
                        this.snackbar.show(successMessage);
                        this.getWaitingRoomPatients();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public onNotes(patient) {
        this.notesData = {
            action: "notes",
            patient: patient,
            isReferral: this.isViewAll ? (patient.status.includes("referred") ? true : false) : false,
        };
        const modalRef = this.modalService.open(ViewNotesModalComponent, { size: "lg", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.NotesData = this.notesData;
    }

    public onPatientDashboard(user) {
        const modalRef = this.modalService.open(PatientDashboardModalComponent, { backdrop: "static", windowClass: "custom-class" });
        modalRef.componentInstance.patientId = user.userid;
        modalRef.componentInstance.patient = user;
        modalRef.componentInstance.wrId = user.towrid;
        modalRef.componentInstance.wruId = user.wruid;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }
    onSort({ column, direction }: any, serverSideSorting = true) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction, true);
    }
    sorting(column, direction, serverSideSorting = true) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.searchOnColumn = "";
        this.tableService.searchOn = "username";
        this.tableService.page = 1;
    }

    public getReason(p, c) {
        if (c === "chiefcomplaint") {
            if (p.requestdata !== undefined && p.requestdata.otherDetails !== undefined) {
                return p.requestdata.otherDetails.apmt_reason
            }

        }
    }
}
