import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'lodash';
@Component({
    selector: "metric-modal",
    templateUrl: "./metric-modal.component.html",
    styleUrls: ["./metric-modal.component.scss"],
})
export class MetricModalComponent implements OnInit {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    constructor(private activeModal: NgbActiveModal, private msxReportService: MSXReportService, private snackbar: SnackbarService) { }
    selected = "";
    metricname = ""
    visitMetrics = [];
    @Input() metric = "";
    @Input() surveys = {
        metricGroups: [],
        metrics: []
    };
    @Input() selectedSurvey = "";
    metricList: any
    @Input() metricType = 'survey'
    ngOnInit() {
        if (this.metricType == "survey") {
            this.metricList = this.surveys.metrics;
            this.getSurvey();
        }
        else {
            this.getVisitMetrics();
        }

    }
    public selectedMetric(m) {
        if (this.metricType == "survey") {
            this.selected = m.metricid;
            this.metricname = m.metricname;
            this.metric = m.metricid;
        }
        else {
            this.selected = m.value;
            this.metricname = m.label;
            this.metric = m.value;
        }
    }
    public onSubmit() {



        if (this.metric === "") {
            this.snackbar.show("Please select a metric", "danger")
        }
        else {
            if (this.metricname == "") {
                this.visitMetrics.forEach(element => {
                    if (element.value == this.metric) {
                        this.metricname = element.label;
                    }
                });
            }
            this.surveys.metrics = this.metricList;
            this.Response.emit({ proceed: this.selected, name: this.metricname, selectedSurvey: this.selectedSurvey, metric: this.metric });
            this.activeModal.close();
        }
    }

    public onCancel() {
        this.surveys.metrics = this.metricList;
        this.activeModal.close();
    }

    public getSurvey(onChange = false) {
        this.surveys.metrics = _.filter(this.metricList, {
            surveyid: this.selectedSurvey
        })
        if (onChange) {
            this.metric = this.surveys.metrics[0].list[0].metrics[0].metricid;
            this.selected = this.metric;
            this.metricname = this.surveys.metrics[0].list[0].metrics[0].metricname;;

        }
    }

    public getVisitMetrics() {
        this.msxReportService.getVisitMetrics().subscribe(
            (data) => {
                this.visitMetrics = data[0].attributes.metrics;
            },
            (err) => {
                this.snackbar.show(err, "danger");
            }
        );
    }
}
