import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase/messaging";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { take } from "rxjs/operators";
import { CommunicationService } from "../../services/communications.service";
import { AuthService } from "../../services/auth.service";
import { CareTeamService } from "../../services/careteam.service";
import { AnalyticsService } from "../../services/analytics.service";
import { SessionService } from "../../services/session.service";

// Activity log.
function log(message, div) {
    const logDiv = document.getElementById(div);
    logDiv.innerHTML += "<p class='alternative_cls'>&gt;&nbsp;" + message + "</p>";
    logDiv.scrollTop = logDiv.scrollHeight;
}
@Component({
    selector: "app-diagnostics",
    templateUrl: "./diagnostics.component.html",
    styleUrls: ["./diagnostics.component.scss"],
})
export class DiagnosticsComponent implements OnInit {
    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private communication: CommunicationService,
        private auth: AuthService,
        private careTeamService: CareTeamService,
        private analyticsService: AnalyticsService,
        private sessionService: SessionService
    ) {}
    public showBrowserBtn = true;
    public showLoginBtn = false;
    public showFCMBtn = false;
    public showMsgBtn = false;
    public loading = false;
    public _activeCareTeam = this.sessionService.activeCareTeam;

    ngOnInit() {
        if (localStorage.getItem("token")) {
            this.auth.get_current_user_by_token(localStorage.getItem("token"));
        }
    }
    public checkBrowserPermission() {
        this.initializeMessaging();
    }
    public textFCM() {
        this.requestPermission(this._activeCareTeam, true);
    }
    private initializeMessaging() {
        log("Check if messaging is supported and notifications are allowed.", "browserPermissionlog");
        log("User Agent", "browserPermissionlog");
        this.recordEvent(window.navigator.userAgent, "browserPermissionlog");
        firebase.isSupported().then((supported) => {
            if (!supported) {
                this.recordEvent("Cloud messaging is not supported in this browser.", "browserPermissionlog");
            } else {
                this.recordEvent("Cloud messaging is supported in this browser.", "browserPermissionlog");
            }
        });
        log("Check Notification preference.", "browserPermissionlog");
        this.recordEvent(Notification.permission, "browserPermissionlog");
        if (Notification.permission === "denied") {
            this.recordEvent("Real time messaging requires push notifications to work. Please enable notifications.", "browserPermissionlog");
        }
        if (Notification.permission === "default") {
            this.recordEvent("Real time messaging requires push notifications to work. Please enable notifications.", "browserPermissionlog");
        }

        log("Active CareTeam ", "browserPermissionlog");
        if (this._activeCareTeam == null) {
            log("Active CareTeam not found (Please login into the application and assign a care team.)", "browserPermissionlog");
            //  log("Please login into the application.", "browserPermissionlog")
            return;
        }
        this.recordEvent(this._activeCareTeam.careteamname, "browserPermissionlog");
        log("---------------------------------------------------------------------------------------------- ", "browserPermissionlog");
        this.showBrowserBtn = false;
        this.showFCMBtn = true;
    }

    requestPermission(careTeam: any, subscribe: boolean = false) {
        this.loading = true;
        // if (this.isPushSubscribed) {
        //   // console.error('Push is already subscribed');
        //   return false;
        // }
        // this.isPushSubscribed = true;
        let topic = careTeam.careteamid;
        this.recordEvent("Request For Permission from angularFireMessaging", "fcmPermissionlog");
        this.angularFireMessaging.requestPermission.pipe(take(1)).subscribe(
            () => {
                this.recordEvent("Notification permission granted.", "fcmPermissionlog");
                this.recordEvent("Try to get token from FCM.", "fcmPermissionlog");
                this.angularFireMessaging.getToken.pipe(take(1)).subscribe((token) => {
                    this.recordEvent("FCM Token created successfully.", "fcmPermissionlog");
                    this.recordEvent("Call Push Subscribe incoming messages API.", "fcmPermissionlog");
                    this.communication
                        .pushSubscribe(token, topic)
                        .pipe(take(1))
                        .subscribe(
                            (data) => {
                                let pushUser = {
                                    token: token,
                                    careteamid: topic,
                                };
                                localStorage.setItem("pushUser", JSON.stringify(pushUser));
                                this.recordEvent("Subscribe to incoming messages successfully.", "fcmPermissionlog");
                                if (subscribe) {
                                    this.recordEvent("Realtime messaging activated", "fcmPermissionlog");
                                    this.showMsgBtn = true;
                                    this.showFCMBtn = false;
                                    log("----------------------------------------------------------------------------------------------", "fcmPermissionlog");
                                    this.loading = false;
                                }
                            },
                            (error) => {
                                this.loading = false;
                                this.recordEvent("ERROR", "fcmPermissionlog");
                                this.recordEvent(JSON.stringify(error), "fcmPermissionlog");
                                this.recordEvent(error[0].detail, "fcmPermissionlog");
                            }
                        );
                });
            },
            (err) => {
                this.loading = false;
                this.recordEvent("Unable to get permission to notify." + err, "fcmPermissionlog");
            }
        );
    }

    public recordEvent(category: string, div) {
        log(category, div);
        let currentUser = this.sessionService.currentUser;
        if (currentUser !== null) {
            let data = [];
            this.analyticsService.recordEvent("diagnostics_event", category, data, currentUser.id).subscribe(
                (data) => {
                    // console.log("saved");
                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }

    public returnDashboard() {
        window.location.href = "/home";
    }
}
