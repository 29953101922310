<div class="modal-body">
    <h5 class="modal-title">Select a group</h5>
    <div class="row">
        <div class="input-group mb-3 col">
            <div class="input-group-prepend">
                <span class="input-group-text">Groups:</span>
            </div>

            <select [(ngModel)]="selectedGroup" (change)="selectedEntity($event)" class="form-control">
                <option value="">Please select</option>
                <option *ngFor="let e of entities" [value]="e.groupid">{{ e.name }}</option>
            </select>
        </div>
    </div>
    <div class="text-center mt-3">
        <!-- <button (click)="onCancel()" type="button" class="btn btn-secondary mr-3">Cancel</button> -->
        <button [disabled]="selectedGroup===''" (click)="onSubmit()" type="button" class="btn btn-primary">Done</button>
    </div>

</div>