<div class="container-fluid">
    <div class="tab">
        <a class="tablinks" [routerLink]="['/patient-experience']">
            <img src="/assets/msx/favorites.svg"> Favorites
        </a>
        <a class="tablinks" [routerLink]="['/survey-data']">
            <img src="/assets/msx/surveydata.png"> Survey Data
        </a>
        <a class="tablinks active" [routerLink]="['/reports']">
            <img src="/assets/msx/reports-white.svg"> Reports
        </a>
        <!-- <a class="tablinks">
            <img src="/assets/msx/admin.svg"> Administration
        </a> -->
    </div>
    <!--<iframe width="100%" height="550" src="https://datastudio.google.com/embed/reporting/1FmSzmVxvVyCig1As8Iuvw6MEY9S4e6jw/page/NwC2" frameborder="0" style="border:0" allowfullscreen></iframe>-->
</div>
<div class="filter-bar">
    <div class="container">
        <form [formGroup]="reportForm" (ngSubmit)="showReport()">
            <div class="report-filters">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label>Report</label>
                            <select formControlName="reportType" class="form-control" (change)="onFormSelect()">
                                <option *ngFor="let rpt of reportTypes" [value]="rpt.type">{{ rpt.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="showControls.roles" class="col-2">
                        <div class="form-group">
                            <label>Roles</label>
                            <select formControlName="role" required class="form-control">
                                <option value="">All</option>
                                <option *ngFor="let r of roles" [value]="r.name">{{ r.displayname }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-2" *ngIf="showControls.careteam && !showProviderList">
                        <div class="form-group">
                            <label>Care Team:</label>
                            <select formControlName="careteamid" required class="form-control">
                                <option value="">{{ optionText }}</option>
                                <option *ngFor="let c of careteam" [value]="c.careteamid">{{ c.careteamname }}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="showProviderList" class="col-2">
                        <div class="form-group">
                            <label>Providers:</label>
                            <select formControlName="providerid" required class="form-control">
                                <option value="00000000-0000-0000-0000-000000000000">All</option>
                                <option *ngFor="let p of providers" [value]="p.userid">{{ p.fullname }}</option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="showControls.employers" class="col-2">
                        <div class="form-group">
                            <label>Employers</label>
                            <select (change)="onChangeOnboardEmployer($event)" formControlName="employer" required
                                class="form-control">
                                <option value="">All</option>
                                <option *ngFor="let e of employers" [value]="e.employername">{{ e.employername }}
                                </option>
                                <option value="other">Other Companies</option>
                                <option *ngIf="!hasNoEmployer" value="No Employer">No Employer</option>
                            </select>
                        </div>
                    </div>

                    <!-- Goals goes here... -->
                    <div *ngIf="showControls.startdate" class="col-2">
                        <div class="form-group date">
                            <label>Start Date </label>

                            <input formControlName="startDate" (keydown)="onKeydown()" class="form-control"
                                placeholder="yyyy-mm-dd" name="startDate" ngbDatepicker #sd="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn calendar" (click)="sd.toggle()" type="button">
                                    <i class="far fa-clock"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showControls.enddate || showControls.reportdate" class="col-2">
                        <div class="form-group date">
                            <label *ngIf="showControls.enddate">End Date</label>
                            <label *ngIf="showControls.reportdate">Report Date</label>
                            <input formControlName="endDate" (keydown)="onKeydown()" class="form-control"
                                placeholder="yyyy-mm-dd" name="endDate" ngbDatepicker #ed="ngbDatepicker" />
                            <div class="input-group-append">
                                <button class="btn calendar" (click)="ed.toggle()" type="button">
                                    <i class="far fa-clock"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showControls.onboarded" class="col-2">
                        <div class="form-group">
                            <label>Onboarded</label>
                            <select (change)="onChangeOnboardEmployer($event)" formControlName="onboarded" required
                                class="form-control">
                                <option value="">All</option>
                                <option *ngFor="let o of onboardTypes" [value]="o.type">{{ o.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-primary cliogoblue float-left" type="submit">Show Report</button>
                        <button *ngIf="showReportSection" class="btn btn-primary cliogoblue float-left ml-2"
                            (click)="downloadCSV()">
                            <i style="float: left" class="fa fa-download mr-2" aria-hidden="true"></i>Download Data
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="showReportSection" class="filter-result">
    <div class="container">
        <div class="report-row">
            <div class="col-6 pl-0">
                <h4>{{ reportType }} Report</h4>
            </div>
            <div class="col-6" style="font-size: 16px">
                <div *ngIf="reportType === 'goals'" class="float-right">
                    <img src="../../assets/icons/icon_calendar.png" /> &nbsp;{{ report_start_date | date: "MM/dd/yyyy"
                    }} - {{ report_end_date | date: "MM/dd/yyyy" }}
                </div>
                <div *ngIf="reportType === 'usage'" class="float-right">
                    <img src="../../assets/icons/icon_calendar.png" /> &nbsp;{{ report_start_date | date: "MM/dd/yyyy"
                    }} - {{ report_end_date | date: "MM/dd/yyyy" }}
                </div>
                <div *ngIf="reportType === 'chatsession'" class="float-right"><img
                        src="../../assets/icons/icon_calendar.png" /> &nbsp; {{ report_end_date | date: "MM/dd/yyyy" }}
                </div>
                <div *ngIf="reportType === 'billing'" class="float-right"><img
                        src="../../assets/icons/icon_calendar.png" /> &nbsp; {{ report_end_date | date: "MM/dd/yyyy" }}
                </div>
            </div>
        </div>
        <div>
            <div class="table-responsive">
                <!-- USAGE REPORT -->
                <div *ngIf="reportType === 'usage'">
                    <table>
                        <th>Care Team Summary: {{ careteam.careteamname }}</th>
                        <tr>
                            <td>Members In Careteam : {{ reportData.total_patient_in_careteam }}</td>
                        </tr>
                        <tr>
                            <td>Sent Messages : {{ reportData.total_sent_messages }}</td>
                        </tr>
                        <tr>
                            <td>Received Messages : {{ reportData.total_received_messages }}</td>
                        </tr>
                        <tr>
                            <td>Unread Messages : {{ reportData.total_unread_messages }}</td>
                        </tr>
                    </table>
                    <div style="height: 30px"></div>
                    <table>
                        <thead>
                            <th>Employer</th>
                            <th>Members</th>
                            <th>Sent Messages</th>
                            <th>Received Messages</th>
                            <th>Unread Messages</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of reportData.list">
                                <td>{{ r?.settingvalue }}</td>
                                <td>{{ r?.total_app_user_count }} of {{ r?.total_patient_in_careteam }}</td>
                                <td>{{ r?.total_sent_messages }}</td>
                                <td>{{ r?.total_received_messages }}</td>
                                <td>{{ r?.total_unread_messages }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- SAVINGS REPORT -->
                <table *ngIf="reportType === 'saving'">
                    <thead>
                        <th>Tag</th>
                        <th>Tag Count</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of reportData">
                            <td>{{ r?.tag }}</td>
                            <td>{{ r?.tag_count }}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- PRIORITY REPORT -->
                <table *ngIf="reportType === 'priority'">
                    <thead>
                        <th>Priority</th>
                        <th>Count</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let r of reportData">
                            <td>{{ r?.priority }}</td>
                            <td>{{ r?.count }}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- GOALS REPORT -->
                <div *ngIf="reportType === 'goals'">
                    <table>
                        <thead>
                            <th style="width: 25%">Member</th>
                            <th style="width: 30%">Email</th>
                            <th style="width: 15%">Goal Count</th>
                            <th style="width: 30%">Goal Status</th>
                            <!-- <th style="width:10%"></th> -->
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of reportData.list">
                                <td>
                                    <!-- <input type="checkbox" class="custom-control-input" id="{{ r.patient }}" /> -->
                                    <!-- <label class="custom-control-label" for="{{ r.patient }}"></label> -->
                                    {{ r?.patient }}
                                </td>
                                <td>
                                    <a>{{ r?.email }}</a>
                                </td>
                                <td style="text-align: center">{{ r?.total_goals }}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar bar" role="progressbar" aria-valuemin="0"
                                            aria-valuemax="100" [style.width]="status(r.goalstatus)"
                                            [ngStyle]="{ color: statusColor(r.goalstatus) }">
                                            {{ r.goalstatus }}%
                                        </div>
                                    </div>
                                </td>
                                <!-- <td style="text-align: center">
                                    <i class="fa fa-download" style="font-size:16px;color:gray"></i>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <!--
                    <table>
                        <thead>
                            <th style="width: 25%">
                                Member : {{ reportData.total.total_patient }}
                            </th>
                            <th style="width: 30%"></th>
                            <th style="width: 15%">Avg Goals : {{ reportData.total.avg_goals_per_patient }}</th>
                            <th style="width: 30%">Avg Status : {{ reportData.total.avg_goals_status }}</th>
                        </thead>
                    </table>
                    <br />
                    <h4>Report By Company</h4>
                    <table class="table table-bordred table-striped">
                        <thead>
                            <th>Company</th>
                            <th>#Goal User Count</th>
                            <th>#User Count</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of reportData.companyReport">
                                <td>{{ r?.company }}</td>
                                <td>{{ r?.goalusercount }}</td>
                                <td>{{ r?.usercount }}</td>
                            </tr>
                        </tbody>
                    </table>
                -->
                </div>

                <!-- CHAT REPORT -->
                <div *ngIf="reportType === 'chatsession'">
                    <table>
                        <thead style="vertical-align: baseline">
                            <th (click)="sortType = 'patientname'; sortReverse = !sortReverse" style="width: 15%">
                                Member
                                <span *ngIf="sortType === 'patientname' && !sortReverse"
                                    class="fa fa-caret-down"></span>
                                <span *ngIf="sortType === 'patientname' && sortReverse" class="fa fa-caret-up"></span>
                            </th>
                            <th (click)="sortType = 'chat_sessions'; sortReverse = !sortReverse" style="width: 15%">
                                Chat Session
                                <span *ngIf="sortType === 'chat_sessions' && !sortReverse"
                                    class="fa fa-caret-down"></span>
                                <span *ngIf="sortType === 'chat_sessions' && sortReverse" class="fa fa-caret-up"></span>
                            </th>
                            <th (click)="sortType = 'patient_chat_lag'; sortReverse = !sortReverse" style="width: 15%">
                                Member Chat Lag
                                <span *ngIf="sortType === 'patient_chat_lag' && !sortReverse"
                                    class="fa fa-caret-down"></span>
                                <span *ngIf="sortType === 'patient_chat_lag' && sortReverse"
                                    class="fa fa-caret-up"></span>
                            </th>
                            <th (click)="sortType = 'provider_chat_lag'; sortReverse = !sortReverse" style="width: 15%">
                                Provider Chat Lag
                                <span *ngIf="sortType === 'provider_chat_lag' && !sortReverse"
                                    class="fa fa-caret-down"></span>
                                <span *ngIf="sortType === 'provider_chat_lag' && sortReverse"
                                    class="fa fa-caret-up"></span>
                            </th>
                            <th (click)="sortType = 'unresponded_patient_chats'; sortReverse = !sortReverse"
                                style="width: 15%">
                                Unresponded Member Chats
                                <span *ngIf="sortType === 'unresponded_patient_chats' && !sortReverse"
                                    class="fa fa-caret-down"></span>
                                <span *ngIf="sortType === 'unresponded_patient_chats' && sortReverse"
                                    class="fa fa-caret-up"></span>
                            </th>
                            <th (click)="sortType = 'unresponded_provider_chats'; sortReverse = !sortReverse"
                                style="width: 25%">
                                Unresponded Provider Chats
                                <span *ngIf="sortType === 'unresponded_provider_chats' && !sortReverse"
                                    class="fa fa-caret-down"></span>
                                <span *ngIf="sortType === 'unresponded_provider_chats' && sortReverse"
                                    class="fa fa-caret-up"></span>
                            </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of reportData.list | orderBy: sortType:sortReverse">
                                <td>
                                    {{ r?.patientname }}
                                </td>
                                <td>
                                    {{ r?.chat_sessions }}
                                </td>
                                <td>{{ r?.patient_chat_lag }}</td>
                                <td>{{ r?.provider_chat_lag }}</td>
                                <td>{{ r?.unresponded_patient_chats }}</td>
                                <td>{{ r?.unresponded_provider_chats }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead class="chatsession">
                            <th>Total Member : {{ reportData.avg.total_patient }}</th>
                            <th>Avg Chat Session : {{ reportData.avg.avg_chat_session }}</th>
                            <th>Avg Member Chat Lag : {{ reportData.avg.avg_patient_chat_lag }}</th>
                            <th>Avg Provider Chat Lag : {{ reportData.avg.avg_provider_chat_lag }}</th>
                            <th>Total Unresponded Member Chats : {{ reportData.avg.total_unresponded_patient_chats }}
                            </th>
                        </thead>
                        <thead class="chatsession">
                            <th>Total Unresponded Provider Chats : {{ reportData.avg.total_unresponded_provider_chats }}
                            </th>
                            <th>Member Chat Lag User Count : {{ reportData.avg.patient_chat_lag_user_count }}</th>
                            <th>Provider Chat Lag User Count : {{ reportData.avg.provider_chat_lag_user_count }}</th>
                            <th>Total Chat Session : {{ reportData.avg.total_chat_session }}</th>
                            <th>Chat Sessions User Count : {{ reportData.avg.chat_sessions_user_count }}</th>
                        </thead>
                    </table>
                </div>
                <!-- BILLING REPORT -->
                <div *ngIf="reportType === 'billing'">
                    <!-- <div *ngFor="let item of testObject | keyvalue">
                        Key: <b>{{ item.key }}</b> and Value: <b>{{ item.value }}</b>
                    </div> -->
                    <table class="billing table table-bordred table-striped">
                        <thead>
                            <th colspan="2">Overall</th>

                            <th></th>
                            <th></th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="width: 10%"></td>
                                <td style="width: 20%">Admins</td>
                                <td>{{ reportData.donotcharge_admin_count+reportData.admin_count
                                    }}/<b>{{reportData.admin_count}}</b></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Providers</td>
                                <td>{{reportData.donotcharge_provider_count + reportData.provider_count
                                    }}/<b>{{reportData.provider_count}}</b></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <!-- <tr>
                                <td></td>
                                <td>Provider Admin</td>
                                <td>{{reportData.donotcharge_provider_admin_count+ reportData.provider_admin_count
                                    }}/<b>{{reportData.provider_admin_count}}</b>
                                </td>
                                <td></td>
                                <td></td>
                            </tr> -->

                            <tr>
                                <td></td>
                                <td>Active Users</td>
                                <td>{{ reportData.active_user_count }}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Invited Users</td>
                                <td>{{ reportData.user_count }}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="billing table table-bordred table-striped">
                        <thead>
                            <th colspan="2">Users By Care Team</th>
                            <th>Active Users</th>
                            <th>Invited Users</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let u of reportData.Users_by_Care_Team | orderBy: sortType:sortReverse:'active_careteamcount':false">
                                <td style="width: 10%"></td>
                                <td style="width: 20%">{{ u.careteamname }}</td>
                                <td>{{ u.active_careteamcount }}</td>
                                <td>{{ u.careteamcount }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="billing table table-bordred table-striped">
                        <thead>
                            <th colspan="2">Users By Company</th>
                            <th>Active Users</th>
                            <th>Invited Users</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let u of reportData.Users_by_Employer">
                                <td style="width: 10%"></td>
                                <td style="width: 20%">{{ u.employerame }}</td>
                                <td>{{ u.active_employercount }}</td>
                                <td>{{ u.employercount }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- END BILLING REPORT -->
                <!-- MEMBERS REPORT -->
                <div *ngIf="reportType === 'members'">
                    <table>
                        <thead>
                            <th style="width: 25%">Name</th>
                            <th style="width: 30%">Email</th>
                            <th style="width: 15%">Care Team</th>
                            <th style="width: 30%">Role</th>
                            <!-- <th style="width:10%"></th> -->
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of reportData">
                                <td>
                                    <!-- <input type="checkbox" class="custom-control-input" id="{{ r.patient }}" /> -->
                                    <!-- <label class="custom-control-label" for="{{ r.patient }}"></label> -->
                                    {{ r?.firstname }} {{ r?.lastname }}
                                </td>
                                <td>
                                    {{ r?.email }}
                                </td>
                                <td>
                                    {{ r?.careteamname }}
                                </td>
                                <td>
                                    {{ r?.rolename | uppercase }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- BY PROVIDER REPORT -->
                <div *ngIf="reportType === 'provider'">
                    <table>
                        <thead>
                            <th style="width: 30%">Provider</th>
                            <th style="width: 25%">Care Team</th>
                            <th style="width: 15%;text-align: left;padding-left: 10px;">Sent Messages</th>
                            <!-- <th style="width: 30%">Unread Messages</th> -->
                            <!-- <th style="width:10%"></th> -->
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of reportData; let i = index;">
                                <td>
                                    {{ r?.fullname }}
                                </td>
                                <td>
                                    {{ r?.careteamname }}
                                </td>
                                <td style="text-align: left;">
                                    {{ r?.total_sent_messages }}
                                </td>
                                <!-- <td>
                                    {{ r?.total_unread_messages==null?0:r.total_unread_messages }}
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- END MEMBERS REPORT -->
            </div>
        </div>
    </div>
</div>

<div class="spinner-border text-primary" role="status" *ngIf="loadingPage">
    <span class="sr-only">Loading...</span>
</div>

<p *ngIf="showHelpText" class="helptext">Please use filters above to populate report data.</p>