<div class="modal-content">
    <!--<div class="modal-header text-white">
        <h5 class="modal-title">{{ UserRole !== "user" ? "Password Reset" : "Send onboarding" }}</h5>
        <button type="button" class="close" (click)="onCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>-->
    <div class="modal-body">
        <!--<p>{{ UserRole !== "user" ? "Send password reset email to " : "Send onboarding email to " }}{{ UserEmail }}?</p>-->
        <h5 class="modal-title">Send onboarding email/SMS message?</h5>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="sendResetLink()">Send</button>
    </div>
</div>
