<div class="modal-header">
    <h5 class="modal-title">Notes</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="notes-row-header" *ngIf="NotesData.patient.referralnotes && NotesData.isReferral">Referral Notes</div>
    <div class="notes-row" *ngIf="NotesData.patient.referralnotes && NotesData.isReferral">
        <div class="notes-text">
            {{ NotesData.patient.referralnotes }}
        </div>
        <hr />
    </div>
    <div class="notes-row-header" *ngIf="NotesData.patient.notes">Provider Notes</div>
    <div class="notes-row" *ngFor="let note of NotesData.patient.notes">
        <div class="notes-text">
          <span class="providername" *ngIf="note.providerName">{{ note.providerName }} @ {{ note.datetimeutc | date: "MM/dd/yyyy hh:mm" | uppercase  }} :</span>
          <span class="providertext">{{ note.text }}</span>
        </div>
        <hr />
    </div>
</div>
