import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { SessionService } from "../services/session.service";

@Injectable({
    providedIn: "root",
})
export class ConfidentialityStatementResolverService implements Resolve<any> {
    constructor(private sessionService: SessionService) {}

    resolve(): Observable<any> | Observable<never> {
        if (!this.sessionService.isAdmin()) {
            // Resolve the acceptance and current statement
            return new Observable((observer: Observer<any>) => {
                observer.next({ acceptance: "", statement: environment.login_message || {} });
                observer.complete();
            });
        }
    }
}
