import { Component, OnInit } from "@angular/core";
import { AppsService } from "../../services/apps.service";
import { CareTeamService } from "../../services/careteam.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddAppModalComponent } from "../../modals/add-app-modal/add-app-modal.component";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "orders",
    templateUrl: "./link-management.component.html",
    styleUrls: ["./link-management.component.scss"],
})
export class LinkManagementComponent implements OnInit {
    keyword = "fullname";

    loadingPage: boolean = false;

    apps = [];

    appData = {};
    public NoRecordsFound = false;
    displayedColumns = ["title", "description", "action"];

    constructor(
        private appsService: AppsService,
        private careTeamService: CareTeamService,
        private snackbar: SnackbarService,
        private modalService: NgbModal,
        private sessionService: SessionService
    ) {}
    userMembershipId = "";
    ngOnInit() {
        this.getAppsList();
        this.userMembershipId = this.sessionService.getCurrentUserMembershipId();
    }
    public getAppsList() {
        this.appsService.getApps().subscribe(
            (data) => {
                this.apps = data[0].attributes.links.filter((i) => i.membershipid == this.userMembershipId);
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public onAddApp() {
        this.appData = {};
        const modalRef = this.modalService.open(AddAppModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.AppData = this.appData;
        modalRef.componentInstance.EditForm = false;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) this.snackbar.show(receivedEntry.message);
            else this.snackbar.show(receivedEntry.message, "danger");
            this.getAppsList();
        });
    }

    public onEdit(p) {
        this.appData = p;
        const modalRef = this.modalService.open(AddAppModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.AppData = this.appData;
        modalRef.componentInstance.EditForm = true;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) this.snackbar.show(receivedEntry.message);
            else this.snackbar.show(receivedEntry.message, "danger");
            this.getAppsList();
        });
    }

    public onDelete(p) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this app?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.appsService.deleteApp(p.appid).subscribe(
                    (data) => {
                        this.getAppsList();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
}
