import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";
import * as moment from "moment";
import * as _ from 'lodash';

@Injectable()
export class MSXReportService {
    private url = environment.uri;
    careteamId = "";
    public groupChangeNotifier = new Subject<string>();
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) { }

    endDate = moment().format("YYYY-MM-DD")
    startDate = moment().add(-6, 'months').format("YYYY-MM-DD")

    changeGroup(data) {
        this.groupChangeNotifier.next(data);
    }

    public getMetricsList(): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + "/GetReportingMetrics/v3?PracticeID=00000000-0000-0000-0000-000000000000&GroupID=" + selectedGroup + "&ProviderID=00000000-0000-0000-0000-000000000000")
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public getChartData(matrixId = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetChartData/v3/' + matrixId + '?GroupID=' + selectedGroup + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public getVisitChartData(ProviderID = "", gender = "", minage = 0, maxage = 100, datapointsType = "daily"): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetVisitChartData/v2/' + datapointsType.toLowerCase() + '?GroupID='
                    + selectedGroup + '&cache=true' + '&gender=' + gender + '&minage=' + minage + '&maxage=' + maxage

                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public getVisitWithSpecialities(ProviderID = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetVisitChartDataWithSpecialities/v2?GroupID=' + selectedGroup + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public GetVisitYearData(ProviderID = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetVisitYearData/v2?GroupID=' + selectedGroup + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public GetSurveyData(url = "", metricId, gender, minage, maxage, questionid, answerid, cptcode = "", specialty = "", surveyType = "", icd10code = "", xAxisType = "",
        practiceID = "", providerName = "", noShowPercentage = false): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        if (url === "/survey-data") {
            return new Observable((observer: Observer<any>) => {
                this.avcHttp
                    .sendRequest("get", environment.graph_uri + '/GetSurveyData/v3/' + metricId + '?GroupID=' + selectedGroup + '&PracticeID=' + practiceID + '&gender=' + gender + '&minage=' + minage + '&maxage=' + maxage + '&questionid=' + encodeURIComponent(questionid)
                        + '&answerid=' + encodeURIComponent(answerid) + '&surveyid=' + (surveyType === "ALL" ? "" : surveyType) + '&icd10code=' + (icd10code === "ALL" ? "" : icd10code)
                        + '&cptcode=' + (cptcode === "ALL" ? "" : cptcode) + '&specialtyid=' + (specialty === "ALL" ? "" : specialty)
                        + '&xAxisType=' + xAxisType + '&cache=true')
                    .subscribe(
                        (body) => {
                            body = this.helper.ParseToJson(body);
                            if (body.data !== undefined) {
                                observer.next(body.data);
                                observer.complete();
                            } else {
                                observer.error(body.errors);
                                observer.complete();
                            }
                        },
                        (err) => {
                            observer.next(err);
                            observer.complete();
                        }
                    );
            });
        }
        else {
            let _url = environment.graph_uri + '/GetVisitData/v3/?GroupID=';
            if (noShowPercentage) {
                _url = environment.graph_uri + '/GetVisitData/nsp/v3/?GroupID=';
            }
            return new Observable((observer: Observer<any>) => {
                this.avcHttp
                    .sendRequest("get", _url + selectedGroup + '&PracticeID=' + practiceID + '&gender=' + gender + '&minage=' + minage + '&maxage=' + maxage + '&questionid=' + encodeURIComponent(questionid)
                        + '&answerid=' + encodeURIComponent(answerid) + '&surveyid=' + (surveyType === "ALL" ? "" : surveyType) + '&icd10code=' + (icd10code === "ALL" ? "" : icd10code)
                        + '&cptcode=' + (cptcode === "ALL" ? "" : cptcode) + '&specialtyid=' + (specialty === "ALL" ? "" : specialty)
                        + '&xAxisType=' + xAxisType + '&cache=true')
                    .subscribe(
                        (body) => {
                            body = this.helper.ParseToJson(body);
                            if (body.data !== undefined) {
                                observer.next(body.data);
                                observer.complete();
                            } else {
                                observer.error(body.errors);
                                observer.complete();
                            }
                        },
                        (err) => {
                            observer.next(err);
                            observer.complete();
                        }
                    );
            });
        }

    }
    public GetEntity(GroupID = "", PracticeID = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetEntity/v2?GroupID=' + GroupID + '&PracticeID=' + PracticeID)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public saveFavoriteList(settings: any, matricsGraph = true): Observable<any> {
        let matrics = []
        if (matricsGraph) {
            settings.forEach(element => {
                matrics.push(element.metricid)
            });
        }
        let data = {
            "userid": this.sessionService.currentUser.id,
            "settings": [
                {
                    "settingname": "additionalsettings",
                    "settingvalue": JSON.stringify(settings)
                }
            ]
        };

        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("post", environment.uri + '/settings', data)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getFavoriteList(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/settings/' + this.sessionService.currentUser.id)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getReportList(reportName = "Comments", type = "group", id = ""): Observable<any> {
        let _id = localStorage.getItem("selectedGroup");
        let reportFor = "&groupId=" + _id;
        if (type == "Group") {
            reportFor = "&groupId=" + id;
        }
        if (type == "Practice") {
            reportFor = "&practiceId=" + id;
        }
        if (type == "Provider") {
            reportFor = "&providerId=" + id;
        }
        if (type == "Markets") {
            reportFor = "&marketId=" + id;
        }
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/reporting/reportList?reportName=' + reportName + reportFor)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public retrieveUndeliverableEmails(reportName = "Comments"): Observable<any> {
        let id = "93AEC4E5-D916-401F-B05B-C0532A4DC5EF";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/reporting/retrieveundeliverableemails?groupId=93AEC4E5-D916-401F-B05B-C0532A4DC5EF&monthYear=02012023&viewMethod=pdf', "", "", false, "", true, 'blob')
                .subscribe(
                    (body) => {
                        // body = this.helper.ParseToJson(body);
                        if (body.response !== undefined) {
                            observer.next(body.response);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }


    public PatientBilling(GroupID = "", Type = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/msx/Billing/GraphType/' + Type + '/v2?GroupID=' + GroupID + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }


    public BillingData(GroupID = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/msx/Billing/Data/v2?GroupID=' + GroupID + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getBillinMetricsList(): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + "/Billing/Graph/Metrics/v2")
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }


    public GetICDData(url = "", metricid = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        if (url === "/survey-data") {
            return new Observable((observer: Observer<any>) => {
                this.avcHttp
                    .sendRequest("get", environment.graph_uri + '/GetSurveyData/dropdown/v3/' + metricid + '?GroupID=' + selectedGroup + '&cache=true')
                    .subscribe(
                        (body) => {
                            body = this.helper.ParseToJson(body);
                            if (body.data !== undefined) {
                                observer.next(body.data);
                                observer.complete();
                            } else {
                                observer.error(body.errors);
                                observer.complete();
                            }
                        },
                        (err) => {
                            observer.next(err);
                            observer.complete();
                        }
                    );
            });
        }
        else {
            return new Observable((observer: Observer<any>) => {
                this.avcHttp
                    .sendRequest("get", environment.graph_uri + '/GetVisitData/dropdown/v3?GroupID=' + selectedGroup + '&cache=true')
                    .subscribe(
                        (body) => {
                            body = this.helper.ParseToJson(body);
                            if (body.data !== undefined) {
                                observer.next(body.data);
                                observer.complete();
                            } else {
                                observer.error(body.errors);
                                observer.complete();
                            }
                        },
                        (err) => {
                            observer.next(err);
                            observer.complete();
                        }
                    );
            });
        }
    }


    public GetVisitDropdownData(metricid = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetVisitData/dropdown/v3?GroupID=' + selectedGroup + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public GetVisitData(gender, minage, maxage, questionid, answerid, cptcode = "", specialty = "", surveyType = "", icd10code = "", xAxisType = "",
        practiceID = "", providerName = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.graph_uri + '/GetVisitData/v3/?GroupID=' + selectedGroup + '&PracticeID=' + practiceID + '&gender=' + gender + '&minage=' + minage + '&maxage=' + maxage + '&questionid=' + encodeURIComponent(questionid)
                    + '&answerid=' + encodeURIComponent(answerid) + '&surveyid=' + (surveyType === "ALL" ? "" : surveyType) + '&icd10code=' + (icd10code === "ALL" ? "" : icd10code)
                    + '&cptcode=' + (cptcode === "ALL" ? "" : cptcode) + '&specialtyid=' + (specialty === "ALL" ? "" : specialty)
                    + '&xAxisType=' + xAxisType + '&cache=true')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public saveFavoriteListV2(settings: any, graphName = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let additionalsettings = [];
            this.getFavoriteList().subscribe(
                (data) => {
                    let value = _.find(data[0].type, { settingname: 'additionalsettings' });
                    if (value != undefined) {
                        value = JSON.parse(value.settingvalue);

                        let index = _.findIndex(value, { graphName: graphName, name: settings.name });
                        if (index > 0) {
                            observer.error("Graph with this name already exists. Please choose a different name.");
                            observer.complete();
                            return;
                        }
                        let graphs = _.filter(value, (f) => (f.graphName === graphName))
                        if (graphs.length > 3) {
                            observer.error("Already 4 graphs added in favorite list. Please remove from favorites for adding new one.");
                            observer.complete();
                            return;
                        }
                        //update graph if already exists
                        if (value.length !== undefined) {
                            value.forEach(element => {
                                if (element.graphId == settings.graphId) {
                                    value = element;
                                }
                            });
                        }
                    }

                    if (_.filter(value, (f) => (f.graphName === "visit" || f.graphName === "survey" || f.graphName === "billing")).length > 0) {
                        additionalsettings = _.filter(value, (f) => (f.graphName === "visit" || f.graphName === "survey" || f.graphName === "billing"));
                    }
                    additionalsettings.push(settings);
                    let settingData = {
                        "userid": this.sessionService.currentUser.id,
                        "settings": [
                            {
                                "settingname": "additionalsettings",
                                "settingvalue": JSON.stringify(additionalsettings)
                            }
                        ]
                    };
                    this.avcHttp
                        .sendRequest("post", environment.uri + '/settings', settingData)
                        .subscribe(
                            (body) => {
                                body = this.helper.ParseToJson(body);
                                if (body.data !== undefined) {
                                    observer.next(body.data);
                                    observer.complete();
                                } else {
                                    observer.error(body.errors);
                                    observer.complete();
                                }
                            },
                            (err) => {
                                observer.next(err);
                                observer.complete();
                            }
                        );

                },
                (err) => {
                    //this.snackbar.show("error occurred please try again later.", "danger");
                }
            );

        });
    }

    public updateFavoriteList(graphId = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let additionalsettings = [];
            this.getFavoriteList().subscribe(
                (data) => {
                    let value = _.find(data[0].type, { settingname: 'additionalsettings' });
                    if (value != undefined) {
                        value = JSON.parse(value.settingvalue);
                        value.forEach(element => {
                            if (element.graphId !== graphId) {
                                additionalsettings.push(element)
                            }
                        });

                    }
                    let settingData = {
                        "userid": this.sessionService.currentUser.id,
                        "settings": [
                            {
                                "settingname": "additionalsettings",
                                "settingvalue": JSON.stringify(additionalsettings)
                            }
                        ]
                    };
                    this.avcHttp
                        .sendRequest("post", environment.uri + '/settings', settingData)
                        .subscribe(
                            (body) => {
                                body = this.helper.ParseToJson(body);
                                if (body.data !== undefined) {
                                    observer.next(body.data);
                                    observer.complete();
                                } else {
                                    observer.error(body.errors);
                                    observer.complete();
                                }
                            },
                            (err) => {
                                observer.next(err);
                                observer.complete();
                            }
                        );

                },
                (err) => {
                    //this.snackbar.show("error occurred please try again later.", "danger");
                }
            );

        });
    }
    public downloadReport(reportId = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/reporting/download/' + reportId.toLocaleLowerCase(), "", "", false, "", true, 'blob')
                .subscribe(
                    (body) => {
                        // body = this.helper.ParseToJson(body);
                        if (body.response !== undefined) {
                            observer.next(body.response);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public generateReport(data): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("post", environment.uri + '/reporting/GenerateReport/v2/', data)
                .subscribe(
                    (body) => {
                        // body = this.helper.ParseToJson(body);
                        if (body.response !== undefined) {
                            observer.next(body.response);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public saveEmailschedules(settings: any, userId = ""): Observable<any> {
        let _userId = userId == "" ? this.sessionService.currentUser.id : userId;
        let data = {
            "userid": _userId,
            "settings": [
                {
                    "settingname": "favoriteemailschedules",
                    "settingvalue": JSON.stringify(settings)
                }
            ]
        };

        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("post", environment.uri + '/settings', data)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getEmailschedules(userId = ""): Observable<any> {
        let _userId = userId == "" ? this.sessionService.currentUser.id : userId;
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/settings/' + _userId)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getVisitMetrics(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/msx/Visit/Graph/Metrics/v2/')
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public UpdateFutureVisit(futurevisits, groupId = ""): Observable<any> {
        let data = {
            "groupid": groupId,
            "futurevisits": futurevisits
        };
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("put", environment.graph_uri + '/group/futureVisitsStatus/v2', data)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getScheduleReports(reportName = "", sortBy = "", orderBy): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + '/msx/ScheduleReports/v2/' + selectedGroup + "?limit=5&pageno=1&order=" + orderBy + "&sort=" + sortBy + "&search=" + encodeURIComponent(reportName))
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public deleteAllGraph(restore = ""): Observable<any> {
        let _userId = this.sessionService.currentUser.id;
        let data = {
            "userid": _userId,
            "settings": [
                {
                    "settingname": "dashboardgraphtype",
                    "settingvalue": restore == "" ? "none" : "default"
                }
            ]
        };
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("post", environment.uri + '/settings/', data)
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }


    public getReportEntityList(providerId = ""): Observable<any> {
        let id = localStorage.getItem("selectedGroup");
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + "/reporting/EntityList?groupId=" + id.toLowerCase() + "&providerID=" + providerId.toLowerCase())
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

}


