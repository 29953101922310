import { Component, OnInit } from "@angular/core";
import { CareTeamService } from "../../services/careteam.service";
import { EmployersService } from "../../services/employer.service";
import { RoleService } from "../../services/role.service";
import { ReportService } from "../../services/report.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { SessionService } from "../../services/session.service";
import { UsersService } from "../../services/users.service";

@Component({
    selector: "reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
    constructor(
        public careTeamService: CareTeamService,
        private fb: UntypedFormBuilder,
        private employersService: EmployersService,
        private roleService: RoleService,
        private reportService: ReportService,

        private sessionService: SessionService,
        private snackbar: SnackbarService,
        private usersService: UsersService
    ) {}

    public reportForm: any;
    //public reportName: string = "";
    public reportData: any;
    public showReportSection: boolean = false;
    public reportType = "";
    public loadingPage = false;
    public hasNoEmployer = false;
    //dt = new Date();
    public report_start_date = new Date();
    public report_end_date = new Date();

    public showHelpText = true;
    public sortType = "patient";
    public sortReverse = true;

    public fromDate = {};
    public toDate = {};
    public userRole = this.sessionService.currentUserRole;

    public optionText = "";
    public showProviderList = false;
    public showCareTeam = false;
    public reportTypes = [
        { type: "", name: "Select a report type" },
        //{type:"chatsession",name:"Chat Session"},
        { type: "goals", name: "Goals Report" },
        //{type:"priority",name:"Priority Report"},
        //{type:"saving",name:"Savings Report"},
        { type: "usage", name: "Usage Report By Careteam" },
    ];
    public onboardTypes = [
        { type: "onboarded", name: "Onboarded" },
        { type: "notonboarded", name: "Not Onboarded" },
    ];
    public showControls = { careteam: false, employers: false, startdate: false, enddate: false, reportdate: false, onboarded: false, roles: false };
    public providers = [];
    ngOnInit() {
        let activeCareTeam = !this.sessionService.isAdmin() ? this.sessionService.activeCareTeam : "";

        if (this.sessionService.isAdmin()) {
            this.reportTypes.push({ type: "reportByProvider", name: "Usage Report By Provider" });
            this.reportTypes.push({ type: "billing", name: "Billing Report" });
            this.reportTypes.push({ type: "members", name: "Members Report" });
            this.getRoles();
        }

        // Set start date to beginning of week, end date to today
        let dt = new Date();
        this.toDate = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() };
        while (dt.getDay() !== 0) dt.setDate(dt.getDate() - 1);
        this.fromDate = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() };

        // Set form data
        this.reportForm = this.fb.group({
            careteamid: [activeCareTeam == null || activeCareTeam === "" ? "" : activeCareTeam.careteamid, Validators.required],
            employer: ["", Validators.required],
            startDate: [this.fromDate, Validators.required],
            endDate: [this.toDate, Validators.required],
            reportType: ["goals", Validators.required],
            role: ["", Validators.required],
            onboarded: ["", Validators.required],
            providerid: ["00000000-0000-0000-0000-000000000000", Validators.required],
        });
        this.getCareTeams();
        this.getEmployers();
        this.onFormSelect();
        this.getProviders();
    }

    public careteam: any;
    public employers = [];
    public roles = [];

    public getCareTeams() {
        let userdata = this.sessionService.currentUser;
        if (!this.sessionService.isAdmin()) {
            this.careTeamService.getCareTeamsByProvider(userdata["id"]).subscribe(
                (data) => {
                    if (data[0].attributes.careteams.length > 0) {
                        this.careteam = data[0].attributes.careteams;
                        // console.log(JSON.stringify(this.careteam));
                    }
                },
                (err) => {
                    console.log(err[0].detail, 1);
                }
            );
        } else {
            this.careTeamService.getCareteams().subscribe(
                (data) => {
                    if (data[0].attributes.length > 0) {
                        this.careteam = data[0].attributes;
                    }
                },
                (err) => {
                    console.log(err[0].detail, 1);
                }
            );
        }
    }

    public getEmployers() {
        this.employersService.getEmployers().subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.employers = data[0].attributes;
                    let noEmployer = this.employers.filter((x) => x.employername === "No Employer");
                    if (noEmployer && noEmployer.length > 0) {
                        this.hasNoEmployer = true;
                    }
                }
            },
            (err) => {
                console.log(err[0].detail, 1);
            }
        );
    }
    public getRoles() {
        this.roleService.getRoles().subscribe(
            (data) => {
                this.roles = data;
            },
            (err) => {
                console.log(err[0].detail, 1);
            }
        );
    }

    public onFormSelect() {
        this.showReportSection = false;
        this.showProviderList = false;
        switch (this.reportForm.value.reportType) {
            case "":
                this.showControls = { careteam: false, employers: false, startdate: false, enddate: false, reportdate: false, onboarded: false, roles: false };
                break;
            case "chatsession":
                this.showControls = { careteam: true, employers: false, startdate: false, enddate: false, reportdate: true, onboarded: false, roles: false };
                break;
            case "usage":
                this.showControls = { careteam: true, employers: false, startdate: true, enddate: true, reportdate: false, onboarded: false, roles: false };
                this.optionText = "Select";
                break;
            case "goals":
                this.showControls = { careteam: true, employers: false, startdate: true, enddate: true, reportdate: false, onboarded: false, roles: false };
                this.optionText = "Select";
                break;
            case "prioritiy":
            case "savings":
            case "usage":
            case "billing":
                this.showControls = { careteam: false, employers: false, startdate: false, enddate: false, reportdate: true, onboarded: false, roles: false };
                break;
            case "members":
                this.showControls = { careteam: true, employers: true, startdate: false, enddate: false, reportdate: false, onboarded: true, roles: true };
                this.optionText = "All";
                break;
            case "reportByProvider":
                this.showControls = { careteam: false, employers: false, startdate: true, enddate: true, reportdate: false, onboarded: false, roles: false };
                this.showProviderList = true;
                break;
        }
    }

    public showReport() {
        if (this.reportForm.value.reportType === "") {
            this.snackbar.show("Please select a report type first.", "danger");
            return false;
        }
        if (this.reportForm.value.reportType === "goals" || this.reportForm.value.reportType === "usage") {
            if (this.reportForm.value.careteamid === "") {
                this.snackbar.show("Please select a care team.", "danger");
                return false;
            }
        }
        if (this.reportForm.value.reportType === "members") {
            this.loadingPage = true;
            let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
            this.reportService.getMembersReport(this.reportForm.value, telerxid).subscribe(
                (data) => {
                    this.reportType = "members";
                    this.reportData = data[0].attributes.list;
                    this.showReportSection = true;
                    this.showHelpText = false;
                    this.loadingPage = false;
                },
                (err) => {
                    this.loadingPage = false;
                    this.showHelpText = false;
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        } else {
            let startDate = this.reportForm.value.startDate;
            let endDate = this.reportForm.value.endDate;
            if (this.reportForm.value.reportType !== "chatsession" && this.reportForm.value.reportType !== "billing") {
                if (new Date(startDate.year, startDate.month, startDate.day).getTime() > new Date(endDate.year, endDate.month, endDate.day).getTime()) {
                    this.snackbar.show("End Date should not be less than start date.", "danger");
                    return false;
                }
            }
            this.showReportSection = false;

            this.report_start_date = new Date(startDate.year, startDate.month - 1, startDate.day);
            this.report_end_date = new Date(endDate.year, endDate.month - 1, endDate.day);

            this.showHelpText = false;
            this.loadingPage = true;
            if (this.reportForm.value.reportType === "reportByProvider") {
                if (this.reportForm.value.providerid == "00000000-0000-0000-0000-000000000000") {
                    this.showCareTeam = false;
                } else {
                    this.showCareTeam = true;
                }
                this.reportService.getReportByProvider(this.reportForm.value).subscribe(
                    (data) => {
                        this.reportType = data[0].type;
                        this.reportData = data[0].attributes.data.records;
                        this.reportData.sort(function (a, b) {
                            if (a.fullname.toLowerCase() < b.fullname.toLowerCase()) {
                                return -1;
                            }
                            if (a.fullname.toLowerCase() > b.fullname.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        });
                        if (this.reportData !== undefined) {
                            this.showReportSection = true;
                            this.showHelpText = false;
                        } else {
                            this.showReportSection = false;
                            this.showHelpText = true;
                        }
                        this.loadingPage = false;
                    },
                    (err) => {
                        this.loadingPage = false;
                        this.showHelpText = false;
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            } else {
                this.reportService.getReportByType(this.reportForm.value).subscribe(
                    (data) => {
                        this.reportType = data[0].type;
                        this.reportData = data[0].attributes.data;

                        if (this.reportData !== undefined) {
                            this.showReportSection = true;
                            this.showHelpText = false;
                        } else {
                            this.showReportSection = false;
                            this.showHelpText = true;
                        }
                        this.loadingPage = false;
                    },
                    (err) => {
                        this.loadingPage = false;
                        this.showHelpText = false;
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        }
    }
    public onChangeOnboardEmployer(evt) {
        this.reportForm.controls["role"].setValue("user");
    }
    public status(status) {
        return status + "%";
    }

    public statusColor(status) {
        if (status < 20) {
            return "black";
        } else {
            return "white";
        }
    }

    public onKeydown() {
        return false;
    }

    public downloadCSV() {
        let headers = [];
        let data = [];
        let isEmpty = false;
        switch (this.reportType.toLocaleLowerCase()) {
            case "savings":
                headers = Object.getOwnPropertyNames(this.reportData[0]);
                data = this.reportData;
                break;
            case "priority":
                // console.log(this.reportData.data);
                if (this.reportData.data.length === 0) {
                    isEmpty = true;
                    break;
                }
                headers = Object.getOwnPropertyNames(this.reportData.data[0]);
                data = this.reportData.data;
                break;
            case "members":
                let _reportData: any;
                _reportData = this.reportData.map((item) => ({
                    userid: item.userid,
                    title: item.title,
                    fullname: item.fullname,
                    gender: item.gender,
                    dob: item.dob,
                    rolename: item.rolename,
                    phonenumber: item.phonenumber,
                    email: item.email,
                    postalcode: item.membershipid,
                    timezone: item.timezone,
                }));
                headers = Object.getOwnPropertyNames(_reportData[0]);
                data = _reportData;
                break;
            case "billing":
                delete this.reportData.Users_by_Care_Team;
                delete this.reportData.Users_by_Employer;
                headers = Object.getOwnPropertyNames(this.reportData);
                data = [this.reportData];
                break;
            case "provider":
                if (this.reportData.length === 0) {
                    isEmpty = true;
                    break;
                }
                headers = Object.getOwnPropertyNames(this.reportData[0]);
                data = this.reportData;
                break;

            default:
                if (this.reportData.list.length === 0) {
                    isEmpty = true;
                    break;
                }
                headers = Object.getOwnPropertyNames(this.reportData.list[0]);
                if (headers[1] === "patient") {
                    headers[1] = "full name";
                }
                data = this.reportData.list;
                // Object.keys(data).forEach((key) => {
                //     if (!data[0][key]) {
                //         data[0][key] = "";
                //     }
                // });
                break;
        }
        if (isEmpty) {
            this.snackbar.show("Report cannot be downloaded with empty data.", "danger");
            return;
        }
        var options = {
            useBom: true,
            headers: headers,
            nullToEmptyString: true,
        };

        new AngularCsv(data, this.reportType, options);
    }
    public getProviders() {
        this.usersService.getAllUsers("", 10000, 1, "firstname", "asc", "provider,superProvider").subscribe(
            (data) => {
                if (data[0].attributes.list.length > 0) {
                    this.providers = data[0].attributes.list;
                    this.providers.sort(function (a, b) {
                        if (a.fullname.toLowerCase() < b.fullname.toLowerCase()) {
                            return -1;
                        }
                        if (a.fullname.toLowerCase() > b.fullname.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
}
