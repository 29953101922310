import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";

@Component({
    selector: "womenhealth-modal",
    templateUrl: "./womenhealth-modal.component.html",
    styleUrls: ["./womenhealth-modal.component.scss"],
})
export class WomenHealthModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    womenhealthForm: UntypedFormGroup;
    public checklist: Array<any> = [];
    public checklist2: Array<any> = [];
    public others: string = "";
    public others2: string = "";
    public birthControl: boolean = false;
    public pregnancy: boolean = false;
    public menopausal: boolean = false;
    error = "";
    ngOnInit() {
        this.womenhealthForm = this.fb.group({
            firstperiod: [this.SummaryData.summary.womenhealthhistory.firstperiod, [Validators.min(10), Validators.max(18)]],
            periodgap: [this.SummaryData.summary.womenhealthhistory.periodgap, [Validators.min(15), Validators.max(100)]],
            periodduration: [this.SummaryData.summary.womenhealthhistory.periodduration, [Validators.min(1), Validators.max(7)]],
            periodflow: [this.SummaryData.summary.womenhealthhistory.periodflow, []],
            totalpregnancies: [this.SummaryData.summary.womenhealthhistory.totalpregnancies, [Validators.min(0), Validators.max(20)]],
            fulltermbirths: [this.SummaryData.summary.womenhealthhistory.fulltermbirths, [Validators.min(0), Validators.max(20)]],
            miscarriages: [this.SummaryData.summary.womenhealthhistory.miscarriages, [Validators.min(0), Validators.max(20)]],
            abortions: [this.SummaryData.summary.womenhealthhistory.abortions, [Validators.min(0), Validators.max(20)]],
            vaginalbirths: [this.SummaryData.summary.womenhealthhistory.vaginalbirths, [Validators.min(0), Validators.max(20)]],
            csections: [this.SummaryData.summary.womenhealthhistory.csections, [Validators.min(0), Validators.max(20)]],
            premature: [this.SummaryData.summary.womenhealthhistory.premature, [Validators.min(0), Validators.max(20)]],
            menopauseage: [this.SummaryData.summary.womenhealthhistory.menopauseage, [Validators.min(40), Validators.max(70)]],
        });
        this.checklist = [
            {
                name: "DepoProvera",
                value: false,
            },
            {
                name: "IUD",
                value: false,
            },
            {
                name: "Oral Contraceptive Pill (OCP)",
                value: false,
            },
            {
                name: "Partner Vasectomy",
                value: false,
            },
            {
                name: "Other",
                value: false,
            },
        ];

        this.checklist2 = [
            {
                name: "None",
                value: false,
            },
            {
                name: "Diabetes",
                value: false,
            },
            {
                name: "High Blood Pressure",
                value: false,
            },
            {
                name: "Pre-eclampsia",
                value: false,
            },
            {
                name: "Other",
                value: false,
            },
        ];
        this.setupForm();
    }
    /**
     * Returns true if form control is valid after being touched
     * @param key
     */
    isFieldValid(key) {
        return this.womenhealthForm.get(key).invalid && this.womenhealthForm.get(key).touched;
    }
    Validate(field) {
        if (this.womenhealthForm.get(field).value == null) {
            this.womenhealthForm.controls[field].setErrors({ incorrect: true });
        }
    }
    toggleBirthControl(v: boolean | undefined = undefined) {
        if (v !== undefined) {
            this.birthControl = v;
        } else {
            this.birthControl = !this.birthControl;
        }
    }
    togglePregnancy(v: boolean | undefined = undefined) {
        if (v !== undefined) {
            this.pregnancy = v;
        } else {
            this.pregnancy = !this.pregnancy;
        }

        if (this.pregnancy) {
            this.womenhealthForm.controls.totalpregnancies.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.fulltermbirths.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.miscarriages.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.abortions.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.vaginalbirths.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.csections.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.premature.setValidators([Validators.min(0), Validators.max(20)]);

            this.womenhealthForm.controls.totalpregnancies.updateValueAndValidity();
            this.womenhealthForm.controls.fulltermbirths.updateValueAndValidity();
            this.womenhealthForm.controls.miscarriages.updateValueAndValidity();
            this.womenhealthForm.controls.abortions.updateValueAndValidity();
            this.womenhealthForm.controls.vaginalbirths.updateValueAndValidity();
            this.womenhealthForm.controls.csections.updateValueAndValidity();
            this.womenhealthForm.controls.premature.updateValueAndValidity();
        } else {
            this.womenhealthForm.controls.totalpregnancies.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.fulltermbirths.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.miscarriages.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.abortions.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.vaginalbirths.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.csections.setValidators([Validators.min(0), Validators.max(20)]);
            this.womenhealthForm.controls.premature.setValidators([Validators.min(0), Validators.max(20)]);

            this.womenhealthForm.controls.totalpregnancies.updateValueAndValidity();
            this.womenhealthForm.controls.fulltermbirths.updateValueAndValidity();
            this.womenhealthForm.controls.miscarriages.updateValueAndValidity();
            this.womenhealthForm.controls.abortions.updateValueAndValidity();
            this.womenhealthForm.controls.vaginalbirths.updateValueAndValidity();
            this.womenhealthForm.controls.csections.updateValueAndValidity();
            this.womenhealthForm.controls.premature.updateValueAndValidity();
        }
    }
    toggleMenopausal(v: boolean | undefined = undefined) {
        if (v !== undefined) {
            this.menopausal = v;
        } else {
            this.menopausal = !this.menopausal;
        }

        if (this.menopausal) {
            this.womenhealthForm.controls.menopauseage.setValidators([Validators.min(40), Validators.max(70)]);
            this.womenhealthForm.controls.menopauseage.updateValueAndValidity();
        } else {
            this.womenhealthForm.controls.menopauseage.setValidators([Validators.min(40), Validators.max(70)]);
            this.womenhealthForm.controls.menopauseage.updateValueAndValidity();
        }
    }
    setupForm() {
        let whh = this.SummaryData.summary.womenhealthhistory;

        this.togglePregnancy(whh.pregnancy);
        this.toggleBirthControl(whh.birthcontrol);
        this.toggleMenopausal(whh.menopausal);

        if (this.birthControl) {
            this.prePopulateChecklist(this.checklist, whh.birthcontroltype);

            if (whh.otherbirthcontrol && whh.otherbirthcontrol.length > 0) {
                this.others = whh.otherbirthcontrol;
            }
        }

        if (this.pregnancy) {
            this.prePopulateChecklist(this.checklist2, whh.pregnancycomplications);

            if (whh.otherpregnancycomplications && whh.otherpregnancycomplications.length > 0) {
                this.others2 = whh.otherpregnancycomplications;
            }
        }
    }

    prePopulateChecklist(chklist, data) {
        if (data && data.length > 0) {
            data.forEach((item) => {
                chklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = true;

                        if (item.subset && item.subset.length > 0) {
                            if (c.subset && c.subset.length > 0) {
                                item.subset.forEach((isub) => {
                                    c.subset.forEach((csub) => {
                                        if (isub.name === csub.name) {
                                            csub.value = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            });
        }
    }

    getSelectedData(chklist) {
        let selectedData = [];

        chklist.forEach((item) => {
            if (item.value === true) {
                let i = item;

                if (item.subset) {
                    let s = [];

                    item.subset.forEach((sub) => {
                        if (sub.value === true) {
                            s.push(sub);
                        }
                    });

                    i.subset = s;
                }

                selectedData.push(i);
            }
        });

        if (this.others.trim().length > 0) {
            selectedData["other"] = this.others;
        }

        return selectedData;
    }

    public onSave() {
        this.womenhealthForm.markAllAsTouched();
        if (this.womenhealthForm.valid) {
            this.SummaryData.summary["womenhealthhistory"] = this.getFormData();
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }
    getFormData() {
        let whh: any = this.womenhealthForm.value;

        whh["birthcontrol"] = this.birthControl;
        whh["pregnancy"] = this.pregnancy;
        whh["menopausal"] = this.menopausal;

        if (this.birthControl === true) {
            whh["birthcontroltype"] = this.getSelectedData(this.checklist);

            // mechanism for "others" option (and textarea value)
            if (this.others.trim().length > 0) {
                whh["otherbirthcontrol"] = this.others;
            }
        }

        if (this.pregnancy === true) {
            whh["pregnancycomplications"] = this.getSelectedData(this.checklist2);

            // mechanism for "others" option (and textarea value)
            if (this.others2.trim().length > 0) {
                whh["otherpregnancycomplications"] = this.others2;
            }
        }

        return whh;
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
