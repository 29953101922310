<div class="filter-section">
    <div class="row">
        <div class="col-4">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">Events:</span>
                </div>
                <select class="selectpicker form-control custom-select" #filterUsers [(ngModel)]="eventsFilter" style="width: 220px">
                    <option value="all">All Events</option>
                    <option value="server">Server Events</option>
                    <option value="user">User Events</option>
                </select>
            </div>
        </div>
        <div *ngIf="eventsFilter === 'user'" class="col-4">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">UserId:</span>
                </div>
                <input type="text" class="search-ctrl" [(ngModel)]="useridFilter" placeholder="Enter the UserId" />
            </div>
        </div>
        <div class="col-4">
            <div class="search-left">
                <button (click)="search()" [disabled]="eventsFilter === 'user' && (!useridFilter || useridFilter === '')" class="btn btn-primary search-btn">Get Analytics</button>
            </div>
            <div class="search-right">
                <button (click)="clear()" class="btn btn-secondary search-btn">Reset</button>
            </div>
        </div>
    </div>

    <!-- <div class="filter-section-left">
        <i (click)="clear()" style="cursor: pointer" class="fa fa-redo-alt left-chevron"></i>
        <input mat-input class="filter-input" [(ngModel)]="searchByName" (keyup)="applyFilter($event.target.value)" placeholder="Search..." />
    </div> -->

    <app-loader style="position: absolute; top: 200px; right: 45%; z-index: 100" class="loader" *ngIf="showLoader"></app-loader>
</div>
<div *ngIf="!showLoader" class="container-fluid">
    <div *ngIf="analytics && analytics.length > 0" class="table-badfilter">
        <table class="table table-striped datatable">
            <thead>
                <tr>
                    <th *ngFor="let c of displayedColumns">
                        {{ c.displayname }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of analytics">
                    <td>
                        {{ r.datetimeutc | date: "MM/dd/yyyy, hh:mm a" }}
                    </td>
                    <td>
                        {{ r.membershipid }}
                    </td>
                    <td>
                        {{ r.deviceid }}
                    </td>
                    <td style="width: 200px">
                        {{ r.appversion }}
                    </td>
                    <td>
                        {{ r.category }}
                    </td>
                    <td style="width: 120px; word-break: break-all">
                        {{ r.name }}
                    </td>
                    <td>
                        {{ r.appname }}
                    </td>
                    <td style="width: 100px">
                        {{ r.analyticsdata }}
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page" [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

            <select class="custom-select pagination" style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important" [(ngModel)]="tableService.pageSize">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>

<div *ngIf="error" class="error-block">
    <p class="text-danger">{{ error }}</p>
</div>
