<div class="container-fluid page-not-found-bg">
    <div class="row justify-content-center align-items-center h-100">
        <div class="d-flex flex-column align-items-center page-not-found-content">
            <h1 class="display-1">404</h1>
            <h1>Looks like you're lost!</h1>
            <h5 class="page-not-found-details">The page you are looking for could not be found. If you require additional support please contact</h5>
            <a class="text-light" [href]="supportEmailMailTo">{{ supportEmail }} </a>
            <button class="btn btn-lg btn-primary mt-4" routerLink="/home">Go Home</button>
        </div>
    </div>
</div>
