import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MSXReportService } from '../../../services/msx_report.service';
import { EntityModalComponent } from '../modals/entity-modal/entity-modal.component';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-selected-group',
  templateUrl: './selected-group.component.html',
  styleUrls: ['./selected-group.component.scss']
})
export class SelectedGroupComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private msxReportService: MSXReportService,
    public sessionService: SessionService) { }
  selectedGroup = "";
  @Input() entities = [];
  ngOnInit(): void {
    setTimeout(() => {
      this.selectedGroup = localStorage.getItem("selectedGroupName");
    }, 3000);

    this.msxReportService.groupChangeNotifier.subscribe(
      data => {
        this.selectedGroup = localStorage.getItem("selectedGroupName");
      }
    );



    this.selectedGroup = localStorage.getItem("selectedGroupName");
    // this.msxReportService.GetEntity().subscribe(
    //   (data) => {
    //     this.entities = data[0].attributes.data.groups;
    //   },
    //   (err) => {
    //     //this.snackbar.show("error occurred please try again later.", "danger");
    //   }
    // );
  }

  public getEntity() {
    const modalRef = this.modalService.open(EntityModalComponent, { size: "lg", backdrop: "static" });
    modalRef.componentInstance.entities = this.entities;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.selectedGroup) {
        this.entities.forEach(element => {
          if (element.groupid === receivedEntry.selectedGroup) {
            this.sessionService.activeCareTeam = this.entities[0].defaultCareTeam;
            localStorage.setItem("selectedGroupName", element.name);
            this.selectedGroup = element.name;
          }
        });
      }
    });
  }
}
