import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../services/auth.service";
import { SessionService } from "../../services/session.service";
import { UsersService } from "../../services/users.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { TwoFactorAuthSetupComponent } from "../two-factor-auth-setup/two-factor-auth-setup.component";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { PatientService } from "../../services/patients.service";
import { Helper } from "../../providers/helper";

@Component({
    selector: "app-user-settings-modal",
    templateUrl: "./user-settings-modal.component.html",
    styleUrls: ["./user-settings-modal.component.css"],
})
export class UserSettingsModalComponent implements OnInit {
    @Output() readonly Response = new EventEmitter<any>();
    url: any = "assets/images/no-image.png";
    userSettingsForm: UntypedFormGroup;
    fileToUpload: any;
    filename: any;
    disabledUpdate = true;
    imageURL: string;
    filedata: string;
    isInvalidImage: boolean = false;
    public user: any = {};
    // public firstNameControl = new FormControl("");
    // public lastNameControl = new FormControl("");
    // public emailControl = new FormControl("");
    // public titleControl = new FormControl("");
    public showTitle = false;

    constructor(
        private activeModal: NgbActiveModal,
        public userService: UsersService,
        public sessionService: SessionService,
        public snackbar: SnackbarService,
        public authService: AuthService,
        private modalService: NgbModal,
        private patientService: PatientService,
        private fb: UntypedFormBuilder,
        private helper: Helper
    ) {}

    ngOnInit(): void {
        this.userSettingsForm = this.fb.group({
            title: [this.sessionService.currentUserSettings.title],
            firstname: [this.sessionService.currentUserSettings.firstname],
            lastname: [this.sessionService.currentUserSettings.lastname], //, [Validators.pattern('[a-zA-Z0-9 "]*')]
            email: [this.sessionService.currentUser.attributes.email],
        });
        if (this.sessionService.currentUserSettings.profilepic !== "") {
            this.url = this.sessionService.currentUserSettings.profilepic;
        }
        if (this.sessionService.currentUser.attributes.roles.includes("superProvider") || this.sessionService.currentUser.attributes.roles.includes("provider")) {
            this.showTitle = true;
        }
    }

    public onSave() {
        this.userSettingsForm.markAllAsTouched();
        if (this.userSettingsForm.valid) {
            const data: any = {};
            data.firstname = this.helper.undefinedIfEmpty(this.userSettingsForm.get("firstname").value);
            data.lastname = this.helper.undefinedIfEmpty(this.userSettingsForm.get("lastname").value);
            data.title = this.helper.undefinedIfEmpty(this.userSettingsForm.get("title").value);
            this.patientService.updateUserSettings(data, this.sessionService.currentUser.id).subscribe(
                (_data) => {
                    this.sessionService.currentUserSettings.firstname = data.firstname;
                    this.sessionService.currentUserSettings.lastname = data.lastname;
                    this.sessionService.currentUserSettings.title = data.title;

                    if (this.fileToUpload != null) {
                        this.uploadPic();
                    } else {
                        this.snackbar.show("Settings updated successfully", "success");
                        this.activeModal.close();
                    }
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }
    }
    isFieldValid(key) {
        return this.userSettingsForm.get(key).invalid && this.userSettingsForm.get(key).touched;
    }

    public onCancel() {
        this.activeModal.close();
    }
    public onError() {
        this.url = "assets/images/no-image.png";
    }
    // Image Preview
    showPreview(event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.fileToUpload = file;
                this.filename = file.name;
                this.filedata = reader.result.toString().substr(reader.result.toString().indexOf(",") + 1);
                this.url = reader.result as string;
                this.isInvalidImage = false;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    }
    public uploadPic() {
        if (this.fileToUpload != null) {
            let file = this.fileToUpload;
            let _url = environment.uri + "/user/uploadprofilepic";
            let token = `Bearer ${this.sessionService.currentUser.attributes.token}`;
            let reader = new FileReader();
            reader.onloadend = () => {
                let formData = new FormData();
                let imgBlob = new Blob([reader.result], { type: file.type });
                formData.append("file", imgBlob, file.name);
                let req = new XMLHttpRequest();
                req.open("POST", _url, true);
                req.setRequestHeader("Authorization", token);
                req.onload = () => {
                    let res = JSON.parse(req.response);
                    if (res.data && res.data[0] && res.data[0].attributes) {
                        return this.userService.getUserSetting(this.sessionService.currentUser.id).subscribe(
                            (settingsData) => {
                                this.sessionService.currentUserSettings = settingsData[0].attributes;
                                // Update profile image
                                let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_PROFILE_IMAGE", { detail: { reload: true } });
                                document.dispatchEvent(updateUnreadMessagesCountEvent);

                                this.snackbar.show("Settings updated successfully", "success");
                                this.activeModal.close();
                            },
                            (err) => {
                                this.snackbar.show(err[0].detail, "danger");
                            }
                        );
                    } else {
                        if (res.body !== null && typeof res.body !== "undefined") {
                            let errBody = JSON.parse(res.body);

                            if (errBody.errors.length > 0) {
                            }
                        }
                    }
                };
                req.onerror = () => {
                    let res = JSON.parse(req.response);
                    if (res.body !== null && typeof res.body !== "undefined") {
                        let errBody = JSON.parse(res.body);
                        if (errBody.errors.length > 0) {
                        }
                    }
                    let err: any = "";
                    try {
                        err = JSON.stringify(req);
                    } catch (e) {
                        console.log(e);
                        err = req;
                    }
                };
                req.send(formData);
            };

            reader.readAsArrayBuffer(file);
        }
    }
    getUserInitials() {
        return this.sessionService.currentUser?.attributes.email;
    }

    public onEnableTwoFactorAuth() {
        this.authService.getTwoFactorCode().subscribe((data) => {
            const modalRef = this.modalService.open(TwoFactorAuthSetupComponent, { backdrop: "static" });
            modalRef.componentInstance.CodeData = data;
            modalRef.componentInstance.Response.subscribe((res) => {
                this.modalResponse(res);
                this.user = res.user;
            });
        });
    }
    public onDisableTwoFactorAuth() {
        const modalRef = this.modalService.open(TwoFactorAuthSetupComponent, { backdrop: "static" });
        modalRef.componentInstance.Action = "disable";
        modalRef.componentInstance.Response.subscribe((res) => {
            this.modalResponse(res);
            this.user = res.user;
        });
    }
    public modalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
}
