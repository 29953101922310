import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TextEditorModalComponent } from "../MSX/modals/campaign/text-editor-modal/text-editor-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "../../../app/services/session.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CampaignService } from "../../../app/services/campaign.service";
import { SnackbarService } from "../../../app/shared/snackbar/snackbar.service";
import { MSXReportService } from "../../../app/services/msx_report.service";
import { WarningModalComponent } from "../../../app/modals/warning-modal/warning-modal.component";
import { SortableHeaderDirective } from "../../../app/directives/sortable.directive";
import { Observable } from "rxjs";
import { TableService } from "../../../app/services/table.service";

@Component({
    selector: "app-campaign",
    templateUrl: "./campaign.component.html",
    styleUrls: ["./campaign.component.scss"],
})
export class CampaignComponent implements OnInit {
    @ViewChild("navCustom") nav;
    counter = 1;
    active = "tab1";
    sendDate = "";
    timeDuration = "AM";
    sendTime = "1:00";
    dateError = false;
    practices = [];
    practiceDropdownSettings: any = {};
    selected_practice = [];
    selectedPractice = [];
    inValidEmail = true;
    campaignStatus = "";
    memberList = [];
    selectedMembers = [];

    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    displayedColumns = [
        { displayname: "Send List", columnname: "name" },
        { displayname: "Description", columnname: "description" },
        { displayname: "Members", columnname: "member_count" },
    ];

    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private formBuilder: UntypedFormBuilder,
        private campaignService: CampaignService,
        private snackbar: SnackbarService,
        private router: Router,
        private msxReportService: MSXReportService,
        public tableService: TableService
    ) {
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
        this.tableService.searchOnColumn = "";
        this.tableService.pageSize = 10;
        this.tableService.searchTerm = "";
    }
    campaignForm: UntypedFormGroup;
    selectedTemplate: any;
    submitted = false;
    previewType = "desktop";
    selectedGroup = localStorage.getItem("selectedGroupName") || "";
    showLoader = false;
    campaign: any;
    campaignId = "";
    compaignName = "";
    testEmail = "";
    groupDetails: any;
    entities = [];
    groupEmail = "contact@anywheremedical.com";
    minDate = "";
    ngOnInit(): void {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const dd = String(today.getDate()).padStart(2, "0");
        // Set the min attribute to today's date
        this.minDate = `${yyyy}-${mm}-${dd}`;

        this.selectedTemplate = this.sessionService.getCampaignTempale();
        this.campaignId = this.route.snapshot.queryParamMap.get("id") || "";
        this.campaignForm = this.formBuilder.group({
            campaignName: ["", Validators.required],
            subject: [this.selectedTemplate === null ? "" : this.selectedTemplate.subject, Validators.required],
            reply: ["", Validators.required],
            preheader: "",
            campaignId: null,
        });
        this.getGroups();
        this.getGroupLogo();
        if (this.campaignId != null && this.campaignId !== "") {
            this.getCampaign();
        } else {
            this.getPractice();
        }
        this.practiceDropdownSettings = {
            singleSelection: false,
            idField: "practiceid",
            textField: "name",
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true,
        };
        this.getMembers();
    }

    async getGroups() {
        return new Promise((resolve, reject) => {
            this.msxReportService.GetEntity().subscribe(
                (data) => {
                    this.entities = data[0].attributes.data.groups;
                    this.groupEmail = this.entities.filter((item) => item.groupid.toLocaleLowerCase() == localStorage.getItem("selectedGroup").toLocaleLowerCase())[0].campaignreplyemail;
                    if (this.campaignId == "" || this.campaignId == null) {
                        this.campaignForm.patchValue({ reply: this.groupEmail });
                    }

                    resolve(this.entities);
                },
                (err) => {
                    reject();
                }
            );
        });
    }
    getCampaign() {
        this.campaignService.getCampaignById(this.campaignId).subscribe(
            (data) => {
                this.campaign = data[0].attributes;
                this.campaignStatus = this.campaign.status.toLocaleLowerCase();
                if (this.campaign.send_datetime !== undefined && this.campaign.send_datetime !== null) {
                    const dt = new Date(this.campaign.send_datetime);
                    const yyyy = dt.getFullYear();
                    const mm = String(dt.getMonth() + 1).padStart(2, "0"); // Months are 0-based
                    const dd = String(dt.getDate()).padStart(2, "0");
                    const date = `${yyyy}-${mm}-${dd}`;
                    let hours = dt.getHours();
                    const amPm = hours >= 12 ? "PM" : "AM";
                    hours = hours % 12;
                    if (hours === 0) hours = 12; // Convert 0 to 12 (midnight/noon)
                    this.sendDate = date;
                    this.sendTime = hours.toString() + ":00";
                    this.timeDuration = amPm;
                }
                if (this.campaign.practice_ids !== null) {
                    this.selected_practice = this.campaign.practice_ids.split(",");
                    this.selectedPractice = this.campaign.practice_ids.toString().split(",");
                }
                this.getPractice();
                this.compaignName = this.campaign.campaign_name;
                this.selectedTemplate = this.campaign;
                this.testEmail = this.selectedTemplate.test_email;
                this.campaignForm = this.formBuilder.group({
                    campaignName: [this.selectedTemplate.campaign_name, Validators.required],
                    subject: [this.selectedTemplate.subject, Validators.required],
                    reply: [this.selectedTemplate.reply_email, Validators.required],
                    preheader: [this.selectedTemplate.preheader],
                    campaignId: this.selectedTemplate.campaign_id.toLocaleLowerCase(),
                });
                if (this.campaignId != null && this.campaignId !== "") {
                    this.campaignForm.patchValue({ reply: this.campaign.reply_email });
                }
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    getMembers() {
        debugger;
        this.showLoader = true;
        this.campaignService.getMembers().subscribe(
            (data) => {
                debugger;
                this.memberList = data[0].attributes.list.filter((e) => e.member_group_id !== undefined);
                if (this.campaign?.member_group_ids !== undefined && this.campaign?.member_group_ids != "") {
                    this.selectedMembers = this.campaign.member_group_ids.split(",");
                    this.memberList.forEach((m) => {
                        m.selected = this.selectedMembers.includes(m.member_group_id) ? true : false;
                    });
                }

                this.tableService.DATA = this.memberList;
                this.tableService.page = 1;
                this.showLoader = false;
                this.sorting("member_count", "desc");
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    get form() {
        return this.campaignForm.controls;
    }

    submitForm() {
        this.submitted = true;
        this.inValidEmail = false;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.campaignForm.value.reply).toLowerCase())) {
            // this.snackbar.show("A valid email is required.", "danger");
            this.inValidEmail = true;
            return false;
        }
        if (this.campaignForm.valid) {
            this.showLoader = true;
            this.campaignService.save(this.campaignForm.value, this.selectedTemplate, this.selectedGroup, this.selectedPractice).subscribe(
                (data) => {
                    this.showLoader = false;
                    this.snackbar.show(data[0].attributes.message, "success");
                    this.campaignId = data[0].attributes.data.campaign_id;
                    this.campaignForm.patchValue({ campaignId: this.campaignId });
                    this.selectedTemplate.campaign_id = this.campaignId;
                    this.compaignName = this.campaignForm.value.campaignName;
                    this.active = "tab2";
                    this.nav.select("tab2");
                },
                (err) => {
                    this.showLoader = false;
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }
    }

    openEditor() {
        const modalRef = this.modalService.open(TextEditorModalComponent, { backdrop: "static" });
        modalRef.componentInstance.campaignText = this.selectedTemplate.html_template;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.selectedTemplate.html_template = receivedEntry.htmlContent;
            }
        });
    }

    setPreviewType(type) {
        this.previewType = type;
    }

    sendTestMail() {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.testEmail.trim()).toLowerCase())) {
            this.snackbar.show("A valid email is required.", "danger");
            return false;
        }
        this.showLoader = true;
        this.selectedTemplate.test_email = this.testEmail;
        this.campaignForm.value.campaignId = this.campaignId;
        this.campaignService.save(this.campaignForm.value, this.selectedTemplate, this.selectedGroup, this.selectedPractice).subscribe(
            (data) => {
                this.showLoader = false;
                this.campaignService.sendMail(this.selectedTemplate, "test", this.testEmail).subscribe(
                    (data) => {
                        this.snackbar.show(data[0].attributes.message, "success");
                    },
                    (err) => {
                        this.showLoader = false;
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    // next() {
    //   this.nav.select('tab' + this.counter++);
    // }
    // previous() {
    //   this.nav.select('tab' + this.counter--);
    // }

    sendNow() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to send mails now?";
        modalRef.componentInstance.OKButton = "Confirm";
        modalRef.componentInstance.ShowCheckBox = true;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed && receivedEntry.sendBulkMail) {
                this.showLoader = true;
                this.snackbar.show("Sending...", "success");
                this.campaignService.sendMail(this.selectedTemplate, "all", this.testEmail).subscribe(
                    (data) => {
                        this.showLoader = false;
                        this.router.navigate(["/patientoutreach"]);
                    },
                    (err) => {
                        this.showLoader = false;
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
            /*if (receivedEntry.proceed && !receivedEntry.sendBulkMail) {
        this.sendTestMail()
      }*/
        });
    }

    validateDatetime(input) {
        const inputDate = new Date(input);
        if (isNaN(inputDate.getTime())) {
            return false;
        }
        const now = new Date();
        if (inputDate.getTime() <= now.getTime()) {
            return false;
        }
        return true;
    }

    scheduleMail() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to schedule this campaign?";
        modalRef.componentInstance.OKButton = "Confirm";
        modalRef.componentInstance.ShowCheckBox = true;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed && receivedEntry.sendBulkMail) {
                this.dateError = false;
                let sendDate = new Date();
                if (this.sendDate == "") {
                    this.dateError = true;
                } else {
                    this.showLoader = true;
                    sendDate = new Date(this.sendDate + " " + this.sendTime + " " + this.timeDuration);
                    if (!this.validateDatetime(sendDate)) {
                        this.showLoader = false;
                        this.snackbar.show("The date and time must be in the future.", "danger");
                        return false;
                    } else {
                        sendDate = new Date(sendDate.getFullYear(), sendDate.getMonth(), sendDate.getDate(), sendDate.getHours(), sendDate.getMinutes(), sendDate.getSeconds());
                        this.selectedTemplate.send_datetime = sendDate.toISOString();
                        this.selectedTemplate.status = "Scheduled";
                        this.campaignService.save(this.campaignForm.value, this.selectedTemplate, this.selectedGroup, this.selectedPractice).subscribe(
                            (data) => {
                                this.showLoader = false;
                                this.snackbar.show(data[0].attributes.message, "success");
                                this.campaignStatus = "scheduled";
                            },
                            (err) => {
                                this.showLoader = false;
                                this.snackbar.show(err[0].detail, "danger");
                            }
                        );
                    }
                }
            }
            if (receivedEntry.proceed && !receivedEntry.sendBulkMail) {
                this.sendTestMail();
            }
        });
    }

    undo() {
        this.router.navigate(["/patientoutreach"]);
    }

    getGroupLogo() {
        this.campaignService.getLogo().subscribe(
            (data) => {
                this.groupDetails = data[0].attributes;
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    changeTab(tab = "tab1") {
        if (this.campaignId == "") {
            this.nav.select("tab1");
            this.snackbar.show("Please create a valid campaign before sending test mail.", "danger");
        } else {
            /*if (tab == "tab3" && this.testEmail == "") {
        this.snackbar.show("Please provide vaild test mail.", "danger");
        this.nav.select("tab2");
      }
      else {*/
            this.nav.select(tab);
            //}
        }
    }

    getPractice() {
        this.showLoader = true;
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        this.msxReportService.GetEntity(selectedGroup, "").subscribe(
            (data) => {
                this.showLoader = false;
                this.practices = data[0].attributes.data.practices;
                let _practices = [];
                this.selected_practice.forEach((element) => {
                    this.practices.forEach((p) => {
                        if (p.practiceid == element) {
                            _practices.push(p);
                        }
                    });
                });
                this.selected_practice = _practices;
                this.selected_practice = this.practices.filter((item) => this.selected_practice.includes(item));

                debugger;
                if (this.campaign !== undefined && this.campaign.reply_email == "" && this.selected_practice.length == 1) {
                    this.campaignForm.patchValue({ reply: this.selected_practice[0].campaignreplyemail });
                } else {
                    if (this.campaign === undefined) {
                        this.campaignForm.patchValue({ reply: this.groupEmail });
                    }
                }
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    onItemDeSelect(item: any) {
        this.selectedPractice.forEach((element, index) => {
            if (element === item.practiceid) {
                this.selectedPractice.splice(index, 1);
            }
            if (this.selectedPractice.length == 1) {
                let practice = this.practices.filter((p) => p.practiceid == this.selectedPractice[0]);
                this.campaignForm.patchValue({ reply: practice[0].campaignreplyemail });
            } else {
                this.campaignForm.patchValue({ reply: this.groupEmail });
            }
        });
    }

    onItemSelect(item: any, type) {
        let practice_found = this.selectedPractice.find((y) => y.name == item.name);
        if (!practice_found) {
            let practice = this.practices.filter((p) => p.practiceid == item.practiceid);
            this.selectedPractice.push(item.practiceid);
            if (this.selectedPractice.length == 1) {
                this.campaignForm.patchValue({ reply: practice[0].campaignreplyemail });
                this.inValidEmail = false;
            } else {
                this.campaignForm.patchValue({ reply: this.groupEmail });
                this.inValidEmail = false;
            }
        }
    }

    cancelCampaign(status) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel this schedule?";
        modalRef.componentInstance.OKButton = "Confirm";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.showLoader = true;
                debugger;

                this.selectedTemplate.status = status;
                this.selectedTemplate.send_datetime = null;
                this.campaignService.save(this.campaignForm.value, this.selectedTemplate, this.selectedGroup, this.selectedPractice).subscribe(
                    (data) => {
                        this.showLoader = false;
                        this.snackbar.show(data[0].attributes.message, "success");
                        this.campaignStatus = "";
                        this.sendDate = "";
                        this.timeDuration = "AM";
                        this.sendTime = "1:00";
                    },
                    (err) => {
                        this.showLoader = false;
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }

    onSort({ column, direction }: any) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction);
    }

    sorting(column, direction) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.page = 1;
    }

    selectMember(e, member) {
        debugger;
        if (e.target.checked && !this.selectedMembers.includes(member.member_group_id)) {
            this.selectedMembers.push(member.member_group_id);
        } else {
            this.selectedMembers.splice(this.selectedMembers.indexOf(member.member_group_id), 1);
        }
        if (this.selectedMembers.length) {
            this.selectedTemplate.member_group_ids = this.selectedMembers.toString();
        }
        this.memberList.forEach((m) => {
            m.selected = this.selectedMembers.includes(m.member_group_id) ? true : false;
        });
    }
}
