import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-password-change-modal",
    templateUrl: "./password-change-modal.component.html",
    styleUrls: ["./password-change-modal.component.scss"],
})
export class PasswordChangeModalComponent {
    @Output() readonly Response = new EventEmitter<any>();
    @Input() PasswordExpired = false;

    errorMessage: string = "";
    passwordForm = this.fb.group({
        //  email: ["", Validators.required],
        newPassword: ["", Validators.required],
        oldPassword: ["", Validators.required],
        confirmPassword: ["", Validators.required],
    });

    constructor(private fb: UntypedFormBuilder, private sessionService: SessionService, private userService: UsersService, private activeModal: NgbActiveModal) { }

    public onSave() {
        this.errorMessage = "";
        let data = this.passwordForm.getRawValue();
        let user = this.sessionService.currentUser;
        data.email = user.attributes.email
        if (this.passwordForm.valid) {
            if (this.passwordForm.value.newPassword != this.passwordForm.value.confirmPassword) {
                this.errorMessage = "New password and confirm password do not match.";
                return;
            }
            else {
                this.userService.changePassword(data, user.id).subscribe(
                    (data) => {
                        this.Response.emit({ success: true, message: "Password changed!" });
                        this.activeModal.close();
                    },
                    (error) => {
                        if (error.constructor === Array) {
                            this.errorMessage = error[0].detail;
                        } else {
                            this.errorMessage = error.message;
                        }
                    }
                );
            }

        }
        else {
            this.errorMessage = "All fields are mandatory.";
        }

    }

    public onCancel() {
        this.activeModal.close();
    }
}
