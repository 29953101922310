import { Component, OnInit } from "@angular/core";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { MSXReportService } from "../../services/msx_report.service";
import * as _ from 'underscore';
import { SessionService } from "../../services/session.service";
import * as __ from 'lodash';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FavoritesModalComponent } from '../MSX/modals/favorites-modal/favorites-modal.component';
import { ScheduleReportModalComponent } from "../MSX/modals/schedule-report-modal/schedule-report-modal.component";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    metrics = [];
    metric = [];
    tabOrderSettings: any;
    current_active = "survey";
    selectedGroup = "";
    surveys = {
        metricGroups: [],
        metrics: []
    };
    metricList: any;
    selectedSurvey = "B153A8F4-96A2-46FE-9228-61BC627A33FA";
    metricsGroup = [];
    showMsg = false;
    showLoader = true;
    additionalsettings: any;
    favoriteList: any;
    showDeleteAll = true;
    showRestore = true;
    constructor(
        private msxReportService: MSXReportService,
        private snackbar: SnackbarService,
        public sessionService: SessionService,
        private modalService: NgbModal
    ) { }
    ngOnInit(): void {

        let _time = 0;
        this.selectedGroup = localStorage.getItem("selectedGroup") || ""
        if (this.selectedGroup == "") {
            _time = 3000;
        }
        setTimeout(() => {
            this.loadGraphs();
        }, _time);





    }

    loadGraphs() {
        this.msxReportService.getFavoriteList().subscribe(
            (data) => {
                let dashboardgraphtype = _.findWhere(data[0].type, { settingname: 'dashboardgraphtype' })
                if (dashboardgraphtype !== undefined && dashboardgraphtype.settingvalue == "none") {
                    this.showDeleteAll = false;
                    this.showRestore = true;
                    this.showLoader = false;
                    this.favoriteList = [];
                }
                else if (dashboardgraphtype !== undefined && dashboardgraphtype.settingvalue == "default") {
                    this.showDeleteAll = true;
                    this.showRestore = false;
                    this.getMetricsList();
                }
                else {
                    let setting = _.findWhere(data[0].type, { settingname: 'additionalsettings' })
                    if (setting !== undefined && (setting.settingvalue !== "{}" || setting.settingvalue !== "[]")) {
                        if (setting) {
                            this.additionalsettings = JSON.parse(setting.settingvalue) || [];
                            this.favoriteList = _.filter(this.additionalsettings, (f) => f.graphName === "survey");
                            this.showLoader = false;
                            this.sessionService.favoritesGraph = this.favoriteList;
                        }
                        if (this.favoriteList.length === 0) {
                            this.favoriteGraph();
                        }
                    }
                    else {
                        this.getMetricsList();
                    }
                }

            },
            (err) => {
                this.showLoader = false;

                // this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    refreshMetricsList() {
        this.favoriteGraph();
    }

    showGraph(active_tab = "") {
        this.current_active = active_tab;
        this.activeGraph();

    }

    favoriteGraph() {
        this.selectedGroup = localStorage.getItem("selectedGroup") || ""
        if (this.selectedGroup !== "") {
            let favorite_graphs = this.sessionService.favoritesGraph || [];
            favorite_graphs = _.filter(favorite_graphs, {
                selectedGroup
                    : this.selectedGroup
            })
            if (favorite_graphs.length == 0) {
                this.getMetricsList();
            }
            if (favorite_graphs != undefined && favorite_graphs.length) {
                favorite_graphs.forEach(function (element) {
                    element.isFavorite = null;
                });

                this.metrics = favorite_graphs;

            }
            else {
                this.metrics = [];
            }
        }
    }

    public getDashboardModules(name = "") {
        if (this.sessionService.currentUser.attributes.msxInfo !== undefined &&
            this.sessionService.currentUser.attributes.msxInfo.dashboardModules !== undefined) {
            return this.sessionService.currentUser.attributes.msxInfo.dashboardModules.includes(name)
        }
        else {
            return true;
        }
    }

    public getMetricsList() {
        this.msxReportService.getMetricsList().subscribe(
            (data) => {
                this.surveys.metricGroups = data[0].attributes.metricGroups;
                this.surveys.metrics = data[0].attributes.metrics;
                this.metricList = this.surveys.metrics;
                if (this.metricList == 0) {
                    this.metrics = []
                    this.showMsg = true
                    this.showLoader = false;
                    return;
                }
                const coreSurvey = _.findWhere(this.surveys.metricGroups, { surveyname: 'Core' })
                if (coreSurvey === undefined) {
                    this.selectedSurvey = this.surveys.metricGroups[0].surveyid;
                }
                else {
                    this.selectedSurvey = coreSurvey.surveyid;
                }
                this.getSurvey();

            },
            (err) => {
                this.showLoader = false;
                // this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    public getSurvey() {
        let metric = _.filter(this.metricList, {
            surveyid: this.selectedSurvey
        });
        this.surveys.metrics = [];
        metric[0].list.forEach(element => {
            element.metrics.forEach(m => {
                this.surveys.metrics.push(m);
            });
        });
        this.metricsGroup = [];
        this.surveys.metrics.forEach(element => {
            this.metricsGroup.push(
                {
                    surveyid: element.surveyid,
                    metricid: element.metricid,
                    metricname: element.metricname,
                    metricgroupid: element.metricgroupid,
                    metricgroupname: element.metricgroupname,
                    name: element.metricname,
                    isFavorite: "no",

                }
            )
        });
        if (this.favoriteList === undefined) {
            this.favoriteList = [];
        }
        this.metricsGroup = __.orderBy(this.metricsGroup, 'metricname', 'asc')

        this.metrics = this.metricsGroup.slice(0, 2);

        this.showLoader = false;
    }


    activeGraph() {
        this.favoriteList = [];
        this.msxReportService.getFavoriteList().subscribe(
            (data) => {
                let value = _.find(data[0].type, { settingname: 'additionalsettings' });
                if (value != undefined) {
                    this.favoriteList = JSON.parse(value.settingvalue);
                    this.favoriteList = _.filter(this.favoriteList, {
                        graphName
                            : this.current_active
                    })
                }
            },
            (err) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }
    delete(graph) {
        const modalRef = this.modalService.open(FavoritesModalComponent, { backdrop: "static" });
        modalRef.componentInstance.heading = "Remove Favorite";
        modalRef.componentInstance.msg = "Are you sure to remove these settings from favorites?";
        modalRef.componentInstance.name = graph.name;
        modalRef.componentInstance.delete = true;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.favoriteList = _.filter(this.favoriteList, (obj) => {
                return obj.graphId !== graph.graphId;
            });
            if (this.favoriteList.length == 0) {
                setTimeout(() => {
                    this.loadGraphs();
                }, 1000);
            }
            this.updateGraphs(graph.graphId);
        });
    }

    public updateGraphs(graphId) {
        this.msxReportService.updateFavoriteList(graphId).subscribe(
            (data) => {
                this.snackbar.show("Favourite deleted successfully.", "success");
            },
            (err) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    public scheduleReport() {
        const modalRef = this.modalService.open(ScheduleReportModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {

        });
    }

    deleteAll() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete all favourite graphs?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.msxReportService.deleteAllGraph().subscribe(
                    (data) => {
                        this.loadGraphs();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }


    restoreDefault() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to restore default graph?";
        modalRef.componentInstance.OKButton = "Restore";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.msxReportService.deleteAllGraph("restore").subscribe(
                    (data) => {
                        this.loadGraphs();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
}
