<div class="filter-section">
    <p class="header-text">This functionality allows administrators to define specific words that will not be allowed in chat</p>
    <div class="filter-section-left">
        <i style="cursor: pointer; font-size: 20px" class="fa fa-redo-alt left-chevron" (click)="onRefresh()"></i
        ><input class="filter-input" [(ngModel)]="filterText" name="searchTerm" [(ngModel)]="tableService.searchTerm" placeholder="Search..." />
    </div>
    <div class="filter-section-right">
        <button (click)="newWord()" class="float-right btn-primary btn"><i class="fas fa-plus-circle"></i>&nbsp;Add Word</button>
    </div>
    <app-loader style="position: absolute; top: 200px; right: 45%; z-index: 100" class="loader" *ngIf="showLoader"></app-loader>
</div>
<div *ngIf="!showLoader" class="container-fluid">
    <div class="table-badfilter">
        <table class="table table-striped datatable">
            <thead>
                <tr>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns" sortable="{{ c.columnname }}" (sort)="onSort($event)">
                        {{ c.displayname }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of resultList$ | async">
                    <td style="vertical-align: middle; max-width: 200px">
                        <ngb-highlight [result]="p.badword" [term]="tableService.searchTerm"></ngb-highlight>
                    </td>
                    <td style="vertical-align: middle; max-width: 200px">
                        <i class="fas fa-times mr-3 cursor-pointer" (click)="onDelete(p)" title="Delete"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page" [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

            <select class="custom-select pagination" style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important" [(ngModel)]="tableService.pageSize">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>
</div>

<div *ngIf="error" class="error-block">
    <p class="text-danger">{{ error }}</p>
</div>
