<div class="roomname" *ngIf="roomName"><i style="cursor: pointer" [routerLink]="['/waiting-room']"
        class="fas fa-chevron-left left-chevron"></i>{{ roomName | uppercase }} REFERRALS</div>

<div class="filter-section">
    <div class="filter-section-left" style="display: flex;">
        <i style="cursor: pointer; font-size: 20px" class="fa fa-redo-alt left-chevron" (click)="onRefresh()"></i><input
            style="margin-left:-2px" class="filter-input" [(ngModel)]="tableService.searchTerm"
            placeholder="Search..." />
        <div *ngIf="showLoader" class="ml-2 h-100 d-flex flex-column justify-content-center align-items-center">
            <div style="height: 24px;width: 24px;" class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="filter-section-right" *ngIf="patients?.length && roomName">
        <button (click)="$event.stopPropagation(); onViewOptions()" class="float-right btn-line mb-1"><i
                class="fas fa-list-alt"></i> View Options</button>
    </div>
</div>

<div *ngIf="patients?.length" class="container-fluid">
    <table class="table table-striped datatable">
        <thead>
            <tr>
                <th [ngClass]="c === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                    sortable="{{ c === 'actions' ? '' : c }}" (sort)="onSort($event)">
                    {{ c === "chiefcomplaint" ? "Reason for Visit" : c }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of resultList$ | async">
                <td class="cursor-pointer" *ngFor="let c of displayedColumns; let i = index" class="cursor-pointer">
                    <span (click)="onPatientDashboard(p)" *ngIf="c === 'requestdate'">
                        {{ p[c] | date: "MM/dd/yyyy, hh:mm a" }}
                    </span>
                    <span (click)="onPatientDashboard(p)" *ngIf="c === 'age'">
                        {{ p[c] === 0 ? "" : p[c] }}
                    </span>
                    <span (click)="onPatientDashboard(p)"
                        *ngIf="c === 'totelerx' || c === 'fromtelerx' || c === 'status'">
                        {{ p[c] | uppercase }}
                    </span>
                    <span (click)="onPatientDashboard(p)"
                        *ngIf="c !== 'requestdate' && c !== 'status' && c !== 'fromtelerx' && c !== 'totelerx'"
                        [innerHTML]="p[c]">
                    </span>
                    <span class="actions-cell" *ngIf="c === 'actions'">
                        <i *ngIf="roomName === 'incoming' || p.status === 'incoming'"
                            class="fas fa-check mr-3 cursor-pointer" (click)="onAccept(p)" title="Accept Patient"></i>
                        <i *ngIf="roomName === 'incoming' || p.status === 'incoming'"
                            class="fas fa-times mr-3 cursor-pointer" (click)="onCancel(p)" title="Reject Patient"></i>
                        <i *ngIf="(roomName === 'outgoing' || p.status === 'outgoing') && p.status !== 'Pending Patient Selection'"
                            class="fas fa-times mr-3 cursor-pointer" (click)="onCancel(p)" title="Cancel Referral"></i>
                        <i *ngIf="p.notes || p.referralnotes" class="fas fa-sticky-note mr-3 cursor-pointer"
                            (click)="onNotes(p)" title="View Notes"></i>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="patients?.length > 0" class="d-flex justify-content-between p-2">
        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
            [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

        <select class="custom-select pagination"
            style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
            [(ngModel)]="tableService.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
        </select>
    </div>
</div>

<div class="no-record" *ngIf="roomName !== '' && (!patients || patients.length === 0)">No records found</div>
<!-- <app-loader class="loader" *ngIf="showLoader"></app-loader> -->