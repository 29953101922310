<div class="modal-body">
    <form [formGroup]="moveToForm">
        <div class="form-group">
            <label>Move to</label>
            <select [ngClass]="{ error: isFieldValid('towrid') }" formControlName="towrid" required
                class="custom-select form-control">
                <option value="">Please Select</option>
                <option *ngFor="let room of PatientData.waitingRooms" [value]="room.wrid">{{ room.roomname }}</option>
            </select>
        </div>

        <div class="form-group">
            <label>Notes</label>
            <textarea style="resize: none" [ngClass]="{ error: isFieldValid('notes') }" formControlName="notes"
                type="text" class="form-control"></textarea>
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
        <div class="form-group">
            <button type="button" class="btn btn-primary move-buttons" (click)="onSave()">Move</button>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onResolve()">Resolve</button>
        </div>
        <!-- <div class="form-group">
                <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onCancel()">Schedule Follow-up</button>
            </div> -->
        <!-- <div class="form-group">
            <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onRefer()">Refer</button>
        </div> -->
        <div class="form-group">
            <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onCancel()">Cancel</button>
        </div>
    </form>
</div>