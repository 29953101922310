<div class="container-fluid">
    <div class="row mb-2">
        <div class="col-3">
            <div class="form-check">
                <input [(ngModel)]="keywords.escalate_scores" class="form-check-input" id="escalate_scores"
                    type="checkbox">
                <label class="form-check-label" for="escalate_scores">Escalate scores below:</label>
            </div>
        </div>
        <div class="col-8 row">
            <div class="col-3">
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        class="form-check-input" id="9" name="score" value="9">
                    9
                    <label class="form-check-label" for="9"></label>
                </div>
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        class="form-check-input" id="8" name="score" value="8">8
                    <label class="form-check-label" for="8"></label>
                </div>
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" name="score"
                        type="radio" class="form-check-input" id="7" value="7">7 (default)
                    <label class="form-check-label" for="7"></label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        class="form-check-input" id="6" name="score" value="6">
                    6
                    <label class="form-check-label" for="6"></label>
                </div>
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        class="form-check-input" id="5" name="score" value="5">5
                    <label class="form-check-label" for="5"></label>
                </div>
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" name="score"
                        type="radio" class="form-check-input" id="4" value="4">4
                    <label class="form-check-label" for="4"></label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        class="form-check-input" id="3" name="score" value="3">
                    3
                    <label class="form-check-label" for="3"></label>
                </div>
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        class="form-check-input" id="2" name="score" value="2">2
                    <label class="form-check-label" for="2"></label>
                </div>
                <div class="form-check">
                    <input [disabled]="!keywords.escalate_scores" [(ngModel)]="keywords.scores" type="radio"
                        name="score" class="form-check-input" id="1" value="1">1
                    <label class="form-check-label" for="1"></label>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-3">
            <div class="form-check">
                <input [(ngModel)]="keywords.escalate_sentiments" class="form-check-input" type="checkbox"
                    id="escalate_sentiments">
                <label class="form-check-label" for="escalate_sentiments">Escalate sentiments:</label>
            </div>
        </div>
        <div class="col-8 row">
            <div class="col-3">
                <div class="form-check">
                    <input [checked]="keywords.sentiments.includes('anger')" [disabled]="!keywords.escalate_sentiments"
                        (change)="setSentiment($event)" type="checkbox" class="form-check-input" id="anger"
                        value="anger">
                    Anger
                    <label class="form-check-label" for="anger"></label>
                </div>
                <div class="form-check">
                    <input [checked]="keywords.sentiments.includes('disgust')"
                        [disabled]="!keywords.escalate_sentiments" (change)="setSentiment($event)" type="checkbox"
                        class="form-check-input" id="disgust" value="disgust">Disgust
                    <label class="form-check-label" for="disgust"></label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-check">
                    <input [checked]="keywords.sentiments.includes('fear')" [disabled]="!keywords.escalate_sentiments"
                        (change)="setSentiment($event)" type="checkbox" class="form-check-input" id="fear"
                        [checked]="keywords.sentiments.includes('fear')" value="fear">
                    Fear
                    <label class="form-check-label" for="fear"></label>
                </div>
                <div class="form-check">
                    <input [checked]="keywords.sentiments.includes('joy')" [disabled]="!keywords.escalate_sentiments"
                        (change)="setSentiment($event)" type="checkbox" class="form-check-input" id="joy"
                        name="sentiments" value="joy">Joy
                    <label class="form-check-label" for="joy"></label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-check">
                    <input [checked]="keywords.sentiments.includes('sadness')"
                        [disabled]="!keywords.escalate_sentiments" (change)="setSentiment($event)" type="checkbox"
                        class="form-check-input" id="sadness" name="sentiments" value="sadness">
                    Sadness
                    <label class="form-check-label" for="sadness"></label>
                </div>
                <div class="form-check">
                    <input [checked]="keywords.sentiments.includes('surprise')"
                        [disabled]="!keywords.escalate_sentiments" (change)="setSentiment($event)" type="checkbox"
                        class="form-check-input" id="surprise" name="sentiments" value="surprise">Surprise
                    <label class="form-check-label" for="surprise"></label>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-3">
            <div class="form-check">
                <input [(ngModel)]="keywords.escalate_keywords" class="form-check-input" type="checkbox"
                    id="escalate_keywords">
                <label for="escalate_keywords" class="form-check-label">Escalate keywords:</label>
            </div>
        </div>
        <div class="col-8 row">
            <div class="col-9">
                <textarea [disabled]="!keywords.escalate_keywords" [(ngModel)]="keywords.keyword"
                    style="width:100%;height:100px;" rows="5"></textarea>
                <span class="help-block">Separate keywords with a comma.</span>
            </div>
        </div>
    </div>
    <div class="row mb-2 mt-5">
        <div class="col-3">

        </div>
        <div class="col-8 row">
            <div class="col-9">
                <button type="button" (click)="cancel()" class="btn btn-secondary">Cancel</button> &nbsp;&nbsp;
                <button type="button" (click)="saveKeyWords()" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>