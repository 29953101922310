<div class="modal-body">
    <div class="category-header">
        <label>{{ ModalTitle }}</label>
    </div>
    <form [formGroup]="goalForm">
        <div class="main-row">
            <div class="main-left">
                <div class="form-group">
                    <label>Goal Name</label>
                    <input formControlName="goalid" type="hidden" />
                    <input maxlength="255" [ngClass]="{ error: isFieldValid('goalname') }" formControlName="goalname"
                        type="text" required class="form-control" focus="true" />
                </div>

                <div class="form-group">
                    <label>Category</label>
                    <select [ngClass]="{ error: isFieldValid('gdcid') }" formControlName="gdcid" required
                        class="custom-select form-control">
                        <option value="">Please select</option>
                        <option *ngFor="let gc of goalCategories" [value]="gc.gdcid">{{ gc.categoryname }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Associated App</label>
                    <select formControlName="associatedapp" class="custom-select form-control">
                        <option value="">Please select</option>
                        <option *ngFor="let app of userApps" [value]="app.appid">{{ app.title }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>User Response</label>
                    <div style="padding-left: 22px;" class="row types-row"
                        [ngClass]="goaltypeid === '' && isSubmitted ? 'error' : ''">
                        <div class="col-6 types-col custom-control custom-radio"
                            *ngFor="let gt of goalTypes; let i = index">
                            <input [attr.disabled]="isEditing || gt.goaltypesubcaption=='Custom'? 'disabled' : null"
                                (change)="changeGoalType(gt.goaltypeid, gt.goaltypename)"
                                class="input-radio custom-control-input" name="radio-goaltypes" type="radio"
                                id="radio_{{ gt.goaltypeid }}" value="gt.goaltypeid"
                                [checked]="gt.goaltypeid === goaltypeid" />
                            <label style="color: #2d2d2d !important;" class="radio-label custom-control-label"
                                for="radio_{{ gt.goaltypeid }}">
                                {{ gt.goaltypesubcaption }}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div *ngIf="!hideTarget || !hideTarget2" class="row">
                        <div [hidden]="hideTarget" class="col">
                            <label>{{ Target1 }}:</label>
                            <input [ngClass]="{ error: isFieldValid('target1') }" formControlName="target1"
                                type="number" class="form-control" />
                        </div>
                        <div [hidden]="hideTarget2" class="col">
                            <label>{{ Target2 }}:</label>
                            <input [ngClass]="{ error: isFieldValid('target2') }" formControlName="target2"
                                type="number" class="form-control" />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div *ngIf="!hideDataType || !hideDataType2" class="row">
                        <div [hidden]="hideDataType" class="col">
                            <label>{{ Caption1 }}:</label>
                            <input maxlength="25" [ngClass]="{ error: isFieldValid('datatype') }"
                                formControlName="datatype" type="text" class="form-control" />
                        </div>
                        <div [hidden]="hideDataType2" class="col">
                            <label>{{ Caption2 }}:</label>
                            <input maxlength="25" [ngClass]="{ error: isFieldValid('datatype2') }"
                                formControlName="datatype2" type="text" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Notes (Visible only to Care Team)</label>
                    <textarea maxlength="1000" style="resize: none; height: 130px"
                        [ngClass]="{ error: isFieldValid('notes') }" formControlName="notes" type="text"
                        class="form-control"></textarea>
                </div>
            </div>
            <div class="main-right">
                <div class="form-group frequency-body">
                    <label for="frequencytype">Frequency</label>
                    <div class="frequency-block"
                        [ngClass]="(!frequencytype || frequencytype === '') && isSubmitted ? 'error' : ''">
                        <div class="col-4 frequency-left">
                            <div class="row">
                                <div class="col-12 custom-control custom-radio" *ngFor="let type of frequencytypes">
                                    <input (change)="changeFrequency(type.id)" class="custom-control-input"
                                        name="radio-frequencytypes" type="radio" id="radio_{{ type.id }}"
                                        [checked]="type.id === frequencytype" />
                                    <label style="color: #2d2d2d!important;" class="custom-control-label"
                                        for="radio_{{ type.id }}">
                                        {{ type.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-8 frequency-right">
                            <div *ngIf="frequencytype === 'Weekly'">
                                <div class="row" [ngClass]="invalidFrequencies && isSubmitted ? 'error' : ''">
                                    <div class="col-6 custom-control custom-checkbox" *ngFor="let day of frequencies">
                                        <input [checked]="day.selected" [(ngModel)]="day.selected"
                                            [ngModelOptions]="{ standalone: true }" type="checkbox"
                                            class="custom-control-input" id="{{ day.id }}" />
                                        <label style="color: #2d2d2d!important;" class="custom-control-label"
                                            for="{{ day.id }}">{{
                                            day.label }}</label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="frequencytype === 'Monthly' || frequencytype === 'Yearly'">
                                <div class="row frequency-header">
                                    <div class="col-5">
                                        <div class="margin-0">
                                            <label>Day:</label>
                                        </div>
                                    </div>
                                    <div class="col-5" *ngIf="frequencytype === 'Yearly'">
                                        <div class="margin-0">
                                            <label>Month:</label>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <div class="margin-0">
                                            <i (click)="addNewSchedule()" class="fa fa-plus-circle icons-goal-add"
                                                aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="schedule-dates" *ngFor="let schedule of scheduletimes; let i = index">
                                    <div class="row">
                                        <div class="col-5">
                                            <div class="margin-0"
                                                *ngIf="frequencytype === 'Monthly' || frequencytype === 'Yearly'">
                                                <!-- <label>Day:</label> -->
                                                <select
                                                    [ngClass]="scheduletimes[i].day === '' && isSubmitted ? 'error' : ''"
                                                    [(ngModel)]="scheduletimes[i].day"
                                                    [ngModelOptions]="{ standalone: true }" type="text" required
                                                    class="custom-select form-control schedule-select">
                                                    <option value="">Day</option>
                                                    <option *ngFor="let gd of goalDays" [value]="gd">{{ gd }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-5" *ngIf="frequencytype === 'Yearly'">
                                            <div class="margin-0">
                                                <!-- <label>Month:</label> -->
                                                <select
                                                    [ngClass]="scheduletimes[i].month === '' && isSubmitted ? 'error' : ''"
                                                    [(ngModel)]="scheduletimes[i].month"
                                                    [ngModelOptions]="{ standalone: true }" type="text" required
                                                    class="custom-select form-control schedule-select">
                                                    <option value="">Month</option>
                                                    <option *ngFor="let gm of goalMonths" [value]="gm">{{ gm }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="edit-icons margin-0">
                                                <i *ngIf="scheduletimes.length > 1" (click)="deleteSchedule(i)"
                                                    class="fa fa-times-circle icons-goal" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Start Date:</label>
                    <div class="input-group">
                        <input [disabled]="isEditing" (keydown)="onKeydown()"
                            [ngClass]="{ error: isFieldValid('startDate') }" required [minDate]="minDate"
                            class="form-control date-control" placeholder="mm-dd-yyyy" name="startDate"
                            [(ngModel)]="startdate" (ngModelChange)="onChangeStartDate()"
                            [ngModelOptions]="{ standalone: true }" ngbDatepicker #sd="ngbDatepicker" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary calendar date-control" (click)="sd.toggle()"
                                type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                    <div class="inp-err" *ngIf="errStartDate">
                        <span class="text-danger"> {{ errStartDate }}</span>
                    </div>
                </div>

                <div class="form-group" *ngIf="frequencytype !== 'Once'">
                    <label>End Date:</label>
                    <div class="input-group">
                        <input (keydown)="onKeydown()" formControlName="endDate" class="form-control date-control"
                            placeholder="mm-dd-yyyy" name="endDate" [minDate]="minEndDate" ngbDatepicker
                            #ed="ngbDatepicker" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary calendar date-control" (click)="ed.toggle()"
                                type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Time:</label>
                    <i *ngIf="
                            goaltimes.length < 3 &&
                            frequencytype === 'Weekly' &&
                            goaltypetext !== '' &&
                            goaltypetext !== 'Single Target' &&
                            goaltypetext !== 'Double Numeric' &&
                            goaltypetext !== 'Double Target'
                        " (click)="addNewTime()" class="fa fa-plus-circle icons-goal-add" aria-hidden="true"></i>
                    <div class="goal-time-controls" *ngFor="let time of goaltimes; let i = index">
                        <div class="time-block">
                            <div class="goal-time-section">
                                <select [ngClass]="goaltimes[i].hours === '' && isSubmitted ? 'error' : ''"
                                    [(ngModel)]="goaltimes[i].hours" [ngModelOptions]="{ standalone: true }" type="text"
                                    required class="custom-select form-control">
                                    <option value="">Hour</option>
                                    <option *ngFor="let hour of goalHours" [value]="hour">{{ hour }}</option>
                                </select>

                                <!-- <input [(ngModel)]="goaltimes[i].hours" [ngModelOptions]="{ standalone: true }" type="number" required class="form-control" /> -->
                            </div>
                            <div class="goal-time-section-dot">
                                <label>:</label>
                            </div>
                            <div class="goal-time-section">
                                <select [ngClass]="goaltimes[i].mins === '' && isSubmitted ? 'error' : ''"
                                    [(ngModel)]="goaltimes[i].mins" [ngModelOptions]="{ standalone: true }" type="text"
                                    required class="custom-select form-control">
                                    <option value="">Min</option>
                                    <option *ngFor="let min of goalMins" [value]="min">{{ min }}</option>
                                </select>
                                <!-- <input [(ngModel)]="goaltimes[i].mins" [ngModelOptions]="{ standalone: true }" type="number" required class="form-control" /> -->
                            </div>
                            <div class="goal-time-section-AM">
                                <div class="am-pm" [ngClass]="goaltimes[i].time === '' && isSubmitted ? 'error' : ''">
                                    <div class="time-am-pm custom-control custom-radio">
                                        <input class="input-radio-time custom-control-input"
                                            [(ngModel)]="goaltimes[i].time" [ngModelOptions]="{ standalone: true }"
                                            name="radio-time-{{ i }}" type="radio" id="radio-time-{{ i }}-AM" value="AM"
                                            [checked]="goaltimes[i].time === 'AM'" />
                                        <label style="color: #2d2d2d!important;"
                                            class="radio-label am-label custom-control-label"
                                            for="radio-time-{{ i }}-AM">AM</label>
                                    </div>
                                    <div class="time-am-pm custom-control custom-radio ml-2">
                                        <input class="input-radio-time custom-control-input"
                                            [(ngModel)]="goaltimes[i].time" [ngModelOptions]="{ standalone: true }"
                                            name="radio-time-{{ i }}" type="radio" id="radio-time-{{ i }}-PM" value="PM"
                                            [checked]="goaltimes[i].time === 'PM'" />
                                        <label style="color: #2d2d2d!important;"
                                            class="radio-label am-label custom-control-label"
                                            for="radio-time-{{ i }}-PM">PM</label>
                                    </div>
                                </div>
                            </div>
                            <div class="goal-time-section-icons">
                                <i *ngIf="goaltimes.length > 1 && frequencytype === 'Weekly'" (click)="deleteTime(i)"
                                    class="fa fa-times-circle icons-goal time-icons" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group cleardiv">
                    <label>Completion</label>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" [checked]="notifyprovider" [(ngModel)]="notifyprovider"
                            [ngModelOptions]="{ standalone: true }" type="checkbox" id="notify-provider" />
                        <label style="color: #2d2d2d!important;" for="notify-provider" class="custom-control-label">Post
                            Updates
                            to Chat</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" [checked]="allowearlyentry" [(ngModel)]="allowearlyentry"
                            [ngModelOptions]="{ standalone: true }" type="checkbox" id="allowearly-entry" />
                        <label style="color: #2d2d2d!important;" for="allowearly-entry"
                            class="custom-control-label">Allow Early
                            Entries</label>
                    </div>
                </div>
                <p *ngIf="errorTime" class="text-danger">{{ errorTime }}</p>
            </div>
            <p *ngIf="error" class="text-danger">{{ error }}</p>
        </div>
    </form>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">{{ isEditing ? "Update" : "Save"
                }}</button>
        </div>
    </div>
</div>