import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { MessagingService } from "../../../services/messaging.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { BadWordAddModalComponent } from "../../../modals/bad-word-add-modal/bad-word-add-modal.component";
import { SortableHeaderDirective } from "../../../directives/sortable.directive";
import { TableService } from "../../../services/table.service";
import { Observable } from "rxjs";
@Component({
    selector: "app-badword-listing",
    templateUrl: "./badword.component.html",
    styleUrls: ["./badword.component.css"],
})
export class BadWordComponent implements OnInit {
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    displayedColumns = [
        { displayname: "word", columnname: "badword" },
        { displayname: "action", columnname: "" },
    ];
    error = "";
    chatFilters: any;
    showLoader = false;
    filterText = "";
    constructor(
        private messagingService: MessagingService,
        private route: ActivatedRoute,
        private snackbar: SnackbarService,
        private modalService: NgbModal,
        private router: Router,
        public tableService: TableService
    ) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }

    ngOnInit(): void {
        this.getBadWords();
    }
    public getBadWords() {
        this.showLoader = true;
        this.error = "";
        return this.messagingService.getBadWords(true).subscribe(
            (data) => {
                this.chatFilters = data[0].attributes.list;
                this.tableService.DATA = data[0].attributes.list;
                this.sorting("badword", "asc");

                if (data[0].attributes.list.length == 0) {
                    this.error = "No data found";
                }
                let defaultSort = { active: "badword", direction: "asc" };
            },
            (err) => {
                this.showLoader = false;
                this.error = "No data found";
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    onRefresh() {
        this.filterText = "";
        this.chatFilters.filter = "";
        this.getBadWords();
    }
    onDelete(b) {
        return this.messagingService.deleteBadWords(b.badword).subscribe(
            (data) => {
                this.snackbar.show("Deleted successfully");
                this.onRefresh();
                this.getBadWords();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    newWord() {
        const modalRef = this.modalService.open(BadWordAddModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.action = "add";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.onRefresh();
            this.getBadWords();
            this.modalResponse(receivedEntry);
        });
    }
    public modalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    onSort({ column, direction }: any) {
        this.sorting(column, direction);
    }
    sorting(column, direction) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.page = 1;
        this.tableService.searchOn = "badword";
        this.showLoader = false;
    }
}
