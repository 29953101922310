import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CheckboxItem } from "./checkbox-item";

@Component({
    selector: "checkbox-group",
    templateUrl: "./checkbox-group.component.html",
    styleUrls: ["./checkbox-group.component.css"],
})
export class CheckboxGroupComponent {
    @Input() options = Array<CheckboxItem>();
    @Input() multiSelect: boolean = true;

    @Input() selectedValues: string[];
    @Input() changedOptions: CheckboxItem[];
    @Output() readonly toggle = new EventEmitter<any[]>();

    constructor() {}

    onToggle(item) {
        if (!Boolean(this.multiSelect)) {
            this.options.forEach((el) => {
                if (el.value !== item.value) {
                    el.checked = false;
                }
            });
        }

        item.changed = item.initialValue !== item.value;
        const checkedOptions = this.options.filter((x) => x.checked);
        this.selectedValues = checkedOptions.map((x) => x.value);
        this.changedOptions = this.options.filter((x) => x.changed);
        this.toggle.emit(checkedOptions.map((x) => x.value));
    }
}
