<div class="modal-body">
    <div class="title-label">Manage Providers</div>
    <div class="form-group has-search mt-2">
        <span *ngIf="search === ''" class="fa fa-search form-control-feedback"></span>
        <a (click)="clearSearch()">
            <span *ngIf="search !== ''" class="fa fa-times form-control-feedback"></span>
        </a>
        <input [(ngModel)]="search" type="text" class="form-control" placeholder="Search" />
    </div>
    <div class="suggestions-container">
        <ul>
            <li *ngFor="let p of providers | grdFilter: { title: search, firstname: search, lastname: search }:false" class="item">
                <div>
                    <div>
                        <a (click)="selectProvider(p, $event)" class="form-check">
                            <input [checked]="p.selected" class="form-check-input ml-1" name="physicians" id="checkbox_{{ p.userid }}" type="checkbox" />
                            <label class="form-check-label ml-4"> {{ p.title === null ? p.firstname + " " + p.lastname : p.title + " " + p.firstname + " " + p.lastname }} </label>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div style="display: flex" class="submit mt-2">
        <div class="col col-6 pl-0">
            <button (click)="onCancel()" type="button" class="btn btn-secondary move-buttons-grey form-control">Cancel</button>
        </div>
        <div class="col col-6 pr-0">
            <button type="button" (click)="save()" class="btn btn-primary form-control">Save</button>
        </div>
    </div>
</div>
