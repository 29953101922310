import { Component, Input, OnInit } from '@angular/core';
import { MSXReportService } from '../../../services/msx_report.service';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { SessionService } from "../../../services/session.service";
import * as _ from 'underscore';
@Component({
  selector: 'app-patient-billing',
  templateUrl: './patient-billing.component.html',
  styleUrls: ['./patient-billing.component.scss']
})
export class PatientBillingComponent implements OnInit {


  constructor(private msxReportService: MSXReportService, private snackbar: SnackbarService, public sessionService: SessionService) { }
  specialtiesClaims: any;
  locationsClaims: any;
  specialtiesAvgBillAmount: any;
  locationsAvgBillAmount: any;
  specialtiesTotalBillAmount: any;
  locationsTotalBillAmount: any;
  diagnosesAmount: any;
  proceduresAmount: any;

  reportData: any;
  selectedGroup = "";
  @Input() isFavorite = false;
  async ngOnInit() {
    this.selectedGroup = localStorage.getItem("selectedGroup") || ""
    this.specialtiesClaims = await this.getBillingReport(this.selectedGroup, "specialtiesClaims")
    this.locationsClaims = await this.getBillingReport(this.selectedGroup, "locationsClaims")
    this.specialtiesAvgBillAmount = await this.getBillingReport(this.selectedGroup, "specialtiesAvgBillAmount")

    this.locationsAvgBillAmount = await this.getBillingReport(this.selectedGroup, "locationsAvgBillAmount")
    this.specialtiesTotalBillAmount = await this.getBillingReport(this.selectedGroup, "specialtiesTotalBillAmount")
    this.locationsTotalBillAmount = await this.getBillingReport(this.selectedGroup, "locationsTotalBillAmount")
    this.diagnosesAmount = await this.getBillingReport(this.selectedGroup, "diagnosesAmount")
    this.proceduresAmount = await this.getBillingReport(this.selectedGroup, "proceduresAmount")
  }

  public getBillingReport(group, type): Promise<void> {
    return new Promise((resolve, reject) => {
      this.msxReportService.PatientBilling(group, type).subscribe(
        (data) => {
          let _categories = [];
          let _data = [];
          let key2 = data[0].attributes.key2;
          let key3 = data[0].attributes.key3;
          if (data[0].attributes.type == "specialtiesTotalBillAmount" || data[0].attributes.type == "locationsTotalBillAmount" || data[0].attributes.type == "diagnosesAmount" || data[0].attributes.type == "proceduresAmount") {
            data[0].attributes.data = data[0].attributes.data.map((x) => {
              x.sortcolumn = x[key2];
              return x;
            });
          }
          else {
            data[0].attributes.data = data[0].attributes.data.map((x) => {
              x.sortcolumn = x[key2] / x[key3];
              return x;
            });
          }

          data[0].attributes.data = _.sortBy(data[0].attributes.data, 'sortcolumn').reverse();

          data[0].attributes.data.forEach(element => {
            _categories.push(element[data[0].attributes.key1])
            if (data[0].attributes.type == "specialtiesTotalBillAmount" || data[0].attributes.type == "locationsTotalBillAmount" || data[0].attributes.type == "diagnosesAmount" || data[0].attributes.type == "proceduresAmount") {
              _data.push((parseInt(element[data[0].attributes.key2])));
            }
            else {
              _data.push((parseInt(element[data[0].attributes.key2]) / parseInt(element[data[0].attributes.key3])))
            }
          });
          this.reportData = {
            data: _data,
            categories: _categories,
            subTitle1: data[0].attributes.subTitle1,
            subTitle2: data[0].attributes.subTitle2,
            title: data[0].attributes.title,
            nationalAverage: data[0].attributes.nationalAverage,
            allPracticesAverage: data[0].attributes.allPracticesAverage,
            key1: data[0].attributes.key1
          }
          resolve(this.reportData);
        },
        (err) => {
          this.snackbar.show("error occurred please try again later.", "danger");
          reject();
        }
      );
    });
  }

}
