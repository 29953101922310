import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-member-search",
    templateUrl: "./member-search.component.html",
    styleUrls: ["./member-search.component.css"],
})
export class MemberSearchComponent implements OnInit {
    @Output() readonly onsearchMembers: EventEmitter<any> = new EventEmitter<any>();
    constructor() {}
    searchText = "";
    NoRecordsFound = true;
    patients = [];
    ngOnInit(): void {
        if (window.location.href.indexOf("patient") === -1) {
            this.searchText = "";
        } else {
            this.searchText = localStorage.getItem("search_name") == null || localStorage.getItem("search_name") === "null" ? "" : localStorage.getItem("search_name");
        }
    }
    public searchMembers(): void {
        this.onsearchMembers.emit(this.searchText);
    }
    public clearSearch(): void {
        this.searchText = "";
        localStorage.setItem("page_no", "1");
        localStorage.setItem("search_name", "");
        // localStorage.setItem("page_size", "10");
        this.onsearchMembers.emit("");
    }

    onEnter() {
        this.onsearchMembers.emit(this.searchText);
    }
}
