<div class="modal-body">
    <h5 class="modal-title">X-Axis</h5>
    <div class="filter">
        <div>
            <div *ngIf="!noShowPercentage" class="row">
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="age" name="datapoints"
                            value="age">
                        <label class="form-check-label" for="age">
                            Age group
                        </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="cptcode"
                            name="datapoints" value="cptcode">
                        <label class="form-check-label" for="cptcode">
                            CPT code
                        </label>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div *ngIf="!noShowPercentage" class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="icd10code"
                            name="datapoints" value="icd10code">
                        <label class="form-check-label" for="icd10code">
                            ICD-10 code
                        </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="location"
                            name="datapoints" value="location">
                        <label class="form-check-label" for="location">
                            Location
                        </label>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div *ngIf="!noShowPercentage" class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="market"
                            name="datapoints" value="market">
                        <label class="form-check-label" for="market">
                            Market
                        </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="month"
                            name="datapoints" value="pplr">
                        <label class="form-check-label" for="month">
                            Months
                        </label>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="practice"
                            name="datapoints" value="practice">
                        <label class="form-check-label" for="practice">
                            Practice
                        </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="provider"
                            name="datapoints" value="provider">
                        <label class="form-check-label" for="provider">
                            Provider
                        </label>
                    </div>
                </div>
            </div>
            <hr *ngIf="!noShowPercentage">
            <div *ngIf="!noShowPercentage" class="row">
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="role"
                            name="datapoints" value="role">
                        <label class="form-check-label" for="role">
                            Role
                        </label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <input [(ngModel)]="dataPoints" class="form-check-input" type="radio" id="specialty"
                            name="datapoints" value="specialty">
                        <label class="form-check-label" for="specialty">
                            Specialty
                        </label>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
    <button (click)="onSubmit()" type="button" class="btn btn-primary">Done</button>
</div>