import { Injectable } from "@angular/core";
import { AvcHttp } from "../providers/avc-http";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PracticeService {
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService, private http: HttpClient) {}

    uri: string = environment.uri + "/telerx/practice";

    public get(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.uri + "/list/v1").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public save(data, providers, isEdit, ptid): Observable<any> {
        let _data = {
            membershipid: data.membershipid,
            practicename: data.practicename,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            country: data.country,
            postalcode: data.postalcode,
            providers: providers,
            isdefault: data.isdefault,
            phonenumber: data.phonenumber,
            ptid: undefined,
        };
        if (isEdit) {
            _data.ptid = ptid;
        }

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest(isEdit ? "put" : "post", this.uri + "/v1", _data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public delete(ptid): Observable<any> {
        let data = JSON.stringify({
            ptid: ptid,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.uri + "/v1", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
