import { Component, OnInit, AfterViewInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-session",
    templateUrl: "./session.component.html",
    styleUrls: ["./session.component.scss"],
})
export class SessionComponent implements OnInit, AfterViewInit {
    constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private sessionService: SessionService, private authService: AuthService) {}
    public url = this.sanitizer.bypassSecurityTrustResourceUrl("");
    public paramUrl;
    public sessionid: string = "";

    ngOnInit() {
        this.route.paramMap.subscribe((params: any) => {
            this.paramUrl = decodeURIComponent(params.params.providerdashboardurl);
            // console.log(this.paramUrl);
            if (this.paramUrl.indexOf("~~sessionkey~~") > -1) {
                this.userSessionKey();
            } else if (this.paramUrl.indexOf("~~token~~") > -1) {
                this.userToken();
            } else {
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.paramUrl);
            }
        });
    }
    ngAfterViewInit() {
        (document.querySelector(".ng-sidebar__content") as HTMLElement).style.overflow = "hidden";
    }
    public userToken() {
        let currentUser = this.sessionService.currentUser;
        if (currentUser.attributes.token.length > 0) {
            this.paramUrl = this.paramUrl.replace("~~token~~", currentUser.attributes.token);
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.paramUrl);
        }
    }

    public userSessionKey() {
        this.authService.getUserSessionKey().subscribe(
            (data) => {
                this.sessionid = data[0].attributes.sessionid;
                this.paramUrl = this.paramUrl.replace("~~sessionkey~~", this.sessionid);
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.paramUrl);
            },
            (err) => {
                console.log(err);
            }
        );
    }
}
