<div class="container-fluid">
    <div class="tab">
        <a class="tablinks" [routerLink]="['/media/activity']">
            <img src="/assets/msx/social-icon.png">Social Media Activity
        </a>
        <a class="tablinks active" [routerLink]="['/media/reviews']">
            <i class="fa-solid fa-reply"></i> Respond to Reviews
        </a>
    </div>


    <div class="row mb-2">

        <div class="input-group col-4 search-div">
            <input [(ngModel)]="tableService.searchTerm" type="text" class="form-control search" placeholder="Search..."
                name="searchTerm" />
            <img (click)="clearSearch()" src="/assets/msx/search.svg">
        </div>
        <div class="input-group col-4 float-right">

        </div>

        <div class="input-group col-4 float-right" style="align-items: center;
        justify-content: center;">
            <label class="mr-3 mt-1">Filter by:</label>
            <select [ngModel]="filterBy" (change)="filterByStstus($event.target.value)" class="form-control">
                <option value="all">All</option>
                <option selected value="positive">Positive</option>
                <option value="negative">Negative</option>
                <option value="ignored">Ignored</option>
                <option value="facebook">Facebook</option>
                <option value="google">Google</option>
                <option value="yelp">Yelp</option>
                <option value="healthgrades">HealthGrades</option>
            </select>
        </div>
    </div>


    <div *ngIf="!showLoader">
        <table class="table-striped datatable">
            <thead>
                <tr>
                    <th class="pr-0"></th>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                        sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of resultList$ | async">
                    <td class="pr-0"><i class="fa fa-flag"></i></td>
                    <td>{{ r.createdat| date: "MM/dd/yyyy . hh:mm a" }}</td>
                    <td>{{ r.review }}</td>
                    <td>
                        <span class="font-600">
                            {{ r.provider_name }}
                        </span><br>
                        <span>
                            {{ r.practice_name }}
                        </span>

                    </td>
                    <td class="font-600">{{ r.socialmediatype }}</td>
                    <td style="width: 120px;" class="cursor-pointer">
                        <!--<button class="btn">
                            <i (click)="onReview(r)" class="fa fa-mail-reply"></i>
                        </button>-->
                        <button (click)="onIgnored(r.smrid,r.isignored)" *ngIf="!r.isignored" class="btn">
                            <i class="fa fa-ban"></i>
                        </button>
                        <button (click)="onIgnored(r.smrid,r.isignored)" *ngIf="r.isignored" class="btn">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination justify-content-between align-items-center">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

            <div>
                <label class="mr-3 mt-1">Items per page:</label>
                <select class="custom-select form-control" [(ngModel)]="tableService.pageSize">
                    <option [ngValue]="5">5</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                </select>
            </div>
        </div>

    </div>
    <app-loader *ngIf="showLoader" style="display: flex;align-items: center;justify-content: center;"></app-loader>

</div>