<div class="container-fluid">
    <div class="row p-3 float-right">
        <button (click)="onNewCareTeam()" class="btn btn-primary"><i class="fas fa-plus-circle"></i> Add New</button>
    </div>

    <table class="table table-striped datatable" style="text-align: left">
        <thead>
            <tr>
                <th scope="col">Care Team</th>
                <th scope="col">Address</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let careteam of careteams">
                <td class="input-td">
                    {{ careteam.careteamname }}
                    <br />
                    <span *ngIf="careteam.defaultcareteam" class="badge badge-pill badge-primary">Default Careteam</span>
                </td>
                <td class="input-td">{{ careteam.address1 }}</td>
                <td class="input-td">{{ careteam.city }}</td>
                <td class="input-td">{{ careteam.state }}</td>
                <td class="input-td">
                    <i title="Edit Care Team" (click)="onEditCareTeam(careteam)" class="fa fa-edit mr-3 cursor-pointer"></i>
                    <i *ngIf="careteam.careteamname !== 'No Care Team'" title="Delete Care Team" (click)="onDeleteCareTeam(careteam)" class="fa fa-trash mr-3 cursor-pointer"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>
