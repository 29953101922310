import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "../../services/session.service";
import { PatientService } from "../../services/patients.service";

@Component({
    selector: "app-careteam-change-modal",
    templateUrl: "./careteam-change-modal.component.html",
    styleUrls: ["./careteam-change-modal.component.scss"],
})
export class CareTeamChangeModalComponent implements OnInit {
    @Output() readonly Response = new EventEmitter<any>();
    @Input() careteams: any;

    errorMessage: string = "";
    currentCareteam = this.sessionService.activeCareTeam;

    constructor(private patientService: PatientService, private sessionService: SessionService, private activeModal: NgbActiveModal) {}

    ngOnInit() {
        this.careteams.forEach((element) => {
            if (element.careteamid === this.currentCareteam.careteamid) {
                this.currentCareteam = element;
            }
        });
    }

    public onSubmit() {
        this.activeModal.close();

        this.patientService.updateUserSettings({ currentcareteamid: this.currentCareteam.careteamid }, this.sessionService.currentUser.id).subscribe(
            (_data) => {},
            (err) => {}
        );
        this.sessionService.activeCareTeam = this.currentCareteam;
        window.location.reload();
    }

    public onCancel() {
        this.activeModal.close();
    }
}
