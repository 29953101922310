import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Helper } from "../../providers/helper";
import { AuthService } from "../../services/auth.service";
import { SessionService } from "../session.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private helper: Helper, private auth: AuthService, private sessionService: SessionService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let userdata = this.sessionService.currentUser;
        if (!userdata) {
            if (localStorage.getItem("token")) {
                return new Promise<boolean>((resolve, reject) => {
                    return this.auth
                        .get_current_user_by_token(localStorage.getItem("token"))
                        .then((res) => {
                            if (res) {
                                if (!Array.isArray(route.data.role)) {
                                    console.error("no role specified for route");
                                    this.sessionService.clearLocalStorageAndSession();
                                    this.router.navigate(["login"]);
                                    resolve(false);
                                } else {
                                    resolve(route.data.role.includes(this.sessionService.currentUserRole));
                                }
                            } else {
                                this.sessionService.clearLocalStorageAndSession();
                                this.router.navigate(["login"]);
                                resolve(false);
                            }
                        })
                        .catch((ex) => {
                            this.sessionService.clearLocalStorageAndSession();
                            this.router.navigate(["login"]);
                            resolve(false);
                        });
                });
            } else {
                this.sessionService.clearLocalStorageAndSession();
                this.router.navigate(["login"]);
                return Promise.resolve(false);
            }
        } else {
            // if (userdata.attributes.roles.some((item) => environment.allow_roles.includes(item))) {
            if (Array.isArray(route.data.dashboadrmodule)) {
                if (this.sessionService.currentUser.attributes.msxInfo.dashboardModules.includes(route.data.dashboadrmodule[0])) {
                    return Promise.resolve(true);
                }
                else {
                    this.sessionService.clearLocalStorageAndSession();
                    this.router.navigate(["login"]);
                    return Promise.resolve(false);
                }
            }

            if (!Array.isArray(route.data.role)) {
                console.error("no role specified for route");
                this.sessionService.clearLocalStorageAndSession();
                this.router.navigate(["login"]);
                return Promise.resolve(false);
            } else {
                return Promise.resolve(route.data.role.includes(this.sessionService.currentUserRole));
            }
        }
    }
}
