import {
    PersonalInformation,
    ContactInformation,
    EmergencyContact,
    HealthInsuranceInformation,
    PastMedicalHistory,
    PastSurgicalHistory,
    WomenHealthHistory,
    MedicationAllergies,
    FoodAllergies,
    EnvironmentalAllergies,
    SocialHistory,
    // Allergies,
    Vaccines,
    FamilyHistory,
    Prescriptions,
    MoodWellBeing,
    Covid19Vaccination,
} from "./SummaryDetails";

export class ClinicalSummary {
    constructor(
        public personalinformation: PersonalInformation,
        public contactinformation: ContactInformation,
        public emergencycontact: EmergencyContact,
        public healthinsuranceinformation: HealthInsuranceInformation,
        public pastmedicalhistory: PastMedicalHistory,
        public pastsurgicalhistory: PastSurgicalHistory,
        public womenhealthhistory: WomenHealthHistory,
        public medicationallergies: MedicationAllergies,
        public foodallergies: FoodAllergies,
        public environmentalallergies: EnvironmentalAllergies,
        public socialhistory: SocialHistory,
        // public allergies: Allergies,
        public vaccines: Vaccines,
        public familyhistory: FamilyHistory,
        public prescriptions: Prescriptions,
        public moodWellBeing: MoodWellBeing,
        public covid19vaccination: Covid19Vaccination,
        public phq9: any,
        public gad7: any,
        public sleepassessment: any
    ) { }
}
