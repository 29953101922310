<div class="container-fluid">
    <div class="tab">
        <a class="tablinks active">
            <img src="/assets/msx/favorites-white.svg">
            Patient Outreach
        </a>
        <a class="tablinks">
           <img src="/assets/msx/members.svg">
            Members & Send Lists
        </a>

    </div>


</div>

<div class="mt-3" style="margin-left: -10px;">
    <ul ngbNav #nav="ngbNav" class="nav-tabs pl-4">
        <li ngbNavItem>
            <a ngbNavLink>Campaign Designer</a>
            <ng-template ngbNavContent>
                <div class="campaign-form">
                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label>Campaign Name:</label>
                        </div>
                        <div class="col-5 px-0">
                            <input type="text" class="form-control" value="Thyroid Awareness"/>
                        </div>
                        <div  class="col-5 text-right">
                            <button class="btn btn-secondary"><img src="/assets/msx/previous.svg">Previous</button>
                            <button class="btn btn-primary ml-3"><img src="/assets/msx/next.svg">Next</button>
                        </div>
                    </div>

                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label class="readonly">From:</label>
                        </div>
                        <div class="col-3 px-0">
                            <input type="text" class="form-control" placeholder="Anywhere Medical Associates" readonly/>
                        </div>
                        <div class="col-2 text-right">
                            <label>Subject:</label>
                        </div>
                        <div class="col-3 px-0">
                            <input type="text" class="form-control" value="Subject"/>
                        </div>
                    </div>

                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label class="readonly">Reply:</label>
                        </div>
                        <div class="col-3 px-0">
                            <input type="text" class="form-control" placeholder="contact@anywheremedical.com" readonly/>
                        </div>
                        <div class="col-2 text-right">
                            <label>Preheader:</label>
                        </div>
                        <div class="col-3 px-0">
                            <input type="text" class="form-control" value="Preheader"/>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-2 text-right">
                        </div>
                        <div class="col-5 pl-0">
                            <button class="btn btn-primary"><img src="/assets/msx/previous.svg">Undo</button>
                            <button class="btn btn-secondary mx-3"><img src="/assets/msx/next.svg">Redo</button>
                            <button class="btn btn-primary"><img src="/assets/msx/next.svg">Save</button>
                        </div>
                    </div> 
                </div>   
                <div class="campaign-layout">
                    <div class="campaign-content">
                        <img class="w-100" src="/assets/msx/campaign-banner.PNG">
                        <div class="campaign-text">
                            <div class="campaign-editor">
                                <p>Dear Valued Patient,<br><br>
                                April is Esophageal Cancer Awareness Month, and we at *|PRACTICENAME|* want to take this opportunity to raise awareness about this condition. Esophageal cancer affects the esophagus, the tube that connects your throat to your stomach. While it is a relatively less common cancer, it’s important to be aware of the risk factors and symptoms.<br><br>
                                Early detection is crucial for improving treatment outcomes. If you experience persistent heartburn, difficulty swallowing, or unintended weight loss, please schedule an appointment with your doctor to discuss these concerns.<br> <br>
                                We encourage you to learn more about esophageal cancer and its prevention methods by visiting the websites of the National Cancer Institute and the American Cancer Society. <br><br>
                                Stay informed, take charge of your health, and don’t hesitate to reach out to us if you have any questions. <br><br>
                                Sincerely, <br><br>
                                The Team at *|PRACTICENAME|*</p>
                            </div>
                        </div>
                    </div>
                    <div class="campaign-footer">
                        <div class="brand">
                            <img  src="/assets/images/clio-linear.png" width="250">
                        </div>
                        <p>123 Main Street, Suite 301</p>
                        <p>Anywhere, Pennsylvania 12345</p>
                        <span>Quidem nemo inventore rerum voluptatem. Magnam fugiat velit omnis sint sit. Totam et laudantium laborum quidem optio ad harum earum in. Voluptatibus quam nihil in mollitia id eum maiores natus tempore. Perferendis tempore quibusdam provident laborum atque non placeat animi magni. Sed cupiditate dolorum.</span>
                    </div>
                </div>   
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Preview & Test</a>
            <ng-template ngbNavContent>
                <div class="campaign-form">
                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label >Campaign Name:</label>
                        </div>
                        <div class="col-5 px-0">
                            <input type="text" class="form-control" value="Thyroid Awareness"/>
                        </div>
                        <div  class="col-5 text-right">
                            <button class="btn btn-primary"><img src="/assets/msx/previous.svg">Previous</button>
                            <button class="btn btn-primary ml-3"><img src="/assets/msx/next.svg">Next</button>
                        </div>
                    </div>
    
                    <div class="row align-items-center mb-3">
                        <div class="col-2 text-right">
                            <label>Test Email:</label>
                        </div>
                        <div class="col-3 px-0">
                            <input type="text" class="form-control" value="skrueger@aspirevc.com"/>
                        </div>
                        <div class="col-4">
                        <button class="btn btn-primary">Send Test Email</button>
                        </div>
                        
                    </div>
                </div>
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab">Desktop</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab">Mobile</a>
                    </li>
                </ul>
                
                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane active" id="desktop">
                        <div class="campaign-layout">
                            <div class="campaign-content">
                                <img class="w-100" src="/assets/msx/campaign-banner.PNG">
                                <div class="campaign-text">
                                    <p>Dear Valued Patient,<br><br>
                                    April is Esophageal Cancer Awareness Month, and we at *|PRACTICENAME|* want to take this opportunity to raise awareness about this condition. Esophageal cancer affects the esophagus, the tube that connects your throat to your stomach. While it is a relatively less common cancer, it’s important to be aware of the risk factors and symptoms.<br><br>
                                    Early detection is crucial for improving treatment outcomes. If you experience persistent heartburn, difficulty swallowing, or unintended weight loss, please schedule an appointment with your doctor to discuss these concerns.<br> <br>
                                    We encourage you to learn more about esophageal cancer and its prevention methods by visiting the websites of the National Cancer Institute and the American Cancer Society. <br><br>
                                    Stay informed, take charge of your health, and don’t hesitate to reach out to us if you have any questions. <br><br>
                                    Sincerely, <br><br>
                                    The Team at *|PRACTICENAME|*</p>
                                </div>
                            </div>
                            <div class="campaign-footer">
                                <div class="brand">
                                    <img  src="/assets/images/clio-linear.png" width="250">
                                </div>
                                <p>123 Main Street, Suite 301</p>
                                <p>Anywhere, Pennsylvania 12345</p>
                                <span>Quidem nemo inventore rerum voluptatem. Magnam fugiat velit omnis sint sit. Totam et laudantium laborum quidem optio ad harum earum in. Voluptatibus quam nihil in mollitia id eum maiores natus tempore. Perferendis tempore quibusdam provident laborum atque non placeat animi magni. Sed cupiditate dolorum.</span>
                            </div>
                        </div> 
                    </div>
                    <div class="tab-pane active" id="mobile">
                        <div class="campaign-layout">
                            <div class="campaign-content">
                                <img class="w-100" src="/assets/msx/campaign-banner.PNG">
                                <div class="campaign-text">
                                    <p>Dear Valued Patient,<br><br>
                                    April is Esophageal Cancer Awareness Month, and we at *|PRACTICENAME|* want to take this opportunity to raise awareness about this condition. Esophageal cancer affects the esophagus, the tube that connects your throat to your stomach. While it is a relatively less common cancer, it’s important to be aware of the risk factors and symptoms.<br><br>
                                    Early detection is crucial for improving treatment outcomes. If you experience persistent heartburn, difficulty swallowing, or unintended weight loss, please schedule an appointment with your doctor to discuss these concerns.<br> <br>
                                    We encourage you to learn more about esophageal cancer and its prevention methods by visiting the websites of the National Cancer Institute and the American Cancer Society. <br><br>
                                    Stay informed, take charge of your health, and don’t hesitate to reach out to us if you have any questions. <br><br>
                                    Sincerely, <br><br>
                                    The Team at *|PRACTICENAME|*</p>
                                </div>
                            </div>
                            <div class="campaign-footer">
                                <div class="brand">
                                    <img  src="/assets/images/clio-linear.png" width="250">
                                </div>
                                <p>123 Main Street, Suite 301</p>
                                <p>Anywhere, Pennsylvania 12345</p>
                                <span>Quidem nemo inventore rerum voluptatem. Magnam fugiat velit omnis sint sit. Totam et laudantium laborum quidem optio ad harum earum in. Voluptatibus quam nihil in mollitia id eum maiores natus tempore. Perferendis tempore quibusdam provident laborum atque non placeat animi magni. Sed cupiditate dolorum.</span>
                            </div>
                        </div> 
                    </div>
                </div>
                 
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Send Campaign</a>
            <ng-template ngbNavContent>
                <div class="campaign-form">
                    <div class="row">
                        <div class="col-4">
                        <div class="mb-3">
                            <label class="small">Campaign Name</label>
                            <label class="big">Thyroid Awareness</label>
                        </div>
                        <div class="mb-3">
                            <label class="small">Subject Line</label>
                            <label class="big">It’s Thyroid Awareness Month!</label>
                        </div>
                        <div class="mb-3">
                            <label class="small">Recipients</label>
                            <label class="big">All Patients (75,299)</label>
                        </div>
                        <div class="mb-3">
                            <label class="small">Description</label>
                            <label class="xs">Thyroid awareness email for January 2024.</label>
                        </div>
                        </div>
                        <div class="col-4">
                            <div class="row align-items-center mb-3">
                                <div class="col-4">
                                    <label>Send Date:</label>
                                </div>
                                <div class="col-6 px-0">
                                    <input type="date" class="form-control" />
                                </div>
                            </div>
                            <div class="row align-items-center mb-3">
                                <div class="col-4">
                                    <label>Send Time:</label>
                                </div>
                                <div class="col-3 px-0">
                                    <select class="form-control">
                                        <option>1:00</option>
                                        <option>2:00</option>
                                        <option>3:00</option>
                                        <option>4:00</option>
                                        <option>5:00</option>
                                        <option>6:00</option>
                                        <option>7:00</option>
                                        <option>8:00</option>
                                        <option>9:00</option>
                                        <option>10:00</option>
                                        <option>11:00</option>
                                        <option>12:00</option>
                                    </select>
                                </div>
                                <div class="col-5">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="TimeDuration" checked>AM
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="TimeDuration">PM
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-primary"><img src="/assets/msx/previous.svg">Schedule</button>
                                <button class="btn btn-primary ml-3"><img src="/assets/msx/next.svg">Send Now</button>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div class="campaign-layout">
                    <div class="campaign-content">
                        <img class="w-100" src="/assets/msx/campaign-banner.PNG">
                        <div class="campaign-text">
                            <p>Dear Valued Patient,<br><br>
                            April is Esophageal Cancer Awareness Month, and we at *|PRACTICENAME|* want to take this opportunity to raise awareness about this condition. Esophageal cancer affects the esophagus, the tube that connects your throat to your stomach. While it is a relatively less common cancer, it’s important to be aware of the risk factors and symptoms.<br><br>
                            Early detection is crucial for improving treatment outcomes. If you experience persistent heartburn, difficulty swallowing, or unintended weight loss, please schedule an appointment with your doctor to discuss these concerns.<br> <br>
                            We encourage you to learn more about esophageal cancer and its prevention methods by visiting the websites of the National Cancer Institute and the American Cancer Society. <br><br>
                            Stay informed, take charge of your health, and don’t hesitate to reach out to us if you have any questions. <br><br>
                            Sincerely, <br><br>
                            The Team at *|PRACTICENAME|*</p>
                        </div>
                    </div>
                    <div class="campaign-footer">
                        <div class="brand">
                            <img  src="/assets/images/clio-linear.png" width="250">
                        </div>
                        <p>123 Main Street, Suite 301</p>
                        <p>Anywhere, Pennsylvania 12345</p>
                        <span>Quidem nemo inventore rerum voluptatem. Magnam fugiat velit omnis sint sit. Totam et laudantium laborum quidem optio ad harum earum in. Voluptatibus quam nihil in mollitia id eum maiores natus tempore. Perferendis tempore quibusdam provident laborum atque non placeat animi magni. Sed cupiditate dolorum.</span>
                    </div>
                </div>  
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>

<ng-template #ReportOptions>
    <div class="container-fluid">
        <div class="row">
            <div class="col-2 text-right">
                <label style="margin-top: 10px;">Report For:</label>
            </div>
            <div class="col-5">
                <!-- <ng-select [(ngModel)]="selectedValue" (change)="selectedEntity($event)" [items]="EntityList"
                    bindLabel="Text" bindValue="Value" groupBy="Type" [searchable]="false">
                </ng-select> -->
            </div>
        </div>
    </div>
</ng-template>
