import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { PatientService } from "../../services/patients.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    moduleId: module.id,
    templateUrl: "incident-billing.component.html",
    styleUrls: ["./incident-billing.css"],
})
export class IncidentBillingComponent implements OnInit {
    model: any = {};
    loading = false;
    wruid: any;
    loadingPage: boolean = true;
    currentInvoice: any;
    generateInvoice: boolean = true;
    isClosed = false;
    constructor(private router: Router, private route: ActivatedRoute, private patientService: PatientService, private snackbar: SnackbarService, public activeModal: NgbActiveModal) {}

    @Input() BillingIncidentData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        if (this.BillingIncidentData.incident.status == "closed") {
            this.isClosed = true;
        }
        this.loadingPage = false;
        this.getInvoices();
    }

    getInvoices() {
        return this.patientService.incidentBilling(this.BillingIncidentData.incident.wruid, false).subscribe(
            (data) => {
                this.currentInvoice = data[0].attributes;
                this.generateInvoice = true;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                this.generateInvoice = true;
            }
        );
    }

    public onInvoiceGenerate() {
        this.generateInvoice = false;
        return this.patientService.incidentGenerateInvoice(this.BillingIncidentData.incident.wruid).subscribe(
            (data) => {
                this.getInvoices();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                this.generateInvoice = true;
            }
        );
    }

    public resizeIframe(event) {
        var iframe = event.target;
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 100 + "px";
    }

    public onViewInvoice(i) {
        this.currentInvoice = i;
    }
    public onCancel() {
        this.activeModal.close();
    }
}
