import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from "@angular/core";
import { CommunicationService } from "../../services/communications.service";
import { MessagingService } from "../../services/messaging.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { ActivatedRoute } from "@angular/router";
import "firebase/messaging";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { SessionService } from "../../services/session.service";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "chat",
    templateUrl: "./chat.component.html",
    styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
    debug: boolean = false;

    patients: any = [];
    currentPatient: any = {};
    currentCareTeam: any = [];
    activeCareTeam: any;

    userId: string = "";
    user: any = {};

    ProviderId: string = "";
    PatientId: string = "";
    urlPatientId: string = "";

    public search: string = "";
    public noResultFound: boolean = false;
    public showSearchLoader: boolean = false;
    public environment = environment;

    public priority_search = "";
    test = 0;

    // Track open subscriptions
    componentDestructionNotifier = new Subject();

    constructor(
        private communication: CommunicationService,
        private sessionService: SessionService,
        private messagingService: MessagingService,
        private snackbar: SnackbarService,
        private route: ActivatedRoute
    ) { }
    @HostListener("document:visibilitychange", ["$event"])
    handleVisibilityChange(event: any): void {
        if (!document.hidden) {
            this.getThreadList();
        }
    }
    ngAfterViewInit() {
        (document.querySelector(".ng-sidebar__content") as HTMLElement).style.overflow = "hidden";
    }

    ngOnInit() {
        this.urlPatientId = this.route.snapshot.paramMap.get("id");

        this.activeCareTeam = this.sessionService.activeCareTeam;
        this.debug = this.route.snapshot.queryParamMap.get("debug") === "true" ? true : false;

        let currentUser = this.sessionService.currentUser;
        this.ProviderId = currentUser.id;
        this.currentCareTeam = this.activeCareTeam;

        // Load all threads
        this.getThreadList();

        document.addEventListener("UPDATE_LEFT_SECTION_COUNT", (event: any) => {
            setTimeout(() => {
                this.patients.forEach((element) => {
                    if (element.patientid == event.detail.patientid) {
                        this.getThreadList();
                        // element.provider_unread_messages_count = parseInt(element.provider_unread_messages_count) + 1;
                    }
                });
            }, 4000);
        });

        // Listen for updates to unread messages counter
        this.messagingService.updateUnreadCounter.pipe(takeUntil(this.componentDestructionNotifier)).subscribe((data: any) => {
            if (data && data.clear && data.clear.length > 0) {
                for (let patient of this.patients) {
                    if (data.clear.includes(patient.patientid)) {
                        patient.provider_unread_messages_count = 0;
                    }
                }
            }
        });

        // Always listen for updates on the "currentMessage" Subject
        this.messagingService.currentMessage.pipe(takeUntil(this.componentDestructionNotifier)).subscribe((data: any) => {
            if (!data) {
                return false;
            }
            let newMessage = JSON.parse(data.data.data);
            if (data.data.type === "chat") {
                this.patients.forEach((element) => {
                    if (element.patientid === newMessage.patientid) {
                        element.message = newMessage.message === "" ? null : newMessage.message;
                        element.datetimeutc = newMessage.datetimeutc.split(" ").join("T") + ".000Z";
                        if (this.currentPatient.patientid !== element.patientid) {
                            element.provider_unread_messages_count = parseInt(element.provider_unread_messages_count) + 1;
                        }
                        element.messagetype = newMessage.messagetype;
                    }
                });
            }
        });

        // Listen for incoming push messages if enabled
        if (this.messagingService.isPushMessagingDisabled) {
            this.messagingService.latestThreadPull.pipe(takeUntil(this.componentDestructionNotifier)).subscribe((data: any) => {
                if (!data) {
                    return false;
                }

                // Create map of patient data
                data = data.reduce((patients, current) => {
                    patients[current.patientid] = current;
                    return patients;
                }, {});

                // Update patients with latest data. Replacing each object, and not overwriting the array itself.
                // NOTE: Mitch 2020/08/11 - overwriting the array here causes the thread list to jump to the scroll bottom
                //                          when chat is running as "polled" and not realtime.
                for (let patient of this.patients) {
                    // Update properties
                    let updated = data[patient.patientid];
                    patient.datetimeutc = updated.datetimeutc;
                    patient.message = updated.message;
                    patient.provider_unread_messages_count = updated.provider_unread_messages_count;
                }
            });
        }
    }

    ngOnDestroy() {
        // Notify component is being destroyed
        this.componentDestructionNotifier.next();
        this.componentDestructionNotifier.complete();
        (document.querySelector(".ng-sidebar__content") as HTMLElement).style.overflow = "auto";
    }

    public setCurrentPatient(patient) {
        this.currentPatient = patient;
    }

    public getThreadList() {
        this.communication.getThreadList().subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.patients = data[0].attributes;
                    // NOTE: Mitch 2020/07/17 - Disabled auto selection of first patient to preserve
                    //       unread message count badge
                    // if (this.patients.length > 0) {
                    //   this.currentPatient = this.patients[0];
                    // }

                    this.patients.forEach((element) => {
                        if (element.patientid === this.urlPatientId) {
                            this.currentPatient = element;
                        }
                    });
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public patientSearch() {
        this.showSearchLoader = true;
        this.communication.patients(this.activeCareTeam["careteamid"], this.search).subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.noResultFound = false;
                    this.patients = data[0].attributes;
                    // NOTE: Mitch 2020/07/17 - Disabled auto selection of first patient to preserve
                    //       unread message count badge
                    // if (this.patients.length > 0) {
                    //   this.currentPatient = this.patients[0];
                    // }
                } else {
                    this.noResultFound = true;
                }
                this.showSearchLoader = false;
            },
            (error) => {
                this.snackbar.show(error[0].detail, "danger");
            }
        );
    }
    public resetSearch() {
        this.search = "";
        this.noResultFound = false;
        this.getThreadList();
    }

    public onError() {
        return "../../assets/images/doctor.png";
    }

}
