import { Component, AfterViewInit, Input, ViewChild, HostListener, Output, EventEmitter } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { MessagingService } from "../../services/messaging.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
@Component({
    selector: "app-contextmenu",
    templateUrl: "./contextmenu.component.html",
    styleUrls: ["./contextmenu.component.scss"],
    animations: [
        trigger("fadeInOut", [
            state(
                "void",
                style({
                    opacity: 0,
                })
            ),
            transition("void <=> *", animate(500)),
        ]),
    ],
})
export class ContextmenuComponent implements AfterViewInit {
    @ViewChild("contextmenu", { static: false }) contextmenu: any;
    ngAfterViewInit() {
        if (this.contextmenu !== undefined) {
            //this.contextmenu.nativeElement.focus();
        }
    }
    @HostListener("document:keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
        if (this.contextmenu.nativeElement.offsetHeight !== 0) {
            if (event.key === "ArrowDown") {
                this.contextmenu.nativeElement.focus();
                if (this.arrowkeyLocation === -1) {
                    this.arrowkeyLocation = 0;
                }
            } else if (event.key === "Enter" || event.key === "Tab") {
                this.addTag(this.contextmenu.nativeElement.querySelectorAll(".active")[0].text.trim());
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }
    constructor(public messagingService: MessagingService, public snackbar: SnackbarService) { }
    hide() { }
    @Input() x = 0;
    @Input() y = 0;
    @Input() data = [];
    @Input() searchKey = "";
    @Output() readonly selectedTag: EventEmitter<any> = new EventEmitter();
    @Input() showDelete = false;
    @Input() message: any;
    currentState = "initial";
    arrowkeyLocation = -1;
    shows = this.data;
    keyDown(event) {
        switch (event.keyCode) {
            case 38: // this is the ascii of arrow up
                this.arrowkeyLocation--;
                break;
            case 40: // this is the ascii of arrow down
                this.arrowkeyLocation++;
                break;
        }
    }
    addTag(tag: any) {
        this.selectedTag.emit(tag);
    }
    deleteMessage() {
        this.message.isDeleteContextMenuOpen = false;
        return this.messagingService.deleteMessage(this.message.id).subscribe(
            (data) => {
                this.snackbar.show("Deleted successfully");
                this.message.status = -1;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    resendMessage() {
        this.message.isDeleteContextMenuOpen = false;
        return this.messagingService.enableMessage(this.message.id).subscribe(
            (data) => {
                this.snackbar.show("Resend successfully");
                this.message.status = 0;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
}
