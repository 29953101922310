import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { interval } from "rxjs";

import { SessionService } from "../../services/session.service";

import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
import * as _ from 'lodash';
@Component({
    selector: "waiting-room-dashboard",
    templateUrl: "./waiting-room-dashboard.component.html",
    styleUrls: ["./waiting-room-dashboard.component.css"],
})
export class WaitingRoomDashboardComponent implements OnInit {
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    constructor(public waitingRoomService: WaitingRoomService, private route: ActivatedRoute, private sessionService: SessionService, private router: Router, public tableService: TableService) {
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
        this.tableService.searchOnColumn = "";
        this.tableService.pageSize = 10;
        this.tableService.searchTerm = "";
        route.params.subscribe((val) => {
            //this.setUpUI();
        });
    }
    activeCareTeam = this.sessionService.activeCareTeam;
    currentUser = this.sessionService.currentUser;
    total = 0;
    incomingTotal = 0;
    outgoingTotal = 0;
    allReferrals = 0;
    isTelerxTypeService = false;
    public showLoader: boolean = false;
    roomName = "";
    wrid = "";
    filterText = "";
    patients: any;
    viewOptions: any;
    //patients: any = [];
    waitingRooms: any = [];
    viewAllDropdown = [];
    isViewAll: boolean = false;
    hasLink: boolean = false;
    displayedColumns: string[];
    globalFilter = "";
    fullData = [];
    filteredValues = {
        createdat: "",
        username: "",
        status: "",
        chiefcomplaint: "",
        refferedtelerxname: "",
    };

    ngOnInit() {
        if (this.currentUser.attributes.membership.telerx && this.currentUser.attributes.membership.telerx.telerxtype === "service") {
            this.isTelerxTypeService = true;
        }
        this.showLoader = true;
        // this.getWaitingRooms();
        // interval(10000).subscribe((val) => {
        //     this.getWaitingRooms();
        // });
        this.setUpUI();
    }

    public setUpUI() {
        this.viewOptions = {
            pageid: "",
            columns: [
                {
                    name: "name",
                    display: "Name",
                    show: false,
                    order: 0,
                },
                {
                    name: "new",
                    display: "New",
                    show: false,
                    order: 1,
                },
                // {
                //     name: "open",
                //     display: "Open",
                //     show: false,
                //     order: 2,
                // },
                {
                    name: "inProgress",
                    display: "InProgress",
                    show: false,
                    order: 3,
                },
                // {
                //     name: "onhold",
                //     display: "OnHold",
                //     show: false,
                //     order: 4,
                // },
                {
                    name: "closed",
                    display: "Closed",
                    show: false,
                    order: 5,
                }

            ],
        };
        this.displayedColumns = ["name", "new", "inProgress", "closed"];
        this.showLoader = true;
        this.wrid = this.route.snapshot.paramMap.get("id");
        if (this.wrid === "00000000-0000-0000-0000-000000000000") {
            this.isViewAll = true;
        } else {
            this.isViewAll = false;
        }

        //this.getWaitingRoomPatients();
        this.getWaitingRooms();
    }
    displayName(name) {
        return this.viewOptions.columns.find((o) => o.name.toLowerCase() === name.toLowerCase()).display;
    }
    onSort({ column, direction }: any, serverSideSorting = true) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction, true);
    }
    sorting(column, direction, serverSideSorting = true) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.searchOnColumn = "";
        this.tableService.searchOn = "name";
        this.tableService.page = 1;
    }

    getWaitingRooms() {
        let _data = [];
        if (window.location.pathname !== "/service-recovery") {
            return this.waitingRoomService.getWaitingRoomsRequestCount(this.activeCareTeam["careteamid"]).subscribe(
                (data) => {
                    if (data[0].attributes) {
                        data[0].attributes.waitingroom.forEach(element => {
                            _data.push({
                                name: element.name,
                                counts: {
                                    closed: element.counts.closed,
                                    inProgress: element.counts.referred,
                                    new: element.counts.active,
                                    onhold: element.counts.referred,
                                    open: element.counts.referred,
                                }

                            })
                        });
                        this.waitingRooms = _data;
                        this.waitingRooms.push(data[0].attributes.servicerecovery)
                        // this.total = parseInt(count.toString()) + parseInt(this.outgoingTotal.toString());

                        this.waitingRooms = _.orderBy(this.waitingRooms, 'name', 'asc')
                        this.tableService.DATA = this.waitingRooms;
                        this.sorting("name", "desc", false);
                        this.showLoader = false;
                    }
                },
                (err) => {
                    this.showLoader = false;
                }
            );
        }
    }
}
