import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { HttpClient } from "@angular/common/http";
import { SessionService } from "./session.service";

@Injectable()
export class WaitingRoomService {
    constructor(private avcHttp: AvcHttp, private helper: Helper, private http: HttpClient, private sessionService: SessionService) { }

    public getWaitingRooms(careteamid): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (selectedGroup !== "") {
            selectedGroup = selectedGroup.toLowerCase();
        }
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/waitingroom?membershipid=" + selectedGroup;
            if (this.sessionService.isAdmin()) {
                url = environment.uri + "/waitingroom";
            }
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public getWaitingRoomsPatients(wrid, careteamid): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (selectedGroup !== "") {
            selectedGroup = selectedGroup.toLowerCase();
        }
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/waitingroom/request/list/" + wrid + "?careteamid=" + careteamid + "&membershipid=" + selectedGroup, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public getWaitingRoomPatientDetails(wrid, careteamid, userid): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (selectedGroup !== "") {
            selectedGroup = selectedGroup.toLowerCase();
        }
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/waitingroom/request/list/" + wrid + "/" + userid + "?careteamid=" + careteamid + "&membershipid=" + selectedGroup, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public movePatientToWaitingRoom(data): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", environment.uri + "/waitingroom/request/change", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public referPatient(data): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/waitingroom/request/refer", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public referPatientToTeleRx(data): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/waitingroom/request/referralinprocess", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public getReferralsPatients(type, careteamid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/waitingroom/request/refer?type=" + type + "&careteamid=" + careteamid, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public referAction(data): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/waitingroom/request/refer/action", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public addWaitingRooms(roomname): Observable<any> {
        let data = JSON.stringify({
            membershipid: this.sessionService.currentUser.attributes.membership.membershipid,
            roomname: roomname,
            roomdescription: roomname + " Waiting",
            isimportant: true,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/waitingroom", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public updateWaitingRooms(room, status = "active", newroomname = ""): Observable<any> {
        let data = JSON.stringify({
            wrid: room.wrid,
            roomname: newroomname,
            roomdescription: room.roomdescription,
            isimportant: true,
            status: status,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", environment.uri + "/waitingroom", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getServiceRecoveryList(pageno = 1, limit = 5, status = "0", search = "", column = "", direction = "", lowestscore, escalateKeywords, escalateSentiments = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (selectedGroup !== "") {
            selectedGroup = selectedGroup.toLowerCase();
        }
        return new Observable((observer: Observer<any>) => {
            let tt = new Date().getTime()
            let url = environment.uri + "/msx/ServiceRecovery/v3?groupId=" + selectedGroup + "&pageno=" + pageno + "&limit=" + limit + "&status=" + status + "&search=" + encodeURIComponent(search) + "&sort=" + column
                + "&order=" + direction + "&time=" + tt
                + "&lowestscore=" + lowestscore + "&escalateKeywords=" + escalateKeywords + "&sentiment=" + encodeURIComponent(escalateSentiments);
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getWaitingRoomsRequestCount(careteamid): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/waitingroom/request/counts?membershipid=" + selectedGroup;
            if (this.sessionService.isAdmin()) {
                url = environment.uri + "/waitingroom";
            }
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }



    public updateServiceRecovery(service: any, additionalData: any): Observable<any> {
        let data = JSON.stringify({
            servicerecoveryid: additionalData.servicerecoveryid,
            status: parseInt(service.status),
            note: service.reason,
            isopen: service.isopen == undefined ? false : service.isopen,
            patientfirstname: additionalData.patientfirstname,
            patientname: additionalData.patientname,
            providerfullname: additionalData.providerfullname,
            oldstatus: additionalData.status,
            locationname: additionalData.locationname,
            email: additionalData.email
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", environment.uri + "/msx/servicerecovery/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }



    public getServiceRecoveryById(servicerecoveryid = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/msx/ServiceRecovery/v2/" + servicerecoveryid;
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }


}
