<div class="modal-body">
    <div class="category-header">
        <label>Personal Information</label>
    </div>
    <form class="modal-body-form" [formGroup]="personalForm">
        <div class="main-row">
            <div class="main-left">
                <div class="form-group">
                    <label>First Name</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('firstname') }" formControlName="firstname"
                        type="text" required class="form-control" focus="true" />
                    <div class="inp-err"
                        *ngIf="personalForm.controls['firstname'].errors && personalForm.controls['firstname'].touched">
                        <span class="error-msg"
                            *ngIf="personalForm.controls['firstname'].hasError('required') || personalForm.controls['firstname'].hasError('ValidateWhiteSpace')">
                            First Name is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="personalForm.controls['firstname'].hasError('maxlength') && !personalForm.controls['firstname'].hasError('required')">
                            First Name is can be max 100 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                personalForm.controls['firstname'].hasError('pattern') && !personalForm.controls['firstname'].hasError('maxlength') && !personalForm.controls['firstname'].hasError('required')
                            ">
                            First Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Middle Name</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('middlename') }"
                        formControlName="middlename" type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="personalForm.controls['middlename'].errors && personalForm.controls['middlename'].touched">
                        <span class="error-msg"
                            *ngIf="personalForm.controls['middlename'].hasError('required') || personalForm.controls['middlename'].hasError('ValidateWhiteSpace')">
                            Middle Name is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="personalForm.controls['middlename'].hasError('maxlength') && !personalForm.controls['middlename'].hasError('required')">
                            Middle Name is can be max 100 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                personalForm.controls['middlename'].hasError('pattern') &&
                                !personalForm.controls['middlename'].hasError('maxlength') &&
                                !personalForm.controls['middlename'].hasError('required')
                            ">
                            Middle Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Last Name</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('lastname') }" formControlName="lastname"
                        type="text" required class="form-control" />
                    <div class="inp-err"
                        *ngIf="personalForm.controls['lastname'].errors && personalForm.controls['lastname'].touched">
                        <span class="error-msg"
                            *ngIf="personalForm.controls['lastname'].hasError('required') || personalForm.controls['lastname'].hasError('ValidateWhiteSpace')">
                            Last Name is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="personalForm.controls['lastname'].hasError('maxlength') && !personalForm.controls['lastname'].hasError('required')">
                            Last Name is can be max 100 characters.
                        </span>
                        <span class="error-msg"
                            *ngIf="personalForm.controls['lastname'].hasError('pattern') && !personalForm.controls['lastname'].hasError('maxlength') && !personalForm.controls['lastname'].hasError('required')">
                            Last Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Maiden Name</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('maidenname') }"
                        formControlName="maidenname" type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="personalForm.controls['maidenname'].errors && personalForm.controls['maidenname'].touched">
                        <span class="error-msg"
                            *ngIf="personalForm.controls['maidenname'].hasError('maxlength') && !personalForm.controls['maidenname'].hasError('required')">
                            Maiden Name is can be max 100 characters.
                        </span>
                        <span class="error-msg"
                            *ngIf="personalForm.controls['maidenname'].hasError('pattern') && !personalForm.controls['maidenname'].hasError('maxlength') && !personalForm.controls['maidenname'].hasError('required')">
                            Maiden Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Medical Record Number</label>
                    <input maxlength="255" [ngClass]="{ error: isFieldValid('mrn') }" formControlName="mrn" type="text"
                        class="form-control" />
                    <div class="inp-err"
                        *ngIf="personalForm.controls['mrn'].errors && personalForm.controls['mrn'].touched">
                        <span class="error-msg"
                            *ngIf="personalForm.controls['mrn'].hasError('required') || personalForm.controls['mrn'].hasError('ValidateWhiteSpace')">
                            Medical Record Number is required.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Gender</label>
                    <select formControlName="gender" [ngClass]="{ error: isFieldValid('gender') }" required
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="personalForm.controls['gender'].errors && personalForm.controls['gender'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['gender'].hasError('required')"> Gender is
                            required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Date of Birth</label>
                    <input formControlName="dob" [ngClass]="{ error: isFieldValid('dob') }" type="date"
                        class="form-control" [max]="minimumAge()" />
                    <div class="inp-err"
                        *ngIf="personalForm.controls['dob'].errors && personalForm.controls['dob'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['dob'].hasError('required')"> Date of Birth
                            is required. </span>
                        <span class="error-msg"
                            *ngIf="personalForm.controls['dob'].hasError('ValidateAge') && !personalForm.controls['dob'].hasError('required')">
                            User must be atleast 13 years old. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Marital Status</label>
                    <select formControlName="maritalstatus" [ngClass]="{ error: isFieldValid('maritalstatus') }"
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widow/er">Widow/er</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="personalForm.controls['maritalstatus'].errors && personalForm.controls['maritalstatus'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['maritalstatus'].hasError('required')">
                            Field is required. </span>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <div class="form-group">
                    <label>Children</label>
                    <select formControlName="children" [ngClass]="{ error: isFieldValid('children') }"
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="None">None</option>
                        <option value="1-2">1-2</option>
                        <option value="3-4">3-4</option>
                        <option value="> 4">> 4</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="personalForm.controls['children'].errors && personalForm.controls['children'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['children'].hasError('required')"> Field is
                            required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Grand Children</label>
                    <select formControlName="grandchildren" [ngClass]="{ error: isFieldValid('grandchildren') }"
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="None">None</option>
                        <option value="1-2">1-2</option>
                        <option value="3-4">3-4</option>
                        <option value="> 4">> 4</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="personalForm.controls['grandchildren'].errors && personalForm.controls['grandchildren'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['grandchildren'].hasError('required')">
                            Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Living Arrangement</label>
                    <select formControlName="livingarrangement" [ngClass]="{ error: isFieldValid('livingarrangement') }"
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="Alone">Alone</option>
                        <option value="With Others">With Others</option>
                        <option value="Assisted Living">Assisted Living</option>
                        <option value="Nursing Home">Nursing Home</option>
                        <option value="Family Caregivers">Family Caregivers</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="personalForm.controls['livingarrangement'].errors && personalForm.controls['livingarrangement'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['livingarrangement'].hasError('required')">
                            Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Employment</label>
                    <select formControlName="employment" [ngClass]="{ error: isFieldValid('employment') }"
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="Employed">Employed</option>
                        <option value="Unemployed">Unemployed</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="personalForm.controls['employment'].errors && personalForm.controls['employment'].touched">
                        <span class="error-msg" *ngIf="personalForm.controls['employment'].hasError('required')"> Field
                            is required. </span>
                    </div>
                </div>
                <div style="position: relative;" class="form-group">
                    <i (click)="showImage(imageURL ? imageURL : '../../../assets/images/add-image-health.png')"
                        class="fa fa-search graycolor showimage"></i>
                    <label>Photo ID</label>
                    <div class="image-block">
                        <div class="imagePreview">
                            <input type="file" accept="image/*" (change)="showPreview($event)" />
                            <button [ngClass]="isInvalidImage ? 'uploadbtnDirty' : 'uploadbtn'">
                                <img class="photoid-image"
                                    [src]="imageURL ? imageURL : '../../../assets/images/add-image-health.png'" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-row">
            <div class="main-left">
                <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            </div>
            <div class="main-right">
                <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
            </div>
        </div>
    </form>
</div>