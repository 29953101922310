// Core
import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarModule } from "ng-sidebar";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BnNgIdleService } from "bn-ng-idle";
import { AutosizeModule } from "ngx-autosize";
import { AvatarModule } from "ngx-avatar";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { HighchartsChartModule } from "highcharts-angular";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxPrintModule } from "ngx-print";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireModule } from "@angular/fire/compat";
// App
import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
import { environment } from "../environments/environment";
// Pages
import { PageLayoutComponent } from "./pages/pagelayout/pagelayout.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { ChatComponent } from "./pages/chat/chat.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { LinkManagementComponent } from "./pages/link-management/link-management.component";
import { GoalsComponent } from "./pages/goals/goals.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { CareteamComponent } from "./pages/superadmin/careteam/careteam.component";
import { EmployerComponent } from "./pages/employer/employer.component";
import { PushComponent } from "./pages/push/push.component";
// Services
import { AuthGuard } from "./services/guards/auth-guard.service";
import { AuthService } from "./services/auth.service";
import { ProviderService } from "./services/providers.service";
import { PatientService } from "./services/patients.service";
import { MessagingService } from "./services/messaging.service";
import { GoalsService } from "./services/goals.service";
import { CommunicationService } from "./services/communications.service";
import { AppsService } from "./services/apps.service";
import { CareTeamService } from "./services/careteam.service";
import { StatsService } from "./services/stats.service";
import { AnalyticsService } from "./services/analytics.service";
import { SessionService } from "./services/session.service";
import { UsersService } from "./services/users.service";
import { EmployersService } from "./services/employer.service";
import { ReportService } from "./services/report.service";
import { OnboardingService } from "./services/onboarding.service";
import { VideoCallService } from "./services/video-call.service";
import { TeleRXService } from "./services/teleRX.service";
import { RoleService } from "./services/role.service";
import { ChatTagsService } from "./services/chattags.service";
import { NotificationService } from "./services/notification.service";
import { WaitingRoomService } from "./services/waiting-room.service";
import { SpecialtyService } from "./services/specialty.service";
import { TableService } from "./services/table.service";
import { PracticeService } from "./services/practice.service";
import { AutoReplyService } from "./services/auto_reply.service";
import { MSXReportService } from "./services/msx_report.service";
import { MediaService } from "./services/media.service";
import { CampaignService } from "./services/campaign.service";
import { MembersService } from "./services/member.service";
// Providers
import { AvcHttp } from "./providers/avc-http";
import { Helper } from "./providers/helper";
import {
    KeysPipe,
    OrderBy,
    OrderByReadMessages,
    FilterMessagePipe,
    PicturePipe,
    ChanelTypePipe,
    UrlifyPipe,
    MenuItemsPipe,
    RoundPipe,
    ExensionTypePipe,
    BanchMarkFilterPipe,
} from "./providers/custom.pipe";
import { FilterPipe } from "../app/contextmenu/filter/filter.pipe";
import { SafePipe } from "./providers/safe.pipe";
import { AsyncPipe } from "../../node_modules/@angular/common";
import { AvcValidators } from "./providers/avc-validators";
import { DatePipe } from "@angular/common";
// Modals
import { WarningModalComponent } from "./modals/warning-modal/warning-modal.component";
import { PasswordChangeModalComponent } from "./modals/password-change-modal/password-change-modal.component";
import { TwoFactorAuthSetupComponent } from "./modals/two-factor-auth-setup/two-factor-auth-setup.component";
import { PatientDashboardModalComponent } from "./modals/patient-dashboard-modal/patient-dashboard-modal.component";
import { PasswordResetModalComponent } from "./modals/password-reset-modal/password-reset-modal.component";
import { ChatImageModalComponent } from "./modals/chat-image-modal/chat-image-modal.component";
import { VideoChatModalComponent } from "./modals/video-chat-modal/video-chat-modal.component";
import { ProviderAppModalComponent } from "./modals/providerapp-modal/providerapp-modal.component";
import { CareTeamModalComponent } from "./modals/careTeam-modal/careTeam-modal.component";
import { AddEmployerModalComponent } from "./modals/add-employer-modal/add-employer-modal.component";
import { OnboardModalComponent } from "./modals/onboard-modal/onboard-modal.component";
import { ChatModalComponent } from "./modals/chat-modal/chat-modal.component";
import { NotesModalComponent } from "./modals/notes-modal/notes-modal.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { SnackbarComponent } from "./shared/snackbar/snackbar.component";
import { ConfidentialityStatementModalComponent } from "./modals/confidentiality-statement-modal/confidentiality-statement-modal.component";
import { ContextmenuComponent } from "./contextmenu/contextmenu/contextmenu.component";
import { AddRemoveTagsComponent } from "./contextmenu/addremovetags/addremovetags.component";
import { CareTeamChangeModalComponent } from "./modals/careteam-change-modal/careteam-change-modal.component";
import { PatientComponent } from "./pages/patient/patient.component";
import { SmartHeightDirective } from "./directives/smart-height.directive";
import { DetailsComponent } from "./pages/patient/details/details.component";
import { VideoChatComponent } from "./pages/video-chat/video-chat.component";
import { LoaderComponent } from "./pages/loader/loader.component";
import { AppsComponent } from "./pages/apps/apps.component";
import { SessionComponent } from "./pages/session/session.component";
import { SmartLoadMoreDirective } from "./directives/smart-load-more.directive";
import { GoalModalComponent } from "./modals/goal-modal/goal-modal.component";
import { CheckboxGroupComponent } from "./shared/checkbox-group/checkbox-group.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { OnboardingLoginComponent } from "./pages/onboarding-login/onboarding-login.component";
import { AddAppModalComponent } from "./modals/add-app-modal/add-app-modal.component";
import { TabsModule } from "ngx-tabset";
import { AutoLoginComponent } from "./pages/auto-login/auto-login.component";
import { MenuItems } from "./_models/menuItems";
import { BulkuploadComponent } from "./pages/patient/bulkupload/bulkupload.component";
import { DiagnosticsComponent } from "./pages/diagnostics/diagnostics.component";
import { TwoFactorAutoLoginAuthSetupComponent } from "./modals/two-factor-autologin-auth-setup/two-factor-autologin-auth-setup.component";
import { MemberSearchComponent } from "./shared/member-search/member-search.component";
import { PatientDashboardComponent } from "./pages/patient-dashboard/patient-dashboard.component";
import { WaitingRoomComponent } from "./pages/waiting-room/waiting-room.component";
import { WaitingRoomDashboardComponent } from "./pages/waiting-room-dashboard/waiting-room-dashboard.component";
import { WaitingRoomReferralsComponent } from "./pages/waiting-room-referrals/waiting-room-referrals.component";
import { PatientGoalComponent } from "./shared/patient-goal/patient-goal.component";
import { PatientChatWindowComponent } from "./shared/patient-chat-window/patient-chat-window.component";
import { ShowGoalsModalComponent } from "./modals/show-goals-modal/show-goals-modal.component";
import { PrescriptionModalComponent } from "./modals/prescription-modal/prescription-modal.component";
import { PrescriptionViewModalComponent } from "./modals/prescription-view-modal/prescription-view-modal.component";
import { SummaryViewModalComponent } from "./modals/summary-view-modal/summary-view-modal.component";
import { SummaryCategoriesModalComponent } from "./modals/summary-category/summary-categories-modal/summary-categories-modal.component";
import { PersonalInformationModalComponent } from "./modals/summary-category/personal-information-modal/personal-information-modal.component";
import { WaitingRoomMoveModalComponent } from "./modals/waiting-room-move-modal/waiting-room-move-modal.component";
import { WaitingRoomReferModalComponent } from "./modals/waiting-room-refer-modal/waiting-room-refer-modal.component";
import { DragDropModule, CDK_DRAG_CONFIG } from "@angular/cdk/drag-drop";
import { ViewOptionsModalComponent } from "./modals/view-options-modal/view-options-modal.component";
import { WaitingRoomAddModalComponent } from "./modals/waiting-room-add-modal/waiting-room-add-modal.component";
import { ViewNotesModalComponent } from "./modals/view-notes-modal/view-notes-modal.component";
import { ContactInformationModalComponent } from "./modals/summary-category/contact-information-modal/contact-information-modal.component";
import { HealthInsuranceInformationModalComponent } from "./modals/summary-category/healthinsurance-information-modal/healthinsurance-information-modal.component";
import { PastMedicalInformationModalComponent } from "./modals/summary-category/pastmedical-information-modal/pastmedical-information-modal.component";
import { PastSurgicalInformationModalComponent } from "./modals/summary-category/pastsurgical-information-modal/pastsurgical-information-modal.component";
import { MedicationAllergiesInformationModalComponent } from "./modals/summary-category/medicationallergies-information-modal/medicationallergies-information-modal.component";
import { FoodAllergiesInformationModalComponent } from "./modals/summary-category/foodallergies-information-modal/foodallergies-information-modal.component";
import { EnvironmentalAllergiesModalComponent } from "./modals/summary-category/environmentalallergies-modal/environmentalallergies-modal.component";
import { SocialInformationModalComponent } from "./modals/summary-category/social-information-modal/social-information-modal.component";
import { AllergiesModalComponent } from "./modals/summary-category/allergies-modal/allergies-modal.component";
import { MoodWellBeingModalComponent } from "./modals/summary-category/mood-wellbeing-modal/mood-wellbeing-modal.component";
import { FamilyHistoryModalComponent } from "./modals/summary-category/familyhistory-modal/familyhistory-modal.component";
import { VaccinesModalComponent } from "./modals/summary-category/vaccines-modal/vaccines-modal.component";
import { WomenHealthModalComponent } from "./modals/summary-category/womenhealth-modal/womenhealth-modal.component";
import { Covid19ModalComponent } from "./modals/summary-category/covid-19-modal/covid-19-modal.component";
import { SymptomGeneralModalComponent } from "./modals/symptom-category/symptom-general-modal/symptom-general-modal.component";
import { SymptomCategoryModalComponent } from "./modals/symptom-category/symptom-category-modal/symptom-category-modal.component";
import { SymptomENTModalComponent } from "./modals/symptom-category/symptom-ent-modal/symptom-ent-modal.component";
import { SymptomGasModalComponent } from "./modals/symptom-category/symptom-gas-modal/symptom-gas-modal.component";
import { SymptomHeartLungModalComponent } from "./modals/symptom-category/symptom-heartlung-modal/symptom-heartlung-modal.component";
import { SymptomNeuroModalComponent } from "./modals/symptom-category/symptom-neuro-modal/symptom-neuro-modal.component";
import { SymptomMusculoModalComponent } from "./modals/symptom-category/symptom-musculo-modal/symptom-musculo-modal.component";
import { SymptomObGynModalComponent } from "./modals/symptom-category/symptom-ob-gyn-modal/symptom-ob-gyn-modal.component";
import { SymptomSkinModalComponent } from "./modals/symptom-category/symptom-skin-modal/symptom-skin-modal.component";
import { SymptomUroModalComponent } from "./modals/symptom-category/symptom-uro-modal/symptom-uro-modal.component";
import { SymptomOtherModalComponent } from "./modals/symptom-category/symptom-other-modal/symptom-other-modal.component";
import { SymptomConfirmModalComponent } from "./modals/symptom-category/symptom-confirm-modal/symptom-confirm-modal.component";
import { CoveredServicesModalComponent } from "./modals/covered-services-modal/covered-services-modal.component";
import { BadWordAddModalComponent } from "./modals/bad-word-add-modal/bad-word-add-modal.component";
import { LocationModalComponent } from "./modals/location-modal/location-modal.component";
import { ManageProvidersModalComponent } from "./modals/manage-providers-modal/manage-providers-modal.component";
import { SubscribeTeleRXModalComponent } from "./modals/subscribe-telerx-modal/subscribe-telerx-modal.component";
import { IncidentModalComponent } from "./modals/incident-modal/incident-modal.component";
import { IncidentListingModalComponent } from "./modals/incident-listing-modal/incident-listing-modal.component";
import { phq9ModalComponent } from "./modals/summary-category/phq9-modal/phq9-modal.component";
import { phq9DetailsModalComponent } from "./modals/summary-category/phq9-modal/details/details-modal.component";
import { IncidentWindowComponent } from "./shared/incident-window/incident-window.component";
import { ChatWindowComponent } from "./shared/chat-window/chat-window.component";
import { GrdFilterPipe } from "./providers/grd-filter.pipe";
import { UserSettingsModalComponent } from "./modals/user-settings-modal/user-settings-modal.component";
import { MediaLibraryComponent } from "./shared/media-library/media-library.component";
import { SettingsComponent as AdminSettingComponent } from "./pages/superadmin/settings/settings.component";
import { NgbDateCustomParserFormatter } from "./providers/date-formatter.service";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { TelerxListingComponent } from "./pages/teleRx/telerx-listing/telerx-listing.component";
import { TelerxDetailComponent } from "./pages/teleRx/telerx-detail/telerx-detail.component";
import { AnalyticsListingComponent } from "./pages/superadmin/analytics-listing/analytics-listing.component";
import { AnalyticsDashboardComponent } from "./pages/superadmin/analytics-dashboard/analytics-dashboard.component";
import { BadWordComponent } from "./pages/superadmin/badword/badword.component";
import { SortableHeaderDirective } from "./directives/sortable.directive";
import { LocationComponent } from "./pages/location/location.component";
import { IncidentBillingComponent } from "./modals/incident-billing/incident-billing.component";
import { clinicalSummaryComponent } from "./shared/clinicalsummary-window/clinicalsummary-window.component";
import { AppsModalComponent } from "./modals/apps-modal-v2/apps-modal-v2.component";
import { SearchChatModalComponent } from "./modals/search-chat-modal/search-chat-modal.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { AutoReplyComponent } from "./pages/auto-reply/auto-reply.component";
import { ScheduleModalComponent } from "./modals/schedule-modal/schedule-modal.component";
import { ChatbotKeywordModalComponent } from "./modals/chatbot-keyword-modal/chatbot-keyword-modal.component";
import { VisitDataComponent } from "./pages/visit-data/visit-data.component";
import { MetricComponent } from "./msx_report/metric/metric.component";
import { PatientVisitsDataComponent } from "./pages/MSX/patient-visits-data/patient-visits-data.component";
import { VisitsDataComponent } from "./pages/MSX/patient-visits-data/visits-data/visits-data.component";
import { GraphComponent } from "./pages/MSX/graph/graph.component";
import { SurveyDataComponent } from "./pages/MSX/survey-data/survey-data.component";
import { ServiceRecoveryModalComponent } from "./modals/service-recovery-modal/service-recovery-modal.component";
import { PatientExperienceComponent } from "./pages/MSX/patient-experience/patient-experience.component";
import { MetricGraphComponent } from "./pages/MSX/graphs/metric-graph/metric-graph.component";
import { MetricModalComponent } from "./pages/MSX/modals/metric-modal/metric-modal.component";
import { EntityModalComponent } from "./pages/MSX/modals/entity-modal/entity-modal.component";
import { SelectedGroupComponent } from "./pages/MSX/selected-group/selected-group.component";
import { FiltersModalComponent } from "./pages/MSX/modals/filters-modal/filters-modal.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ServiceRecoveryComponent } from "./pages/service-recovery/service-recovery.component";
import { VisitsFiltersModalComponent } from "./pages/MSX/modals/visits-filters-modal/visits-filters-modal.component";
import { MsxReportsComponent } from "./pages/MSX/msx-reports/msx-reports.component";
import { ServiceRecoveryModal } from "./pages/MSX/modals/service-recovery-modal/service-recovery-modal.component";
import { PatientBillingComponent } from "./pages/MSX/patient-billing/patient-billing.component";
import { BillingDataComponent } from "./pages/MSX/billing-data/billing-data.component";
import { PatientBillingGraphComponent } from "./pages/MSX/patient-billing-graph/patient-billing-graph.component";
import { BillingMetricModalComponent } from "./pages/MSX/modals/billing-metric-modal/billing-metric-modal.component";
import { FiltersModalV2Component } from "./pages/MSX/modals/filters-modal-v2/filters-modal-v2.component";

import { FavoritesModalComponent } from "./pages/MSX/modals/favorites-modal/favorites-modal.component";
import { xAsisFiltersModalComponent } from "./pages/MSX/modals/xAsis-filters-modal/xAsis-filters-modal.component";

import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { FavoritesComponent } from "./pages/MSX/favorites/favorites.component";

import { ScheduleReportModalComponent } from "./pages/MSX/modals/schedule-report-modal/schedule-report-modal.component";
import { AdministrationComponent } from "./pages/MSX/administration/administration.component";
import { EscalateKeywordsComponent } from "./pages/service-recovery/escalate-keywords/escalate-keywords.component";
import { EditLinkComponent } from "./pages/MSX/social-media/edit-link/edit-link.component";
import { ReviewsComponent } from "./pages/MSX/social-media/reviews/reviews/reviews.component";
import { ActivityComponent } from "./pages/MSX/social-media/activity/activity/activity.component";

import { ReviewModalComponent } from "./pages/MSX/social-media/reviews/reviews/review-modal/review-modal.component";
import { AddLinkComponent } from "./pages/MSX/social-media/add-link/add-link.component";
import { ScheduleReportComponent } from "./pages/MSX/administration/schedule-report/schedule-report.component";

import { MemberModalComponent } from "./pages/MSX/modals/member-modal/member-modal.component";

import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from "@abacritt/angularx-social-login";

import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from "@googlemaps/js-api-loader";
import { GoogleSigninComponent } from "./google-signin/google-signin.component";
import { CampaignComponent } from "./pages/campaign/campaign.component";

import { TextEditorModalComponent } from "./pages/MSX/modals/campaign/text-editor-modal/text-editor-modal.component";
import { TemplateModalComponent } from "./pages/MSX/modals/campaign/template-modal/template-modal.component";

import { AngularEditorModule } from "@kolkov/angular-editor";
import { PatientOutreachComponent } from "./pages/MSX/patient-outreach/patient-outreach.component";
import { DuplicateCampaignModalComponent } from "./pages/MSX/modals/duplicate-campaign-modal/duplicate-campaign-modal.component";
import { TestMailModalComponent } from "../app/pages/MSX/modals/campaign/test-mail-modal/test-mail-modal.component";
import { UnsubscribeComponent } from "./pages/MSX/unsubscribe/unsubscribe.component";
import { MembersComponent } from "./pages/MSX/members/members.component";

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 10000,
};
@NgModule({
    imports: [
        AutocompleteLibModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        routing,
        CommonModule,
        BrowserModule,
        SidebarModule.forRoot(),
        BrowserAnimationsModule,
        NgbModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        AutosizeModule,
        HttpClientModule,
        AvatarModule,
        TabsModule.forRoot(),
        DragDropModule,
        NgxQRCodeModule,
        HighchartsChartModule,
        NgxPrintModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgMultiSelectDropDownModule.forRoot(),
        SocialLoginModule,
        NgxGpAutocompleteModule,
        AngularEditorModule,
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        PageLayoutComponent,
        HomeComponent,
        OrdersComponent,
        LinkManagementComponent,
        GoalsComponent,
        WarningModalComponent,
        ReportsComponent,
        FilterPipe,
        KeysPipe,
        OrderBy,
        OrderByReadMessages,
        FilterMessagePipe,
        PicturePipe,
        UrlifyPipe,
        ChanelTypePipe,
        SafePipe,
        ChatComponent,
        PasswordChangeModalComponent,
        DuplicateCampaignModalComponent,
        PasswordResetModalComponent,
        ChatImageModalComponent,
        VideoChatModalComponent,
        ProviderAppModalComponent,
        SnackbarComponent,
        ConfidentialityStatementModalComponent,
        TwoFactorAuthSetupComponent,
        TwoFactorAutoLoginAuthSetupComponent,
        PageNotFoundComponent,
        ContextmenuComponent,
        AddRemoveTagsComponent,
        CareTeamChangeModalComponent,
        PatientComponent,
        SmartHeightDirective,
        DetailsComponent,
        PatientDashboardModalComponent,
        VideoChatComponent,
        LoaderComponent,
        AppsComponent,
        SessionComponent,
        SmartLoadMoreDirective,
        GoalModalComponent,
        SettingsComponent,
        OnboardingLoginComponent,
        AddAppModalComponent,
        AutoLoginComponent,
        MenuItemsPipe,
        CareTeamModalComponent,
        CareteamComponent,
        EmployerComponent,
        AddEmployerModalComponent,
        PushComponent,
        BulkuploadComponent,
        DiagnosticsComponent,
        MemberSearchComponent,
        PatientDashboardComponent,
        WaitingRoomComponent,
        WaitingRoomDashboardComponent,
        WaitingRoomReferralsComponent,
        OnboardModalComponent,
        PatientGoalComponent,
        PatientChatWindowComponent,
        ChatModalComponent,
        NotesModalComponent,
        RoundPipe,
        ExensionTypePipe,
        AppsModalComponent,
        ShowGoalsModalComponent,
        PrescriptionModalComponent,
        PrescriptionViewModalComponent,
        SummaryViewModalComponent,
        SummaryCategoriesModalComponent,
        PersonalInformationModalComponent,
        WaitingRoomMoveModalComponent,
        WaitingRoomReferModalComponent,
        ViewOptionsModalComponent,
        WaitingRoomAddModalComponent,
        ViewNotesModalComponent,
        ContactInformationModalComponent,
        HealthInsuranceInformationModalComponent,
        PastMedicalInformationModalComponent,
        PastSurgicalInformationModalComponent,
        MedicationAllergiesInformationModalComponent,
        FoodAllergiesInformationModalComponent,
        EnvironmentalAllergiesModalComponent,
        SocialInformationModalComponent,
        AllergiesModalComponent,
        MoodWellBeingModalComponent,
        FamilyHistoryModalComponent,
        VaccinesModalComponent,
        WomenHealthModalComponent,
        Covid19ModalComponent,
        SymptomGeneralModalComponent,
        SymptomCategoryModalComponent,
        SymptomENTModalComponent,
        SymptomGasModalComponent,
        SymptomHeartLungModalComponent,
        SymptomNeuroModalComponent,
        SymptomMusculoModalComponent,
        SymptomObGynModalComponent,
        SymptomSkinModalComponent,
        SymptomUroModalComponent,
        SymptomOtherModalComponent,
        SymptomConfirmModalComponent,
        CoveredServicesModalComponent,
        BadWordAddModalComponent,
        SubscribeTeleRXModalComponent,
        IncidentModalComponent,
        IncidentListingModalComponent,
        CheckboxGroupComponent,
        ChatWindowComponent,
        IncidentWindowComponent,
        GrdFilterPipe,
        UserSettingsModalComponent,
        MediaLibraryComponent,
        AdminSettingComponent,
        TelerxListingComponent,
        TelerxDetailComponent,
        AnalyticsListingComponent,
        AnalyticsDashboardComponent,
        BadWordComponent,
        SortableHeaderDirective,
        LocationComponent,
        LocationModalComponent,
        ManageProvidersModalComponent,
        phq9ModalComponent,
        phq9DetailsModalComponent,
        IncidentBillingComponent,
        clinicalSummaryComponent,
        SearchChatModalComponent,
        ResetPasswordComponent,
        AutoReplyComponent,
        ScheduleModalComponent,
        ChatbotKeywordModalComponent,
        VisitDataComponent,
        MetricComponent,
        PatientVisitsDataComponent,
        VisitsDataComponent,
        VisitDataComponent,
        GraphComponent,
        SurveyDataComponent,
        ServiceRecoveryModalComponent,
        PatientExperienceComponent,
        MetricGraphComponent,
        MetricModalComponent,
        EntityModalComponent,
        SelectedGroupComponent,
        FiltersModalComponent,
        VisitsFiltersModalComponent,
        ServiceRecoveryComponent,
        MsxReportsComponent,
        ServiceRecoveryModal,
        PatientBillingComponent,
        BillingDataComponent,
        PatientBillingGraphComponent,
        BillingMetricModalComponent,
        FiltersModalV2Component,
        FavoritesModalComponent,
        xAsisFiltersModalComponent,
        BanchMarkFilterPipe,
        FavoritesComponent,
        ScheduleReportModalComponent,
        AdministrationComponent,
        EscalateKeywordsComponent,
        EditLinkComponent,
        ReviewsComponent,
        ActivityComponent,
        ReviewModalComponent,
        AddLinkComponent,
        ScheduleReportComponent,
        GoogleSigninComponent,
        CampaignComponent,
        TextEditorModalComponent,
        PatientOutreachComponent,
        TemplateModalComponent,
        TestMailModalComponent,
        UnsubscribeComponent,
        MembersComponent,
        MemberModalComponent,
    ],
    providers: [
        AvcHttp,
        AuthService,
        AuthGuard,
        SessionService,
        AvcValidators,
        DatePipe,
        Helper,
        ProviderService,
        PatientService,
        CommunicationService,
        GoalsService,
        MessagingService,
        AppsService,
        CareTeamService,
        AsyncPipe,
        StatsService,
        AnalyticsService,
        BnNgIdleService,
        UsersService,
        EmployersService,
        ReportService,
        OnboardingService,
        VideoCallService,
        TeleRXService,
        MenuItems,
        RoleService,
        ChatTagsService,
        NotificationService,
        WaitingRoomService,
        SpecialtyService,
        NgbActiveModal,
        PracticeService,
        AutoReplyService,
        MSXReportService,
        CampaignService,
        MediaService,
        MembersService,
        { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        TableService,
        DecimalPipe,
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: "AIzaSyDze3wDADXiB2IOpE2A_jASLwcDbNxKZRo",
                libraries: ["places"],
            }),
        },
        {
            provide: "SocialAuthServiceConfig",
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider("1014746750290-t9vqtqqbotqtj2tfjrlu4vkcoruhhr4u.apps.googleusercontent.com", {
                            scopes: "openid profile email",
                        }),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
