<div class="modal-body">
    <div class="category-header">
        <label>{{ AssessmentLabel }}</label>

        <table class="table table-striped datatable" style="text-align: left">
            <thead>
                <tr>
                    <th scope="col">date</th>
                    <th scope="col">score</th>
                    <th scope="col">severity</th>
                    <th scope="col">show details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of assessment | slice:0:10;">
                    <td>{{ a?.date | date: "MM/dd/yyyy, h:mm a" }}</td>
                    <td>{{ score(a.details).score }}</td>
                    <td>{{ score(a.details).severity }}</td>
                    <td><i (click)="showDetails(a)" class="fa fa-external-link-alt cursor-pointer"
                            aria-hidden="true"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="main-row">
        <p *ngIf="error" class="text-danger">{{ error }}</p>
    </div>
    <div class="main-row main-row-footer">
        <button type="button" class="btn btn-secondary float-right" (click)="onCancel()">Cancel</button>
    </div>
</div>