<div class="spinner-border" style="margin: 5px" role="status" *ngIf="loadingPage">
    <span class="sr-only">Loading...</span>
</div>

<div class="container-fluid" style="padding-top: 50px" *ngIf="!loadingPage">
    <form [formGroup]="pushMessageForm">
        <div class="col col-12">
            <div class="card mx-auto">
                <div class="card-body">
                    <h4 class="mb-3">Send Push message to Clio GO Users</h4>
                    <div class="input-group mb-3" *ngIf="false">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Care Team:</span>
                        </div>
                        <select class="selectpicker form-control" formControlName="careteam" (change)="onChangeCareTeam()">
                            <option *ngFor="let c of careteams" [value]="c.careteamid">{{ c.careteamname }}</option>
                        </select>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Send To:</span>
                        </div>
                        <select formControlName="users" class="selectpicker form-control">
                            <option value="all">All</option>
                            <option value="android">Android Users</option>
                            <option value="ios">IOS Users</option>
                        </select>
                    </div>
                    <div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Employer:</span>
                            </div>
                            <select formControlName="employer" class="selectpicker form-control">
                                <option value=""></option>
                                <option *ngFor="let e of employers" [value]="e.employername">{{ e.employername }}</option>
                            </select>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Min Age:</span>
                                    </div>
                                    <input formControlName="minage" type="number" class="col-12 form-control" />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Max Age:</span>
                                    </div>
                                    <input formControlName="maxage" type="number" class="col-12 form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Disease:</span>
                                    </div>
                                    <input formControlName="disease" type="text" class="form-control" />
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Gender:</span>
                                    </div>
                                    <select formControlName="gender" class="selectpicker form-control">
                                        <option value="both">All</option>
                                        <option value="male">Men</option>
                                        <option value="female">Women</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Message: </span>
                        </div>
                        <textarea formControlName="message" class="form-control" rows="3" [(ngModel)]="message"></textarea>
                    </div>
                    <div>
                        <button type="button" disabled="true" class="btn btn-primary float-right" (click)="sendPush()">Send Push</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
