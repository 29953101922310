export class MenuItems {
    public menus = [
        {
            displayName: "Home",
            route: "home",
            class: "fas fa-2x fa-fw fa-home members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider", "admin"],
            activeIcon: "/assets/menuIcons/home.svg",
            inActiveIcon: "/assets/menuIcons/home.svg"
        },
        {
            displayName: "Patients",
            route: "patients",
            class: "fas fa-2x fa-fw fa-users members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider", "admin"],
            activeIcon: "/assets/menuIcons/members.svg",
            inActiveIcon: "/assets/menuIcons/members.svg"

        },
        {
            displayName: "Patient Communication",
            route: "chat",
            class: "fas fa-2x fa-fw fa-comment-dots members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            activeIcon: "/assets/menuIcons/careteamchat.svg",
            inActiveIcon: "/assets/menuIcons/careteamchat.svg"

        },
        {
            displayName: "Patient Escalation",
            route: "waiting-room",
            class: "fas fa-2x fa-fw fa-user-clock members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/menuIcons/patientescalation.svg",
            inActiveIcon: "/assets/menuIcons/patientescalation.svg"

        },
        {
            displayName: "Patient Experience",
            route: "reports",
            class: "fas fa-2x fa-fw fa-user-clock members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/menuIcons/patientexperience.svg",
            inActiveIcon: "/assets/menuIcons/patientexperience.svg"
        },
        {
            displayName: "Patient Visits",
            route: "patient-visits",
            class: "fas fa-2x fa-fw fa-calendar",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/menuIcons/patientvisits.svg",
            inActiveIcon: "/assets/menuIcons/patientvisits.svg"
        },
        {
            displayName: "Patient Billing",
            route: "#",
            class: "fas fa-2x fa-fw fa-user-clock members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/menuIcons/patientbilling.svg",
            inActiveIcon: "/assets/menuIcons/patientbilling.svg"
        },
        {
            displayName: "Patient Engagement",
            route: "#",
            class: "fas fa-2x fa-fw fa-user-clock members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/menuIcons/campaignmanager.svg",
            inActiveIcon: "/assets/menuIcons/campaignmanager.svg"
        },
        {
            displayName: "Administration",
            route: "#",
            class: "fas fa-2x fa-fw fa-user-clock members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/menuIcons/admin.svg",
            inActiveIcon: "/assets/menuIcons/admin.svg"
        },
        {
            displayName: "Socialmedia",
            route: "socialmedia",
            class: "fas fa-2x fa-fw fa-user-clock members-icon",
            iconType: "image",
            availableFor: ["provider", "superprovider"],
            hasSubMenu: true,
            activeIcon: "/assets/msx/social.svg",
            inActiveIcon: "/assets/msx/social.svg"
        },
        // {
        //     displayName: "Links",
        //     route: "orders",
        //     class: "fas fa-2x fa-fw fa-link members-icon",
        //     iconType: "fontawesome",
        //     availableFor: ["provider", "superprovider"],
        // },
        {
            displayName: "Link Management",
            route: "link-management",
            class: "fas fa-2x fa-fw fa-bezier-curve members-icon",
            iconType: "fontawesome",
            availableFor: ["admin", "provider", "superprovider"],
        },
        // {
        //     displayName: "Goals",
        //     route: "goals",
        //     class: "fas fa-2x fa-fw fa-bullseye members-icon",
        //     iconType: "fontawesome",
        //     availableFor: ["provider", "superprovider"],
        // },

        // {
        //     displayName: "Care Teams",
        //     route: "careteams",
        //     class: "fas fa-heart fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["admin"],
        // },
        // {
        //     displayName: "Management",
        //     route: "",
        //     class: "fas fa-2x fa-fw fa-tasks members-icon",
        //     iconType: "fontawesome",
        //     availableFor: ["provider", "superprovider", "admin"],
        //     hasSubMenu: true,
        // },

        // {
        //     displayName: "Reports",
        //     route: "reports",
        //     class: "fas fa-2x fa-fw fa-file-alt members-icon",
        //     iconType: "fontawesome",
        //     availableFor: ["provider", "superprovider", "admin"],
        // },
        // {
        //     displayName: "Locations",
        //     route: "location",
        //     class: "fa fa-map-marker-alt fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["admin"],
        // },
        // {
        //     displayName: "Settings",
        //     route: "setting",
        //     class: "fas fa-cog fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["superprovider", "admin"],
        // },
        // {
        //     displayName: "Users",
        //     route: "admin",
        //     class: "fas fa-cog fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["superadmin"],
        // },
        // {
        //     displayName: "Teleremedies",
        //     route: "telerx",
        //     class: "fas fa-medkit fa-2x fa-fw fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["superadmin"],
        // },
        // {
        //     displayName: "Analytics Dashboard",
        //     route: "analytics-dashboard",
        //     class: "fas fa-chart-bar fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["superadmin"],
        // },
        // {
        //     displayName: "Analytics Log",
        //     route: "analytics-listing",
        //     class: "fas fa-align-justify fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["superadmin"],
        // },

        // {
        //     displayName: "Chat Filter",
        //     route: "badwords",
        //     class: "fas fa-filter fa-2x fa-fw",
        //     iconType: "fontawesome",
        //     availableFor: ["superadmin"],
        // },
    ];
    // {
    //     displayName: "Notification Center",
    //     route: "notifications",
    //     class: "fas fa-2x fa-fw fas fa-exclamation-circle members-icon",
    //     iconType: "fontawesome",
    //     availableFor: ["superprovider"],
    // },
    // {
    //     displayName: "Campaign Manager",
    //     route: "/session/" + encodeURIComponent(environment.campaignManagerServerUri),
    //     class: "fas fa-2x fa-fw fa-mail-bulk members-icon",
    //     iconType: "fontawesome",
    //     availableFor: ["provider"],
    // },
    // {
    //     displayName: "Care Team Providers",
    //     route: "providers",
    //     class: "fas fa-heartbeat fa-2x fa-fw",
    //     iconType: "fontawesome",
    //     availableFor: ["admin"],
    // },
    // {
    //     displayName: "Employers",
    //     route: "employers",
    //     class: "fas fa-2x fa-fw fa-users members-icon",
    //     iconType: "fontawesome",
    //     availableFor: ["admin", "superprovider"],
    // },
}
