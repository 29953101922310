import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
@Component({
    selector: "favorites-modal",
    templateUrl: "./favorites-modal.component.html",
    styleUrls: ["./favorites-modal.component.scss"],
})
export class FavoritesModalComponent implements OnInit {
    constructor(private activeModal: NgbActiveModal, private msxReportService: MSXReportService, private snackbar: SnackbarService) { }

    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    @Input() graphName = "";
    @Input() name = "";
    @Input() delete = false;
    @Input() heading = "Set Favorite"
    @Input() msg = "Are you want to save these settings as favorites?"
    ngOnInit() {

    }
    public onSubmit() {

        if (this.name.trim() === "") {
            this.snackbar.show("Please enter graph name to save in favorites.", "danger");
        }
        else {
            this.Response.emit({
                parameters: this.name
            });
            this.activeModal.close();
        }
    }

    public onCancel() {
        this.activeModal.close();
    }

}
