import { Component, OnInit, Input } from "@angular/core";
import { GoalsService } from "../../services/goals.service";
import { CareTeamService } from "../../services/careteam.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { ActivatedRoute } from "@angular/router";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { GoalModalComponent } from "../../modals/goal-modal/goal-modal.component";
import { ChartService } from "../../services/chart.service";
import { StatsService } from "../../services/stats.service";
import { OrderBy } from "../../providers/custom.pipe";
import { ShowGoalsModalComponent } from "../../modals/show-goals-modal/show-goals-modal.component";
import { SessionService } from "../../services/session.service";
import { Helper } from "../../providers/helper";
import * as Highcharts from "highcharts";

@Component({
    selector: "app-patient-goal",
    templateUrl: "./patient-goal.component.html",
    styleUrls: ["./patient-goal.component.scss"],
})
export class PatientGoalComponent implements OnInit {
    @Input() patient: any;
    @Input() patientDashboardSetting: any;
    @Input() orderByCompliancePer = true;
    Highcharts: typeof Highcharts = Highcharts; // required
    constructor(
        private goalsService: GoalsService,
        private careTeamService: CareTeamService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private snackbar: SnackbarService,
        private chartService: ChartService,
        private statsService: StatsService,
        public sessionService: SessionService,
        private helper: Helper
    ) { }
    userId: String;
    user: any = {};
    patients: any = [];
    goals: any = [];
    goalsPerFrequency: any = [];
    _graphData = [];
    colors = ["#2f7ed8", "#0d233a", "#8bbc21", "#910000", "#1aadce", "#492970", "#f28f43", "#77a1e5", "#c42525", "#a6c96a"];
    complianceGraphOptions: any = [];
    public chartOptions: any;
    _goaldata: any;
    selectedgoalid: any;
    frequencytypes: any = [
        { label: "Yearly", id: "Yearly", selected: false },
        { label: "Monthly", id: "Monthly", selected: false },
        { label: "Weekly", id: "Weekly", selected: false },
        { label: "Once", id: "Once", selected: false },
    ];
    frequencytype = "Weekly";
    ngOnInit(): void {
        this.userId = this.patient.patientid;
        this.loadGoals();
    }
    loadGoalsByFrequency() {
        this.goalsPerFrequency = this.goals;
        this.chartOptions = [];
        this.selectedgoalid = "";
    }
    loadGraphforGoal(goalId) {
        this.selectedgoalid = goalId;
        let goal = this.complianceGraphOptions.filter((x) => x.list[0].goalid === goalId);
        this.loadGraph(goal);
    }
    loadGoals() {
        this.goalsService.goalByPatient(this.userId).subscribe(
            (data) => {
                this._goaldata = data[0].attributes || [];
                this._graphData = data[0].attributes;
                this.goals = [];
                if (this._graphData && this._graphData.length > 0) {
                    this.complianceGraphOptions = [];
                    for (let goal of this._graphData) {
                        goal.list.sort(function (a, b) {
                            let x = parseInt(a.gtime.replace(":", ""));
                            let y = parseInt(b.gtime.replace(":", ""));
                            return x - y;
                        });
                        let goaltimename = goal.list.map((e) => e.goaltime.goaltimename).join(",");
                        let goaltimes = goal.list.map((e) => e.gtime).join(",");
                        let goalformattedtimes = goal.list.map((e) => e.gtimeformat).join(",");
                        this.complianceGraphOptions.push(goal);
                        this.goals.push({
                            goalname: goal.goalname,
                            startdate: goal.list[0].startdate,
                            enddate: goal.list[0].enddate,
                            goaltarget: goal.list[0].goaltarget,
                            status: goal.list[0].graphenabled,
                            goalid: goal.list[0].goalid,
                            userid: goal.list[0].userid,
                            goaltypename: goal.list[0].goaltype.goaltypename,
                            scheduledate: goal.list[0].scheduledate,
                            goaltimename: goaltimename,
                            frequencies: goal.list[0].frequencies,
                            frequencytype: goal.list[0].scheduletype,
                            datatype: goal.list[0].datatype,
                            gdcid: goal.list[0].gdcid,
                            goaltypeid: goal.list[0].goaltypeid,
                            compliancePer: goal.compliancePer,
                            target1: goal.list[0].target1,
                            target2: goal.list[0].target2,
                            notes: goal.list[0].notes,
                            notifyprovider: goal.list[0].notifyprovider,
                            allowearlyentry: goal.list[0].allowearlyentry,
                            goaltimes: goaltimes,
                            goalformattedtimes: goalformattedtimes,
                            goaltimeid: goal.goaltime.goaltimeid,
                            associatedapp: goal.list[0].associatedapp !== null ? goal.list[0].associatedapp.replace("app://", "") : "",
                        });
                    }
                    this.goals.sort((a, b) => (a.goalname.toLocaleLowerCase() > b.goalname.toLocaleLowerCase() ? 1 : -1));
                    this.loadGoalsByFrequency();
                } else {
                    this.goalsPerFrequency = [];
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    /**
     * Opens a modal to add a new goal
     */
    public onAddGoal() {
        const modalRef = this.modalService.open(GoalModalComponent, { size: "lg", windowClass: "modal-goal-large-center", centered: true, backdrop: "static" });
        modalRef.componentInstance.PatientId = this.userId;
        this.goals.forEach((element) => {
            modalRef.componentInstance.ExistingGoal.push(element.goalname);
        });
        modalRef.componentInstance.Response.subscribe(
            (response) => {
                // Goal added successfully
                this.snackbar.show(response);
                this.loadGoals();
                this.chartOptions = [];
                this.selectedgoalid = "";
            },
            (err) => {
                console.error(err);
                this.snackbar.show(err, "danger");
            }
        );
    }
    /**
     * Opens a modal to edit an existing goal
     */
    public onEditGoal(goal) {
        const modalRef = this.modalService.open(GoalModalComponent, { size: "lg", windowClass: "modal-goal-large-center", centered: true, backdrop: "static" });
        modalRef.componentInstance.PatientId = this.userId;
        modalRef.componentInstance.Goal = goal;
        modalRef.componentInstance.Response.subscribe(
            (response) => {
                // Goal added successfully
                this.snackbar.show(response);
                this.loadGoals();
                this.chartOptions = [];
                this.selectedgoalid = "";
            },
            (err) => {
                console.error(err);
                this.snackbar.show(err, "danger");
            }
        );
    }

    public onDeleteGoal(goal) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this goal?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.goalsService.deleteGoal(goal.goalname, this.userId).subscribe(
                    (data) => {
                        this.snackbar.show(data[0].attributes);
                        this.loadGoals();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    getCompliancePer(compliance) {
        if (compliance && compliance !== "") {
            return compliance;
        } else {
            return 0;
        }
    }

    loadGraph(goal) {
        this.chartOptions = this.chartService.drawGraph(goal[0], "column");
    }
    public statusColor(color) {
        if (color) {
            return color.replace("#", "A");
        }
    }
    public perStatusColor(compliance) {
        let per_status = this.getCompliancePer(compliance);
        let className = per_status > 80 ? "barGreen" : per_status <= 80 && per_status > 60 ? "barYellow" : "barRed";
        return className;
    }
    public sortByCompliancePer() {
        const orderByPipe = new OrderBy();
        this._graphData = orderByPipe.transform(this._graphData, "compliancePer", !this.orderByCompliancePer);
        this.goals = orderByPipe.transform(this.goals, "compliancePer", !this.orderByCompliancePer);
        this.orderByCompliancePer = !this.orderByCompliancePer;
        this.loadGraph(this._graphData);
    }
    public getColor(color) {
        return "#" + color;
    }

    public showGoals() {
        const modalRef = this.modalService.open(ShowGoalsModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.PatientId = this.patient.patientid;
        modalRef.componentInstance.GoalsData = this._goaldata;
        modalRef.componentInstance.showAllGoals = true;
    }
    public isOpenedTab(name) {
        this.helper.isOpenedTab(name);
    }
}
