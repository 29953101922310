import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class RoleService {
    private handler = `/roles`;
    private url = `${environment.uri}${this.handler}`;

    public ROLE_ID_PROVIDER_ADMIN = "2e698bd9-7f75-4aeb-9815-7b55c4915f22";
    public ROLE_ID_PROVIDER = "84cfa1dc-1ce3-4159-99f0-824340ebfd28";
    public ROLE_ID_USER = "cf05bbd0-856a-4a88-98fb-0011ca8ec54d";
    public ROLE_ID_ADMIN = "214e8a8e-6ec4-4659-8465-ed94efdfb221";

    constructor(private avcHttp: AvcHttp, private helper: Helper) {}

    public getRoles(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        let roles = body.data[0].attributes.map((el) => {
                            el.displayname = this.getDisplayName(el.name);
                            return el;
                        });
                        observer.next(roles);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public getAssignRole(userId: string): Observable<any> {
        let _url = `${environment.uri}`;
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", _url + "/user/roles/" + userId, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public deleteRole(roleId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({ roleId: roleId });
            this.avcHttp.sendRequest("delete", this.url, data).subscribe(
                (body) => {
                    if (body.response === "") {
                        observer.next("Role deleted successfully.");
                        observer.complete();
                    } else {
                        observer.next("Error occured");
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public saveRole(rolename: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                name: rolename,
                description: "",
                isActive: true,
            });

            this.avcHttp.sendRequest("post", this.url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public updateRole(rolename: string, roleId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                name: rolename,
                roleId: roleId,
                description: "",
                isActive: true,
            });

            this.avcHttp.sendRequest("put", this.url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(new Error(body.errors[0].detail));
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getDisplayName(role) {
        role = role.toLowerCase();
        if (role.includes("superprovider")) {
            return "Provider Admin";
        }
        if (role.includes("provider")) {
            return "Provider";
        }
        if (role.includes("admin")) {
            return "Administrator";
        }
        if (role.includes("user")) {
            return "User";
        }

        return "n/a";
    }
}
