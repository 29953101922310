import { Component, Output, EventEmitter, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommunicationService } from "../../services/communications.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { take } from "rxjs/operators";
@Component({
    selector: "chat-modal",
    templateUrl: "./chat-modal.component.html",
    styleUrls: ["./chat-modal.component.scss"],
})
export class ChatModalComponent {
    constructor(public activeModal: NgbActiveModal, private snackbar: SnackbarService, private communication: CommunicationService) { }

    @Input() currentPatient: any = {};
    @Input() patients: any = [];
    @Input() markAsUnreadButton = false;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    patientId = "";
    public onCancel() {
        let hideProviderOnly = new CustomEvent("HIDE_PROVIDER_ONLY", { detail: this.currentPatient });
        document.dispatchEvent(hideProviderOnly);
        this.Response.emit({ proceed: true });
        this.activeModal.close();
    }
    public getThreadList() {
        this.communication.getThreadList().subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.patients = data[0].attributes;
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public markAsUnread() {
        this.patientId = this.currentPatient.patientid;
        this.currentPatient.provider_unread_messages_count += 1;
        this.communication.markMessagesAsUnRead(this.currentPatient.careteamid, this.patientId).pipe(take(1)).subscribe();
        let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_UNREAD_MESSAGE_COUNT", { detail: { reload: true } });
        document.dispatchEvent(updateUnreadMessagesCountEvent);
    }
}
