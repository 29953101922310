<div class="modal-body">
    <h5 class="modal-title">{{isTemplate?'New Campaign':'Choose Image'}}</h5>
    <div *ngIf="isTemplate" class="tab">
        <a (click)="selectedTab('monthly')" class="tablinks" [ngClass]="tabName=='monthly'?'active':''">
            Monthly
        </a>
        <a (click)="selectedTab('specialty')" class="tablinks" [ngClass]="tabName=='specialty'?'active':''">
            Specialty
        </a>
    </div>
    <div *ngIf="!showLoader">
        <label>Template:</label>
        <div *ngIf="isTemplate">
            <div style="height:250px;" class="filter p-3">
                <div *ngIf="tabName=='monthly'">
                    <div *ngFor="let m of monthly">
                        <label>{{m.month}}</label>
                        <div *ngFor="let t of m.template" class="form-check">
                            <input (change)="selectedOption(t)" class="form-check-input" type="radio" name="template"
                                id="{{t.template_id}}">
                            <label class="form-check-label" for="{{t.template_id}}">
                                {{t.template_name}}
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="tabName=='specialty'">
                    <div *ngFor="let s of specialty" class="form-check">
                        <input (change)="selectedOption(s)" class="form-check-input" type="radio" name="template"
                            id="{{s.template_id}}">
                        <label class="form-check-label" for="{{s.template_id}}">
                            {{s.template_name}}
                        </label>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button (click)="onTemplateCancel()" type="button" class="btn btn-secondary">Cancel</button>
                <button (click)="onTemplateSubmit()" type="button" class="btn btn-primary">Select</button>
            </div>
        </div>

        <div *ngIf="!isTemplate">
            <div *ngFor="let img of selectedTemplate.featured_images; index as i" class="form-check d-flex align-items-center">
                <input (change)="selectedImage(img)" class="form-check-input" type="radio" name="template" id="{{i}}">
                <label class="form-check-label mt-2" for="{{i}}">
                    <div *ngIf="loading" class="h-100 d-flex flex-column justify-content-center align-items-center">
                        <app-loader></app-loader>
                    </div>
                    <img (load)="onLoad()" style="width:98%;" src="{{ img}}">
                </label>
            </div>
            <div class="modal-footer">
                <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
                <button (click)="onImageSubmit()" type="button" class="btn btn-primary">Select</button>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center align-items-center">
    <app-loader></app-loader>
</div>