<div style="padding-left: 0"
    smartHeight="calc(50vh - (#globalAlert + #navbar + #messageFilter + #messageInput + #patientManageInfo + #patientManageTabs + #messageAttachmentContainer))"
    class="col-12 msg_history">
    <div class="media-library">
        <div class="library-heading">
            Media Library
            <i style="cursor: pointer" title="attachment" (click)="attachment(true)" class="fa fa-paperclip"
                aria-hidden="true"></i>
        </div>
        <div style="display: none">
            <label for="filePicker"></label><br />
            <input (change)="handleFileSelect($event)" type="file" id="filePicker" />
        </div>
        <div class="filterExt">
            <select (click)="$event.stopPropagation()" [(ngModel)]="exension" class="form-control">
                <option [disabled]="true" value="">Filter</option>
                <option *ngFor="let t of media_filter" [ngValue]="t">
                    {{ t.type }}
                </option>
            </select>
        </div>
    </div>

    <ul style="height: 238px; margin-right: 4px; overflow-x: auto; float: left" class="list">
        <li style="margin: 5px; cursor: pointer; display: flex" *ngFor="let m of mediaFiles | exensionType: exension"
            (click)="showImage(m.presignedUrl, m.metadata.extension, m)" class="doc_image">
            <span class="time_date">
                <strong>{{ m.metadata.actual_file_name }}</strong> created on {{ m.datetimeutc | date: "MMM d, h:mm:ss
                a" }}</span>
        </li>
    </ul>
</div>