<div class="container-fluid">
    <div *ngIf="!showLoader">
        <div *ngIf="showGraphdata">
            <div class="row">
                <div class="col-lg-9">
                </div>
                <div style="text-align: right" class="col-lg-3">
                    <a class="icon-href" *ngIf="metric.isFavorite==null" href="#"
                        (click)="$event.preventDefault();delete(metric)">
                        <img src="../../../assets/images/delete.svg">
                    </a>
                    <a *ngIf="metric.isFavorite===false" href="#" title="add to favorite"
                        (click)="$event.preventDefault();addToFavorite()">
                        <i style="color:gray" class="fa fa-bookmark" aria-hidden="true"></i>
                    </a>
                    <a *ngIf="metric.isFavorite===true" href="#" title="remove from favorite"
                        (click)="$event.preventDefault();removeFromFavorite()">
                        <i style="color:#0A529E" class="fa fa-bookmark" aria-hidden="true"></i>
                    </a>
                </div>

            </div>
            <div class="row">
                <highcharts-chart *ngIf="chartOptions" [Highcharts]="Highcharts"
                    style="width: 100%; display: block; overflow: hidden" [options]="chartOptions"></highcharts-chart>
                <label>Provider</label>
            </div>
            <div class="row">
                <div class="col-lg-6">
                </div>
                <div class="col-lg-6 text-right">
                    <a href="#" (click)="$event.preventDefault();previous()"
                        [className]="page==0 ? 'previous' : 'next'"><i class="fa fa-chevron-left"
                            aria-hidden="true"></i></a>
                    <a href="#" (click)="$event.preventDefault();next()"
                        [className]="pageCount-1==page? 'previous' : 'next'"><i class="fa fa-chevron-right"
                            aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
        <label style="display: flex;justify-content: center;align-items: center;"
            *ngIf="!showGraphdata">{{surveyName}}</label>
        <div class="noData" *ngIf="!showGraphdata">
            <label>No data found for this graph.</label>
        </div>
    </div>

    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 45%;"></app-loader>
</div>