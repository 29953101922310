<div class="container-fluid">
    <div class="tab">
        <a class="tablinks" [routerLink]="['/favorites']" [queryParams]="{type: 'survey'}">
            <img src="/assets/msx/favorites.svg"> Favorites
        </a>
        <a class="tablinks" [routerLink]="['/survey-data']">
            <img src="/assets/msx/surveydata.png"> Survey Data
        </a>
        <a class="tablinks active" [routerLink]="['/msx/reports']">
            <img src="/assets/msx/reports-white.svg"> Reports
        </a>
        <!-- <a class="tablinks">
            <img src="/assets/msx/admin.svg"> Administration
        </a> -->
    </div>

    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>

</div>

<div class="mt-3" style="margin-left: -10px;">
    <ul ngbNav #nav="ngbNav" class="nav-tabs pl-4">

        <!-- ReportCard  -->
        <li (click)="loadData('ReportCard')" ngbNavItem>
            <a ngbNavLink>Report Cards</a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="ReportOptions"></ng-template>
                <div class="mt-2">
                    <div class="row mt-2 ">
                        <div class="col-2"></div>
                        <div class="col-6">
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input [(ngModel)]="reportDuration" name="reportDuration" type="radio"
                                        class="form-check-input" value="monthly">Monthly
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input [(ngModel)]="reportDuration" name="reportDuration" type="radio"
                                        class="form-check-input" value="quarterly">Quarterly
                                </label>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="ROLE!='Markets'" class="row mt-2">
                        <div class="col-2 text-right">
                            <label style="margin-top: 10px;">Generate Report:</label>
                        </div>
                        <div class="col-5">
                            <select *ngIf="reportDuration=='monthly'" [(ngModel)]="monthReport" class="form-control">
                                <option *ngFor="let r of GenerateMonthDropDown" [value]="r.Value">{{r.Text }}
                                </option>
                            </select>
                            <select *ngIf="reportDuration=='quarterly'" [(ngModel)]="quarterReport"
                                class="form-control">
                                <option *ngFor="let r of GenerateQuarterDropDown" [value]="r.Value">{{r.Text }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2 text-right">
                            <label style="margin-top: 10px;">Report Month:</label>
                        </div>
                        <div class="col-5">
                            <select [(ngModel)]="report" class="form-control">
                                <option *ngFor="let r of reportFor" [value]="r.reportId">{{r.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-2"></div>
                    <div class="col-10 d-flex">
                        <button *ngIf="GenerateMonthDropDown.length>0" (click)="generateReport()"
                            class="btn btn-primary mr-2" style="font-weight: 600;">Generate
                            Report</button>
                        <button *ngIf="report!=''" (click)="downloadReport()" class="btn btn-primary"
                            style="font-weight: 600;">Download
                            Report</button>
                    </div>
                </div>
            </ng-template>
        </li>

        <!-- Patient Comment  -->
        <li *ngIf="!this.IsDashboardViewer" (click)="loadData('Comments')" ngbNavItem>
            <a ngbNavLink>Patient Comments</a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="ReportOptions"></ng-template>
                <div class="row mt-2">
                    <div class="col-2 text-right">
                        <label style="margin-top: 10px;">Report Period:</label>
                    </div>
                    <div *ngIf="downloadType!='csv'" class="col-5">
                        <select [(ngModel)]="report" class="form-control">
                            <option *ngFor="let r of reportFor" [value]="r.reportId">{{ r.text }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="downloadType=='csv'" class="col-5">

                        <select [(ngModel)]="report" class="form-control">
                            <option [value]="GenerateCsvCurrentMonth.Value">{{GenerateCsvCurrentMonth.Text}}
                            </option>
                            <option *ngFor="let r of reportFor" [value]="r.reportDate">{{r.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2 text-right mt-1">
                        <label style="margin-top: 10px;">Report Type:</label>
                    </div>
                    <div class="col-5 mt-3">
                        <div class="form-check-inline">
                            <label class="form-check-label">
                                <input (click)="reportType('csv')" [(ngModel)]="downloadType" name="downloadType"
                                    type="radio" class="form-check-input" value="csv">CSV
                            </label>
                        </div>
                        <div class="form-check-inline">
                            <label class="form-check-label">
                                <input (click)="reportType('pdf')" [(ngModel)]="downloadType" name="downloadType"
                                    type="radio" class="form-check-input" value="pdf">PDF
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-10 d-flex">
                        <div *ngIf="downloadType=='csv' && allowIncludeAllGroups" class="form-check-inline">
                            <label class="form-check-label">
                                <input [(ngModel)]="includeAllGroups" type="checkbox" class="form-check-input"
                                    value="csv">Include All Groups in Reseller
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-10 d-flex">

                        <button *ngIf="downloadType=='csv' && GenerateMonthDropDown.length>0" (click)="generateReport()"
                            class="btn btn-primary mr-2" style="font-weight: 600;">Generate
                            CSV</button>
                        <button *ngIf="report!='' && downloadType=='pdf'" (click)="downloadReport()"
                            class="btn btn-primary" style="font-weight: 600;">Download
                            Report</button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-2"></div>
                    <div class="col-10">
                        <p>
                            Please note that it may take several minutes for your request to complete.
                        </p>
                    </div>
                </div>

            </ng-template>
        </li>

        <!-- Outlier Report  -->
        <li *ngIf="!this.isProvider" (click)="loadData('Outliers')" ngbNavItem>
            <a ngbNavLink>Outlier Report</a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="ReportOptions"></ng-template>
                <div *ngIf="reportName=='Outliers'">
                    <div class="row mt-2">
                        <div class="col-2 text-right">
                            <label style="margin-top: 10px;">Report Period:</label>
                        </div>
                        <div class="col-5">
                            <select [(ngModel)]="report" class="form-control">
                                <option *ngFor="let r of reportFor" [value]="r.reportId">{{ r.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-2"></div>
                    <div class="col-10">
                        <button *ngIf="report!=''" (click)="downloadReport()" class="btn btn-primary"
                            style="font-weight: 600;">Download
                            Report</button>
                    </div>
                </div>
            </ng-template>
        </li>

        <!-- Undeliverable Email  -->
        <li *ngIf="!this.IsDashboardViewer && !this.isProvider" (click)="loadData('Undeliverables')" ngbNavItem>
            <a ngbNavLink>Undeliverable Emails</a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="ReportOptions"></ng-template>
                <div *ngIf="ROLE!='Markets'">
                    <div>
                        <div class="row mt-2">
                            <div class="col-2 text-right">
                                <label style="margin-top: 10px;">Generate Report:</label>
                            </div>
                            <div class="col-5">
                                <select [(ngModel)]="monthReport" class="form-control">
                                    <option *ngFor="let r of GenerateMonthDropDown" [value]="r.Value">{{r.Text
                                        }}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right">
                                <label style="margin-top: 10px;">Report Month:</label>
                            </div>
                            <div class="col-5">
                                <select [(ngModel)]="report" class="form-control">
                                    <option *ngFor="let r of reportFor" [value]="r.reportId">{{r.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-2"></div>
                            <div class="col-10 d-flex">
                                <button *ngIf="GenerateMonthDropDown.length>0" (click)="generateReport()"
                                    class="btn btn-primary mr-2" style="font-weight: 600;">Generate
                                    Report</button>
                                <button *ngIf="report!=''" (click)="downloadReport()" class="btn btn-primary"
                                    style="font-weight: 600;">Download
                                    Report</button>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-2 text-right">
                                <label style="margin-top: 10px;">Report Month:</label>
                            </div>
                            <div class="col-5">

                                <select [(ngModel)]="reportDate" class="form-control">
                                    <option *ngFor="let r of reportFor" [value]="r.reportDate">{{r.text }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-2"></div>
                            <div class="col-10">
                                <div *ngIf="reportDate!=''">
                                    <button (click)="generateReport('csv')" class="btn btn-primary"
                                        style="font-weight: 600;">Generate
                                        CSV</button>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </ng-template>
        </li>

        <!-- Ranking Report  -->
        <li *ngIf="!this.isProvider" (click)="loadData('Ranking')" ngbNavItem>
            <a ngbNavLink>Ranking Report</a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="ReportOptions"></ng-template>
                <div>
                    <div class="mt-2">
                        <div *ngIf="ROLE!='Markets'" class="row mt-2">
                            <div class="col-2 text-right">
                                <label style="margin-top: 10px;">Generate Report:</label>
                            </div>
                            <div class="col-5">
                                <select [(ngModel)]="quarterReport" class="form-control">
                                    <option *ngFor="let r of GenerateQuarterDropDown" [value]="r.Value">{{r.Text
                                        }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-2 text-right">
                                <label style="margin-top: 10px;">Report Month:</label>
                            </div>
                            <div class="col-5">
                                <select [(ngModel)]="report" class="form-control">
                                    <option *ngFor="let r of reportFor" [value]="r.reportId">{{r.value }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-2"></div>
                        <div class="col-10 d-flex">
                            <div *ngIf="GenerateMonthDropDown.length>0">
                                <button (click)="generateReport()" class="btn btn-primary mr-2"
                                    style="font-weight: 600;">Generate
                                    Report</button>
                            </div>
                            <button *ngIf="report!=''" (click)="downloadReport()" class="btn btn-primary"
                                style="font-weight: 600;">Download
                                Report</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>

<ng-template #ReportOptions>
    <div class="row">
        <div class="col-2 text-right">
            <label style="margin-top: 10px;">Report For:</label>
        </div>
        <div class="col-5">
            <ng-select [(ngModel)]="selectedValue" (change)="selectedEntity($event)" [items]="EntityList"
                bindLabel="Text" bindValue="Value" groupBy="Type" [searchable]="false">
            </ng-select>
        </div>
    </div>
</ng-template>

<app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>