import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmployersService } from "../../services/employer.service";

@Component({
    selector: "add-employer-modal",
    templateUrl: "./add-employer-modal.component.html",
    styleUrls: ["./add-employer-modal.component.scss"],
})
export class AddEmployerModalComponent implements OnInit {
    @Input() Employer: any = [];
    @Output() readonly Response = new EventEmitter<any>();
    @Input() Context: any = {};

    errorMessage: string = "";
    employerName: string = "";
    constructor(private activeModal: NgbActiveModal, private employersService: EmployersService) {}

    ngOnInit() {
        if (this.Context.edit) {
            this.employerName = this.Context.employer.employername;
        }
    }

    public onAdd() {
        this.employersService.addEmployer(this.employerName).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Employer created." });
                this.activeModal.close();
            },
            (err) => {
                this.errorMessage = err[0].detail !== undefined ? err[0].detail : err.message;
            }
        );
    }
    public update() {
        this.employersService.updateEmployer(this.Context.employer.employername, this.employerName).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Employer updated successfully." });
                this.activeModal.close();
            },
            (err) => {
                this.errorMessage = err[0].detail !== undefined ? err[0].detail : err.message;
            }
        );
    }

    public onCancel() {
        this.activeModal.close();
    }
}
