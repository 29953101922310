import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { AvcValidators } from "../../providers/avc-validators";
import { ChatImageModalComponent } from "../chat-image-modal/chat-image-modal.component";

@Component({
    selector: "summary-view-modal",
    templateUrl: "./summary-view-modal.component.html",
    styleUrls: ["./summary-view-modal.component.scss"],
})
export class SummaryViewModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private avcValidators: AvcValidators,
        private modalService: NgbModal
    ) { }

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    birthControl: boolean = false;
    pregnancy: boolean = false;
    menopausal: boolean = false;
    age: any;
    firstName: string;
    lastName: string;
    gender: string;
    imageURLFront: string;
    imageURLBack: string;
    imageURLVaccination: string;
    imageURLPhotoID: string;
    phq9score = 0;
    gad7Score = 0;
    phq9Date = "";
    gad7Date = "";

    sleepScore = 0;
    sleepDate = "";
    ngOnInit() {
        this.setUpHeader();
        if (this.SummaryData.summary.personalinformation.photoid) {
            this.getPhotoIDImageURL();
        }
        if (this.SummaryData.summary.healthinsuranceinformation.imagefront) {
            this.getFrontImageURL();
        }
        if (this.SummaryData.summary.healthinsuranceinformation.imageback) {
            this.getBackImageURL();
        }
        if (this.SummaryData.summary.covid19vaccination.vaccinationcard) {
            this.getVaccinationImageURL();
        }
    }
    setUpHeader() {
        this.SummaryData.summary.phq9 = this.SummaryData.summary.phq9 || [];
        this.SummaryData.summary.gad7 = this.SummaryData.summary.gad7 || [];

        this.SummaryData.summary.phq9.phq9 != undefined && this.SummaryData.summary.phq9.phq9.sort(function (a, b) {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        })
        this.SummaryData.summary.gad7.gad7 != undefined && this.SummaryData.summary.gad7.gad7.sort(function (a, b) {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        })

        if (this.SummaryData.summary.phq9.phq9 != undefined && this.SummaryData.summary.phq9.phq9.length > 0) {
            this.SummaryData.summary.phq9.phq9.at(0).details.forEach((element) => {
                this.phq9score += element.score;
            });
            this.phq9Date = this.SummaryData.summary.phq9.phq9.at(0).date;
        }
        if (this.SummaryData.summary.gad7.gad7 != undefined && this.SummaryData.summary.gad7.gad7.length > 0) {
            this.SummaryData.summary.gad7.gad7.at(0).details.forEach((element) => {
                this.gad7Score += element.score;
            });
            this.gad7Date = this.SummaryData.summary.gad7.gad7.at(0).date;
        }
        this.SummaryData.summary.sleepassessment != undefined && this.SummaryData.summary.sleepassessment.sleepAssessment.sort(function (a, b) {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        })
        if (this.SummaryData.summary.sleepassessment?.sleepAssessment != undefined && this.SummaryData.summary.sleepassessment.sleepAssessment.length > 0) {
            this.SummaryData.summary.sleepassessment.sleepAssessment.at(0).details.forEach((element) => {
                this.sleepScore += element.score;
            });
            this.sleepDate = this.SummaryData.summary.sleepassessment.sleepAssessment.at(0).date;
        }
        let summaryDOB = this.SummaryData.summary
            ? this.SummaryData.summary.personalinformation
                ? this.SummaryData.summary.personalinformation.dob
                    ? this.SummaryData.summary.personalinformation.dob
                    : null
                : null
            : null;
        let dob = summaryDOB ? summaryDOB : this.SummaryData.userAttributes.dob;
        if (dob) {
            var timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
            this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        }
        let summaryFirstName = this.SummaryData.summary
            ? this.SummaryData.summary.personalinformation
                ? this.SummaryData.summary.personalinformation.firstname !== ""
                    ? this.SummaryData.summary.personalinformation.firstname
                    : null
                : null
            : null;
        this.firstName = summaryFirstName ? summaryFirstName : this.SummaryData.userAttributes.firstname;
        let summaryLastName = this.SummaryData.summary
            ? this.SummaryData.summary.personalinformation
                ? this.SummaryData.summary.personalinformation.lastname !== ""
                    ? this.SummaryData.summary.personalinformation.lastname
                    : null
                : null
            : null;
        this.lastName = summaryLastName ? summaryLastName : this.SummaryData.userAttributes.lastname;
        let summaryGender = this.SummaryData.summary
            ? this.SummaryData.summary.personalinformation
                ? this.SummaryData.summary.personalinformation.gender !== ""
                    ? this.SummaryData.summary.personalinformation.gender
                    : null
                : null
            : null;
        this.gender = summaryGender ? summaryGender : this.SummaryData.userAttributes.gender;
    }
    public getFrontImageURL() {
        return this.patientService.getImage(this.SummaryData.summary.healthinsuranceinformation.imagefront).subscribe(
            (data) => {
                this.imageURLFront = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getBackImageURL() {
        return this.patientService.getImage(this.SummaryData.summary.healthinsuranceinformation.imageback).subscribe(
            (data) => {
                this.imageURLBack = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getVaccinationImageURL() {
        return this.patientService.getImage(this.SummaryData.summary.covid19vaccination.vaccinationcard).subscribe(
            (data) => {
                this.imageURLVaccination = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getPhotoIDImageURL() {
        return this.patientService.getImage(this.SummaryData.summary.personalinformation.photoid).subscribe(
            (data) => {
                this.imageURLPhotoID = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public onCancel() {
        this.activeModal.close();
    }
    public onEdit() {
        this.Response.emit({ success: true, message: "", action: "edit" });
        this.activeModal.close();
    }
    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }
}
