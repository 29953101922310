import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MSXReportService } from "../../../services/msx_report.service";
import * as __ from 'underscore';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import * as _ from 'lodash';
import { FavoritesModalComponent } from '../modals/favorites-modal/favorites-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ScheduleReportModalComponent } from '../modals/schedule-report-modal/schedule-report-modal.component';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})

export class FavoritesComponent implements OnInit {

  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    route.queryParams.subscribe(val => {
      this.type = val.type;
      this.getGraphs();
    });
  }

  favoriteList = [];
  type = "";
  graphType = "";
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
      this.graphType = params['graph'];
    });

    this.getGraphs();

  }
  getGraphs() {
    this.msxReportService.getFavoriteList().subscribe(
      (data) => {
        let value = _.find(data[0].type, { settingname: 'additionalsettings' });
        if (value != undefined) {
          this.favoriteList = JSON.parse(value.settingvalue);
          this.favoriteList = _.filter(this.favoriteList, {
            graphName
              : this.type
          })
        }
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }
  delete(graph) {
    const modalRef = this.modalService.open(FavoritesModalComponent, { backdrop: "static" });
    modalRef.componentInstance.heading = "Remove Favorite";
    modalRef.componentInstance.msg = "Are you sure to remove these settings from favorites?";
    modalRef.componentInstance.name = graph.name;
    modalRef.componentInstance.delete = true;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.favoriteList = _.filter(this.favoriteList, (obj) => {
        return obj.graphId !== graph.graphId;
      });
      this.updateGraphs(graph.graphId);
    });
  }


  public updateGraphs(graphId) {
    this.msxReportService.updateFavoriteList(graphId).subscribe(
      (data) => {
        this.snackbar.show("Favourite deleted successfully.", "success");
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  public scheduleReport() {
    const modalRef = this.modalService.open(ScheduleReportModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {

    });
  }
}

