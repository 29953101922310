import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Configuration } from "../../pages/auto-reply/models/Schedule";
import { AutoReplyService } from "../../services/auto_reply.service";
import { SessionService } from "../../services/session.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "schedule-modal",
    templateUrl: "./schedule-modal.component.html",
    styleUrls: ["./schedule-modal.component.scss"],
})
export class ScheduleModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private snackbar: SnackbarService,
        private formBuilder: UntypedFormBuilder,
        private sessionService: SessionService,
        private autoreply: AutoReplyService
    ) { }
    scheduleForm: UntypedFormGroup;
    submitted = false;
    configuration: any;
    _selected = false;
    minDate = new Date().toISOString().slice(0, 10);
    @Input() selectedIndex: number = -1;
    ngOnInit() {
        let autoreplySetting = this.sessionService.currentUser?.attributes.membership.telerx.autoreply
        this.configuration = new Configuration(
            autoreplySetting ? (autoreplySetting ? autoreplySetting.enable : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.enableFor : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.afterHoursSettings : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.defaultMessage : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.schedules : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.typicalKewords : "") : "",
        )
        let i = this.selectedIndex;
        this.scheduleForm = this.formBuilder.group({
            name: [i != -1 ? this.configuration.schedules[i].name : "", Validators.required],
            message: [i != -1 ? this.configuration.schedules[i].message : "", Validators.required],
            startDate: [i != -1 ? this.configuration.schedules[i].startDate : "", Validators.required],
            endDate: [i != -1 ? this.configuration.schedules[i].endDate : "", Validators.required],
            startTime: [i != -1 ? this.configuration.schedules[i].startTime : "", Validators.required],
            endTime: [i != -1 ? this.configuration.schedules[i].endTime : "", Validators.required],
            id: [this.selectedIndex != -1 ? this.configuration.schedules[this.selectedIndex].id : this.guid()],
            fullDay: [i != -1 ? this.configuration.schedules[i].fullDay : false],

        });

    }
    public guid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }
    get form() {
        return this.scheduleForm.controls;
    }
    public addToSchedule() {
        this.submitted = true;
        if (this.scheduleForm.valid) {
            let _startDate = new Date(this.scheduleForm.value.startDate + " " + this.scheduleForm.value.startTime)
            let _endDate = new Date(this.scheduleForm.value.endDate + " " + this.scheduleForm.value.endTime)
            if (_startDate > _endDate) {
                this.snackbar.show("End Date Time of schedule must not be less than Start Date Time. ", "danger");
                return;
            }
            let nameFound = 0;
            this.configuration.schedules.forEach(element => {
                if (element.name.toLowerCase() === this.scheduleForm.value.name.toLowerCase()) {
                    nameFound++;
                    if (this.selectedIndex != -1 && this.scheduleForm.value.id == element.id) {
                        nameFound--;
                    }
                }
            });
            if (this.selectedIndex === -1 && nameFound > 0) {
                this.snackbar.show("Schedule already exists.", "danger")
                return false;
            }
            if (this.selectedIndex != -1 && nameFound >= 1) {
                this.snackbar.show("Schedule already exists.", "danger")
                return false;
            }
            if (nameFound == 0) {
                if (this.selectedIndex === -1) {
                    this.configuration.schedules.push(this.scheduleForm.value)
                }
                else {
                    this.configuration.schedules[this.selectedIndex].name = this.scheduleForm.value.name;
                    this.configuration.schedules[this.selectedIndex].startDate = this.scheduleForm.value.startDate;
                    this.configuration.schedules[this.selectedIndex].endDate = this.scheduleForm.value.endDate;
                    this.configuration.schedules[this.selectedIndex].startTime = this.scheduleForm.value.startTime;
                    this.configuration.schedules[this.selectedIndex].endTime = this.scheduleForm.value.endTime;
                    this.configuration.schedules[this.selectedIndex].message = this.scheduleForm.value.message;
                    this.configuration.schedules[this.selectedIndex].fullDay = this.scheduleForm.value.fullDay;



                }
                this.autoreply.addSchedule(this.configuration).subscribe(
                    (data) => {
                        this.activeModal.close();
                    },
                    (error) => {

                    }
                );
            }
        }
    }

    public onCancel() {
        this.activeModal.close();
    }
    public allDay(startDate) {
        this.scheduleForm.patchValue({
            endDate: startDate.value,
            startTime: "00:00",
            endTime: "23:30"
        });
    }
}
