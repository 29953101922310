<div class="tab">
    <button class="tablinks border-radius" (click)="scheduleReport()">
        <i class="far fa-clock" aria-hidden="true"></i>&nbsp;
        Schedule Report</button>
    <a style="border-radius: 5px 0px 0px 5px;" class="tablinks active">
        <img src="/assets/msx/favorites-white.svg"> Favorites
    </a>
    <a *ngIf="type==='survey'" class="tablinks" [routerLink]="['/survey-data']">
        <img src="/assets/msx/surveydata.png"> Survey Data
    </a>
    <a *ngIf="type==='visit'" class="tablinks" [routerLink]="['/visit-data']">
        <img src="/assets/msx/surveydata.png"> Visit Data
    </a>
    <a *ngIf="type==='billing'" class="tablinks" [routerLink]="['/billing-data']">
        <img src="/assets/msx/surveydata.png"> Billing Data
    </a>
    <a *ngIf="type==='survey'" class="tablinks" [routerLink]="['/msx/reports']">
        <img src="/assets/msx/reports.svg"> Reports
    </a>

</div>

<div class="card" *ngFor="let f of favoriteList">
    <div class="card-body">
        <div style="display: inline" class="row">
            <a class="icon-href" href="#" (click)="$event.preventDefault();delete(f)">
                <img src="../../../assets/images/delete.svg">
            </a>
        </div>
        <app-survey-data *ngIf="type !='billing'" [_favoriteList]="f"></app-survey-data>
        <app-billing-data *ngIf="type =='billing'" [_favoriteList]="f"></app-billing-data>
    </div>
</div>

<p *ngIf="favoriteList.length===0 && graphType=='default'">
    <ng-container *ngIf="type=='survey'">
        <app-survey-data [_type]="'survey'"></app-survey-data>
    </ng-container>
    <ng-container *ngIf="type=='visit'">
        <app-survey-data [_type]="'visit'"></app-survey-data>
    </ng-container>
    <ng-container *ngIf="type=='billing'">
        <app-billing-data [_type]="'favorite'" style="width:100%;" *ngIf="favoriteList.length===0"></app-billing-data>
    </ng-container>
</p>

<p *ngIf="favoriteList.length===0 && graphType!='default'" class="helptext">You don't have any favorite list yet. Please
    go to
    <ng-container *ngIf="type=='survey'">
        <a [routerLink]="['/survey-data']">
            Survey Data
        </a> and select favorite List.
    </ng-container>
    <ng-container *ngIf="type=='visit'">
        <a [routerLink]="['/visit-data']">
            Visit Data
        </a> and select favorite List.
    </ng-container>
    <ng-container *ngIf="type=='billing'">
        <a [routerLink]="['/billing-data']">
            Billing Data
        </a> and select favorite List.
    </ng-container>
</p>