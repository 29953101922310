<div class="container-fluid">
    <div class="row">
        <div class="tab col-8">
            <button (click)="showGraph('patient')" class="tablinks" [class.active]="current_active === 'patient'">
                <img *ngIf="current_active!=='patient'" src="/assets/msx/favorites.svg">
                <img *ngIf="current_active==='patient'" src="/assets/msx/favorites-white.svg"> Patient Experience
            </button>
            <button (click)="showGraph('visits')" class="tablinks" [class.active]="current_active === 'visits'">
                <img *ngIf="current_active!=='visits'" src="/assets/msx/favorites.svg">
                <img *ngIf="current_active==='visits'" src="/assets/msx/favorites-white.svg"> Visits
            </button>
            <button (click)="showGraph('billing')" class="tablinks" [class.active]="current_active === 'billing'">
                <img *ngIf="current_active!=='billing'" src="/assets/msx/favorites.svg">
                <img *ngIf="current_active==='billing'" src="/assets/msx/favorites-white.svg"> Billing</button>
            <button (click)="showGraph('insights')" class="tablinks-last"
                [class.active]="current_active === 'insights'">
                <img *ngIf="current_active!=='insights'" src="/assets/msx/insights.svg">
                <img *ngIf="current_active==='insights'" src="/assets/msx/insights-white.svg"> Insights</button>


        </div>
        <div class="col-4 action">
            <button>
                <img *ngIf="current_active==='insights'" src="/assets/msx/trash.svg">
                Delete All
            </button>
            <button>
                <img *ngIf="current_active==='insights'" src="/assets/msx/restore.svg">
                Restore Defaults
            </button>
        </div>
    </div>
    <div class="row"></div>

    <div *ngIf="current_active==='patient'" class="row" #activeList="cdkDropList" cdkDropList
        (cdkDropListDropped)="drop($event)">
        <div cdkDrag *ngFor="let m of metrics" class="col-lg-6">
            <div class="card mb-2">
                <div class="card-body">
                    <app-metric (refreshMetricsList)="refreshMetricsList()" [metric]="m"></app-metric>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="current_active==='visits'" class="row insights">
        <div class="card mb-2">
            <div class="card-body">
                visits
            </div>
        </div>
    </div>
    <div *ngIf="current_active==='billing'" class="row insights">
        <div class="card mb-2">
            <div class="card-body">
                billing
            </div>
        </div>
    </div>
    <div *ngIf="current_active==='insights'" class="row insights">
        <div class="card mb-2">
            <div class="card-body">
                TBD
            </div>
        </div>
    </div>
</div>