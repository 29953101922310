<div class="modal-body">
    <div class="category-header">
        <label>{{ EditForm === false ? "Add New Link" : "Update Link" }}</label>
    </div>
    <form [formGroup]="appForm">
        <div class="main-row">
            <div class="main-left">
                <div class="form-group">
                    <label>Title:<span class="text-danger">*</span></label>
                    <input formControlName="appTitle" [ngModel]="AppData?.title" type="text" required
                        class="form-control" />
                </div>
                <div class="form-group">
                    <label>Description:<span class="text-danger">*</span></label>
                    <input formControlName="appDescription" [ngModel]="AppData?.description" type="text" required
                        class="form-control" />
                </div>
                <div class="form-group">
                    <label>Action:<span class="text-danger">*</span></label>
                    <select [ngModel]="action" formControlName="appAction" type="text" required
                        class="form-control custom-select">
                        <option value="">Please select</option>
                        <option value="browser">Show URL by opening a browser</option>
                        <option value="window">Show URL inside the Clio app</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Image URL:<span class="text-danger">*</span></label>
                    <input formControlName="appImageUrl" [ngModel]="AppData?.imageurl" type="text" required
                        class="form-control" />
                </div>
                <div class="form-group">
                    <label>Action Button Text:</label>
                    <input formControlName="appActionButtonText" [ngModel]="AppData?.actiontext" type="text"
                        class="form-control" />
                </div>
            </div>
            <div class="main-right">
                <div class="form-group">
                    <label>Action URL iOS:</label>
                    <input placeholder="Leave empty if no URL avilable." formControlName="appActionUrliOS"
                        [ngModel]="AppData?.actionurlios" type="text" class="form-control" />
                </div>
                <div class="form-group">
                    <label>Action URL Android:</label>
                    <input placeholder="Leave empty if no URL avilable." formControlName="appActionUrlAndroid"
                        [ngModel]="AppData?.actionurlandroid" type="text" class="form-control" />
                </div>
                <div class="form-group">
                    <label>Info Button Text:</label>
                    <input formControlName="appInfoButtonText" [ngModel]="AppData?.infotext" type="text"
                        class="form-control" />
                </div>
                <div class="form-group">
                    <label>Info Button URL:</label>
                    <input formControlName="appInfoButtonUrl" [ngModel]="AppData?.infourl" type="text"
                        class="form-control" />
                </div>
                <div class="form-group">
                    <label>Visibility:<span class="text-danger">*</span></label>

                    <select
                        [attr.disabled]="currentUser.attributes.membership.telerx.telerxtype==='application' ? '' : null"
                        formControlName="linktype" [(ngModel)]="linktype" type="text" [disabled]="true"
                        class="form-control custom-select">
                        <option value="">Please select</option>
                        <!-- <option *ngIf="currentUser.attributes.membership.telerx.telerxtype!=='application'"
                            value="global">
                            Assign link to All Users</option> -->
                        <!-- <option *ngIf="currentUser.attributes.membership.telerx.telerxtype!=='application'"
                            value="private">Link must be assigned by a provider</option> -->
                        <option value="private">Link must be assigned by a provider</option>
                        <!-- <option [selected]='true'
                            *ngIf="currentUser.attributes.membership.telerx.telerxtype==='application'"
                            value="application">Application link assigned to subscribed users</option> -->
                    </select>
                </div>
            </div>
            <div class="main-full">
                <ngb-alert *ngIf="errorMessage!==''" [dismissible]="false" [type]="'danger'" class="mb-0 error-block">
                    {{ errorMessage }}
                </ngb-alert>
            </div>
        </div>
        <div class="main-row">
            <div class="main-left">
                <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            </div>
            <div class="main-right">
                <button type="button" class="btn btn-primary" (click)="onSubmit()">{{ EditForm === false ? "Add" :
                    "Update" }}</button>
            </div>
        </div>
    </form>
</div>