import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { AvcValidators } from "../../providers/avc-validators";
import { SessionService } from "../../services/session.service";
import { ChatImageModalComponent } from "../../modals/chat-image-modal/chat-image-modal.component";

@Component({
    selector: "prescription-modal",
    templateUrl: "./prescription-modal.component.html",
    styleUrls: ["./prescription-modal.component.scss"],
})
export class PrescriptionModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private sessionService: SessionService,
        private fb: UntypedFormBuilder,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private avcValidators: AvcValidators,
        private modalService: NgbModal
    ) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    error = "";
    prescriptionForm: UntypedFormGroup;
    imageURL: string;
    filename: string;
    filedata: string;
    isInvalidImage: boolean = false;
    public showLoader: boolean = false;
    ngOnInit() {
        this.prescriptionForm = this.fb.group(
            {
                medicationname: [this.SummaryData.medication.medicationname, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
                dosage: [this.SummaryData.medication.dosage, Validators.required],
                frequency: [this.SummaryData.medication.frequency, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
                image: [this.SummaryData.medication.image],
                notes: [this.SummaryData.medication.notes, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            },
            { updateOn: "blur" }
        );
        if (this.SummaryData.action === "edit") {
            this.getImageURL();
        }
    }
    public onCancel() {
        this.activeModal.close();
    }
    isFieldValid(key) {
        return this.prescriptionForm.get(key).invalid && this.prescriptionForm.get(key).touched;
    }
    // Image Preview
    showPreview(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.prescriptionForm.patchValue({
            image: file,
        });
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.filename = file.name;
                this.filedata = reader.result.toString().substr(reader.result.toString().indexOf(",") + 1);
                this.imageURL = reader.result as string;
                this.isInvalidImage = false;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    }
    onSave() {
        this.isInvalidImage = false;
        if (this.prescriptionForm.valid) {
            if (this.SummaryData.summary.prescriptions.medications && this.SummaryData.summary.prescriptions.medications.length > 0) {
                let medication = this.SummaryData.summary.prescriptions.medications.filter((x) => x.medicationname === this.prescriptionForm.get("medicationname").value);
                if (medication && medication.length > 0) {
                    this.error = "Medication Name already exists.";
                    return;
                }
            }
            this.showLoader = true;
            this.SummaryData.medication.medicationname = this.prescriptionForm.get("medicationname").value;
            this.SummaryData.medication.dosage = this.prescriptionForm.get("dosage").value;
            this.SummaryData.medication.frequency = this.prescriptionForm.get("frequency").value;
            this.SummaryData.medication.notes = this.prescriptionForm.get("notes").value;
            let userData = this.sessionService.currentUserSettings;
            let firstName = userData.firstname ? userData.firstname : "";
            let lastName = userData.lastname ? userData.lastname : "";
            this.SummaryData.medication.addedby = firstName + " " + lastName;
            this.SummaryData.medication.addedon = new Date();

            if (this.SummaryData.summary.prescriptions.medications) {
                this.SummaryData.summary.prescriptions.medications.push(this.SummaryData.medication);
            } else {
                let medications = [];
                medications.push(this.SummaryData.medication);
                this.SummaryData.summary.prescriptions.medications = medications;
            }
            if (this.filename !== undefined) {
                const formData = new FormData();
                formData.append("file", this.prescriptionForm.get("image").value, this.filename);
                return this.patientService.uploadFile(formData).subscribe(
                    (uploadData) => {
                        this.SummaryData.medication.image = uploadData.attributes.libraryid;
                        this.addUpdateMedications();
                    },
                    (err) => {
                        this.showLoader = false;
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            } else {
                this.addUpdateMedications();
            }
        } else {
            if (!this.filename || this.filename === "") {
                this.isInvalidImage = true;
            }
            this.prescriptionForm.markAllAsTouched();
            this.error = "Please provide mandatory fields.";
        }
    }
    onUpdate() {
        this.prescriptionForm.markAllAsTouched();
        if (this.prescriptionForm.valid) {
            this.showLoader = true;
            if (this.SummaryData.summary.prescriptions.medications && this.SummaryData.summary.prescriptions.medications.length > 1) {
                const filteredMedications = this.SummaryData.summary.prescriptions.medications.filter((x) => x.medicationname !== this.SummaryData.medication.medicationname);
                let medication = filteredMedications.filter((x) => x.medicationname === this.prescriptionForm.get("medicationname").value);
                if (medication && medication.length > 0) {
                    this.error = "Medication Name already exists.";
                    return;
                }
            }
            this.SummaryData.medication.medicationname = this.prescriptionForm.get("medicationname").value;
            this.SummaryData.medication.dosage = this.prescriptionForm.get("dosage").value;
            this.SummaryData.medication.frequency = this.prescriptionForm.get("frequency").value;
            this.SummaryData.medication.notes = this.prescriptionForm.get("notes").value;

            if (this.filename && this.filename !== "") {
                const formData = new FormData();
                formData.append("file", this.prescriptionForm.get("image").value, this.filename);
                return this.patientService.uploadFile(formData).subscribe(
                    (uploadData) => {
                        this.SummaryData.medication.image = uploadData.attributes.libraryid;
                        this.SummaryData.summary.prescriptions.medications[this.SummaryData.index] = this.SummaryData.medication;
                        this.addUpdateMedications();
                    },
                    (err) => {
                        this.showLoader = false;
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            } else {
                this.SummaryData.summary.prescriptions.medications[this.SummaryData.index] = this.SummaryData.medication;
                this.addUpdateMedications();
            }
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }
    onDelete() {
        this.SummaryData.summary.prescriptions.medications.splice(this.SummaryData.index, 1);
        this.addUpdateMedications();
    }

    addUpdateMedications() {
        let updatedData = this.SummaryData.summary;
        let userId = this.SummaryData.patientId;
        return this.patientService.updateSummary(updatedData, userId).subscribe(
            (data) => {
                this.showLoader = false;
                this.activeModal.close();
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public getImageURL() {
        return this.patientService.getImage(this.SummaryData.medication.image).subscribe(
            (data) => {
                this.imageURL = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }
}
