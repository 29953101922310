import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../..//services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { AvcValidators } from "../../../providers/avc-validators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";
import { PersonalInformationModalComponent } from "../../../modals/summary-category/personal-information-modal/personal-information-modal.component";
import { ContactInformationModalComponent } from "../../../modals/summary-category/contact-information-modal/contact-information-modal.component";
import { HealthInsuranceInformationModalComponent } from "../../../modals/summary-category/healthinsurance-information-modal/healthinsurance-information-modal.component";
import { FoodAllergiesInformationModalComponent } from "../../../modals/summary-category/foodallergies-information-modal/foodallergies-information-modal.component";
import { EnvironmentalAllergiesModalComponent } from "../../../modals/summary-category/environmentalallergies-modal/environmentalallergies-modal.component";
import { SocialInformationModalComponent } from "../../../modals/summary-category/social-information-modal/social-information-modal.component";
import { FamilyHistoryModalComponent } from "../../../modals/summary-category/familyhistory-modal/familyhistory-modal.component";
import { VaccinesModalComponent } from "../../../modals/summary-category/vaccines-modal/vaccines-modal.component";
import { PastMedicalInformationModalComponent } from "../../../modals/summary-category/pastmedical-information-modal/pastmedical-information-modal.component";
import { PastSurgicalInformationModalComponent } from "../../../modals/summary-category/pastsurgical-information-modal/pastsurgical-information-modal.component";
import { MedicationAllergiesInformationModalComponent } from "../../../modals/summary-category/medicationallergies-information-modal/medicationallergies-information-modal.component";
import { MoodWellBeingModalComponent } from "../../../modals/summary-category/mood-wellbeing-modal/mood-wellbeing-modal.component";
import { WomenHealthModalComponent } from "../../../modals/summary-category/womenhealth-modal/womenhealth-modal.component";
import { Covid19ModalComponent } from "../../../modals/summary-category/covid-19-modal/covid-19-modal.component";
import { phq9ModalComponent } from "../phq9-modal/phq9-modal.component";

@Component({
    selector: "summary-categories-modal",
    templateUrl: "./summary-categories-modal.component.html",
    styleUrls: ["./summary-categories-modal.component.scss"],
})
export class SummaryCategoriesModalComponent {
    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private avcValidators: AvcValidators,
        private modalService: NgbModal
    ) { }

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public summaryData: any = { summary: {} };
    public onCategorySelection(type) {
        this.viewCategoryModalResponse(true, "", false, type);
        this.activeModal.close();
    }
    public viewCategoryModalResponse(success, message, isSaved, type) {
        if (success) {
            this.summaryData = {
                action: "edit",
                categoryType: type,
                patientId: this.SummaryData.patientId,
                summary: this.SummaryData.summary,
            };
            if (type === "personal") {
                const modalRef = this.modalService.open(PersonalInformationModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "contact") {
                const modalRef = this.modalService.open(ContactInformationModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "insurance") {
                const modalRef = this.modalService.open(HealthInsuranceInformationModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "pastmedical") {
                const modalRef = this.modalService.open(PastMedicalInformationModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "pastsurgical") {
                const modalRef = this.modalService.open(PastSurgicalInformationModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            // if (type === "allergies") {
            //     const modalRef = this.modalService.open(AllergiesModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
            //     modalRef.componentInstance.SummaryData = this.summaryData;
            //     modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            //         this.allergiesModalResponse(receivedEntry);
            //     });
            // }
            if (type === "foodallergies") {
                const modalRef = this.modalService.open(FoodAllergiesInformationModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "medicationalallergies") {
                const modalRef = this.modalService.open(MedicationAllergiesInformationModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "environmentallergies") {
                const modalRef = this.modalService.open(EnvironmentalAllergiesModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "social") {
                const modalRef = this.modalService.open(SocialInformationModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "mood") {
                const modalRef = this.modalService.open(MoodWellBeingModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "vaccines") {
                const modalRef = this.modalService.open(VaccinesModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "womenhealth") {
                const modalRef = this.modalService.open(WomenHealthModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "familyhistory") {
                const modalRef = this.modalService.open(FamilyHistoryModalComponent, { size: "lg", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "covid19") {
                const modalRef = this.modalService.open(Covid19ModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
            if (type === "phq9" || type === "gad7" || type === "sleepassessment") {
                const modalRef = this.modalService.open(phq9ModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.AssessmentType = type;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            }
        }
    }
    public viewSummaryModalResponse(event) {
        if (event.success && event.action === "edit") {
            this.summaryData = {
                action: "edit",
                categoryType: "",
                patientId: this.SummaryData.patientId,
                summary: event.updatedSummaryData,
            };
            const modalRef = this.modalService.open(SummaryCategoriesModalComponent, { size: "lg", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
            modalRef.componentInstance.SummaryData = this.summaryData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.Response.emit({ success: true, message: receivedEntry.message, action: "edit", updatedSummaryData: this.SummaryData.summary });
                this.activeModal.close();
            });
        }
    }
    public allergiesModalResponse(event) {
        if (event.success && event.action === "edit") {
            this.summaryData = {
                action: "edit",
                categoryType: "",
                patientId: this.SummaryData.patientId,
                summary: event.updatedSummaryData,
            };
            if (event.isCancel) {
                this.viewSummaryModalResponse(event);
            } else {
                if (this.summaryData.summary.allergies.medication) {
                    const modalRef = this.modalService.open(MedicationAllergiesInformationModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                    modalRef.componentInstance.SummaryData = this.summaryData;
                    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                        this.allergiesSectionsModalResponse(receivedEntry);
                    });
                } else if (this.summaryData.summary.allergies.food) {
                    const modalRef = this.modalService.open(FoodAllergiesInformationModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                    modalRef.componentInstance.SummaryData = this.summaryData;
                    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                        this.allergiesSectionsModalResponse(receivedEntry);
                    });
                } else if (this.summaryData.summary.allergies.environmental) {
                    const modalRef = this.modalService.open(EnvironmentalAllergiesModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                    modalRef.componentInstance.SummaryData = this.summaryData;
                    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                        this.viewSummaryModalResponse(event);
                    });
                } else {
                    this.viewSummaryModalResponse(event);
                }
            }
        }
    }
    public allergiesSectionsModalResponse(event) {
        if (event.success && event.action === "edit") {
            this.summaryData = {
                action: "edit",
                categoryType: "",
                patientId: this.SummaryData.patientId,
                summary: event.updatedSummaryData,
            };

            if (event.section === "medication" && this.summaryData.summary.allergies.food) {
                const modalRef = this.modalService.open(FoodAllergiesInformationModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.allergiesSectionsModalResponse(receivedEntry);
                });
            } else if (this.summaryData.summary.allergies.environmental) {
                const modalRef = this.modalService.open(EnvironmentalAllergiesModalComponent, { size: "sm", windowClass: "modal-lg-small-center", centered: true, backdrop: "static" });
                modalRef.componentInstance.SummaryData = this.summaryData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.viewSummaryModalResponse(receivedEntry);
                });
            } else {
                this.viewSummaryModalResponse(event);
            }
        }
    }
    addUpdateSummary() {
        let updatedData = this.SummaryData.summary;
        let userId = this.SummaryData.patientId;
        return this.patientService.updateSummary(updatedData, userId).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Summary updated successfully", action: "edit", updatedSummaryData: this.SummaryData.summary });
                let updateEvent = new CustomEvent("UPDATE_SUMMARY", {});
                document.dispatchEvent(updateEvent);
                this.activeModal.close();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public onSaveChanges() {
        this.addUpdateSummary();
    }

    public onCancel() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.activeModal.close();
            }
        });
    }
}
