import { Injectable } from "@angular/core";
// import { Helper } from "../providers/helper";
import * as _ from 'lodash';
@Injectable()
export class SessionService {
    public currentUser: any;
    public currentUserRole: string;
    public currentUserSettings: any;
    public favoritesGraph: any;

    public getCurrentUserRole() {
        if (this.currentUserRole !== null) {
            return this.currentUserRole;
        } else {
            return "user";
        }
    }

    public getCurrentUserMembershipId() {
        return this.currentUser?.attributes.membership.membershipid;
    }

    public isUser() {
        if (this.currentUserRole === "user") {
            return true;
        }
        return false;
    }

    public isProvider() {
        if (this.currentUserRole === "provider") {
            return true;
        }
        return false;
    }

    public isAdmin() {
        if (this.currentUserRole === "admin") {
            return true;
        }
        return false;
    }

    public isProviderAdmin() {
        if (this.currentUserRole === "superprovider") {
            return true;
        }
        return false;
    }
    public isSuperAdmin() {
        if (this.currentUserRole === "superadmin") {
            return true;
        }
        return false;
    }
    public setCurrentUserRole(roles) {
        const _roles = roles.map((r) => r.toLocaleLowerCase());
        if (_roles.includes("superadmin")) {
            this.currentUserRole = "superadmin";
            return;
        }
        if (_roles.includes("admin")) {
            this.currentUserRole = "admin";
            return;
        }
        if (_roles.includes("superprovider")) {
            this.currentUserRole = "superprovider";
            return;
        }
        if (_roles.includes("provider")) {
            this.currentUserRole = "provider";
            return;
        }

        this.currentUserRole = "user";
    }

    public clearLocalStorageAndSession() {
        this.currentUser = null;
        this.currentUserRole = null;
        this.currentUserSettings = null;
        this.activeCareTeam = null;
        const sortOptions = localStorage.getItem("sort_options");
        const page_size = localStorage.getItem("page_size");
        const patient_dashboard_tab_settings = localStorage.getItem("patient_dashboard_tab_settings");
        const twoFactorAuthReminderDismissed = localStorage.getItem("twoFactorAuthReminderDismissed");
        const selectedGroup = localStorage.getItem("selectedGroup");
        const selectedGroupName = localStorage.getItem("selectedGroupName");

        localStorage.clear();
        // localStorage.setItem("sort_options", sortOptions);
        /// localStorage.setItem("page_size", page_size);
        // localStorage.setItem("patient_dashboard_tab_settings", patient_dashboard_tab_settings);
        // localStorage.setItem("twoFactorAuthReminderDismissed", twoFactorAuthReminderDismissed);
        //  localStorage.setItem("selectedGroup", JSON.stringify(selectedGroup));
        // localStorage.setItem("selectedGroupName", selectedGroupName);
    }

    // public activeCareTeam: any;
    get activeCareTeam() {
        try {
            return JSON.parse(localStorage.getItem("active_careteam"));
        } catch {
            return {};
        }
    }
    set activeCareTeam(value) {
        localStorage.setItem("active_careteam", JSON.stringify(value));
    }

    get patientDashboardTabSetting(): any {
        if (localStorage.getItem("patient_dashboard_tab_settings") == null) {
            if (this.currentUser.attributes.membership.providerpanelsettings && this.currentUser.attributes.membership.providerpanelsettings.tabOrderSettings) {
                return {
                    showChatOpened: true,
                    showCurrentVisitOpened: true,
                    showClinicalSummaryOpened: true,
                    showGoalsOpened: true,
                    tabOrderSettings: this.currentUser.attributes.membership.providerpanelsettings.tabOrderSettings,
                };
            } else {
                return null;
            }
        } else {
            return JSON.parse(localStorage.getItem("patient_dashboard_tab_settings"));
        }
    }
    set patientDashboardTabSetting(value) {
        localStorage.setItem("patient_dashboard_tab_settings", JSON.stringify(value));
    }


    setFavorites(favoriteList, selectedFactors, graphName, matric) {
        if (_.isEmpty(favoriteList)) {
            // if (favoriteList.favoriteGraphs === undefined) {
            //     favoriteList.favoriteGraphs = [];
            // }
            favoriteList.push({
                name: graphName,
                data: selectedFactors || [],
                matric: matric
            })

        }
        else {
            let isFound = false;
            favoriteList.forEach(element => {
                if (element.name === graphName) {
                    isFound = true;
                }
            });
            if (isFound) {
                favoriteList.forEach(element => {
                    if (element.name === graphName) {
                        element.data = selectedFactors,
                            element.graphName = graphName
                        element.matric = matric
                    }
                });
            }
            else {
                favoriteList.push({
                    name: graphName,
                    data: selectedFactors || [],
                    matric: matric
                })
            }
        }
        return favoriteList;
    }

    getCampaignTempale() {
        return JSON.parse(localStorage.getItem("campaignTemplate"));
    }
    set campaignTemplate(value) {
        localStorage.setItem("campaignTemplate", JSON.stringify(value));

    }

}
