import { Component, Input, Output, EventEmitter } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { CommunicationService } from "../../services/communications.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
@Component({
    selector: "app-addremove-tags",
    templateUrl: "./addremovetags.component.html",
    styleUrls: ["./addremovetags.component.scss"],
    animations: [
        trigger("fadeInOut", [
            state(
                "void",
                style({
                    opacity: 0,
                })
            ),
            transition("void <=> *", animate(500)),
        ]),
    ],
})
export class AddRemoveTagsComponent {
    constructor(private communication: CommunicationService, private snackbar: SnackbarService) {}
    hide() {}
    @Input() x = 0;
    @Input() y = 0;
    @Input() data = [];
    @Input() messageId = "";
    @Input() relative = false;
    shows = this.data;
    @Output() readonly updateTags: EventEmitter<any> = new EventEmitter();

    public saveTags() {
        this.updateTags.emit();
    }
}
