import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Helper } from "../../../providers/helper";

@Component({
    selector: "mood-wellbeing-modal",
    templateUrl: "./mood-wellbeing-modal.component.html",
    styleUrls: ["./mood-wellbeing-modal.component.scss"],
})
export class MoodWellBeingModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private helper: Helper) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    moodForm: UntypedFormGroup;
    error = "";
    ngOnInit() {
        this.moodForm = this.fb.group({
            mood: [this.SummaryData.summary.moodWellBeing.mood, []],
            chronicIllness: [this.SummaryData.summary.moodWellBeing.chronicIllness, []],
            pain: [this.SummaryData.summary.moodWellBeing.pain, []],
        });
    }
    /**
     * Returns true if form control is valid after being touched
     * @param key
     */
    isFieldValid(key) {
        return this.moodForm.get(key).invalid && this.moodForm.get(key).touched;
    }
    public onSave() {
        this.moodForm.markAllAsTouched();
        if (this.moodForm.valid) {
            this.SummaryData.summary.moodWellBeing.mood = this.helper.undefinedIfEmpty(this.moodForm.get("mood").value);
            this.SummaryData.summary.moodWellBeing.chronicIllness = this.helper.undefinedIfEmpty(this.moodForm.get("chronicIllness").value);
            this.SummaryData.summary.moodWellBeing.pain = this.helper.undefinedIfEmpty(this.moodForm.get("pain").value);
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
