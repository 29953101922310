<div class="container-fluid row">
    <div *ngIf="!isFavorite" class="row tab vol-12">
        <a class="tablinks active" [routerLink]="['/patient-visits']">
            <img src="/assets/msx/favorites-white.svg"> Favorites
        </a>
        <a class="tablinks" [routerLink]="['/visit-data']">
            <img src="/assets/msx/surveydata.png"> Visit Data
        </a>
    </div>
    <div class="row col-12">
        <div class="col-lg-6">
            <div class="card mb-2">
                <div class="card-body">
                    <app-graph *ngIf="specialitiesData" [graphData]="specialitiesData"
                        [settings]="SpecialitiesSettings"></app-graph>
                    <app-loader *ngIf="!specialitiesData" class="loader"></app-loader>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card mb-2">
                <div class="card-body">
                    <app-graph *ngIf="visitsData" [graphData]="visitsData"
                        [settings]="PatientVisitsSettings"></app-graph>
                    <app-loader *ngIf="!visitsData" class="loader"></app-loader>
                </div>
            </div>
        </div>
    </div>
</div>