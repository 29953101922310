<div class="modal-header text-white">


    <div class="col-12"
        style="padding-right: 0px !important; padding-left: 0px !important; vertical-align: middle; color: white">
        <span style="float: left">Chat</span>
        <div style="float: right">
            <button *ngIf="markAsUnreadButton" (click)="markAsUnread()" class="float-right new-button">
                Mark Last Message As Unread
            </button>
            <button type="button" class="close" (click)="onCancel()" aria-label="Close">
                <span style="color: white;" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <!-- <div class="col-6">
        Chat
    </div>
    <div class="col-6">
        <button class="float-right new-button"> Edit Profile </button>
        <button class="btn btn-primary float-right ml-1 btnunread">Mark Last Message As Unread</button> 
    </div>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div style="text-align: center" style="padding: 0px" class="modal-body">
    <app-chat-window [patient]="currentPatient" [markAsUnreadButton]="markAsUnreadButton" [patients]="patients"
        [showFilters]="false" (updateThreads)="getThreadList()"></app-chat-window>
</div>