import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { ViewOptionsModalComponent } from "../../modals/view-options-modal/view-options-modal.component";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

import { SessionService } from "../../services/session.service";
import { ViewNotesModalComponent } from "../../modals/view-notes-modal/view-notes-modal.component";
import { PatientDashboardModalComponent } from "../../modals/patient-dashboard-modal/patient-dashboard-modal.component";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
@Component({
    selector: "waiting-room-refferals",
    templateUrl: "./waiting-room-referrals.component.html",
    styleUrls: ["./waiting-room-referrals.component.css"],
})
export class WaitingRoomReferralsComponent implements OnInit {
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    public patientData: any = { patient: {} };
    public viewOptionsData: any = {};
    public notesData: any = {};
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

    constructor(
        public waitingRoomService: WaitingRoomService,
        private snackbar: SnackbarService,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private router: Router,
        private modalService: NgbModal,
        public tableService: TableService
    ) {
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
        this.tableService.searchOnColumn = "";
        this.tableService.pageSize = 10;
        this.tableService.searchTerm = "";
        route.params.subscribe((val) => {
            this.setUpUI();
        });
    }
    activeCareTeam = this.sessionService.activeCareTeam;
    roomName = "";
    wrid = "";
    filterText = "";
    patients: any;
    waitingRooms: any = [];
    isIncoming: boolean = false;
    isOutgoing: boolean = false;
    isViewAll: boolean = false;
    hasLink: boolean = false;
    public showLoader: boolean = false;
    displayedColumns: string[];
    viewOptions: any;

    ngOnInit() {
        this.setUpUI();
    }

    public setUpUI() {
        this.viewOptions = {
            pageid: "",
            columns: [
                {
                    name: "requestdate",
                    display: "Date",
                    show: false,
                    order: 0,
                },
                {
                    name: "username",
                    display: "Name",
                    show: false,
                    order: 1,
                },
                {
                    name: "totelerx",
                    display: "Referred To",
                    show: false,
                    order: 2,
                },
                {
                    name: "fromtelerx",
                    display: "Referred From",
                    show: false,
                    order: 3,
                },
                {
                    name: "chiefcomplaint",
                    display: "Reason For Visit",
                    show: false,
                    order: 4,
                },
                {
                    name: "status",
                    display: "Status",
                    show: false,
                    order: 5,
                },
                {
                    name: "actions",
                    display: "Actions",
                    show: false,
                    order: 6,
                },
            ],
        };
        this.displayedColumns = ["requestdate", "username", "totelerx", "fromtelerx", "chiefcomplaint", "status", "actions"];
        this.showLoader = true;
        this.wrid = this.route.snapshot.paramMap.get("id");
        this.isViewAll = false;
        if (this.wrid === "incoming") {
            this.isIncoming = true;
        } else if (this.wrid === "outgoing") {
            this.isOutgoing = true;
        } else {
            this.isViewAll = true;
        }
        this.getReferralsPatientsbyCategory();
    }

    public onRefresh() {
        //  this.tableService.searchTerm = "";
        //  this.patients.filter = "";
        //  this.filterText = "";
        this.getReferralsPatientsbyCategory();
    }
    defaultColumns() {
        if (this.roomName.toLowerCase() === "incoming") {
            this.displayedColumns = ["requestdate", "username", "fromtelerx", "chiefcomplaint", "actions"];
        } else if (this.roomName.toLowerCase() === "outgoing") {
            this.displayedColumns = ["requestdate", "username", "totelerx", "chiefcomplaint", "status", "actions"];
        } else {
            this.displayedColumns = ["requestdate", "username", "totelerx", "fromtelerx", "chiefcomplaint", "status", "actions"];
        }
        this.defaultViewOptions();
    }
    defaultViewOptions() {
        this.viewOptions.columns.forEach((c, i) => {
            if (this.displayedColumns.includes(c.name)) {
                c.show = true;
            }
        });
    }
    getReferralsPatientsbyCategory() {
        if (this.isViewAll) {
            this.getReferralsPatients("incoming").then(
                () => {
                    this.getReferralsPatients("outgoing");
                },
                () => {
                    console.log("Error");
                }
            );
            this.roomName = "all";
        } else {
            this.getReferralsPatients(this.wrid);
            this.roomName = this.wrid;
        }
        this.getWaitingRooms();
    }
    getReferralsPatients(wrid) {
        this.showLoader = true;
        // create an instance of a 'promise'
        var promise = new Promise((resolve, reject) => {
            return this.waitingRoomService.getReferralsPatients(wrid, this.activeCareTeam["careteamid"]).subscribe(
                (data) => {
                    if (data[0].attributes) {
                        let displayedCols = [];
                        let localStorageExists = false;
                        let existingColumnsOptions = JSON.parse(localStorage.getItem("referral-room-columns"));
                        if (existingColumnsOptions) {
                            let pageColumns = existingColumnsOptions.filter((x) => x.pageid === wrid);
                            if (pageColumns && pageColumns.length > 0) {
                                localStorageExists = true;
                                this.viewOptions = pageColumns[0];
                                this.viewOptions.columns.forEach((c, i) => {
                                    if (c.show) {
                                        displayedCols.push(c.name);
                                    }
                                });
                                this.displayedColumns = displayedCols;
                            }
                        }
                        if (displayedCols.length === 0 && !localStorageExists) {
                            this.defaultColumns();
                        } else {
                            this.displayedColumns = displayedCols;
                        }
                        data[0].attributes.list.forEach((d, i) => {
                            let columnName = "chiefcomplaintdata";
                            if (d["wrpstatus"] && d["wrpstatus"] === "inprocess") {
                                columnName = "requestdata";
                            }
                            //ChiefComplaint
                            if (this.displayedColumns.includes("chiefcomplaint")) {
                                d["chiefcomplaint"] = "";
                                if (d["requestdata"] && d["requestdata"].symptoms) {
                                    let icdCodes = [];
                                    let symptoms = d["requestdata"]?.symptoms;
                                    Object.keys(symptoms).forEach((key) => {
                                        if (symptoms[key].reasons && symptoms[key].reasons.length > 0) {
                                            let icdcode = symptoms[key].reasons
                                                .map(function (elem) {
                                                    let durationValue = symptoms[key]["duration"]
                                                        ? symptoms[key]["duration"].since +
                                                          " " +
                                                          (parseInt(symptoms[key]["duration"].since) > 1 ? symptoms[key]["duration"].sinceUnit : symptoms[key]["duration"].sinceUnit.slice(0, -1))
                                                        : " - ";
                                                    let value = elem.icdcode
                                                        ? elem.icdcode + " - " + key + " - " + elem.generalsymptoms
                                                        : "user - " +
                                                          key +
                                                          " - " +
                                                          elem.generalsymptoms +
                                                          "(Other: " +
                                                          (symptoms[key]["other"] ? symptoms[key]["other"] : " - ") +
                                                          ", Since: " +
                                                          durationValue +
                                                          ") ";
                                                    return value;
                                                })
                                                .join("<br />");
                                            icdCodes.push(icdcode);
                                        } else {
                                            let durationValue = symptoms[key]["duration"]
                                                ? symptoms[key]["duration"].since +
                                                  " " +
                                                  (parseInt(symptoms[key]["duration"].since) > 1 ? symptoms[key]["duration"].sinceUnit : symptoms[key]["duration"].sinceUnit.slice(0, -1))
                                                : " - ";
                                            let value = key + " - (Other: " + (symptoms[key]["other"] ? symptoms[key]["other"] : " - ") + ", Since: " + durationValue + ") ";
                                            icdCodes.push(value);
                                        }
                                    });
                                    if (d["requestdata"].other) {
                                        icdCodes.push("Other: " + d["requestdata"].other);
                                    }

                                    d["chiefcomplaint"] = icdCodes
                                        .map(function (elem) {
                                            return elem;
                                        })
                                        .join("<br />");
                                }
                                if (d["requestdata"] && d["requestdata"].general && d["requestdata"].general.reasons) {
                                    d["chiefcomplaint"] = Array.prototype.map
                                        .call(d["requestdata"].general.reasons, function (item) {
                                            return item.name;
                                        })
                                        .join(", ");
                                    if (d["requestdata"].general.other && d["requestdata"].general.other !== "") {
                                        if (d["chiefcomplaint"]) {
                                            d["chiefcomplaint"] = d["chiefcomplaint"] + ", " + d["requestdata"].general.other;
                                        } else {
                                            d["chiefcomplaint"] = d["requestdata"].general.other;
                                        }
                                    }
                                }
                            }
                            d["status"] = wrid;
                            if (wrid === "incoming") {
                                if (d["dob"] && d["gender"]) {
                                    var timeDiff = Math.abs(Date.now() - new Date(d["dob"]).getTime());
                                    let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
                                    d["username"] = age + " year old " + d["gender"];
                                } else {
                                    d["username"] = "New Member Referred";
                                }
                            }
                            //Notes
                            if (d[columnName] && d[columnName].notes && d[columnName].notes) {
                                d["notes"] = d[columnName].notes;
                            }
                            if (d[columnName] && d[columnName].referralNotes && d[columnName].referralNotes.notes) {
                                d["referralnotes"] = d[columnName].referralNotes.notes;
                            }
                            if (d["wrpstatus"] && d["wrpstatus"] === "inprocess") {
                                let teleRXlist = d["suggestedtelerx"];
                                d["totelerx"] = JSON.parse(teleRXlist)
                                    .map((e) => e.name)
                                    .join(", ");
                                d["status"] = "Pending Patient Selection";
                            }
                        });
                        this.patients = data[0].attributes.list;
                        this.tableService.DATA = data[0].attributes.list;
                        this.tableService.pageSize = 10;
                        this.sorting("requestdate", "desc");
                        this.showLoader = false;
                        resolve("");
                    }
                },
                (err) => {
                    this.showLoader = false;
                    reject();
                }
            );
        });
        return promise;
    }
    getWaitingRooms() {
        return this.waitingRoomService.getWaitingRooms(this.activeCareTeam["careteamid"]).subscribe(
            (data) => {
                if (data[0].attributes) {
                    this.waitingRooms = data[0].attributes.list;
                }
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public onAccept(patient) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to accept this member referral?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                let requestData = {
                    cwruid: patient.cwruid,
                    action: "accepted",
                };
                this.waitingRoomService.referAction(requestData).subscribe(
                    (data) => {
                        this.snackbar.show("Member referral accepted.");
                        this.getReferralsPatientsbyCategory();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public onCancel(patient) {
        let warningMessage = "";
        let successMessage = "";
        if (patient.status === "incoming") {
            warningMessage = "Are you sure you want to reject this member referral?";
            successMessage = "Referral rejected for member.";
        } else {
            warningMessage = "Are you sure you want to cancel this member referral?";
            successMessage = "Referral cancelled for member.";
        }
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = warningMessage;
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                let requestData = {
                    cwruid: patient.cwruid,
                    action: "cancelled",
                };
                this.waitingRoomService.referAction(requestData).subscribe(
                    (data) => {
                        this.snackbar.show(successMessage);
                        this.getReferralsPatientsbyCategory();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }

    public onViewOptions() {
        this.viewOptionsData = {
            action: "viewoptions",
            page: "ReferralRoom",
            pageid: this.wrid,
            viewOptions: this.viewOptions,
        };
        const modalRef = this.modalService.open(ViewOptionsModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.ViewOptionsData = this.viewOptionsData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.ViewOptionsModalResponse(receivedEntry);
        });
    }
    public ViewOptionsModalResponse(event) {
        if (event.success) {
            this.getReferralsPatientsbyCategory();
        } else {
            this.snackbar.show(event.message, "danger");
        }
        if (event.reset) {
            this.getReferralsPatientsbyCategory();
        }
    }
    public onNotes(patient) {
        this.notesData = {
            action: "notes",
            patient: patient,
            isReferral: true,
        };
        const modalRef = this.modalService.open(ViewNotesModalComponent, { size: "lg", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.NotesData = this.notesData;
    }

    public onPatientDashboard(user) {
        if (user.status === "incoming") {
            return;
        }
        const modalRef = this.modalService.open(PatientDashboardModalComponent, { backdrop: "static", windowClass: "custom-class" });
        modalRef.componentInstance.patientId = user.userid;
        modalRef.componentInstance.wruId = user.cwruid;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {});
    }

    onSort({ column, direction }: any, serverSideSorting = true) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction, true);
    }
    sorting(column, direction, serverSideSorting = true) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.searchOn = "username";
        this.tableService.page = 1;
    }

    displayName(name) {
        return this.viewOptions.columns.find((o) => o.name === name).display;
    }
}
