import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SortableHeaderDirective } from '../../../../directives/sortable.directive';
import { Observable } from 'rxjs';
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import { TableService } from "../../../../services/table.service";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleReportModalComponent } from '../../modals/schedule-report-modal/schedule-report-modal.component';
import { WarningModalComponent } from '../../../../../app/modals/warning-modal/warning-modal.component';
@Component({
  selector: 'app-schedule-report',
  templateUrl: './schedule-report.component.html',
  styleUrls: ['./schedule-report.component.scss']
})
export class ScheduleReportComponent implements OnInit {

  @ViewChild("input") input: ElementRef;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  page = 1;
  pageSize = 10;
  public resultsLength = 50;
  public pageNo = 1;
  reports = [];
  searchText = "";
  sortBy = "";
  orderBy = "";
  searchTimeout: any = null;
  displayedColumns = [
    { displayname: "Report Name", columnname: "reportName" },
    { displayname: "Recipients", columnname: "recipients" },
    { displayname: "Patient Experience Survey Data", columnname: "" },
    { displayname: "Visit Data", columnname: "" },
    { displayname: "Billing Data", columnname: "" },
    { displayname: "Action", columnname: "" },


  ];
  public NoRecordsFound = false;
  public pageIndex = 1;
  showLoader = true;
  resultList$: Observable<any[]>;
  total$: Observable<number>;

  constructor(
    private modalService: NgbModal,
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    public tableService: TableService,
    public activeModal: NgbActiveModal
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 10;
    this.tableService.searchTerm = "";

  }

  ngOnInit(): void {
    this.getReport();
  }

  public getReport() {
    this.showLoader = true;
    this.msxReportService.getScheduleReports(this.searchText, this.sortBy, this.orderBy).subscribe(
      (data) => {
        this.reports = data[0].attributes.data.list;
        this.tableService.DATA = this.reports;
        this.resultsLength = this.reports.length;
        this.tableService.page = 1;
        this.showLoader = false;
        // this.sorting("reportName", "asc");
      },
      (err) => {
        this.snackbar.show("`error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }

  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
    this.sortBy = column;
    this.orderBy = direction;
    this.getReport();

  }

  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;

  }

  public onPagination(page: any) {
    this.tableService.page = page;
  }

  setPageSize() {
    this.tableService.pageSize = this.pageSize;
  }
  public reportSearch() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getReport();
    }, 200);


  }
  clearSearch() {
    this.searchText = "";
    this.getReport();
  }

  public editReport(currentRow) {
    let { userid } = currentRow;
    const modalRef = this.modalService.open(ScheduleReportModalComponent, { backdrop: "static" });
    modalRef.componentInstance.userId = userid;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {

    });
  }

  public deleteReport(currentRow) {

    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Delete";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        let { userid } = currentRow;
        this.msxReportService.saveEmailschedules({}, userid).subscribe(
          (data) => {
            this.getReport();
            this.snackbar.show("Deleted successfully.", "success");
          },
          (err) => {
            this.snackbar.show(err, "danger");
          }
        );
        this.activeModal.close();
      }
    });


  }

}
