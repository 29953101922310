import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
@Component({
    selector: "xAsis-filters-modal",
    templateUrl: "./xAsis-filters-modal.component.html",
    styleUrls: ["./xAsis-filters-modal.component.scss"],
})
export class xAsisFiltersModalComponent implements OnInit {
    constructor(private activeModal: NgbActiveModal, private msxReportService: MSXReportService, private snackbar: SnackbarService) { }

    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    @Input() dataPoints = "weeks"
    @Input() parentPage = ""
    @Input() noShowPercentage = false;

    ngOnInit() {


    }
    public onSubmit() {
        this.Response.emit({
            dataPoints: this.dataPoints
        });
        this.activeModal.close();
    }

    public onCancel() {
        this.activeModal.close();
    }
}
