import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "../../../services/analytics.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { ActivatedRoute } from "@angular/router";
import { TableService } from "../../../services/table.service";
import * as Highcharts from "highcharts";

@Component({
    selector: "app-analytics-dashboard-listing",
    templateUrl: "./analytics-dashboard.component.html",
    styleUrls: ["./analytics-dashboard.component.css"],
})
export class AnalyticsDashboardComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;
    public osPieChart;
    public versionsChart;
    public usersChart;
    public selectedDaysValue = 90;
    public totalusers;
    public updateFlag1 = false;
    public updateFlag2 = false;
    public updateFlag3 = false;

    constructor(private analyticsService: AnalyticsService, private route: ActivatedRoute, private snackbar: SnackbarService, public tableService: TableService) {}

    ngOnInit(): void {
        this.getAnalytics();
    }
    public getAnalytics() {
        // note, doesn't take into account time zone
        const currentDate = new Date().getTime();
        const msPerDay = 1000 * 60 * 60 * 24;
        const daysAgo = new Date(currentDate - msPerDay * this.selectedDaysValue).toISOString().slice(0, 10);

        this.analyticsService.getReport("overallplatform", daysAgo).subscribe(
            (data) => {
                data = data[0].attributes.records.data;

                let total = 0;
                let osdata = data.map((e) => {
                    total += e.count;
                    return { name: e.os, percentage: 0, y: e.count };
                });

                osdata.forEach((os) => {
                    os.percentage = os.y / total;
                });

                this.osPieChart = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: "pie",
                    },
                    title: {
                        text: "Mobile App Usage By OS",
                    },
                    subtitle: {
                        text: `Last ${this.selectedDaysValue} days`,
                    },
                    credits: {
                        enabled: false,
                    },
                    tooltip: {
                        pointFormat: "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>",
                    },
                    accessibility: {
                        point: {
                            valueSuffix: "%",
                        },
                    },
                    plotOptions: {
                        pie: {
                            cursor: "pointer",
                            dataLabels: {
                                enabled: true,
                                format: "<b>{point.name}</b> {point.percentage:.1f}%",
                            },
                        },
                    },
                    series: [
                        {
                            name: "Usage",
                            colorByPoint: true,
                            data: osdata,
                        },
                    ],
                };
                this.updateFlag1 = true;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );

        this.analyticsService.getReport("dailyversions", daysAgo).subscribe(
            (data) => {
                data = data[0].attributes.records.data;
                const datalabels = [];
                for (let dt = new Date().getTime() - this.selectedDaysValue * msPerDay; dt <= new Date().getTime(); dt += msPerDay) {
                    datalabels.push(new Date(dt).toISOString().split("T")[0]);
                }

                const seriesdata = [];

                data.forEach((row) => {
                    const sd = seriesdata.find((s) => s.name === row.appversion);

                    if (sd) {
                        sd.data.push({ name: row.dateday, y: row.count });
                    } else {
                        seriesdata.push({ name: row.appversion, data: [{ name: row.dateday, y: row.count }] });
                    }
                });

                this.versionsChart = {
                    title: {
                        text: "Mobile App Usage By Version",
                    },
                    subtitle: {
                        text: `Last ${this.selectedDaysValue} days`,
                    },
                    credits: {
                        enabled: false,
                    },

                    chart: {
                        type: "column",
                    },
                    xAxis: {
                        type: "category",
                        categories: datalabels,
                        // max: this.selectedDaysValue,
                        crosshair: true,
                        min: 0,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Unique Users",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat:
                            '<tr style="height: inherit"><td style="color:{series.color};padding:0; border-bottom:0">{series.name}: </td>' +
                            '<td style="padding:0;border-bottom:0"><b>{point.y}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: seriesdata,
                };
                this.updateFlag2 = true;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );

        this.analyticsService.getReport("overallusers", daysAgo).subscribe(
            (data) => {
                let count = data[0].attributes.records.count;
                data = data[0].attributes.records.data;
                this.totalusers = count;

                const datalabels = [];
                for (let dt = new Date().getTime() - this.selectedDaysValue * msPerDay; dt <= new Date().getTime(); dt += msPerDay) {
                    datalabels.push(new Date(dt).toISOString().split("T")[0]);
                }

                const seriesdata = [{ name: "Users", data: [] }];
                data.forEach((row) => {
                    seriesdata[0].data.push({ name: row.dateday, y: row.count });
                });

                this.usersChart = {
                    title: {
                        text: "Unique User Count on Dashboard & Mobile",
                    },
                    subtitle: {
                        text: `Last ${this.selectedDaysValue} days`,
                    },
                    credits: {
                        enabled: false,
                    },

                    chart: {
                        type: "column",
                    },
                    xAxis: {
                        type: "category",
                        categories: datalabels,
                        // max: this.selectedDaysValue,
                        crosshair: true,
                        min: 0,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Unique GO Users",
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat:
                            '<tr style="height: inherit"><td style="color:{series.color};padding:0; border-bottom:0">{series.name}: </td>' +
                            '<td style="padding:0;border-bottom:0"><b>{point.y}</b></td></tr>',
                        footerFormat: "</table>",
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: seriesdata,
                };
                this.updateFlag3 = true;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
}
