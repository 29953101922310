import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { SessionService } from "../services/session.service";

@Injectable()
export class Helper {
    openMenu: boolean;

    public patientDashboardSetting = {
        showChatOpened: true,
        showCurrentVisitOpened: true,
        showClinicalSummaryOpened: true,
        showGoalsOpened: true,
        tabOrderSettings: [
            { text: "CHAT", label: "chat", order: 1 },
            { text: "GOALS", label: "goals", order: 2 },
            { text: "CURRENT VISIT", label: "currentVisit", order: 3 },
            { text: "CLINICAL SUMMARY", label: "clinicalSummary", order: 4 },
        ],
    };
    constructor(private http: HttpClient, private sessionService: SessionService) {
        // if (this.sessionService.patientDashboardTabSetting == null) {
        //     this.sessionService.patientDashboardTabSetting = this.patientDashboardSetting;
        // } else {
        //     this.patientDashboardSetting = this.sessionService.patientDashboardTabSetting;
        // }
    }
    public updateUserDashboardSetting() {
        if (this.sessionService.patientDashboardTabSetting == null) {
            if (
                this.sessionService.currentUser.attributes.membership.providerpanelsettings !== null &&
                this.sessionService.currentUser.attributes.membership.providerpanelsettings.tabOrderSettings !== null
            ) {
                this.patientDashboardSetting.tabOrderSettings = this.sessionService.currentUser.attributes.membership.providerpanelsettings.tabOrderSettings;
            }
            this.sessionService.patientDashboardTabSetting = this.patientDashboardSetting;
        } else {
            this.patientDashboardSetting = this.sessionService.patientDashboardTabSetting;
        }
    }
    public menuOpened = new Subject<boolean>();
    public menuOpened$ = this.menuOpened.asObservable();

    public changeMenuState(isOpen: boolean) {
        this.menuOpened.next(isOpen);
    }

    public Sort(property: any) {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a: any, b: any) {
            if (typeof a[property] !== "undefined" && typeof b[property] !== "undefined") {
                try {
                    let result = a[property].toLowerCase() < b[property].toLowerCase() ? -1 : a[property].toLowerCase() > b[property].toLowerCase() ? 1 : 0;
                    return result;
                } catch (e) { }
            }
        };
    }

    public ParseToJson(data: any) {
        try {
            if (data.status === "401" || data === "user context or refreshToken missing") {
                if (window.location.href.indexOf("login") === -1) {
                    window.location.href = "login";
                } else {
                    return JSON.parse(data.response);
                }
            } else if (data.status >= 200 && data.status < 300) {
                return JSON.parse(data.response);
            } else if (data.status === 400 || data.status === 409) {
                return JSON.parse(data.response);
            } else {
                return JSON.parse('{"errors":[{"title":"User authentication failed","detail":"An error occurred while contacting the authentication server."}]}');
            }
        } catch (ex) {
            {
                return JSON.parse('{"errors":[{"title":"User authentication failed","detail":"An error occurred while contacting the authentication server."}]}');
            }
        }
    }

    public sort(obj, property) {
        obj.sort((a, b) => {
            if (a[property] == null || b[property] == null) {
                return 0;
            } else if (a[property].toLowerCase() < b[property].toLowerCase()) {
                return -1;
            } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        return obj;
    }

    public getCookie(name: string) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");

        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
    }
    public deleteCookie(name: string) {
        let value = "";
        document.cookie = `${name}=${value}` + "; domain=." + location.hostname.split(".").reverse()[1] + "." + location.hostname.split(".").reverse()[0] + "; path=/";
    }
    public setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;
        // Set it expire in 7 days
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
        // Set it
        document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
    }

    public isOpenedTab(name) {
        if (name === "clinical_summary") {
            this.patientDashboardSetting.showClinicalSummaryOpened = !this.sessionService.patientDashboardTabSetting.showClinicalSummaryOpened;
            this.sessionService.patientDashboardTabSetting = this.patientDashboardSetting;
        }
        if (name === "current_visit") {
            this.patientDashboardSetting.showCurrentVisitOpened = !this.sessionService.patientDashboardTabSetting.showCurrentVisitOpened;
            this.sessionService.patientDashboardTabSetting = this.patientDashboardSetting;
        }
        if (name === "chat") {
            this.patientDashboardSetting.showChatOpened = !this.sessionService.patientDashboardTabSetting.showChatOpened;
            this.sessionService.patientDashboardTabSetting = this.patientDashboardSetting;
        }
        if (name === "goal") {
            this.patientDashboardSetting.showGoalsOpened = !this.sessionService.patientDashboardTabSetting.showGoalsOpened;
            this.sessionService.patientDashboardTabSetting = this.patientDashboardSetting;
        }
    }

    public undefinedIfEmpty(s) {
        if (("" + s).trim().length === 0) {
            return undefined;
        }
        return s;
    }
}
