import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarService } from "../../../../../app/shared/snackbar/snackbar.service";
import { MembersService } from "../../../../../app/services/member.service";

@Component({
    selector: "member-modal-modal",
    templateUrl: "./member-modal.component.html",
    styleUrls: ["./member-modal.component.scss"],
})
export class MemberModalComponent implements OnInit {
    constructor(private fb: UntypedFormBuilder, private activeModal: NgbActiveModal, private snackbar: SnackbarService, private formBuilder: FormBuilder, private memberService: MembersService) {}
    showLoader = false;
    submitted = false;
    @Input() groups = [];
    @Input() selectedMember: [];

    selectedGroups = [];

    @Output() readonly Response = new EventEmitter<any>();
    memberForm = this.fb.group({
        email: ["", Validators.required],
        fName: ["", Validators.required],
        lName: ["", Validators.required],
        phone: ["", Validators.required],
        birthdate: ["", Validators.required],
        gender: ["", Validators.required],
    });
    ngOnInit(): void {
        if (this.selectedMember !== undefined) {
            let ids = this.selectedMember["member_group_ids"] || "";
            let selectedGroups = ids.split(",");

            this.groups = this.groups.map((g) => ({
                ...g,
                selected: selectedGroups.includes(g.member_group_id),
            }));

            this.groups.forEach((element) => {
                if (element.selected) {
                    this.selectedGroups.push(element.member_group_id);
                }
            });

            this.memberForm = this.fb.group({
                email: [this.selectedMember["email"] || "", Validators.required],
                fName: [this.selectedMember["firstname"] || "", Validators.required],
                lName: [this.selectedMember["lastname"] || "", Validators.required],
                phone: [this.selectedMember["phonenumber"] || "", Validators.required],
                birthdate: [this.selectedMember["dob"] || "", Validators.required],
                gender: [(this.selectedMember["gender"] || "").trim(), Validators.required],
            });
        }
    }

    get form() {
        return this.memberForm.controls;
    }
    public onSave() {
        this.submitted = true;
        //let memberForm: any;
        if (this.memberForm.valid) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(this.memberForm.value.email.trim()).toLowerCase())) {
                this.snackbar.show("A valid email is required.", "danger");
                return false;
            }

            this.showLoader = true;
            this.memberService.addEditMember(this.memberForm.value, this.selectedGroups).subscribe(
                (data) => {
                    this.snackbar.show(data.attributes.message);
                    this.Response.emit({ success: true });
                    this.showLoader = false;
                    this.activeModal.close();
                },
                (err) => {
                    this.showLoader = false;
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }
    }

    onCheckChange(event) {
        if (event.target.checked) {
            this.selectedGroups.push(event.target.value);
        } else {
            this.selectedGroups.splice(this.selectedGroups.indexOf(event.target.value), 1);
        }
    }
    public onCancel() {
        this.activeModal.close();
    }
}
