import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MediaService } from "../../../../../../services/media.service";
import { SnackbarService } from "../../../../../..//shared/snackbar/snackbar.service";

@Component({
    selector: "app-review-modal",
    templateUrl: "./review-modal.component.html",
    styleUrls: ["./review-modal.component.scss"],
})
export class ReviewModalComponent {
    constructor(private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private media: MediaService,
        private snackbar: SnackbarService,
        private mediaService: MediaService,) { }
    ngOnInit(): void {
        this.Review.responses.forEach(element => {
            this.savedResponse.push(element);
        });
    }

    @Input() Title: string = "";
    @Input() Review: any;
    @Input() OKButton: string = "Continue";
    @Input() CancelButton: string = "Cancel";
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    @Input() Header: boolean = true;
    @Input() SubmitReview: boolean = false;
    @Input() Reply: string = "";
    savedResponse = []
    response = "";
    submit = false;
    public addToResponseList() {
        this.savedResponse.push(this.response);
        this.media.addResponses(this.Review.smrid, this.savedResponse).subscribe(
            (data) => {
                this.updateList(this.Review.smrid);
                this.snackbar.show("Response updated successfully", "success");
            },
            (error) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
        this.response = "";
    }

    public addToResponse(r) {
        this.response = r;
    }
    public delete(r) {
        const index = this.savedResponse.indexOf(r);
        if (index > -1) {
            this.savedResponse.splice(index, 1);
        }
        this.media.addResponses(this.Review.smrid, this.savedResponse).subscribe(
            (data) => {
                this.updateList(this.Review.smrid);
                this.snackbar.show("Response deleted successfully", "success");
            },
            (error) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    public onOK() {
        this.submit = true;
        this.Response.emit({ proceed: true });
        this.activeModal.close();
        this.submit = true;
        const modalRef = this.modalService.open(ReviewModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Review = this.Review;
        modalRef.componentInstance.SubmitReview = true;
        modalRef.componentInstance.Reply = this.response;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {

            }
        });
    }

    public onCancel() {
        if (this.SubmitReview) {
            this.Response.emit({ proceed: true });
            this.activeModal.close();
            this.submit = false;
            const modalRef = this.modalService.open(ReviewModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
            modalRef.componentInstance.Review = this.Review;
            modalRef.componentInstance.SubmitReview = false;
            modalRef.componentInstance.Reply = this.response;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                if (receivedEntry.proceed) {

                }
            });
        }
        else {
            this.Response.emit({ proceed: false });
            this.activeModal.close();
        }

    }

    public onSubmit() {
        this.media.addResponses(this.Review.smrid, this.response).subscribe(
            (data) => {
                this.snackbar.show("Response submited successfully", "success");
            },
            (error) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
        this.Response.emit({ proceed: false });
        this.activeModal.close();
    }

    public updateList(smrid) {
        this.mediaService.GetSocialMediaReviews("all").subscribe(
            (data) => {
                this.Review = data[0].attributes.data.reviews.filter((data) => {
                    return data.smrid === smrid;
                })[0];

            },
            (err) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }
}
