import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";

@Component({
    selector: "symptom-heartlung-modal",
    templateUrl: "./symptom-heartlung-modal.component.html",
    styleUrls: ["./symptom-heartlung-modal.component.scss"],
})
export class SymptomHeartLungModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private patientService: PatientService, private snackbar: SnackbarService, private modalService: NgbModal) {}

    @Input() SymptomsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    checklist: Array<any> = [];
    public others: string = "";
    public temperature: number;
    public error = "";
    public symptoms: any = {};

    ngOnInit() {
        this.symptoms = this.SymptomsData.symptoms;
        this.checklist = [
            {
                name: "Chest Pain",
                value: false,
            },
            {
                name: "Cough",
                value: false,
            },
            {
                name: "Palpitations",
                value: false,
            },
            {
                name: "Rapid Heart Beat",
                value: false,
            },
            {
                name: "Shortness of Breath",
                value: false,
            },
            {
                name: "Sputum Production",
                value: false,
            },
        ];
        this.setupForm();
    }
    getSelectedData() {
        let selectedData = [];

        this.checklist.forEach((item) => {
            if (item.value === true) {
                let i = { name: item.name, value: item.value };

                if (item.subset) {
                    let s = [];

                    item.subset.forEach((sub) => {
                        if (sub.value === true) {
                            s.push(sub);
                        }
                    });

                    i["subset"] = s;
                }

                selectedData.push(i);
            }
        });
        return selectedData;
    }

    setupForm() {
        if (this.symptoms.heartLung && this.symptoms.heartLung.reasons && this.symptoms.heartLung.reasons.length > 0) {
            this.symptoms.heartLung.reasons.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = true;

                        if (item.subset && item.subset.length > 0) {
                            if (c.subset && c.subset.length > 0) {
                                item.subset.forEach((isub) => {
                                    c.subset.forEach((csub) => {
                                        if (isub.name === csub.name) {
                                            csub.value = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            });
        }
        if (this.symptoms.heartLung && this.symptoms.heartLung.other && this.symptoms.heartLung.other.trim().length > 0) {
            this.others = this.symptoms.heartLung.other;
        }
    }

    onSubmit() {
        let selection = this.getSelectedData();

        if (selection.length > 0 || this.others.length > 0) {
            this.symptoms["heartLung"] = {
                reasons: selection,
            };

            if (this.others.trim().length > 0) {
                this.symptoms.heartLung["other"] = this.others;
            }

            this.Response.emit({ success: true, message: "", action: this.SymptomsData.action, wruid: this.SymptomsData.wruid, symptoms: this.symptoms });
            this.activeModal.close();
        } else {
            delete this.symptoms["heartLung"];
            this.Response.emit({ success: true, message: "", action: this.SymptomsData.action, wruid: this.SymptomsData.wruid, symptoms: this.symptoms });
            this.activeModal.close();
        }
    }

    public onCancel() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.activeModal.close();
            }
        });
    }
}
