import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-page-not-found",
    templateUrl: "./page-not-found.component.html",
    styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent {
    supportEmail: String = "";
    supportEmailMailTo: String = "";

    constructor() {
        this.supportEmail = environment.support_email;
        this.supportEmailMailTo = `mailto:${this.supportEmail}`;
    }
}
