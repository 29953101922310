import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { Helper } from "../../../providers/helper";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpecialtyService } from "../../../services/specialty.service";
import { CoveredServicesModalComponent } from "../../covered-services-modal/covered-services-modal.component";
import { ChatImageModalComponent } from "../../chat-image-modal/chat-image-modal.component";

@Component({
    selector: "healthinsurance-information-modal",
    templateUrl: "./healthinsurance-information-modal.component.html",
    styleUrls: ["./healthinsurance-information-modal.component.scss"],
})
export class HealthInsuranceInformationModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private specialtyService: SpecialtyService,
        private helper: Helper
    ) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public CoveredServicesData: any;
    insuranceForm: UntypedFormGroup;
    error = "";
    services: any = [];
    providers: any = [];
    imageURLFront: string;
    imageURLBack: string;
    filenameFront: string;
    filedataFront: string;
    filenameBack: string;
    filedataBack: string;
    isInvalidFrontImage: boolean = false;
    isInvalidBackImage: boolean = false;
    ngOnInit() {
        this.insuranceForm = this.fb.group({
            cardholderfirstname: [this.SummaryData.summary.healthinsuranceinformation.cardholderfirstname, []],
            cardholderlastname: [this.SummaryData.summary.healthinsuranceinformation.cardholderlastname, []],
            idnumber: [this.SummaryData.summary.healthinsuranceinformation.idnumber, []],
            groupnumber: [this.SummaryData.summary.healthinsuranceinformation.groupnumber, []],
            companyname: [this.SummaryData.summary.healthinsuranceinformation.companyname ? this.SummaryData.summary.healthinsuranceinformation.companyname : "", []],
            imagefront: [this.SummaryData.summary.healthinsuranceinformation.imagefront],
            imageback: [this.SummaryData.summary.healthinsuranceinformation.imageback],
        });
        this.getProviders();
        if (this.SummaryData.summary.healthinsuranceinformation.imagefront) {
            this.getFrontImageURL();
        }
        if (this.SummaryData.summary.healthinsuranceinformation.imageback) {
            this.getBackImageURL();
        }
        this.getCoveredServices();
    }
    /**
     * Returns true if form control is valid after being touched
     * @param key
     */
    isFieldValid(key) {
        return this.insuranceForm.get(key).invalid && this.insuranceForm.get(key).touched;
    }
    public onSave() {
        this.insuranceForm.markAllAsTouched();
        if (
            this.insuranceForm.valid
            // &&
            // (this.filedataFront || this.SummaryData.summary.healthinsuranceinformation.imagefront) &&
            // (this.filenameBack || this.SummaryData.summary.healthinsuranceinformation.imageback)
        ) {
            this.SummaryData.summary.healthinsuranceinformation.cardholderfirstname = this.helper.undefinedIfEmpty(this.insuranceForm.get("cardholderfirstname").value);
            this.SummaryData.summary.healthinsuranceinformation.cardholderlastname = this.helper.undefinedIfEmpty(this.insuranceForm.get("cardholderlastname").value);
            this.SummaryData.summary.healthinsuranceinformation.groupnumber = this.helper.undefinedIfEmpty(this.insuranceForm.get("groupnumber").value);
            this.SummaryData.summary.healthinsuranceinformation.idnumber = this.helper.undefinedIfEmpty(this.insuranceForm.get("idnumber").value);
            this.SummaryData.summary.healthinsuranceinformation.companyname = this.helper.undefinedIfEmpty(this.insuranceForm.get("companyname").value);
            this.SummaryData.summary.healthinsuranceinformation.company = this.providers.find((e) => e.healthcareid == this.insuranceForm.get("companyname").value);

            if (this.filedataFront && this.filedataFront !== "") {
                const formData = new FormData();
                formData.append("file", this.insuranceForm.get("imagefront").value, this.filenameFront);
                return this.patientService.uploadFile(formData).subscribe(
                    (uploadData) => {
                        this.SummaryData.summary.healthinsuranceinformation.imagefront = uploadData.attributes.libraryid;
                        if (this.filedataBack && this.filedataBack !== "") {
                            const formDataback = new FormData();
                            formDataback.append("file", this.insuranceForm.get("imageback").value, this.filenameBack);
                            return this.patientService.uploadFile(formDataback).subscribe(
                                (uploadData) => {
                                    this.SummaryData.summary.healthinsuranceinformation.imageback = uploadData.attributes.libraryid;
                                    this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
                                    this.activeModal.close();
                                },
                                (err) => {
                                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                                }
                            );
                        } else {
                            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
                            this.activeModal.close();
                        }
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            } else if (this.filedataBack && this.filedataBack !== "") {
                const formData = new FormData();
                formData.append("file", this.insuranceForm.get("imageback").value, this.filenameBack);
                return this.patientService.uploadFile(formData).subscribe(
                    (uploadData) => {
                        this.SummaryData.summary.healthinsuranceinformation.imageback = uploadData.attributes.libraryid;
                        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
                        this.activeModal.close();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } //else {
        // if (!this.filedataFront || this.filedataFront === "") {
        //     this.isInvalidFrontImage = true;
        // }
        // if (!this.filedataBack || this.filedataBack === "") {
        //     this.isInvalidBackImage = true;
        // }
        //this.error = "Please provide mandatory fields.";
        //}
    }
    public getFrontImageURL() {
        return this.patientService.getImage(this.SummaryData.summary.healthinsuranceinformation.imagefront).subscribe(
            (data) => {
                this.imageURLFront = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getBackImageURL() {
        return this.patientService.getImage(this.SummaryData.summary.healthinsuranceinformation.imageback).subscribe(
            (data) => {
                this.imageURLBack = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    // Image Preview
    showPreviewFront(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.insuranceForm.patchValue({
            imagefront: file,
        });
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.filenameFront = file.name;
                this.filedataFront = reader.result.toString().substr(reader.result.toString().indexOf(",") + 1);
                this.imageURLFront = reader.result as string;
                this.isInvalidFrontImage = false;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    }
    // Image Preview
    showPreviewBack(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.insuranceForm.patchValue({
            imageback: file,
        });
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.filenameBack = file.name;
                this.filedataBack = reader.result.toString().substr(reader.result.toString().indexOf(",") + 1);
                this.imageURLBack = reader.result as string;
                this.isInvalidBackImage = false;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    }
    public onViewCoveredServices(e) {
        e.stopPropagation();

        this.CoveredServicesData = {
            action: "view",
            services: this.services,
        };
        const modalRef = this.modalService.open(CoveredServicesModalComponent, { size: "sm", windowClass: "modal-lg-small-center", backdrop: "static" });
        modalRef.componentInstance.CoveredServicesData = this.CoveredServicesData;
    }
    public getCoveredServices() {
        return this.specialtyService.getServices().subscribe(
            async (data) => {
                this.services = data.attributes.list;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public getProviders() {
        return this.specialtyService.getPayers().subscribe(
            async (data) => {
                this.providers = data.attributes.list;
                this.insuranceForm.patchValue({
                    companyname: this.SummaryData.summary.healthinsuranceinformation.companyname ? this.SummaryData.summary.healthinsuranceinformation.companyname : "",
                });
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }
}
