import { Component, OnInit, ViewChild, ViewChildren, OnDestroy, ElementRef, HostListener, QueryList } from "@angular/core";
import { SortableHeaderDirective, SortEvent } from "../../../directives/sortable.directive";
import { MSXReportService } from "../../../services/msx_report.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { TableService } from "../../../services/table.service";
import { Observable } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  name = 'Iframe Src';
  url: string = 'https://adminv2-dev.medstatix.com/Auth?sessionkey=892190052be683516aedabcd8a351520d050edcfcc466bcf062fefe20e22f56ccad2339b36fcc46aac025fb98e68082193e185aba38f56356967ee2bbec3965d7ec9da116d414128b4cda29acfc6ba2cd4096008e52c4ffd7f99c2bb30e8afa067fb38e0da79718f3ccd45e39000fc7a3835d0b218dcf72286168ea4b92369f3c88fa008f70bf88748e4a47e24ca98e68748';
  urlSafe: SafeResourceUrl;


  @ViewChild("input") input: ElementRef;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  page = 1;
  pageSize = 10;
  public resultsLength = 50;
  public pageNo = 1;

  displayedColumns = [
    { displayname: "group name", columnname: "name" },
    { displayname: "future visits capture", columnname: "futurevisits" },

  ];
  public NoRecordsFound = false;
  public pageIndex = 1;
  showLoader = true;
  groups = [];
  resultList$: Observable<any[]>;
  total$: Observable<number>;
  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    public tableService: TableService,
    public sanitizer: DomSanitizer
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 10;
    this.tableService.searchTerm = "";

  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.msxReportService.GetEntity().subscribe(
      (data) => {
        this.groups = data[0].attributes.data.groups;
        this.tableService.DATA = this.groups;
        this.resultsLength = this.groups.length;
        this.tableService.page = 1;
        this.showLoader = false;
        this.sorting("name", "asc");
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }

  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }
  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
  }

  updateFutureVisit(event, groupid) {
    let status = event.currentTarget.checked ? 1 : 0;
    this.msxReportService.UpdateFutureVisit(status, groupid).subscribe(
      (data) => {
        this.snackbar.show("future visits captured successfully.", "success");
        this.groups.find((element) => element.groupid === groupid).futurevisits = status;
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }

  public onPagination(page: any) {
    this.tableService.page = page;
  }
  setPageSize() {
    this.tableService.pageSize = this.pageSize;
  }
}
