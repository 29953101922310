import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PracticeService } from "../../services/practice.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SessionService } from "../../services/session.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "location-apps-modal",
    templateUrl: "./location-modal.component.html",
    styleUrls: ["./location-modal.component.css"],
})
export class LocationModalComponent implements OnInit {
    @Output() readonly Response = new EventEmitter<any>();
    locationForm: UntypedFormGroup;
    @Input() locationData = [];
    @Input() action = "add";
    states = [];
    constructor(
        private activeModal: NgbActiveModal,
        private snackbar: SnackbarService,
        private practiceService: PracticeService,
        private fb: UntypedFormBuilder,
        private sessionService: SessionService,
        private http: HttpClient
    ) {}

    List = [];
    _providers = [];
    _isEdit = false;
    submitted = false;
    ngOnInit(): void {
        this._isEdit = this.action == "edit" ? true : false;
        this.locationForm = this.fb.group({
            membershipid: [this.sessionService.getCurrentUserMembershipId()],
            practicename: [this._isEdit ? this.locationData["practicename"] : "", Validators.required],
            address1: [this._isEdit ? this.locationData["address1"] : "", Validators.required],
            address2: [this._isEdit ? this.locationData["address2"] : ""],
            city: [this._isEdit ? this.locationData["city"] : "", Validators.required],
            state: [this._isEdit ? this.locationData["state"] : "", Validators.required],
            country: ["US", Validators.required],
            postalcode: [this._isEdit ? this.locationData["postalcode"] : "", Validators.required],
            phonenumber: [this._isEdit ? this.locationData["phonenumber"] : "", Validators.required],
            isdefault: [this._isEdit ? this.locationData["isdefault"] : false],
            lat: [this._isEdit ? this.locationData["lat"] : ""],
            lng: [this._isEdit ? this.locationData["lng"] : ""],
        });
        if (this._isEdit) {
            this._providers = this.locationData["providers"];
        } else {
            this._providers = [];
        }
        this.getStates().subscribe((data) => {
            this.states = data;
        });
    }
    currentApp: any = {};
    public onCancel() {
        this.activeModal.close();
    }
    public getStates(): Observable<any> {
        return this.http.get("../../../assets/states.json");
    }
    public saveChanges() {
        this.submitted = true;
        if (this.locationForm.valid) {
            // console.log(this.locationForm.value);
            this.practiceService.save(this.locationForm.value, [], this._isEdit, this._isEdit ? this.locationData["ptid"] : "").subscribe(
                (data) => {
                    this.Response.emit({ success: true, message: "Location saved successfully." });
                    this.activeModal.close();
                },
                (err) => {
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            this.snackbar.show("Please provide mandatory fields.", "danger");
        }
    }
    public addProvider(providername) {
        if (providername.value.trim() != "" && this._providers.indexOf(providername.value) === -1) {
            this._providers.push(providername.value);
            providername.value = "";
            this.locationForm.value.providers = this._providers;
        }
    }
    public removeProvider(providername) {
        this._providers = this._providers.filter((el) => el !== providername);
    }
    isFieldValid(key) {
        return this.locationForm.get(key).invalid && this.locationForm.get(key).touched;
    }
}
