<ngb-accordion #acc="ngbAccordion" cdkDrag (panelChange)="isOpenedTab('clinical_summary')"
    activeIds="{{ sessionService.patientDashboardTabSetting.showClinicalSummaryOpened ? 'clinical_summary-panel' : '' }}"
    class="range-accordion" [ngStyle]="{ order: getDisplayOrder('clinicalSummary') }">
    <ngb-panel id="clinical_summary-panel">
        <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
                    <span class="accordion-title">Clinical Summary</span>
                    <div class="d-flex align-items-center">
                        <button
                            (click)="$event.stopPropagation(); viewSummaryModalResponse({ success: true, action: 'edit' })"
                            class="new-button">
                            <i class="fas fa-edit"></i> Edit Profile
                        </button>
                        <div *ngIf="clinicalSummaryModel" class="panel-clinical-title-edit cursor-pointer"
                            (click)="onViewEditClinicalSummary(); $event.stopPropagation()">
                            <i aria-hidden="true" class="fa fa-external-link-alt"></i>
                        </div>
                        <i class="fa fa-arrows-alt move-arrow"></i>
                        <i class="tab-expand-icon"
                            [ngClass]="sessionService.patientDashboardTabSetting.showClinicalSummaryOpened ? 'fa fa-window-minimize' : 'fa fa-window-maximize'"
                            aria-hidden="true"></i>
                    </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="panel-content row" *ngIf="clinicalSummaryModel">
                <div class="clinical-content col-8">
                    <div class="summary-content-title" style="margin-top: 0px !important">
                        <span>Personal Information</span>
                    </div>
                    <div class="summary-content-left">First Name</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.firstname ?
                        clinicalSummaryModel.personalinformation.firstname : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Middle Name</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.middlename ?
                        clinicalSummaryModel.personalinformation.middlename : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Last Name</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.lastname ?
                        clinicalSummaryModel.personalinformation.lastname : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Maiden Name</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.maidenname ?
                        clinicalSummaryModel.personalinformation.maidenname : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Medical Record Number</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.mrn ?
                        clinicalSummaryModel.personalinformation.mrn : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Gender</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.gender ?
                        (clinicalSummaryModel.personalinformation.gender | titlecase) : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Date of Birth</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.dob ?
                        (clinicalSummaryModel.personalinformation.dob | date: "MM/dd/yyyy") : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Marital Status</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.maritalstatus ?
                        clinicalSummaryModel.personalinformation.maritalstatus : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Children</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.children ?
                        clinicalSummaryModel.personalinformation.children : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Grand Children</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.grandchildren ?
                        clinicalSummaryModel.personalinformation.grandchildren : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Living Arrangement</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.personalinformation.livingarrangement ?
                        clinicalSummaryModel.personalinformation.livingarrangement : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Employment</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.personalinformation.employment ?
                        clinicalSummaryModel.personalinformation.employment : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Photo ID</div>
                    <div class="summary-content-row image-row" *ngIf="imageURLPhotoID">
                        <div class="imagePreview">
                            <img (click)="showImage(imageURLPhotoID ? imageURLPhotoID : '../../../assets/images/addimage.png')"
                                [src]="imageURLPhotoID ? imageURLPhotoID : '../../../assets/images/addimage.png'" />
                        </div>
                    </div>
                    <div *ngIf="!imageURLPhotoID" class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />

                    <div class="summary-content-title">
                        <span>Contact Information</span>
                    </div>
                    <div class="summary-content-left">Phone Number 1</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.contactinformation.phone1type }} {{
                        clinicalSummaryModel.contactinformation.phone1type ? "-" : "" }}
                        {{ clinicalSummaryModel.contactinformation.phone1 ?
                        clinicalSummaryModel.contactinformation.phone1 : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Phone Number 2</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.contactinformation.phone2type }} {{
                        clinicalSummaryModel.contactinformation.phone2type ? "-" : "" }}
                        {{ clinicalSummaryModel.contactinformation.phone2 ?
                        clinicalSummaryModel.contactinformation.phone2 : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Phone Number 3</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.contactinformation.phone3type }} {{
                        clinicalSummaryModel.contactinformation.phone3type ? "-" : "" }}
                        {{ clinicalSummaryModel.contactinformation.phone3 ?
                        clinicalSummaryModel.contactinformation.phone3 : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Address 1</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.contactinformation.address1 ?
                        clinicalSummaryModel.contactinformation.address1 : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Address 2</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.contactinformation.address2 ?
                        clinicalSummaryModel.contactinformation.address2 : "" }}</div>
                    <hr />
                    <div class="summary-content-left">City</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.contactinformation.city ?
                        clinicalSummaryModel.contactinformation.city : "" }}</div>
                    <hr />
                    <div class="summary-content-left">State</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.contactinformation.state ?
                        clinicalSummaryModel.contactinformation.state : "" }}</div>
                    <hr />
                    <div class="summary-content-left">ZIP Code</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.contactinformation.zip ?
                        clinicalSummaryModel.contactinformation.zip : "" }}</div>
                    <hr />
                    <div class="summary-content-title">
                        <span>Emergency Contact</span>
                    </div>
                    <div class="summary-content-left">Name</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.emergencycontact.name ?
                        clinicalSummaryModel.emergencycontact.name : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Phone Number</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.emergencycontact.phonenumber1 ?
                        clinicalSummaryModel.emergencycontact.phonenumber1 : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Relation</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.emergencycontact.relation ?
                        (clinicalSummaryModel.emergencycontact.relation | titlecase) : "" }}</div>
                    <hr />
                    <div class="summary-content-title">
                        <span>Health Insurance Information</span>
                    </div>
                    <div class="summary-content-left">Cardholder First Name</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname ?
                        clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Cardholder Last Name</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.healthinsuranceinformation.cardholderlastname ?
                        clinicalSummaryModel.healthinsuranceinformation.cardholderlastname : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">ID Number</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.healthinsuranceinformation.idnumber ?
                        clinicalSummaryModel.healthinsuranceinformation.idnumber : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Group Number</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.healthinsuranceinformation.groupnumber ?
                        clinicalSummaryModel.healthinsuranceinformation.groupnumber : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Health Coverage Provider</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.healthinsuranceinformation.company ?
                        clinicalSummaryModel.healthinsuranceinformation.company.payername : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Health Insurance Card: Front</div>
                    <div class="summary-content-row image-row" *ngIf="imageURLFront">
                        <div class="imagePreview">
                            <img (click)="showImage(imageURLFront ? imageURLFront : '../../../assets/images/addimage.png')"
                                [src]="imageURLFront ? imageURLFront : '../../../assets/images/addimage.png'" />
                        </div>
                    </div>
                    <div *ngIf="!imageURLFront" class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />
                    <div class="summary-content-left">Heath Insurance Card: Back</div>
                    <div class="summary-content-row image-row" *ngIf="imageURLBack">
                        <div class="imagePreview">
                            <img (click)="showImage(imageURLBack ? imageURLBack : '../../../assets/images/addimage.png')"
                                [src]="imageURLBack ? imageURLBack : '../../../assets/images/addimage.png'" />
                        </div>
                    </div>
                    <div *ngIf="!imageURLBack" class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />
                    <div class="summary-content-title">
                        <span>Past Medical History</span>
                    </div>
                    <div class="summary-content-left">History</div>
                    <div *ngIf="
                            (clinicalSummaryModel.pastmedicalhistory.historyrecords && clinicalSummaryModel.pastmedicalhistory.historyrecords.length > 0) ||
                            clinicalSummaryModel.pastmedicalhistory.other
                        " class="summary-content-row summary-content-row-list">
                        <span
                            *ngFor="let history of clinicalSummaryModel.pastmedicalhistory.historyrecords; let i = index">
                            {{ history.name
                            }}<span *ngIf="history.subset && history.subset.length > 0">(<span
                                    *ngFor="let subset of history.subset; let j = index">{{ subset.name }}{{ j + 1 ===
                                    history.subset.length ? "" : ", " }}</span>)</span>{{ i + 1 ===
                            clinicalSummaryModel.pastmedicalhistory.historyrecords.length ? "" : "," }}</span>
                        <span *ngIf="clinicalSummaryModel.pastmedicalhistory.other">{{
                            clinicalSummaryModel.pastmedicalhistory.historyrecords ?
                            (clinicalSummaryModel.pastmedicalhistory.historyrecords.length > 0 ? ", " : "") : ""
                            }}{{ clinicalSummaryModel.pastmedicalhistory.other }}</span>
                    </div>
                    <div *ngIf="
                            !clinicalSummaryModel.pastmedicalhistory.historyrecords ||
                            (clinicalSummaryModel.pastmedicalhistory.historyrecords.length === 0 &&
                                (!clinicalSummaryModel.pastmedicalhistory.other || clinicalSummaryModel.pastmedicalhistory.other.length === 0))
                        " class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />
                    <div class="summary-content-title">
                        <span>Past Surgical History</span>
                    </div>
                    <div class="summary-content-left">History</div>
                    <div *ngIf="
                            (clinicalSummaryModel.pastsurgicalhistory.historyrecords && clinicalSummaryModel.pastsurgicalhistory.historyrecords.length > 0) ||
                            clinicalSummaryModel.pastsurgicalhistory.other
                        " class="summary-content-row summary-content-row-list">
                        <span
                            *ngFor="let history of clinicalSummaryModel.pastsurgicalhistory.historyrecords; let i = index">
                            {{ history.name
                            }}<span *ngIf="history.subset && history.subset.length > 0">(<span
                                    *ngFor="let subset of history.subset; let j = index">{{ subset.name }}{{ j + 1 ===
                                    history.subset.length ? "" : ", " }}</span>)</span>{{ i + 1 ===
                            clinicalSummaryModel.pastsurgicalhistory.historyrecords.length ? "" : "," }}</span>
                        <span *ngIf="clinicalSummaryModel.pastsurgicalhistory.other">{{
                            clinicalSummaryModel.pastsurgicalhistory.historyrecords ?
                            (clinicalSummaryModel.pastsurgicalhistory.historyrecords.length > 0 ? ", " : "") : ""
                            }}{{ clinicalSummaryModel.pastsurgicalhistory.other }}
                        </span>
                    </div>
                    <div *ngIf="
                            !clinicalSummaryModel.pastsurgicalhistory.historyrecords ||
                            (clinicalSummaryModel.pastsurgicalhistory.historyrecords.length === 0 &&
                                (!clinicalSummaryModel.pastsurgicalhistory.other || clinicalSummaryModel.pastsurgicalhistory.other.length === 0))
                        " class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />
                    <div [hidden]="!(clinicalSummaryModel.personalinformation.gender === 'female')"
                        class="woman-section">
                        <div class="summary-content-title">
                            <span>Women Health History</span>
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Age at First Period</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.firstperiod ?
                                clinicalSummaryModel.womenhealthhistory.firstperiod : "" }}</div>
                            <hr />
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Days Between Periods</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.periodgap ?
                                clinicalSummaryModel.womenhealthhistory.periodgap : "" }}</div>
                            <hr />
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Period Duration in Days</div>
                            <div class="summary-content-row">
                                {{ clinicalSummaryModel.womenhealthhistory.periodduration ?
                                clinicalSummaryModel.womenhealthhistory.periodduration : "" }}
                            </div>
                            <hr />
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Flow</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.periodflow ?
                                clinicalSummaryModel.womenhealthhistory.periodflow : "" }}</div>
                            <hr />
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Do you use birth control?</div>
                            <div class="summary-content-row">
                                {{ clinicalSummaryModel.womenhealthhistory.birthcontrol === null ? "" :
                                clinicalSummaryModel.womenhealthhistory.birthcontrol ? "Yes" : "No" }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.birthcontrol"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Birth Control Type</div>
                            <div class="summary-content-row summary-content-row-list"
                                *ngIf="clinicalSummaryModel.womenhealthhistory.birthcontroltype && clinicalSummaryModel.womenhealthhistory.birthcontroltype.length > 0">
                                <span
                                    *ngFor="let item of clinicalSummaryModel.womenhealthhistory.birthcontroltype; let i = index"><span
                                        *ngIf="item.name !== 'Other'">{{ item.name }} <br /></span><span
                                        *ngIf="item.name === 'Other' && clinicalSummaryModel.womenhealthhistory.otherbirthcontrol">{{
                                        clinicalSummaryModel.womenhealthhistory.otherbirthcontrol }}
                                        <br /></span></span>
                            </div>
                            <div class="summary-content-row"
                                *ngIf="!clinicalSummaryModel.womenhealthhistory.birthcontroltype || clinicalSummaryModel.womenhealthhistory.birthcontroltype.length === 0">
                            </div>
                            <hr />
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Have you ever been pregnant?</div>
                            <div class="summary-content-row">
                                {{ clinicalSummaryModel.womenhealthhistory.pregnancy === null ? "" :
                                clinicalSummaryModel.womenhealthhistory.pregnancy ? "Yes" : "No" }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Total Pregnancies</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.totalpregnancies
                                }}</div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Full-term births</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.fulltermbirths
                                }}</div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Premature births</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.premature }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Miscarriages</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.miscarriages }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Abortions</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.abortions }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Vaginal births</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.vaginalbirths }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">C-Section births</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.csections }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.pregnancy"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Pregnancy Complications</div>
                            <div class="summary-content-row summary-content-row-list"
                                *ngIf="clinicalSummaryModel.womenhealthhistory.pregnancycomplications && clinicalSummaryModel.womenhealthhistory.pregnancycomplications.length > 0">
                                <span
                                    *ngFor="let item of clinicalSummaryModel.womenhealthhistory.pregnancycomplications; let i = index"><span
                                        *ngIf="item.name !== 'Other'">{{ item.name }} <br /></span><span
                                        *ngIf="item.name === 'Other' && clinicalSummaryModel.womenhealthhistory.otherpregnancycomplications">{{
                                        clinicalSummaryModel.womenhealthhistory.otherpregnancycomplications }}
                                        <br /></span></span>
                            </div>
                            <div class="summary-content-row"
                                *ngIf="!clinicalSummaryModel.womenhealthhistory.pregnancycomplications || clinicalSummaryModel.womenhealthhistory.pregnancycomplications.length === 0">
                            </div>
                            <hr />
                        </div>
                        <div class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Have you reached menopause?</div>
                            <div class="summary-content-row">
                                {{ clinicalSummaryModel.womenhealthhistory.menopausal === null ? "" :
                                clinicalSummaryModel.womenhealthhistory.menopausal ? "Yes" : "No" }}
                            </div>
                            <hr />
                        </div>
                        <div [hidden]="!clinicalSummaryModel.womenhealthhistory.menopausal"
                            class="summary-content-womenhealthhistory">
                            <div class="summary-content-left">Age at Onset of Menopause</div>
                            <div class="summary-content-row">{{ clinicalSummaryModel.womenhealthhistory.menopauseage ?
                                clinicalSummaryModel.womenhealthhistory.menopauseage : "" }}</div>
                            <hr />
                        </div>
                    </div>
                    <div class="summary-content-title">
                        <span>Medication Allergies</span>
                    </div>
                    <div class="summary-content-left">Medication Allergies</div>
                    <div *ngIf="
                            (clinicalSummaryModel.medicationallergies.allergies && clinicalSummaryModel.medicationallergies.allergies.length > 0) ||
                            (clinicalSummaryModel.medicationallergies.other && clinicalSummaryModel.medicationallergies.other !== '')
                        " class="summary-content-row summary-content-row-list">
                        <span *ngFor="let allergy of clinicalSummaryModel.medicationallergies.allergies; let i = index">
                            {{ allergy.name }}{{ i + 1 === clinicalSummaryModel.medicationallergies.allergies.length ?
                            "" : ", " }}</span>
                        <span *ngIf="clinicalSummaryModel.medicationallergies.other">{{
                            clinicalSummaryModel.medicationallergies.allergies ?
                            (clinicalSummaryModel.medicationallergies.allergies.length > 0 ? ", " : "") : ""
                            }}{{ clinicalSummaryModel.medicationallergies.other }}</span>
                    </div>
                    <div class="summary-content-row" *ngIf="
                            (!clinicalSummaryModel.medicationallergies.allergies || clinicalSummaryModel.medicationallergies.allergies.length === 0) &&
                            (!clinicalSummaryModel.medicationallergies.other || clinicalSummaryModel.medicationallergies.other === '')
                        ">
                        <span></span>
                    </div>
                    <hr />

                    <div class="summary-content-title">
                        <span>Food Allergies</span>
                    </div>
                    <div class="summary-content-left">Food Allergies</div>
                    <div *ngIf="clinicalSummaryModel.foodallergies.allergies && clinicalSummaryModel.foodallergies.allergies.length > 0"
                        class="summary-content-row summary-content-row-list">
                        <span *ngFor="let allergy of clinicalSummaryModel.foodallergies.allergies; let i = index">
                            {{ allergy.name
                            }}<span *ngIf="allergy.subset && allergy.subset.length > 0">(<span
                                    *ngFor="let subset of allergy.subset; let j = index">{{ subset.name }}{{ j + 1 ===
                                    allergy.subset.length ? "" : ", " }}</span>
                                <span *ngIf="allergy.name === 'Other' && clinicalSummaryModel.foodallergies.other">{{
                                    allergy.subset.length > 0 ? ", " : "" }}{{ clinicalSummaryModel.foodallergies.other
                                    }}</span>)</span>
                            <span
                                *ngIf="allergy.name === 'Other' && allergy.subset.length < 1 && clinicalSummaryModel.foodallergies.other">({{
                                clinicalSummaryModel.foodallergies.other }})</span>{{ i + 1 ===
                            clinicalSummaryModel.foodallergies.allergies.length ? "" : "," }}</span>
                    </div>
                    <div *ngIf="!clinicalSummaryModel.foodallergies.allergies || clinicalSummaryModel.foodallergies.allergies.length === 0"
                        class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />
                    <div class="summary-content-title">
                        <span>Environmental Allergies</span>
                    </div>
                    <div class="summary-content-left">Environmental Allergies</div>
                    <div *ngIf="clinicalSummaryModel.environmentalallergies.allergies && clinicalSummaryModel.environmentalallergies.allergies.length > 0"
                        class="summary-content-row summary-content-row-list">
                        <span
                            *ngFor="let allergy of clinicalSummaryModel.environmentalallergies.allergies; let i = index">
                            {{ allergy.name
                            }}<span *ngIf="allergy.subset && allergy.subset.length > 0">(<span
                                    *ngFor="let subset of allergy.subset; let j = index">{{ subset.name }}{{ j + 1 ===
                                    allergy.subset.length ? "" : ", " }}</span>
                                <span
                                    *ngIf="allergy.name === 'Other' && clinicalSummaryModel.environmentalallergies.other">{{
                                    allergy.subset.length > 0 ? ", " : "" }}{{
                                    clinicalSummaryModel.environmentalallergies.other }}</span>)</span>
                            <span
                                *ngIf="allergy.name === 'Other' && allergy.subset.length < 1 && clinicalSummaryModel.environmentalallergies.other">({{
                                clinicalSummaryModel.environmentalallergies.other }})</span>{{ i + 1 ===
                            clinicalSummaryModel.environmentalallergies.allergies.length ? "" : "," }}</span>
                    </div>
                    <div *ngIf="!clinicalSummaryModel.environmentalallergies.allergies || clinicalSummaryModel.environmentalallergies.allergies.length === 0"
                        class="summary-content-row">
                        <span></span>
                    </div>
                    <hr />
                    <div class="summary-content-title">
                        <span>Social History</span>
                    </div>
                    <div class="summary-content-left">Tobacco Use</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.tobaccouse ?
                        clinicalSummaryModel.socialhistory.tobaccouse : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Alcohol Use (Drinks)</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.alcoholuse ?
                        clinicalSummaryModel.socialhistory.alcoholuse : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Drug Use</div>
                    <div class="summary-content-row">
                        {{ clinicalSummaryModel.socialhistory.druguse === null ? "" :
                        clinicalSummaryModel.socialhistory.druguse ? "Yes" : "No" }}
                        {{ clinicalSummaryModel.socialhistory.druguse ? (clinicalSummaryModel.socialhistory.drugdetails
                        ? " - " + clinicalSummaryModel.socialhistory.drugdetails : "") : "" }}
                    </div>
                    <hr />
                    <div class="summary-content-left">Caffeine Use</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.caffeineuse ?
                        clinicalSummaryModel.socialhistory.caffeineuse : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Sexual Activity</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.sexuallyactive ?
                        clinicalSummaryModel.socialhistory.sexuallyactive : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Exercise Frequency</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.exerciseFrequency ?
                        clinicalSummaryModel.socialhistory.exerciseFrequency : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Exercise Intensity</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.exerciseIntensity ?
                        clinicalSummaryModel.socialhistory.exerciseIntensity : "" }}</div>
                    <hr />
                    <div class="summary-content-left">Exercise Duration</div>
                    <div class="summary-content-row">{{ clinicalSummaryModel.socialhistory.exerciseDuration ?
                        clinicalSummaryModel.socialhistory.exerciseDuration : "" }}</div>
                    <!-- <hr />

                    <div class="summary-content-left">Vaccination Card</div>
                    <div class="summary-content-row image-row" *ngIf="imageURLVaccination">
                        <div class="imagePreview">
                            <img (click)="showImage(imageURLVaccination ? imageURLVaccination : '../../../assets/images/addimage.png')"
                                class="insurance-image"
                                [src]="imageURLVaccination ? imageURLVaccination : '../../../assets/images/addimage.png'" />
                        </div>
                    </div>
                    <div *ngIf="!imageURLVaccination" class="summary-content-row">
                        <span></span>
                    </div> -->
                    <hr />


                    <div class="summary-content-title">
                        <span>Vaccines</span>
                    </div>
                    <hr
                        *ngIf="!clinicalSummaryModel.vaccines.vaccines || clinicalSummaryModel.vaccines.vaccines.length < 1" />
                    <div class="summary-content-womenhealthhistory"
                        *ngFor="let item of clinicalSummaryModel.vaccines.vaccines; let i = index">
                        <div class="summary-content-left">{{ item.name }}</div>
                        <div class="summary-content-row">
                            {{ item.value && item.value.length > 0 ? (item.value.toString() === "Dont Know" ? "Don't
                            Know" : item.value.toString()) : "" }}
                        </div>
                        <hr />
                    </div>
                    <div class="summary-content-title">
                        <span>Family History</span>
                    </div>
                    <hr *ngIf="
                            (!clinicalSummaryModel.familyhistory.history || clinicalSummaryModel.familyhistory.history.length < 1) &&
                            (!clinicalSummaryModel.familyhistory.other || clinicalSummaryModel.familyhistory.other === '')
                        " />
                    <div class="summary-content-womenhealthhistory"
                        *ngFor="let item of clinicalSummaryModel.familyhistory.history; let i = index">
                        <div *ngIf="item.value.length > 0">
                            <div class="summary-content-left">
                                {{ item.name }}
                            </div>
                            <div class="summary-content-row-tabs">
                                <div class="family-history-tab-first" *ngIf="item.value.includes('Father')">Father</div>
                                <div class="family-history-tab-first" *ngIf="!item.value.includes('Father')"></div>
                                <div class="family-history-tab-first" *ngIf="item.value.includes('Mother')">Mother</div>
                                <div class="family-history-tab-first" *ngIf="!item.value.includes('Mother')"></div>
                                <div class="family-history-tab" *ngIf="item.value.includes('Maternal Grandparent')">
                                    Maternal Grandparent</div>
                                <div class="family-history-tab" *ngIf="!item.value.includes('Maternal Grandparent')">
                                </div>
                                <div class="family-history-tab" *ngIf="item.value.includes('Paternal Grandparent')">
                                    Paternal Grandparent</div>
                                <div class="family-history-tab" *ngIf="!item.value.includes('Paternal Grandparent')">
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>

                    <div class="summary-content-womenhealthhistory"
                        *ngIf="clinicalSummaryModel.familyhistory.other && clinicalSummaryModel.familyhistory.other !== ''">
                        <div class="summary-content-left">Other</div>
                        <div class="summary-content-row-tabs">
                            {{ clinicalSummaryModel.familyhistory.other }}
                        </div>
                        <hr>
                    </div>

                    <div class="summary-content-title col-12 row">
                        <span>Mood & Well-being</span>
                    </div>
                    <hr
                        *ngIf="!clinicalSummaryModel.vaccines.vaccines || clinicalSummaryModel.vaccines.vaccines.length < 1" />
                    <div *ngIf="clinicalSummaryModel.phq9?.phq9 && phq9Date !== ''"
                        class="summary-content-womenhealthhistory">
                        <div class="summary-content-left">Mood Assessment</div>
                        <div style="font-weight: 100" class="summary-content-left">Score: {{ phq9score }} ({{
                            phq9Severity }})</div>
                        <div class="summary-content-row">{{ phq9Date | date: "MM/dd/yyyy" }}
                            <i (click)="onCategorySelection('phq9')" class=" fa fa-external-link-alt assessment"></i>
                        </div>

                        <hr />
                    </div>
                    <div *ngIf="clinicalSummaryModel.gad7?.gad7 && gad7Date !== ''"
                        class="summary-content-womenhealthhistory">
                        <div class="summary-content-left">Stress Assessment</div>
                        <div style="font-weight: 100" class="summary-content-left">Score: {{ gad7Score }} ({{
                            gad7Severity }})</div>
                        <div class="summary-content-row">{{ gad7Date | date: "MM/dd/yyyy" }}
                            <i (click)="onCategorySelection('gad7')" class="fa fa-external-link-alt assessment"></i>
                        </div>

                        <hr />
                    </div>
                    <div *ngIf="clinicalSummaryModel.sleepassessment?.sleepAssessment && sleepDate !== ''"
                        class="summary-content-womenhealthhistory">
                        <div class="summary-content-left">Sleep Assessment</div>
                        <div style="font-weight: 100" class="summary-content-left">Score: {{ sleepScore }} ({{
                            sleepSeverity }})</div>
                        <div class="summary-content-row">{{ sleepDate | date: "MM/dd/yyyy" }}
                            <i (click)="onCategorySelection('sleepassessment')"
                                class="fa fa-external-link-alt assessment"></i>
                        </div>

                        <hr />
                    </div>
                </div>
                <div class="presciption-content col-4 no-padding-margin">
                    <div class="presciption-body">
                        <div class="row mb-2 no-padding-margin">
                            <div class="col-12">
                                <strong>Prescriptions</strong>
                            </div>
                        </div>
                        <div class="presciption-body-content"
                            *ngIf="clinicalSummaryModel.prescriptions.medications?.length">
                            <div class="row no-padding-margin"
                                *ngFor="let m of clinicalSummaryModel.prescriptions.medications; let i = index">
                                <div class="col-9">
                                    <i class="fa fa-capsules app-icon" aria-hidden="true"></i>
                                    {{ m.medicationname }}
                                </div>
                                <div class="col-1">
                                    <i (click)="onEditPrescription(m, i, $event)" class="fa fa-pen graycolor"
                                        aria-hidden="true"></i>
                                </div>
                                <div class="col-1">
                                    <i (click)="onViewPrescription(m, $event)" class="fa fa-search graycolor"
                                        aria-hidden="true"></i>
                                </div>
                                <div class="col-1">
                                    <i (click)="onDeletePrescription(i, $event)" class="fa fa-window-close graycolor"
                                        aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div (click)="onAddPrescription(); $event.stopPropagation()"
                            class="row  add-new no-padding-margin">
                            <div class="col-12">
                                <i class="fas fa-plus-circle graycolor" aria-hidden="true"></i>
                                <span class="add-new-span">Add Prescription</span>
                            </div>
                        </div>
                    </div>
                    <div class="presciption-body">
                        <div class="row mb-2 no-padding-margin">
                            <div class="col-12">
                                <strong>Prescribed Apps</strong>
                            </div>
                        </div>

                        <div class="presciption-body-content" *ngIf="links?.length">
                            <!-- {{ links.suggested.concat(links.global) | json }} -->
                            <div class="row no-padding-margin" *ngFor="let l of links">
                                <div class="col-11">
                                    <i class="fa fa-th app-icon" aria-hidden="true"></i>
                                    {{ l.title }}
                                </div>
                                <div class="col-1">
                                    <i *ngIf="l?.assignmentid" (click)="onDelete(l); $event.stopPropagation()"
                                        class="fa fa-window-close graycolor" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row add-new no-padding-margin">
                            <div *ngIf="sessionService.currentUser.attributes.membership.telerx.telerxtype!='application'"
                                (click)="onAddApp(); $event.stopPropagation()" class="col-12">
                                <i class="fas fa-plus-circle graycolor" aria-hidden="true"></i>
                                <span class="add-new-span">Prescribe App</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>