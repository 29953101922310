<div class="modal-body">
    <div class="category-header">
        <label>Skin</label>
    </div>
    <div class="main-row">
        <div class="row checklist-row">
            <div class="col-lg-6 checklist-columns" *ngFor="let reason of checklist; let i = index">
                <div class="checklist-item">
                    <input
                        class="radio-checklist"
                        type="checkbox"
                        name="reason"
                        id="checkbox_{{ i }}"
                        [(ngModel)]="checklist[i].value"
                    />
                    <label for="checkbox_{{ i }}" class="radio-checkbox-label">{{ reason.name }}</label>
                </div>
            </div>
        </div>
        <div class="others checklist-columns">
            <div class="others-label">
                <label>Other</label>
            </div>
            <div class="others-text">
                <textarea
                    [(ngModel)]="others"
                    type="text"
                    class="others-textarea form-control"
                    placeholder="Please describe"
                >
                </textarea>
            </div>
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
    </div>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSubmit()">Continue</button>
        </div>    
    </div>
</div>