<div class="modal-body">
    <div class="category-header">
        <label>Mood &amp; Well-being</label>
    </div>
    <form class="modal-body-form" [formGroup]="moodForm">
        <div class="form-group">
            <label>Mood <span class="newline">On average, I would describe my mood as:</span></label>
            <select formControlName="mood" [ngClass]="{ error: isFieldValid('mood') }" class="custom-select form-control">
                <option value="">Please Select</option>
                <option value="I feel anxious or sad 1-2 times per month">I feel anxious or sad 1-2 times per month</option>
                <option value="I feel anxious or sad 3-4 times per month">I feel anxious or sad 3-4 times per month</option>
                <option value="I feel anxious or sad 5 or more times per month">I feel anxious or sad 5 or more times per month</option>
                <option value="I feel anxious or sad most days">I feel anxious or sad most days</option>
                <option value="I generally feel happy and content">I generally feel happy and content</option>
            </select>
            <div class="inp-err" *ngIf="moodForm.controls['mood'].errors && moodForm.controls['mood'].touched">
                <span class="error-msg"> Field is . </span>
            </div>
        </div>
        <div class="form-group">
            <label
                >Chronic Illness
                <span class="newline">Tell us about how you cope with chronic illness (Chronic illnesses include:Asthma, COPD, diabetes, emphysema, heart failure & high blood pressure)</span></label
            >
            <select formControlName="chronicIllness" [ngClass]="{ error: isFieldValid('chronicIllness') }" class="custom-select form-control">
                <option value="">Please Select</option>
                <option value="I dont have any chronic illness">I don't have any chronic illness</option>
                <option value="My doctor and I control my chronic illness">My doctor and I control my chronic illness</option>
                <option value="My doctor and I mostly control my chronic illness">My doctor and I mostly control my chronic illness</option>
                <option value="My doctor and I sometimes control my chronic illness">My doctor and I sometimes control my chronic illness</option>
                <option value="My doctor and I seldom control my chronic illness">My doctor and I seldom control my chronic illness</option>
                <option value="I dont have a doctor to help control my chronic illness">I don't have a doctor to help control my chronic illness</option>
            </select>
            <div class="inp-err" *ngIf="moodForm.controls['chronicIllness'].errors && moodForm.controls['chronicIllness'].touched">
                <span class="error-msg"> Field is . </span>
            </div>
        </div>
        <div class="form-group">
            <label>Pain</label>
            <select formControlName="pain" [ngClass]="{ error: isFieldValid('pain') }" class="custom-select form-control">
                <option value="">Please Select</option>
                <option value="I rarely have any pain">I rarely have any pain</option>
                <option value="I sometimes have pain with or after exercise">I sometimes have pain with or after exercise</option>
                <option value="I often have pain with or after exercise">I often have pain with or after exercise</option>
                <option value="I sometimes have pain without exercise">I sometimes have pain without exercise</option>
                <option value="I frequently have pain without exercise">I frequently have pain without exercise</option>
                <option value="I experience pain most of the time">I experience pain most of the time</option>
            </select>
            <div class="inp-err" *ngIf="moodForm.controls['pain'].errors && moodForm.controls['pain'].touched">
                <span class="error-msg"> Field is . </span>
            </div>
        </div>
    </form>
    <p *ngIf="error" class="text-danger">{{ error }}</p>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
    </div>
</div>
