import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TeleRXService } from "../../services/teleRX.service";
import { AppsService } from "../../services/apps.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-apps-modal",
    templateUrl: "./apps-modal-v2.component.html",
    styleUrls: ["./apps-modal-v2.component.scss"],
})
export class AppsModalComponent implements OnInit {
    @Output() readonly Response = new EventEmitter<any>();
    @Input() userId: string = "";
    @Input() membership_subscription = [];
    constructor(
        private appsService: AppsService,
        private activeModal: NgbActiveModal,
        private snackbar: SnackbarService,
        private teleRxService: TeleRXService,
        private sessionService: SessionService
    ) { }
    apps = [];
    userApps = [];
    errorMessage: string = "";
    showLoader = false;
    displayCount = 0;
    appids = [];
    telerxIds = [];
    unSubtelerxIds = [];
    publiclinks = [];
    userMembershipId = "";
    userAppCount = 0;
    ngOnInit(): void {
        this.userMembershipId = this.sessionService.getCurrentUserMembershipId();

        this.getAppsList();
    }
    currentApp: any = {};
    linkTypes;
    public onCancel() {
        this.activeModal.close();
    }
    public getAppsList() {
        this.showLoader = true;
        this.appsService.getApps().subscribe(
            (data) => {
                this.apps = data[0].attributes.links;
                //this.apps.map((o) => (o.subscriptiontype = "self"));
                this.publiclinks = data[0].attributes.publiclinks;
                this.apps.sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) {
                        return -1;
                    }
                    if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });

                if (this.apps.length > 0) {
                    //this.currentApp = this.apps[0];
                    if (this.userId != "") {
                        this.getUserApps();
                    } else {
                        this.showLoader = false;
                    }
                } else {
                    this.showLoader = false;
                }
                this.getapplication(this.apps, "application");
                this.getapplication(this.apps, "link");
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
                this.showLoader = false;
            }
        );
    }

    public getUserApps() {
        this.appsService.getUserApps(this.userId).subscribe(
            (data) => {
                this.userApps = data[0].attributes.suggested || [];
                this.userApps =   this.userApps.filter((el) => el.applicationlinktype != "global");
                this.userApps =   this.userApps.filter((el) =>  el.linktype != "global");
                for (let i = 0; i < this.apps.length; i++) {
                    this.apps[i].ordered = false;
                    for (let j = 0; j < this.userApps.length; j++) {
                        if (this.apps[i].appid === this.userApps[j].appid) {
                            this.apps[i].subscriptiontype = this.userApps[j].subscriptiontype;
                            this.apps[i].ordered = true;
                            this.appids.push(this.apps[i].appid);
                        }
                    }
                }
                this.displayCount = this.apps.filter((el) => !(el.ordered || el.linktype == "global")).length;
                this.showLoader = false;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
                this.showLoader = false;
            }
        );
    }

    public onSelectApp(a) {
        this.currentApp = a;
    }
    public onAssignApp() {
        this.unSubtelerxIds = this.unSubtelerxIds.filter((u) => !this.telerxIds.find((t) => t === u));
        this.appsService.assignLinksToUser(this.appids, this.userId).subscribe(
            (data) => {
                if (this.telerxIds.length == 0 && this.unSubtelerxIds.length == 0) {
                    this.Response.emit({ success: true });
                    this.activeModal.close();
                } else {
                    if (this.telerxIds.length != 0) {
                        this.subscribeTelerx();
                    }
                    if (this.unSubtelerxIds.length != 0) {
                        this.unsubscribeTelerx();
                    }
                }
            },
            (err) => {
                this.Response.emit({ success: false, message: err[0].detail });
            }
        );
    }
    public getapplication(apps, linktype) {
        this.linkTypes = this.findapplication(apps, linktype);
    }
    public selectedApps(appid, event) {
        if (event.target.checked) {
            this.appids.push(appid);
        } else {
            const index: number = this.appids.indexOf(appid);
            if (index !== -1) {
                this.appids.splice(index, 1);
            }
        }
        this.apps.forEach((element) => {
            if (element.appid == appid) element.ordered = event.target.checked;
        });
    }
    public findapplication(apps, linktype) {
        if (this.userId == "") {
            if (linktype == "application") {
                for (let i = 0; i < apps.length; i++) {
                    for (let j = 0; j < this.membership_subscription.length; j++) {
                        if (apps[i].appid === this.membership_subscription[j].appid) {
                            apps[i].subscriptiontype = this.membership_subscription[j].subscriptiontype;
                        }
                    }
                }
                apps = apps.filter((i) => i.linktype === "application"  && i.applicationlinktype === "global");
                return apps.reduce((r, { name, telerxid, ordered, subscriptiontype,applicationlinktype }) => {
                    if (!r.some((o) => o.name == name)) {
                        r.push({ name, telerxid, ordered, subscriptiontype,applicationlinktype, groupItem: this.apps.filter((v) => v.name == name && v.linktype === "application") });
                    }

                    return r;
                }, []);
            } else {
                this.userAppCount = apps.filter((i) => i.linktype !== "application" && i.membershipid == this.userMembershipId).length;
                return apps.filter((i) =>  i.linktype !== "application" && i.linktype !== "private" && i.membershipid == this.userMembershipId);
            }
        } else {
            if (linktype == "application") {
                apps = apps.filter((i) => i.linktype === "application" && i.applicationlinktype === "non-global");
                return apps.reduce((r, { name, telerxid, ordered, subscriptiontype,applicationlinktype }) => {
                    if (!r.some((o) => o.name == name)) {
                        r.push({ name, telerxid, ordered, subscriptiontype,applicationlinktype, groupItem: this.apps.filter((v) => v.name == name && v.linktype === "application" ) });
                    }
                    return r;
                }, []);
            } else {
                this.userAppCount = apps.filter((i) => i.linktype !== "global" || i.linktype !== "application" && i.membershipid == this.userMembershipId).length;
                return apps.filter((i) => i.linktype !== "application" && i.linktype !== "global" && i.membershipid == this.userMembershipId);
            }
        }
    }
    public selectedTelerx(telerxId, event) {
        if (event.target.checked) {
            this.telerxIds.push(telerxId);
        } else {
            const i: number = this.telerxIds.indexOf(telerxId);
            if (i === -1) {
                this.unSubtelerxIds.push(telerxId);
            }
            const index: number = this.telerxIds.indexOf(telerxId);
            if (index !== -1) {
                this.telerxIds.splice(index, 1);
            }
        }
        this.apps.forEach((element) => {
            if (element.telerxid == telerxId) element.ordered = event.target.checked;
        });
    }
    public subscribeTelerx() {
        this.telerxIds.forEach((telerxId) => {
            this.teleRxService.addUserTelerx(this.userId, telerxId).subscribe(
                (data) => {
                    this.Response.emit({ success: true });
                    this.activeModal.close();
                },
                (err) => {
                    this.Response.emit({ success: false, message: err[0].detail });
                }
            );
        });
    }
    public unsubscribeTelerx() {
        this.unSubtelerxIds.forEach((telerxId) => {
            this.teleRxService.removeUserFromTeleRemedy(telerxId, this.userId).subscribe(
                (data) => {
                    this.Response.emit({ success: true });
                    this.activeModal.close();
                },
                (err) => {
                    this.Response.emit({ success: false, message: err[0].detail });
                }
            );
        });
    }
}
