<div class="modal-body">
    <div class="category-header">
        <label>Social History</label>
    </div>
    <form class="modal-body-form" [formGroup]="socialForm">
        <div class="main-row">
            <div class="main-left drugs-sections">
                <div class="form-group">
                    <label>Tobacco Use <span class="newline">One pack equals 20 cigarettes</span></label>
                    <select formControlName="tobaccouse" [ngClass]="{ error: isFieldValid('tobaccouse') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="I have never smoked">I have never smoked</option>
                        <option value="I smoke less than 1 pack each day">I smoke less than 1 pack each day</option>
                        <option value="I smoke 1-2 packs each day">I smoke 1-2 packs each day</option>
                        <option value="I smoke 3-4 packs each day">I smoke 3-4 packs each day</option>
                        <option value="I smoke more than 4 packs each day">I smoke more than 4 packs each day</option>
                        <option value="I smoked but quit more than 5 years ago">I smoked but quit more than 5 years ago</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['tobaccouse'].errors && socialForm.controls['tobaccouse'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Alcohol Use <span class="newline">One drink equals: 12 oz. beer, 6 oz. wine, or 1.5 oz liquor</span></label>
                    <select formControlName="alcoholuse" [ngClass]="{ error: isFieldValid('alcoholuse') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="I never consume alcohol">I never consume alcohol</option>
                        <option value="I consume 1-2 drinks per week">I consume 1-2 drinks per week</option>
                        <option value="I consume 3-5 drinks per week">I consume 3-5 drinks per week</option>
                        <option value="I consume 6-9 drinks per week">I consume 6-9 drinks per week</option>
                        <option value="I consume more than 10 drinks per week">I consume more than 10 drinks per week</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['alcoholuse'].errors && socialForm.controls['alcoholuse'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Caffeine Use</label>
                    <select formControlName="caffeineuse" [ngClass]="{ error: isFieldValid('caffeineuse') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="None">None</option>
                        <option value="1-2 cups/day">1-2 cups/day</option>
                        <option value="2-4 cups/day">2-4 cups/day</option>
                        <option value="4-8 cups/day">4-8 cups/day</option>
                        <option value=">8 cups/day">> 8 cups/day</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['caffeineuse'].errors && socialForm.controls['caffeineuse'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Sexual Activity</label>
                    <select formControlName="sexuallyactive" [ngClass]="{ error: isFieldValid('sexuallyactive') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                        <option value="With Male">With Male</option>
                        <option value="With Female">With Female</option>
                        <option value="With Both">With Both</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['sexuallyactive'].errors && socialForm.controls['sexuallyactive'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Drug Use</label>
                    <div class="druguse-radio">
                        <input type="radio" id="radio_yes" class="druguse-radiobuttons" name="druguse" value="true" (change)="toggleDrugUse(true)" [checked]="druguse === true" /><label for="radio_yes" class="span-name">Yes</label>
                        <input type="radio" id="radio_no" class="druguse-radiobuttons" name="druguse" value="false" (change)="toggleDrugUse(false)" [checked]="druguse === false" /><label for="radio_no" class="span-name">No</label>
                    </div>
                    <div class="others druguse-text">
                        <div [hidden]="!druguse">
                            <textarea formControlName="drugdetails" placeholder="Please describe" class="form-control druguse-textarea" type="text"></textarea>
                            <div class="inp-err" *ngIf="socialForm.controls['drugdetails'].errors && socialForm.controls['drugdetails'].touched">
                                <span class="error-msg"> Field is required. </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <div class="category-header-black">
                    <label class="category-header-black-label">Exercise</label>
                </div>
                <div class="form-group">
                    <label>Frequency</label>
                    <select formControlName="exerciseFrequency" [ngClass]="{ error: isFieldValid('exerciseFrequency') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="Never">Never</option>
                        <option value="1-2 times a week">1-2 times a week</option>
                        <option value="3-5 times a week">3-5 times a week</option>
                        <option value="Daily">Daily</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['exerciseFrequency'].errors && socialForm.controls['exerciseFrequency'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
                <div class="form-group exercise-extra-margins">
                    <label>Intensity</label>
                    <select formControlName="exerciseIntensity" [ngClass]="{ error: isFieldValid('exerciseIntensity') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="None">None</option>
                        <option value="Light">Light</option>
                        <option value="Moderate">Moderate</option>
                        <option value="Active/Aerobic">Active/Aerobic</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['exerciseIntensity'].errors && socialForm.controls['exerciseIntensity'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Duration</label>
                    <select formControlName="exerciseDuration" [ngClass]="{ error: isFieldValid('exerciseDuration') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="None">None</option>
                        <option value="Less than 30 minutes">Less than 30 minutes</option>
                        <option value="30 minutes - 1 hour">30 minutes - 1 hour</option>
                        <option value="Over an hour">Over an hour</option>
                    </select>
                    <div class="inp-err" *ngIf="socialForm.controls['exerciseDuration'].errors && socialForm.controls['exerciseDuration'].touched">
                        <span class="error-msg"> Field is required. </span>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>    
    </div>
</div>
