import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
@Component({
    selector: "filters-modal",
    templateUrl: "./filters-modal.component.html",
    styleUrls: ["./filters-modal.component.scss"],
})
export class FiltersModalComponent implements OnInit {
    constructor(private activeModal: NgbActiveModal, private msxReportService: MSXReportService, private snackbar: SnackbarService) { }
    @Input() survayData: any
    providers = [];
    graphLocations = [];
    graphRoles = [];
    practice = [];
    compare_practice = [];
    @Input() selectedProvider = [];
    @Input() selectedPractice = "-1";
    @Input() practiceName = "";
    selectedPracticeName = "";
    disabledPractices = false;
    disabledProviders = false;
    practiceIdSeriesId = "2EA635AB-A9FD-4A6A-B96A-F557DB026FD8";
    providerSeriesId = "740ED927-15C0-4BA9-B971-CD873FB0AE73";
    metric = JSON.parse(localStorage.getItem('selectedMetric'));
    providerDropdownSettings: any = {};
    roleDropdownSettings: any = {};
    locationDropdownSettings: any = {};
    practiceDropdownSettings: any = {};
    selectedOption = "";
    selected_filters = [];
    selected_practice = [];
    selected_provider = [];
    selected_location = [];
    selected_role = [];
    options = [
        {
            name: "Location",
            value: "location"
        },
        {
            name: "Practice",
            value: "practice"
        },
        {
            name: "Provider",
            value: "provider"
        },
        {
            name: "Role",
            value: "role"
        }];
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    ngOnInit() {

        this.selectedProvider.forEach(element => {
            if (element.type === "location") {
                this.selected_location.push(element);
            }
            if (element.type === "practice") {
                this.selected_practice.push(element);
            }
            if (element.type === "provider") {
                this.selected_provider.push(element);
            }
            if (element.type === "role") {
                this.selected_role.push(element);
            }

        });

        this.providerDropdownSettings = {
            singleSelection: false,
            idField: 'providerid',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true
        };
        this.roleDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true
        };
        this.locationDropdownSettings = {
            singleSelection: false,
            idField: 'name',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true
        };
        this.practiceDropdownSettings = {
            singleSelection: false,
            idField: 'practiceid',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true
        };
        if (this.selectedPractice === "") {
            this.GetEntity();
        }
        else {
            JSON.parse(localStorage.getItem('practice')).forEach(element => {
                if (element.practiceid === this.selectedPractice) {
                    this.practice.push(element);
                }
            });
            this.GetEntity(this.selectedPractice);
        }
    }

    public GetEntity(practiceId = "") {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        this.msxReportService.GetEntity(selectedGroup, practiceId).subscribe(
            (data) => {
                this.graphLocations = data[0].attributes.data.graphLocations;
                this.graphRoles = data[0].attributes.data.graphRoles;
                if (practiceId !== "") {
                    this.providers = data[0].attributes.data.providers;
                    this.compare_practice = [];
                    this.practice.forEach(element => {
                        if (element.practiceid === practiceId) {
                            this.compare_practice.push(element);
                        }
                    });
                }
                else {
                    this.practice = data[0].attributes.data.practices;
                    localStorage.setItem('practice', JSON.stringify(this.practice))
                    this.compare_practice = data[0].attributes.data.practices;
                    this.providers = data[0].attributes.data.providers;
                }
            },
            (err) => {
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    public onSubmit() {
        let parameters = {
            practiceId: this.selectedPractice,
            practiceName: this.selectedPracticeName,
            selected_role: this.selected_role,
            selected_location: this.selected_location,
            selected_practice: this.selected_practice,
            selected_provider: this.selected_provider
        }
        this.Response.emit({
            parameters: parameters
        });
        this.activeModal.close();
    }

    public onCancel() {
        this.activeModal.close();
    }

    public onPracticeChange(event) {
        this.selectedPracticeName = event.target['options']
        [event.target['options'].selectedIndex].text;
        if (this.selectedPractice !== "-1") {
            this.GetEntity(this.selectedPractice);
        }
    }

    onItemSelect(item: any, type) {
        let provider_found = this.selectedProvider.find(y => y.name == item.name);
        if (!provider_found) {
            this.selectedProvider.push(item)
        }
    }
    onItemDeSelect(item: any) {
        this.selectedProvider.forEach((element, index) => {
            if (element.name === item.name) {
                this.selectedProvider.splice(index, 1)
            }
        });
        let provider_found = this.selectedProvider.find(y => y.name == item.name);

    }

    selectedOptions(type = "") {
        this.selectedOption = type;

    }

}
