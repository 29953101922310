import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CampaignService } from '../../../../../app/services/campaign.service';
import { SnackbarService } from '../../../../../app/shared/snackbar/snackbar.service';


@Component({
    selector: "app-duplicate-campaign-modal-modal",
    templateUrl: "./duplicate-campaign-modal.component.html",
    styleUrls: ["./duplicate-campaign-modal.component.scss"],
})
export class DuplicateCampaignModalComponent {
    constructor(private fb: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private campaignService: CampaignService,
        private snackbar: SnackbarService

    ) { }
    submitted = false;
    @Input() campaignId: string = "";
    @Output() readonly Response = new EventEmitter<any>();
    campaignForm = this.fb.group({
        newName: ["", Validators.required],

    });

    get form() {
        return this.campaignForm.controls;
    }
    public onSave() {
        this.submitted = true;
        let campaignForm: any;
        if (this.campaignForm.valid) {
            this.campaignService.getCampaignById(this.campaignId).subscribe(
                (data) => {
                    campaignForm = data[0].attributes;
                    campaignForm.campaignName = this.campaignForm.value.newName;
                    campaignForm.from = campaignForm.campaign_from;
                    campaignForm.reply = campaignForm.reply_email;
                    this.campaignService.save(campaignForm, campaignForm).subscribe(
                        (data) => {
                            this.snackbar.show("Campaign created successfully.", "success");
                            this.Response.emit({ proceed: true });
                            this.activeModal.close();
                        },
                        (err) => {
                            this.snackbar.show(err[0].detail, "danger");
                        }
                    );
                },
                (err) => {
                    //this.showLoader = false;
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }

    }

    public onCancel() {
        this.activeModal.close();
    }
}
