import { Component, OnInit, ElementRef, Renderer2, ViewChild, HostListener, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from "@angular/core";
import { CommunicationService } from "../../services/communications.service";
import { MessagingService } from "../../services/messaging.service";
import { environment } from "../../../environments/environment";
import { SnackbarService } from "../snackbar/snackbar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChatImageModalComponent } from "../../modals/chat-image-modal/chat-image-modal.component";
import { VideoChatModalComponent } from "../../modals/video-chat-modal/video-chat-modal.component";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { SmartHeightDirective } from "../../directives/smart-height.directive";
import { SessionService } from "../../services/session.service";
import { PatientDashboardModalComponent } from "../../modals/patient-dashboard-modal/patient-dashboard-modal.component";
import { SymptomGeneralModalComponent } from "../../modals/symptom-category/symptom-general-modal/symptom-general-modal.component";
import { PatientService } from "../../services/patients.service";
import * as BadWordChecker from "bad-words";
import { IncidentBillingComponent } from "../../modals/incident-billing/incident-billing.component";
import { SearchChatModalComponent } from "../../modals/search-chat-modal/search-chat-modal.component";
import { ViewportScroller } from "@angular/common";
import { ServiceRecoveryModal } from "../../../app/pages/MSX/modals/service-recovery-modal/service-recovery-modal.component";
import { WaitingRoomService } from "../../services/waiting-room.service";

@Component({
    selector: "app-chat-window",
    templateUrl: "./chat-window.component.html",
    styleUrls: ["./chat-window.component.scss"],
})
export class ChatWindowComponent implements OnInit, OnChanges, OnDestroy {
    @Input() patient: any;
    @Input() patients: any = [];
    @Input() showForPatientDashBoard = false;
    @Input() showFilters = true;
    @Input() markAsUnreadButton = false;
    @Output() readonly updateThreads: EventEmitter<any> = new EventEmitter();
    @Output() readonly getTagList: EventEmitter<any> = new EventEmitter();
    public smart_height = "calc(100vh - (#globalAlert + #navbar + #messageFilter + #messageInput + #patientManageInfo + #patientManageTabs + #messageAttachmentContainer))";

    debug: boolean = false;

    userId: string = "";
    user: any = {};

    careTeamId: string;

    contextmenuX = 0;
    contextmenuY = 0;
    currentPatient: any = {};
    channel: string = "";
    tags: any = [];
    non_priorities_tags: any = [];
    contextmenuData: any = [];
    Messages: any = [];
    currentCareTeam: any = [];
    ProviderId: string = "";
    patientId: string = "";
    _message: string = "";
    priority = "";
    priority_search = "";
    menuData = [];
    messageId = "";
    searchKey = "";
    needToReplaceWithTag = "";
    caretPos: number = 0;
    lastMessageDatetimeutc = "";
    newestMessageDatetimeutc = "";
    activeCareTeam: any;
    elChatList: any;
    chat_type: string = "";
    _authorname: string = "";
    currentMousePos;
    attachments: any = [];
    url: any;
    selected_tag: string = "";
    unreadMessageCount: number = 0;
    openContextMenus: any = [];

    showLoading: boolean = false;
    contextmenu: boolean = false;
    loadingMoreMessages: boolean = false;
    loadedAllCurrentPatientMessages: boolean = false;
    showAdvancedSearch: boolean = false;
    noResultFound: boolean = false;
    is_attachment_msg: boolean = false;
    providerOnly: boolean = false;
    isContextOpen: boolean = false;
    showUnreadMessagesAlert: boolean = false;
    showScrollShadow: boolean = false;
    isLoadingMessages: boolean = false;
    showSelectionMessage: boolean = false;
    environment = environment;
    userScrolled = false;
    lastScrollTop = 0;
    private queuedChat: any = {};
    badWordChecker = new BadWordChecker();

    priority_list = [
        {
            tag: "Urgent",
        },
        {
            tag: "High",
        },
        {
            tag: "Moderate",
        },
        {
            tag: "Low",
        },
    ];
    isImageAttachment = true;
    fileName = "";
    showchatWindow = true;
    // Track open subscriptions
    componentDestructionNotifier = new Subject();
    windoHeight = "280px";
    badwords = [];
    searchtext = "";
    searchFound = [];
    current_index = 0;
    selected_message = "";
    showSearch = false;
    public removeEventListener: () => void;

    @ViewChild("messageHistory", { static: false }) messageHistoryElement: ElementRef;
    @ViewChild("messageBox", { static: false }) messageBoxElement: ElementRef;
    @ViewChild(SmartHeightDirective, { static: false }) vc: SmartHeightDirective;
    // vc: any = { render: () => { } }

    @HostListener("document:keydown.escape", ["$event"]) keyup(e) {
        // Close the input context menu
        this.contextmenu = false;
        // Close any open edit tags context menus
        for (let message of this.openContextMenus) {
            message.isContextMenuOpen = false;
        }
        for (let message of this.Messages) {
            message.isDeleteContextMenuOpen = false;
        }
    }

    @HostListener("mouseup", ["$event"]) onClick($event) {
        // Close input context menu if it is open and the click is outside the menu
        if (this.contextmenu) {
            // TODO: replace this with "element.contains(target)" implementation
            if (!$event.toElement.className.includes("dropdown-item") && !$event.toElement.className.includes("ng-tns-c6")) {
                // Close the tags input context menu
                this.contextmenu = false;
                if (this._message.substring(this._message.length - 1) === "#") {
                    this._message = this._message.substring(0, this._message.length - 1);
                }
            }
        }

        if (this.openContextMenus.length > 0) {
            let elements = this.el.nativeElement.querySelectorAll(".contextmenu");
            for (let e of elements) {
                if (!e.contains($event.target)) {
                    for (let message of this.openContextMenus) {
                        message.isContextMenuOpen = false;
                    }
                    this.openContextMenus = [];
                }
            }
        }
    }

    constructor(
        private readonly communication: CommunicationService,
        private messagingService: MessagingService,

        private snackbar: SnackbarService,
        private el: ElementRef,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private patientService: PatientService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private scroller: ViewportScroller,
        private waitingRoomService: WaitingRoomService
    ) { }
    public symptomData: any = { symptom: {}, userAttributes: {} };
    userAttributes: any = {};
    hideProviderOnly = false;
    ngOnInit() {
        if (!this.showFilters) {
            this.windoHeight = "500px";
        }
        this.currentPatient = this.patient;
        this.patientId = this.currentPatient.patientid;

        if (!this.patientId && this.Messages.length === 0) {
            this.showSelectionMessage = true;
        }

        this.activeCareTeam = this.sessionService.activeCareTeam;
        this.debug = this.route.snapshot.queryParamMap.get("debug") === "true" ? true : false;
        // console.log("debug messaging", this.debug);
        if (this.debug) {
            localStorage.setItem("startPolling", "true");
            this.messagingService.isPushMessagingDisabled = true;
        }
        if (!this.debug) {
            localStorage.removeItem("startPolling");
        }
        console.log("push messaging disabled", this.messagingService.isPushMessagingDisabled);

        if (this.activeCareTeam) {
            this.careTeamId = this.activeCareTeam["careteamid"];
            // this.getThreadList();
            this.getTags();
        } else {
            setTimeout(() => {
                this.snackbar.show("Please select a care team.", "danger");
            }, 1000);
            return false;
        }

        let currentUser = this.sessionService.currentUser;
        this.ProviderId = currentUser.id;
        this.currentCareTeam = this.activeCareTeam;

        // For non supported FCM push browser
        if (this.messagingService.isPushMessagingDisabled) {
            // Subscribe to thread updates
            this.messagingService.latestThreadPull.pipe(takeUntil(this.componentDestructionNotifier)).subscribe(
                (threads: any) => {
                    // Load messages and merge new ones
                    if (this.patientId) {
                        this.loadMessages(this.currentPatient, {
                            merge: true,
                            afterdatetime: this.newestMessageDatetimeutc,
                        });
                    }
                },
                (err) => {
                    console.error(err);
                }
            );
        }
        // // Always listen for updates on the "currentMessage" subject
        this.messagingService.currentMessage.pipe(takeUntil(this.componentDestructionNotifier)).subscribe(
            (data) => this.incomingMessageHandler(data),
            (err) => {
                console.error(err);
                // this.snackbar.show(err[0].detail, "danger");
            }
        );

        // Load messages for the current user
        if (this.patientId) {
            this.loadMessages(this.currentPatient);
        }

        // Update unread message count in navbar
        let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_UNREAD_MESSAGE_COUNT", { detail: { reload: true } });
        document.dispatchEvent(updateUnreadMessagesCountEvent);

        // Clear unread messages for current user
        this.messagingService.updateUnreadCounter.next({
            clear: [this.patientId],
        });

        document.addEventListener("FILTER_MESSAGE_BY_TAG", (event: any) => {
            this.channel = event.detail;
            this.scrollDown();
        });
        this.getBadWords();

        //HANDLE PDF LINK CLICK
        this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, "click", (event) => {
            if (event.target instanceof HTMLAnchorElement) {
                // Your custom anchor click event handler
                if (event.target.title !== "link") {
                    const anchor = event.target as HTMLAnchorElement;
                    if (anchor.getAttribute("data-wruid") != null) {
                        this.handlePDFLinkClick(event);
                    }
                }
            }
        });

        document.addEventListener("HIDE_PROVIDER_ONLY", (event: any) => {
            setTimeout(() => {
                this.hideProviderOnly = !this.hideProviderOnly;
            }, 500);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.patient?.previousValue) {
            this.queuedChat[changes.patient.previousValue.patientid] = this._message;
        }
        if (changes.patient && changes.patient.currentValue !== changes.patient.previousValue) {
            if (changes.patient.currentValue && changes.patient.currentValue.patientid) {
                // console.log('Update patient', changes.patient.currentValue);
                this.currentPatient = changes.patient.currentValue;
                this.patientId = this.currentPatient.patientid;
                this.showSelectionMessage = false;
                this.Messages = [];
                this.userScrolled = false;
                this.lastScrollTop = 0;
                if (this.queuedChat[this.patientId]) {
                    this._message = this.queuedChat[this.patientId];
                } else {
                    this._message = "";
                }
                this.loadMessages(this.currentPatient);
                setTimeout(() => this.messageBoxElement.nativeElement.focus(), 500);
            }
        }
    }

    ngOnDestroy() {
        // Notify component is being destroyed
        this.componentDestructionNotifier.next();
        this.componentDestructionNotifier.complete();
    }

    // ngAfterViewChecked() { }

    /**
     * Handle incoming messages from the firebase messaging subscription
     * @param data
     */
    private incomingMessageHandler(data) {
        if (!data) {
            // console.error('received empty message event');
            return false;
        }

        this.chat_type = data.data.type;
        let newMessage = JSON.parse(data.data.data);
        // console.log('type', this.chat_type)
        // console.log(newMessage)

        // Don't process the message if it doesn't belong to the current patient
        if (newMessage.patientid !== this.patientId) {
            return false;
        }

        if (this.chat_type === "videocallaction") {
            newMessage.message = data.body;
            this.loadMessages(this.currentPatient);
            newMessage.authorname = this._authorname;
            let x = document.createEvent("CustomEvent");
            x.initCustomEvent("callActiontype", true, true, newMessage.actiontype);
            document.dispatchEvent(x);
        }

        let patient_avatar, author_avatar;

        this.patients.forEach((element) => {
            if (element.patientid === newMessage.patientid) {
                element.message = newMessage.message === "" ? null : newMessage.message;
                // NOTE: Mitch 2020/07/14 - Commented out as this is handled by a subscription on the chat component
                // if (this.currentPatient.patientid !== element.patientid) {
                //   element.provider_unread_messages_count = (parseInt(element.provider_unread_messages_count) || 0) + 1;
                // }
                element.messagetype = newMessage.messagetype;
                patient_avatar = element.patient_avatar;
                author_avatar = element.author_avatar;
            }
        });

        // Push new message to the message history
        let message = {
            id: newMessage.id,
            careteamid: newMessage.careteamid,
            message: newMessage.message === "" ? null : newMessage.message,
            messagetype: newMessage.messagetype,
            authorid: newMessage.authorid,
            datetimeutc: new Date(),
            patientid: newMessage.patientid,
            status: newMessage.status,
            authorname: newMessage.authorname,
            tags: newMessage.tags,
            provideronly: newMessage.provideronly,
            patient_avatar: newMessage.patient_avatar || this.currentPatient.profilepic,
            author_avatar: newMessage.author_avatar,
            attachments: newMessage.attachments,
            fileExtension: newMessage.fileExtension,
        };

        // Mark message as read, by fetching it from the database
        if (newMessage.authorid === this.patientId) {
            this.communication.markMessagesAsRead(this.patientId).pipe(take(1)).subscribe();
        }

        // Check if message is from provider
        if (newMessage.authorid !== this.patientId) {
            // console.log('MESSAGE FROM PROVIDER')
            this.addMessageUnique(message);
            this.scrollDown(false);
        } else if (
            this.messageHistoryElement &&
            this.messageHistoryElement.nativeElement.scrollHeight === this.messageHistoryElement.nativeElement.scrollTop + this.messageHistoryElement.nativeElement.offsetHeight
        ) {
            // console.log('MESSAGE FROM PATIENT, AT BOTTOM')
            this.addMessageUnique(message);

            this.scrollDown();
        }

        // Check if message history is scrolled up
        else if (this.messageHistoryElement && this.messageHistoryElement.nativeElement.scrollTop !== this.messageHistoryElement.nativeElement.scrollHeight) {
            // console.log('MESSAGE FROM PATIENT, SCROLLED UP')
            this.showUnreadMessagesAlert = true;
            this.unreadMessageCount += 1;
            this.addMessageUnique(message);
        }
    }

    /**
     * Retrieve the tags for the current careteam
     */
    public getTags() {
        this.communication
            .tags(this.careTeamId)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    for (let tag of data[0].attributes) {
                        if (typeof this.tags.find((data) => data.tag === tag.tag) === "undefined") {
                            this.tags.push(tag);
                        }
                        this.non_priorities_tags = [];
                        this.tags.forEach((element) => {
                            // if (element.tag.toLowerCase() !== "low" && element.tag.toLowerCase() !== "high" && element.tag.toLowerCase() !== "urgent" && element.tag.toLowerCase() !== "moderate") {
                            this.non_priorities_tags.push(element);
                            // }
                        });
                        this.contextmenuData = this.non_priorities_tags;
                    }
                    this.getTagList.emit(this.non_priorities_tags);
                },
                (error) => {
                    this.snackbar.show(error[0].detail, "danger");
                }
            );
    }

    public getThreadList() {
        this.communication.getThreadList().subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.patients = data[0].attributes;
                    if (this.patients.length > 0) {
                        if (this.currentPatient.length === 0) {
                            this.currentPatient = this.patients[0];
                            this.loadMessages(this.patients[0]);
                        } else {
                            if (this.currentPatient.patientid) {
                                this.loadMessages(this.currentPatient);
                            }
                        }
                    }
                }
                this.updateFormAnchorBinding();
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    /**
     * Retrieves an array of messages for the current patient
     * @param patient
     * @param beforedatetime
     */
    public loadMessages(patient, { beforedatetime = "", afterdatetime = "", merge = false, forceScroll = false } = {}, search = "") {
        // Do not reset this flag if merging unread messages
        if (!merge) {
            this.loadedAllCurrentPatientMessages = false;
        }
        this._authorname = patient.authorname;
        if (patient.priority === null || patient.priority === undefined) {
            this.priority = "";
        } else {
            this.priority = patient.priority.toUpperCase();
        }

        // Clear unread messages for current user
        if (!merge) {
            this.messagingService.updateUnreadCounter.next({
                clear: [patient.patientid],
            });
        }

        if (!this.Messages.length) {
            this.isLoadingMessages = true;
            this.searchtext = "";
            this.searchFound = [];
            this.current_index = 0;
        }

        this.currentPatient = patient;
        if (this.currentPatient.provider_unread_messages_count !== undefined) {
            this.currentPatient.provider_unread_messages_count = 0;
        }
        this.patientId = patient.patientid;
        this.communication
            .getMessages(patient, "", beforedatetime, afterdatetime, search)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    if (data[0].attributes.length > 0) {
                        // All message history loaded
                        if (data[0].attributes.length < 50) {
                            this.loadedAllCurrentPatientMessages = true;
                        }

                        if (merge) {
                            let results = 0;

                            // Merge new messages into the history window
                            for (let msg of data[0].attributes) {
                                if (this.addMessageUnique(msg)) {
                                    results++;
                                }
                            }

                            if (results > 0) {
                                // console.log('Merged', results, 'new messages')

                                if (this.messageHistoryElement && this.messageHistoryElement.nativeElement.scrollTop !== this.messageHistoryElement.nativeElement.scrollHeight) {
                                    this.showUnreadMessagesAlert = true;
                                    this.unreadMessageCount += 1;
                                }

                                this.sortMessages();

                                // Update unread message count
                                this.unreadMessageCount = results;
                            }
                        } else {
                            // console.log("Setting initial messages array");
                            // Setup initial message load as normal
                            this.Messages = data[0].attributes;
                            this.searchFound = this.Messages.filter((m) => m.searchAvailable === true);
                            this.lastMessageDatetimeutc = this.Messages[0].datetimeutc;
                            this.scrollDown();
                        }

                        this.patients.forEach((element) => {
                            if (element.patientid === patient.patientid) {
                                element.care_team_unread_messages = 0;
                            }
                        });

                        // Set newest message datetimeutc
                        if (this.Messages.length > 0) {
                            // console.log('newest msg', this.Messages[this.Messages.length - 1]);
                            this.newestMessageDatetimeutc = this.Messages[this.Messages.length - 1].datetimeutc;
                        }

                        // Done loading initial messages
                        this.isLoadingMessages = false;
                    } else {
                        if (!merge) {
                            this.Messages = [];
                        }
                        this.isLoadingMessages = false;
                    }
                    this.updateFormAnchorBinding();
                    if (forceScroll) {
                        this.scrollDown();
                    }

                    // Update unread message count in navbar
                    let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_UNREAD_MESSAGE_COUNT", { detail: { reload: true } });
                    document.dispatchEvent(updateUnreadMessagesCountEvent);
                },
                (error) => {
                    console.error(error);
                    this.snackbar.show(error[0].detail, "danger");
                }
            );
    }

    public loadMore(callback = null) {
        if (this.loadedAllCurrentPatientMessages) {
            return false;
        }
        if (callback) {
            this.loadingMoreMessages = true;
        }
        this.communication
            .getMessages(this.currentPatient, "", this.lastMessageDatetimeutc)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    if (data[0].attributes.length > 0) {
                        // NOTE: currently loading 50 messages at a time. Must be updated if this value changes
                        if (data[0].attributes.length < 50) {
                            this.loadedAllCurrentPatientMessages = true;
                        }
                        let newMessages = data[0].attributes;
                        this.lastMessageDatetimeutc = newMessages[0].datetimeutc;
                        newMessages.reverse();
                        newMessages.forEach((msg) => this.Messages.unshift(msg));
                        if (typeof callback === "function") {
                            callback();
                        }
                    } else {
                        this.loadedAllCurrentPatientMessages = true;
                    }
                    this.updateFormAnchorBinding();
                    this.loadingMoreMessages = false;
                },
                (error) => {
                    this.snackbar.show(error[0].detail, "danger");
                    this.loadingMoreMessages = false;
                }
            );
    }

    public smartLoadMore(event) {
        if (event.loadMore) {
            this.loadMore(event.updateScroll);
        }
    }

    /**
     *
     * @param event
     */
    inputMessage(event) {
        if (event.data === "#") {
            this.contextmenu = true;
            this.needToReplaceWithTag = event.data;
        } else if (event.data !== null && event.data.trim() !== "") {
            this.needToReplaceWithTag += event.data;
        }
        if (event.data === null) {
            if (this.needToReplaceWithTag === "#") {
                this.contextmenu = false;
            }
            this.needToReplaceWithTag = this.needToReplaceWithTag.substring(0, this.needToReplaceWithTag.length - 1);
        }
        this.searchKey = this.needToReplaceWithTag.replace("#", "");

        this.contextmenuData = this.non_priorities_tags;
        // this.contextmenuX = this.currentMousePos.clientX;
        // this.contextmenuY = this.currentMousePos.clientY;
    }

    /**
     * Tracks the input caret position on keydown events
     * @param oField
     * @param event
     */
    public getCaretPos(oField, event) {
        if (event.key === "Tab") {
            event.preventDefault();
        }
        // console.log(oField.selectionStart);
        if (oField.selectionStart || oField.selectionStart === "0") {
            if (oField.selectionStart < 250) {
                this.caretPos = oField.selectionStart;
                this.contextmenuX = this.caretPos;
            }
        }
        // if (event.key === "Enter") {
        //     this.postMessage();
        // }
    }

    // Close the menu
    public disableContextMenu() {
        // this.contextmenu = false;
    }

    /**
     * Returns the selected tag from the contextmenu
     * @param tag
     */
    public getSelectedTag(tag) {
        this._message += tag + " ";
        this._message = this._message.replace(this.needToReplaceWithTag, "#" + "");
        this.contextmenu = false;
        this.messageBoxElement.nativeElement.focus();
    }

    /**
     * Scroll to the bottom of the message history window
     */
    public scrollDown(clearUnreadAlert = true, force = false): void {
        // don't scroll down if user has scrolled up
        if (!this.userScrolled || force) {
            setTimeout(() => {
                if (this.messageHistoryElement) {
                    this.messageHistoryElement.nativeElement.scrollTop = this.messageHistoryElement.nativeElement.scrollHeight;
                    if (clearUnreadAlert) {
                        this.clearUnreadMessages();
                    }
                }
            }, 100);
        }
    }

    public updatePriority() {
        this.communication
            .updatePriority(this.patientId, this.priority)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    //this.loadMessages(this.currentPatient);
                },
                (error) => {
                    this._message = "";
                    this.snackbar.show(error[0].detail, "danger");
                }
            );
    }

    public tagsFromMessage() {
        if (this._message.trim().match(/(^|\s)\#([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g) !== null) {
            return this._message
                .trim()
                .match(/(^|\s)\#([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g)
                .map(function (v) {
                    return v.trim().substring(0).replace("#", "");
                });
        } else {
            return "";
        }
    }

    public removeTagsFromMessage() {
        let messageOnly = this._message;
        let tags = this.tagsFromMessage().toString().split(",");
        tags.forEach((element) => {
            messageOnly = messageOnly.replace(element, "").replace("#", "");
        });
        return messageOnly;
    }

    public postMessage() {
        if (this._message.trim() !== "" && this._message !== "sending...") {
            if (this.badWordChecker.isProfane(this._message)) {
                this.snackbar.show("This message contains a word that isn't allowed.", "danger");
                return;
            }

            this.channel = "";
            let _tags = this.tagsFromMessage();
            let _message = this.removeTagsFromMessage();
            this._message = "sending...";
            let currentUser = this.sessionService.currentUser;
            this.ProviderId = currentUser.id;

            this.userScrolled = false;

            // Send the message
            this.communication
                .post(this.currentPatient.careteamid, this.patientId, _message, _tags.toString(), this.attachments, this.providerOnly)
                .pipe(take(1))
                .subscribe(
                    (data) => {
                        this._message = "";
                        this.selected_tag = "";

                        let resetFilter = new CustomEvent("RESET_FILTER", { detail: this.channel });
                        document.dispatchEvent(resetFilter);

                        //for non supported FCM push browser
                        if (this.messagingService.isPushMessagingDisabled) {
                            if (this.updateThreads) {
                                this.updateThreads.emit();
                            }
                            // Update messages with merge
                            this.loadMessages(this.currentPatient, {
                                merge: true,
                                forceScroll: true,
                            });
                        }
                    },
                    (error) => {
                        this._message = "";
                        this.snackbar.show(error[0].detail, "danger");
                    }
                );
        }
    }

    public attachment() {
        let element = document.getElementById("chatfilePicker");
        element.click();
    }

    public handleFileSelect1(evt) {
        let files = evt.target.files;
        let file = files[0];
        if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            if (file.size > 10506200) {
                this.snackbar.show("File size should be less than 10MB.", "danger");
                return false;
            }
            if (file.type.includes("image")) {
                this.isImageAttachment = true;
            } else {
                this.isImageAttachment = false;
                this.fileName = file.name;
            }
            if (files && file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let attachment = {
                        filename: file.name,
                        filedata: reader.result.toString().substr(reader.result.toString().indexOf(",") + 1),
                    };
                    this.url = reader.result;
                    this.is_attachment_msg = true;
                    //  this.showSelectionMessage = true;
                    this.attachments.push(attachment);

                    // Re-render message history window height
                    this.vc.render();
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };
            }
        } else {
            this.snackbar.show("File type not supported.", "danger");
            return false;
        }
    }

    public sendAttachment() {
        this.showLoading = true;
        let currentUser = this.sessionService.currentUser;
        this.ProviderId = currentUser.id;
        let _tags = this.tagsFromMessage();
        let _message = "";

        this.communication
            .post(this.careTeamId, this.patientId, _message, _tags.toString(), this.attachments, this.providerOnly)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    //this.threadList();
                    this._message = "";
                    this.selected_tag = "";
                    this.channel = "";
                    this.showLoading = false;
                    this.is_attachment_msg = false;
                    this.attachments = [];
                    this.selected_tag = "";
                    // this.loadMessages(this.currentPatient);
                    //for non supported FCM push browser
                    if (this.messagingService.isPushMessagingDisabled || Notification.permission === "default" || Notification.permission === "denied") {
                        // NOTE: N/A to single focused chat
                        // this.getThreadList();
                    }
                },
                (error) => {
                    this.showLoading = false;
                    this._message = "";
                    this.snackbar.show(error[0].detail, "danger");
                }
            );
    }

    public removeAttachment() {
        this.is_attachment_msg = false;
        this.showSelectionMessage = false;
        this.attachments = [];
        // Re-render message history window height
        this.vc.render();
    }

    public updateFormAnchorBinding() {
        setTimeout(() => {
            let links = this.el.nativeElement.querySelectorAll("a.form-pdf");
            if (links && links.length > 0) {
                links.forEach((link) => {
                    link.addEventListener("click", (event) => {
                        event.preventDefault();
                        this.showForm(link.href);
                    });
                });
            }
        }, 1000);
    }

    public showForm(link: string) {
        // console.log(link);
        let ind = link.indexOf("://");
        if (ind >= 0) {
            ind += 3;
            let params = link.substr(ind).split("/");
            if (params.length > 1) {
                //Setting value for js file
                localStorage.setItem("formServerUri", environment.forms_server_uri);
                let url = window.location.origin + "/assets/form-pdf/index.html?form=" + params[0] + "&sub=" + params[1];
                window.open(url, "_blank");
                // console.log(url);
                //   this.navCtrl.push(PdfPage, { form: params[0], sub: params[1] });
            }
        }
    }

    public videoChat() {
        const date = new Date();
        const modalRef = this.modalService.open(VideoChatModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.RoomName = this.currentPatient.patientid + "-" + date.getTime();
        this.currentPatient.careteamid = this.activeCareTeam.careteamid;
        modalRef.componentInstance.Identity = this.activeCareTeam.careteamname;
        modalRef.componentInstance.Patient = this.currentPatient;
        modalRef.componentInstance.Response.subscribe(console.log("sa"));
    }

    /**
     * Watch the scroll position of the message history window
     * @param e
     */
    public onMessagesScroll(e) {
        let atBottom = e.target.scrollHeight === e.target.scrollTop + e.target.offsetHeight;
        let atTop = e.target.scrollTop === 0;

        // only set user scrolled flag if up
        if (this.lastScrollTop > e.target.scrollTop) {
            this.userScrolled = true;
        }
        this.lastScrollTop = e.target.scrollTop;

        // Hide scroll shadow at the top of the message history
        if (atTop) {
            this.showScrollShadow = false;
        }

        // Show the shadow when scrolling or at the bottom of chat
        if (!atTop && !this.showScrollShadow) {
            this.showScrollShadow = true;
        }

        // Remove unread messages alert if messages scroll reaches the bottom
        if (this.showUnreadMessagesAlert && atBottom) {
            this.clearUnreadMessages();
        }
    }

    public imageLoaded(evt) {
        this.scrollDown();
    }

    /**
     * Remove the unread messages alert bar
     */
    public clearUnreadMessages() {
        this.showUnreadMessagesAlert = false;
        this.unreadMessageCount = 0;

        // Clear unread messages for current user in tab badge
        this.messagingService.updateUnreadCounter.next({
            clear: [this.patientId],
        });

        // Update unread message count in navbar
        let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_UNREAD_MESSAGE_COUNT", { detail: { reload: true } });
        document.dispatchEvent(updateUnreadMessagesCountEvent);

        this.patients.forEach((element) => {
            if (element.patientid === this.patientId) {
                if (this.currentPatient.patientid !== element.patientid) {
                    element.provider_unread_messages_count = 0;
                }
            }
        });
    }

    // ====
    // Chat Bubble Methods
    // ====

    public showImage(image, fileExtension) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
        modalRef.componentInstance.fileExtension = fileExtension;
        modalRef.componentInstance.Response.subscribe(console.log("sa"));
    }

    public showContextOption(tag) {
        try {
            if (tag !== null) {
                if (tag.indexOf("*") === 0 || tag.toLowerCase() === "provideronly") {
                    return false;
                } else {
                    return true;
                }
            }
        } catch (e) {
            return true;
        }
    }

    // public closeContextMenu(target) {
    //   if (!target) {
    //     this.isContextMenuOpen = false;
    //   } else {
    //     let elements = this.elem.nativeElement.querySelectorAll('.contextmenu');
    //     for (let e of elements) {
    //       if (!e.contains(target)) {
    //         this.isContextMenuOpen = false;
    //       }
    //     }
    //   }
    //   // Unsubscribe from click/escape events
    //   this.closeContextMenuSubscription.unsubscribe();
    // }

    public onContextMenu(message, event) {
        // Prepare and open context menu
        let _tags = [];
        let _selected = false;
        let currentTags = [];
        if (message.tags !== null) {
            currentTags = message.tags.split(",");
        }

        //Show the context menu
        message.isContextMenuOpen = true;

        this.tags.forEach((element) => {
            if (currentTags.length > 0 && currentTags.toString().toLowerCase().includes(element.tag.toLowerCase())) {
                _selected = true;
            } else {
                _selected = false;
            }
            let tag = {
                tag: element.tag,
                selected: _selected,
            };
            _tags.push(tag);
        });

        //Insert custom tag in tag list
        currentTags.forEach((customTag) => {
            let found = false;
            this.tags.forEach((element) => {
                if (customTag.toLowerCase() === element.tag.toLowerCase()) {
                    found = true;
                }
            });
            if (customTag !== "" && customTag.toLowerCase() !== "provideronly" && !found && customTag.indexOf("*") !== 0) {
                let tag = {
                    tag: customTag,
                    selected: true,
                };
                _tags.push(tag);
            }
        });

        this.menuData = _tags;
        this.openContextMenus.push(message);
        setTimeout(() => {
            //  if (this.messageHistoryElement.nativeElement.scrollTop > 9000) {
            this.messageHistoryElement.nativeElement.scrollTop = this.messageHistoryElement.nativeElement.scrollHeight;
            //  }
        }, 100);
    }

    public updateTags(message) {
        let tags = "";
        this.menuData.forEach((element) => {
            if (element.selected) {
                tags += element.tag + ",";
            }
        });
        tags = tags.replace(/,(\s+)?$/, "");
        this.communication
            .updatetag(tags, message.id)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    message.isContextMenuOpen = false;
                    message.tags = tags;
                },
                (error) => this.snackbar.show(error[0].detail, "danger")
            );
    }

    public markAsUnread() {
        if (this.markAsUnreadButton) {
            let updateLeftSectionCountEvent = new CustomEvent("UPDATE_LEFT_SECTION_COUNT", { detail: this.currentPatient });
            document.dispatchEvent(updateLeftSectionCountEvent);
        }
        this.currentPatient.provider_unread_messages_count += 1;
        this.communication.markMessagesAsUnRead(this.currentPatient.careteamid, this.currentPatient.patientid).pipe(take(1)).subscribe();
        let updateUnreadMessagesCountEvent = new CustomEvent("UPDATE_UNREAD_MESSAGE_COUNT", { detail: { reload: true } });
        document.dispatchEvent(updateUnreadMessagesCountEvent);
    }

    public openPatientDashboard() {
        this.currentPatient.userid = this.currentPatient.patientid;
        this.showchatWindow = false;
        const modalRef = this.modalService.open(PatientDashboardModalComponent, { backdrop: "static", windowClass: "custom-class" });
        modalRef.componentInstance.patientId = this.currentPatient.patientid;
        modalRef.componentInstance.patient = this.currentPatient;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.showchatWindow = true;
            this.userScrolled = false;
            this.lastScrollTop = 0;
            this.scrollDown();
        });
    }

    public onAddSymptoms() {
        this.symptomData = {
            action: "new",
            symptoms: {},
            currentIndex: 0,
            userAttributes: this.userAttributes,
            careteamId: this.activeCareTeam.careteamid,
            patientId: this.patientId,
        };
        const modalRef = this.modalService.open(SymptomGeneralModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.SymptomsData = this.symptomData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.snackbar.show(receivedEntry.message);
        });
    }
    public onError() {
        return "../../assets/images/doctor.png";
    }

    private addMessageUnique(msg) {
        if (this.Messages.find((m) => m.id === msg.id) === undefined) {
            this.Messages.push(msg);
            return true;
        }
        return false;
    }

    private sortMessages() {
        // NOTE: Necessary incase the provider sends a messages before polling happens.
        //       Will order the messages properly after new patient messages are pulled.
        // Sort messages by "datetimeutc"
        this.Messages = this.Messages.sort((a, b) => a.datetimeutc - b.datetimeutc);
    }
    public getBadWords() {
        return this.messagingService.getBadWords().subscribe(
            (data) => {
                let list = data[0].attributes.list;
                this.badwords = list.map((a) => a.badword);
                this.badWordChecker.list = this.badwords;
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public handlePDFLinkClick(event: Event) {
        event.preventDefault();
        const anchor = event.target as HTMLAnchorElement;
        // console.log(anchor.getAttribute("data-wruid"));
        let incident = {
            wruid: anchor.getAttribute("data-wruid"),
            invoiceid: anchor.getAttribute("data-invoiceid"),
        };
        let billingData = {
            incident: incident,
            isSentUserMessage: true,
        };
        const modalRef = this.modalService.open(IncidentBillingComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.BillingIncidentData = billingData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.snackbar.show(receivedEntry.message);
        });
    }

    public showSearchBox() {
        const modalRef = this.modalService.open(SearchChatModalComponent, { backdrop: "static" });
        modalRef.componentInstance.patientid = this.patientId;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.searchtext = receivedEntry.searchtext;
                if (this.searchtext.trim() === "") {
                    return;
                }
                this.loadMessages(this.currentPatient, {}, receivedEntry.searchtext);
                this.current_index = 0;
                this.showSearch = true;
                setTimeout(() => {
                    if (this.searchFound.length) {
                        this.selected_message = this.searchFound[0].id;
                        document.getElementById(this.searchFound[0].id).scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "nearest",
                        });
                    }
                }, 2000);
            }
        });
    }
    public scrollToNext() {
        if (this.current_index < this.searchFound.length - 1) {
            this.current_index++;
            let selectedDiv = this.searchFound[this.current_index].id;
            this.selected_message = selectedDiv;
            document.getElementById(selectedDiv).scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        }
    }
    public scrollToPrevious() {
        if (this.current_index != 0) {
            this.current_index--;
            let selectedDiv = this.searchFound[this.current_index].id;
            this.selected_message = selectedDiv;
            document.getElementById(selectedDiv).scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        }
    }
    public hideSearch() {
        this.showSearch = false;
        this.selected_message = "";
        this.searchtext = "";
        this.loadMessages(this.currentPatient, {}, this.searchtext);
    }

    public onDeleteContextMenu(message, event) {
        this.Messages.forEach((element) => {
            element.isDeleteContextMenuOpen = false;
        });
        message.isDeleteContextMenuOpen = true;
    }

    public openServiceRecovery(message) {
        this.showLoading = true;
        let servicerecoveryData;
        return this.waitingRoomService.getServiceRecoveryById(message.additionaldata.surveydata.servicerecoveryid).subscribe(
            (data) => {

                if (data[0].attributes.data.available) {
                    this.showLoading = false;
                    servicerecoveryData = data[0].attributes.data.srdata;
                    let modalRef = this.modalService.open(ServiceRecoveryModal, { size: "lg", windowClass: "modal-medium", backdrop: "static" });
                    modalRef.componentInstance.servicerecoveryData = servicerecoveryData;
                    modalRef.componentInstance.message = message;
                    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                        this.snackbar.show("Service Recovery data is updated successfully.");
                    });
                }
                else {
                    this.showLoading = false;
                    this.snackbar.show("Service Recovery data is not available at the moment. Please try after some time.", "danger");

                }
            },
            (err) => {
                this.showLoading = false;
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
}
