<div class="modal-header">
    <h5 class="modal-title" id="addGoalModalTitle">Clinical Summary</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="panel-content-modal">
        <div class="summary-intro-modal-row">
            <div class="summary-left-column-intro">
                {{ lastName | uppercase }} <br />
                {{ firstName }}
            </div>
            <div class="summary-right-column-intro">
                <span class="summary-right-column-intro-left">SEX:</span> {{ gender ? gender : "N/A" }} <br />
                <span class="summary-right-column-intro-left">AGE:</span> {{ age ? age : "N/A" }}
            </div>
        </div>
        <div class="summary-details">
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Personal Information</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">First Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.firstname }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Middle Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.middlename }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Last Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.lastname }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Maiden Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.maidenname }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Medical Record Number</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.mrn }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Gender</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.gender ?
                            (SummaryData.summary.personalinformation.gender | titlecase) : "" }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Date of Birth</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.dob ?
                            (SummaryData.summary.personalinformation.dob | date: "MM/dd/yyyy") : "" }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Marital Status</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.maritalstatus }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Children</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.children }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Grand Children</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.grandchildren }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Living Arrangement</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.livingarrangement }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Employment</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.personalinformation.employment }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Photo ID</div>
                        <div class="summary-content-right image-full" *ngIf="imageURLPhotoID">
                            <div class="imagePreview">
                                <img (click)="showImage(imageURLPhotoID ? imageURLPhotoID : '../../../assets/images/addimage.png')"
                                    [src]="imageURLPhotoID ? imageURLPhotoID : '../../../assets/images/addimage.png'" />
                            </div>
                        </div>
                        <div class="summary-content-right" *ngIf="!imageURLPhotoID">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Contact Information</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Phone Number 1</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.phone1type }} {{
                            SummaryData.summary.contactinformation.phone1type ? "-" : "" }}
                            {{ SummaryData.summary.contactinformation.phone1 }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Phone Number 2</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.phone2type }} {{
                            SummaryData.summary.contactinformation.phone2type ? "-" : "" }}
                            {{ SummaryData.summary.contactinformation.phone2 }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Phone Number 3</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.phone3type }} {{
                            SummaryData.summary.contactinformation.phone3type ? "-" : "" }}
                            {{ SummaryData.summary.contactinformation.phone3 }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Address 1</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.address1 }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Address 2</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.address2 }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">City</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.city }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">State</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.state }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">ZIP Code</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.contactinformation.zip }}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Emergency Contact</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.emergencycontact.name }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Phone Number</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.emergencycontact.phonenumber1 }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Relation</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.emergencycontact.relation ?
                            (SummaryData.summary.emergencycontact.relation | titlecase) : "" }}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Health Insurance Information</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Cardholder First Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.healthinsuranceinformation.cardholderfirstname }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Cardholder Last Name</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.healthinsuranceinformation.cardholderlastname }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">ID Number</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.healthinsuranceinformation.idnumber }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Group Number</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.healthinsuranceinformation.groupnumber }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Health Coverage Provider</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.healthinsuranceinformation.company ?
                            SummaryData.summary.healthinsuranceinformation.company.payername : "" }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Health Insurance Card: Front</div>
                        <div class="summary-content-right image-full" *ngIf="imageURLFront">
                            <div class="imagePreview">
                                <img (click)="showImage(imageURLFront ? imageURLFront : '../../../assets/images/addimage.png')"
                                    [src]="imageURLFront ? imageURLFront : '../../../assets/images/addimage.png'" />
                            </div>
                        </div>
                        <div class="summary-content-right" *ngIf="!imageURLFront">
                            <span></span>
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Health Insurance Card: Back</div>
                        <div class="summary-content-right image-full" *ngIf="imageURLBack">
                            <div class="imagePreview">
                                <img (click)="showImage(imageURLBack ? imageURLBack : '../../../assets/images/addimage.png')"
                                    [src]="imageURLBack ? imageURLBack : '../../../assets/images/addimage.png'" />
                            </div>
                        </div>
                        <div class="summary-content-right" *ngIf="!imageURLBack">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Past Medical History</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            <span
                                *ngFor="let history of SummaryData.summary.pastmedicalhistory.historyrecords; let i = index">
                                {{ history.name
                                }}<span *ngIf="history.subset && history.subset.length > 0">(<span
                                        *ngFor="let subset of history.subset; let j = index">{{ subset.name }}{{ j + 1
                                        === history.subset.length ? "" : ", " }}</span>)</span><br /></span>
                            <span *ngIf="SummaryData.summary.pastmedicalhistory.other">{{
                                SummaryData.summary.pastmedicalhistory.other }} </span>
                        </div>
                        <!-- <div class="summary-content-right">
                            
                        </div> -->
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Past Surgical History</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            <span
                                *ngFor="let history of SummaryData.summary.pastsurgicalhistory.historyrecords; let i = index">
                                {{ history.name
                                }}<span *ngIf="history.subset && history.subset.length > 0">(<span
                                        *ngFor="let subset of history.subset; let j = index">{{ subset.name }}{{ j + 1
                                        === history.subset.length ? "" : ", " }}</span>)</span><br /></span>
                            <span *ngIf="SummaryData.summary.pastsurgicalhistory.other">{{
                                SummaryData.summary.pastsurgicalhistory.other }} </span>
                        </div>
                        <!-- <div class="summary-content-right">
                            
                        </div> -->
                    </div>
                </div>
            </div>
            <hr />
            <div [hidden]="!(SummaryData.summary.personalinformation.gender === 'female')" class="summary-sections">
                <div class="summary-section-title">
                    <span>Women Health History</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Age at First Period</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.firstperiod }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Days Between Periods</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.periodgap }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Period Duration in Days</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.periodduration }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Flow</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.periodflow }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Do you use birth control?</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.birthcontrol === null ? "" :
                            SummaryData.summary.womenhealthhistory.birthcontrol ? "Yes" : "No" }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.birthcontrol">
                        <div class="summary-content-left">Birth control type</div>
                        <div class="summary-content-right">
                            <span
                                *ngFor="let item of SummaryData.summary.womenhealthhistory.birthcontroltype; let i = index"><span
                                    *ngIf="item.name !== 'Other'">{{ item.name }} <br /></span><span
                                    *ngIf="item.name === 'Other' && SummaryData.summary.womenhealthhistory.otherbirthcontrol">{{
                                    SummaryData.summary.womenhealthhistory.otherbirthcontrol }} <br /></span></span>
                            <!-- <span *ngFor="let item of SummaryData.summary.womenhealthhistory.birthcontroltype; let i = index"><span *ngIf="item.name !== 'Other'">{{ item.name }} <br /></span></span>
                            <span *ngIf="SummaryData.summary.womenhealthhistory.otherbirthcontrol">{{ SummaryData.summary.womenhealthhistory.otherbirthcontrol }}</span> -->
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Have you ever been pregnant?</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.pregnancy === null ? "" :
                            SummaryData.summary.womenhealthhistory.pregnancy ? "Yes" : "No" }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Total Pregnancies</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.totalpregnancies }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Full-term births</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.fulltermbirths }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Premature births</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.premature }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Miscarriages</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.miscarriages }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Abortions</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.abortions }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Vaginal births</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.vaginalbirths }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">C-Section births</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.csections }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.pregnancy">
                        <div class="summary-content-left">Pregnancy Complications</div>
                        <div class="summary-content-right">
                            <span
                                *ngFor="let item of SummaryData.summary.womenhealthhistory.pregnancycomplications; let i = index"><span
                                    *ngIf="item.name !== 'Other'">{{ item.name }} <br /></span><span
                                    *ngIf="item.name === 'Other' && SummaryData.summary.womenhealthhistory.otherpregnancycomplications">{{
                                    SummaryData.summary.womenhealthhistory.otherpregnancycomplications }}
                                    <br /></span></span>
                            <!-- <span *ngFor="let item of SummaryData.summary.womenhealthhistory.pregnancycomplications; let i = index"><span *ngIf="item.name !== 'Other'">{{ item.name }} <br /></span></span>
                            <span *ngIf="SummaryData.summary.womenhealthhistory.otherpregnancycomplications">{{ SummaryData.summary.womenhealthhistory.otherpregnancycomplications }}</span> -->
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Have you reached menopause?</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.menopausal === null ? "" :
                            SummaryData.summary.womenhealthhistory.menopausal ? "Yes" : "No" }}
                        </div>
                    </div>
                    <div class="summary-content-row" [hidden]="!SummaryData.summary.womenhealthhistory.menopausal">
                        <div class="summary-content-left">Age at Onset of Menopause</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.womenhealthhistory.menopauseage }}
                        </div>
                    </div>
                </div>
            </div>
            <hr [hidden]="!(SummaryData.summary.personalinformation.gender === 'female')" />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Medication Allergies</span>
                </div>
                <!-- <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            {{ SummaryData.summary.allergies.medication == null ? "" : SummaryData.summary.allergies.medication ? "Yes" : "No" }}
                        </div>
                    </div>
                </div> -->
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            <span
                                *ngFor="let allergy of SummaryData.summary.medicationallergies.allergies; let i = index">{{
                                allergy.name }} <br /></span>
                            <span *ngIf="SummaryData.summary.medicationallergies.other">{{
                                SummaryData.summary.medicationallergies.other }}</span>
                        </div>
                        <!-- <div class="summary-content-right">
                            
                        </div> -->
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Food Allergies</span>
                </div>
                <!-- <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            {{ SummaryData.summary.allergies.food == null ? "" : SummaryData.summary.allergies.food ? "Yes" : "No" }}
                        </div>
                    </div>
                </div> -->
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            <span *ngFor="let allergy of SummaryData.summary.foodallergies.allergies; let i = index">
                                {{ allergy.name
                                }}<span *ngIf="allergy.subset && allergy.subset.length > 0">(<span
                                        *ngFor="let subset of allergy.subset; let j = index">{{ subset.name }}{{ j + 1
                                        === allergy.subset.length ? "" : ", " }}</span>
                                    <span *ngIf="allergy.name === 'Other' && SummaryData.summary.foodallergies.other">{{
                                        allergy.subset.length > 0 ? ", " : "" }}{{
                                        SummaryData.summary.foodallergies.other }}</span>)</span>
                                <span
                                    *ngIf="allergy.name === 'Other' && allergy.subset.length < 1 && SummaryData.summary.foodallergies.other">({{
                                    SummaryData.summary.foodallergies.other }})</span><br /></span>
                        </div>
                        <!-- <div class="summary-content-right">
                            
                        </div> -->
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Environmental Allergies</span>
                </div>
                <!-- <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            {{ SummaryData.summary.allergies.environmental == null ? "" : SummaryData.summary.allergies.environmental ? "Yes" : "No" }}
                        </div>
                    </div>
                </div> -->
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left full-left">
                            <span
                                *ngFor="let allergy of SummaryData.summary.environmentalallergies.allergies; let i = index">
                                {{ allergy.name
                                }}<span *ngIf="allergy.subset && allergy.subset.length > 0">(<span
                                        *ngFor="let subset of allergy.subset; let j = index">{{ subset.name }}{{ j + 1
                                        === allergy.subset.length ? "" : ", " }}</span><span
                                        *ngIf="allergy.name === 'Other' && SummaryData.summary.environmentalallergies.other">{{
                                        allergy.subset.length > 0 ? ", " : "" }}{{
                                        SummaryData.summary.environmentalallergies.other }}</span>)</span>
                                <span
                                    *ngIf="allergy.name === 'Other' && allergy.subset.length < 1 && SummaryData.summary.environmentalallergies.other">({{
                                    SummaryData.summary.environmentalallergies.other }})</span><br /></span>
                        </div>
                        <!-- <div class="summary-content-right">
                            
                        </div> -->
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Social History</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Tobacco Use</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.tobaccouse }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Alcohol Use (Drinks)</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.alcoholuse }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Drug Use</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.druguse === null ? "" :
                            SummaryData.summary.socialhistory.druguse ? "Yes" : "No" }}
                            {{ SummaryData.summary.socialhistory.druguse ?
                            (SummaryData.summary.socialhistory.drugdetails ? " - " +
                            SummaryData.summary.socialhistory.drugdetails : "") : "" }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Caffeine Use</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.caffeineuse }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Sexual Activity</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.sexuallyactive }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Exercise Frequency</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.exerciseFrequency }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Exercise Intensity</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.exerciseIntensity }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Exercise Duration</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.socialhistory.exerciseDuration }}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <!-- <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Mood & Well-being</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Mood</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.moodWellBeing.mood }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Chronic Illness</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.moodWellBeing.chronicIllness }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Pain</div>
                        <div class="summary-content-right">
                            {{ SummaryData.summary.moodWellBeing.pain }}
                        </div>
                    </div>
                </div>
            </div>
            <hr /> -->
            <!-- <div class="summary-sections">
                <div class="summary-section-title">
                    <span>COVID 19 Vaccination</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row">
                        <div class="summary-content-left">Vaccination Status</div>
                        <div class="summary-content-right">
                            {{
                            SummaryData.summary.covid19vaccination.vaccinationstatus
                            ? SummaryData.summary.covid19vaccination.vaccinationstatus === "full"
                            ? "Full Vaccinated"
                            : SummaryData.summary.covid19vaccination.vaccinationstatus === "partial"
                            ? "Partially Vaccinated"
                            : "Not Vaccinated"
                            : ""
                            }}
                        </div>
                    </div>
                    <div class="summary-content-row">
                        <div class="summary-content-left">Vaccination Card</div>
                        <div class="summary-content-right image-full" *ngIf="imageURLVaccination">
                            <div class="imagePreview">
                                <img (click)="showImage(imageURLVaccination ? imageURLVaccination : '../../../assets/images/addimage.png')"
                                    [src]="imageURLVaccination ? imageURLVaccination : '../../../assets/images/addimage.png'" />
                            </div>
                        </div>
                        <div class="summary-content-right" *ngIf="!imageURLVaccination">
                            <span></span>
                        </div>
                    </div>
                    <div
                        [hidden]="!SummaryData.summary.covid19vaccination.vaccinationstatus || SummaryData.summary.covid19vaccination.vaccinationstatus === 'notyet'">
                        <div class="summary-content-row">
                            <div class="summary-content-left">
                                <b>Dose 1 Details</b>
                            </div>
                            <div class="summary-content-right">
                                <span></span>
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Manufacturer</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose1manufacturer }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Expiration Date</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose1expirationdate ?
                                (SummaryData.summary.covid19vaccination.dose1expirationdate | date: "MM/dd/yyyy") : ""
                                }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Lot Number</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose1lotnumber }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Vaccination Date</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose1date ?
                                (SummaryData.summary.covid19vaccination.dose1date | date: "MM/dd/yyyy") : "" }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Facility</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose1facility }}
                            </div>
                        </div>
                    </div>
                    <div [hidden]="SummaryData.summary.covid19vaccination.vaccinationstatus !== 'full'">
                        <div class="summary-content-row">
                            <div class="summary-content-left">
                                <b>Dose 2 Details</b>
                            </div>
                            <div class="summary-content-right">
                                <span></span>
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Manufacturer</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose2manufacturer }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Expiration Date</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose2expirationdate ?
                                (SummaryData.summary.covid19vaccination.dose2expirationdate | date: "MM/dd/yyyy") : ""
                                }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Lot Number</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose2lotnumber }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Vaccination Date</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose2date ?
                                (SummaryData.summary.covid19vaccination.dose2date | date: "MM/dd/yyyy") : "" }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Facility</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose2facility }}
                            </div>
                        </div>
                    </div>
                    <div [hidden]="SummaryData.summary.covid19vaccination.vaccinationstatus !== 'full'">
                        <div class="summary-content-row">
                            <div class="summary-content-left">
                                <b>Booster Dose</b>
                            </div>
                            <div class="summary-content-right">
                                <span></span>
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Manufacturer</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose3manufacturer }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Expiration Date</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose3expirationdate ?
                                (SummaryData.summary.covid19vaccination.dose3expirationdate | date: "MM/dd/yyyy") : ""
                                }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Lot Number</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose3lotnumber }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Vaccination Date</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose3date ?
                                (SummaryData.summary.covid19vaccination.dose3date | date: "MM/dd/yyyy") : "" }}
                            </div>
                        </div>
                        <div class="summary-content-row">
                            <div class="summary-content-left">Facility</div>
                            <div class="summary-content-right">
                                {{ SummaryData.summary.covid19vaccination.dose3facility }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr /> -->

            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Vaccines</span>
                </div>
                <div class="summary-section-content">
                    <div class="summary-content-row"
                        *ngFor="let item of SummaryData.summary.vaccines.vaccines; let i = index">
                        <div class="summary-content-left">
                            {{ item.name }}
                        </div>
                        <div class="summary-content-right">
                            {{ item.value && item.value.length > 0 ? (item.value.toString() === "Dont Know" ? "Don't
                            Know" : item.value.toString()) : "" }}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-sections">
                <div class="summary-section-title full-width-title">
                    <span>Family History</span>
                </div>
                <div class="summary-section-content full-width-row">
                    <div class="summary-content-row"
                        *ngFor="let item of SummaryData.summary.familyhistory.history; let i = index">
                        <div *ngIf="item.value.length > 0">
                            <div class="summary-content-left-full">
                                {{ item.name }}
                            </div>
                            <div class="summary-content-right-full">
                                <div class="family-history-tab-first" *ngIf="item.value.includes('Father')">Father</div>
                                <div class="family-history-tab-first" *ngIf="!item.value.includes('Father')"></div>
                                <div class="family-history-tab-first" *ngIf="item.value.includes('Mother')">Mother</div>
                                <div class="family-history-tab-first" *ngIf="!item.value.includes('Mother')"></div>
                                <div class="family-history-tab" *ngIf="item.value.includes('Maternal Grandparent')">
                                    Maternal Grandparent</div>
                                <div class="family-history-tab" *ngIf="!item.value.includes('Maternal Grandparent')">
                                </div>
                                <div class="family-history-tab" *ngIf="item.value.includes('Paternal Grandparent')">
                                    Paternal Grandparent</div>
                                <div class="family-history-tab" *ngIf="!item.value.includes('Paternal Grandparent')">
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div class="summary-content-row"
                        *ngIf="SummaryData.summary.familyhistory.other && SummaryData.summary.familyhistory.other !== ''">
                        <div class="summary-content-left-full">Other</div>
                        <div class="summary-content-right-full">
                            {{ SummaryData.summary.familyhistory.other }}
                        </div>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="summary-sections">
                <div class="summary-section-title">
                    <span>Mood & Well-being</span>
                </div>
                <div class="summary-section-content">
                    <!-- <div class="summary-content-row">
                        <div class="summary-content-left">
                            {{ item.name }}
                        </div>
                        <div class="summary-content-right">
                            {{ item.value && item.value.length > 0 ? (item.value.toString() === "Dont Know" ? "Don't Know" : item.value.toString()) : "" }}
                        </div>
                    </div> -->
                    <div *ngIf="SummaryData.summary.phq9?.phq9 && phq9Date!==''"
                        class="summary-content-womenhealthhistory">
                        <div class="summary-content-left"><b>Mood Assessment</b></div>
                        <div class="summary-content-row">Score: {{ phq9score }}</div>
                        <div class="summary-content-row">Date: {{ phq9Date | date: "MM/dd/yyyy" }}</div>
                        <hr />
                    </div>
                    <div *ngIf="SummaryData.summary.gad7?.gad7 && gad7Date!==''"
                        class="summary-content-womenhealthhistory">
                        <div class="summary-content-left"><b>Stress Assessment</b></div>
                        <div class="summary-content-row">Score: {{ gad7Score }}</div>
                        <div class="summary-content-row">Date: {{ gad7Date | date: "MM/dd/yyyy" }}</div>
                        <hr />
                    </div>
                    <div *ngIf="SummaryData.summary.sleepassessment?.sleepAssessment && sleepDate!==''"
                        class="summary-content-womenhealthhistory">
                        <div class="summary-content-left"><b>Sleep Assessment</b></div>
                        <div class="summary-content-row">Score: {{ sleepScore }}</div>
                        <div class="summary-content-row">Date: {{ sleepDate | date: "MM/dd/yyyy" }}</div>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
        <div class="summary-footer">
            <div class="summary-edit-section">
                <button type="button" class="btn btn-primary summary-edit-button" (click)="onEdit()">Edit</button>
            </div>
        </div>
    </div>
</div>