<div class="modal-header">
    <h5 class="modal-title">{{selectedIndex==-1? 'Add':'Update'}} Chatbot Keyword</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="modal-body-form" [formGroup]="scheduleForm" (ngSubmit)="addToSchedule()">
        <div class="row">
            <div class="row col-12">
                <div class="form-group col-9">
                    <label>Keyword (multiple keywords can be separated by a comma)</label>
                </div>
                <div class="form-group col-3">
                    <label style="margin-left:10px">Severity</label>
                </div>
            </div>
            <div class="row col-12 pr-0">
                <div class="form-group col-9">
                    <input type="text" class="form-control" formControlName="keyword"
                        [ngClass]="{ 'is-invalid': submitted && form.keyword.errors }">
                    <div *ngIf="submitted && form.keyword.errors" class="invalid-feedback">
                        <div *ngIf="form.keyword.errors">Keyword is required.</div>
                    </div>
                </div>
                <div class="col-3 pr-0">
                    <select style="height:48px" formControlName="severity" class="form-control">
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- <div class="row col-12">
            <div class="col-3 custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="reply_type" value="all-time" />
                <label class="custom-control-label">
                    All the time
                </label>
            </div>
        </div> -->

        <div class="row">
            <div class="form-group col-12">
                <label>Auto-Reply Message</label>

                <textarea class="form-control" rows="4" formControlName="chatMessage"
                    [ngClass]="{ 'is-invalid': submitted && form.chatMessage.errors }"></textarea>
                <div *ngIf="submitted && form.chatMessage.errors" class="invalid-feedback">
                    <div *ngIf="form.chatMessage.errors">Message is required.</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button (click)="onCancel()" type="button" class="btn btn-secondary form-control">Cancel</button>
            </div>
            <div class="col-6">
                <button type="submit" class="btn btn-primary form-control">Save</button>
            </div>
        </div>
    </form>
</div>