/******************************************************************************
avc-validators.ts 

Author: Jason Hertzog, Akhilesh Shukla

Description: Angular custom validators for use with forms.

Copyright (C) 2015-2020 Aspire Ventures 

This program is copyrighted software: you can redistribute it and/or
modify it under the terms of the Aspire General Community License as
published by Aspire Ventures, either version 1 of the License, or (at 
your option) any later version. 

This program is distributed in the hope that it will be useful, but 
WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.See the 
Aspire General Community License for more details.

You should have received a copy of the Aspire General Community 
License along with this program. If not, see: 
<http://ACP.aspirevc.com/licenses/>.
******************************************************************************/
import { UntypedFormControl, AbstractControl } from "@angular/forms";

export class AvcValidators {
    public ValidateIsTrue(c: UntypedFormControl) {
        return c.value === true
            ? null
            : {
                  validateIsTrue: {
                      valid: false,
                  },
              };
    }

    public ValidateEmail(c: UntypedFormControl) {
        let reg = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        return reg.test(c.value)
            ? null
            : {
                  validateEmail: {
                      valid: false,
                  },
              };
    }

    public ValidateNumber(c: UntypedFormControl) {
        return !isNaN(Number(c.value))
            ? null
            : {
                  validateNumber: {
                      valid: false,
                  },
              };
    }

    public ValidateWhiteSpace(c: UntypedFormControl) {
        return c.value && c.value.trim().length > 0
            ? null
            : {
                  ValidateWhiteSpace: {
                      valid: false,
                  },
              };
    }

    public MatchPassword(AC: AbstractControl) {
        let password = AC.get("pwd").value; // to get value in input tag
        let confirmPassword = AC.get("confirmPwd").value; // to get value in input tag
        if (password !== confirmPassword) {
            AC.get("confirmPwd").setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }

    public ValidatePassword(c: UntypedFormControl) {
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*_`?&\-=|#+;~^></\\(){}\[\]])[A-Za-z\d$@$!%*_`?&\-=|#+;~^&gt;&lt;/\\(){}\[\]]{8,}/;
        return reg.test(c.value)
            ? null
            : {
                  invalidPassword: true,
              };
    }
}
