import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, AfterViewInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientDashboardComponent } from "../../pages/patient-dashboard/patient-dashboard.component";

@Component({
    selector: "patient-dashboard-modal",
    templateUrl: "./patient-dashboard-modal.component.html",
    styleUrls: ["./patient-dashboard-modal.component.scss"],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None, // this is needed because of the custom modal
})
export class PatientDashboardModalComponent implements AfterViewInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() patientId: any;
    @Input() patient: any;
    @Input() wrId: any;
    @Input() wruId: any;
    @ViewChild(PatientDashboardComponent) pd: PatientDashboardComponent;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    modalTitle = "Create Member";
    saveBtnText = "Create";

    errorMessage = "";

    ngAfterViewInit() {
        this.pd.Response.subscribe((el) => {
            this.Response.emit(el);
        });
    }
}
