import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { AvcValidators } from "../../providers/avc-validators";
import { SessionService } from "../../services/session.service";
import { IncidentBillingComponent } from "../incident-billing/incident-billing.component";
import { CommunicationService } from "../../services/communications.service";
import { take } from "rxjs/operators";

@Component({
    selector: "incident-modal",
    templateUrl: "./incident-modal.component.html",
    styleUrls: ["./incident-modal.component.scss"],
})
export class IncidentModalComponent implements OnInit {
    constructor(
        private readonly communication: CommunicationService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        private sessionService: SessionService,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private avcValidators: AvcValidators
    ) { }

    @Input() IncidentData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public billingIncidentData: any = {};
    searchICD = "keywords";
    searchCPT = "keywords";
    other = "";
    header = "Add";
    icdkeyword = "";
    selectedICDkeyword = null;
    selectedICDRow = {};
    icdButtonText = "Create";
    cptkeyword = "";
    ICDList: any = [];
    CPTList: any = [];
    selectedCPTList: any = [];
    selectedICDList: any = [];
    error = "";
    errorGender = "";
    isClosed = false;
    isSentUserMessage = false;
    public disableAddtoTriage = false;
    public disableSaveOnSubmit = false;
    public disableTriageOnSubmit = false;
    public disableSendToMember = false;
    @ViewChild("auto") auto;
    @ViewChild("auto") autocpt;
    teleRx: any;
    ngOnInit() {
        this.teleRx = this.sessionService.currentUser?.attributes.membership.telerx;
        if (this.IncidentData.incident && this.IncidentData.incident.status == "closed") {
            this.isClosed = true;
        }
        if (this.IncidentData.incident && this.IncidentData.incident.sentUserMessage) {
            this.isSentUserMessage = true;
        }
        this.getICDCodes();
        this.getCPTCodes();
        if (!this.IncidentData.gender) {
            this.errorGender = "Please select gender of member before saving incident.";
        }
        if (this.IncidentData.action == "edit") {
            this.header = "Edit";
            this.other = this.IncidentData.incident.other;
        }
        if (this.IncidentData.action === "new" || (this.IncidentData.incident.requesttype === "symptoms" && !this.IncidentData.incident.requestcreated)) {
            this.disableAddtoTriage = false;
        } else {
            this.disableAddtoTriage = true;
        }
    }
    customFilter = function (icds: any[], query: string): any[] {
        if (query != null && query.length > 0) {
            return icds.filter((x) => x.keywords.toLowerCase().includes(query.toLowerCase()));
        }
        return [];
    };
    customFilterCPT = function (cpt: any[], query: string): any[] {
        if (query != null && query.length > 0) {
            return cpt.filter((x) => x.keywords.toLowerCase().includes(query.toLowerCase()));
        }
        return [];
    };
    public onCancel() {
        this.Response.emit({ success: true, message: "" });
        this.activeModal.close();
    }
    public getCodeDetails(item) {
        return "<b>" + (item.code ? item.code : "") + "</b> <i>(" + item.description + ")</i>";
    }
    public onChangeSearch(val: string) {
        // if (val === "") {
        //     this.ICDList = [];
        // }
    }
    public onSelectCode(evt, autocomplete) {
        let alreadyExist = null;
        if (evt.icdid) {
            alreadyExist = this.selectedICDList.filter((x) => x.icdid == evt.icdid);
        } else if (evt.smtpid) {
            alreadyExist = this.selectedICDList.filter((x) => x.smtpid == evt.smtpid);
        }

        if (!alreadyExist || alreadyExist.length == 0) {
            let icd = {
                icdcode: evt.code,
                generalsymptoms: evt.description,
                category: "",
                icdid: evt.icdid,
            };
            this.selectedICDList.push(icd);
        }
        this.icdkeyword = "";
        autocomplete.clear();
    }
    public clickICD() {
        this.icdkeyword = "";
        //this.auto.clear();
    }
    public clickCPT() {
        this.cptkeyword = "";
        //this.autocpt.clear();
    }
    public getICDCodes() {
        // console.log(this.IncidentData.incident)
        this.ICDList = this.IncidentData.ICDList;
        if (this.IncidentData.action == "edit") {
            this.IncidentData.incident.categories.forEach((category) => {
                category.value.reasons.forEach((reason) => {
                    if (!reason.category) {
                        reason.category = category.key;
                    }
                    if (!reason.specificbodyparts) {
                        reason.specificbodyparts = "";
                    }
                    this.selectedICDList.push(reason);
                });
            });
        }
    }

    public getCPTDetails(item) {
        return "<b>" + item.code + "</b> <i>(" + item.exam + ")</i>";
    }
    public onChangeSearchCPT(val: string) {
        //  if (val === "") {
        //      this.ICDList = [];
        //  }
    }
    public onSelectCPTCode(evt, autocomplete) {
        let alreadyExist = this.selectedCPTList.filter((x) => x.cptid == evt.cptid);
        if (!alreadyExist || alreadyExist.length == 0) {
            this.selectedCPTList.push(evt);
        }
        this.cptkeyword = "";
        autocomplete.clear();
    }

    public getCPTCodes() {
        this.CPTList = this.IncidentData.CPTList;
        if (this.IncidentData.action == "edit") {
            if (this.IncidentData.incident.cpt) {
                this.IncidentData.incident.cpt.forEach((cpt) => {
                    let cptItem = this.CPTList.filter((x) => x.cptid == cpt.cptid);
                    if (cptItem && cptItem.length > 0) {
                        this.selectedCPTList.push(cptItem[0]);
                    }
                });
            }
        }
    }

    public onDeleteICD(item, evt) {
        if (item.icdid) {
            this.selectedICDList = this.selectedICDList.filter((x) => x.icdid != item.icdid);
        } else if (item.smtpid) {
            this.selectedICDList = this.selectedICDList.filter((x) => x.smtpid != item.smtpid);
        }
    }

    public onDeleteCPT(item, evt) {
        this.selectedCPTList = this.selectedCPTList.filter((x) => x.cptid != item.cptid);
    }
    public onSave(isClose = false, closeModal = true, doneCB = undefined) {
        if (this.IncidentData.incident && this.IncidentData.incident.status == "closed") {
            doneCB();
            return;
        }
        if (this.selectedICDList == 0) {
            this.error = "Please choose at least one ICD10 Code.";
            return;
        }
        if (!this.IncidentData.gender) {
            this.errorGender = "Please select gender of member before saving incident.";
            return;
        }
        this.disableSaveOnSubmit = true;
        let requestdata = this.prepareSymptoms();
        let model = {
            userid: this.IncidentData.patientId,
            careteamid: this.IncidentData.careteamId,
            requestdata: requestdata,
            requestcreated: false,
            status: "active",
        };
        if (isClose) {
            model["status"] = "closed";
        }
        if (this.isSentUserMessage) {
            model.requestdata["sentUserMessage"] = true;
        }
        let type = "put";
        let message = "Incident Added Successfully";
        if (this.IncidentData.action == "edit") {
            message = "Incident Updated Successfully";
            model["wruid"] = this.IncidentData.incident.wruid;
        }
        return this.patientService.submitSymptoms(model, type).subscribe(
            (data) => {
                this.disableSaveOnSubmit = false;
                this.Response.emit({ success: true, message: message });
                if (closeModal) {
                    this.activeModal.close();
                }
                if (doneCB) {
                    doneCB();
                }
            },
            (err) => {
                this.disableSaveOnSubmit = false;
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    onAddToTriage() {
        if (this.selectedICDList == 0) {
            this.error = "Please choose at least one ICD10 Code.";
            return;
        }
        if (!this.IncidentData.gender) {
            this.errorGender = "Please select gender of member before saving incident.";
            return;
        }
        this.disableAddtoTriage = true;
        this.disableTriageOnSubmit = true;
        let requestdata = this.prepareSymptoms();
        let message = "Incident Added Successfully";
        if (this.IncidentData.action === "new") {
            let model = {
                userid: this.IncidentData.patientId,
                careteamid: this.IncidentData.careteamId,
                requestdata: requestdata,
                requesttype: "appointment",
            };
            return this.patientService.submitSymptoms(model, "post").subscribe(
                (data) => {
                    this.disableTriageOnSubmit = false;
                    this.Response.emit({ success: true, message: message });
                    this.activeModal.close();
                },
                (err) => {
                    this.disableTriageOnSubmit = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            message = "Incident Updated Successfully";
            let model = {
                userid: this.IncidentData.patientId,
                careteamid: this.IncidentData.careteamId,
                requestdata: requestdata,
                requesttype: "appointment",
            };
            return this.patientService.submitSymptoms(model, "post").subscribe(
                (data) => {
                    let modelPut = {
                        userid: this.IncidentData.patientId,
                        careteamid: this.IncidentData.careteamId,
                        wruid: this.IncidentData.incident.wruid,
                        requestdata: requestdata,
                        requestcreated: true,
                        status: "transferred",
                    };
                    return this.patientService.submitSymptoms(modelPut, "put").subscribe(
                        (data) => {
                            this.disableTriageOnSubmit = false;
                            this.Response.emit({ success: true, message: message });
                            this.activeModal.close();
                        },
                        (err) => {
                            this.disableTriageOnSubmit = false;
                            this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                        }
                    );
                },
                (err) => {
                    this.disableTriageOnSubmit = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        }
    }

    public prepareSymptoms() {
        let requestdata = {};
        ///Symptoms
        let icdCodes = this.selectedICDList;
        let symptoms = {};
        icdCodes.forEach((item) => {
            let key = "";
            if (item.category) {
                // let bodyType = item.category.toLowerCase().includes("front") ? "front" : "back";
                key = item.category;
            }
            if (symptoms[key] && symptoms[key].reasons.length > 0) {
                symptoms[key].reasons.push({
                    generalsymptoms: item.generalsymptoms,
                    icdcode: item.icdcode,
                    icdcodedescription: item.icdcodedescription,
                    smtpid: item.smtpid,
                    icdid: item.icdid,
                });
            } else {
                symptoms[key] = {
                    reasons: [
                        {
                            generalsymptoms: item.generalsymptoms,
                            icdcode: item.icdcode,
                            icdcodedescription: item.icdcodedescription,
                            smtpid: item.smtpid,
                            icdid: item.icdid,
                        },
                    ],
                };
                if (this.IncidentData.incident) {
                    let category = this.IncidentData.incident.categories.filter((x) => x.key.toLowerCase() == key.toLowerCase());
                    if (category && category.length > 0) {
                        if (category[0].value.other) {
                            symptoms[key].other = category[0].value.other;
                        }
                        if (category[0].value.duration) {
                            symptoms[key].duration = category[0].value.duration;
                        }
                    }
                }
            }
        });

        let cptCodes = this.selectedCPTList;
        let cpt = [];
        cptCodes.forEach((item) => {
            let providerId = this.sessionService.currentUser.id;
            let firstName = this.sessionService.currentUserSettings.firstname;
            let lastName = this.sessionService.currentUserSettings.lastname;
            let date = new Date();
            if (this.IncidentData.incident && this.IncidentData.incident.cpt && this.IncidentData.incident.cpt.length > 0) {
                let existingcpt = this.IncidentData.incident.cpt.filter((x) => x.cptid.toLowerCase() == item.cptid.toLowerCase());
                if (existingcpt && existingcpt.length > 0) {
                    if (existingcpt[0].cptCodeSelectorProviderId) {
                        providerId = existingcpt[0].cptCodeSelectorProviderId;
                    }
                    if (existingcpt[0].providerFirstName) {
                        firstName = existingcpt[0].providerFirstName;
                    }
                    if (existingcpt[0].providerFirstName) {
                        lastName = existingcpt[0].providerLastName;
                    }
                    if (existingcpt[0].date) {
                        date = existingcpt[0].date;
                    }
                }
            }

            cpt.push({
                code: item.code,
                cptid: item.cptid,
                exam: item.exam,
                history: item.history,
                medicaldecision: item.medicaldecision,
                cashrate: item.cashrate,
                cptCodeSelectorProviderId: providerId,
                providerFirstName: firstName,
                providerLastName: lastName,
                date: date,
            });
        });

        requestdata["symptoms"] = symptoms;
        requestdata["cpt"] = cpt;
        requestdata["other"] = this.other;
        return requestdata;
    }

    public viewSuperBill() {
        this.onSave(undefined, false, () => {
            if (!this.IncidentData.incident) {
                return;
            }
            this.billingIncidentData = {
                incident: this.IncidentData.incident,
                isSentUserMessage: this.isSentUserMessage,
            };
            const modalRef = this.modalService.open(IncidentBillingComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
            modalRef.componentInstance.BillingIncidentData = this.billingIncidentData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.snackbar.show(receivedEntry.message);
            });
        });
    }
    public sendToMember() {
        this.onSave(undefined, false, () => {
            if (!this.IncidentData.incident) {
                return;
            }
            this.disableSendToMember = true;
            return this.patientService.incidentBilling(this.IncidentData.incident.wruid, true).subscribe(
                (data) => {
                    let invoice = data[0].attributes;
                    let link =
                        '<a style="cursor:pointer;color:#0A529E; font-weight:bold;" data-wruid="' +
                        this.IncidentData.incident.wruid +
                        '" data-userid="' +
                        this.IncidentData.incident.userid +
                        '" data-invoiceid="' +
                        invoice.invoiceid +
                        '" (click)="openPDF()">here</a>';
                    let _message = "Your bill for services can be viewed " + link;
                    let model = {
                        wruid: this.IncidentData.incident.wruid,
                        userid: this.IncidentData.incident.userid,
                        invoiceid: invoice.invoiceid,
                    };
                    // Send the message
                    this.communication
                        .sendPDFLinkToPatient(this.IncidentData.careteamId, this.IncidentData.patientId, _message, "", {}, false, model)
                        .pipe(take(1))
                        .subscribe(
                            (data) => {
                                this.disableSendToMember = false;
                                this.isSentUserMessage = true;
                                this.snackbar.show("Bill sent to member");
                            },
                            (error) => {
                                this.disableSendToMember = false;
                                this.snackbar.show(error[0].detail, "danger");
                            }
                        );
                },
                (err) => {
                    this.disableSendToMember = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        });
    }

    public onCloseIncident() {
        this.onSave(true);
    }
}
