import { Component, OnInit } from '@angular/core';
import { MSXReportService } from '../../../services/msx_report.service';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import * as _ from 'underscore';
import { SessionService } from '../../../services/session.service';
import * as __ from 'lodash';
@Component({
  selector: 'app-patient-experience',
  templateUrl: './patient-experience.component.html',
  styleUrls: ['./patient-experience.component.scss']
})
export class PatientExperienceComponent implements OnInit {
  graphId = "";
  graphs = [];
  surveys = {
    metricGroups: [],
    metrics: []
  };
  surveys_metrics = [];
  metricList: any;
  selectedSurvey = "B153A8F4-96A2-46FE-9228-61BC627A33FA";

  metricsGroup = [];



  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    public sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.msxReportService.getMetricsList().subscribe(
      (data) => {
        this.surveys.metricGroups = data[0].attributes.metricGroups;
        this.surveys.metrics = data[0].attributes.metrics;
        this.metricList = this.surveys.metrics;
        const coreSurvey = _.findWhere(this.surveys.metricGroups, { surveyname: 'Core' })
        if (coreSurvey === undefined) {
          this.selectedSurvey = this.surveys.metricGroups[0].surveyid;
        }
        else {
          this.selectedSurvey = coreSurvey.surveyid;
        }
        this.getSurvey();

      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );

    let favorite_graphs = this.sessionService.favoritesGraph || []
    let _selectedGroup = localStorage.getItem("selectedGroup") || ""
    favorite_graphs = _.filter(favorite_graphs, {
      selectedGroup
        : _selectedGroup
    })
    if (favorite_graphs != undefined && favorite_graphs.length) {
      favorite_graphs.forEach(function (element) {
        element.isFavorite = true;
      });
      this.graphs = favorite_graphs;
    }
  }

  selectedGraph() {
    if (_.where(this.graphs, { metricid: this.graphId }).length === 0) {
      let metric = _.where(this.surveys.metrics, { metricid: this.graphId })
      metric.map((m) => {
        m.name = m.metricname
      });
      metric[0].isFavorite = false
      this.graphs.push(metric[0])
    }
  }
  public getSurvey() {
    let metric = _.filter(this.metricList, {
      surveyid: this.selectedSurvey
    });
    this.surveys.metrics = [];
    metric[0].list.forEach(element => {
      element.metrics.forEach(m => {
        this.surveys.metrics.push(m);
      });
    });
    this.metricsGroup = [];
    this.surveys.metrics.forEach(element => {
      this.metricsGroup.push(
        {
          surveyid: element.surveyid,
          metricid: element.metricid,
          metricname: element.metricname,
          metricgroupid: element.metricgroupid,
          metricgroupname: element.metricgroupname
        }
      )
    });

    this.metricsGroup = __.orderBy(this.metricsGroup, 'metricname', 'asc')


  }
}
