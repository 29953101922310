export class PersonalInformation {
    constructor(
        public firstname: string,
        public middlename: string,
        public lastname: string,
        public maidenname: string,
        public mrn: string,
        public gender: string,
        public dob: string,
        public maritalstatus: string,
        public children: string,
        public grandchildren: string,
        public livingarrangement: string,
        public employment: string,
        public photoid: string
    ) {}
}

export class ContactInformation {
    constructor(
        public phone1: string,
        public phone1type: string,
        public phone2: string,
        public phone2type: string,
        public phone3: string,
        public phone3type: string,
        public address1: string,
        public address2: string,
        public city: string,
        public state: string,
        public zip: string
    ) {}
}
export class EmergencyContact {
    constructor(public name: string, public phonenumber1: string, public relation: string) {}
}
export class HealthInsuranceInformation {
    constructor(
        public cardholderfirstname: string,
        public cardholderlastname: string,
        public idnumber: string,
        public groupnumber: string,
        public companyname: string,
        public company: any,
        public imagefront: string,
        public imageback: string
    ) {}
}
export class PastMedicalHistory {
    constructor(public other: string, public historyrecords: NameValueSubset[]) {}
}
export class PastSurgicalHistory {
    constructor(public other: string, public historyrecords: NameValueSubset[]) {}
}
export class WomenHealthHistory {
    constructor(
        public firstperiod: string,
        public periodgap: string,
        public periodduration: string,
        public periodflow: string,
        public totalpregnancies: string,
        public fulltermbirths: string,
        public miscarriages: string,
        public abortions: string,
        public vaginalbirths: string,
        public csections: string,
        public premature: string,
        public menopauseage: string,
        public menopausal: string,
        public birthcontrol: boolean,
        public pregnancy: boolean,
        public birthcontroltype: NameValue[],
        public pregnancycomplications: NameValue[],
        public otherbirthcontrol: string,
        public otherpregnancycomplications: string
    ) {}
}
export class MedicationAllergies {
    constructor(public other: string, public allergies: NameValue[]) {}
}
export class FoodAllergies {
    constructor(public other: string, public allergies: NameValueSubset[]) {}
}
export class EnvironmentalAllergies {
    constructor(public other: string, public allergies: NameValueSubset[]) {}
}
export class SocialHistory {
    constructor(
        public tobaccouse: string,
        public alcoholuse: string,
        public druguse: boolean,
        public drugdetails: string,
        public caffeineuse: string,
        public exerciseFrequency: string,
        public exerciseIntensity: string,
        public exerciseDuration: string,
        public sexuallyactive: string
    ) {}
}
// export class Allergies {
//     constructor(public medication: boolean, public food: boolean, public environmental: boolean) {}
// }
export class MoodWellBeing {
    constructor(public mood: string, public chronicIllness: string, public pain: string) {}
}
export class Vaccines {
    constructor(public vaccines: NameValue[]) {}
}
export class FamilyHistory {
    constructor(public history: NameValueArray[], public other: boolean) {}
}
export class Prescriptions {
    constructor(public medications: Medications[]) {}
}
export class Medications {
    constructor(
        public medicationname: string,
        public dosage: string,
        public frequency: string,
        public image: string,
        public notes: string,
        public prescribedby: string,
        public prescribedon: Date,
        public addedby: string,
        public addedon: Date,
        public renewedby: string,
        public renewedon: Date
    ) {}
}

export class NameValueSubset {
    constructor(public name: string, public value: string, public subset: NameValue[]) {}
}
export class NameValue {
    constructor(public name: string, public value: string) {}
}
export class NameValueArray {
    constructor(public name: string, public value: string[]) {}
}
export class Covid19Vaccination {
    constructor(
        public vaccinationstatus: string,
        public vaccinationcard: string,
        public dose1manufacturer: string,
        public dose1expirationdate: string,
        public dose1lotnumber: string,
        public dose1date: string,
        public dose1facility: string,
        public dose2manufacturer: string,
        public dose2expirationdate: string,
        public dose2lotnumber: string,
        public dose2date: string,
        public dose2facility: string,
        public dose3manufacturer: string,
        public dose3expirationdate: string,
        public dose3lotnumber: string,
        public dose3date: string,
        public dose3facility: string
    ) {}
}
