<div class="container-fluid">
    <div class="filter-section">
        <div class="filter-section-left">
            <i (click)="reload()" style="cursor: pointer; font-size: 20px" class="fa fa-redo-alt left-chevron"></i
            ><input class="filter-input" name="searchTerm" [(ngModel)]="tableService.searchTerm" placeholder="Search..." />
        </div>
        <div class="filter-section-right">
            <button (click)="savePractice('add', null)" class="spacing float-right btn-primary btn"><i class="fas fa-plus-circle"></i>&nbsp;Add New</button>
        </div>
    </div>

    <table class="table table-striped datatable">
        <thead>
            <tr>
                <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns" sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let l of resultList$ | async">
                <td>{{ l.practicename }}</td>
                <td>{{ l.address1 }}</td>
                <td>{{ l.city }}</td>
                <td>{{ l.state }}</td>
                <!-- <td>{{ l.country }}</td> -->
                <td>{{ l.postalcode }}</td>
                <td>{{ l.phonenumber }}</td>
                <td><i *ngIf="l.isdefault" class="fas fa-check"></i></td>
                <td>
                    <i class="fa fa-edit mr-3 cursor-pointer" (click)="savePractice('edit', l)" title="Edit Location"></i>
                    <i class="fa fa-user-edit mr-3 cursor-pointer" (click)="manageProviders(l)" title="Manage Providers"></i>
                    <i class="fa fa-trash mr-3 cursor-pointer" (click)="onDelete(l)" title="Remove Location"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-between p-2">
        <ngb-pagination [collectionSize]="List.length" [pageSize]="pageSize" [(page)]="pageIndex" [maxSize]="5" [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>
        <select class="custom-select pagination" style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important" [(ngModel)]="pageSize" (ngModelChange)="setPageSize()">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
        </select>
    </div>
</div>
