import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class AutoReplyService {
    private handler = `/telerx/autoreply`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) { }

    public addSchedule(configuration): Observable<any> {
        let data = JSON.stringify({
            autoreply: configuration,
        });
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/" + telerxid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

}
