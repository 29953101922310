<div class="modal-header text-white">
    Office Notes
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div style="text-align: center" class="modal-body">
    <textarea class="form-control" placeholder="New note" id="notes" [(ngModel)]="note"></textarea>
    <div class="addnote">
        <button (click)="savePatientNote()" [disabled]="note.length === 0" class="btn btn-primary">Save</button>
    </div>
    <div class="notestext">
        <div *ngIf="shownote">
            <div class="note" *ngFor="let note of notes">
                <i (click)="delete(note)" class="fas fa-window-close"></i>
                {{ note?.notetext }}
                <small class="info" class="form-text text-muted">{{ note?.createdate | date: "MM/dd/yy" }} {{ note?.providername }}</small>
            </div>
        </div>
    </div>
</div>
<app-snackbar></app-snackbar>
