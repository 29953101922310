import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { AutoReplyService } from '../../services/auto_reply.service';
import { Configuration } from './models/Schedule'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleModalComponent } from '../../modals/schedule-modal/schedule-modal.component';
import { ChatbotKeywordModalComponent } from '../../modals/chatbot-keyword-modal/chatbot-keyword-modal.component';
import { WarningModalComponent } from '../../modals/warning-modal/warning-modal.component';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { Observable } from 'rxjs';
import { SortableHeaderDirective } from '../../directives/sortable.directive';
import { TableService } from '../../services/table.service';
import { TeleRXService } from '../../services//teleRX.service';

@Component({
  selector: 'app-auto-reply',
  templateUrl: './auto-reply.component.html',
  styleUrls: ['./auto-reply.component.scss']
})
export class AutoReplyComponent implements OnInit {
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  resultList$: Observable<any[]>;
  total$: Observable<number>;
  displayedColumns = [
    { displayname: "Keyword", columnname: "keyword" },
    { displayname: "Severity", columnname: "severity" },
    { displayname: "", columnname: "" },
  ];

  constructor(
    private autoreply: AutoReplyService,
    private sessionService: SessionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snackbar: SnackbarService,
    public tableService: TableService,
    public teleRxservice: TeleRXService
  ) {
    this.tableService.searchTerm = "";
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
  }
  public configuration: any;
  public defaultMessage = "";
  public reply_type = [
    { name: "All the time", value: 'all-time', checked: false },
    { name: "After hours", value: 'after-hours', checked: false },
    { name: "Schedule", value: 'schedules', checked: false }
  ];
  public days = [
    { name: "Monday", "isOpen": false, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
    { name: "Tuesday", "isOpen": true, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
    { name: "Wednesday", "isOpen": true, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
    { name: "Thursday", "isOpen": true, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
    { name: "Friday", "isOpen": true, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
    { name: "Saturday", "isOpen": true, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
    { name: "Sunday", "isOpen": true, "startTime": { hour: '--', minutes: '--', meridiem: '--' }, "endTime": { hour: '--', minutes: '--', meridiem: '--' } },
  ]
  public _enableFor = ""
  public _startTime = "00:00"
  public _endTime = "06:00"
  public afterHourMessage = "AfterHoursAutoReply: Our Office is currently closed. We will respond to your message as soon as we can. If this is medical emergency, please call 911"
  public validdate = true;
  autoReplyEnabled = true;
  ngOnInit(): void {

    this.teleRxservice.get().subscribe(
      (data) => {
        let membership = data[0].type.membership;
        if (!membership.auto_reply_feature_enabled) {
          this.autoReplyEnabled = false
          this.configuration.enable = false;
        }
      },
      (err) => {
        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
      }
    );


    let autoreplySetting = this.sessionService.currentUser?.attributes.membership.telerx.autoreply
    if (autoreplySetting === null) {
      autoreplySetting = {
        "enable": true,
        "consolidatedReply": true,
        "enableFor": "after-hours",
        "afterHoursSettings": {
          "chatMessage": ""
        },
        "defaultMessage": "",
        "schedules": [],
        "typicalKewords": [
          { keyword: 'suicide', severity: 'high', chatMessage: 'If you’re thinking about suicide, are worried about a friend or loved one, or would like emotional support, the Lifeline network is available 24/7 across the United States. National Suicide Prevention Lifeline: 1-800-273-8256', id: this.guid() },
          { keyword: 'depressed', severity: 'high', chatMessage: 'If you’re thinking about suicide, are worried about a friend or loved one, or would like emotional support, the Lifeline network is available 24/7 across the United States. National Suicide Prevention Lifeline: 1-800-273-8255', id: this.guid() },
          { keyword: 'appointment', severity: 'low', chatMessage: "<a href='visitlink://appointments'>Click here</a> to schedule an appointment. If you're having a medical emergency, call 911.", id: this.guid() },
          { keyword: 'problem, bleeding, hurting, pain, sore, aching', severity: 'medium', chatMessage: "<a href='visitlink://appointments'>Click here</a> to schedule an appointment. If you're having a medical emergency, call 911.", id: this.guid() },
          { keyword: '[parts of the body]', severity: 'medium', chatMessage: "<a href='visitlink://appointments'>Click here</a> to schedule an appointment. If you're having a medical emergency, call 911.", id: this.guid() },


        ]
      }
      this.sessionService.currentUser.attributes.membership.telerx.autoreply = autoreplySetting;
    }
    if (autoreplySetting.afterHoursSettings === undefined || autoreplySetting.afterHoursSettings.days == undefined) {
      if (autoreplySetting.afterHoursSettings === undefined) {
        autoreplySetting.afterHoursSettings = {};
      }
      autoreplySetting.afterHoursSettings.days = this.days;
    }
    else {
      this.days = autoreplySetting.afterHoursSettings.days;
    }
    if (autoreplySetting.afterHoursSettings !== undefined && autoreplySetting.afterHoursSettings.chatMessage !== undefined) {
      this.afterHourMessage = autoreplySetting.afterHoursSettings.chatMessage;
    }
    this.configuration = new Configuration(
      autoreplySetting ? (autoreplySetting ? autoreplySetting.enable : "") : "",
      autoreplySetting ? (autoreplySetting ? autoreplySetting.enableFor : "") : "",
      autoreplySetting ? (autoreplySetting ? autoreplySetting.afterHoursSettings : "") : "",
      autoreplySetting ? (autoreplySetting ? autoreplySetting.defaultMessage : "") : "",
      autoreplySetting ? (autoreplySetting ? autoreplySetting.schedules : "") : "",
      autoreplySetting ? (autoreplySetting ? autoreplySetting.typicalKewords : "") : "",
    )
    this.configuration.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.configuration.enable === undefined || this.configuration.enable === null) {
      this.configuration.enable = false;
    }
    this.defaultMessage = autoreplySetting.defaultMessage;
    this.reply_type.find((r) => {
      if (autoreplySetting.enableFor.includes(r.value)) {
        r.checked = true;
        this._enableFor = autoreplySetting.enableFor;
      }
    });

    this.tableService.DATA = this.configuration.typicalKewords
    setTimeout(() => {
      this.sorting("severity", "asc");
    }, 1000);
  }
  public guid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }
  public onPagination(page: any) {
    this.tableService.page = page;
  }
  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }
  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
    this.tableService.searchOn = "severity";
  }

  public addForAllTime() {
    this.validdate = true;
    let currentDate = new Date();
    let date = currentDate.getDate();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    let hasError = false;
    if (this._enableFor === "all-time") {
      this.configuration.enableFor = "all-time";
      this.configuration.defaultMessage = this.defaultMessage;
    }
    else {
      this.configuration.afterHoursSettings.days.map((p) => {

        if (p.isOpen) {
          let _startHour = parseInt(p.startTime.hour == "12" ? "0" : p.startTime.hour);
          let _endHour = parseInt(p.endTime.hour == "12" ? "0" : p.endTime.hour);

          p.startTimeUtc = new Date(year, month, date, p.startTime.meridiem == "PM" ? _startHour + 12 : _startHour, p.startTime.minutes);
          p.endTimeUtc = new Date(year, month, date, p.endTime.meridiem == "PM" ? _endHour + 12 : _endHour, p.endTime.minutes);

          if (p.startTimeUtc < p.endTimeUtc) {
            p.startTimeError = false;
          } else {
            p.startTimeError = true;
            hasError = true;
            return false;
          }
          if (isNaN(p.startTimeUtc.getTime()) || isNaN(p.endTimeUtc.getTime())) {
            p.startTimeError = true;
            hasError = true;
            this.validdate = false;
            this.snackbar.show("Please select valid date. ", "danger");
            return;
          }
        }
      });


      this.configuration.afterHoursSettings = {
        days: this.days,
        chatMessage: this.afterHourMessage
      }
    }
    if (hasError) {
      this.snackbar.show("End time should not be less than start time. ", "danger");
      return;
    }
    if (this.validdate && !hasError) {
      this.autoreply.addSchedule(this.configuration).subscribe(
        (data) => {
          this.snackbar.show(data[0].type.message, "success");
          this.sessionService.currentUser.attributes.membership.telerx.autoreply = this.configuration;

        },
        (error) => {
          this.snackbar.show(error[0].detail, "danger");
        }
      );
    }

  }
  public addToSchedule(showModal = true) {
    this.configuration.enableFor = this._enableFor;
    this.configuration.defaultMessage = this.defaultMessage;
    const modalRef = this.modalService.open(ScheduleModalComponent, { size: "lg", backdrop: "static" });

  }
  public editSchedules(i: number) {
    const modalRef = this.modalService.open(ScheduleModalComponent, { size: "lg", backdrop: "static" });
    modalRef.componentInstance.selectedIndex = i;

  }
  public deleteSchedules(i: number) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Yes";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.activeModal.close();
        this.configuration.schedules.splice(i, 1)
        this.updateSchedule()
      }
    });
  }
  public addKeywords() {
    const modalRef = this.modalService.open(ChatbotKeywordModalComponent, { size: "xl", backdrop: "static" });
    modalRef.componentInstance.selecteId = "";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.success) {
        this.sorting("severity", "asc");
      }
    });
  }
  public editKeywords(i: number, id: any) {
    const modalRef = this.modalService.open(ChatbotKeywordModalComponent, { size: "xl", backdrop: "static" });
    modalRef.componentInstance.selecteId = id;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.success) {
        this.sorting("severity", "asc");
      }
    });
  }
  public deleteKeywords(i: number, id: any) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Yes";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.activeModal.close();
        this.configuration.typicalKewords.forEach((element, index) => {
          if (element.id === id) {
            this.configuration.typicalKewords.splice(index, 1)
          }
        });

        this.updateSchedule()
      }
    });
  }
  public updateSchedule() {
    this.autoreply.addSchedule(this.configuration).subscribe(
      (data) => {
        this.sessionService.currentUser.attributes.membership.telerx.autoreply = this.configuration;
        this.sorting("severity", "asc");
      },
      (error) => {
      }
    );
  }
  public autoReplyType(event, value) {
    this._enableFor = ""
    this.reply_type.forEach(element => {
      if ((value === "all-time" || value === "after-hours") && (element.value === "all-time" || element.value === "after-hours")) {
        element.checked = false;
      }
      if (element.value === value) {
        element.checked = event.target.checked;
      }
      if (value === "schedules" && event.target.checked && element.value === "all-time") {
        element.checked = false;
      }
      if (value === "all-time" && event.target.checked && element.value === "schedules") {
        element.checked = false;
      }
    });
    this.reply_type.forEach(element => {
      if (element.checked) {
        this._enableFor += element.value + ',';
      }
    });
    this._enableFor = this._enableFor.replace(/,\s*$/, "")
    this.configuration.enableFor = this._enableFor;
    this.sessionService.currentUser.attributes.membership.telerx.autoreply = this.configuration;

  }

  public status(day, open) {
    this.days.find((d) => {
      if (d.name === day) {
        d.isOpen = open;
      }
    });
  }

  public autoReplyStatus(event: any) {
    this.configuration.enable = event.target.checked;
    this.updateSchedule();
  }
  reload() {
    this.tableService.searchTerm = "";
  }
}
