import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "providerapp-modal",
    templateUrl: "./providerapp-modal.component.html",
    styleUrls: ["./providerapp-modal.component.scss"],
})
export class ProviderAppModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}

    public url;
    @Input() appUrl: any;
    @Input() appTitle: string;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.appUrl);
    }

    public onCancel() {
        this.activeModal.close();
    }
}
