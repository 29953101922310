import { Injectable, HostListener } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { Subject } from "rxjs";
import { CommunicationService } from "./communications.service";
import * as firebase from "firebase/messaging";
import { take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ReportService } from "./report.service";
import { SessionService } from "./session.service";
import { Observable, Observer } from "rxjs";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class MessagingService {
    currentMessage = new Subject();
    latestThreadPull = new Subject();
    latestCareTeamPull = new Subject();
    updateUnreadCounter = new Subject();
    latestUnreadCountPull = new Subject();
    goalCountPull = new Subject();
    isFirebaseSupported: boolean = false;
    isPushMessagingDisabled: boolean = false;
    isPushSubscribed: boolean = false;
    isMessagingSubscribed: boolean = false;
    isStartedPolling: boolean = false;
    threads: any = [];
    messagePoller: any;
    selectedGroup = "";
    constructor(
        private communication: CommunicationService,
        private reportService: ReportService,
        private angularFireMessaging: AngularFireMessaging,

        private sessionService: SessionService,
        private avcHttp: AvcHttp,
        private helper: Helper
    ) {
        firebase.isSupported().then((supported) => {
            this.isFirebaseSupported = supported;

            // this.angularFireMessaging.messages.subscribe((_messaging: MessagePayload) => {
            //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            //     // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            // });

            // For non supported FCM push browser
            if (!this.isFirebaseSupported || Notification.permission === "denied" || Notification.permission === "default" || localStorage.getItem("startPolling") === "true") {
                this.isPushMessagingDisabled = true;
                this.startPolling();
            }
            try {
                console.log("Notification preference", Notification.permission);
            } catch (e) {
                console.log(e);
            }
        });

        this.selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (this.selectedGroup !== "") {
            this.selectedGroup = this.selectedGroup.toLowerCase();
        }
    }

    @HostListener("document:visibilitychange", ["$event"])
    handleVisibilityChange(event: any): void {
        if (!document.hidden) {
            this.getThreads();
            this.getCareTeamStats();
        }
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     */
    public requestPermission(careTeam: any, subscribe: boolean = false) {
        // if (this.isPushSubscribed) {
        //   // console.error('Push is already subscribed');
        //   return false;
        // }
        // this.isPushSubscribed = true;
        let topic = careTeam.careteamid;
        this.angularFireMessaging.requestPermission.pipe(take(1)).subscribe(
            () => {
                console.log("Notification permission granted.");
                this.angularFireMessaging.getToken.pipe(take(1)).subscribe((token) => {
                    this.communication
                        .pushSubscribe(token, topic)
                        .pipe(take(1))
                        .subscribe(
                            (data) => {
                                let pushUser = {
                                    token: token,
                                    careteamid: topic,
                                };
                                localStorage.setItem("pushUser", JSON.stringify(pushUser));
                                // Subscribe to incoming messages
                                if (subscribe) {
                                    // Start listening for messages
                                    this.receiveMessage();
                                    // Stop polling if activated
                                    if (localStorage.getItem("startPolling") !== null && localStorage.getItem("startPolling") !== "true") {
                                        this.stopPolling();
                                    }
                                    console.log("Realtime messaging activated");
                                }
                            },
                            (error) => {
                                console.log("ERROR");
                                console.log(JSON.stringify(error));
                                console.log(error[0].detail);
                            }
                        );
                });
            },
            (err) => {
                console.log("Unable to get permission to notify.", err);
                this.currentMessage.error({
                    denied: true,
                });
                // Start polling as backup method
                this.startPolling();
            }
        );
    }

    public receiveMessage() {
        if (this.isMessagingSubscribed) {
            // console.error('Message receiver already subscribed')
            return false;
        }
        this.isMessagingSubscribed = true;
        // console.log("ReceiveMessage Start");
        // this.angularFireMessaging.messages.pipe(takeUntil(this.utilities.logoutEvent)).subscribe(
        this.angularFireMessaging.messages.pipe().subscribe(
            (payload) => {
                let x = document.createEvent("CustomEvent");
                x.initCustomEvent("newMessageReceived", true, true, (payload as any).data);
                document.dispatchEvent(x);
                this.currentMessage.next(payload);
                // Clear subject current value after payload by sending null
                this.currentMessage.next(null);
            },
            (error) => console.error(error)
        );
    }

    /**
     * Will periodically poll the server to update the thread list and pull new messages for
     * the current patient chat
     */
    public startPolling() {
        // if (this.isStartedPolling) {
        //     console.error("Thread polling already started");
        //     return false;
        // }
        // this.isStartedPolling = true;
        // console.log("Started polling threads");
        // this.messagePoller = setInterval(() => {
        //     this.getThreads();
        //     this.getCareTeamStats();
        // }, environment.chat_message_polling_time_interval);
    }

    /**
     * Clears the interval managing message poilling
     */
    public stopPolling() {
        if (this.messagePoller) {
            clearInterval(this.messagePoller);
            console.log("Stopped polling");
        }
    }

    public getThreads() {
        this.communication
            .getThreadList()
            .pipe(take(1))
            .subscribe(
                (data) => {
                    if (data[0].attributes.length > 0) {
                        this.threads = data[0].attributes;
                        this.latestThreadPull.next(this.threads);
                        this.latestUnreadCountPull.next(this.threads);
                        this.goalCountPull.next();
                    }
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    private getCareTeamStats() {
        if (!this.sessionService.activeCareTeam) {
            return false;
        }
        let id = this.sessionService.activeCareTeam.careteamid;
        this.reportService
            .getCareTeamStats(id)
            .pipe(take(1))
            .subscribe((data) => {
                // Send stats to the careteam pull subject
                this.latestCareTeamPull.next(data.attributes[0]);
            });
    }

    public getBadWords(defaultlist = false): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/messaging/badwords?membershipid=" + this.selectedGroup;
            if (defaultlist) {
                url = environment.uri + "/messaging/badwords?defaultlist=yes&membershipid=" + this.selectedGroup;
            }
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public deleteBadWords(badword): Observable<any> {
        badword = encodeURIComponent(badword);
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", `${environment.uri}/messaging/badwords/${badword}`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
    public addBadWord(badword): Observable<any> {
        let data = JSON.stringify({
            membershipid: this.sessionService.currentUser.attributes.membership.membershipid,
            badword: badword,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/messaging/badwords", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteMessage(messageId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", `${environment.uri}/messaging/${messageId}`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
    public enableMessage(messageId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", `${environment.uri}/messaging/${messageId}/enable`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
}
