import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class ChatTagsService {
    private handler = `/messaging/tags`;
    private url = `${environment.uri}${this.handler}`;

    constructor(private avcHttp: AvcHttp, private helper: Helper) {}

    public getTags(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public createTag(form): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                tag: form.value.tagName,
            });

            this.avcHttp.sendRequest("post", this.url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteTag(tagName): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/" + tagName).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
