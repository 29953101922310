<div class="modal-body">
    <div class="category-header">
        <label>Confirm</label>
    </div>
    <div class="main-row">
        <div class="row visit-category-row">
            <div class="visit-category-columns symptoms">
                <label>Symptoms</label>
                <div class="sym-item" *ngFor="let cat of keys">
                    <span>{{ cat }}</span>
                    <div class="sym-sub-item" *ngFor="let sub of symptoms[cat].reasons"> {{ sub.name }} </div>
                    <div class="sym-sub-item" *ngIf="symptoms[cat].other"> {{ symptoms[cat].other }} </div>
                </div>
                <label *ngIf="symptoms.other">Comments</label>
                <div *ngIf="symptoms.other" class="sym-item">
                    <div class="sym-sub-item">{{ symptoms.other }} </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row visit-category-row">
        <div class="col-lg-4 visit-category-columns">
            <button type="button" class="btn btn-secondary cancel-button" (click)="onCancel()">Cancel</button>
        </div>
        <div class="col-lg-4 visit-category-columns">
            <button type="button" class="btn btn-primary cancel-button" (click)="onMakeChanges()">Make Changes</button>
        </div>
        <div class="col-lg-4 visit-category-columns">
            <button type="button" class="btn btn-primary save-button" [disabled]="disableAddtoTriage"
                (click)="onAddToTriage()">Add to Appointment Requests</button>
        </div>
        <!-- <div class="col-lg-3 visit-category-columns">
            <button type="button" class="btn btn-primary save-button" [disabled]="disabled"
                (click)="onSubmit()">Submit</button>
        </div> -->
    </div>
</div>