import { Injectable } from "@angular/core";
import { AvcHttp } from "../providers/avc-http";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class TeleRXService {
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService, private http: HttpClient) { }

    uri: string = environment.uri + "/telerx";

    public get(_telerxid = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
            if (_telerxid !== "") {
                telerxid = _telerxid;
            }
            this.avcHttp.sendRequest("get", this.uri + "/" + telerxid).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public addUserTelerx(userId, terxId = ""): Observable<any> {
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
        if (terxId !== "") {
            telerxid = terxId;
        }
        let data = JSON.stringify({
            telerxid: telerxid,
            userid: userId,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", `${this.uri}/` + "subscription/user", data).subscribe(
                (data) => {
                    const Data = this.helper.ParseToJson(data);
                    observer.next(Data.data);
                    observer.complete();
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public list(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.uri + "/subscription/membership").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public addMemberToTeleRemedy(telerxid, reEnable = false) {
        let data = JSON.stringify({
            telerxid: telerxid,
            membershipid: this.sessionService.getCurrentUserMembershipId(),
            reEnable: reEnable,
        });
        return new Promise((resolve, reject) => {
            this.avcHttp.sendRequest("post", this.uri + "/subscription/membership", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        resolve(body.data);
                    } else {
                        reject(body.errors);
                    }
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
    public addMembershipToTeleRemedy(data: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/telerx/subscription/membership", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public removeMemberToTeleRemedy(telerxid, membershipid): Observable<any> {
        let data = JSON.stringify({
            telerxid: telerxid,
            membershipid: membershipid,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", environment.uri + "/telerx/subscription/membership", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public uploadLogo(fileToUpload: File, membershipid = "") {
        return new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            formData.append("file", fileToUpload, fileToUpload.name);
            let headers = new HttpHeaders({
                Authorization: "Bearer " + this.sessionService.currentUser?.attributes.token,
            });
            let options = {
                headers: headers,
            };
            let _url = this.uri + "/uploadlogo";
            if (membershipid !== "") {
                _url = this.uri + "/uploadlogo?membershipid=" + membershipid;
            }

            return this.http.post(_url, formData, options).subscribe(
                (body) => {
                    if (body["data"] !== undefined && body["data"][0].attributes !== undefined) {
                        resolve(body["data"][0].attributes);
                    } else {
                        reject("errors");
                    }
                },
                (err) => {
                    reject(err.error.errors);
                }
            );
        });
    }

    public update(updatedValue, _telerxid = "", _workFlow: any = {}): Observable<any> {
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
        // let membership = this.sessionService.currentUser?.attributes.membership;
        // if (membership.telerx.name !== undefined) {
        //     _name = membership.telerx.name;
        // }
        if (_telerxid !== "") {
            telerxid = _telerxid;
        }
        updatedValue.additional_settings = {};
        updatedValue.additional_settings.enableUserSelfGoals = updatedValue.enableUserSelfGoals;
        if (Object.keys(_workFlow).length) {
            updatedValue.additional_settings.waitingRoomWorkflowSettings = _workFlow;
        }
        let data = updatedValue;
        if (data.telerxtype === "application") {
            data.validatedashboardurl = true;
            if (data.applicationlinktype != 'global') {
                data.applicationlinktype = 'non-global';
            }
        }
        else {
            data.applicationlinktype = null
        }

        data.providerdashboardurl = data.providerdashboardurl ? data.providerdashboardurl.trim() : data.providerdashboardurl;
        data.callbackeventurl = data.callbackeventurl ? data.callbackeventurl.trim() : data.callbackeventurl;

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.uri + "/" + telerxid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public updateRooms(tabOrderSettings = [], waitingRooms = []): Observable<any> {
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
        let data = JSON.stringify({
            providerpanelsettings: {
                tabOrderSettings: tabOrderSettings,
                waitingRooms: waitingRooms,
            },
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.uri + "/" + telerxid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getTeleRxByType(type): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
            this.avcHttp.sendRequest("get", this.uri + "?telerxtype=" + type).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public removeUserFromTeleRemedy(telerxid, userid): Observable<any> {
        let data = JSON.stringify({
            telerxid: telerxid,
            userid: userid,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", environment.uri + "/telerx/subscription/user", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getTeleRx(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
            this.avcHttp.sendRequest("get", this.uri).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public delete(telerxid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.uri + "/" + telerxid).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getTeleRxMembers(telerxid, role = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = this.uri + "/userlist/" + telerxid + "?limit=100000";
            if (role !== "") {
                url = this.uri + "/userlist/" + telerxid + "?limit=100000&type=" + role;
            }
            this.avcHttp.sendRequest("get", url).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getSubscribedTeleRx(telerxid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.uri + "/subscription/details/" + telerxid).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
