import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Helper } from "../../providers/helper";
import { SnackbarService } from "../snackbar/snackbar.service";
import { SessionService } from "../../services/session.service";
import { PrescriptionViewModalComponent } from "../../modals/prescription-view-modal/prescription-view-modal.component";
import { PatientService } from "../../services/patients.service";
import { PrescriptionModalComponent } from "../../modals/prescription-modal/prescription-modal.component";
import { SummaryCategoriesModalComponent } from "../../modals/summary-category/summary-categories-modal/summary-categories-modal.component";
import { SummaryViewModalComponent } from "../../modals/summary-view-modal/summary-view-modal.component";
import {
    PersonalInformation,
    ContactInformation,
    EmergencyContact,
    HealthInsuranceInformation,
    PastMedicalHistory,
    PastSurgicalHistory,
    WomenHealthHistory,
    MedicationAllergies,
    FoodAllergies,
    EnvironmentalAllergies,
    SocialHistory,
    // Allergies,
    Vaccines,
    FamilyHistory,
    Prescriptions,
    Medications,
    MoodWellBeing,
    Covid19Vaccination,
} from "../../pages/patient-dashboard/models/SummaryDetails";
import { ClinicalSummary } from "../../pages/patient-dashboard/models/ClinicalSummary";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { AppsModalComponent } from "../../modals/apps-modal-v2/apps-modal-v2.component";
import { AppsService } from "../../services/apps.service";
import { ChatImageModalComponent } from "../../modals/chat-image-modal/chat-image-modal.component";
import { phq9ModalComponent } from "../../modals/summary-category/phq9-modal/phq9-modal.component";

@Component({
    selector: "app-clinicalsummary-window",
    templateUrl: "./clinicalsummary-window.component.html",
    styleUrls: ["./clinicalsummary-window.component.scss"],
})
export class clinicalSummaryComponent implements OnInit {
    constructor(
        private helper: Helper,
        private modalService: NgbModal,
        private snackbar: SnackbarService,
        public sessionService: SessionService,
        private patientService: PatientService,
        private appsService: AppsService
    ) { }
    hasWallet = false;
    @Input() patient: any;
    @Input() patientId: string;
    @Input() patientDashboardSetting: any;
    currentPatient: any = {};

    links: any = {};
    public summaryData: any = { summary: {} };
    clinicalSummaryModel = undefined;
    phq9score = 0;
    gad7Score = 0;
    phq9Date = "";
    gad7Date = "";

    sleepScore = 0;
    sleepDate = "";

    imageURLVaccination: string;
    imageURLFront: string;
    imageURLBack: string;
    imageURLPhotoID: string;
    userAttributes: any = {};
    gender: string;
    phq9Severity = "Minimal or none";
    gad7Severity = "Minimal or none";
    sleepSeverity = "Minimal or none";

    ngOnInit() {
        this.currentPatient = this.patient;
        this.patientId = this.currentPatient.patientid;
        this.getClinicalSummary();
        this.getPrescribedApps();
        document.addEventListener("UPDATE_SUMMARY", (event: any) => {
            this.getClinicalSummary();
            this.setUpHeader();
        });
    }
    public onAddApp() {
        // this.appData = {};
        const modalRef = this.modalService.open(AppsModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.userId = this.patientId;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show("Action completed successfully.");
                this.getPrescribedApps();
            } else {
                this.snackbar.show(receivedEntry.message, "danger");
            }
        });
    }
    public onDelete(app) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this app?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.appsService.unassignUser(this.patientId, app.appid).subscribe(
                    (data) => {
                        this.snackbar.show("Application removed for member.");
                        this.getPrescribedApps();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public getDisplayOrder(panel) { }
    public isOpenedTab(name) {
        this.helper.isOpenedTab(name);
    }
    public onViewPrescription(medication, e) {
        e.stopPropagation();

        this.summaryData = {
            action: "view",
            medication: medication,
        };
        const modalRef = this.modalService.open(PrescriptionViewModalComponent, { size: "lg", windowClass: "modal-center", backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
    }
    public onDeletePrescription(index, e) {
        e.stopPropagation();

        this.clinicalSummaryModel.prescriptions.medications.splice(index, 1);
        this.addUpdateSummary();
        this.snackbar.show("Prescription deleted for patient.");
    }
    public addUpdateSummary() {
        let updatedData = this.clinicalSummaryModel;
        let userId = this.patientId;
        return this.patientService.updateSummary(updatedData, userId).subscribe(
            (data) => {
                // console.log(data);
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public onAddPrescription() {
        let newMedication = new Medications("", "", "", "", "", "", null, "", null, "", null);
        this.summaryData = {
            action: "add",
            patientId: this.patientId,
            summary: this.clinicalSummaryModel,
            medication: newMedication,
        };
        const modalRef = this.modalService.open(PrescriptionModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show("Prescription Added for patient.");
                this.getPrescribedApps();
            } else {
                this.snackbar.show(receivedEntry.message, "danger");
            }
        });
    }
    public onEditPrescription(medication, index, e) {
        e.stopPropagation();
        this.summaryData = {
            action: "edit",
            patientId: this.patientId,
            summary: this.clinicalSummaryModel,
            medication: medication,
            index: index,
        };
        const modalRef = this.modalService.open(PrescriptionModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show("Prescription Updated for patient.");
            } else {
                this.snackbar.show(receivedEntry.message, "danger");
            }
        });
    }
    public getPrescribedApps() {
        return this.patientService.getUserLinks(this.patientId).subscribe(
            (data) => {
                this.links = data.attributes;
                // this.links = this.links.suggested.concat(this.links.global);
                this.links = this.links.suggested.filter(link => link.linktype != 'global');
                this.links.sort((a, b) => (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? 1 : -1));
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public viewSummaryModalResponse(event) {
        if (event.success && event.action === "edit") {
            let summaryModel = JSON.parse(JSON.stringify(this.clinicalSummaryModel));
            this.summaryData = {
                action: "edit",
                patientId: this.patientId,
                summary: summaryModel,
                userAttributes: this.userAttributes,
            };
            const modalRef = this.modalService.open(SummaryCategoriesModalComponent, { size: "lg", centered: true, backdrop: "static" });
            modalRef.componentInstance.SummaryData = this.summaryData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.clinicalSummaryModel = receivedEntry.updatedSummaryData;
                if (this.clinicalSummaryModel.personalinformation.photoid) {
                    this.getPhotoIDImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imagefront) {
                    this.getFrontImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imageback) {
                    this.getBackImageURL();
                }
                if (this.clinicalSummaryModel.covid19vaccination.vaccinationcard) {
                    this.getVaccinationImageURL();
                }
                let updateEvent = new CustomEvent("UPDATE_HEADER", {});
                document.dispatchEvent(updateEvent);
                this.snackbar.show(receivedEntry.message);
            });
        }
    }

    public getFrontImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.healthinsuranceinformation.imagefront).subscribe(
            (data) => {
                this.imageURLFront = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getBackImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.healthinsuranceinformation.imageback).subscribe(
            (data) => {
                this.imageURLBack = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getVaccinationImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.covid19vaccination.vaccinationcard).subscribe(
            (data) => {
                this.imageURLVaccination = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getPhotoIDImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.personalinformation.photoid).subscribe(
            (data) => {
                this.imageURLPhotoID = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public onViewEditClinicalSummary() {
        let summaryModel = JSON.parse(JSON.stringify(this.clinicalSummaryModel));
        this.summaryData = {
            action: "edit",
            patientId: this.patientId,
            summary: summaryModel,
            userAttributes: this.userAttributes,
        };
        const modalRef = this.modalService.open(SummaryViewModalComponent, { size: "lg", centered: true, backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.viewSummaryModalResponse(receivedEntry);
        });
    }

    getClinicalSummary() {
        return this.patientService.getClinicalSummary(this.patientId).subscribe(
            (data) => {
                let profileSettings = data[0].attributes.profileSettings;
                let personalinformationData = profileSettings ? profileSettings.personalinformation : null;
                let personalInformationModel = new PersonalInformation(
                    profileSettings ? (personalinformationData ? personalinformationData.firstname : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.middlename : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.lastname : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.maidenname : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.mrn : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.gender ? personalinformationData.gender : "") : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.dob : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.maritalstatus ? personalinformationData.maritalstatus : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.children ? personalinformationData.children : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.grandchildren ? personalinformationData.grandchildren : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.livingarrangement ? personalinformationData.livingarrangement : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.employment ? personalinformationData.employment : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.photoid ? personalinformationData.photoid : "") : "") : ""
                );

                let contactData = profileSettings ? profileSettings.contactinformation : null;
                let contactInformationModel = new ContactInformation(
                    profileSettings ? (contactData ? contactData.phone1 : "") : "",
                    profileSettings ? (contactData ? (contactData.phone1type ? contactData.phone1type : "") : "") : "",
                    profileSettings ? (contactData ? contactData.phone2 : "") : "",
                    profileSettings ? (contactData ? (contactData.phone2type ? contactData.phone2type : "") : "") : "",
                    profileSettings ? (contactData ? contactData.phone3 : "") : "",
                    profileSettings ? (contactData ? (contactData.phone3type ? contactData.phone3type : "") : "") : "",
                    profileSettings ? (contactData ? contactData.address1 : "") : "",
                    profileSettings ? (contactData ? contactData.address2 : "") : "",
                    profileSettings ? (contactData ? contactData.city : "") : "",
                    profileSettings ? (contactData ? contactData.state : "") : "",
                    profileSettings ? (contactData ? contactData.zip : "") : ""
                );

                let emergencycontactData = profileSettings ? profileSettings.emergencycontact : null;
                let emergencycontactModel = new EmergencyContact(
                    profileSettings ? (emergencycontactData ? emergencycontactData.name : "") : "",
                    profileSettings ? (emergencycontactData ? emergencycontactData.phonenumber1 : "") : "",
                    profileSettings ? (emergencycontactData ? (emergencycontactData.relation ? emergencycontactData.relation : "") : "") : ""
                );
                let healthInsuranceData = profileSettings ? profileSettings.healthinsuranceinformation : null;
                let healthInsurancModel = new HealthInsuranceInformation(
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.cardholderfirstname : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.cardholderlastname : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.idnumber : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.groupnumber : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.companyname : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.company : null) : null,
                    profileSettings ? (healthInsuranceData ? (healthInsuranceData.imagefront ? healthInsuranceData.imagefront : "") : "") : "",
                    profileSettings ? (healthInsuranceData ? (healthInsuranceData.imageback ? healthInsuranceData.imageback : "") : "") : ""
                );

                let pastmedicalhistoryData = profileSettings ? profileSettings.pastmedicalhistory : null;
                let pastmedicalhistoryModel = new PastMedicalHistory(
                    profileSettings ? (pastmedicalhistoryData ? (pastmedicalhistoryData.other ? pastmedicalhistoryData.other : "") : "") : "",
                    profileSettings ? (pastmedicalhistoryData ? pastmedicalhistoryData.historyrecords : null) : null
                );

                let pastsurgicalhistoryData = profileSettings ? profileSettings.pastsurgicalhistory : null;
                let pastsurgicalhistoryModel = new PastSurgicalHistory(
                    profileSettings ? (pastsurgicalhistoryData ? (pastsurgicalhistoryData.other ? pastsurgicalhistoryData.other : "") : "") : "",
                    profileSettings ? (pastsurgicalhistoryData ? pastsurgicalhistoryData.historyrecords : null) : null
                );
                let womenhealthhistoryData = profileSettings ? profileSettings.womenhealthhistory : null;
                let womenhealthhistoryModel = new WomenHealthHistory(
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.firstperiod : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.periodgap : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.periodduration : "") : "",
                    profileSettings ? (womenhealthhistoryData ? (womenhealthhistoryData.periodflow ? womenhealthhistoryData.periodflow : "") : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.totalpregnancies : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.fulltermbirths : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.miscarriages : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.abortions : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.vaginalbirths : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.csections : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.premature : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.menopauseage : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.menopausal : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.birthcontrol : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.pregnancy : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.birthcontroltype : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.pregnancycomplications : null) : null,
                    profileSettings ? (womenhealthhistoryData ? (womenhealthhistoryData.otherbirthcontrol ? womenhealthhistoryData.otherbirthcontrol : "") : "") : "",
                    profileSettings ? (womenhealthhistoryData ? (womenhealthhistoryData.otherpregnancycomplications ? womenhealthhistoryData.otherpregnancycomplications : "") : "") : ""
                );

                let medicationallergiesData = profileSettings ? profileSettings.medicationallergies : null;
                let medicationallergiesModel = new MedicationAllergies(
                    profileSettings ? (medicationallergiesData ? (medicationallergiesData.other ? medicationallergiesData.other : "") : "") : "",
                    profileSettings ? (medicationallergiesData ? medicationallergiesData.allergies : null) : null
                );

                let foodallergiesData = profileSettings ? profileSettings.foodallergies : null;
                let foodallergiesModel = new FoodAllergies(
                    profileSettings ? (foodallergiesData ? (foodallergiesData.other ? foodallergiesData.other : "") : "") : "",
                    profileSettings ? (foodallergiesData ? foodallergiesData.allergies : null) : null
                );

                let environmentalallergiesData = profileSettings ? profileSettings.environmentalallergies : null;
                let environmentalallergiesModel = new EnvironmentalAllergies(
                    profileSettings ? (environmentalallergiesData ? (environmentalallergiesData.other ? environmentalallergiesData.other : "") : "") : "",
                    profileSettings ? (environmentalallergiesData ? environmentalallergiesData.allergies : null) : null
                );

                let socialhistoryData = profileSettings ? profileSettings.socialhistory : null;
                let socialhistoryModel = new SocialHistory(
                    profileSettings ? (socialhistoryData ? (socialhistoryData.tobaccouse ? socialhistoryData.tobaccouse : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.alcoholuse ? socialhistoryData.alcoholuse : "") : "") : "",
                    profileSettings ? (socialhistoryData ? socialhistoryData.druguse : null) : null,
                    profileSettings ? (socialhistoryData ? (socialhistoryData.drugdetails ? socialhistoryData.drugdetails : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.caffeineuse ? socialhistoryData.caffeineuse : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.exerciseFrequency ? socialhistoryData.exerciseFrequency : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.exerciseIntensity ? socialhistoryData.exerciseIntensity : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.exerciseDuration ? socialhistoryData.exerciseDuration : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.sexuallyactive ? socialhistoryData.sexuallyactive : "") : "") : ""
                );

                // let allergiesData = profileSettings ? profileSettings.allergies : null;
                // let allergiesModel = new Allergies(
                //     profileSettings ? (allergiesData ? (allergiesData.medication ? allergiesData.medication : false) : false) : false,
                //     profileSettings ? (allergiesData ? (allergiesData.food ? allergiesData.food : false) : false) : false,
                //     profileSettings ? (allergiesData ? (allergiesData.environmental ? allergiesData.environmental : false) : false) : false
                // );
                let vaccinesData = profileSettings ? profileSettings.vaccines : null;
                let vaccinesModel = new Vaccines(profileSettings ? (vaccinesData ? vaccinesData.vaccines : null) : null);

                let familyhistoryData = profileSettings ? profileSettings.familyhistory : null;
                let familyhistoryDataModel = new FamilyHistory(
                    profileSettings ? (familyhistoryData ? familyhistoryData.history : null) : null,
                    profileSettings ? (familyhistoryData ? familyhistoryData.other : null) : null
                );

                let prescriptionsData = profileSettings ? profileSettings.prescriptions : null;
                let prescriptionsModel = new Prescriptions(profileSettings ? (prescriptionsData ? prescriptionsData.medications : null) : null);

                let moodWellBeingData = profileSettings ? profileSettings.moodWellBeing : null;
                let moodWellBeingModel = new MoodWellBeing(
                    profileSettings ? (moodWellBeingData ? (moodWellBeingData.mood ? moodWellBeingData.mood : "") : "") : "",
                    profileSettings ? (moodWellBeingData ? (moodWellBeingData.chronicIllness ? moodWellBeingData.chronicIllness : "") : "") : "",
                    profileSettings ? (moodWellBeingData ? (moodWellBeingData.pain ? moodWellBeingData.pain : "") : "") : ""
                );

                let covid19VaccinationData = profileSettings ? profileSettings.covid19vaccination : null;
                let covid19VaccinationModel = new Covid19Vaccination(
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.vaccinationstatus : "") : "",
                    profileSettings ? (covid19VaccinationData ? (covid19VaccinationData.vaccinationcard ? covid19VaccinationData.vaccinationcard : "") : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1manufacturer : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1expirationdate : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1lotnumber : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1date : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1facility : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2manufacturer : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2expirationdate : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2lotnumber : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2date : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2facility : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3manufacturer : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3expirationdate : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3lotnumber : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3date : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3facility : "") : ""
                );
                this.clinicalSummaryModel = new ClinicalSummary(
                    personalInformationModel,
                    contactInformationModel,
                    emergencycontactModel,
                    healthInsurancModel,
                    pastmedicalhistoryModel,
                    pastsurgicalhistoryModel,
                    womenhealthhistoryModel,
                    medicationallergiesModel,
                    foodallergiesModel,
                    environmentalallergiesModel,
                    socialhistoryModel,
                    // allergiesModel,
                    vaccinesModel,
                    familyhistoryDataModel,
                    prescriptionsModel,
                    moodWellBeingModel,
                    covid19VaccinationModel,
                    profileSettings.moodassessment,
                    profileSettings.stressassessment,
                    profileSettings.sleepassessment
                );
                this.setUpHeader();
                if (this.clinicalSummaryModel.personalinformation.photoid) {
                    this.getPhotoIDImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imagefront) {
                    this.getFrontImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imageback) {
                    this.getBackImageURL();
                }
                if (this.clinicalSummaryModel.covid19vaccination.vaccinationcard) {
                    this.getVaccinationImageURL();
                }
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    setUpHeader() {
        this.clinicalSummaryModel.phq9 = this.clinicalSummaryModel.phq9 || [];
        this.clinicalSummaryModel.gad7 = this.clinicalSummaryModel.gad7 || [];
        if (this.clinicalSummaryModel.phq9.phq9 != undefined && this.clinicalSummaryModel.phq9.phq9.length > 0) {
            this.clinicalSummaryModel.phq9.phq9.at(-1).details.forEach((element) => {
                this.phq9score += element.score;
                if (this.phq9score >= 5 && this.phq9score <= 9) {
                    this.phq9Severity = "Mild";
                } else if (this.phq9score >= 10 && this.phq9score <= 14) {
                    this.phq9Severity = "Moderate";
                } else if (this.phq9score >= 15 && this.phq9score <= 19) {
                    this.phq9Severity = "Moderately severe";
                } else if (this.phq9score >= 20 && this.phq9score <= 27) {
                    this.phq9Severity = "Severe";
                }
            });
            this.phq9Date = this.clinicalSummaryModel.phq9.phq9.at(-1).date;
        }
        if (this.clinicalSummaryModel.gad7.gad7 != undefined && this.clinicalSummaryModel.gad7.gad7.length > 0) {
            this.clinicalSummaryModel.gad7.gad7.at(-1).details.forEach((element) => {
                this.gad7Score += element.score;
                if (this.gad7Score >= 5 && this.gad7Score <= 9) {
                    this.gad7Severity = "Mild";
                } else if (this.gad7Score >= 10 && this.gad7Score <= 14) {
                    this.gad7Severity = "Moderate";
                } else if (this.gad7Score >= 15) {
                    this.gad7Severity = "Severe";
                }
            });
            this.gad7Date = this.clinicalSummaryModel.gad7.gad7.at(-1).date;
        }

        if (this.clinicalSummaryModel.sleepassessment?.sleepAssessment != undefined && this.clinicalSummaryModel.sleepassessment.sleepAssessment.length > 0) {
            this.clinicalSummaryModel.sleepassessment.sleepAssessment.at(-1).details.forEach((element) => {
                this.sleepScore += element.score;
                if (this.sleepScore >= 5 && this.sleepScore <= 9) {
                    this.sleepSeverity = "Mild";
                } else if (this.sleepScore >= 10 && this.sleepScore <= 14) {
                    this.sleepSeverity = "Moderate";
                } else if (this.sleepScore >= 15) {
                    this.sleepSeverity = "Severe";
                }
            });
            this.sleepDate = this.clinicalSummaryModel.sleepassessment.sleepAssessment.at(-1).date;
        }


        this.clinicalSummaryModel;

        let summaryDOB = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.dob !== ""
                    ? this.clinicalSummaryModel.personalinformation.dob
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.dob = summaryDOB ? summaryDOB : this.userAttributes.dob;
        let summaryFirstName = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.firstname !== ""
                    ? this.clinicalSummaryModel.personalinformation.firstname
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.firstname = summaryFirstName ? summaryFirstName : this.userAttributes.firstname;
        let summaryLastName = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.lastname !== ""
                    ? this.clinicalSummaryModel.personalinformation.lastname
                    : null
                : null
            : null;
            
        this.clinicalSummaryModel.personalinformation.lastname = summaryLastName ? summaryLastName : this.userAttributes.lastname;
        
        let summaryMaidenName = this.clinicalSummaryModel
        ? this.clinicalSummaryModel.personalinformation
            ? this.clinicalSummaryModel.personalinformation.maidenname !== ""
                ? this.clinicalSummaryModel.personalinformation.maidenname
                : null
            : null
        : null;
        this.clinicalSummaryModel.personalinformation.maidenname = summaryMaidenName ? summaryMaidenName : this.userAttributes.maidenname;
            
        
        let summaryGender = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.gender !== ""
                    ? this.clinicalSummaryModel.personalinformation.gender
                    : null
                : null
            : null;
            
        this.gender = summaryGender ? summaryGender : this.userAttributes.gender;

        let summaryMRN = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.mrn !== ""
                    ? this.clinicalSummaryModel.personalinformation.mrn
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.mrn = summaryMRN ? summaryMRN : this.userAttributes.mrn;
        this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname =
            !this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname || this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname == ""
                ? this.clinicalSummaryModel.personalinformation.firstname
                : this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname;
        this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname =
            !this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname || this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname == ""
                ? this.clinicalSummaryModel.personalinformation.lastname
                : this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname;
    }

    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }

    public onCategorySelection(type) {
        this.summaryData = {
            action: "edit",
            categoryType: type,
            patientId: this.patientId,
            summary: this.clinicalSummaryModel,
        };
        const modalRef = this.modalService.open(phq9ModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.fromClinicalPage = true;
        modalRef.componentInstance.AssessmentType = type;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.viewSummaryModalResponse(receivedEntry);
        });
    }

}
