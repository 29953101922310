<div class="container-fluid">
    <div class="tab">
        <a class="tablinks active" [routerLink]="['/media/activity']">
            <img src="/assets/msx/social-icon-white.png">Social Media Activity
        </a>
        <a class="tablinks" [routerLink]="['/media/reviews']">
            <i class="fa-solid fa-reply"></i> Respond to Reviews
        </a>
    </div>




    <div>
        <button (click)="addNew()" class="btn btn-primary ml-1 float-right">Add New</button>
        <table *ngIf="!showLoader" class="table-striped datatable">
            <thead>
                <tr>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                        sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of resultList$ | async">
                    <td>{{ r.last_review_date| date: "MM/dd/yyyy . hh:mm a" }}</td>
                    <td class="font-600">{{ r.practice_name }}
                    </td>
                    <td>{{ r.location_name }}</td>
                    <td class="font-600">{{ r.total_reviews }}</td>
                    <td class="font-600">{{ r.negative_reviews }}</td>
                    <td class="cursor-pointer">
                        <span data-html="true" class="badge hover" [ngClass]="getClass(r.avg_score)">
                            {{r.avg_score }}
                            <div class="tooltip">
                                <div *ngFor="let a of r.socialAverages">
                                    <div class="d-flex justify-content-between">
                                        <label style="text-transform: capitalize;">
                                            {{a.socialmediatype}}<i class="fa-solid fa-arrow-up-right-from-square"></i>

                                            <!-- <a *ngIf="a.socialmedia=='facebook'"
                                                href="https://www.facebook.com/profile.php?id=61556460532120">
                                                <i class="fa fa-external-link" aria-hidden="true"></i>
                                            </a>
                                            <a *ngIf="a.socialmedia=='yelp'"
                                                href="https://www.yelp.com/biz/mangrove-property-management-north-palm-beach-3">
                                                <i class="fa fa-external-link" aria-hidden="true"></i>
                                            </a> -->
                                        </label>
                                        <div class="text-right">{{a.avg}}</div>
                                    </div>

                                </div>
                            </div>
                        </span>

                    </td>
                    <td class="cursor-pointer">
                        <i (click)="onEdit(r)" class="fa fa-edit"></i>
                        <!-- <i (click)="onDelete(r.smid)" class="fa fa-trash ml-2"></i> -->
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

            <select class="custom-select pagination"
                style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                [(ngModel)]="tableService.pageSize">
                <option [ngValue]="5">5 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div> -->
        <app-loader *ngIf="showLoader" style="display: flex;align-items: center;justify-content: center;"></app-loader>

    </div>
</div>