<div class="modal-body">
    <div class="category-header">
        <label>Health Insurance Information</label>
    </div>
    <form class="modal-body-form" [formGroup]="insuranceForm">
        <div class="main-row">
            <div class="main-left">
                <div class="form-group">
                    <label>Cardholder First Name</label>
                    <input maxlength="500" [ngClass]="{ error: isFieldValid('cardholderfirstname') }"
                        formControlName="cardholderfirstname" type="text" required class="form-control" />
                    <div class="inp-err"
                        *ngIf="insuranceForm.controls['cardholderfirstname'].errors && insuranceForm.controls['cardholderfirstname'].touched">
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['cardholderfirstname'].hasError('required') || insuranceForm.controls['cardholderfirstname'].hasError('ValidateWhiteSpace')">
                            Cardholder First Name is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['cardholderfirstname'].hasError('maxlength') && !insuranceForm.controls['cardholderfirstname'].hasError('required')">
                            Cardholder First Name is can be max 500 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                insuranceForm.controls['cardholderfirstname'].hasError('pattern') &&
                                !insuranceForm.controls['cardholderfirstname'].hasError('maxlength') &&
                                !insuranceForm.controls['cardholderfirstname'].hasError('required')
                            ">
                            Cardholder First Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Cardholder Last Name</label>
                    <input maxlength="500" [ngClass]="{ error: isFieldValid('cardholderlastname') }"
                        formControlName="cardholderlastname" type="text" required class="form-control" />
                    <div class="inp-err"
                        *ngIf="insuranceForm.controls['cardholderlastname'].errors && insuranceForm.controls['cardholderlastname'].touched">
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['cardholderlastname'].hasError('required') || insuranceForm.controls['cardholderlastname'].hasError('ValidateWhiteSpace')">
                            Cardholder Last Name is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['cardholderlastname'].hasError('maxlength') && !insuranceForm.controls['cardholderlastname'].hasError('required')">
                            Cardholder Last Name is can be max 500 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                insuranceForm.controls['cardholderlastname'].hasError('pattern') &&
                                !insuranceForm.controls['cardholderlastname'].hasError('maxlength') &&
                                !insuranceForm.controls['cardholderlastname'].hasError('required')
                            ">
                            Cardholder Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>ID Number</label>
                    <input maxlength="20" [ngClass]="{ error: isFieldValid('idnumber') }" formControlName="idnumber"
                        type="text" required class="form-control" />
                    <div class="inp-err"
                        *ngIf="insuranceForm.controls['idnumber'].errors && insuranceForm.controls['idnumber'].touched">
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['idnumber'].hasError('required') || insuranceForm.controls['idnumber'].hasError('ValidateWhiteSpace')">
                            ID Number is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['idnumber'].hasError('maxlength') && !insuranceForm.controls['idnumber'].hasError('required')">
                            ID Number is can be max 20 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                insuranceForm.controls['idnumber'].hasError('pattern') &&
                                !insuranceForm.controls['idnumber'].hasError('maxlength') &&
                                !insuranceForm.controls['idnumber'].hasError('required')
                            ">
                            ID Number can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Group Number</label>
                    <input maxlength="20" [ngClass]="{ error: isFieldValid('groupnumber') }"
                        formControlName="groupnumber" type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="insuranceForm.controls['groupnumber'].errors && insuranceForm.controls['groupnumber'].touched">
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['groupnumber'].hasError('required') || insuranceForm.controls['groupnumber'].hasError('ValidateWhiteSpace')">
                            Group Number is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['groupnumber'].hasError('maxlength') && !insuranceForm.controls['groupnumber'].hasError('required')">
                            Group Number is can be max 20 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                insuranceForm.controls['groupnumber'].hasError('pattern') &&
                                !insuranceForm.controls['groupnumber'].hasError('maxlength') &&
                                !insuranceForm.controls['groupnumber'].hasError('required')
                            ">
                            Group Number can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Health Coverage Provider</label>
                    <select [ngClass]="{ error: isFieldValid('companyname') }" formControlName="companyname"
                        class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option *ngFor="let provider of providers" [value]="provider.healthcareid">{{ provider.payername
                            }}</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="insuranceForm.controls['companyname'].errors && insuranceForm.controls['companyname'].touched">
                        <span class="error-msg"
                            *ngIf="insuranceForm.controls['companyname'].hasError('required') || insuranceForm.controls['companyname'].hasError('ValidateWhiteSpace')">
                            Health Coverage Provider is required.
                        </span>
                    </div>
                </div>
            </div>
            <div class="main-right image-section">
                <div style="position: relative;" class="form-group">
                    <i (click)="showImage(imageURLFront ? imageURLFront : '../../../assets/images/add-image-health.png')"
                        class="fa fa-search graycolor showimage"></i>
                    <label>Health Insurance Card Front</label>
                    <div class="image-block">
                        <div class="imagePreview">
                            <input type="file" accept="image/*" (change)="showPreviewFront($event)" />
                            <button [ngClass]="isInvalidFrontImage ? 'uploadbtnDirty' : 'uploadbtn'">
                                <img class="photoid-image"
                                    [src]="imageURLFront ? imageURLFront : '../../../assets/images/add-image-health.png'" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">

                    <label>Health Insurance Card Back</label>
                    <div style="position: relative;" class="image-block">
                        <i (click)="showImage(imageURLBack ? imageURLBack : '../../../assets/images/add-image-health.png')"
                            class="fa fa-search graycolor showCardBackimage"></i>
                        <div class="imagePreview">
                            <input type="file" accept="image/*" (change)="showPreviewBack($event)" />
                            <button [ngClass]="isInvalidBackImage ? 'uploadbtnDirty' : 'uploadbtn'">
                                <img class="photoid-image"
                                    [src]="imageURLBack ? imageURLBack : '../../../assets/images/add-image-health.png'" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <a (click)="onViewCoveredServices($event)" class="insurance-covered-services">List of Covered
                        Services</a>
                </div>
            </div>
        </div>
    </form>
    <div class="main-row">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>
    </div>
</div>