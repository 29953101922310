import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServiceRecoveryModal } from "../MSX/modals/service-recovery-modal/service-recovery-modal.component";
import { ChatModalComponent } from "../../modals/chat-modal/chat-modal.component";
export interface SearchElements {
  username: string;
  status: string;
  chiefcomplaint: string;
  refferedtelerxname: string;
}

@Component({
  selector: "service-recovery",
  templateUrl: "./service-recovery.component.html",
  styleUrls: ["./service-recovery.component.scss"],
})
export class ServiceRecoveryComponent implements OnInit {
  public patientData: any = { patient: {} };
  public patientReferData: any = { patient: {} };
  public viewOptionsData: any = {};
  public notesData: any = {};
  displayedColumns: string[];
  viewOptions: any;
  resultList$: Observable<any[]>;
  total$: Observable<number>;
  showLoader = true;
  pageLoad = true;
  resultsLength = 30
  pageSize = 10
  pageIndex = 1
  searchTimeout: any = null;
  searchTerm = "";
  status = "0";
  column = "commentdate";
  direction = "desc";
  escalate_keywords = {
    scores: "7",
    keyword: "all"
  }
  sentiments: string = "";
  allResult = true;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  constructor(
    public tableService: TableService,
    public waitingRoomService: WaitingRoomService,
    private modalService: NgbModal
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 10;
    this.tableService.searchTerm = "";
  }

  showAllResult = false;
  ngOnInit() {
    //this.openServiceModal({})
    this.setUpUI();
    //this.getServiceList();

    this.showAllResult = true;
    this.getServiceList()
  }

  public setUpUI() {
    this.viewOptions = {
      pageid: "",
      columns: [
        {
          name: "commentdate",
          display: "Date",
          show: false,
          order: 0,
        },
        {
          name: "status",
          display: "Status",
          show: false,
          order: 1,
        },
        {
          name: "lowestscore",
          display: "Lowest Score",
          show: false,
          order: 2,
        },
        {
          name: "providerfullname",
          display: "Provider",
          show: false,
          order: 3,
        },
        {
          name: "locationname",
          display: "Location",
          show: false,
          order: 4,
        },
        {
          name: "practicecomment",
          display: "Practice Comment",
          show: false,
          order: 9,
        },
        {
          name: "providercomment",
          display: "Provider Comment",
          show: false,
          order: 10,
        },
        {
          name: "actions",
          display: "Actions",
          show: false,
          order: 11,
        },
      ],
    };
    this.displayedColumns = ["commentdate", "status", "lowestscore", "providerfullname", "locationname", "practicecomment", "providercomment", "actions"];

  }

  getServiceList() {
    let keywords = JSON.parse(localStorage.getItem("escalate-keywords"))
    let lowestscore = 7;
    let escalateKeywords = "";
    let escalateSentiments = "";
    if (keywords !== null) {
      if (!keywords.escalate_keywords && !keywords.escalate_scores && !keywords.escalate_sentiments) {
        this.showAllResult = true;
        this.allResult = true;
      }

      else {
        if (this.showAllResult) {
          this.sentiments = "";
        }
        else {
          this.sentiments = keywords.sentiments.join(", ");
        }
        this.escalate_keywords = keywords;
        if (this.escalate_keywords.keyword === "") {
          this.escalate_keywords.keyword = "";
        }
        if (!keywords.escalate_scores) {
          this.escalate_keywords.scores = "10";
          lowestscore = 10;
        }
        else {
          lowestscore = parseInt(keywords.scores);
        }
        if (!keywords.escalate_keywords) {
          escalateKeywords = "";
          this.escalate_keywords.keyword = "all";
        }
        else {
          escalateKeywords = keywords.keyword;
        }
        if (!keywords.escalate_sentiments) {
          escalateSentiments = ""
          this.sentiments = ""
        }
        else if (keywords.sentiments.length > 0) {
          escalateSentiments = keywords.sentiments.toString();
        }

      }
    }

    this.showLoader = true;
    setTimeout(() => {
      if (this.showAllResult) {
        lowestscore = 10;
        escalateKeywords = ""
      }
      return this.waitingRoomService.getServiceRecoveryList(this.pageIndex, this.pageSize, this.status, this.searchTerm, this.column, this.direction, lowestscore, this.showAllResult ? "" : escalateKeywords, this.showAllResult ? "" : escalateSentiments).subscribe(
        (data) => {
          data[0].attributes.data.list.forEach(element => {
            if (element.comments[0] && element.comments[0].name === "Practice Comment") {
              element.practicecomment = element.comments[0].comment;
            }
            if (element.comments[0] && element.comments[0].name === "Provider Comment") {
              element.providercomment = element.comments[0].comment;
            }
            if (element.comments[1] && element.comments[1].name === "Practice Comment") {
              element.practicecomment = element.comments[1].comment;
            }
            if (element.comments[1] && element.comments[1].name === "Provider Comment") {
              element.providercomment = element.comments[1].comment;
            }


          });
          this.tableService.DATA = data[0].attributes.data.list;
          this.tableService.pageSize = this.pageSize;
          this.resultsLength = data[0].attributes.data.totalCount;
          if (this.pageLoad) {
            this.sorting("commentdate", "desc");
            this.pageLoad = false;
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
          //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
        }
      );
    }, 1000);

  }

  displayName(name) {
    return this.viewOptions.columns.find((o) => o.name === name).display;
  }

  sorting(column, direction, serverSideSorting = true) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    // this.tableService.sortColumn = column;
    // this.tableService.sortDirection = direction;
    this.tableService.searchOnColumn = "";
    this.tableService.page = 1;
  }
  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    if (column === "commentdate") {
      this.column = "commentdate"
    }
    if (column === "lowestscore") {
      this.column = "score"
    }
    if (column === "providerfullname") {
      this.column = "provider"
    }
    if (column === "locationname") {
      this.column = "location"
    }




    this.direction = direction;
    this.getServiceList();
    this.sorting(column, direction);

  }
  openServiceModal(servicerecoveryData: any, size = "") {
    let modalRef;
    if (size == "sm") {
      modalRef = this.modalService.open(ServiceRecoveryModal, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
    }
    else {
      modalRef = this.modalService.open(ServiceRecoveryModal, { size: "lg", windowClass: "modal-medium", backdrop: "static" });
    }
    modalRef.componentInstance.servicerecoveryData = servicerecoveryData;
    modalRef.componentInstance.size = size;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.getServiceList();

    });
  }

  public onViewPatient(p: any) {
    p.patientid = p.clio && p.clio.userid ? p.clio.userid : null;
    p.careteamid = p.clio && p.clio.careteamid ? p.clio.careteamid : null;
    const modalRef = this.modalService.open(ChatModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
    modalRef.componentInstance.currentPatient = p;
    modalRef.componentInstance.patients = [];
    modalRef.componentInstance.Response.subscribe((receivedEntry) => { });
  }

  setPageSize() {
    this.getServiceList();
  }

  public onPagination(page: any) {
    this.pageIndex = page;
    this.getServiceList();
  }
  public filterByStstus(status = "0") {
    this.column = "commentdate";
    this.direction = "desc";
    this.pageIndex = 1;
    this.status = status;
    this.getServiceList();
  }

  public search() {
    // if (this.searchTerm.trim() !== "") {
    this.column = "commentdate";
    this.direction = "desc";
    this.pageIndex = 1
    this.getServiceList();
    // }
  }

  public clear() {
    this.searchTerm = "";
    this.status = "0";
    this.column = "commentdate";
    this.direction = "desc";
    this.pageIndex = 1
    this.getServiceList();
  }

  public trimValue() {
    if (this.searchTerm.trim() == "") {
      this.searchTerm = "";
    }
  }

  public convertoUStime(date) {
    if (date) {
      var myDate = new Date(date);
      var timezone_delay = -myDate.getTimezoneOffset() * 60 * 1000;
      return new Date(myDate.getTime() + timezone_delay);
    }
  }

  public showAll(event) {

    if (event.target.checked) {
      this.showAllResult = true;
      this.getServiceList()
    }
    else {
      this.showAllResult = false;
      this.getServiceList()
    }
  }

  public getSortString(val) {
    if (val !== undefined) {
      return val.substring(0, 100) + "..."
    }
  }
}
