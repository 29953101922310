import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UsersService } from "../../services/users.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OnboardingService } from "../../services/onboarding.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-password-reset-modal",
    templateUrl: "./password-reset-modal.component.html",
    styleUrls: ["./password-reset-modal.component.scss"],
})
export class PasswordResetModalComponent {
    constructor(private userService: UsersService, private sessionService: SessionService, private onboardingService: OnboardingService, private activeModal: NgbActiveModal) {}

    @Input() UserEmail;
    @Input() UserRole;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    public sendResetLink() {
        if (this.UserRole === "user" || !this.sessionService.isAdmin()) {
            this.sendOnboardingEmail();
        } else {
            this.passwordReset();
        }
    }

    public passwordReset() {
        this.userService.sendReset(this.UserEmail).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Password reset email sent" });
                this.activeModal.close();
            },
            (err) => {
                this.Response.emit({ success: false, message: err });
                this.activeModal.close();
            }
        );
    }
    public sendOnboardingEmail() {
        this.onboardingService.onboarding(this.UserEmail).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Onboarding email sent" });
                this.activeModal.close();
            },
            (err) => {
                this.Response.emit({ success: false, message: err });
                this.activeModal.close();
            }
        );
    }

    public onCancel() {
        this.activeModal.close();
    }
}
