import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "waiting-room-move-modal",
    templateUrl: "./waiting-room-move-modal.component.html",
    styleUrls: ["./waiting-room-move-modal.component.scss"],
})
export class WaitingRoomMoveModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private snackbar: SnackbarService, private waitingRoomService: WaitingRoomService) {}

    @Input() PatientData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    error = "";
    isPatientDashboard: boolean = false;
    moveToForm: UntypedFormGroup;
    ngOnInit() {
        this.isPatientDashboard = this.PatientData.page === "PatientDashboard" ? true : false;
        this.moveToForm = this.fb.group(
            {
                wruid: [this.PatientData.wruid],
                fromwrid: [this.PatientData.fromwrid],
                towrid: ["", Validators.required],
                notes: [""],
                status: "active",
            },
            { updateOn: "blur" }
        );
    }
    isFieldValid(key) {
        return this.moveToForm.get(key).invalid && this.moveToForm.get(key).touched;
    }

    public onSave() {
        if (this.moveToForm.valid) {
            return this.waitingRoomService.movePatientToWaitingRoom(this.moveToForm.value).subscribe(
                (data) => {
                    this.Response.emit({ success: true, message: "Member moved successfully", towrid: this.moveToForm.get("towrid").value, wruid: this.moveToForm.get("wruid").value, action: "move" });
                    this.activeModal.close();
                },
                (err) => {
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            this.moveToForm.markAllAsTouched();
            this.error = "Please provide mandatory fields.";
        }
    }

    public onResolve() {
        let resolvedData = {
            wruid: this.PatientData.wruid,
            fromwrid: this.PatientData.fromwrid,
            towrid: this.PatientData.fromwrid,
            notes: "",
            status: "closed",
        };
        return this.waitingRoomService.movePatientToWaitingRoom(resolvedData).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Member resolved successfully", towrid: this.moveToForm.get("towrid").value, action: "resolved" });
                this.activeModal.close();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public onRefer() {
        this.Response.emit({
            success: true,
            message: "Member moved successfully",
            towrid: this.moveToForm.get("towrid").value,
            wruid: this.moveToForm.get("wruid").value,
            action: "refer",
            patientId: this.PatientData.patientId,
        });
        this.activeModal.close();
    }

    public onCancel() {
        this.activeModal.close();
    }
}
