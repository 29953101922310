import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { PracticeService } from "../../services/practice.service";
import { SessionService } from "../../services/session.service";
import { SpecialtyService } from "../../services/specialty.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { HttpClient } from "@angular/common/http";
@Component({
    selector: "manage-providers-modal",
    templateUrl: "./manage-providers-modal.component.html",
    styleUrls: ["./manage-providers-modal.component.css"],
})
export class ManageProvidersModalComponent implements OnInit {
    @Input() locationData = [];
    @Output() readonly Response = new EventEmitter<any>();
    constructor(
        private activeModal: NgbActiveModal,
        private snackbar: SnackbarService,
        private specialtyService: SpecialtyService,
        private sessionService: SessionService,
        private practiceService: PracticeService,
        private http: HttpClient
    ) {}
    ngOnInit(): void {
        this.getProvidersFromTelerx();
    }
    providers = [];
    selectedProviders = [];
    search = "";
    public onCancel() {
        this.activeModal.close();
    }
    public getStates(): Observable<any> {
        return this.http.get("../../../assets/states.json");
    }
    getProvidersFromTelerx() {
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;

        return this.specialtyService.getProvidersFromTelerx(telerxid).subscribe(
            (data) => {
                this.providers = data;
                this.providers.map((p) => {
                    p.selected = false;
                });
                if (this.locationData["providers"].length > 0) {
                    this.selectedProviders = this.locationData["providers"];

                    this.locationData["providers"].forEach((data) => {
                        this.providers.map((p) => {
                            if (p.userid === data.userid) {
                                p.selected = true;
                                return p;
                            }
                        });
                    });
                }

                // this.providers.sort((a, b) => b.selected - a.selected);
                this.providers.sort((a, b) => b.selected - a.selected || a.lastname.localeCompare(b.lastname) || a.firstname.localeCompare(b.firstname));
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    clearSearch() {
        this.search = "";
    }
    selectProvider(provider, event) {
        let _P = {
            userid: provider.userid,
            fullname: provider.fullname,
        };

        if (!this.selectedProviders.find((el) => el.userid === provider.userid)) {
            this.selectedProviders.push(_P);
            this.providers.map((p) => {
                if (p.userid === provider.userid) {
                    p.selected = true;
                }
            });
        } else {
            this.providers.map((p) => {
                if (p.userid === provider.userid) {
                    p.selected = false;
                }
            });
            this.selectedProviders = this.selectedProviders.filter((el) => el.userid !== provider.userid);
        }
    }

    save() {
        this.practiceService.save(this.locationData, this.selectedProviders, true, this.locationData["ptid"]).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Provider updated to location successfully." });
                this.activeModal.close();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
}
