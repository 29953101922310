<div class="container-fluid">
    <div class="row">
        <hr style="width: 100%; border: solid 1px #e2e2e2" />
        <div class="col-12">
            <h4>{{ !this.isNew ? "Edit" : "Add" }} Member</h4>
            <div class="row">
                <div class="col col-6">
                    <div class="form-group">
                        <label>Email:</label>
                        <input type="text" class="form-control" [(ngModel)]="userData.email" [disabled]="!this.isNew" />
                    </div>
                </div>
                <div class="col col-6">
                    <div class="form-group">
                        <label>MRN:</label>
                        <input type="text" class="form-control" [(ngModel)]="userSettings.mrn" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="showTitle()" class="col col-2">
                    <div class="form-group">
                        <label>Title:</label>
                        <input type="text" class="form-control" [(ngModel)]="userSettings.title" />
                    </div>
                </div>
                <div class="col" [ngClass]="showTitle() ? 'col-4' : 'col-6'">
                    <div class="form-group">
                        <label>First Name:</label>
                        <input type="text" class="form-control" [(ngModel)]="userSettings.firstname" />
                    </div>
                </div>
                <div class="col col-6">
                    <div class="form-group">
                        <label>Last Name:</label>
                        <input type="text" class="form-control" [(ngModel)]="userSettings.lastname" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-6">
                    <div class="form-group">
                        <label>Maiden Name:</label>
                        <input type="text" class="form-control" [(ngModel)]="userSettings.maidenname" />
                    </div>
                </div>

                <div class="col col-6">
                    <div class="form-group">
                        <label>Gender:</label>
                        <select class="selectpicker form-control custom-select" [(ngModel)]="userSettings.gender">
                            <option value="" disabled>Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-6">
                    <div class="form-group">
                        <label>Date of Birth:</label>
                        <input (blur)="checkDateValidity($event)" type="date" [max]="minimumAge()" class="form-control"
                            [value]="userSettings.dob | date:'yyyy-MM-dd'" />
                    </div>
                    <small *ngIf="futureDateError" style="color: red !important" class="form-text text-muted">The
                        patient must be 13 years old. </small>
                </div>
                <div class="col col-6">
                    <div class="form-group">
                        <label>Phone Number:</label>
                        <input type="text" class="form-control" maxlength="70" [(ngModel)]="userData.phonenumber" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-6">
                    <div class="form-group">
                        <label>ZIP Code:</label>
                        <input type="number" class="form-control" [(ngModel)]="userSettings.postalcode" />
                    </div>
                </div>
                <div class="col col-6">
                    <div class="form-group">
                        <label>Employer:</label>
                        <select [(ngModel)]="userSettings.employer" class="form-control custom-select">
                            <option *ngFor="let e of employers" [value]="e.employername">{{ e.employername }}</option>
                        </select>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col col-6">
                    <div class="form-group">
                        <label>Height:</label>
                        <input type="number" placeholder="inches" (change)="updateBMI()" class="form-control"
                            [(ngModel)]="userSettings.height" />
                    </div>
                </div>
                <div class="col col-6">
                    <div class="form-group">
                        <label>Weight:</label>
                        <input type="number" placeholder="Ibs" (change)="updateBMI()" class="form-control"
                            [(ngModel)]="userSettings.weight" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-6">
                    <div class="form-group">
                        <label>BMI:</label>
                        <input disabled type="number" class="form-control" [(ngModel)]="userSettings.bmi" />
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <div style="display: none" class="col col-4">
                    <div class="form-group">
                        <label>Card Number:</label>
                        <input maxlength="10" (keypress)="blockSpecialChar($event)" type="text" class="form-control"
                            [(ngModel)]="userSettings.cardnumber" />
                    </div>
                    <div class="form-group" *ngIf="sessionService.isAdmin() || sessionService.isProviderAdmin()">
                        <label>Role:</label>
                        <select class="selectpicker form-control custom-select" [(ngModel)]="patientRole"
                            (change)="onRoleChange($event)">
                            <option *ngFor="let role of roles" [value]="role.id">{{ role.displayname }}</option>
                        </select>
                    </div>
                </div>
                <div class="col col-6">
                    <div class="form-group"
                        *ngIf="(sessionService.isAdmin() || sessionService.isProviderAdmin()) && getRoleById(this.patientRole) !== 'admin'">
                        <label>Care Team:</label>
                        <checkbox-group [options]="careteams" [multiSelect]="getRoleById(this.patientRole) !== 'user'"
                            (toggle)="onCareTeamChange($event)" class="checkbox-group"></checkbox-group>
                    </div>
                </div>
                <div class="col col-6">
                    <div style="display:flex; float: right;">
                        <button type="button" class="btn btn-secondary" (click)="onBack()">Back</button>
                        <button [disabled]="futureDateError" type="button" class="btn btn-primary"
                            (click)="onSaveProfile()">{{ !this.isNew ? "Update" : "Add" }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>