<div class="modal-body">
    <label class="refer-label">Refer</label>

    <ul [ngStyle]="sendToChat ? { border: '0px' } : ''" ngbNav #nav="ngbNav" class="nav-tabs" style="width: 100%">
        <li ngbNavItem *ngIf="enableSpeciality">
            <a *ngIf="!sendToChat" (click)="reset()" ngbNavLink>Speciality</a>
            <ng-template ngbNavContent>
                <label *ngIf="!showSpecialtiesList" style="width: 100%" class="text-center">{{ search }}</label>
                <div *ngIf="showSpecialtiesList" class="form-group has-search">
                    <label>Specialities</label>
                    <span *ngIf="showSpecialtiesList" class="fa fa-search form-control-feedback"></span>
                    <a *ngIf="!showSpecialtiesList" (click)="resetSpecialtiesList()">
                        <span class="fa fa-times form-control-feedback"></span>
                    </a>
                    <input (keydown)="onKeydown($event)" [(ngModel)]="search" type="text" class="form-control"
                        placeholder="Search" />
                </div>

                <div *ngIf="showSpecialtiesList" class="suggestions-container">
                    <ul>
                        <li *ngFor="let s of specialties | grdFilter: { name: search }:false" class="item">
                            <div>
                                <a [ngClass]="[s.spid === selectedSpeciality?.spid ? 'checked-item' : 'check-item']">
                                    <label class="checkbox-label" for="checkbox_{{ s.spid }}"><input
                                            class="input-checkbox display-none" name="physicians"
                                            id="checkbox_{{ s.spid }}" (click)="selectSpeciality(s)" type="radio" /> {{
                                        s.name }}
                                    </label>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showSpecialtiesList && !sendToChat" class="form-group has-search">
                    <label class="label">Health Insurance Company</label>
                    <input type="text" class="form-control" placeholder="Cigna Health" />
                </div>
                <div *ngIf="!showSpecialtiesList && !sendToChat" class="form-group has-search row">
                    <div class="col-9">
                        <label class="label">Distance</label>
                        <select class="form-control">
                            <option>Within 20 miles</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <label class="label">ZIP Code</label>
                        <input type="text" class="form-control" placeholder="17601" />
                    </div>
                </div>
                <div *ngIf="sendToChat" class="form-group has-search suggestions-container">
                    <p class="text-message" *ngIf="noTeleRXMessage">{{ noTeleRXMessage }}</p>
                    <ul>
                        <li *ngFor="let t of teleRxBySpeciality" class="item">
                            <div>
                                <a class="check-item">
                                    <label class="checkbox-label" for="checkbox_{{ t.telerxid }}"><input
                                            class="input-checkbox" id="checkbox_{{ t.telerxid }}"
                                            (click)="selectedTeleRx(t)" type="checkbox" /> {{ t.name }}
                                    </label>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                <div style="display: flex" class="mt-2 mb-2">
                    <button type="button" (click)="onBack()" class="btn btn-primary white-btn">Back</button>
                    <button type="button" (click)="onCancel()"
                        class="btn btn-primary white-btn ml-1 mr-1">Cancel</button>
                    <button *ngIf="showSpecialtiesList" [disabled]="!selectedSpeciality" type="button"
                        (click)="getTeleRxBySpeciality(false)" class="btn btn-primary">Continue</button>
                    <button *ngIf="!showSpecialtiesList && !sendToChat" [disabled]="search === ''" type="button"
                        (click)="onContinue()" class="btn btn-primary">Continue</button>
                    <button *ngIf="sendToChat" [disabled]="selectedTeleRxList.length === 0" type="button"
                        (click)="onSendToChat()" class="btn btn-primary">Send to Chat</button>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a *ngIf="!sendToChat" (click)="reset()" ngbNavLink>Physician</a>
            <ng-template ngbNavContent>
                <label *ngIf="!showSpecialtiesList && !sendToChat" style="width: 100%" class="text-center">{{ search
                    }}</label>
                <label *ngIf="sendToChat" style="width: 100%" class="heading-left">
                    <!-- {{ selectedPhysician?.firstname }} {{ selectedPhysician?.lastname }} <br /> -->
                    <span class="blue_label">Teleremedy</span><br />
                    {{ selectedTeleRX?.name }}
                    <br />
                    <!-- <span class="blue_label" style="margin-top: 10px">Patient</span><br />
                    {{ patientInfo.firstname }} {{ patientInfo.middlename }} {{ patientInfo.lastname }}
                    <br /> -->
                    <span class="blue_label" style="margin-top: 10px">Eligibility Check</span>
                    <div class="row" style="margin-top: 10px">
                        <div class="col col-8">
                            <div class="form-group has-search">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input [(ngModel)]="selectedProcedure" type="text" class="form-control"
                                    placeholder="Procedure name" />
                            </div>

                            <div class="suggestions-container">
                                <ul>
                                    <li *ngFor="let p of filteredProcedures | async | grdFilter: { name: selectedProcedure }:false"
                                        class="item">
                                        <div>
                                            <a
                                                [ngClass]="[p.code === control.value?.code ? 'checked-item' : 'check-item']">
                                                <label class="checkbox-label" for="checkbox_{{ p.code }}"><input
                                                        class="input-checkbox display-none" name="specialities"
                                                        id="checkbox_{{ p.code }}" (click)="setProcedure(p)"
                                                        type="radio" />
                                                    {{ p.name }}
                                                </label>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col col-4">
                            <button *ngIf="!elQuerySuccess" type="button" (click)="checkEligibility()"
                                class="btn btn-primary">Check</button>
                        </div>
                    </div>
                    <div class="elResponseDiv">
                        <span *ngIf="elQuerySuccess">
                            This patient is
                            <span class="eligible" *ngIf="eligible">ELIGIBLE</span>
                            <span class="not_eligible" *ngIf="!eligible">NOT ELIGIBLE</span>
                            for {{ procedure }}.
                        </span>
                        <span *ngIf="elQueryError" style="color: red"><i class="fas fa-exclamation-triangle"></i> {{
                            elQueryErrorMessage }}</span>
                        <div class="spinner-border text-primary" role="status" *ngIf="elQueryLoading">
                            <span class="sr-only"></span>
                        </div>
                        <div *ngIf="elQueryLoading" class="checkingText">Checking for eligibility...</div>
                    </div>
                    <!-- {{ search }} -->
                </label>
                <div *ngIf="showSpecialtiesList" class="form-group has-search">
                    <label>Specialities</label>
                    <span *ngIf="showSpecialtiesList" class="fa fa-search form-control-feedback"></span>
                    <a *ngIf="!showSpecialtiesList" (click)="resetSpecialtiesList()">
                        <span class="fa fa-times form-control-feedback"></span>
                    </a>
                    <input [(ngModel)]="search" type="text" class="form-control" placeholder="Search" />
                </div>

                <div *ngIf="showSpecialtiesList" class="suggestions-container">
                    <ul>
                        <li *ngFor="let s of specialties | grdFilter: { name: search }:false" class="item">
                            <div>
                                <div>
                                    <a
                                        [ngClass]="[s.spid === selectedSpeciality?.spid ? 'checked-item' : 'check-item']">
                                        <label class="checkbox-label" for="checkbox_{{ s.spid }}"><input
                                                class="input-checkbox display-none" name="physicians"
                                                id="checkbox_{{ s.spid }}" (click)="selectSpeciality(s)" type="radio" />
                                            {{ s.name }}
                                        </label>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showSpecialtiesList && !sendToChat" class="form-group has-search suggestions-container">
                    <p class="text-message" *ngIf="noTeleRXMessage">{{ noTeleRXMessage }}</p>
                    <ul>
                        <!-- <li *ngFor="let p of providers" class="item">
                            <div>
                                <a [ngClass]="[p.userid === selectedPhysician?.userid ? 'checked-item' : 'check-item']">
                                    <label class="checkbox-label" for="checkbox_{{ p.userid }}"
                                        ><input class="input-checkbox display-none" name="physicians" id="checkbox_{{ p.userid }}" (click)="selectPhysician(p)" type="radio" />
                                        {{ p.telerxname }}
                                    </label>
                                </a>
                            </div>
                        </li> -->
                        <li *ngFor="let t of teleRxBySpeciality" class="item">
                            <div>
                                <a
                                    [ngClass]="[t.telerxid === selectedTeleRX?.telerxid ? 'checked-item' : 'check-item']">
                                    <label class="checkbox-label" for="checkbox_{{ t.telerxid }}"><input
                                            class="input-checkbox display-none" name="telerxs"
                                            id="checkbox_{{ t.telerxid }}" (click)="selectTeleRXInPhysician(t)"
                                            type="radio" /> {{ t.name }}
                                    </label>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="sendToChat" class="form-group has-search suggestions-container">
                    <p class="text-message" *ngIf="noTeleRXMessage">{{ noTeleRXMessage }}</p>
                    <div class="telerx-address">
                        <!-- Functionality To Show Telerx Address -->
                    </div>
                    <div class="form-group">
                        <textarea style="resize: none" placeholder="Notes" [(ngModel)]="notes"
                            [ngClass]="{ error: isFieldValid('notes') }" type="text" class="form-control"></textarea>
                    </div>
                </div>
                <div style="display: flex" class="mt-2 mb-2">
                    <button type="button" (click)="onBack()" class="btn btn-primary white-btn">Back</button>
                    <button type="button" (click)="onCancel()"
                        class="btn btn-primary white-btn ml-1 mr-1">Cancel</button>
                    <button *ngIf="showSpecialtiesList" [disabled]="!selectedSpeciality" type="button"
                        (click)="getTeleRxBySpeciality(true)" class="btn btn-primary">Continue</button>
                    <!-- <button *ngIf="showSpecialtiesList" [disabled]="!selectedSpeciality" type="button" (click)="getProviderBySpeciality()" class="btn btn-primary">Continue</button> -->
                    <button *ngIf="!showSpecialtiesList && !sendToChat" [disabled]="!selectedTeleRX" type="button"
                        (click)="openNotesScreenPhysician()" class="btn btn-primary">Continue</button>
                    <!-- <button *ngIf="sendToChat" [disabled]="notes.trim() === ''" type="button" (click)="onRefer(selectedPhysician.userid, selectedPhysician.membershipid)" class="btn btn-primary">
                        Refer
                    </button> -->
                    <button *ngIf="sendToChat" type="button" (click)="onRefer('', selectedTeleRX.membershipid)"
                        class="btn btn-primary">Refer</button>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a *ngIf="!sendToChat" (click)="reset()" ngbNavLink>Recent</a>
            <ng-template ngbNavContent>
                <label *ngIf="!showSpecialtiesList && !sendToChat" style="width: 100%" class="text-center">{{ search
                    }}</label>
                <label *ngIf="sendToChat" style="width: 100%; font-weight: 600" class="heading-left">{{
                    selectedTeleRX?.name }}
                    <!-- <br />
                    {{ search }} -->
                </label>

                <div *ngIf="showSpecialtiesList" class="form-group has-search">
                    <label>Specialities</label>
                    <span *ngIf="showSpecialtiesList" class="fa fa-search form-control-feedback"></span>
                    <a *ngIf="!showSpecialtiesList" (click)="resetSpecialtiesList()">
                        <span class="fa fa-times form-control-feedback"></span>
                    </a>
                    <input [(ngModel)]="search" type="text" class="form-control" placeholder="Search" />
                </div>

                <div *ngIf="!showReferral && !sendToChat" class="suggestions-container">
                    <ul>
                        <li *ngFor="let s of specialties | grdFilter: { name: search }:false" class="item">
                            <div>
                                <a [ngClass]="[s.spid === selectedSpeciality?.spid ? 'checked-item' : 'check-item']">
                                    <label class="checkbox-label" for="checkbox_{{ s.spid }}"><input
                                            class="input-checkbox display-none" name="specialities"
                                            id="checkbox_{{ s.spid }}" (click)="selectSpeciality(s)" type="radio" /> {{
                                        s.name }}
                                    </label>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="showReferral" class="form-group has-search suggestions-container">
                    <p class="text-message" *ngIf="noTeleRXMessage">{{ noTeleRXMessage }}</p>
                    <ul>
                        <li *ngFor="let t of recentReferral" class="item">
                            <div>
                                <a
                                    [ngClass]="[t.telerxid === selectedTeleRX?.telerxid ? 'checked-item' : 'check-item']">
                                    <label class="checkbox-label" for="checkbox_{{ t.telerxid }}"><input
                                            class="input-checkbox display-none" name="telerxs"
                                            id="checkbox_{{ t.telerxid }}" (click)="selectTeleRX(t)" type="radio" /> {{
                                        t.name }}
                                    </label>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="sendToChat" class="form-group has-search suggestions-container">
                    <p class="text-message" *ngIf="noTeleRXMessage">{{ noTeleRXMessage }}</p>
                    <div class="telerx-address">
                        <!-- Functionality To Show Telerx Address -->
                    </div>
                    <div class="form-group">
                        <textarea style="resize: none" placeholder="Notes" [(ngModel)]="notes"
                            [ngClass]="{ error: isFieldValid('notes') }" type="text" required
                            class="form-control"></textarea>
                    </div>
                </div>
                <div style="display: flex" class="mt-2 mb-2">
                    <button type="button" (click)="onBackRecent()" class="btn btn-primary white-btn">Back</button>
                    <button type="button" (click)="onCancel()"
                        class="btn btn-primary white-btn ml-1 mr-1">Cancel</button>
                    <button *ngIf="!showReferral && !sendToChat" [disabled]="!selectedSpeciality" type="button"
                        (click)="getRecentReferral()" class="btn btn-primary">Continue</button>
                    <button *ngIf="showReferral" [disabled]="!selectedTeleRX" type="button" (click)="openNotesScreen()"
                        class="btn btn-primary">Continue</button>
                    <button *ngIf="sendToChat" type="button" (click)="onRefer('', selectedTeleRX.membershipid)"
                        class="btn btn-primary">Refer</button>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>