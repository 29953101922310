<div class="modal-body">
    <form [formGroup]="subscribeForm">
        <div class="category-header">
            <label>Subscribe</label>
        </div>
        <div class="roomname">
            <label>Teleremedy</label>
            <select [ngClass]="{ error: isFieldValid('membership') }" formControlName="membership" required class="custom-select form-control">
                <option value="">Please Select</option>
                <option *ngFor="let telerx of telerxList" [value]="telerx.membershipid">{{ telerx.name }}</option>
            </select>
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
        <div class="form-group">
            <button type="button" class="btn btn-primary move-buttons" (click)="onSave()">Save</button>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onCancel()">Cancel</button>
        </div>
    </form>
</div>
<app-snackbar></app-snackbar>