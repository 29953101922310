<div class="modal-body">
    <div class="category-header">
        <label>{{ SummaryData.action === "add" ? "Add Prescription" : "Edit Prescription" }}</label>
    </div>
    <form [formGroup]="prescriptionForm">
        <div class="main-row">
            <div class="main-left">
                <div class="form-group">
                    <label>Medication Name</label>
                    <input maxlength="255" [ngClass]="{ error: isFieldValid('medicationname') }"
                        formControlName="medicationname" type="text" required class="form-control" focus="true" />
                </div>
                <div class="form-group">
                    <label>Dosage</label>
                    <input placeholder="ml, g, tablets, drops etc" [ngClass]="{ error: isFieldValid('dosage') }"
                        formControlName="dosage" type="text" required class="form-control" />
                </div>
                <div class="form-group">
                    <label>Frequency</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('frequency') }" formControlName="frequency"
                        type="text" required class="form-control" />
                </div>

                <div class="form-group upload-btn-wrapper">
                    <label>Image</label>
                    <img (click)="showImage(imageURL ? imageURL : '../../../assets/images/addimage-new.png')"
                        class="medication-image"
                        [src]="imageURL ? imageURL : '../../../assets/images/addimage-new.png'" />
                    <div style="display: flex;" class="upload-btn-wrapper mt-2">
                        <button (change)="showPreview($event)" class="btn btn-secondary">Upload Image</button>
                        <input type="file" accept="image/*" (change)="showPreview($event)" />
                    </div>

                    <!-- <div class="imagePreview">
                        <img class="medication-image"
                            [src]="imageURL ? imageURL : '../../../assets/images/addimage-new.png'" />

                        <input type="file" accept="image/*" (change)="showPreview($event)" />
                        <button (click)="showImage(imageURL ? imageURL : '../../../assets/images/addimage-new.png')"
                            class="btn btn-primary mt-2">Preview</button>

                    </div> -->

                    <!-- Image Preview -->
                    <!-- <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                            <img [src]="imageURL" [alt]="prescriptionForm.value.medicationName">
                        </div> -->
                </div>
            </div>
            <div class="main-right">
                <div class="form-group">
                    <label>Notes</label>
                    <textarea style="resize: none" [ngClass]="{ error: isFieldValid('notes') }" formControlName="notes"
                        type="text" required class="form-control"></textarea>
                </div>

                <div class="prescription-buttons">
                    <div class="prescription-btn" *ngIf="SummaryData.action === 'add'"><button type="button"
                            class="btn btn-primary" (click)="onSave()">Save</button></div>
                    <div class="prescription-btn" *ngIf="SummaryData.action === 'edit'"><button type="button"
                            class="btn btn-primary" (click)="onUpdate()">Update</button></div>
                    <div class="prescription-btn"><button type="button" class="btn btn-secondary"
                            (click)="onCancel()">Cancel</button></div>
                    <div class="prescription-btn" *ngIf="SummaryData.action === 'edit'">
                        <button type="button" class="btn btn-secondary delete-button" (click)="onDelete()">Delete this
                            Medication</button>
                    </div>
                </div>
                <app-loader style="margin: 0px auto" *ngIf="showLoader"></app-loader>
            </div>
        </div>
        <div class="main-row">
            <div class="main-full">
                <p *ngIf="error" class="text-danger">{{ error }}</p>
            </div>

        </div>
    </form>
</div>