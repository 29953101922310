<div class="modal-body">
    <div class="panel-content-modal">
        <div class="category-header">
            <label>Click a Category to Edit</label>
        </div>
        <div class="summary-category-row">
            <div class="col-lg-6 summary-category-columns" *ngFor="let item of checklist; let i = index">
                <button type="button" class="btn btn-menu category-button" (click)="onCategorySelection(item.component)">{{ item.name }}</button>
            </div>
        </div>
        <div class="summary-category-row footer-buttons">
            <div class="summary-category-left">
                <button type="button" class="btn btn-secondary cancel-button" (click)="onCancel()">Cancel</button>
            </div>
            <div class="summary-category-right">
                <button type="button" class="btn btn-primary save-button" (click)="onCategorySelection('SymptomOther')">Continue</button>
            </div>
        </div>
    </div>
</div>
