<div>
    <div *ngIf="chartOptions.series" class="leftDiv">
        <div class="heading1">{{reportData.title}}</div>
        <div class="row">
            <div class="col-2">
                <span>{{reportData.subTitle1}}</span>
            </div>
            <div class="col-10">
                <div class="row">
                    <div class="mb-2">
                        <highcharts-chart [Highcharts]="Highcharts" style="width: 100%; display: block; overflow: hidden"
                            [options]="chartOptions"></highcharts-chart>
                    </div>
                </div>
                <div style="width: 400px;display: flex;
                align-items: center;
                justify-content: center;" class="row">
                    <span> {{reportData.subTitle2}}</span>
                </div>
                <div *ngIf="pageCount>1" class="page">
                   <a href="#" (click)="$event.preventDefault();previous()"
                        [className]="page==0 ? 'previous' : 'next'"><i
                        class="fa fa-chevron-left" aria-hidden="true"></i></a>
                    <a href="#" (click)="$event.preventDefault();next()"
                        [className]="pageCount-1==page? 'previous' : 'next'"><i
                        class="fa fa-chevron-right" aria-hidden="true"></i></a>
                </div>
            </div>
    
        </div>
        

    </div>

    <div *ngIf="!chartOptions.series" class="mb-2 noData">
        <label>No data available for the {{reportData.title}}.</label>
    </div>
</div>