import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class PatientService {
    private url = environment.msx_uri;
    constructor(private http: HttpClient, private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) { }
    public patients(userId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/providers/detailsby/" + userId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getUserSetting(userid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/user/settings/" + userid, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getClinicalSummary(userid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/profile/" + userid, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public updateUserSettings(formData: any, patientId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let settingNames = [
                "gender",
                "firstname",
                "lastname",
                "maidenname",
                "dob",
                "postalcode",
                "height",
                "weight",
                "bmi",
                "employer",
                "hypertension",
                "diabetes",
                "weightmanagement",
                "highcholesterol",
                "primaryimprovement",
                "secondaryimprovement",
                "cardnumber",
                "mrn",
                "title",
                "currentcareteamid",
            ];
            let settings = settingNames.filter((name) => typeof formData[name] !== "undefined" && formData[name] !== "").map((name) => ({ settingname: name, settingvalue: formData[name] }));
            // const index = settings.findIndex((x) => x.settingname === "cardnumber");
            // if (index === -1) {
            //     settings.push({ settingname: "cardnumber", settingvalue: "" });
            // }
            // const titleindex = settings.findIndex((x) => x.settingname === "title");
            // if (titleindex === -1) {
            //     settings.push({ settingname: "title", settingvalue: "" });
            // }
            let data = JSON.stringify({
                userid: patientId,
                settings: settings,
            });
            this.avcHttp.sendRequest("post", environment.uri + "/settings", data).subscribe(
                (data) => {
                    // observer.next(this.helper.ParseToJson(data));
                    // observer.next({});
                    // observer.complete();
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getUserStats(userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${environment.uri}/stats/patient/${userId}/${this.sessionService.activeCareTeam.careteamid}`).subscribe(
                (data) => {
                    observer.next(this.helper.ParseToJson(data).data);
                    observer.complete();
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public uploadFile(data: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/library/uploadfile", data, {}, false, "", true).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public uploadLibrary(data: any, userid, careteamid, filename = ""): Observable<any> {
        let url = environment.uri + "/library/uploadfile?userid=" + userid + "&careteamid=" + careteamid;
        if (filename !== "") {
            url = environment.uri + "/library/uploadfile?userid=" + userid + "&careteamid=" + careteamid + "&filename=" + filename.split(".")[0];
        }
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", url, data, {}, false, "", true).subscribe(
                (data) => {
                    if (data.status >= 200 && data.status < 300) {
                        observer.next(data);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getUserLinks(userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${environment.uri}/links/user/${userId}`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
    public getSymptomsfromWaitingLists(userid: string, careteamId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/waitingroom/request/lists/" + userid + "?careteamid=" + careteamId, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public submitSymptoms(data: any, requestType: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest(requestType, environment.uri + "/waitingroom/request", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public incidentBilling(wruid: any, sentUserMessage: boolean): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest(
                    "get",
                    environment.uri + "/waitingroom/request/billing/" + wruid + "?sentUserMessage=" + sentUserMessage + "&timezone=" + Intl.DateTimeFormat().resolvedOptions().timeZone,
                    {}
                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    public incidentGenerateInvoice(wruid: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", environment.uri + "/waitingroom/request/billing/" + wruid + "?filterall=refresh&timezone=" + Intl.DateTimeFormat().resolvedOptions().timeZone, {})
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    public getPatientNotes(userId, careteamId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${environment.uri}/careteam/notes/${userId}/${careteamId}`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public savePatientNotes(userId, careteamId, text): Observable<any> {
        const data = {
            userid: userId,
            notetext: text,
        };
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", `${environment.uri}/careteam/notes/${careteamId}`, data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public deletePatientNotes(noteid, userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", `${environment.uri}/careteam/notes/${userId}/${noteid}`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public updateSummary(data: any, userid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/profile/" + userid, data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getImage(libraryid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/library/" + libraryid, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getLibrary(careteamid, userid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/library/" + careteamid + "/" + userid, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public updateFileName(libraryid: string, metadata: any): Observable<any> {
        let data = JSON.stringify({
            metadata: {
                extension: metadata.extension,
                sizeInKb: metadata.sizeInKb,
                actual_file_name: metadata.actual_file_name,
            },
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", environment.uri + "/library/" + libraryid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getLibraryById(libraryid: string, actiontype = "download"): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/library/" + libraryid + "?actiontype=" + actiontype).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getSymptoms(gender: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let category = "";
            if (gender) {
                if (gender == "male") {
                    category = "male-all";
                } else if (gender == "female") {
                    category = "female-all";
                }
            }
            this.avcHttp.sendRequest("get", `${environment.uri}/symptoms?category=${category}`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
    public getICDCodes(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${environment.uri}/symptoms/icdcodes`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
    public getCPTCodes(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${environment.uri}/symptoms/cptcodes`).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
}
