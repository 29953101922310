<div class="container-fluid">
    <div class="row mt-3">
        <div class="col-6"></div>
        <div class="col-6">
            <button (click)="onAddApp()" class="btn btn-primary float-right mb-2" data-toggle="modal"
                data-target="#addAppsModal"><i class="fas fa-plus-circle"></i> Add New</button>
        </div>
    </div>
    <table class="table table-striped datatable">
        <thead>
            <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Link Type</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of apps">
                <td>{{ p.title }}</td>
                <td>{{ p.description }}</td>
                <td>{{ p.linktype }}</td>
                <td>
                    <i class="fa fa-edit mr-3 cursor-pointer" (click)="onEdit(p)" title="Edit Link"></i>
                    <i class="fa fa-trash mr-3 cursor-pointer" (click)="onDelete(p)" title="Delete Link"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="NoRecordsFound">No records found</div>
</div>

<div class="spinner-border text-primary" role="status" *ngIf="loadingPage">
    <span class="sr-only">Loading...</span>
</div>