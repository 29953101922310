import { Component, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CampaignService } from '../../../../../../app/services/campaign.service';
import { SnackbarService } from '../../../../../../app/shared/snackbar/snackbar.service';


@Component({
    selector: "app-duplicate-campaign-modal-modal",
    templateUrl: "./test-mail-modal.component.html",
    styleUrls: ["./test-mail-modal.component.scss"],
})
export class TestMailModalComponent {
    constructor(private fb: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private campaignService: CampaignService,
        private snackbar: SnackbarService

    ) { }
    showLoader = false;
    submitted = false;
    @Input() campaignId: string = "";
    @Output() readonly Response = new EventEmitter<any>();
    campaignForm = this.fb.group({
        email: ["", Validators.required],

    });

    get form() {
        return this.campaignForm.controls;
    }
    public onSave() {
        this.submitted = true;
        let campaignForm: any;
        if (this.campaignForm.valid) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(this.campaignForm.value.email.trim()).toLowerCase())) {
                this.snackbar.show("A valid email is required.", "danger");
                return false;
            }


            this.showLoader = true;
            this.campaignService.getCampaignById(this.campaignId).subscribe(
                (data) => {
                    campaignForm = data[0].attributes;
                    campaignForm.campaignName = campaignForm.campaign_name;
                    campaignForm.from = campaignForm.campaign_from;
                    campaignForm.reply = campaignForm.reply_email;
                    this.campaignService.sendMail(campaignForm, "test", this.campaignForm.value.email).subscribe(
                        (data) => {
                            this.showLoader = false;
                            this.activeModal.close();
                            this.snackbar.show(data[0].attributes.message, "success");
                        },
                        (err) => {
                            this.showLoader = false;
                            this.snackbar.show(err[0].detail, "danger");
                        }
                    );
                },
                (err) => {
                    //this.showLoader = false;
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }

    }

    public onCancel() {
        this.activeModal.close();
    }
}
