import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { GoalsService } from "../../services/goals.service";
import { CareTeamService } from "../../services/careteam.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { ActivatedRoute } from "@angular/router";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { GoalModalComponent } from "../../modals/goal-modal/goal-modal.component";
import { ChartService } from "../../services/chart.service";
import { StatsService } from "../../services/stats.service";
import { SessionService } from "../../services/session.service";
import { ReportService } from "../../services/report.service";
import { ShowGoalsModalComponent } from "../../modals/show-goals-modal/show-goals-modal.component";
import { OrderBy } from "../../providers/custom.pipe";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
export interface SearchElements {
    username: string;
    userid: string;
    goals: [];
}

@Component({
    selector: "app-goals",
    templateUrl: "./goals.component.html",
    styleUrls: ["./goals.component.scss"],
})
export class GoalsComponent implements OnInit {
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

    displayedColumns = [
        { displayname: "name", columnname: "name" },
        { displayname: "goal", columnname: "goal" },
        { displayname: "compliance", columnname: "compliance" },
        { displayname: "date last entered", columnname: "lastentrytime" },
        // { displayname: "next due date", columnname: "nextentrydate" },
        { displayname: "action", columnname: "" },
    ];

    collectionSize = 0;
    pageSize = 10;
    pageIndex = 1;
    page = 1;
    showLoader = false;
    constructor(
        private goalsService: GoalsService,
        private careTeamService: CareTeamService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private snackbar: SnackbarService,
        private chartService: ChartService,
        private statsService: StatsService,
        private reportService: ReportService,
        public tableService: TableService
    ) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }
    public patients: any;
    public allPatients: any;
    public _goaldata = [];
    public _graphData = [];
    public complianceGraphOptions = [];
    public goals = [];
    public scheduletype = "";
    public searchByName = "";
    public disabledClick = false;

    ngOnInit() {
        this.getGoalByCareTeam();
    }

    public getGoalByCareTeam(orderby = "lastentrytime", order = "desc") {
        this.showLoader = true;
        let goalData = [];
        let patientGoals = [];
        return this.goalsService.goalByCareteam(this.sessionService.activeCareTeam.careteamid, this.scheduletype, orderby, order).subscribe(
            (data) => {
                if (data[0].attributes) {
                    this.patients = data[0].attributes.list;
                    this.patients.forEach((p) => {
                        let _goals = [];
                        if (p.goals) {
                            p.goals.forEach((goal, index) => {
                                if (!_goals.find((g) => g.goalname === goal.goalname)) {
                                    _goals.push(goal);
                                } else {
                                    let obj = _goals.find((g) => g.goalname === goal.goalname);
                                    obj.totalentries = parseInt(obj.totalentries) + parseInt(goal.totalentries);
                                    obj.totalexpectedentries = parseInt(obj.totalexpectedentries) + parseInt(goal.totalexpectedentries);

                                    let _lastentrytime = new Date(goal.lastentrytime).getTime();

                                    obj.lastentrytime = new Date(obj.lastentrytime).getTime() >= _lastentrytime ? obj.lastentrytime : goal.lastentrytime;
                                }
                            });
                        } else {
                            _goals.push({ userid: p.userid, username: p.username, nogoals: true });
                        }
                        goalData.push({
                            userid: p.userid,
                            username: p.username,
                            goals: _goals,
                        });
                    });

                    this.patients = goalData;
                    this.tableService.DATA = goalData;
                    this.sorting(orderby, order, false);
                }

                this.showLoader = false;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                this.showLoader = false;
            }
        );
    }
    public onEditGoal(g) {
        this.goalDetails(g, "edit");
    }

    public onDeleteGoal(goal) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this goal?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.goalsService.deleteGoal(goal.goalname, goal.userid).subscribe(
                    (data) => {
                        this.snackbar.show(data[0].attributes);
                        this.getGoalByCareTeam();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public showGoals(goal) {
        this.goalDetails(goal, "details");
    }

    public goalDetails(g, type = "edit") {
        //Disabled click to avoid open multiple popup.
        if (this.disabledClick) {
            return true;
        } else {
            this.disabledClick = true;
        }
        return this.goalsService.goalByPatient(g.userid, g.goalname).subscribe(
            (data) => {
                this.disabledClick = true;
                if (data[0].attributes) {
                    this._goaldata = data[0].attributes || [];
                    const orderByPipe = new OrderBy();
                    this._graphData = orderByPipe.transform(data[0].attributes || [], "compliancePer", true);
                    this.goals = [];
                    if (this._graphData && this._graphData.length > 0) {
                        this.complianceGraphOptions = [];
                        for (let goal of this._graphData) {
                            goal.list.sort(function (a, b) {
                                let x = parseInt(a.gtime.replace(":", ""));
                                let y = parseInt(b.gtime.replace(":", ""));
                                return x - y;
                            });
                            let goaltimename = goal.list.map((e) => e.goaltime.goaltimename).join(",");
                            let goaltimes = goal.list.map((e) => e.gtime).join(",");
                            let goalformattedtimes = goal.list.map((e) => e.gtimeformat).join(",");
                            this.complianceGraphOptions.push(goal);
                            this.goals.push({
                                goalname: goal.goalname,
                                startdate: goal.list[0].startdate,
                                enddate: goal.list[0].enddate,
                                goaltarget: goal.list[0].goaltarget,
                                status: goal.list[0].graphenabled,
                                goalid: goal.list[0].goalid,
                                userid: goal.list[0].userid,
                                goaltypename: goal.list[0].goaltype.goaltypename,
                                scheduledate: goal.list[0].scheduledate,
                                goaltimename: goaltimename,
                                frequencies: goal.list[0].frequencies,
                                frequencytype: goal.list[0].scheduletype,
                                datatype: goal.list[0].datatype,
                                gdcid: goal.list[0].gdcid,
                                goaltypeid: goal.list[0].goaltypeid,
                                compliancePer: goal.compliancePer,
                                target1: goal.list[0].target1,
                                target2: goal.list[0].target2,
                                notes: goal.list[0].notes,
                                notifyprovider: goal.list[0].notifyprovider,
                                allowearlyentry: goal.list[0].allowearlyentry,
                                goaltimes: goaltimes,
                                goalformattedtimes: goalformattedtimes,
                                goaltimeid: goal.goaltime.goaltimeid,
                                associatedapp: goal.list[0].associatedapp !== null ? goal.list[0].associatedapp.replace("app://", "") : "",
                            });
                            this.goals = orderByPipe.transform(this.goals, "compliancePer", true);
                        }
                    }
                    if (type === "details") {
                        this.disabledClick = false;
                        const modalRef = this.modalService.open(ShowGoalsModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
                        modalRef.componentInstance.GoalsData = this._goaldata;
                        modalRef.componentInstance.PatientId = g.userid;
                    } else {
                        this.disabledClick = false;
                        const modalRef = this.modalService.open(GoalModalComponent, { size: "lg", windowClass: "modal-goal-large-center", centered: true, backdrop: "static" });
                        modalRef.componentInstance.PatientId = g.userid;
                        modalRef.componentInstance.Goal = this.goals[0];
                        modalRef.componentInstance.Response.subscribe(
                            (response) => {
                                // Goal added successfully
                                this.snackbar.show(response);
                                this.getGoalByCareTeam();
                            },
                            (err) => {
                                console.error(err);
                                this.snackbar.show(err, "danger");
                            }
                        );
                    }
                }
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                this.showLoader = false;
                this.disabledClick = false;
            }
        );
    }
    public onAddGoal(goal) {
        const modalRef = this.modalService.open(GoalModalComponent, { size: "lg", windowClass: "modal-goal-large-center", centered: true, backdrop: "static" });
        modalRef.componentInstance.PatientId = goal.userid;
        this.goals.forEach((element) => {
            modalRef.componentInstance.ExistingGoal.push(element.goalname);
        });
        modalRef.componentInstance.Response.subscribe(
            (response) => {
                // Goal added successfully
                this.snackbar.show(response);
                this.getGoalByCareTeam();
            },
            (err) => {
                console.error(err);
                this.snackbar.show(err, "danger");
            }
        );
    }

    public getAdherence(goal) {
        if (goal.totalentries === 0 || goal.totalexpectedentries === 0) {
            return 0;
        } else {
            if (parseInt(((goal.totalentries / goal.totalexpectedentries) * 100).toFixed(0)) >= 100) {
                return 100;
            } else {
                return ((goal.totalentries / goal.totalexpectedentries) * 100).toFixed(1);
            }
        }
    }

    filterByScheduletype(value) {
        this.getGoalByCareTeam();
    }
    reload() {
        this.getGoalByCareTeam();
    }

    onSort({ column, direction }: any, serverSideSorting = true) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction, true);
    }
    sorting(column, direction, serverSideSorting = true) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.searchOn = "username";
        if (serverSideSorting) {
            this.getGoalByCareTeam(column, direction);
            this.tableService.page = 1;
        } else {
            this.tableService.page = 1;
        }
    }
}
