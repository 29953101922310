import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../../environments/environment";
import { phq9DetailsModalComponent } from "./details/details-modal.component";

@Component({
    selector: "phq9-modal",
    templateUrl: "./phq9-modal.component.html",
    styleUrls: ["./phq9-modal.component.scss"],
})
export class phq9ModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }
    @Input() SummaryData: any;
    @Input() AssessmentType: string = "";
    public AssessmentLabel: string = "";
    @Input() fromClinicalPage = false;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public assessment: any;
    public others: string;
    error = "";
    totalScore = 0;
    ngOnInit() {
        this.setupForm();
        if (this.AssessmentType === "phq9") {
            this.AssessmentLabel = "Depression Analysis";
        }
        else if (this.AssessmentType === "gad7") {
            this.AssessmentLabel = "Stress Analysis";
        }
        else {
            this.AssessmentLabel = "Sleep Assessment";
        }
    }
    setupForm() {
        if (this.AssessmentType == "phq9" && this.SummaryData.summary.phq9 !== undefined) {
            this.assessment = this.SummaryData.summary.phq9.phq9;
            if (this.assessment !== undefined) {
                this.assessment.sort((a, b) => (a.date.toLocaleLowerCase() < b.date.toLocaleLowerCase() ? 1 : -1));
            }
        }
        if (this.AssessmentType == "gad7" && this.SummaryData.summary.gad7 !== undefined) {
            this.assessment = this.SummaryData.summary.gad7.gad7;
            if (this.assessment !== undefined) {
                this.assessment.sort((a, b) => (a.date.toLocaleLowerCase() < b.date.toLocaleLowerCase() ? 1 : -1));
            }
        }
        if (this.AssessmentType == "sleepassessment" && this.SummaryData.summary.sleepassessment !== undefined) {
            this.assessment = this.SummaryData.summary.sleepassessment.sleepAssessment
            if (this.assessment !== undefined) {
                this.assessment.sort((a, b) => (a.date.toLocaleLowerCase() < b.date.toLocaleLowerCase() ? 1 : -1));
            }
        }
    }
    onSave() { }
    score(questionnaire) {
        let score = 0;
        let severity = "Minimal or none";
        questionnaire.forEach((element) => {
            score += element.score;
        });
        if (this.AssessmentType == "phq9") {
            if (score >= 5 && score <= 9) {
                severity = "Mild";
            } else if (score >= 10 && score <= 14) {
                severity = "Moderate";
            } else if (score >= 15 && score <= 19) {
                severity = "Moderately severe";
            } else if (score >= 20 && score <= 27) {
                severity = "Severe";
            }
        } else {
            if (score >= 5 && score <= 9) {
                severity = "Mild";
            } else if (score >= 10 && score <= 14) {
                severity = "Moderate";
            } else if (score >= 15) {
                severity = "Severe";
            }
        }
        return { score, severity };
    }
    public onCancel() {
        if (this.fromClinicalPage) {
            this.activeModal.close();
        }
        else {
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        }
    }

    public showDetails(assessment) {
        const modalRef = this.modalService.open(phq9DetailsModalComponent, { size: "xl", windowClass: "modal-large", centered: true, backdrop: "static" });
        modalRef.componentInstance.SummaryData = assessment;
        if (this.AssessmentType === "phq9") {
            modalRef.componentInstance.AssessmentType = "Depression Analysis";
        }
        else if (this.AssessmentType === "gad7") {
            modalRef.componentInstance.AssessmentType = "Stress Analysis";
        }
        else {
            modalRef.componentInstance.AssessmentType = "Sleep Assessment";
        }
    }
}
