<div class="parent">
    <div #left_container class="left-col">
        <div class="titlebox">
            <img src="../../../assets/logo-dashboard.svg" /><br />

        </div>
        <div class="lastlogin">
            <strong>LAST LOGIN</strong>
            <br />
            <span *ngIf="userDetails.lastlogintime"> {{ userDetails.lastlogintime | date: "MM/dd/yyyy" }} @ {{
                userDetails.lastlogintime | date: "shortTime" }} </span>
            <span *ngIf="!userDetails.lastlogintime"> N/A </span>
        </div>
        <br />
        <div class="pic">
            <img [src]="userAttributes.profilepic ? userAttributes.profilepic : '../../../assets/images/user.png'" />
        </div>
        <br />
        <div><i (click)="videoChat()" title="video chat" aria-hidden="true" class="fa fa-video"></i></div>
        <div *ngIf="clinicalSummaryModel" class="patientname">
            {{ clinicalSummaryModel.personalinformation.firstname | uppercase }} {{
            clinicalSummaryModel.personalinformation.lastname | uppercase }}
        </div>
        <button (click)="$event.stopPropagation(); viewSummaryModalResponse({ success: true, action: 'edit' })"
            class="edit-profile-button btn-small">Edit Profile</button>
        <br />
        <hr />
        {{ clinicalSummaryModel ? (clinicalSummaryModel.personalinformation.dob ?
        (clinicalSummaryModel.personalinformation.dob | date: "MM/dd/yyyy") : "DOB N/A") : "DOB N/A" }}<br />
        {{ clinicalSummaryModel ? (clinicalSummaryModel.personalinformation.gender ?
        (clinicalSummaryModel.personalinformation.gender | titlecase) : "GENDER N/A") : "GENDER N/A" }}<br />
        MRN# {{ clinicalSummaryModel ? (clinicalSummaryModel.personalinformation.mrn ?
        clinicalSummaryModel.personalinformation.mrn : "N/A") : "N/A" }}<br />
        {{ userAttributes?.employer }}<br />
        <hr />
        {{ activeCareTeam?.careteamname }}<br />
        <div class="notes">
            <div class="notesheader">
                Office Notes
                <div class="notesheaderexpand">
                    <i style="color: #2d2d2d;" class="fa fa-external-link-alt" (click)="openMainNotesWindow($event)"></i>
                </div>
            </div>
            <div class="notestext">
                <div *ngIf="notes.length > 0">
                    <div class="note" *ngFor="let note of notes">
                        {{ note?.notetext }}
                        <small class="info" class="form-text text-muted">{{ note?.createdate | date: "MM/dd/yy" }} {{
                            note?.providername }}</small>
                    </div>
                </div>
            </div>
        </div>
        <br /><br />
    </div>
    <div class="right-col">
        <div class="dashboard-top">
            <div class="dashboard-top-left">
                <i *ngIf="wrid" class="fa fa-arrows-alt mr-3 cursor-pointer move-icon" (click)="onMovePatient()"
                    title="Move Member"></i>
                <h3 class="h3-room" *ngIf="wrid">{{ roomName }}</h3>
                <h3 class="h3-dashboard" *ngIf="!wrid">Member Dashboard <i (click)="blur(_blurStatus)"
                        class="fa fa-eye cursor-pointer"></i></h3>
            </div>
            <div class="dashboard-top-time" *ngIf="waitingRoomTime">
                <span>{{ waitingRoomTime }}</span>
            </div>
            <div class="back" (click)="back()">
                <img class="fas fa-window-close cursor-pointer" src="/assets/msx/close-gray.svg">
            </div>
        </div>

        <div *ngIf="currentPatient" #main_container #activeList="cdkDropList" cdkDropList
            cdkDropListOrientation="vertical" [cdkDropListData]="tabOrderSettings" (cdkDropListDropped)="drop($event)"
            style="display: flex; flex-direction: column" class="main-col">

            <app-patient-chat-window [ngStyle]="{ order: getDisplayOrder('chat') }" [patient]="currentPatient"
                [patients]="patients" (updateThreads)="getThreadList()"
                [patientDashboardSetting]="sessionService.patientDashboardTabSetting"></app-patient-chat-window>

            <app-clinicalsummary-window [ngStyle]="{ order: getDisplayOrder('clinicalSummary') }"
                [patient]="currentPatient" [patientDashboardSetting]="sessionService.patientDashboardTabSetting">
            </app-clinicalsummary-window>

            <!-- <app-patient-goal [ngStyle]="{ order: getDisplayOrder('goals') }" [patient]="currentPatient"
                [patientDashboardSetting]="sessionService.patientDashboardTabSetting"></app-patient-goal> -->

            <app-incident-window [ngStyle]="{ order: getDisplayOrder('currentVisit') }" [gender]="gender"
                [patient]="currentPatient" [patientDashboardSetting]="sessionService.patientDashboardTabSetting">
            </app-incident-window>
        </div>
    </div>
</div>
<app-snackbar></app-snackbar>