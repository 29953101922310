<div style="text-align: center" class="modal-body">
    <div id="remote-media"></div>
    <div text-center>
        <div [ngStyle]="{ display: actiontype === 'accepted' ? 'block' : 'none' }" id="preview">
            <!-- <p class="instructions">Hi there!</p> -->
            <div id="local-media"></div>
            <!-- <button class="btn btn-primary" (click)="buttonPreview()">Preview My Camera</button> -->
        </div>
        <!-- <p class="actiontype" *ngIf="actiontype === 'declined'">
            Video Call Declined!
        </p>
        <p class="actiontype" *ngIf="actiontype === 'busy'">
            Busy on another call!
        </p>
        <p class="actiontype" *ngIf="actiontype === 'completed'">
            Video Call End!
        </p> -->
        <div style="display: none" id="log"></div>
        <!-- <app-loader *ngIf="showLoader"></app-loader> -->
        <div class="calling" *ngIf="calling || call_initializing">
            <div *ngIf="!error.length">
                <label *ngIf="call_initializing">initializing call ...</label><br />
                <button *ngIf="!call_initializing" (click)="leaveRoom('declined')" class="btn btn-primary">
                    <i class="fa fa-phone"></i>
                </button>
                <br />
                <label *ngIf="!call_initializing"> {{ Patient.authorname }}</label>
                <br />
                <app-loader></app-loader>
            </div>
            <div *ngIf="error.length" class="error">
                <p>{{ error }}</p>
                <button (click)="leaveRoom('declined')" class="btn btn-primary">
                    <i class="fa fa-phone"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!call_initializing && !calling" class="modal-footer">
    <div class="controls">
        <button id="mute-btn" [class.muted]="muted" (click)="muteToggle()"></button>
        <button id="call-btn" (click)="leaveRoom('declined')"></button>
    </div>
</div>
