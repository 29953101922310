import { Component, OnInit } from "@angular/core";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { MSXReportService } from "../../services/msx_report.service";
import * as _ from 'underscore';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-visit-data",
  templateUrl: "./visit-data.component.html",
  styleUrls: ["./visit-data.component.scss"],
})
export class VisitDataComponent implements OnInit {
  metrics = [];
  tabOrderSettings: any;
  current_active = "patient";
  constructor(private msxReportService: MSXReportService, private snackbar: SnackbarService) { }
  ngOnInit(): void {
    this.getMetricsList();
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.metrics, event.previousIndex, event.currentIndex);
    localStorage.setItem("graphPosition", JSON.stringify({
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex
    }));
  }
  getMetricsList() {
    this.msxReportService.getMetricsList().subscribe(
      (data) => {
        this.metrics = data[0].attributes.matricslist;
        var metrics: any;
        metrics = JSON.parse(localStorage.getItem('metrics')) || []
        //Draw graph only if not in deleted list
        this.metrics = this.metrics.filter(function (item) {
          return metrics.filter(function (item2) {
            return item.metricid == item2.metricid;
          }).length == 0;
        });

        setTimeout(() => {
          if (localStorage.getItem("graphPosition") !== null) {
            let graphPosition = JSON.parse(localStorage.getItem("graphPosition"));
            moveItemInArray(this.metrics, graphPosition.previousIndex, graphPosition.currentIndex);
          }
        }, 1000);
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }
  refreshMetricsList() {
    this.getMetricsList();
  }

  showGraph(active_tab = "") {
    this.current_active = active_tab;
  }
}
