<div *ngIf="show" class="container-fluid p-0 chat">
    <ngb-accordion (panelChange)="isOpenedTab('chat')"
        activeIds="{{ sessionService.patientDashboardTabSetting.showChatOpened ? 'chat-panel' : '' }}"
        class="range-accordion">
        <ngb-panel id="chat-panel">
            <ng-template ngbPanelTitle>
                <div style="position: relative">
                    <div cdkDrag class="d-flex justify-content-between">
                            <span class="accordion-title">Chat</span>
                            <div class="d-flex align-items-center">
                                <button (click)="$event.stopPropagation();markAsUnread()"  class="btn btnunread">Mark Last Message As Unread</button>
                                <select (click)="$event.stopPropagation()" [(ngModel)]="channel"
                                class="form-control cursor-pointer" (change)="scrollDown()">
                                    <option value="">Filter</option>
                                    <option *ngFor="let t of non_priorities_tags" [ngValue]="t">
                                        {{ t.tag }}
                                    </option>
                                </select>
                                <i class="fa fa-external-link-alt cursor-pointer"
                                    (click)="openChatWindow(); $event.stopPropagation()" aria-hidden="true"></i>
                                <i class="fa fa-arrows-alt"></i>
                                <i [ngClass]="sessionService.patientDashboardTabSetting.showChatOpened ? 'fa fa-window-minimize' : 'fa fa-window-maximize'"
                                    aria-hidden="true"></i>
                            </div>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-chat-window [patient]="patient" (getTagList)="getTagList($event)" [patients]="patients"
                    [showForPatientDashBoard]="true"></app-chat-window>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>