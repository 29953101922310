<div class="modal-body">
    <div class="category-header">
        <label>{{ IncidentData.action === "new" ? "Add" : "Edit" }} Incident</label>
    </div>
    <div class="main-row">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <!-- <li *ngIf="teleRx?.additional_settings.waitingRoomWorkflowSettings.useICD10Codes" ngbNavItem> -->
                <a (click)="clickICD()" ngbNavLink>ICD10 Codes</a>
                <ng-template ngbNavContent>
                    <div class="tab-section">
                        <div class="search-box-section">
                            <div class="position-relative">
                                <ng-autocomplete #auto [data]="ICDList" [searchKeyword]="searchICD"
                                    [(ngModel)]="icdkeyword" (selected)="onSelectCode($event, auto)"
                                    (inputChanged)="onChangeSearch($event)" [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate" class="form-control search" minQueryLength="1"
                                    [customFilter]="customFilter">
                                </ng-autocomplete>
                                <i class="fa fa-search" *ngIf="icdkeyword.length === 0"></i>
                                <ng-template #itemTemplate let-item>
                                    <a *ngIf="icdkeyword.length > 0" [innerHTML]="getCodeDetails(item)"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="incidents-area">
                            <table class="incident-table table table-striped datatable">
                                <tbody>
                                    <tr *ngFor="let s of selectedICDList"
                                        [class]="s === selectedICDRow ? 'selected-icd' : ''">
                                        <td style="width: 10%">
                                            <span>{{ s?.icdcode ? s?.icdcode : "user" }}</span>
                                        </td>
                                        <td style="width: 30%">
                                            <span>{{ s?.category }}</span>
                                        </td>
                                        <td style="width: 55%">
                                            <span>{{ s?.generalsymptoms }}</span>
                                        </td>
                                        <td style="width: 5%">
                                            <i (click)="onDeleteICD(s, $event)" class="fa fa-trash graycolor"
                                                aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-template>
            </li>

            <li *ngIf="teleRx?.additional_settings.waitingRoomWorkflowSettings.useCPTCodes" ngbNavItem>
                <a (click)="clickCPT()" ngbNavLink>CPT Codes</a>
                <ng-template ngbNavContent>
                    <div class="tab-section">
                        <div class="search-box-section">
                            <div class="position-relative">
                                <ng-autocomplete #autocpt [data]="CPTList" [(ngModel)]="cptkeyword"
                                    [searchKeyword]="searchCPT" (selected)="onSelectCPTCode($event, autocpt)"
                                    (inputChanged)="onChangeSearchCPT($event)" [itemTemplate]="itemTemplateCPT"
                                    [notFoundTemplate]="notFoundTemplateCPT" class="form-control search"
                                    minQueryLength="1" [customFilter]="customFilterCPT">
                                </ng-autocomplete>
                                <i class="fa fa-search" *ngIf="cptkeyword.length === 0"></i>
                                <ng-template #itemTemplateCPT let-item>
                                    <a *ngIf="cptkeyword.length > 0" [innerHTML]="getCPTDetails(item)"></a>
                                </ng-template>

                                <ng-template #notFoundTemplateCPT let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="incidents-area">
                            <table class="incident-table table table-striped datatable">
                                <tbody>
                                    <tr *ngFor="let s of selectedCPTList">
                                        <td style="width: 25%">
                                            <span>{{ s?.code }}</span>
                                        </td>
                                        <td style="width: 65%">
                                            <span>{{ s?.exam }}</span>
                                        </td>
                                        <td style="width: 10%">
                                            <i (click)="onDeleteCPT(s, $event)" class="fa fa-trash graycolor"
                                                aria-hidden="true"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-template>
            </li>

            <li ngbNavItem>
                <a ngbNavLink>Notes</a>
                <ng-template ngbNavContent>
                    <div class="tab-section">
                        <div class="form-group">
                            <textarea maxlength="1000" [(ngModel)]="other" type="text" class="form-control"
                                focus="true"> </textarea>
                        </div>
                    </div>
                </ng-template>
            </li>

            <li *ngIf="teleRx?.additional_settings.waitingRoomWorkflowSettings.userBilling" ngbNavItem>
                <a ngbNavLink>Billing</a>
                <ng-template ngbNavContent>
                    <div class="tab-section">
                        <div style="width: 50%" class="footer-buttons">
                            <button type="button" class="btn btn-primary cancel-button"
                                [disabled]="this.IncidentData.action === 'new' || selectedCPTList.length === 0 || (this.IncidentData.incident && this.IncidentData.incident.cpt.length === 0 && isClosed) "
                                (click)="viewSuperBill()">
                                View Super Bill
                            </button>
                        </div>
                        <div style="width: 50%" class="footer-buttons">
                            <button type="button" class="btn btn-primary save-button"
                                [disabled]="this.IncidentData.action === 'new' || isClosed || disableSendToMember || selectedCPTList.length === 0"
                                (click)="sendToMember()">
                                Send to Member
                            </button>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
    <p *ngIf="errorGender" class="text-danger">{{ errorGender }}</p>
    <p *ngIf="error && selectedICDList.length === 0" class="text-danger">{{ error }}</p>
    <div class="main-row main-row-footer">
        <div style="width: 25%; padding-left: 0px" class="footer-buttons">
            <button type="button" class="btn btn-secondary cancel-button" (click)="onCancel()">Cancel</button>
        </div>
        <div style="width: 25%" class="footer-buttons">
            <button type="button" class="btn btn-primary save-button"
                [disabled]="this.errorGender !== '' || isClosed || disableSaveOnSubmit" (click)="onSave()">Save</button>
        </div>
        <div style="width: 25%" class="footer-buttons">
            <button type="button" class="btn btn-primary save-button"
                [disabled]="this.IncidentData.action === 'new' || isClosed" (click)="onCloseIncident()">
                Close Incident
            </button>
        </div>
        <div style="width: 25%; padding-right: 0px" class="footer-buttons">
            <button type="button" class="btn btn-primary save-button"
                [disabled]="disableAddtoTriage || this.errorGender !== '' || isClosed" (click)="onAddToTriage()">+
                Waiting Rooms</button>
        </div>
    </div>
</div>