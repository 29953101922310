import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-escalate-keywords',
  templateUrl: './escalate-keywords.component.html',
  styleUrls: ['./escalate-keywords.component.scss']
})
export class EscalateKeywordsComponent implements OnInit {

  constructor(
    private snackbar: SnackbarService,
    private router: Router
  ) { }
  sentiments = [];
  public keywords = {
    escalate_scores: true,
    scores: "7",
    escalate_sentiments: true,
    sentiments: [...this.sentiments],
    escalate_keywords: true,
    keyword: ""
  }
  ngOnInit(): void {
    let keywords = JSON.parse(localStorage.getItem("escalate-keywords"))
    if (keywords !== null) {
      this.keywords = keywords;
      this.sentiments = keywords.sentiments;
    }
  }


  public saveKeyWords() {
    localStorage.setItem("escalate-keywords", JSON.stringify(this.keywords));
    this.snackbar.show("Filters updated successfully.", "success");
    this.router.navigate(["/service-recovery"]);
  }

  public cancel() {
    this.router.navigate(["/service-recovery"]);
    // let keywords = JSON.parse(localStorage.getItem("escalate-keywords"))
    // if (keywords !== null) {
    //   this.keywords = keywords;
    // }
    // else {
    //   this.keywords = {
    //     escalate_scores: true,
    //     scores: "7",
    //     escalate_sentiments: true,
    //     sentiments: "anger",
    //     escalate_keywords: true,
    //     keyword: ""
    //   }
    // }
  }


  public setSentiment(event) {
    if (this.sentiments.includes(event.target.value)) {
      this.sentiments.splice(this.sentiments.indexOf(event.target.value), 1);
    }
    else {
      this.sentiments.push(event.target.value);
    }
    this.keywords.sentiments = [...this.sentiments];

  }
}
