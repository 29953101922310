<div class="container-fluid">
    <div>
        <div class="row">
            <div class="col-lg-12 chartPrintTitle">{{settings.title}}</div>
            <!-- <div class="col-lg-3 move">
                <a href="#">
                    <img src="/assets/images/delete.svg">
                </a>
                <a href="#">
                    <img src="/assets/images/move.svg">
                </a>
            </div> -->
        </div>
        <div class="row">
            <highcharts-chart *ngIf="chartOptions" [Highcharts]="Highcharts"
                style="width: 100%; display: block; overflow: hidden" [options]="chartOptions"></highcharts-chart>
            <label>{{settings.xaxisLabel}}</label>
        </div>
        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right">
                <a href="#" (click)="$event.preventDefault();previous()" [className]="page==0 ? 'previous' : 'next'"><i
                        class="fa fa-chevron-left" aria-hidden="true"></i></a>
                <a href="#" (click)="$event.preventDefault();next()"
                    [className]="pageCount-1==page? 'previous' : 'next'"><i class="fa fa-chevron-right"
                        aria-hidden="true"></i></a>
            </div>
        </div>
    </div>

</div>