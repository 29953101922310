<ngb-accordion cdkDrag #acc="ngbAccordion" (click)="isOpenedTab('current_visit')"
    activeIds="{{ sessionService.patientDashboardTabSetting.showCurrentVisitOpened ? 'incident-panel' : '' }}"
    class="range-accordion">
    <ngb-panel id="incident-panel">
        <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
                    <span class="accordion-title">Incidents</span>
                    <div class="d-flex align-items-center">
                        <button [disabled]="ICDList.length === 0 || CPTList.length === 0"
                        (click)="onAddIncident(); $event.stopPropagation()" class="new-button">
                        <i class="fas fa-plus-circle"></i> Add New
                        </button>
                        <i *ngIf="ICDList.length > 0 && CPTList.length > 0"
                            (click)="onShowIncidents(); $event.stopPropagation()"
                            class="fa fa-external-link-alt cursor-pointer" aria-hidden="true"></i>
                        <i class="fa fa-arrows-alt"></i>
                        <i [ngClass]="sessionService.patientDashboardTabSetting.showCurrentVisitOpened ? 'fa fa-window-minimize' : 'fa fa-window-maximize'"
                            aria-hidden="true"></i>
                    </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="panel-content" (click)="$event.stopPropagation()">
                <div class="row" style="max-height: 300px;overflow-y: auto;">
                    <span class="ml-2" *ngIf="incidents?.length === 0">No Incidents Found</span>
                    <table class="incident-table table table-striped datatable" *ngIf="incidents?.length > 0">
                        <thead>
                            <tr>
                                <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                    *ngFor="let c of displayedColumns">
                                    {{ c.displayname }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of incidents">
                                <td style="width: 10%">
                                    <span>{{ s?.date | date: "shortDate" }}</span>
                                </td>
                                <td style="width: 10%">
                                    <span>{{ s?.date | date: "shortTime" }}</span>
                                </td>
                                <td style="width: 30%">
                                    <div class="icd-rows" *ngFor="let reason of s?.generalsymptoms; let i = index">
                                        <div class="incident-key">
                                            {{ s?.icdcodes[i] ? s?.icdcodes[i] : "user" }}
                                        </div>
                                        <div class="incident-values" [innerHTML]="s?.generalsymptoms[i]"></div>
                                    </div>
                                    <div class="icd-rows" *ngIf="s?.other">
                                        <span>Other: {{ s?.other }}</span>
                                    </div>
                                </td>
                                <td style="width: 30%">
                                    <div class="cpt-rows" *ngFor="let reason of s?.cptcodes; let i = index">
                                        <div class="cpt-key">
                                            {{ s?.cptcodes[i] }}
                                        </div>
                                        <div class="cpt-values">
                                            {{ s?.cptexams[i] }}
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 10%">
                                    <span>{{ s?.status }}</span>
                                </td>
                                <td style="width: 10%">
                                    <i (click)="onEditIncident(s); $event.stopPropagation()"
                                        class="fa fa-edit editincident" aria-hidden="true"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>