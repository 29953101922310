import { Component, OnInit, Input } from '@angular/core';
import { MSXReportService } from '../../../services/msx_report.service';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { SessionService } from "../../../services/session.service";
import * as _ from 'lodash';
import { Moment } from 'moment/moment';
import * as Highcharts from "highcharts";
import * as moment from 'moment';
import * as __ from 'underscore';
import { BillingMetricModalComponent } from '../modals/billing-metric-modal/billing-metric-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersModalV2Component } from '../modals/filters-modal-v2/filters-modal-v2.component';
import { FavoritesModalComponent } from '../modals/favorites-modal/favorites-modal.component';
import { xAsisFiltersModalComponent } from '../modals/xAsis-filters-modal/xAsis-filters-modal.component';
import { Router } from '@angular/router';
import * as uuid from 'uuid';
declare var require: any
const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)


@Component({
  selector: 'app-billing-data',
  templateUrl: './billing-data.component.html',
  styleUrls: ['./billing-data.component.scss']
})
export class BillingDataComponent implements OnInit {

  public chartOptions: any;
  public chartData = [];
  public data = [];
  page = 0;
  pageCount = 0;
  perPage = 5;
  selectedBillingMatric = "averageClaimAge";
  metricName = "Claim Age"
  showLoader = false;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  oneToOneFlag = true;
  selectedFactors: any;
  chartTitle = "";
  favoriteList: any;
  additionalsettings: any;
  _datapointsType = "weekly"
  noData = false;
  selectedPractice = "";
  url = ""
  @Input() _favoriteList: any;
  @Input() _type = "";
  isFavorite = false;
  graphType = "line";
  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    public sessionService: SessionService,
    private modalService: NgbModal,
    public router: Router


  ) { }

  ngOnInit(): void {
    if (this._type !== "") {
      this.isFavorite = true;
    }

    if (this._favoriteList !== undefined) {
      this.isFavorite = true;
      this.favoriteList = this._favoriteList;
      this.selectedBillingMatric = this.favoriteList.selectedBillingMatric;
      this.metricName = (this.favoriteList.metricName !== undefined ? this.favoriteList.metricName : this.metricName);
      this.selectedFactors = (this.favoriteList.factors !== undefined ? this.favoriteList.factors : this.selectedFactors);
      this.selectedBillingMatric = (this.favoriteList.selectedBillingMatric !== undefined ? this.favoriteList.selectedBillingMatric : this.selectedBillingMatric);

    }
    this.showLoader = true;
    let selectedGroup = localStorage.getItem("selectedGroup") || ""
    this.showLoader = true;
    this.msxReportService.BillingData(selectedGroup).subscribe(
      (data) => {
        this.chartData = data[0].attributes.data;
        this.chartData = _.orderBy(this.chartData, 'bucketdate', 'asc');
        this.data = this.chartData;
        this.showLoader = false;
        this.loadReport(this.chartData)
        if (this._favoriteList !== undefined) {
          if (this.selectedFactors) {
            this.filterByFactors(this.selectedFactors);
          }
        }
      },
      (err) => {
        this.showLoader = false;
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  loadReport(reportData, metricType = "") {
    // this.selectedBillingMatric = metricType;
    if (this.selectedBillingMatric == "billAmountPerProvider" || this.selectedBillingMatric == "billAmountPerVisit") {
      this.graphType = "column";
      reportData = _.filter(reportData, {
        factorid
          : '740ED927-15C0-4BA9-B971-CD873FB0AE73'
      })
    }

    this.noData = false;
    reportData = _.orderBy(reportData, [user => user.bucketname == null ? "" : user.bucketname.toLowerCase()], ['asc']);
    // reportData = _.orderBy(reportData, 'bucketname', 'asc');
    reportData = reportData.map(item => {
      return {
        ...item,
        claimamount: parseFloat(item.claimamount)
      }
    })

    reportData = reportData.map((x) => {
      x.weeknumber = moment(moment(x.bucketdate, 'YYYY-MM-DD 00:00:00').day('Monday')).format('YYYY-MM-DD 00:00:00');
      x.bucketmonth = moment(x.bucketdate, 'YYYY-MM-DD 00:00:00').format('YYYY-MM-01 00:00:00');
      return x;
    });


    metricType = this.selectedBillingMatric;
    if (this.selectedBillingMatric == "averageClaimAge") {
      this.metricName = "Claim Age"
    }
    else if (this.selectedBillingMatric == "averageBillAmount") {
      this.metricName = "Average Bill Amount (dollars)"
    }
    else if (this.selectedBillingMatric == "billAmountPerProvider") {
      this.metricName = "Bill Amount Per Provider"
    }
    else if (this.selectedBillingMatric == "billAmountPerVisit") {
      this.metricName = "Bill Amount Per Visit"
    }

    else {
      this.metricName = "Total Bill Amount (dollars)"
    }
    if (this.selectedFactors === undefined)
      reportData = _.filter(reportData, {
        factorid
          : '740ED927-15C0-4BA9-B971-CD873FB0AE73'
      })

    if (this._datapointsType === "7 days") {
      let startDate = moment().add(-7, 'days').format('YYYY-MM-DD 00:00:00');
      reportData = reportData.filter(({ bucketdate }) => (new Date(bucketdate)).getTime() > (new Date(startDate)).getTime());
    }
    if (this._datapointsType === "14 days") {
      let startDate = moment().add(-14, 'days').format('YYYY-MM-DD 00:00:00');
      reportData = reportData.filter(({ bucketdate }) => (new Date(bucketdate)).getTime() > (new Date(startDate)).getTime());
    }
    if (this._datapointsType === "daily") {
      let startDate = moment().add(-1, 'months').format('YYYY-MM-DD 00:00:00');
      reportData = reportData.filter(({ bucketdate }) => (new Date(bucketdate)).getTime() > (new Date(startDate)).getTime());
    }
    if (reportData.length === 0) {
      this.noData = true;;
      return;
    }
    let dateStart = moment(new Date(Math.min(...reportData.map(e => new Date(e.bucketdate))))).format('YYYY-MM-DD 00:00:00');
    let dateEnd = moment(new Date(Math.max(...reportData.map(e => new Date(e.bucketdate))))).format('YYYY-MM-DD 00:00:00');
    let nonZeroReportData = reportData.filter((m) => m.bucketid > 0);
    let nonZerobuckets = _.groupBy(nonZeroReportData, x => x.bucketname);

    let chartSeriesData = (_.uniqBy(reportData.filter((m) => m.bucketid > 0), 'bucketname')).map((s) => {
      let filterByColumn = this._datapointsType == "monthly" ? "bucketmonth" : this._datapointsType == "weekly" ? "weeknumber" : "bucketdate";
      const bucketListData = _.groupBy(nonZerobuckets[s.bucketname], x => x[filterByColumn]);
      if (metricType === "billAmountPerProvider" || metricType === "billAmountPerVisit") {
        return {
          seriesId: s.factorid,
          seriesName: s.bucketname,
          seriesData: (_.uniqBy(nonZerobuckets[s.bucketname], 'bucketname')).map((d) => {
            return {
              y: _.sumBy(nonZerobuckets[s.bucketname], 'claimamount'),
              n: _.sumBy(nonZerobuckets[s.bucketname], 'totalvisits'),
              x: d.bucketname,
            };
          })
        };
      }
      else {
        return {
          seriesId: s.factorid,
          seriesName: s.bucketname,
          seriesData: (_.uniqBy(nonZerobuckets[s.bucketname], 'bucketdate')).map((d) => {
            let _y = d.claimage;
            if (metricType === "averageBillAmount") {
              _y = parseFloat((_.sumBy(bucketListData[d[filterByColumn]], 'claimamount') / _.sumBy(bucketListData[d[filterByColumn]], 'totalvisits')).toFixed(2));

            }
            else if (metricType === "totalBillAmount") {
              _y = _.sumBy(bucketListData[d[filterByColumn]], 'claimamount');

            }
            else {
              _y = _.sumBy(bucketListData[d[filterByColumn]], 'claimage');
            }
            var myDate = new Date(d[filterByColumn]);
            var timezone_delay = -myDate.getTimezoneOffset() * 60 * 1000;
            myDate = new Date(myDate.getTime() + timezone_delay);
            return {
              y: _y,
              // n: _n,
              x: parseInt(moment(myDate).format("x")),
              //_x: moment(d.bucketdate).format("MM/DD/YYYY")
            };
          })
        };
      }
    });


    this.chartSettings();
    this.chartOptions.series = [];
    let aaa = JSON.stringify(chartSeriesData);
    //console.log(JSON.stringify(chartSeriesData))

    this.pageCount = Math.ceil(chartSeriesData.length / this.perPage);

    chartSeriesData = __.first(__.rest(chartSeriesData, (this.page || 0) * this.perPage), this.perPage)

    if (metricType === "billAmountPerProvider" || metricType === "billAmountPerVisit") {
      let categories = [];
      let data = []
      chartSeriesData.forEach(element => {
        if (metricType === "billAmountPerVisit") {
          data.push(element.seriesData[0].y / element.seriesData[0].n);
        }
        else {
          data.push(element.seriesData[0].y);
        }
        categories.push(element.seriesName);
      });
      this.chartOptions.series = [
        {
          color: '#08c86c',
          showInLegend: false,
          data: data
        }
      ]
      this.chartOptions.xAxis["categories"] = categories;
    }

    else {
      chartSeriesData.forEach(element => {
        _.sortBy(element.seriesData, ['x']);
      });
      chartSeriesData.forEach(element => {
        let _data = []
        element.seriesData.forEach(series => {
          _data.push([series.x, series.y])
        });
        this.chartOptions.series.push(
          {
            name: element.seriesName,
            data: _data
          }
        );
      });
    }
    this.chartTitle = "(" + moment(dateStart).format('MM/DD/YYYY') + " to " + moment(dateEnd).format('MM/DD/YYYY') + ")"
    //chartBenchmarks= chartBenchmarks,
    this.showLoader = false;
  }

  chartSettings() {

    if (this.selectedBillingMatric == "billAmountPerProvider" || this.selectedBillingMatric === "billAmountPerVisit") {
      this.graphType = "column";
    }
    else {
      this.graphType = "line";
    }
    this.chartOptions = {
      chart: {
        renderTo: 'container',
        type: this.graphType,
        marginTop: 20,
        spacingBottom: 30,
        zoomType: 'x',
        marginRight: 200,
        plotBorderColor: '#C3C3C3',
        plotBorderWidth: 2,
        plotBorderRadius: 20,
      },

      credits: {
        enabled: false
      },
      title: {
        align: 'left',
        useHTML: true,
        text: null,
        //  text: '<div class="chartPrintTitle"> ' + seriesName + ' </div>',
        // text: this.metric.metricGroupName
        //   + '(Month to Date)',
        y: 1,
        style: {
          fontSize: '10px',
        }
      },
      subtitle: {
        align: 'right',
        useHTML: true,
        //text: '<div class="chartRedDotMessage" style="font-weight:bold;font-size:smaller;">Red ' + ((multiMetric || columnChart) ? 'outline' : 'dot') + '(s) have collected very few responses and may not be accurate.</div>',
        verticalAlign: 'top',
        x: -3,
        y: 7
      },
      xAxis: {
        type: 'datetime',
        //tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          //format: "{value:%b  '%y}",
          rotation: -30,
          align: 'right'
        }
      },
      yAxis: {
        // ceiling: 350,
        //max: 105,
        labels: {
          format: '{value}'
        },
        title: {
          text: null
        },
        plotLines: [
          {
            color: '#24B6FF',
            width: 2,
            value: "benchmarkValue",
            zIndex: 5,
            label: {
              useHTML: true,
              text: '<div class="chartbenchMark"> benchmarkName </div>',
              align: 'right',
              allowOverlap: false,
            }
          }
        ],
        tickInterval: 5
      },
      lang: {
        noData: "No data available for the current selection."
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#303030'
        }
      },
      legend: {
        floating: true,
        align: 'right',
        itemMarginBottom: 5,
        x: 5,
        itemMarginLeft: 1,
        verticalAlign: 'top',
        layout: 'vertical',
        symbolPadding: 0,
        symbolWidth: 0.1,
        symbolHeight: 0.1,
        symbolRadius: 0,
        useHTML: true,
        width: 180,
        labelFormatter: function () {
          if (!this.visible) {
            return '<div style="margin:8px 0px;display: flex; max-width:150px"><span style="float:left;"></span><span style="max-width:145px; overflow: hidden;float:right;padding:4px;border-radius: 2px;color:white;background-color:' + this.color + '">' + this.name.substring(0, 17) + (this.visible ? '<i class="ml-1 mr-1 fa fa-eye"></i>' : '<i class="ml-1 mr-1 fa fa-eye-slash"></i>') + '</span></div>';
          }
          else {
            return '<div style="margin:8px 0px;display: flex; max-width:150px"><span style="float:left;"></span><span style="max-width:145px; overflow: hidden;float:right;padding:4px;border-radius: 2px;color:white;background-color:' + this.color + '">' + this.name.substring(0, 17) + (this.visible ? '<i class="ml-1 mr-1 fa fa-eye"></i>' : '<i class="ml-1 mr-1 fa fa-eye-slash"></i>') + '</span></div>';
          }
        },
        itemStyle: {
          color: '#ffffff',
          fontSize: '10px',
        }
      },
      tooltip: {
        valueSuffix: '%',
        useHTML: true,
        shared: true,
        formatter: function () {
          var title = Highcharts.dateFormat('%b %e', this.points[0].key);
          var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
          this.points.forEach(element => {
            if (title != "") {
              tooltip += '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + Highcharts.numberFormat(element.y, 2) + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
            }
            else {
              tooltip = '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.x + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + Highcharts.numberFormat(element.y, 2) + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
            }
          });
          return tooltip + '</table>';
        }
      }
    }
  }

  next() {
    if (this.page < this.pageCount - 1) {
      this.page++;
      this.loadReport(this.data, this.selectedBillingMatric);
    }
  }

  previous() {
    if (this.page > 0) {
      this.page--;
      this.loadReport(this.data, this.selectedBillingMatric);
    }
  }

  public selectedMatric() {
    const modalRef = this.modalService.open(BillingMetricModalComponent, { size: "sm", backdrop: "static" });
    modalRef.componentInstance.selected = this.selectedBillingMatric;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed != "") {
        this.showLoader = true;
        this.selectedBillingMatric = receivedEntry.proceed;
        this.loadReport(this.data, receivedEntry.proceed);
      }
      if (receivedEntry.name != "") {
        this.metricName = receivedEntry.name;
      }

    });
  }


  public compareGraph() {
    const modalRef = this.modalService.open(FiltersModalV2Component, { backdrop: "static" });
    modalRef.componentInstance.ChartData = this.chartData;
    modalRef.componentInstance.SelectedFactors = this.selectedFactors;
    modalRef.componentInstance.selectedPractice = this.selectedPractice;
    modalRef.componentInstance.graphType = "line";
    modalRef.componentInstance.benchmarkData = undefined;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.page = 0;
      this.selectedFactors = receivedEntry.parameters;
      this.filterByFactors(this.selectedFactors)
    });
  }

  public removeFilter(name, type = "") {
    this.page = 0;
    if (type === "practice") {
      this.selectedFactors.selected_practice = __.without(this.selectedFactors.selected_practice, __.findWhere(this.selectedFactors.selected_practice, { b: name.b }));
    }
    if (type === "provider") {
      this.selectedFactors.selected_provider = __.without(this.selectedFactors.selected_provider, __.findWhere(this.selectedFactors.selected_provider, { b: name }));
    }
    if (type === "location") {
      this.selectedFactors.selected_location = __.without(this.selectedFactors.selected_location, __.findWhere(this.selectedFactors.selected_location, { b: name }));
    }
    if (type === "role") {
      this.selectedFactors.selected_role = __.without(this.selectedFactors.selected_role, __.findWhere(this.selectedFactors.selected_role, { b: name }));
    }
    this.filterByFactors(this.selectedFactors);
  }

  public filterByFactors(factors) {
    this.selectedPractice = factors.practiceId;
    let selectedFactors = [];
    let selectedFactorIds = [];
    let practiceIds = [];
    if (factors.practiceId !== "") {
      this.selectedPractice = factors.practiceId;
      practiceIds.push(factors.practiceId);
    }
    factors.selected_practice.forEach(element => {
      selectedFactors.push(element.bucketname);
      //   practiceIds.push(element.practiceid);
      selectedFactorIds.push(element.factorid)
    });
    factors.selected_provider.forEach(element => {
      selectedFactors.push(element.bucketname);
      selectedFactorIds.push(element.factorid)
    });
    factors.selected_location.forEach(element => {
      selectedFactors.push(element.bucketname);
      selectedFactorIds.push(element.factorid)
    });
    factors.selected_role.forEach(element => {
      selectedFactors.push(element.bucketname);
      selectedFactorIds.push(element.factorid)
    });
    if (selectedFactors.length === 0 && practiceIds.length == 0) {
      this.reset();
    }
    else {
      //  this.perPage = selectedFactors.length;
      if (practiceIds.length > 0) {
        this.data = _.filter(this.chartData, (v) => _.includes(practiceIds, v.practiceid));
        if (selectedFactors.length > 0) {
          this.data = _.filter(this.data, (v) => _.includes(selectedFactors, v.bucketname));
        }
      }
      else {
        this.data = _.filter(this.chartData, (v) => _.includes(selectedFactors, v.bucketname));
      }
      if (selectedFactorIds.length > 0) {
        this.data = _.filter(this.data, (v) => _.includes(selectedFactorIds, v.factorid));
      }
      this.loadReport(this.data);
      this.updateFlag = true;
    }

  }

  reset() {
    this.selectedPractice = "";
    // this.metricName = "Claim Age";
    //this.selectedBillingMatric = "averageClaimAge";
    this.selectedFactors = undefined;
    this.data = this.chartData;
    this.loadReport(this.chartData);
    this.updateFlag = true;
  }


  setFavorites() {
    let _graphName = "billing"
    let additionalsettings = {
      graphName: _graphName,
      metricName: this.metricName,
      filters: [],
      factors: this.selectedFactors,
      xAxisType: (this.metricName == "Bill Amount Per Provider" || this.metricName == "Bill Amount Per Visit") ? "Provider" : "Months",
      graphType: this.graphType,
      selectedSurvey: "",
      graphId: uuid.v4(),
      Group: localStorage.getItem("selectedGroup") || "",
      metricid: "",
      selectedBillingMatric: this.selectedBillingMatric,
      dataPointsType: "weekly",
      dateFormat: "MMM'YY",
    }
    const modalRef = this.modalService.open(FavoritesModalComponent, { backdrop: "static" });
    //modalRef.componentInstance.name = this.graphName;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      additionalsettings["name"] = receivedEntry.parameters;
      this.msxReportService.saveFavoriteListV2(additionalsettings, _graphName).subscribe(
        (data) => {
          this.snackbar.show("Favourite added successfully.", "success");
        },
        (err) => {
          this.snackbar.show(err, "danger");
        }
      );
    });
  }



  datapoints(datapoints) {
    this._datapointsType = datapoints;
    this.loadReport(this.data);
  }
  xAxisFilter() {
    const modalRef = this.modalService.open(xAsisFiltersModalComponent, { size: "sm", windowClass: "modal-lg-xAxis-filter", backdrop: "static" });
    modalRef.componentInstance.dataPoints = this._datapointsType
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.page = 0;
      this.datapoints(receivedEntry.dataPoints);
    });
  }

  getLableName() {
    if (this._datapointsType === "monthly") {
      return "Months"
    }
    else if (this._datapointsType === "daily") {
      return "Days"
    }
    else if (this._datapointsType === "daily") {
      return "Days"
    }
    else {
      return "Weeks"
    }
  }

}
