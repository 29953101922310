import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { AvcValidators } from "../../../providers/avc-validators";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { Helper } from "../../../providers/helper";
import { ChatImageModalComponent } from "../../chat-image-modal/chat-image-modal.component";

@Component({
    selector: "covid-19-modal",
    templateUrl: "./covid-19-modal.component.html",
    styleUrls: ["./covid-19-modal.component.scss"],
})
export class Covid19ModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private helper: Helper,
        private avcValidators: AvcValidators,
        private modalService: NgbModal
    ) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    covid19Form: UntypedFormGroup;
    error = "";
    showErr: boolean = false;
    imageURL: string;
    filename: string;
    filedata: string;
    isInvalidImage: boolean = false;
    vaccinationstatus: string;
    ngOnInit() {
        this.covid19Form = this.fb.group({
            vaccinationstatus: [this.SummaryData.summary.covid19vaccination.vaccinationstatus],
            vaccinationcard: [this.SummaryData.summary.covid19vaccination.vaccinationcard],
            dose1manufacturer: [this.SummaryData.summary.covid19vaccination.dose1manufacturer, [Validators.required, Validators.maxLength(50), this.avcValidators.ValidateWhiteSpace]],
            dose1expirationdate: [this.SummaryData.summary.covid19vaccination.dose1expirationdate, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose1lotnumber: [this.SummaryData.summary.covid19vaccination.dose1lotnumber, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose1date: [this.SummaryData.summary.covid19vaccination.dose1date, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose1facility: [this.SummaryData.summary.covid19vaccination.dose1facility, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose2manufacturer: [this.SummaryData.summary.covid19vaccination.dose2manufacturer, [Validators.required, Validators.maxLength(50), this.avcValidators.ValidateWhiteSpace]],
            dose2expirationdate: [this.SummaryData.summary.covid19vaccination.dose2expirationdate, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose2lotnumber: [this.SummaryData.summary.covid19vaccination.dose2lotnumber, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose2date: [this.SummaryData.summary.covid19vaccination.dose2date, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose2facility: [this.SummaryData.summary.covid19vaccination.dose2facility, [Validators.required, this.avcValidators.ValidateWhiteSpace]],
            dose3manufacturer: [this.SummaryData.summary.covid19vaccination.dose3manufacturer],
            dose3expirationdate: [this.SummaryData.summary.covid19vaccination.dose3expirationdate],
            dose3lotnumber: [this.SummaryData.summary.covid19vaccination.dose3lotnumber],
            dose3date: [this.SummaryData.summary.covid19vaccination.dose3date],
            dose3facility: [this.SummaryData.summary.covid19vaccination.dose3facility],
        });
        this.vaccinationstatus = this.SummaryData.summary.covid19vaccination.vaccinationstatus;
        this.updateValidations();
        if (this.SummaryData.summary.covid19vaccination.vaccinationcard) {
            this.getVaccinationCard();
        }
    }
    isFieldValid(key) {
        return this.covid19Form.get(key).invalid && this.covid19Form.get(key).touched;
    }
    updateValidations() {
        if (this.vaccinationstatus === "notyet") {
            this.setDose1Validity(false);
            this.setDose2Validity(false);
        } else if (this.vaccinationstatus === "partial") {
            this.setDose1Validity(true);
            this.setDose2Validity(false);
        } else {
            this.setDose1Validity(true);
            this.setDose2Validity(true);
        }
    }
    setDose1Validity(flag: boolean) {
        if (flag === true) {
            this.covid19Form.controls.dose1manufacturer.setValidators([Validators.required, Validators.maxLength(20), Validators.pattern("[a-zA-Z0-9 ]*"), this.avcValidators.ValidateWhiteSpace]);
            this.covid19Form.controls.dose1lotnumber.setValidators([Validators.required, this.avcValidators.ValidateWhiteSpace]);
            this.covid19Form.controls.dose1facility.setValidators([Validators.required, this.avcValidators.ValidateWhiteSpace]);
            this.covid19Form.controls.dose1expirationdate.setValidators([Validators.required]);
            this.covid19Form.controls.dose1date.setValidators([Validators.required]);

            this.covid19Form.controls.dose1manufacturer.updateValueAndValidity();
            this.covid19Form.controls.dose1lotnumber.updateValueAndValidity();
            this.covid19Form.controls.dose1facility.updateValueAndValidity();
            this.covid19Form.controls.dose1expirationdate.updateValueAndValidity();
            this.covid19Form.controls.dose1date.updateValueAndValidity();
        } else {
            this.covid19Form.controls.dose1manufacturer.setValidators([Validators.maxLength(20), Validators.pattern("[a-zA-Z0-9 ]*")]);
            this.covid19Form.controls.dose1lotnumber.setValidators([]);
            this.covid19Form.controls.dose1facility.setValidators([]);
            this.covid19Form.controls.dose1expirationdate.setValidators([]);
            this.covid19Form.controls.dose1date.setValidators([]);

            this.covid19Form.controls.dose1manufacturer.updateValueAndValidity();
            this.covid19Form.controls.dose1lotnumber.updateValueAndValidity();
            this.covid19Form.controls.dose1facility.updateValueAndValidity();
            this.covid19Form.controls.dose1expirationdate.updateValueAndValidity();
            this.covid19Form.controls.dose1date.updateValueAndValidity();
        }
    }

    setDose2Validity(flag: boolean) {
        if (flag === true) {
            this.covid19Form.controls.dose2manufacturer.setValidators([Validators.required, Validators.maxLength(20), Validators.pattern("[a-zA-Z0-9 ]*"), this.avcValidators.ValidateWhiteSpace]);
            this.covid19Form.controls.dose2lotnumber.setValidators([Validators.required, this.avcValidators.ValidateWhiteSpace]);
            this.covid19Form.controls.dose2facility.setValidators([Validators.required, this.avcValidators.ValidateWhiteSpace]);
            this.covid19Form.controls.dose2expirationdate.setValidators([Validators.required]);
            this.covid19Form.controls.dose2date.setValidators([Validators.required]);

            this.covid19Form.controls.dose2manufacturer.updateValueAndValidity();
            this.covid19Form.controls.dose2lotnumber.updateValueAndValidity();
            this.covid19Form.controls.dose2facility.updateValueAndValidity();
            this.covid19Form.controls.dose2expirationdate.updateValueAndValidity();
            this.covid19Form.controls.dose2date.updateValueAndValidity();
        } else {
            this.covid19Form.controls.dose2manufacturer.setValidators([Validators.maxLength(20), Validators.pattern("[a-zA-Z0-9 ]*")]);
            this.covid19Form.controls.dose2lotnumber.setValidators([]);
            this.covid19Form.controls.dose2facility.setValidators([]);
            this.covid19Form.controls.dose2expirationdate.setValidators([]);
            this.covid19Form.controls.dose2date.setValidators([]);

            this.covid19Form.controls.dose2manufacturer.updateValueAndValidity();
            this.covid19Form.controls.dose2lotnumber.updateValueAndValidity();
            this.covid19Form.controls.dose2facility.updateValueAndValidity();
            this.covid19Form.controls.dose2expirationdate.updateValueAndValidity();
            this.covid19Form.controls.dose2date.updateValueAndValidity();
        }
    }

    public onSave() {
        this.covid19Form.markAllAsTouched();
        if ((this.vaccinationstatus === "full" || this.vaccinationstatus === "partial" || this.vaccinationstatus === "notyet") && this.covid19Form.valid) {
            this.SummaryData.summary.covid19vaccination.vaccinationstatus = this.helper.undefinedIfEmpty(this.vaccinationstatus);
            this.SummaryData.summary.covid19vaccination.dose1manufacturer = this.helper.undefinedIfEmpty(this.covid19Form.get("dose1manufacturer").value);
            this.SummaryData.summary.covid19vaccination.dose1expirationdate = this.helper.undefinedIfEmpty(this.covid19Form.get("dose1expirationdate").value);
            this.SummaryData.summary.covid19vaccination.dose1lotnumber = this.helper.undefinedIfEmpty(this.covid19Form.get("dose1lotnumber").value);
            this.SummaryData.summary.covid19vaccination.dose1date = this.helper.undefinedIfEmpty(this.covid19Form.get("dose1date").value);
            this.SummaryData.summary.covid19vaccination.dose1facility = this.helper.undefinedIfEmpty(this.covid19Form.get("dose1facility").value);
            this.SummaryData.summary.covid19vaccination.dose2manufacturer = this.helper.undefinedIfEmpty(this.covid19Form.get("dose2manufacturer").value);
            this.SummaryData.summary.covid19vaccination.dose2expirationdate = this.helper.undefinedIfEmpty(this.covid19Form.get("dose2expirationdate").value);
            this.SummaryData.summary.covid19vaccination.dose2lotnumber = this.helper.undefinedIfEmpty(this.covid19Form.get("dose2lotnumber").value);
            this.SummaryData.summary.covid19vaccination.dose2date = this.helper.undefinedIfEmpty(this.covid19Form.get("dose2date").value);
            this.SummaryData.summary.covid19vaccination.dose2facility = this.helper.undefinedIfEmpty(this.covid19Form.get("dose2facility").value);
            this.SummaryData.summary.covid19vaccination.dose3manufacturer = this.helper.undefinedIfEmpty(this.covid19Form.get("dose3manufacturer").value);
            this.SummaryData.summary.covid19vaccination.dose3expirationdate = this.helper.undefinedIfEmpty(this.covid19Form.get("dose3expirationdate").value);
            this.SummaryData.summary.covid19vaccination.dose3lotnumber = this.helper.undefinedIfEmpty(this.covid19Form.get("dose3lotnumber").value);
            this.SummaryData.summary.covid19vaccination.dose3date = this.helper.undefinedIfEmpty(this.covid19Form.get("dose3date").value);
            this.SummaryData.summary.covid19vaccination.dose3facility = this.helper.undefinedIfEmpty(this.covid19Form.get("dose3facility").value);
            if (this.filedata && this.filedata !== "") {
                const formData = new FormData();
                formData.append("file", this.covid19Form.get("vaccinationcard").value, this.filename);
                return this.patientService.uploadFile(formData).subscribe(
                    (uploadData) => {
                        this.SummaryData.summary.covid19vaccination.vaccinationcard = uploadData.attributes.libraryid;
                        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
                        this.activeModal.close();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } else {
            this.showErr = true;
        }
    }
    public getVaccinationCard() {
        return this.patientService.getImage(this.SummaryData.summary.covid19vaccination.vaccinationcard).subscribe(
            (data) => {
                this.imageURL = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    // Image Preview
    showPreview(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.covid19Form.patchValue({
            vaccinationcard: file,
        });
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.filename = file.name;
                this.filedata = reader.result.toString().substr(reader.result.toString().indexOf(",") + 1);
                this.imageURL = reader.result as string;
                this.isInvalidImage = false;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    }

    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }

    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }
}
