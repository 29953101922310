import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarService } from "../../../../../shared/snackbar/snackbar.service";
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
    selector: "text-editor-modal",
    templateUrl: "./text-editor-modal.component.html",
    styleUrls: ["./text-editor-modal.component.scss"],
})
export class TextEditorModalComponent implements OnInit {
    @Input() campaignText: string = "";

    htmlContent = '';

    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            ['bold']
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    };

    constructor(private activeModal: NgbActiveModal, private snackbar: SnackbarService) { }

    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    ngOnInit() {
        this.htmlContent = this.campaignText;
    }

    public onCancel() {
        this.activeModal.close();
    }

    public onOK() {
        this.Response.emit({ proceed: true, htmlContent: this.htmlContent });
        this.activeModal.close();

    }

}
