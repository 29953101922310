import { Component, OnInit } from '@angular/core';
import { MSXReportService } from "../../../../services/msx_report.service";
import * as _ from 'underscore';
import * as Highcharts from "highcharts";
import { Router } from '@angular/router';
import { VisitsFiltersModalComponent } from '../../modals/visits-filters-modal/visits-filters-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { xAsisFiltersModalComponent } from '../../modals/xAsis-filters-modal/xAsis-filters-modal.component';
@Component({
  selector: 'app-visits-data',
  templateUrl: './visits-data.component.html',
  styleUrls: ['./visits-data.component.scss']
})
export class VisitsDataComponent implements OnInit {

  constructor(
    private msxReportService: MSXReportService,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal) { }
  reportData: any;
  showLoader = false;
  public chartOptions: any;
  Highcharts: typeof Highcharts = Highcharts;
  benchmarkName = "National Average";
  benchmark = [];
  colorArray = ['#08c86c', '#ff930a', '#232bc8', '#ffbf0a', '#ff480a'];
  page = 0;
  pageCount = 0;
  perPage = 5
  chartData: any;
  gender: any;
  age: any;
  minage = 0;
  maxage = 100;
  _datapointsType = "weekly"
  filters = {
    age: {
      value: "ALL",
      displayText: "ALL",
      minAge: 0,
      maxAge: 100
    },
    gender: "ALL",
    cptCode: "ALL",
    icdCode: "ALL",
    specialty: "ALL",
    surveyType: "ALL",
    specialtyDisplayText: "",
    surveyTypeDisplayText: ""
  }
  ngOnInit(): void {
    // this.getReportData();
  }
  public getReportData(ProviderID = "", gender = "", minage = 0, maxage = 100, datapointsType = "weekly") {
    this.showLoader = true;
    this.page = 0;
    this.msxReportService.getVisitChartData(ProviderID, gender, minage, maxage, datapointsType).subscribe(
      (data) => {
        this.chartData = data[0].attributes.data;
        this.loadReport(data[0].attributes.data);
      },
      (err) => {

      }
    );
  }

  loadReport(data) {
    this.showLoader = false;
    this.benchmark = _.where(data.chartBenchmarks, { benchmarkName: 'National Average' })

    this.pageCount = Math.ceil(data.chartSeries.length / this.perPage);
    let _chartSeries = _.first(_.rest(data.chartSeries, (this.page || 0) * this.perPage), this.perPage)

    this.reportData = _chartSeries;
    let _data = [];
    let practices = [];
    this.chartSettings();
    this.chartOptions.series = [];
    this.reportData.forEach(element => {
      _data.push(element)
    });

    _chartSeries.forEach(element => {
      let _data = []
      element.seriesData.forEach(series => {
        _data.push([series.x, series.y])
      });
      this.chartOptions.series.push(
        {
          name: element.seriesName,
          data: _data
        }
      );
    });



    // if (this.router.url === "/patient-visits") {
    //   this.chartOptions.series.push(
    //     {
    //       name: this.reportData.seriesName,
    //       data: _data,
    //       color: '#00BD00',
    //     }
    //   );
    // }
    // else {
    //   _data.forEach(element => {
    //     if (element.seriesId !== "0") {
    //       this.chartOptions.series.push(
    //         {
    //           name: element.seriesName,
    //           data: element.seriesData,
    //           legendIndex: 2,
    //         }

    //       );
    //     }
    //   });
    // }

    //this.chartOptions.xAxis['categories'] = _.chain(_data).pluck('x').value(); //_(providers).pluck('name');

  }

  chartSettings(type = 'daily') {
    let _format = '{value:%b %e}';
    let _tickInterval = 3600 * 1000 * 24 * 7;
    if (this._datapointsType === 'monthly') {
      // _format = '{value:%b}'
      _tickInterval = 3600 * 1000 * 24 * 30;
    }
    if (this._datapointsType === 'daily') {
      //  _format = '{value:%b %e}'
      _tickInterval = 3600 * 1000 * 24;
    }
    this.chartOptions = {
      chart: {
        type: 'line',
        marginTop: 20,
        spacingBottom: 30,
        zoomType: 'x',
        // height: 350, // 220 , 450 for 1+
        marginRight: 200,
        // width: 400
      },
      colors: this.colorArray,
      credits: {
        enabled: false
      },
      title: {
        align: 'left',
        useHTML: true,
        text: null,
        // text: this.metric.metricGroupName
        //   + '(Month to Date)',
        y: 1,
        style: {
          fontSize: '10px',
        }
      },
      subtitle: {
        align: 'right',
        useHTML: true,
        //text: '<div class="chartRedDotMessage" style="font-weight:bold;font-size:smaller;">Red ' + ((multiMetric || columnChart) ? 'outline' : 'dot') + '(s) have collected very few responses and may not be accurate.</div>',
        verticalAlign: 'top',
        x: -3,
        y: 7
      },
      xAxis: {
        type: 'datetime',
        tickInterval: _tickInterval,
        labels: {
          format: _format,
          rotation: -30,
          align: 'right',
        }
      },
      yAxis: {
        // ceiling: 350,
        max: this.benchmark[0].benchmarkValue + 20,
        labels: {
          format: '{value}'
        },
        title: {
          text: 'Visit Count'
        },
        plotLines: [
          {
            color: '#24B6FF',
            width: 2,
            value: this.benchmark[0].benchmarkValue,
            zIndex: 5,
            // label: {
            //   useHTML: true,
            //   text: '<div class="chartbenchMark"> ' + this.benchmark[0].benchmarkName + '</div>',
            //   align: 'right',
            //   allowOverlap: false,
            //   // x: 100,
            //   // x: 50, //shifts right
            //   // y: -15
            // }
          }
        ],
        //tickInterval: 5
      },
      lang: {
        noData: "No data available for the current selection."
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#303030'
        }
      },
      legend: {
        floating: true,
        align: 'right',
        itemMarginBottom: 5,
        x: 15,
        itemMarginLeft: 5,
        verticalAlign: 'top',
        layout: 'vertical',
        symbolPadding: 0,
        symbolWidth: 0.1,
        symbolHeight: 0.1,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function () {
          return '<div style="margin:8px"><span style="float:left; margin-left:10px"></span><span style="float:right;padding:2px;color:white;background-color:' + this.color + '">' + this.name + '</span></div>';
        },
        itemStyle: {
          color: '#ffffff',
          fontSize: '10px',

        }
      },
      tooltip: {
        valueSuffix: '%',
        useHTML: true,
        shared: true,
        formatter: function () {
          var title = Highcharts.dateFormat('%b %e', this.points[0].key);
          var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
          this.points.forEach(element => {
            tooltip += '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + element.y + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
          });
          return tooltip + '</table>';
        }
      }
    }
  }


  next() {
    if (this.page < this.pageCount - 1) {
      this.page++;
      this.loadReport(this.chartData);
    }
  }
  previous() {
    if (this.page > 0) {
      this.page--;
      this.loadReport(this.chartData);
    }
  }

  showFilters() {
    const modalRef = this.modalService.open(VisitsFiltersModalComponent, { backdrop: "static" });
    modalRef.componentInstance.filters = this.filters;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.filters = receivedEntry.parameters.filters;
      this.gender = receivedEntry.parameters.filters.gender;
      this.age = receivedEntry.parameters.filters.age;
      this.minage = receivedEntry.parameters.filters.age === undefined ? 0 : receivedEntry.parameters.filters.age.minAge;
      this.maxage = receivedEntry.parameters.filters.age === undefined ? 100 : receivedEntry.parameters.filters.age.maxAge;
      this.getReportData("", this.gender !== undefined ? this.gender.value : "ALL", this.minage, this.maxage, this._datapointsType);
    });
  }

  removeFilter(type = "") {
    if (type === "gender") {
      this.gender = undefined;
    }
    else {
      this.minage = 0;
      this.maxage = 100;
      this.age = undefined;
    }
    if (type == "all") {
      this.gender = undefined;
    }
    this.getReportData("", this.gender == undefined ? "" : this.gender.value, this.minage, this.maxage, this._datapointsType)
  }
  datapoints(datapointsType) {
    this._datapointsType = datapointsType;
    this.getReportData("", this.gender == undefined ? "" : this.gender.value, this.minage, this.maxage, this._datapointsType)

  }

  xAxisFilter() {
    const modalRef = this.modalService.open(xAsisFiltersModalComponent, { size: "sm", windowClass: "modal-lg-xAxis-filter", backdrop: "static" });
    modalRef.componentInstance.dataPoints = this._datapointsType
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.datapoints(receivedEntry.dataPoints)
    });
  }
  getLableName() {
    if (this._datapointsType === "monthly") {
      return "Months"
    }
    else if (this._datapointsType === "daily") {
      return "Days"
    }
    else {
      return "Weeks"
    }
  }
}

