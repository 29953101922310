<div class="modal-header">
    <h5 class="modal-title">Compare</h5>
</div>

<div style="overflow-x: inherit;" class="modal-body">
    <div class="row">
        <div *ngIf="practiceName===''" class="input-group mb-3 col">
            <select [disabled]="disabledPractices" [(ngModel)]="selectedPractice" (change)="onPracticeChange($event)"
                class="form-control">
                <option value="">ALL Practices</option>
                <option *ngFor="let p of practice" [value]="p.practiceid">{{ p.name }}</option>
            </select>
        </div>
        <div *ngIf="practiceName!==''" class="input-group mb-3 col">
            {{practiceName}}
        </div>
    </div>
    <div class="row">
        <div class="input-group mb-3 col">
            <div class="form-check form-check-inline">
                <label class="form-check-label"><b>Compare:</b></label>
            </div>
            <div *ngFor="let option of options" class="form-check form-check-inline">
                <input class="form-check-input" name="compare" type="radio" id={{option.name}}
                    (change)="selectedOptions(option.value)" [(ngModel)]="selectedOption" [value]="option.value">
                <label class="form-check-label" for={{option.name}}>{{option.name}}</label>
            </div>

        </div>
    </div>

    <div class="row">
        <div [ngStyle]="{'display': selectedOption==='practice' ? 'block' : 'none' }" class="input-group mb-3 col">
            <ng-multiselect-dropdown (onDeSelect)="onItemDeSelect($event)" [(ngModel)]="selected_practice"
                (onSelect)="onItemSelect($event,'practice')" style="width:100%" [data]="compare_practice"
                [settings]="practiceDropdownSettings">
            </ng-multiselect-dropdown>
        </div>
        <div [ngStyle]="{'display': selectedOption==='provider' ? 'block' : 'none' }" class="input-group mb-3 col">
            <ng-multiselect-dropdown [(ngModel)]="selected_provider" (onSelect)="onItemSelect($event,'provider')"
                style="width:100%" [data]="providers" [settings]="providerDropdownSettings">
            </ng-multiselect-dropdown>
        </div>
        <div [ngStyle]="{'display': selectedOption==='location' ? 'block' : 'none' }" class="input-group mb-3 col">
            <ng-multiselect-dropdown [(ngModel)]="selected_location" (onSelect)="onItemSelect($event,'location')"
                style="width:100%" [data]="graphLocations" [settings]="locationDropdownSettings">
            </ng-multiselect-dropdown>
        </div>
        <div [ngStyle]="{'display': selectedOption==='role' ? 'block' : 'none' }" class="input-group mb-3 col">
            <ng-multiselect-dropdown [(ngModel)]="selected_role" (onSelect)="onItemSelect($event,'role')"
                style="width:100%" [data]="graphRoles" [settings]="roleDropdownSettings">
            </ng-multiselect-dropdown>
        </div>
    </div>

    <div class="row filter">
        <div class="input-group col-12 mb-2">
            <div *ngFor="let s of selected_location;let i=index">
                <b *ngIf="i==0">Location:</b>
                <span class="help-block mr-2">
                    {{s.name}}
                </span>
            </div>
        </div>
        <div class="input-group col-12 mb-2">
            <div *ngFor="let s of selected_practice;let i=index">
                <b *ngIf="i==0">Practice:</b>
                <span class="help-block mr-2">
                    {{s.name}}
                </span>
            </div>

        </div>
        <div class="input-group col-12 mb-2">
            <div *ngFor="let s of selected_provider;let i=index">
                <b *ngIf="i==0">Provider:</b>
                <span class="help-block mr-2">
                    {{s.name}}
                </span>
            </div>
        </div>
        <div class="input-group col-12 mb-2">
            <div *ngFor="let s of selected_role;let i=index">
                <b *ngIf="i==0">Role:</b>
                <span class="help-block mr-2">
                    {{s.name}}
                </span>
            </div>
        </div>

    </div>
</div>
<div class="modal-footer">
    <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
    <button [disabled]="selectedPractice==='' && selectedProvider.length==0" (click)="onSubmit()" type="button"
        class="btn btn-primary">Done</button>
</div>