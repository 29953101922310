import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Configuration } from "../../pages/auto-reply/models/Schedule";
import { AutoReplyService } from "../../services/auto_reply.service";
import { SessionService } from "../../services/session.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "chatbot-keyword-modal",
    templateUrl: "./chatbot-keyword-modal.component.html",
    styleUrls: ["./chatbot-keyword-modal.component.scss"],
})
export class ChatbotKeywordModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private snackbar: SnackbarService,
        private formBuilder: UntypedFormBuilder,
        private sessionService: SessionService,
        private autoreply: AutoReplyService
    ) { }
    scheduleForm: UntypedFormGroup;
    submitted = false;
    configuration: any;
    @Input() selecteId: string = "";
    @Output() readonly Response = new EventEmitter<any>();
    selectedIndex = -1;
    ngOnInit() {
        let autoreplySetting = this.sessionService.currentUser?.attributes.membership.telerx.autoreply
        this.configuration = new Configuration(
            autoreplySetting ? (autoreplySetting ? autoreplySetting.enable : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.enableFor : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.afterHoursSettings : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.defaultMessage : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.schedules : "") : "",
            autoreplySetting ? (autoreplySetting ? autoreplySetting.typicalKewords : "") : "",
        )
        this.configuration.typicalKewords.forEach((element, index) => {
            if (element.id === this.selecteId) {
                this.selectedIndex = index;
            }
        });

        this.scheduleForm = this.formBuilder.group({
            keyword: [this.selectedIndex != -1 ? this.configuration.typicalKewords[this.selectedIndex].keyword : "", Validators.required],
            severity: [this.selectedIndex != -1 ? this.configuration.typicalKewords[this.selectedIndex].severity.toLowerCase() : "high", Validators.required],
            chatMessage: [this.selectedIndex != -1 ? this.configuration.typicalKewords[this.selectedIndex].chatMessage : "", Validators.required],
            id: [this.selectedIndex != -1 ? this.configuration.typicalKewords[this.selectedIndex].id : this.guid()],
        });
    }
    get form() {
        return this.scheduleForm.controls;
    }
    public guid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }
    public addToSchedule() {
        this.submitted = true;
        let keywordFound = 0;

        if (this.scheduleForm.valid) {
            this.configuration.typicalKewords.forEach((element, index) => {
                if (element.keyword.toLowerCase() === this.scheduleForm.value.keyword.toLowerCase()) {
                    keywordFound++;
                    if (this.selectedIndex != -1 && this.scheduleForm.value.id == element.id) {
                        keywordFound--;
                    }
                }
            });

            if (this.selectedIndex == -1 && keywordFound > 0) {
                this.snackbar.show("Keywords already exists.", "danger")
                return false;
            }
            if (this.selectedIndex != -1 && keywordFound >= 1) {
                this.snackbar.show("Keywords already exists.", "danger")
                return false;
            }

            if (this.selectedIndex === -1) {
                this.configuration.typicalKewords.push(this.scheduleForm.value)
            }
            else {
                this.configuration.typicalKewords[this.selectedIndex].keyword = this.scheduleForm.value.keyword;
                this.configuration.typicalKewords[this.selectedIndex].severity = this.scheduleForm.value.severity;
                this.configuration.typicalKewords[this.selectedIndex].chatMessage = this.scheduleForm.value.chatMessage;

            }
            this.autoreply.addSchedule(this.configuration).subscribe(
                (data) => {
                    let a = data;
                    this.Response.emit({ success: true, message: "" });
                    this.activeModal.close();
                },
                (error) => {
                    let a = error;
                }
            );
        }
    }

    public onCancel() {
        this.activeModal.close();
    }


}
