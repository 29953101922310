import { Component, OnInit, ViewChild, ViewChildren, OnDestroy, ElementRef, HostListener, QueryList } from "@angular/core";
import { SortableHeaderDirective, SortEvent } from "../../../directives/sortable.directive";
import { MSXReportService } from "../../../services/msx_report.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { TableService } from "../../../services/table.service";
import { Observable } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SessionService } from "../../../services/session.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  name = 'Iframe Src';
  public sessionId: string = "";
  url: string = "";
  urlSafe: SafeResourceUrl;


  @ViewChild("input") input: ElementRef;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  page = 1;
  pageSize = 10;
  public resultsLength = 50;
  public pageNo = 1;

  displayedColumns = [
    { displayname: "group name", columnname: "name" },
    { displayname: "future visits capture", columnname: "futurevisits" },

  ];
  public NoRecordsFound = false;
  public pageIndex = 1;
  showLoader = true;
  groups = [];
  resultList$: Observable<any[]>;
  total$: Observable<number>;
  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    public tableService: TableService,
    public sanitizer: DomSanitizer,
    public sessionService: SessionService,
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 10;
    this.tableService.searchTerm = "";

  }

  ngOnInit(): void {
    if (this.sessionService.currentUser.attributes.sessionid != undefined || this.sessionService.currentUser.attributes != null) {
      this.sessionId = this.sessionService.currentUser.attributes.sessionid;
      this.url = environment.cliotomsx + '/Auth?sessionkey=' + this.sessionId;
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.msxReportService.GetEntity().subscribe(
      (data) => {
        this.groups = data[0].attributes.data.groups;
        this.tableService.DATA = this.groups;
        this.resultsLength = this.groups.length;
        this.tableService.page = 1;
        this.showLoader = false;
        this.sorting("name", "asc");
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }

  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }
  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
  }

  updateFutureVisit(event, groupid) {
    let status = event.currentTarget.checked ? 1 : 0;
    this.msxReportService.UpdateFutureVisit(status, groupid).subscribe(
      (data) => {
        this.snackbar.show("future visits captured successfully.", "success");
        this.groups.find((element) => element.groupid === groupid).futurevisits = status;
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;
      }
    );
  }

  public onPagination(page: any) {
    this.tableService.page = page;
  }
  setPageSize() {
    this.tableService.pageSize = this.pageSize;
  }
}
