<ngb-accordion cdkDrag #acc="ngbAccordion" (click)="isOpenedTab('goal')"
    activeIds="{{ sessionService.patientDashboardTabSetting.showGoalsOpened ? 'goal-panel' : '' }}"
    class="range-accordion">
    <ngb-panel id="goal-panel">
        <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
                <div class="d-flex" style="font-weight: bold; color: #1c2128"></div>
                <div class="d-flex"><i class="icon-arrow-down"></i></div>
                <div class="col-12"
                    style="padding-right: 0px !important; padding-left: 0px !important; vertical-align: middle">
                    <span style="float: left; color: white">Goals</span>
                    <div style="float: right; margin-top: -4px; color: white">
                        <i class="fa fa-external-link-alt cursor-pointer" style="padding-top: 6px; margin-left: 10px"
                            (click)="showGoals(); $event.stopPropagation()" aria-hidden="true"></i>
                        <i class="fa fa-arrows-alt"></i>
                        <i [ngClass]="sessionService.patientDashboardTabSetting.showGoalsOpened ? 'fa fa-window-minimize' : 'fa fa-window-maximize'"
                            aria-hidden="true"></i>
                    </div>
                    <button (click)="$event.stopPropagation(); onAddGoal()" class="float-right new-button"><i
                            class="fas fa-plus-circle"></i> Add New</button>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div>
                <span class="ml-2" *ngIf="goalsPerFrequency?.length === 0">No Goals Found</span>

                <div class="row col-12" *ngIf="goalsPerFrequency?.length !== 0">
                    <div style="max-height: 300px;overflow-y: auto;overflow-x: hidden;" class="col-6">

                        <div *ngFor="let g of goalsPerFrequency; let i = index">
                            <div (click)="loadGraphforGoal(g.goalid); $event.stopPropagation()" class="row goals-div"
                                [ngClass]="[g.goalid === selectedgoalid ? 'goals-selected' : 'goals-blurred']">
                                <div class="col-2 ml-4">
                                    <div [ngClass]="perStatusColor(g.compliancePer)"
                                        class="c100 p{{ g.compliancePer | round }} small">
                                        <span [ngClass]="perStatusColor(g.compliancePer)">{{
                                            getCompliancePer(g.compliancePer) }}%</span>

                                        <div class="slice">
                                            <div class="bar"></div>
                                            <div class="fill"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="goalname col-7">
                                    <h6 class="pt-2" [ngClass]="statusColor(colors[i])" style="color: {{ colors[i] }}">
                                        {{ g.goalname }}
                                    </h6>
                                </div>
                                <div class="col-2">
                                    <i (click)="onEditGoal(g)" class="pt-2 fa fa-pen graycolor mr-3 cursor-pointer"
                                        aria-hidden="true"></i>

                                    <i (click)="onDeleteGoal(g)" class="pt-2 fa fa-trash graycolor"
                                        aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <highcharts-chart [Highcharts]="Highcharts"
                            style="width: 100%; display: block; overflow: hidden" *ngFor="let c of chartOptions"
                            [options]="c.chartObject"></highcharts-chart>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>