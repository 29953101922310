import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { TeleRXService } from "../../services/teleRX.service";
@Component({
    selector: "app-subscribe-telerx-modal",
    templateUrl: "./subscribe-telerx-modal.component.html",
    styleUrls: ["./subscribe-telerx-modal.component.css"],
})
export class SubscribeTeleRXModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private teleRxservice: TeleRXService,
        private teleRXService: TeleRXService,
        private fb: UntypedFormBuilder,
        private snackbar: SnackbarService,
        private waitingRoomService: WaitingRoomService
    ) {}

    @Input() SubscribeData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    error = "";
    title = "";
    telerxList: any = [];
    subscribeForm: UntypedFormGroup;
    ngOnInit() {
        // if(this.SubscribeData.action == "to") {
        //     this.title = "Subscribe"
        // }
        // else {
        //     this.title = "Subscribe"
        // }
        this.telerxList = this.SubscribeData.telerxList;
        this.subscribeForm = this.fb.group(
            {
                membership: ["", Validators.required],
            },
            { updateOn: "blur" }
        );
    }
    isFieldValid(key) {
        return this.subscribeForm.get(key).invalid && this.subscribeForm.get(key).touched;
    }

    public onSave() {
        if (this.subscribeForm.valid) {
            let selectedMembershipId = this.subscribeForm.get("membership").value;
            let telerxdata = {};
            let reEnable = false;
            if (this.SubscribeData.action == "by") {
                let selectedMembership = this.telerxList.filter((x) => x.membershipid == selectedMembershipId);
                if (selectedMembership && selectedMembership.length > 0) {
                    let alreadySubscribed = this.SubscribeData.subscribedByAll.filter((x) => x.telerxid == selectedMembership[0].telerxid);
                    if (alreadySubscribed && alreadySubscribed.length > 0) {
                        reEnable = true;
                    }
                    telerxdata = {
                        telerxid: selectedMembership[0].telerxid,
                        membershipid: this.SubscribeData.membershipid,
                        reEnable: reEnable,
                    };
                }
            } else {
                let alreadySubscribed = this.SubscribeData.subscribedToAll.filter((x) => x.membershipid == selectedMembershipId);
                if (alreadySubscribed && alreadySubscribed.length > 0) {
                    reEnable = true;
                }
                telerxdata = {
                    telerxid: this.SubscribeData.telerxid,
                    membershipid: selectedMembershipId,
                    reEnable: reEnable,
                };
            }
            this.teleRXService.addMembershipToTeleRemedy(telerxdata).subscribe(
                (data) => {
                    this.Response.emit({ success: true, message: "Teleremedy subscribed successfully" });
                    this.activeModal.close();
                },
                (err) => {
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            this.subscribeForm.markAllAsTouched();
            this.error = "Please provide mandatory fields.";
        }
    }
    public onCancel() {
        this.activeModal.close();
    }
}
