import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class VideoCallService {
    constructor(private avcHttp: AvcHttp, private helper: Helper) {}
    public getTwilioToken(roomName, identity): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/videocall/twilio/token?roomid=" + roomName + "&identityname=" + identity;
            this.avcHttp.sendRequest("get", url, {}, {}, false).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.data[0]) {
                        observer.next(body.data[0]);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public startCall(patient, provider, token, roomid, identityname): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/videocall/start";
            let data = JSON.stringify({
                careteamid: patient.careteamid,
                providerid: provider.id,
                patientid: patient.patientid,
                roomid: roomid,
                identityname: identityname,
                token: token,
            });
            this.avcHttp.sendRequest("post", url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.data[0]) {
                        observer.next(body.data[0]);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public actions(patient, provider, actiontype, duration = 0): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/videocall/action";
            let data = JSON.stringify({
                providerid: provider.id,
                patientid: patient.patientid,
                actiontype: actiontype,
                duration: duration,
            });
            this.avcHttp.sendRequest("post", url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.data[0]) {
                        observer.next(body.data[0]);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
