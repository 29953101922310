import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { PatientService } from "../services/patients.service";
import { UsersService } from "../services/users.service";

@Injectable({
    providedIn: "root",
})
export class UserResolverService implements Resolve<any> {
    constructor(private patientService: PatientService, private usersService: UsersService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Observable<never> {
        let userId = route.paramMap.get("id");
        return this.patientService.getUserSetting(userId);
    }
}
