<div class="modal-body">
    <h5 class="modal-title">Metric</h5>
    <div *ngIf="metricType=='survey'" class="filter p-3">
        <div class="form-row">
            <div class="form-group col-md-6">
                <label style="font-size:16px;" for="survey">Please select a survey</label>
                <select class="form-control" id="survey" (change)="getSurvey(true)" [(ngModel)]="selectedSurvey">
                    <option *ngFor="let m of surveys?.metricGroups| orderBy: 'surveyname'" [ngValue]="m.surveyid
                ">{{ m.surveyname }}</option>
                </select>
            </div>
        </div>
        <div *ngFor="let l of surveys.metrics[0]?.list">
            <label class="group_name"> {{l.groupName}}</label>
            <div *ngFor="let m of l.metrics" class="form-check">
                <input [checked]="metric== m.metricid" (change)="selectedMetric(m)" class="form-check-input"
                    type="radio" name="metric" id="{{m.metricname}}">
                <label class="form-check-label" for="{{m.metricname}}">
                    {{m.metricname}}
                </label>
            </div>
        </div>
    </div>
    <div *ngIf="metricType=='visit'" class="filter p-3">
        <div *ngFor="let m of visitMetrics" class="form-check">
            <input [checked]="metric== m.value" (change)="selectedMetric(m)" class="form-check-input" type="radio"
                name="metric" id="{{m.value}}">
            <label class="form-check-label" for="{{m.value}}">
                {{m.label}}
            </label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
    <button (click)="onSubmit()" type="button" class="btn btn-primary">Done</button>
</div>