import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from 'underscore';
import * as Highcharts from "highcharts";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../../../modals/warning-modal/warning-modal.component";
import { Router } from "@angular/router";
import { SessionService } from "../../../../services/session.service";
@Component({
  selector: 'app-metric-graph',
  templateUrl: './metric-graph.component.html',
  styleUrls: ['./metric-graph.component.scss']
})
export class MetricGraphComponent implements OnInit {

  @Input() metric: any;
  @Input() isSurvayGraph = false;
  showGraphdata = false;
  surveyName = "";
  @Output("refreshMetricsList") refreshMetricsList: EventEmitter<any> = new EventEmitter();
  public chrtData = [];
  public chartOptions: any;
  Highcharts: typeof Highcharts = Highcharts;
  page = 0;
  pageCount = 0;
  chart;
  showLoader = false;
  lowSample = 5
  additionalsettings: any;
  favoriteList: any;
  constructor(
    private http: HttpClient,
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    public sessionService: SessionService
  ) { }

  _data = [];
  _categories = [];
  benchmarkName = "National Average";
  benchmark = [];
  reportData: any;
  ngOnInit(): void {
    this.getFavoriteList();
    if (this.isSurvayGraph) {
      this.reportData = this.metric;
      this.loadReport();
    }
    else {
      this.getReportData();
    }
  }
  public getReportData() {
    this.showLoader = true;
    this.msxReportService.getChartData(this.metric.metricid).subscribe(
      (data) => {
        this.reportData = data[0].attributes.data;
        this.loadReport();
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  roundN(n, name) {
    if (name.match(/NATIONAL AVERAGE/i)) return "";
    if (n >= 1e7) return Math.round(n / 1e6) + 'MN';
    if (n >= 1e6) return Math.round(n / 1e5) / 10 + 'MN';
    if (n >= 1e4) return Math.round(n / 1e3) + 'KN';
    if (n >= 1e3) return Math.round(n / 1e2) / 10 + 'KN';
    return n + 'N';
  }

  loadReport() {
    let _chartSeries: any;
    this.showLoader = false;
    if (this.reportData.rows.length == 0) {
      this.surveyName = "Survey Name: " + this.reportData.metricSurvey.surveyname;
      this.showGraphdata = false;
      return;
    }
    else {
      this.showGraphdata = true;
    }
    this.benchmark = _.where(this.reportData.chartBenchmarks, { benchmarkName: 'National Average' })
    let providers: any;
    providers = this.reportData.rows;

    var latterSeries = [];
    for (var i = 0; i < providers.length; i++) {
      latterSeries.push({ category: providers[i].b, y: providers[i].a })
    }
    this.chartSettings();
    this.chartOptions.series = [];
    var maxDocsInXaxis = 7;
    var perPage = 10; //Math.ceil(_.pluck(providers, 'name').length / Math.ceil(_.pluck(providers, 'name').length / maxDocsInXaxis));
    this.pageCount = Math.ceil(_.pluck(providers, 'b').length / perPage);

    this.chartOptions.series.push(
      {
        data: _.first(_.rest(latterSeries, (this.page || 0) * perPage), perPage),
        name: Highcharts.dateFormat('%B %Y', this.reportData.chartLatterX),
        legendIndex: 2,
        color: '#00BD00',
        showInLegend: false

      });
    this.chartOptions.xAxis['categories'] = _.chain(providers).pluck('b').rest((this.page || 0) * perPage).first(perPage).value(); //_(providers).pluck('name');
  }

  chartSettings() {

    this.chartOptions = {
      chart: {
        type: 'column',
        marginTop: 20,
        spacingBottom: 20,
        zoomType: 'x',
        height: 350,
        marginRight: this.isSurvayGraph ? 0 : 100,
        plotBorderColor: '#C3C3C3',
        plotBorderWidth: 2,
        plotBorderRadius: 20,
      },

      credits: {
        enabled: false
      },
      title: {
        align: 'center',
        useHTML: true,
        text: "<b>Survey Name: </b>" + this.reportData.metricSurvey.surveyname,
        y: 1,
        style: {
          fontSize: '14px',
        }
      },
      subtitle: {
        align: 'right',
        useHTML: true,
        verticalAlign: 'top',
        x: -3,
        y: 7
      },
      xAxis: {
        minRange: 1,
        labels: {
          rotation: -60,
          align: 'right'
        }
      },
      plotOptions: {

        series: {
          pointWidth: 25,
          pointPadding: 0,
          groupPadding: 0.08
        }
      },
      yAxis: {
        labels: {
          format: '{value}%'
        },
        title: {
          text: this.metric.name,
          style: {
            fontWeight: 'bold'
          }
        },
        // plotLines: [
        //   {
        //     color: '#24B6FF',
        //     width: 2,
        //     value: this.benchmark[0] === undefined ? "" : this.benchmark[0].benchmarkValue,
        //     zIndex: 5,
        //     label: {
        //       useHTML: true,
        //       text: '<div class="chartbenchMark"> ' + this.benchmark[0] === undefined ? "" : this.benchmark[0].benchmarkName + '</div>',
        //       align: 'right',
        //       allowOverlap: false,
        //       x: 100,
        //     }
        //   }
        // ],
        tickInterval: 5
      },
      lang: {
        noData: "No data available for the current selection."
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#303030'
        }
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal'
      },
      tooltip: {
        valueSuffix: '%',
        useHTML: true,
        shared: true,
        formatter: function () {
          var title = this.points[0].key;
          var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
          this.points.forEach(element => {
            tooltip += '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + element.y + '%' + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
          });
          return tooltip + '</table>';
        }
      }
    }
  }

  previous() {
    if (this.page > 0) {
      this.page--;
      this.loadReport();
    }
  }

  next() {
    if (this.page < this.pageCount - 1) {
      this.page++;
      this.loadReport();
    }

  }

  delete(metric) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Yes";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        let _fav = [];
        let favorite_graphs = this.sessionService.favoritesGraph || []
        favorite_graphs.forEach(element => {
          if (element.metricid.toLowerCase() !== metric.metricid.toLowerCase())
            _fav.push(element)
        });

        favorite_graphs = _fav;
        this.sessionService.favoritesGraph = favorite_graphs;
        this.updateFavoriteList(this.sessionService.favoritesGraph);
        this.activeModal.close();
        this.refreshMetricsList.emit();
      }
    });
  }

  checklocalStorageDeletedGraph() {
    var metrics = [];
    metrics = JSON.parse(localStorage.getItem('metrics')) || []
  }

  public addToFavorite() {
    this.metric.selectedGroup = localStorage.getItem("selectedGroup") || "";
    this.metric.isFavorite = true;
    let favorite_graphs = this.sessionService.favoritesGraph || [];
    if (Object.keys(favorite_graphs).length === 0) {
      favorite_graphs = [];
      favorite_graphs.push(this.metric)

    }
    else {
      const index = favorite_graphs.findIndex(object => object.metricid === this.metric.metricid);
      if (index === -1) {
        favorite_graphs.push(this.metric)
      }
    }

    this.sessionService.favoritesGraph = favorite_graphs
    this.updateFavoriteList(favorite_graphs);
  }

  public removeFromFavorite() {
    this.metric.isFavorite = true;
    let favorite_graphs = this.sessionService.favoritesGraph || [];
    favorite_graphs.pop(this.metric)
    this.sessionService.favoritesGraph = favorite_graphs
    this.updateFavoriteList(favorite_graphs);
  }


  public updateFavoriteList(favorite_graphs) {
    let _data;
    if (favorite_graphs.length == 0) {
      _data = {};
    }
    else {

      favorite_graphs = favorite_graphs.map((x) => {
        x.graphName = "home";
        x.isFavorite = true;
        return x;
      });
      _data = favorite_graphs
    }

    let recordFound = false
    if (this.additionalsettings == undefined) {
      this.additionalsettings = [];
    }
    else {
      if (Object.keys(this.additionalsettings).length == 0) {
        this.additionalsettings = [];

      }
    }
    this.additionalsettings.forEach((element, i) => {
      if (element.graphName == this.router.url) {
        this.additionalsettings[i] = favorite_graphs;
        recordFound = true;
      }
    });
    if (!recordFound) {
      this.additionalsettings = _data;
    }

    this.msxReportService.saveFavoriteList(this.additionalsettings, false).subscribe(
      (data) => {
        let _data = data;
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  public getFavoriteList() {
    this.msxReportService.getFavoriteList().subscribe(
      (data) => {
        let setting = _.findWhere(data[0].type, { settingname: 'additionalsettings' })
        if (setting) {
          this.additionalsettings = JSON.parse(setting.settingvalue) || [];
          this.favoriteList = _.filter(this.additionalsettings, (f) => f.graphName === "home")[0];
        }
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

}
