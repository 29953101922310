import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class CommunicationService {
    private url = environment.uri;
    selectedGroup = "";
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) {
        this.selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (this.selectedGroup !== "") {
            this.selectedGroup = this.selectedGroup.toLowerCase();
        }
    }

    public get(providerid, patientid, afterdatetime?): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let _url = "";
            if (typeof afterdatetime === "undefined") {
                _url = this.url + "/messaging/threads/" + providerid + "/" + patientid;
            } else {
                _url = this.url + "/messaging/threads/" + providerid + "/" + patientid + "?afterdatetime=" + afterdatetime;
            }
            this.avcHttp.sendRequest("get", _url).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public post(careteamid: string, patientid: string, message: string, tag: string, attachments, provideronly = false, teleRXList = [], cwruid = ""): Observable<any> {
        let hasImage = attachments !== undefined && attachments.length > 0;
        let _data = JSON.stringify({
            message: message,
            messagetype: hasImage ? "attachment" : "text",
            careteamid: careteamid,
            patientid: patientid,
            tag: tag,
            attachments: hasImage ? attachments : [],
            provideronly: provideronly,
            additionaldata: { telerx: teleRXList, cwruid: cwruid },
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/messaging", _data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public sendPDFLinkToPatient(careteamid: string, patientid: string, message: string, tag: string, attachments, provideronly = false, additionalData): Observable<any> {
        let hasImage = attachments !== undefined && attachments.length > 0;
        let _data = JSON.stringify({
            message: message,
            messagetype: hasImage ? "attachment" : "text",
            careteamid: careteamid,
            patientid: patientid,
            tag: tag,
            attachments: hasImage ? attachments : [],
            provideronly: provideronly,
            additionaldata: { data: additionalData },
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/messaging", _data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getThreadList(): Observable<any> {

        this.selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (this.selectedGroup !== "") {
            this.selectedGroup = this.selectedGroup.toLowerCase();
        }

        let careTeam = this.sessionService.activeCareTeam;
        if (careTeam == null) {
            if (this.sessionService.isAdmin() || this.sessionService.isSuperAdmin()) {
                // do nothing, return nothing
                return new Observable((observer: Observer<any>) => {
                    observer.next([{ attributes: {} }]);
                    observer.complete();
                });
            } else {
                location.reload();
            }
        }
        return new Observable((observer: Observer<any>) => {
            let _url = this.url + "/messaging/threads/" + careTeam.careteamid + "?membershipid=" + this.selectedGroup;
            this.avcHttp.sendRequest("get", _url).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public getMessages(patient, tag = "", beforedatetime = "", afterdatetime = "", search = ""): Observable<any> {
        let careTeam = this.sessionService.activeCareTeam;
        let queryString = tag !== "" ? "?tags=" + tag : "";
        if (queryString !== "" && beforedatetime !== "") {
            queryString += "&beforedatetime=" + beforedatetime;
        } else if (beforedatetime !== "") {
            queryString = "?beforedatetime=" + beforedatetime;
        }
        if (queryString !== "" && afterdatetime !== "") {
            queryString += "&afterdatetime=" + afterdatetime;
        } else if (afterdatetime !== "") {
            queryString = "?afterdatetime=" + afterdatetime;
        }
        else if (search !== "") {
            queryString = "?search=" + search;
        }
        return new Observable((observer: Observer<any>) => {
            let _URL = this.url + "/messaging/threads/" + patient.careteamid + "/" + patient.patientid + queryString;
            this.avcHttp.sendRequest("get", _URL).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public tags(careteamId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/messaging/tags?careteamid=" + careteamId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public updatetag(tag, messageid): Observable<any> {
        let _data = JSON.stringify({
            tag: tag,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/messaging/" + messageid, _data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public pushSubscribe(token, careteamid): Observable<any> {
        let careteam = [];
        careteam.push(careteamid);
        if (this.selectedGroup != "") {
            let data = JSON.stringify({
                token: token,
                topics: [this.selectedGroup],
            });

            return new Observable((observer: Observer<any>) => {
                this.avcHttp.sendRequest("post", this.url + "/messaging/notification/subscribe", data).subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
            });
        }

    }

    public pushUnSubscribe(token, careteamid): Observable<any> {
        let careteam = [];
        if (this.sessionService.currentUser == null || this.sessionService.currentUser?.attributes.careteam === undefined) {
            careteam.push(careteamid);
        } else {
            let careteams = this.sessionService.currentUser?.attributes.careteam.careteams;
            careteams.forEach((element) => {
                careteam.push(element.careteamid);
            });
        }
        let data = JSON.stringify({
            token: token,
            topics: [this.selectedGroup]
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/messaging/notification/unsubscribe", data, {}, true).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public updatePriority(patientid: string, priority: string): Observable<any> {
        let _data = JSON.stringify({
            patientid: patientid,
            priority: priority.toLowerCase(),
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/messaging/priority", _data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public patients(careteamId: string, name: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/messaging/threads/" + careteamId + "?name=" + name).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public markMessagesAsRead(patientId: string): Observable<any> {
        let careTeam = this.sessionService.activeCareTeam;

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", `${this.url}/messaging/provider/${careTeam.careteamid}/${patientId}`, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public markMessagesAsUnRead(careteamid: string, patientId: string): Observable<any> {
        let careTeam = this.sessionService.activeCareTeam;

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", `${this.url}/messaging/threads/${careteamid}/${patientId}`, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getMessageUnreadcount(): Observable<any> {
        let careTeam = this.sessionService.activeCareTeam;
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${this.url}/messaging/provider/${careTeam.careteamid}`, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
}
