import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "foodallergies-information-modal",
    templateUrl: "./foodallergies-information-modal.component.html",
    styleUrls: ["./foodallergies-information-modal.component.scss"],
})
export class FoodAllergiesInformationModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    error = "";
    public checklist: Array<any> = [];
    public others: string = "";

    ngOnInit() {
        this.checklist = [
            {
                name: "Eggs",
                value: false,
            },
            {
                name: "Fruits/Vegetables",
                value: false,
                subset: [
                    {
                        name: "Apple",
                        value: false,
                    },
                    {
                        name: "Banana",
                        value: false,
                    },
                    {
                        name: "Cantaloupe",
                        value: false,
                    },
                    {
                        name: "Fig",
                        value: false,
                    },
                    {
                        name: "Garlic",
                        value: false,
                    },
                    {
                        name: "Hot Peppers",
                        value: false,
                    },
                    {
                        name: "Kiwi",
                        value: false,
                    },
                    {
                        name: "Mango",
                        value: false,
                    },
                    {
                        name: "Papaya",
                        value: false,
                    },
                    {
                        name: "Passion Fruit",
                        value: false,
                    },
                    {
                        name: "Peach",
                        value: false,
                    },
                    {
                        name: "Pineapple",
                        value: false,
                    },
                ],
            },
            {
                name: "Grains",
                value: false,
                subset: [
                    {
                        name: "Corn",
                        value: false,
                    },
                    {
                        name: "Oats",
                        value: false,
                    },
                    {
                        name: "Rice",
                        value: false,
                    },
                    {
                        name: "Wheat",
                        value: false,
                    },
                ],
            },
            {
                name: "Meat",
                value: false,
                subset: [
                    {
                        name: "Poultry Meat",
                        value: false,
                    },
                    {
                        name: "Red Meat",
                        value: false,
                    },
                ],
            },
            {
                name: "Peanuts",
                value: false,
            },
            {
                name: "Seafood",
                value: false,
                subset: [
                    {
                        name: "Fish",
                        value: false,
                    },
                    {
                        name: "Shellfish",
                        value: false,
                    },
                ],
            },
            {
                name: "Soy",
                value: false,
            },
            {
                name: "Tree Nuts",
                value: false,
                subset: [
                    {
                        name: "Almonds",
                        value: false,
                    },
                    {
                        name: "Brazil Nuts",
                        value: false,
                    },
                    {
                        name: "Cashews",
                        value: false,
                    },
                    {
                        name: "Chestnuts",
                        value: false,
                    },
                    {
                        name: "Hazelnuts (Filberts)",
                        value: false,
                    },
                    {
                        name: "Hickory Nuts",
                        value: false,
                    },
                    {
                        name: "Macadamia Nuts",
                        value: false,
                    },
                    {
                        name: "Pecans",
                        value: false,
                    },
                    {
                        name: "Pine Nuts (Pinon, Pignolias)",
                        value: false,
                    },
                    {
                        name: "Pistachios",
                        value: false,
                    },
                    {
                        name: "Walnuts",
                        value: false,
                    },
                ],
            },
            {
                name: "Other",
                value: false,
                subset: [
                    {
                        name: "Sesame",
                        value: false,
                    },
                    {
                        name: "Sulfites",
                        value: false,
                    },
                    {
                        name: "Tartrazine",
                        value: false,
                    },
                ],
            },
        ];
        this.setupForm();
    }
    setupForm() {
        let pmh = this.SummaryData.summary.foodallergies.allergies;

        if (pmh && pmh.length > 0) {
            pmh.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = true;

                        if (item.subset && item.subset.length > 0) {
                            if (c.subset && c.subset.length > 0) {
                                item.subset.forEach((isub) => {
                                    c.subset.forEach((csub) => {
                                        if (isub.name === csub.name) {
                                            csub.value = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            });
        }
        if (this.SummaryData.summary.foodallergies.other && this.SummaryData.summary.foodallergies.other.trim().length > 0) {
            this.others = this.SummaryData.summary.foodallergies.other;
        }
    }

    isChecklistValid(): boolean {
        let isValid = true;
        for (let i = 0; i < this.checklist.length; i++) {
            if (this.checklist[i].value === true) {
                if (this.checklist[i].subset && this.checklist[i].subset.length > 0) {
                    let selectedSub = this.checklist[i].subset.filter((i) => i.value === true);
                    if (selectedSub.length === 0) {
                        isValid = false;
                        if (this.checklist[i].name === "Other" && this.others.trim().length > 0) {
                            isValid = true;
                        }
                        if (!isValid) {
                            break;
                        }
                    }
                }
            }
        }

        if (!isValid) {
            this.error = "Please make sure sub-categories are also selected.";
        }

        return isValid;
    }

    getSelectedData() {
        let selectedData = [];

        this.checklist.forEach((item) => {
            if (item.value === true) {
                let i = item;

                if (item.subset) {
                    let s = [];

                    item.subset.forEach((sub) => {
                        if (sub.value === true) {
                            s.push(sub);
                        }
                    });

                    i.subset = s;
                }

                selectedData.push(i);
            }
        });
        return selectedData;
    }
    public onSave() {
        if (this.isChecklistValid()) {
            this.SummaryData.summary.foodallergies = { allergies: this.getSelectedData() };

            if (this.others.trim().length > 0) {
                this.SummaryData.summary.foodallergies["other"] = this.others;
            }
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary, section: "food" });
            this.activeModal.close();
        }
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary, section: "food" });
        this.activeModal.close();
    }
}
