import { Directive, Output, EventEmitter, HostListener } from "@angular/core";

@Directive({
    selector: "[smartLoadMore]",
})
export class SmartLoadMoreDirective {
    @Output() readonly changed = new EventEmitter();

    constructor() {}

    @HostListener("scroll", ["$event"]) onScroll($event) {
        if ($event.srcElement.scrollTop === 0) {
            this.changed.emit({
                loadMore: true,
                updateScroll: function () {
                    $event.srcElement.scrollTo(0, 1);
                },
            });
        }
    }
}
