import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";
import { RoleService } from "./role.service";

@Injectable({
    providedIn: "root",
})
export class UsersService {
    private handler = `/user`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper, private roleService: RoleService, private sessionService: SessionService) { }

    public changePassword(data: any, userid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", `${environment.uri}${this.handler}/msx/password/change`, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public userDetails(userid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", `${this.url}/${userid}`).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public delete(id: string, actiontype = 0): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({ userId: id });
            this.avcHttp.sendRequest("delete", this.url + "/" + id + "?actiontype=" + actiontype, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public addBlacklist(data: any, type = "POST") {
        return Observable.create((observer: Observer<any>) => {
            this.avcHttp.sendRequest(type, environment.uri + "/authorization/blacklist", data, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data.failureCount == 0) {
                        observer.next(body.data);
                    } else {
                        observer.error(body);
                    }
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public addWhitelist(data: any) {
        return Observable.create((observer: Observer<any>) => {
            let Data = data;
            this.avcHttp.sendRequest("post", environment.uri + "/authorization/whitelist", Data, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    observer.next(body);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public create(formData: any, roleid = ""): Observable<any> {
        if (roleid !== "" && roleid !== this.roleService.ROLE_ID_USER) {
            let _roles = [];
            if (roleid === this.roleService.ROLE_ID_PROVIDER_ADMIN) {
                _roles = [this.roleService.ROLE_ID_PROVIDER_ADMIN];
            } else if (roleid === this.roleService.ROLE_ID_PROVIDER) {
                _roles = [this.roleService.ROLE_ID_PROVIDER];
            } else {
                _roles = [roleid];
            }

            let data = JSON.stringify({
                email: formData.email,
                firstname: formData.firstname,
                lastname: formData.lastname,
                isanonymous: false,
                membershipid: this.sessionService.getCurrentUserMembershipId(),
                password: formData.password,
                roles: _roles,
            });

            return new Observable((observer: Observer<any>) => {
                this.avcHttp.sendRequest("post", environment.uri + "/useradmin/inviteasadmin", data).subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
            });
        } else {

            let selectedGroup = localStorage.getItem("selectedGroup") || "";
            if (selectedGroup !== "") {
                selectedGroup = selectedGroup.toLowerCase();
            }
            let data = JSON.stringify({
                email: formData.email,
                firstname: formData.firstname,
                lastname: formData.lastname,
                isanonymous: false,
                membershipid: selectedGroup,
                password: formData.password,
            });
            return new Observable((observer: Observer<any>) => {
                this.avcHttp.sendRequest("post", environment.uri + "/user", data).subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
            });
        }
    }

    public sendReset(email: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/" + encodeURIComponent(email) + "/password/forgot/v2", {}, {}, true).subscribe(
                (data) => {
                    // if(data === "user context or refreshtoken missing"){
                    //   observer.error(data);
                    // }
                    observer.next(data);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public updateUserSettings(formData: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let settingNames = ["gender", "firstname", "lastname", "dob", "postalcode", "subrole", "employer", "hypertension", "diabetes", "weightmanagement", "highcholesterol"];
            let settings = settingNames.filter((name) => typeof formData[name] !== "undefined" && formData[name] !== "").map((name) => ({ settingname: name, settingvalue: formData[name] }));

            let data = JSON.stringify({
                userid: formData.id,
                settings: settings,
            });

            this.avcHttp.sendRequest("post", environment.uri + "/settings", data).subscribe(
                (data) => {
                    observer.next(this.helper.ParseToJson(data));
                    observer.next({});
                    observer.complete();
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public userPhoneNumber(user: any): Observable<any> {
        let data = JSON.stringify({
            email: user.email,
            phonenumber: user.phonenumber,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", `${this.url}/${user.userid}`, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public unsubscribeUser(userId): Observable<any> {
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
        let data = JSON.stringify({
            telerxid: telerxid,
            userid: userId,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("DELETE", `${environment.uri}/` + "telerx/subscription/user", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public deleteFromMemberShip(userId): Observable<any> {
        let telerxid = this.sessionService.currentUser?.attributes.membership.telerx.telerxid;
        let data = JSON.stringify({
            telerxid: telerxid,
            userid: userId,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("DELETE", `${environment.uri}` + "/telerx/subscription/user/", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getAllUsers(name = "", limit = 10, pageno = 1, orderby = "firstname", order = "asc", role = 'all'): Observable<any> {
        let apiUrl = "";

        apiUrl =
            environment.uri +
            "/telerx/userlist/" +
            this.sessionService.getCurrentUserMembershipId() +
            "?type=" +
            (role ? role : "all") +
            "&limit=" +
            limit +
            "&pageno=" +
            pageno +
            "&orderby=" +
            orderby +
            "&order=" +
            order +
            "&name=" +
            encodeURIComponent(name);

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", apiUrl, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        body.data[0].attributes.list = body.data[0].attributes.list.filter((user) => {
                            return user.email.substring(0, 4) !== "fake";
                        });

                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public updateUserRole(userid, roleid): Observable<any> {
        let _roles = [{ roleid: roleid }];

        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                userid: userid,
                roles: _roles,
            });
            this.avcHttp.sendRequest("post", this.url + "/roles", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data) {
                        observer.next(body);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getUserSetting(userid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/user/settings/" + userid, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public updateSettings(pic): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                userid: this.sessionService.currentUser.id,
                settings: [
                    {
                        settingname: "profilepic",
                        settingvalue: pic,
                    },
                ],
            });

            this.avcHttp.sendRequest("post", environment.uri + "/settings", data).subscribe(
                (data) => {
                    // observer.next(this.helper.ParseToJson(data));
                    // observer.next({});
                    // observer.complete();
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getUserByEmail(email: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/user/details/" + encodeURIComponent(email), {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }


}
