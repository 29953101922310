<div class="container-fluid">
    <div class="row p-3 float-right">
        <button (click)="addEmployer()" class="float-left btn-primary btn"><i class="fas fa-plus-circle"></i>&nbsp;New Employer</button>
    </div>
    <table class="table table-hover mt-3" style="text-align: left">
        <thead>
            <tr>
                <th scope="col">Employer Name</th>
                <th scope="col">Registration Date</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of Employers">
                <td class="input-td">
                    {{ e.employername }}
                </td>
                <td class="input-td">{{ e.registrationdate | date: "MM/dd/yyyy" }}</td>
                <td>
                    <i title="Edit Employer" (click)="editEmployer(e)" class="fa fa-edit mr-3 cursor-pointer"></i>
                    <i title="Delete Employer" (click)="onDeleteEmployer(e)" class="fas fa-trash-alt mr-3 cursor-pointer"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>
