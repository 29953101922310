import { Injectable } from "@angular/core";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { environment } from "../../environments/environment";
import { Observable, Observer } from "rxjs";

@Injectable()
export class NotificationService {
    url: string = `${environment.uri}/push`;

    constructor(private avcHttp: AvcHttp, private helper: Helper) {}

    public getPushUsers(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/users", {}).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    data = data.data[0].attributes.users;
                    if (data) {
                        observer.next(data);
                        observer.complete();
                    } else {
                        observer.error("Error loading push users");
                        observer.complete();
                    }
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public sendPush(formData, _countuser): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = {
                title: "",
                message: formData.message,
                user: formData.users,
                employer: formData.employer,
                disease: formData.disease,
                minage: formData.minage,
                maxage: formData.maxage,
                gender: formData.gender,
                countuser: _countuser,
            };

            for (var propName in data) {
                if (data[propName] == null || data[propName] === undefined || data[propName] === "") {
                    delete data[propName];
                }
            }
            let _data = JSON.stringify(data);
            this.avcHttp.sendRequest("post", this.url + "/send/custom", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.errors) {
                        observer.error(data.errors);
                        observer.complete();
                    } else {
                        observer.next(data);
                        observer.complete();
                    }
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
}
