import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "familyhistory-modal",
    templateUrl: "./familyhistory-modal.component.html",
    styleUrls: ["./familyhistory-modal.component.scss"],
})
export class FamilyHistoryModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public checklist: Array<any> = [];
    public others: string;
    error = "";
    ngOnInit() {
        this.checklist = [
            {
                name: "Alcoholism",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Anemia",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Asthma",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Blood Disorder",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Cancer",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Cystic Fibrosis",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Dementia",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Diabetes",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Heart Attack prior to 50 years of age",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Heart Disease",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "High Blood Pressure (Hypertension)",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "High Cholesterol (Hyperlipidemia)",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Kidney Disease",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Mental Illness",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Osteoporosis",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Seizures/Epilepsy",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Sickle Cell",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
            {
                name: "Stroke (Cerebral Infarction)",
                value: {
                    Father: false,
                    Mother: false,
                    "Maternal Grandparent": false,
                    "Paternal Grandparent": false,
                },
            },
        ];
        this.setupForm();
    }
    setupForm() {
        let familyhistory = this.SummaryData.summary.familyhistory.history;

        if (familyhistory && familyhistory.length > 0) {
            familyhistory.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        item.value.forEach((h) => {
                            c.value[h] = true;
                        });
                    }
                });
            });
        }

        if (this.SummaryData.summary.familyhistory.other) {
            this.others = this.SummaryData.summary.familyhistory.other;
        }
    }
    getValues(valueOb) {
        let vals = Object.keys(valueOb);
        let values = [];

        vals.forEach((v) => {
            if (valueOb[v] === true) {
                values.push(v);
            }
        });

        return values;
    }

    getSelectedData(): Array<any> {
        let selectedData = [];

        this.checklist.forEach((item) => {
            let i = { name: item.name, value: this.getValues(item.value) };
            selectedData.push(i);
        });
        return selectedData;
    }
    onSave() {
        let data = this.getSelectedData();
        this.SummaryData.summary["familyhistory"] = { history: data };

        if (this.others) {
            this.SummaryData.summary.familyhistory["other"] = this.others;
        }
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
