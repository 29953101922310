<div class="modal-body">
    <div class="category-header">
        <label>Vaccines</label>
    </div>
    <div class="main-row">
        <div class="row checklist-row">
            <div class="inp-items col-lg-12 checklist-columns" *ngFor="let item of checklist; let i = index">
                <label> {{ item.name }} </label>
                <div class="checkboxes">
                    <div class="chkbox-1">
                        <input class="checkbox-input" id="check_{{ i }}_yes" name="vaccines-radio[{{i}}]" [(ngModel)]="checklist[i].value" value="Yes" type="radio" [checked]="item.value === 'Yes'" />
                        <label for="check_{{ i }}_yes" class="span-name">Yes</label>
                    </div>
                    <div class="chkbox-2">
                        <input class="checkbox-input" id="check_{{ i }}_no" name="vaccines-radio[{{i}}]" [(ngModel)]="checklist[i].value" value="No" type="radio" [checked]="item.value === 'No'" />
                        <label for="check_{{ i }}_no" class="span-name">No</label>
                    </div>
                    <div class="chkbox-3">
                        <input class="checkbox-input" id="check_{{ i }}_know" name="vaccines-radio[{{i}}]" [(ngModel)]="checklist[i].value" value="Dont Know" type="radio" [checked]="item.value === 'Dont Know'" />
                        <label for="check_{{ i }}_know" class="span-name">Don't Know</label>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
    </div>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>    
    </div>
</div>