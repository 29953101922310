<div class="modal-body">
    <div class="category-header">
        <label>COVID 19 Vaccination</label>
    </div>
    <form class="modal-body-form" [formGroup]="covid19Form">
        <div class="main-row">
            <div class="main-full">
                <div class="form-group">
                    <label>Are you vaccinated for COVID 19?</label>
                    <div class="checkboxes">
                        <div class="chkbox-1">
                            <input class="checkbox-input" id="notyet" name="vaccinationstatus"
                                [(ngModel)]="vaccinationstatus" [ngModelOptions]="{standalone: true}"
                                (change)="updateValidations()" value="notyet" type="radio"
                                [checked]="vaccinationstatus === 'notyet'" />
                            <label for="notyet" class="span-name">Not Vaccinated</label>
                        </div>
                        <div class="chkbox-2">
                            <input class="checkbox-input" id="partial" name="vaccinationstatus"
                                [(ngModel)]="vaccinationstatus" [ngModelOptions]="{standalone: true}"
                                (change)="updateValidations()" value="partial" type="radio"
                                [checked]="vaccinationstatus === 'partial'" />
                            <label for="partial" class="span-name">Partially Vaccinated</label>
                        </div>
                        <div class="chkbox-3">
                            <input class="checkbox-input" id="full" name="vaccinationstatus"
                                [(ngModel)]="vaccinationstatus" [ngModelOptions]="{standalone: true}"
                                (change)="updateValidations()" value="full" type="radio"
                                [checked]="vaccinationstatus === 'full'" />
                            <label for="full" class="span-name">Full Vaccinated </label>
                        </div>
                    </div>
                    <div class="inp-err"
                        *ngIf="(vaccinationstatus !== 'full') && (vaccinationstatus !== 'partial') && (vaccinationstatus !== 'notyet') && showErr">
                        <span class="error-msg">
                            Please select a value.
                        </span>
                    </div>
                </div>
                <div class="form-group upload-btn-wrapper">
                    <label>Vaccination Card</label>
                    <div class="image-block">
                        <div class="imagePreview">
                            <!-- <input type="file" accept="image/*" (change)="showPreview($event)" />
                            <button [ngClass]="isInvalidImage ? 'uploadbtnDirty' : 'uploadbtn'">
                                <img class="photoid-image"
                                    [src]="imageURL ? imageURL : '../../../assets/images/add-image-health.png'" />
                            
                            </button> -->

                            <img (click)="showImage(imageURL ? imageURL : '../../../assets/images/add-image-health.png')"
                                class="photoid-image"
                                [src]="imageURL ? imageURL : '../../../assets/images/add-image-health.png'" />
                            <div style="display: flex;" class="upload-btn-wrapper mt-2">
                                <button [ngClass]="isInvalidImage ? 'uploadbtnDirty' : ''"
                                    (change)="showPreview($event)" class="btn btn-secondary">Upload Image</button>
                                <input type="file" accept="image/*" (change)="showPreview($event)" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-row">
            <div class="main-left">
                <div class="section-block-left" [hidden]="!vaccinationstatus || vaccinationstatus === 'notyet'">
                    <div class="category-header">
                        <label class="category-header-black-label">Dose 1 Details</label>
                    </div>
                    <div class="form-group">
                        <label>Manufacturer</label>
                        <input maxlength="50" [ngClass]="{ error: isFieldValid('dose1manufacturer') }"
                            formControlName="dose1manufacturer" type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose1manufacturer'].errors && covid19Form.controls['dose1manufacturer'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose1manufacturer'].hasError('required') || covid19Form.controls['dose1manufacturer'].hasError('ValidateWhiteSpace')">
                                Manufacturer is required.
                            </span>
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose1manufacturer'].hasError('maxlength') && !covid19Form.controls['dose1manufacturer'].hasError('required')">
                                Manufacturer can be max 50 characters.
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Expiration Date</label>
                        <input [ngClass]="{ error: isFieldValid('dose1expirationdate') }"
                            formControlName="dose1expirationdate" type="date" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose1expirationdate'].errors && covid19Form.controls['dose1expirationdate'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose1expirationdate'].hasError('required') || covid19Form.controls['dose1expirationdate'].hasError('ValidateWhiteSpace')">Expiration
                                Date is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Lot Number</label>
                        <input maxlength="20" [ngClass]="{ error: isFieldValid('dose1lotnumber') }"
                            formControlName="dose1lotnumber" type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose1lotnumber'].errors && covid19Form.controls['dose1lotnumber'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose1lotnumber'].hasError('required')|| covid19Form.controls['dose1lotnumber'].hasError('ValidateWhiteSpace')">Lot
                                Number is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Vaccination Date</label>
                        <input [ngClass]="{ error: isFieldValid('dose1date') }" formControlName="dose1date" type="date"
                            class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose1date'].errors && covid19Form.controls['dose1date'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose1date'].hasError('required') || covid19Form.controls['dose1date'].hasError('ValidateWhiteSpace')">Vaccination
                                Date is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Facility</label>
                        <input [ngClass]="{ error: isFieldValid('dose1facility') }" formControlName="dose1facility"
                            type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose1facility'].errors && covid19Form.controls['dose1facility'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose1facility'].hasError('required') || covid19Form.controls['dose1facility'].hasError('ValidateWhiteSpace')">Facility
                                is required. </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <div class="section-block-right" [hidden]="vaccinationstatus !== 'full'">
                    <div class="category-header">
                        <label class="category-header-black-label">Dose 2 Details</label>
                    </div>
                    <div class="form-group">
                        <label>Manufacturer</label>
                        <input maxlength="50" [ngClass]="{ error: isFieldValid('dose2manufacturer') }"
                            formControlName="dose2manufacturer" type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose2manufacturer'].errors && covid19Form.controls['dose2manufacturer'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose2manufacturer'].hasError('required') || covid19Form.controls['dose2manufacturer'].hasError('ValidateWhiteSpace')">
                                Manufacturer is required.
                            </span>
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose2manufacturer'].hasError('maxlength') && !covid19Form.controls['dose2manufacturer'].hasError('required')">
                                Manufacturer can be max 50 characters.
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Expiration Date</label>
                        <input [ngClass]="{ error: isFieldValid('dose2expirationdate') }"
                            formControlName="dose2expirationdate" type="date" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose2expirationdate'].errors && covid19Form.controls['dose2expirationdate'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose2expirationdate'].hasError('required') || covid19Form.controls['dose2expirationdate'].hasError('ValidateWhiteSpace')">Expiration
                                Date is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Lot Number</label>
                        <input [ngClass]="{ error: isFieldValid('dose2lotnumber') }" formControlName="dose2lotnumber"
                            type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose2lotnumber'].errors && covid19Form.controls['dose2lotnumber'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose2lotnumber'].hasError('required') || covid19Form.controls['dose2lotnumber'].hasError('ValidateWhiteSpace')">Lot
                                Number is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Vaccination Date</label>
                        <input [ngClass]="{ error: isFieldValid('dose2date') }" formControlName="dose2date" type="date"
                            class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose2date'].errors && covid19Form.controls['dose2date'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose2date'].hasError('required') || covid19Form.controls['dose2date'].hasError('ValidateWhiteSpace')">Vaccination
                                Date is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Facility</label>
                        <input [ngClass]="{ error: isFieldValid('dose2facility') }" formControlName="dose2facility"
                            type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose2facility'].errors && covid19Form.controls['dose2facility'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose2facility'].hasError('required') || covid19Form.controls['dose2facility'].hasError('ValidateWhiteSpace')">Facility
                                is required. </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-row">
            <div class="main-left">
                <div class="section-block-left" [hidden]="vaccinationstatus !== 'full'">
                    <div class="category-header">
                        <label class="category-header-black-label">Booster Dose</label>
                    </div>
                    <div class="form-group">
                        <label>Manufacturer</label>
                        <input maxlength="50" [ngClass]="{ error: isFieldValid('dose3manufacturer') }"
                            formControlName="dose3manufacturer" type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose3manufacturer'].errors && covid19Form.controls['dose3manufacturer'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose3manufacturer'].hasError('required') || covid19Form.controls['dose3manufacturer'].hasError('ValidateWhiteSpace')">
                                Manufacturer is required.
                            </span>
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose3manufacturer'].hasError('maxlength') && !covid19Form.controls['dose3manufacturer'].hasError('required')">
                                Manufacturer can be max 50 characters.
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Expiration Date</label>
                        <input [ngClass]="{ error: isFieldValid('dose3expirationdate') }"
                            formControlName="dose3expirationdate" type="date" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose3expirationdate'].errors && covid19Form.controls['dose3expirationdate'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose3expirationdate'].hasError('required') || covid19Form.controls['dose3expirationdate'].hasError('ValidateWhiteSpace')">Expiration
                                Date is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Lot Number</label>
                        <input maxlength="20" [ngClass]="{ error: isFieldValid('dose3lotnumber') }"
                            formControlName="dose3lotnumber" type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose3lotnumber'].errors && covid19Form.controls['dose3lotnumber'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose3lotnumber'].hasError('required')|| covid19Form.controls['dose3lotnumber'].hasError('ValidateWhiteSpace')">Lot
                                Number is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Vaccination Date</label>
                        <input [ngClass]="{ error: isFieldValid('dose3date') }" formControlName="dose3date" type="date"
                            class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose3date'].errors && covid19Form.controls['dose3date'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose3date'].hasError('required') || covid19Form.controls['dose3date'].hasError('ValidateWhiteSpace')">Vaccination
                                Date is required. </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Facility</label>
                        <input [ngClass]="{ error: isFieldValid('dose3facility') }" formControlName="dose3facility"
                            type="text" class="form-control" focus="true" />
                        <div class="inp-err"
                            *ngIf="covid19Form.controls['dose3facility'].errors && covid19Form.controls['dose3facility'].touched">
                            <span class="error-msg"
                                *ngIf="covid19Form.controls['dose3facility'].hasError('required') || covid19Form.controls['dose3facility'].hasError('ValidateWhiteSpace')">Facility
                                is required. </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <p *ngIf="error" class="text-danger">{{ error }}</p>
    <div class="main-row">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>
    </div>
</div>