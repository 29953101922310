<div class="container-fluid">
    <div class="tab">
        <a class="tablinks active" [routerLink]="['/media/activity']">
            <img src="/assets/msx/social-icon-white.png">Social Media Activity
        </a>
        <a class="tablinks" [routerLink]="['/media/reviews']">
            <i class="fa-solid fa-reply"></i> Respond to Reviews
        </a>
    </div>

    <form [formGroup]="mediaForm">
        <h3>Edit Social Links</h3>
        <div class="row">
            <div class="col-1 pr-0 text-right">
                <label style="margin-top: 10px;">Practice</label>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <select formControlName="practice" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.practice.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let p of practices" [ngValue]="p">{{ p.name }}</option>
                    </select>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1 pr-0 text-right">
                <label style="margin-top: 10px;">Location</label>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <select formControlName="location" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.location.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let l of locations" [ngValue]="l">{{ l.name }}</option>
                    </select>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div class="row float-right">
                        <button (click)="addNew()" class="btn btn-primary m-2">Add New</button>
                    </div>

                    <table class="table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Social Media</th>

                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of selectedProviders">
                                <td>{{p.provider_name}}</td>
                                <td *ngFor="let l of p.list">
                                    {{l.socialmediatype}} ({{l.pages[0].page_name}})
                                </td>

                                <td>
                                    <div class="row my-2" *ngFor="let l of p.list">
                                        <!--<button class="btn" (click)="edit(p,l)">
                                            <i class="fa fa-edit"></i>
                                        </button>-->
                                        <button (click)="onDelete(p,l)" class="btn">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>




        <!-- <div class="row">
        <div class="col-2 text-right">
            <label>Google:</label>
        </div>
        <div class="col-4">
            <input type="checkbox" id="google" value="google">
            &nbsp; <label for="google"> Show in Survey</label>
        </div>
        <div class="col-6">
            <button class="btn btn-primary">Connect</button>
        </div>
    </div> -->


        <div *ngIf="socialLinks.length" class="row mt-2">
            <div class="col-2 text-right">
                <label>Facebook Page(s):</label>
            </div>
            <div class="col-6">
                <ng-container *ngFor="let s of socialLinks">
                    <ng-container *ngFor="let p of s['facebook']?.pages">
                        <span class="badge bg-secondary"> {{p.page_name}}</span> &nbsp;
                    </ng-container>
                </ng-container>
            </div>
        </div>


        <div *ngIf="socialLinks.length" class="row mt-2">
            <div class="col-2 text-right">
                <label>Yelp Page(s):</label>
            </div>

            <div class="col-6">
                <ng-container *ngFor="let s of socialLinks">
                    <ng-container *ngFor="let p of s['yelp']?.pages">
                        <span class="badge bg-secondary"> {{p.page_name}}</span> &nbsp;
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div *ngIf="userPages" class="row mt-2">
            <div class="col-2 text-right">
                <label style="margin-top: 10px;">Pages:</label>
            </div>
            <div class="col-7">
                <div class="form-group">
                    <ng-multiselect-dropdown formControlName="page_id" [placeholder]="'Please Select'"
                        style="width:100%" [settings]="facebookPageSettings" [data]="userPages"
                        [(ngModel)]="selectedFBPages" (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)"
                        [ngClass]="{ 'is-invalid': submitted && form.page_id.errors }">
                    </ng-multiselect-dropdown>
                    <span *ngIf="submitted && form.page_id.errors"
                        [ngClass]="{ 'is-invalid': submitted && form.page_id.errors }" style="color:red;">
                        Please select a page.
                    </span>
                    <!-- <select [(ngModel)]="page_id" formControlName="page_id" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.page_id.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let p of userPages" [ngValue]="p">{{ p.name }}</option>
                    </select> -->
                </div>
            </div>
        </div>
        <div *ngIf="this.userPages" class="row mt-2">
            <button (click)="onCancel();" type="button" class="btn btn-secondary ml-2 mr-2">Cancel</button>
            <button (click)="onSubmit();" class="btn btn-primary">Save</button>

        </div>
    </form>

</div>