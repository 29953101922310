<div class="container-fluid">

    <div class="tab">
        <a class="tablinks" [routerLink]="['/administration']">
            <img src="/assets/msx/admin.svg">Administration
        </a>
        <a class="tablinks active" [routerLink]="['/schedule-report']">
            <img src="/assets/msx/automated-white.svg">Automated Reports
        </a>
    </div>

    <div class="row">
        <div class="input-group col-6 mb-3"></div>
        <div style="display: block; text-align: right" class="input-group col-6 mb-3"></div>
    </div>
    <div class="row align-items-center">
        <div class="col-4">
            <div class="search-div">
                <input type="text" (keyup)="reportSearch()" [(ngModel)]="searchText" class="form-control search"
                    placeholder="Search" />
                <img (click)="clearSearch()" src="/assets/msx/search.svg">
            </div>
        </div>
        <div class="col-2">

        </div>
        <div class="input-group col-6" style="display: none; text-align: right; vertical-align: middle">
            <label class="mb-0"><input type="checkbox" />&nbsp; Disable Default Report Card</label>
        </div>

    </div>




    <div *ngIf="!NoRecordsFound">
        <table class="table-striped datatable">
            <thead>
                <tr>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                        sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of resultList$ | async">
                    <td>{{r?.fvrtschedules?.reportName}}</td>
                    <td style="text-transform: lowercase;">
                        {{r?.fvrtschedules?.recipients}}
                    </td>
                    <td class="text-center">
                        <input *ngIf="r?.fvrtschedules?.favorites.includes('surveyData')" type="checkbox" checked
                            disabled>
                    </td>
                    <td class="text-center">
                        <input *ngIf="r?.fvrtschedules?.favorites.includes('visitData')" type="checkbox" checked
                            disabled>
                    </td>
                    <td class="text-center">
                        <input *ngIf="r?.fvrtschedules?.favorites.includes('billingData')" type="checkbox" checked
                            disabled>
                    </td>
                    <td>
                        <img (click)="editReport(r)" class="mr-2 cursor-pointer" src="/assets/msx/edit.svg">
                        <img (click)="deleteReport(r)" class="cursor-pointer" src="/assets/msx/delete.svg">
                    </td>
            </tbody>
        </table>

        <div class="pagination justify-content-between align-items-center">
            <ngb-pagination [collectionSize]="resultsLength" [pageSize]="pageSize" [(page)]="pageIndex" [maxSize]="5"
                [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>

            <div>
                <label class="mr-3 mt-1">Items per page:</label>
                <select class="custom-select form-control" [(ngModel)]="pageSize" (ngModelChange)="setPageSize()">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                </select>
            </div>
        </div>


    </div>


</div>