<div class="modal-body">
    <div class="category-header">
        <label>{{ _isEdit ? "Edit Location" : "New Location" }}</label>
    </div>
    <form [formGroup]="locationForm" (ngSubmit)="saveChanges()">
        <div class="container-fluid">
            <div class="col-lg-12">
                <div class="form-group">
                    <label>Location Name:</label>
                    <input required type="text" formControlName="practicename" class="form-control" [ngClass]="{
                            error: (locationForm.controls['practicename'].errors && locationForm.controls['practicename'].touched) || (locationForm.controls['practicename'].errors && submitted)
                        }" />
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <label>Address 1:</label>
                    <input required type="text" formControlName="address1" class="form-control"
                        [ngClass]="{ error: (locationForm.controls['address1'].errors && locationForm.controls['address1'].touched) || (locationForm.controls['address1'].errors && submitted) }" />
                </div>
            </div>
            <div class="col-lg-12 mt-2">
                <div class="form-group">
                    <label>Address 2:</label>
                    <input type="text" formControlName="address2" class="form-control" />
                </div>
            </div>
            <div class="col-lg-12 row mt-2 mx-0">
                <div class="col-6 pl-0">
                    <div class="form-group">
                        <label>City:</label>
                        <input required type="text" formControlName="city" class="form-control" [ngClass]="{
                                error: (locationForm.controls['city'].errors && locationForm.controls['city'].touched) || (locationForm.controls['city'].errors && submitted)
                            }" />
                    </div>
                </div>
                <div class="col-4 px-0">
                    <div class="form-group">
                        <label>State:</label>

                        <select formControlName="state" class="form-control" [ngClass]="{
                                error: (locationForm.controls['state'].errors && locationForm.controls['state'].touched) || (locationForm.controls['state'].errors && submitted)
                            }">
                            <option value="">Please select</option>
                            <option *ngFor="let s of states" [value]="s.name">{{ s.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-2 pr-0">
                    <div class="form-group">
                        <label>Country:</label>
                        <input [attr.disabled]="true" required type="text" formControlName="country"
                            class="form-control" [ngClass]="{
                                error: (locationForm.controls['country'].errors && locationForm.controls['country'].touched) || (locationForm.controls['country'].errors && submitted)
                            }" />
                    </div>
                </div>
            </div>
            <div class="col-lg-12 row mt-2 mx-0">
                <div class="col-6 pl-0">
                    <div class="form-group">
                        <label>ZIP Code:</label>
                        <input required type="text" formControlName="postalcode" class="form-control" [ngClass]="{
                                error: (locationForm.controls['postalcode'].errors && locationForm.controls['postalcode'].touched) || (locationForm.controls['postalcode'].errors && submitted)
                            }" />
                    </div>
                </div>
                <div class="col-6 pr-0">
                    <div class="form-group">
                        <label>Phone:</label>
                        <input required type="text" formControlName="phonenumber" class="form-control" [ngClass]="{
                                error: (locationForm.controls['phonenumber'].errors && locationForm.controls['phonenumber'].touched) || (locationForm.controls['phonenumber'].errors && submitted)
                            }" />
                    </div>
                </div>
            </div>
            <div class="col-lg-12 row mx-0 mt-2">
                <div class="col-6 pl-0">
                    <div class="form-group">
                        <label>Default Location:</label> <br />
                        <input type="checkbox" formControlName="isdefault" class="input-checkbox" />
                    </div>
                </div>
            </div>
            <div *ngIf="_isEdit" class="col-lg-12 row mx-0 mt-2">
                <div class="col-6 pl-0">
                    <div class="form-group">
                        <label>Lat:</label>
                        <input [attr.disabled]="_isEdit" type="text" formControlName="lat" class="form-control" />
                    </div>
                </div>
                <div class="col-6 pr-0">
                    <div class="form-group">
                        <label>Lng:</label>
                        <input [attr.disabled]="_isEdit" type="text" formControlName="lng" class="form-control" />
                    </div>
                </div>
            </div>

            <div class="submit col-lg-12 row mt-2 mx-0">
                <div class="col col-6 pl-0">
                    <button (click)="onCancel()" type="button"
                        class="btn btn-secondary move-buttons-grey form-control">Cancel</button>
                </div>
                <div class="col col-6 pr-0">
                    <button type="submit" class="btn btn-primary form-control">{{ _isEdit ? "Save" : "Add" }}</button>
                </div>
            </div>
        </div>
    </form>
</div>