import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { OnboardingService } from "../../services/onboarding.service";
import { TeleRXService } from "../../services/teleRX.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { SpecialtyService } from "../../services/specialty.service";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { WaitingRoomAddModalComponent } from "../../modals/waiting-room-add-modal/waiting-room-add-modal.component";
import { BadWordAddModalComponent } from "../../modals/bad-word-add-modal/bad-word-add-modal.component";
import { SessionService } from "../../services/session.service";
import { MessagingService } from "../../services/messaging.service";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
@Component({
    selector: "app-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
    public membership: any;
    public btnDisabled = false;
    public btnImgDisabled = false;
    public isValidCsv = false;
    public isImageEdit = false;
    public isValidImg = false;
    public bulkFileToUpload: any;
    public imageFileToUpload: any;
    public tabOrderSettings: any;
    public waitingRooms = [];
    public specialties = [];
    public selectSpecialties = [];
    chatFilters = [];
    show_workflow_for_carenav_only = false;
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    displayedColumns = [
        { displayname: "word", columnname: "badword" },
        { displayname: "action", columnname: "" },
    ];
    filterText = "";
    error = "";
    showLoader = false;
    public settingForm = this.fb.group({
        name: ["", Validators.required],
        description: ["", Validators.required],
        telerxtype: ["", Validators.required],
        providerdashboardurl: [""],
        callbackeventurl: [""],
        npi: ["", Validators.maxLength(10)],
        profilerequired: [false],
        enablerequest: [false],
        chatbot: [false],
        phq9: [false],
        gad7: [false],
        sleepassessment: [false],
        specialty: [""],
        searchenabled: [false],
        enableUserSelfGoals: [false]
    });


    public teleRx: any;
    public userRole = this.sessionService.currentUserRole;
    public joinURL = `https://join.clio.health/${this.sessionService.currentUser?.attributes.membership.telerx.telerxid}`;

    constructor(
        private snackbar: SnackbarService,
        private fb: UntypedFormBuilder,
        private onboarding: OnboardingService,
        private teleRxservice: TeleRXService,
        private modalService: NgbModal,
        private waitingRoomService: WaitingRoomService,
        private specialtyService: SpecialtyService,
        public sessionService: SessionService,
        public messagingService: MessagingService,
        public tableService: TableService,

    ) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }
    waitingRoomWorkflowSettings = [
        { name: "Show Symptom Selector in Appointment Request", value: "showSymptomSelector", selected: false },
        { name: "Show Provider Search", value: "showProviderSearch", selected: false },
        { name: "Use ICD10 Codes", value: "useICD10Codes", selected: false },
        { name: "Use CPT Codes", value: "useCPTCodes", selected: false },
        { name: "User Billing", value: "userBilling", selected: false },
    ]
    ngOnInit() {
        this.show_workflow_for_carenav_only = environment.show_workflow_for_carenav_only;
        this.tabOrderSettings = this.sessionService.currentUser.attributes.membership.providerpanelsettings.tabOrderSettings;
        this.teleRxservice.get().subscribe(
            (data) => {
                this.membership = data[0].type.membership;
                let _name = this.membership["membershipname"].replace("TELERX-", "");
                this.settingForm.patchValue({
                    name: _name,
                    description: this.membership.description,
                    telerxtype: this.membership.telerxtype,
                    providerdashboardurl: this.membership.providerdashboardurl,
                    callbackeventurl: this.membership.callbackeventurl,
                    npi: this.membership.npi,
                    profilerequired: this.membership.profilerequired,
                    enablerequest: this.membership.enablerequest,
                    chatbot: this.membership.chatbot,
                    searchenabled: this.membership.searchenabled,
                    phq9: this.membership.phq9,
                    gad7: this.membership.gad7,
                    sleepassessment: this.membership.sleepassessment,
                    enableUserSelfGoals: this.membership.additional_settings.enableUserSelfGoals,
                });
                if (this.membership && this.membership.additional_settings) {
                    if (this.membership.additional_settings.waitingRoomWorkflowSettings == undefined) {
                        this.membership.additional_settings.waitingRoomWorkflowSettings = {
                            "useCPTCodes": false,
                            "userBilling": false,
                            "useICD10Codes": false,
                            "showProviderSearch": false,
                            "showSymptomSelector": false
                        }
                    }
                    let _workFlow = this.membership.additional_settings.waitingRoomWorkflowSettings
                    this.waitingRoomWorkflowSettings.forEach(element => {
                        element.selected = _workFlow[element.value]
                    });
                }
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );

        this.teleRx = this.sessionService.currentUser?.attributes.membership.telerx;
        this.getWaitingRooms();
        this.getSpecialties();
    }

    public saveChanges(workFlow: any = {}) {
        if (this.settingForm.valid) {
            this.teleRxservice.update(this.settingForm.value, "", workFlow).subscribe(
                (data) => {
                    if (this.settingForm.value.telerxtype === "service") {
                        this.specialtyService.postSpecialties(this.selectSpecialties.map((el) => el.spid)).subscribe(
                            (data) => {
                                this.snackbar.show("Updated Successfully", "success");
                            },
                            (err) => {
                                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                            }
                        );
                    } else {
                        this.snackbar.show(data[0].type.message, "success");
                    }


                },
                (err) => {
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            this.snackbar.show("Please provide mandatory fields.", "danger");
        }
    }

    setDefaultPic() {
        this.teleRx.logourl = "assets/images/no-image.png";
    }

    public chooseFile($event: any) {
        const file = $event.srcElement.files;
        const fileExtension = file[0].name.substr(file[0].name.lastIndexOf(".") + 1);
        if (fileExtension !== "csv") {
            this.isValidCsv = false;
            this.snackbar.show("Please choose valid csv file", "danger");
        } else {
            this.bulkFileToUpload = file[0];
            this.isValidCsv = true;
            this.btnDisabled = false;
        }
    }

    public async uploadBulkFile() {
        try {
            this.btnDisabled = true;
            const result = await this.onboarding.importCsv(this.bulkFileToUpload);
            const mergeTempOnboarding = await this.onboarding.mergeTempOnboarding();

            this.snackbar.show(mergeTempOnboarding[0].attributes.data, "success");
            this.btnDisabled = false;
            this.isValidCsv = false;
        } catch (error) {
            this.snackbar.show(error[0].detail, "danger");
            this.btnDisabled = false;
            this.isValidCsv = false;
        }
    }

    public chooseImageFile($event: any) {
        const file = $event.srcElement.files;
        const fileExtension = file[0].name.substr(file[0].name.lastIndexOf(".") + 1).toLowerCase();
        if (!["png", "jpg", "jpeg"].includes(fileExtension)) {
            this.snackbar.show("Please choose valid image file", "danger");
            this.isValidImg = false;
        } else {
            this.imageFileToUpload = file[0];
            this.btnImgDisabled = false;
            this.isValidImg = true;
        }
    }

    public async uploadImageFile() {
        try {
            this.btnImgDisabled = true;
            const result = await this.teleRxservice.uploadLogo(this.imageFileToUpload);

            this.teleRx.logourl = result;

            this.snackbar.show("Uploaded logo.", "success");

            this.btnImgDisabled = false;
            this.isValidImg = false;
        } catch (error) {
            console.log(error);
            this.snackbar.show(error[0].detail, "danger");
            this.btnImgDisabled = false;
            this.isValidImg = false;
        }
    }

    public modalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            // console.log("dropped Event", `> dropped '${event.item.data}' into '${event.container.id}'`);
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            let memberShip = this.sessionService.currentUser.attributes.membership;
            this.tabOrderSettings.forEach((element, index) => {
                element.order = index + 1;
            });

            this.teleRxservice.updateRooms(this.tabOrderSettings, this.waitingRooms).subscribe(
                (data) => {
                    this.membership = data[0].type.membership;
                    let tabSettings = this.sessionService.patientDashboardTabSetting;
                    tabSettings.tabOrderSettings = this.tabOrderSettings;
                    this.sessionService.patientDashboardTabSetting = tabSettings;
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        } else {
            // console.log("dropped Event", `> dropped '${event.item.data}' into '${event.container.id}'`);
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }
    waitingRoomDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            // console.log("dropped Event", `> dropped '${event.item.data}' into '${event.container.id}'`);
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            let memberShip = this.sessionService.currentUser.attributes.membership;

            this.teleRxservice.updateRooms(this.tabOrderSettings, this.waitingRooms).subscribe(
                (data) => {
                    this.membership = data[0].type.membership;
                    let updateRoomEvent = new CustomEvent("UPDATE_ROOM", {});
                    document.dispatchEvent(updateRoomEvent);
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        } else {
            // console.log("dropped Event", `> dropped '${event.item.data}' into '${event.container.id}'`);
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    public getWaitingRooms() {
        let careteamId = "";
        if (!this.sessionService.isAdmin()) {
            careteamId = this.sessionService.activeCareTeam.careteamid;
        }
        return this.waitingRoomService.getWaitingRooms(careteamId).subscribe(
            (data) => {
                this.waitingRooms = data[0].attributes.list.filter((x) => x.status === "active");
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public getSpecialties() {
        return this.specialtyService.getAllSpecialties().subscribe(
            (data) => {
                this.specialties = data;
                // filter and sort

                this.specialties = this.specialties
                    .map((e) => {
                        e.display = e.classification;
                        if (e.specialization) {
                            e.display += " - " + e.specialization;
                        }

                        return e;
                    })
                    .sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0));

                return this.specialtyService.getSpecialties().subscribe(
                    (data) => {
                        this.selectSpecialties = data.map((e) => {
                            e.display = e.classification;
                            if (e.specialization) {
                                e.display += " - " + e.specialization;
                            }

                            return e;
                        });
                    },
                    (err) => {
                        //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public getBadWords() {
        this.showLoader = true;
        this.error = "";
        return this.messagingService.getBadWords().subscribe(
            (data) => {
                this.showLoader = false;
                if (data[0].attributes.list.length == 0) {
                    this.error = "No data found";
                }

                //this.sortData(defaultSort);
                this.tableService.DATA = data[0].attributes.list;
                this.chatFilters = data[0].attributes.list;
                this.sorting("badword", "asc");
            },
            (err) => {
                this.showLoader = false;
                this.error = "No data found";
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    clickfilter() {
        this.getBadWords();
    }

    onRefresh() {
        this.tableService.searchTerm = "";
        this.filterText = "";
        this.getBadWords();
    }
    onDelete(b) {
        return this.messagingService.deleteBadWords(b.badword).subscribe(
            (data) => {
                this.snackbar.show("Deleted successfully");
                this.onRefresh();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    onReset(type) {
        this.tableService.searchTerm = "";
        let resetType = "~~reset~~";
        let message = "List reset to default";
        let warningMessage = "Are you sure you want to reset to default?";
        if (type == "removeall") {
            resetType = "~~all~~";
            message = "List removed";
            warningMessage = "Are you sure you want to remove all the list?";
        }
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = warningMessage;
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.messagingService.deleteBadWords(resetType).subscribe(
                    (data) => {
                        this.snackbar.show(message);
                        this.onRefresh();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
        });
    }
    newWord() {
        const modalRef = this.modalService.open(BadWordAddModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.action = "add";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.onRefresh();
            this.modalResponse(receivedEntry);
        });
    }

    public addSpecialty() {
        const sp = this.specialties.find((el) => el.spid === this.settingForm.value.specialty);
        if (sp && !this.selectSpecialties.find((el) => el.spid === sp.spid)) {
            this.selectSpecialties.push(sp);
        }
    }

    public removeSpecialty(id) {
        this.selectSpecialties = this.selectSpecialties.filter((el) => el.spid !== id);
    }

    public newRoom() {
        const modalRef = this.modalService.open(WaitingRoomAddModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.tabOrderSettings = this.tabOrderSettings;
        modalRef.componentInstance.waitingRooms = this.waitingRooms;
        modalRef.componentInstance.action = "add";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }

    public editRoom(room) {
        const modalRef = this.modalService.open(WaitingRoomAddModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.tabOrderSettings = this.tabOrderSettings;
        modalRef.componentInstance.waitingRooms = this.waitingRooms;
        modalRef.componentInstance.room = room;
        modalRef.componentInstance.action = "update";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }
    public delete(room) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this room?";
        modalRef.componentInstance.OKButton = "Delete";
        let memberShip = this.sessionService.currentUser.attributes.membership;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.waitingRoomService.updateWaitingRooms(room, "inactive", room.roomname).subscribe(
                    (data) => {
                        this.waitingRooms.forEach((element) => {
                            if (element.wrid === room.wrid) {
                                element.status = "inactive";
                            }
                        });
                        this.teleRxservice.updateRooms(this.tabOrderSettings, this.waitingRooms).subscribe(
                            (data) => {
                                this.membership = data[0].type.membership;
                                this.snackbar.show("Room Deleted Successfully.", "success");
                                this.getWaitingRooms();
                                let updateRoomEvent = new CustomEvent("UPDATE_ROOM", {});
                                document.dispatchEvent(updateRoomEvent);
                            },
                            (err) => {
                                this.snackbar.show(err[0].detail, "danger");
                            }
                        );
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
        });
    }

    public copyOnboardURL() {
        navigator.clipboard.writeText(this.joinURL).then(
            () => {
                this.snackbar.show("URL copied to clipboard.", "success");
            },
            () => {
                this.snackbar.show("Unable to copy to clipboard", "danger");
            }
        );
    }
    public onEditImage() {
        this.isImageEdit = true;
    }

    public preventInput(event) {
        let value = this.settingForm.value.npi;
        if (value >= 9999999999) {
            event.preventDefault();
            this.settingForm.patchValue({ npi: value.toString().substring(0, 10) });
        }
    }
    onSort({ column, direction }: any) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction);
    }
    sorting(column, direction) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.page = 1;
        this.tableService.searchOn = "badword";
    }

    onWorkFlowChange(event: any) {
        this.waitingRoomWorkflowSettings.forEach(element => {
            this.membership.additional_settings.waitingRoomWorkflowSettings[element.value] = element.selected;
        });
        this.saveChanges(this.membership.additional_settings.waitingRoomWorkflowSettings);
    }


}
