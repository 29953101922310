<div *ngIf="configuration" class="container-fluid">
    <div class="pt-3 pb-3">
        <div class="row col-12 m-0 p-0">
            <h3 *ngIf="!autoReplyEnabled">Currently Auto Reply is disabled on the server configuration</h3>
            <div *ngIf="autoReplyEnabled" class="col-7 m-0 p-0">
                <span class="span_heading">DEFAULT AUTO REPLY</span>
                <label class="switch">
                    <input (click)="autoReplyStatus($event)" [checked]="configuration.enable"
                        [(ngModel)]="configuration.enable" type="checkbox">
                    <span class="slider round"></span>
                </label>
            </div>
            <div *ngIf="configuration.enable" class="col-5 m-0 p-0">
                <div class="float-right"><button (click)="addForAllTime()" class="float-right btn-primary btn mr-4"><i
                            class="fas fa-plus-circle"></i>&nbsp;Save Auto Reply
                        Settings</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="configuration.enable">
        <div class="row">
            <div class="col-3">
                <label>
                    Send auto-reply:
                </label>
            </div>
            <div *ngFor="let r of reply_type; let i = index" class="form-group col-3 form-check">
                <input (change)="autoReplyType($event,r.value)" [(ngModel)]="r.checked" class="form-check-input"
                    type="checkbox" value="r.value" />
                <label class="form-check-label">
                    {{r.name}}
                </label>
            </div>
        </div>
        <div *ngIf="_enableFor !== 'all-time' && _enableFor !== 'after-hours'" class="row" style="align-items: center;
        justify-content: center;">
            <div class="col-6 mt-2 mb-2">
                <label>Set times when a reply is automatically sent.</label>
            </div>
            <div class="col-6 mt-2 mb-2"><button [disabled]="_enableFor===''" (click)="addToSchedule()"
                    class="float-right btn-primary btn mr-4"><i class="fas fa-plus-circle"></i>&nbsp;Add Schedule
                </button>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-6 mt-2 mb-2">
            </div>
            <div *ngIf="_enableFor !=='' " class="col-6 mt-2 mb-2"><button (click)="addForAllTime()"
                    class="float-right btn-primary btn mr-4"><i class="fas fa-plus-circle"></i>&nbsp;Submit</button>
            </div>
        </div> -->
        <div *ngIf="_enableFor === 'all-time'" class="p-3">
            <div class="row">
                <span class="span_heading">RESPONSES</span>
            </div>
        </div>
        <div *ngIf="_enableFor !== 'all-time' && _enableFor !== 'after-hours'" class="row col-12">
            <div class="pl-3 pt-2">
                <div class="row">
                    <span class="span_heading">Schedule</span>
                </div>
            </div>

            <table class="table table-hover mt-3" style="text-align: left">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let s of configuration.schedules;let i = index">
                        <td class="input-td">
                            {{s.name}}
                        </td>
                        <td class="input-td"> {{s.startDate | date: "MM/dd/yyyy"}}</td>
                        <td class="input-td"> {{s.endDate | date: "MM/dd/yyyy"}}</td>
                        <td>
                            <i title="Edit" (click)="editSchedules(i)" class="fa fa-edit mr-3 cursor-pointer"></i>
                            <i title="Delete" (click)="deleteSchedules(i)"
                                class="fas fa-trash-alt mr-3 cursor-pointer"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="_enableFor == 'after-hours' || _enableFor == 'after-hours,schedules'" class="row col-12">
            <div class="pb-3 col-12 pl-0 ml-0  pt-2">
                <span class="span_heading">After Hour</span>
            </div>
            <div *ngFor="let d of days" class="flex-container pr-0 mr-0">
                <div style="width:200px;">{{d.name}}</div>
                <div class="form-check">
                    <input (click)="status(d.name,true)" [name]="d.name" [checked]="d.isOpen" class="form-check-input"
                        type="radio" />
                    <label class="form-check-label">
                        Open
                    </label>
                </div>
                <div class="form-check">
                    <input (click)="status(d.name,false)" [name]="d.name" [checked]="!d.isOpen" class="form-check-input"
                        type="radio" />
                    <label class="form-check-label">
                        Closed
                    </label>
                </div>

                <div>

                    <select [disabled]="!d.isOpen" [ngClass]="{error: d.isOpen? d.startTimeError:'' }"
                        [(ngModel)]="d.startTime.hour" class="form-control time">
                        <option disabled value="--">--</option>
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>
                <div>
                    <select [disabled]="!d.isOpen" [ngClass]="{ error: d.isOpen? d.startTimeError:'' }"
                        [(ngModel)]="d.startTime.minutes" class="form-control time">
                        <option disabled value="--">--</option>
                        <option value="0">00</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                    </select>
                </div>
                <div>
                    <select [disabled]="!d.isOpen" [ngClass]="{ error: d.isOpen? d.startTimeError:'' }"
                        [(ngModel)]="d.startTime.meridiem" class="form-control time">
                        <option value="--">--</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
                <div>to</div>
                <div>
                    <select [disabled]="!d.isOpen" [ngClass]="{ error: d.isOpen? d.startTimeError:'' }"
                        [(ngModel)]="d.endTime.hour" class="form-control time">
                        <option disabled value="--">--</option>
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                </div>
                <div>
                    <select [disabled]="!d.isOpen" [ngClass]="{ error: d.isOpen? d.startTimeError:'' }"
                        [(ngModel)]="d.endTime.minutes" class="form-control time">
                        <option disabled value="--">--</option>
                        <option value="0">00</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                    </select>
                </div>
                <div>
                    <select [disabled]="!d.isOpen" [ngClass]="{ error: d.isOpen? d.startTimeError:'' }"
                        [(ngModel)]="d.endTime.meridiem" class="form-control time">
                        <option disabled value="--">--</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>

            </div>
            <div class="row col-12 mt-2 pl-0 ml-0">
                <div class="col-12 pl-0 ml-0">
                    <label>After hours response</label>
                </div>
                <div class="col-12 pl-0 ml-0">
                    <textarea rows="4" [(ngModel)]="afterHourMessage" class="form-control"></textarea>
                </div>
            </div>
        </div>

        <div *ngIf="_enableFor === 'all-time'" class="row col-12">
            <label>Default Response</label>
            <textarea rows="4" [(ngModel)]="defaultMessage" class="form-control"></textarea>
        </div>
        <div class="row mt-4">
            <div class="col-6 mt-2 mb-2">
                <label>Responses to keywords:</label>
            </div>
            <div class="col-6 mt-2 mb-2"></div>
            <div class="col-12">
                <div style="padding-left:0px" class="filter-section">
                    <div class="filter-section-left">
                        <i (click)="reload()" style="cursor: pointer; font-size: 20px"
                            class="fa fa-redo-alt left-chevron"></i><input [(ngModel)]="tableService.searchTerm"
                            class="filter-input" name="searchTerm" placeholder="Search..." />
                    </div>
                    <div class="filter-section-right">
                        <button (click)="addKeywords()" class="float-right btn-primary btn mr-4"><i
                                class="fas fa-plus-circle"></i>&nbsp;Add
                            Keywords</button>
                    </div>

                </div>
                <table class="table table-striped datatable mt-3" style="text-align: left">
                    <thead>
                        <tr>
                            <th style="padding-left:0px" [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                *ngFor="let c of displayedColumns" sortable="{{ c.columnname }}"
                                (sort)="onSort($event)">{{ c.displayname }}</th>
                            <!-- <th scope="col">Keyword</th>
                            <th scope="col">Severity</th>
                            <th scope="col"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of resultList$ | async;let i = index">
                            <td class="input-td">
                                {{t.keyword}}
                            </td>
                            <td class="input-td">
                                {{t.severity}}
                            </td>
                            <td>
                                <i (click)="editKeywords(i,t.id)" title="Edit"
                                    class="fa fa-edit mr-3 cursor-pointer"></i>
                                <i (click)="deleteKeywords(i,t.id)" title="Delete"
                                    class="fas fa-trash-alt mr-3 cursor-pointer"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>