import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SpecialtyService } from "../../services/specialty.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "covered-services-modal",
    templateUrl: "./covered-services-modal.component.html",
    styleUrls: ["./covered-services-modal.component.scss"],
})
export class CoveredServicesModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private specialtyService: SpecialtyService, private snackbar: SnackbarService) {}

    @Input() CoveredServicesData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    services: any = [];
    ngOnInit() {
        this.services = this.CoveredServicesData.services;
        if (!this.services || this.services.length == 0) {
            this.getCoveredServices();
        }
    }
    public getCoveredServices() {
        return this.specialtyService.getServices().subscribe(
            async (data) => {
                this.services = data.attributes.list;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public onCancel() {
        this.activeModal.close();
    }
}
