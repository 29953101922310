import { Component, OnInit } from '@angular/core';
import { MSXReportService } from '../../../services/msx_report.service';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { SessionService } from "../../../services/session.service";;

@Component({
  selector: 'app-msx-reports',
  templateUrl: './msx-reports.component.html',
  styleUrls: ['./msx-reports.component.scss']
})
export class MsxReportsComponent implements OnInit {
  reportFor = [];
  GenerateCsvCurrentMonth: any;
  showLoader = false;
  reportName = "ReportCard";
  report = "";
  EntityList = [];
  GenerateMonthDropDown = [];
  GenerateQuarterDropDown = [];
  monthReport = "";
  quarterReport = "";
  reportValue = "";
  reportDate = "";
  selectedValue: any;
  downloadType = "pdf";
  reportDuration = "monthly"
  username = "";
  ROLE = "Groups";
  SELECTEDID = "";
  allowIncludeAllGroups = false;
  includeAllGroups = false;
  isProvider = false;
  IsDashboardViewer = false;

  constructor(private msxReportService: MSXReportService,
    private snackbar: SnackbarService, public sessionService: SessionService) { }

  ngOnInit(): void {
    this.loadData(this.reportName)
  }

  public loadData(reportName) {
    this.reportName = reportName;
    this.getEntityList(this.reportName);
  }

  public getReportList(reportName = "", type = "", id = "") {
    if (type == "Practices") {
      type = "Practice"
    }
    if (type == "Providers") {
      type = "Provider"
    }

    this.ROLE = type;
    this.SELECTEDID = id;
    this.msxReportService.getReportList(reportName, type, id).subscribe(
      (data) => {
        if (data[0].attributes.data.length > 0) {
          this.reportFor = data[0].attributes.data;
          this.report = this.reportFor[0].reportId;
          this.reportValue = this.reportFor[0].value;
          this.reportDate = this.reportFor[0].reportDate;
        }
        else {
          this.reportFor = [];
          this.report = "";
          this.reportValue = "";
          this.reportDate = "";
        }

      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  public getEntityList(reportName = "") {
    let providerId = "";
    if (this.sessionService.currentUser.attributes.msxInfo.roles[0].rolename.includes("Provider")) {
      providerId = this.sessionService.currentUser.attributes.msxInfo.providerId;
    }
    this.msxReportService.getReportEntityList(providerId).subscribe(
      (data) => {
        this.isProvider = data[0].attributes.data.IsProvider;
        this.IsDashboardViewer = data[0].attributes.data.IsDashboardViewer;
        this.allowIncludeAllGroups = data[0].attributes.data.AllowIncludeAllGroups;
        this.EntityList = data[0].attributes.data.EntityList;
        this.GenerateMonthDropDown = data[0].attributes.data.GenerateMonthDropDown;
        this.GenerateQuarterDropDown = data[0].attributes.data.GenerateQuarterDropDown;
        this.quarterReport = this.GenerateQuarterDropDown.length ? this.GenerateQuarterDropDown[0].Value : "";
        this.monthReport = this.GenerateMonthDropDown.length ? this.GenerateMonthDropDown[0].Value : "";

        this.GenerateCsvCurrentMonth = data[0].attributes.data.GenerateCsvCurrentMonth;
        if (this.reportName == "Ranking" || this.reportName == "Outliers" || this.reportName == "Undeliverables")
          this.EntityList.forEach(e => {
            if (e.Type == "Provider" || e.Type == "Providers") {
              e.disabled = true
            }
          });
        this.selectedValue = this.EntityList[0].Value;
        this.getReportList(reportName, this.EntityList[0].Type, this.selectedValue.toUpperCase());

      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  downloadReport() {
    this.showLoader = true;
    this.msxReportService.downloadReport(this.report.toLocaleLowerCase()).subscribe(
      (data) => {
        this.showLoader = false;
        data = JSON.parse(data)
        data = data.data[0].attributes;
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + data.base64;
        downloadLink.download = data.fileName;
        downloadLink.click();
      },
      (err) => {
        this.showLoader = false;
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  generateReport(type = "pdf") {

    let data: any;
    let entitySelected = ""
    if (this.reportName == "Comments") {
      data = {
        role: this.ROLE,
        ViewMethod: "csv",
        UserID: this.sessionService.currentUser?.attributes.msxInfo.userId.toLocaleLowerCase(),
        UserName: this.sessionService.currentUser?.attributes.email,
        GroupID: (localStorage.getItem("selectedGroup") || "").toLocaleLowerCase(),
        ReportID: 1,
        RangeID: 1,
        Date: this.report,
        IncludeAllGroups: this.includeAllGroups,
        PracticeID: this.ROLE == "Practice" ? this.SELECTEDID : "",
        ProviderID: this.ROLE == "Provider" ? this.SELECTEDID : ""
      }
    }
    if (this.reportName == "Ranking") {

      data = {
        role: this.ROLE,
        ViewMethod: "email",
        UserID: this.sessionService.currentUser?.attributes.msxInfo.userId.toLocaleLowerCase(),
        UserName: this.sessionService.currentUser?.attributes.email,
        GroupID: (localStorage.getItem("selectedGroup") || "").toLocaleLowerCase(),
        ReportID: 3,
        RangeID: 1,
        Year: this.quarterReport.split(" ")[1],
        Quarter: this.quarterReport.split(" ")[0].replace("Q", ""),
        PracticeID: this.ROLE == "Practice" ? this.SELECTEDID : "",
        ProviderID: this.ROLE == "Provider" ? this.SELECTEDID : ""

      }
    }
    if (this.reportName == "ReportCard" && this.reportDuration == "monthly") {
      data = {
        role: this.ROLE,
        monthyear: this.monthReport,
        ViewMethod: "email",
        UserID: this.sessionService.currentUser?.attributes.msxInfo.userId.toLocaleLowerCase(),
        UserName: this.sessionService.currentUser?.attributes.email,
        GroupID: (localStorage.getItem("selectedGroup") || "").toLocaleLowerCase(),
        ReportID: 2,
        RangeID: 1,
        PracticeID: this.ROLE == "Practice" ? this.SELECTEDID : "",
        ProviderID: this.ROLE == "Provider" ? this.SELECTEDID : ""
      }
    }
    if (this.reportName == "ReportCard" && this.reportDuration == "quarterly") {

      data = {
        role: this.ROLE,
        monthyear: "",
        ViewMethod: "email",
        UserID: this.sessionService.currentUser?.attributes.msxInfo.userId.toLocaleLowerCase(),
        UserName: this.sessionService.currentUser?.attributes.email,
        GroupID: (localStorage.getItem("selectedGroup") || "").toLocaleLowerCase(),
        ReportID: 2,
        RangeID: 3,
        Year: this.quarterReport.split(" ")[1],
        Quarter: this.quarterReport.split(" ")[0].replace("Q", ""),
        PracticeID: this.ROLE == "Practice" ? this.SELECTEDID : "",
        ProviderID: this.ROLE == "Provider" ? this.SELECTEDID : ""

      }
    }

    if (this.reportName == "Undeliverables" && type == "pdf") {
      data = {
        role: this.ROLE,
        Monthyear: this.monthReport,
        ViewMethod: "pdf",
        UserID: this.sessionService.currentUser?.attributes.msxInfo.userId.toLocaleLowerCase(),
        UserName: this.sessionService.currentUser?.attributes.email,
        GroupID: (localStorage.getItem("selectedGroup") || "").toLocaleLowerCase(),
        ReportID: 5,
        RangeID: 1,
        PracticeID: this.ROLE == "Practice" ? this.SELECTEDID : "",
        ProviderID: this.ROLE == "Provider" ? this.SELECTEDID : ""
      }
    }

    if (this.reportName == "Undeliverables" && type == "csv") {
      data = {
        role: this.ROLE,
        Monthyear: this.monthReport,
        ViewMethod: "csv",
        UserID: this.sessionService.currentUser?.attributes.msxInfo.userId.toLocaleLowerCase(),
        UserName: this.sessionService.currentUser?.attributes.email,
        GroupID: (localStorage.getItem("selectedGroup") || "").toLocaleLowerCase(),
        ReportID: 5,
        RangeID: 1,
        Date: this.reportDate,
        PracticeID: this.ROLE == "Practice" ? this.SELECTEDID : "",
        ProviderID: this.ROLE == "Provider" ? this.SELECTEDID : ""
      }
    }



    this.showLoader = true;
    this.msxReportService.generateReport(data).subscribe(
      (data) => {
        this.showLoader = false;
        data = JSON.parse(data)

        if (this.reportName == "Ranking") {
          alert("Email has been sent.")
          return;
        }
        else {
          if (data.errors === undefined) {
            data = data.data[0].attributes;
            const downloadLink = document.createElement("a");
            downloadLink.href = "data:application/pdf;base64," + data.base64;
            downloadLink.download = data.fileName;
            downloadLink.click();
          }
          else {
            this.snackbar.show("No Data for this period.", "danger");
          }
        }

      },
      (err) => {
        this.showLoader = false;
        if (err !== undefined) {
          this.snackbar.show("No Data for this period.", "danger");
        }
        else {
          this.snackbar.show("No Data for this period.", "danger");

        }
      }
    );
  }
  selectedEntity(e) {
    this.getReportList(this.reportName, e.Type, e.Value.toUpperCase())
  }

  reportType(r_type) {
    if (r_type == "csv") {
      this.report = this.GenerateCsvCurrentMonth.Value;
    }
    else {
      this.report = this.reportFor[0].reportId;
    }
  }

}