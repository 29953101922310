import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
@Component({
    selector: "billing-metric-modal",
    templateUrl: "./billing-metric-modal.component.html",
    styleUrls: ["./billing-metric-modal.component.scss"],
})
export class BillingMetricModalComponent implements OnInit {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    constructor(private activeModal: NgbActiveModal, private msxReportService: MSXReportService, private snackbar: SnackbarService) { }
    metrics: any;
    @Input() selected = "averageClaimAge";
    showLoader = true;
    metricname = ""
    metric: any;

    ngOnInit() {
        this.msxReportService.getBillinMetricsList().subscribe(
            (data) => {
                this.metrics = data[0].attributes.metrics;
                this.metrics.forEach(element => {
                    if (element.label === "Average Bill Amount") {
                        element.label = "Average Bill Amount (dollars)"
                    }
                    if (element.label === "Total Bill Amount") {
                        element.label = "Total Bill Amount (dollars)"
                    }
                });
                this.showLoader = false;
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }
    public selectedMetric(m) {
        this.selected = m.value;
        this.metricname = m.label;
    }
    public onSubmit() {
        if (this.metricname == "") {
            this.metrics.forEach(element => {
                if (element.value == this.selected) {
                    this.metricname = element.label;
                }
            });
        }
        this.showLoader = true;
        setTimeout(() => {
            this.Response.emit({ proceed: this.selected, name: this.metricname });
            this.activeModal.close();
        }, 500);

    }

    public onCancel() {
        this.activeModal.close();
    }


}
