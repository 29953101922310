import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'lodash';
@Component({
    selector: "filters-modal-v2",
    templateUrl: "./filters-modal-v2.component.html",
    styleUrls: ["./filters-modal-v2.component.scss"],
})
export class FiltersModalV2Component implements OnInit {
    constructor(private activeModal: NgbActiveModal, private msxReportService: MSXReportService, private snackbar: SnackbarService) { }
    @Input() SelectedFactors: any
    @Input() ChartData: any
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    @Input() selectedPractice = "";
    @Input() parent = "";
    @Input() graphType = "";
    @Input() benchmarkData: any;
    @Input() practiceData = [];
    @Input() benchMarkCount = 0;
    selectedPracticeName = "";
    dropdownSettings: any = {};
    practicedropdownSettings: any = {};
    _benchMarkValue = 0;
    practices = [];
    allPractices = [];
    providers = [];
    roles = [];
    locations = [];

    selected_practice = [];
    selected_provider = [];
    selected_location = [];
    selected_role = [];
    options = [
        {
            name: "Location",
            value: "location"
        },
        {
            name: "Practice",
            value: "practice"
        },
        {
            name: "Provider",
            value: "provider"
        },
        {
            name: "Role",
            value: "role"
        }];
    selectedOption = "";
    selectedBanchMarkOption = "";
    banchMarkoptions = [
        {
            id: "National Average",
            value: 'nationalbenchmar'
        },
        {
            id: "Practice Average",
            value: 'practicebenchmar'
        },
        {
            id: "Role Average",
            value: 'rolebenchmar'
        },
        {
            id: "Location Average",
            value: 'locationbenchmar'
        },
        {
            id: "Last Month Average",
            value: 'lastmonthavg'
        },
        {
            id: "Last Year Average",
            value: 'lastyearavg'
        },
        {
            id: "State Average",
            value: 'stateavg'
        }
    ]
    selectedBanchMarkValue = "";
    ngOnInit() {
        if (this.selectedBanchMarkOption == "") {
            this.selectedBanchMarkOption = "nationalbenchmar"
        }
        if (this.SelectedFactors !== undefined && this.SelectedFactors !== null) {
            this.selected_practice = this.SelectedFactors.selected_practice;
            this.selected_provider = this.SelectedFactors.selected_provider;
            this.selected_role = this.SelectedFactors.selected_role;
            this.selected_location = this.SelectedFactors.selected_location;
            this.selectedBanchMarkOption = this.SelectedFactors.selectedBanchMarkOption;
            this._benchMarkValue = this.SelectedFactors.benchMarkValue;
            this.selectedBanchMarkValue = this.SelectedFactors.selectedBanchMarkValue;
        }
        this.intSettings();
        setTimeout(() => {
            this.GetEntity(this.selectedPractice);
        }, 1000);
        if (this.parent === "survey") {
            this.ChartData = this.ChartData.map((x) => {
                x.practiceid = x.p;
                return x;
            });
        }
        else {
            this.ChartData = this.ChartData.map((x) => {
                x.p = x.practiceid;
                x.f = x.factorid;
                x.d = x.bucketdate;
                x.b = x.bucketname;
                return x;
            });
        }

    }
    public onSubmit() {
        this._benchMarkValue = 0.0;

        if (this.parent !== "survey") {
            this.selected_practice.forEach(element => {
                element['bucketname'] = element.b
                element['factorid'] = '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8'
            });
            this.selected_provider.forEach(element => {
                element['bucketname'] = element.b
                element['factorid'] = '740ED927-15C0-4BA9-B971-CD873FB0AE73'
            });
            this.selected_role.forEach(element => {
                element['bucketname'] = element.b
                element['factorid'] = '0E5E868A-D26F-4593-A385-A8F935972E10'
            });
            this.selected_location.forEach(element => {
                element['bucketname'] = element.b
                element['factorid'] = '3E2D7B7A-2FC9-4B3F-A112-902C3FB0925C'
            });
        }

        if (this.selectedBanchMarkOption === "nationalbenchmar") {
            this.selectedBanchMarkValue = 'National Average';
            let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
                b: 'National Average',
            }), 'b'), ['b'])
            if (banchMark.length != 0) {
                this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            }

        }

        if (this.selectedBanchMarkOption === "lastmonthavg") {
            this.selectedBanchMarkValue = 'Last Month Average';
            let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
                b: 'Last Month Average',
            }), 'b'), ['b'])
            if (banchMark.length != 0) {
                this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            }

        }

        if (this.selectedBanchMarkOption === "lastyearavg") {
            this.selectedBanchMarkValue = 'Last Year Average';
            let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
                b: 'Last Year Average',
            }), 'b'), ['b'])
            if (banchMark.length != 0) {
                this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            }

        }
        if (this.selectedBanchMarkOption === "practicebenchmar") {
            //  this.selectedBanchMarkValue = 'Group Average';
            let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
                b: this.selectedBanchMarkValue,
            }), 'b'), ['b'])
            if (banchMark.length != 0) {
                this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            }

        }

        if (this.selectedBanchMarkOption === "rolebenchmar") {
            //  this.selectedBanchMarkValue = 'Group Average';
            let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
                b: this.selectedBanchMarkValue,
            }), 'b'), ['b'])
            if (banchMark.length != 0) {
                this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            }

        }
        if (this.selectedBanchMarkOption === "stateavg") {
            //  this.selectedBanchMarkValue = 'Group Average';
            let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
                b: this.selectedBanchMarkValue,
            }), 'b'), ['b'])
            if (banchMark.length != 0) {
                this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            }

        }




        let parameters = {
            practiceId: this.selectedPractice,
            selected_practice: this.selected_practice,
            selected_provider: this.selected_provider,
            selected_role: this.selected_role,
            selected_location: this.selected_location,
            benchMarkValue: this._benchMarkValue,
            selectedBanchMarkOption: this.selectedBanchMarkOption,
            selectedBanchMarkValue: this.selectedBanchMarkValue,
            benchMarkCount: this.benchMarkCount
        }
        this.Response.emit({
            parameters: parameters
        });

        this.activeModal.close();
    }

    public onCancel() {
        this.activeModal.close();
    }

    public intSettings() {
        this.practicedropdownSettings = {
            singleSelection: false,
            idField: 'practiceid',
            textField: 'b',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true
        };
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'b',
            textField: 'b',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false,
            clearSearchFilter: true
        };

    }


    selectedOptions(type = "") {
        this.selectedOption = type;
    }
    onItemSelect(item: any, type) {

    }
    onItemDeSelect(item: any) {

    }

    public onPracticeChange(event) {
        this.resetFilters();
        this.selectedPracticeName = event.target['options']
        [event.target['options'].selectedIndex].text;
        if (this.selectedPractice !== "-1") {
            this.GetEntity(this.selectedPractice);
        }
    }
    public GetEntity(practiceId = "") {
        this.allPractices = _.sortBy(_.uniqBy(_.filter(this.ChartData, {
            f
                : '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8',
        }), 'b'), ['b'])


        this.allPractices = _.filter(this.allPractices, (p) => {
            return p.practiceid !== "00000000-0000-0000-0000-000000000000";
        });

        if (this.allPractices.length === 0 && this.practiceData.length > 0) {
            this.practiceData = this.practiceData.map((x) => {
                x.p = x.practiceid;
                x.b = x.name;
                return x;
            });

            this.allPractices = this.practiceData;
        }
        // if (this.allPractices.length > 0) {
        //     localStorage.setItem("allPractices", JSON.stringify(this.allPractices));
        // }
        // if (this.allPractices.length === 0) {
        //     let allPractices = JSON.parse(localStorage.getItem('allPractices'))
        //     if (allPractices != undefined) {
        //         this.allPractices = allPractices;
        //     }
        // }


        let data = this.ChartData;
        if (practiceId !== "") {
            data = _.filter(data, {
                practiceid
                    : practiceId,
            })
        }

        this.practices = _.sortBy(_.uniqBy(_.filter(data, {
            f
                : '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8',
        }), 'b'), ['b'])





        this.practices = _.filter(this.practices, (name) => {
            return name.b !== "All Practices";
        });

        this.providers = _.sortBy(_.uniqBy(_.filter(data, {
            f
                : '740ED927-15C0-4BA9-B971-CD873FB0AE73'
        }), 'b'), ['b'])
        this.providers = _.orderBy(this.providers, [user => user.b == null ? "" : user.b.toLowerCase()], ['asc']);

        this.providers = _.filter(this.providers, (name) => {
            return name.b !== "Group Average";
        });



        this.roles = _.sortBy(_.uniqBy(_.filter(data, {
            f
                : '0E5E868A-D26F-4593-A385-A8F935972E10'
        }), 'b'), ['b'])
        this.locations = _.sortBy(_.uniqBy(_.filter(data, {
            f
                : '3E2D7B7A-2FC9-4B3F-A112-902C3FB0925C'
        }), 'b'), ['b'])
    }

    public resetFilters() {
        this.selected_practice = [];
        this.selected_provider = [];
        this.selected_role = [];
        this.selected_location = [];
    }

    public benchMarkValue(b) {
        this.selectedBanchMarkValue = b.target.value;
        let banchMark = _.sortBy(_.uniqBy(_.filter(this.benchmarkData, {
            b: b.target.value,
        }), 'b'), ['b'])
        if (banchMark.length != 0) {
            this._benchMarkValue = _.round((banchMark[0].m / banchMark[0].n), 2);
            this.benchMarkCount = banchMark[0].n;
        }
    }
    public changeBenchMark() {
        this.selectedBanchMarkValue = "";
    }

}

