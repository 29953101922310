import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { HttpClient } from "@angular/common/http";
import { SessionService } from "./session.service";

@Injectable()
export class SpecialtyService {
    constructor(private avcHttp: AvcHttp, private helper: Helper, private http: HttpClient, private sessionService: SessionService) {}

    public getAllSpecialties(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/specialties";
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.list);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getSpecialties(membershipid = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/specialties/telerx";
            if (membershipid !== "") {
                url = environment.uri + "/specialties/telerx?membershipid=" + membershipid;
            }
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.list);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public postSpecialties(spids, membershipid = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/specialties/telerx";
            let _id = this.sessionService.getCurrentUserMembershipId();
            if (membershipid !== "") {
                _id = membershipid;
            }

            this.avcHttp.sendRequest("post", url, { specialties: spids, membershipid: _id }).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getTeleRxBySpeciality(spid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/specialties/telerx/" + spid;
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.list);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getProviderBySpeciality(spid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/specialties/telerx/" + spid;
            let queryString = "filter=provider";
            this.avcHttp.sendRequest("get", url + "?" + queryString, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.list);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getRecentReferral(spid, patientId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/specialties/telerx/" + spid + "/recent?userid=" + patientId;
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.list);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getProvidersFromTelerx(telerxid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/telerx/userlist/" + telerxid + "?type=provider&trxustatus=enabled&membershiptype=all&orderby=fullname&order=ASC";
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.list);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public checkEligibility(data): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/eligibility/v1", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getServiceTypeCodes(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/eligibility/servicetype/v1").subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public getServices(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/eligibility/servicetype/v1").subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
    public getPayers(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/eligibility/payers/v1").subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
}
