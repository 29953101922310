<div class="modal-body">
    <div class="category-header">
        <label>Health Insurance Information</label>
    </div>
    <div class="main-row">
        <div class="form-group">
            <label>Covered Services</label>
            <div class="covered-service-section" *ngIf="services && services.length > 0">
                <div class="service-item" *ngFor="let service of services; let i = index">
                    <label>{{ service.name }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="main-row service-footer-row">
        <button type="button" class="btn btn-primary btn-services-back" (click)="onCancel()">Back</button>
    </div>
</div>
