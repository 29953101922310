import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MediaService } from '../../../../services/media.service';
import { SnackbarService } from '../../../../shared/snackbar/snackbar.service';
import { MSXReportService } from '../../../../services/msx_report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../../../../app/modals/warning-modal/warning-modal.component';

declare var FB: any;
@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss']
})
export class EditLinkComponent implements OnInit {
  message: any;
  authResponse = {
    userID: "",
    accessToken: ""
  }

  userDetails: any;
  longLivedToken: any;
  userPages: any;
  reviews: any;
  selectedPage: any;
  mediaForm: UntypedFormGroup;
  submitted = false;
  locations = [];
  practices = [];
  providers = [];
  page_id = "";
  facebookPageSettings: any = {};
  selectedOption = "";
  selectedFBPages = [];
  locationid = "";
  practiceid = "";
  socialLinks = [];
  selectedProviders = [];
  constructor(private http: HttpClient, private fb: UntypedFormBuilder,
    private media: MediaService, private snackbar: SnackbarService,
    private msxReportService: MSXReportService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.locationid = this.route.snapshot.queryParamMap.get("locationid") || "";
    this.practiceid = this.route.snapshot.queryParamMap.get("practiceid") || "";
    this.GetProviders();

    this.facebookPageSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false,
      clearSearchFilter: true
    };

    this.GetEntity();
    this.mediaForm = this.fb.group({
      practice: ["", Validators.required],
      location: ["", Validators.required],
      page_id: ["", Validators.required],
      provider: [""],
      userid: [""],
      facebook: [false]
    });

  }

  get form() {
    return this.mediaForm.controls;
  }

  public GetEntity() {
    let selectedGroup = localStorage.getItem("selectedGroup") || ""
    this.msxReportService.GetEntity(selectedGroup, "").subscribe(
      (data) => {
        this.locations = data[0].attributes.data.graphLocations;
        this.practices = data[0].attributes.data.practices;
        this.providers = data[0].attributes.data.providers;

        if (this.locationid !== "" && this.practiceid !== "") {
          this.mediaForm.patchValue({
            practice: this.practices.find((element) => element.practiceid == this.practiceid),
            location: this.locations.find((element) => element.locationid == this.locationid),
          });

        }
        // if (this.smid !== "") {
        //   this.getLink(this.smid)
        // }

      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }


  getPages(userid = "") {
    this.http
      .get<any>('https://graph.facebook.com/v14.0/' + userid + '/accounts?access_token=' + this.longLivedToken, {
      })
      .subscribe((pageData: any) => {
        this.userPages = pageData.data;
      });

    setTimeout(() => {
      var element = document.querySelector(".multiselect-dropdown");
      element.scrollIntoView();
    }, 1000);


  }

  onSubmit() {
    this.submitted = true;
    if (this.mediaForm.valid) {
      let pages = [];
      this.userPages.forEach(element => {
        this.selectedFBPages.forEach(page => {
          if (element.id === page.id) {
            pages.push(element);
          }
        });
      });
      this.selectedFBPages = pages;
      this.media.save(this.authResponse, this.longLivedToken, this.mediaForm.value, this.selectedFBPages).subscribe(
        (data) => {
          this.snackbar.show(data[0].attributes.data, "success");
        },
        (error) => {
          this.snackbar.show(error[0].detail, "danger");
        }
      );

    } else {

    }
  }

  onItemSelect(item: any) {
    //this.selectedFBPages.push(item)
  }

  onItemDeSelect(item: any) {
    this.selectedFBPages.forEach((element, index) => {
      // if (element.name === item.name) {
      //   this.selectedFBPages.splice(index, 1)
      // }
    });
  }

  public edit(provider = "", pages) {
    this.authResponse.userID = pages.userid;
    this.authResponse.accessToken = pages.access_token
    if (provider != "") {
      this.mediaForm.patchValue({
        provider: provider,
      });
    }

    this.selectedFBPages = pages.pages.map(item => {
      return {
        id: item.page_id,
        name: item.page_name
      };
    });


    this.longLivedToken = pages.long_lived_access_token;
    this.getPages(pages.userid);
  }

  public onDelete(provider, pages) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.Header = false;
    modalRef.componentInstance.OKButton = "Delete";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.media.delete(provider.smgroupid).subscribe(
          (data) => {
            this.snackbar.show("Deleted successfully.", "success");
            this.GetProviders();
          },
          (err) => {
            this.snackbar.show("error occurred please try again later.", "danger");
          }
        );
      }
    });
  }

  public GetProviders() {
    this.media.GetProviders(this.locationid, this.practiceid).subscribe(
      (data) => {
        this.selectedProviders = data[0].attributes.data.list;
        this.selectedProviders.sort((a, b) => (a.provider_name.toLocaleLowerCase() > b.provider_name.toLocaleLowerCase() ? 1 : -1));


      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }
  public addNew() {
    this.router.navigate(["/media/add-link/"]);
  }

  public getName(str) {
    if (str != undefined && str !== "")
      return str.split("=")[0];
  }
  public onCancel() {
    this.router.navigate(["/media/activity/"]);
  }
}
