import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CampaignService } from "../../../../../services/campaign.service";
import { SnackbarService } from "../../../../../shared/snackbar/snackbar.service";
import { SessionService } from "../../../../../services/session.service";
import { forEach } from "underscore";

@Component({
    selector: "template-modal",
    templateUrl: "./template-modal.component.html",
    styleUrls: ["./template-modal.component.scss"],
})
export class TemplateModalComponent implements OnInit {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    constructor(
        private activeModal: NgbActiveModal,
        private router: Router,
        private templateService: CampaignService,
        private snackbar: SnackbarService,
        private sessionService: SessionService
    ) { }
    isTemplate = true;
    templates = [];
    monthly = [];
    specialty = [];
    selectedTemplate: any;
    showLoader = true;
    tabName = "monthly";
    loading = true;
    ngOnInit() {
        this.getTemplateList();
    }
    public onTemplateSubmit() {
        if (this.selectedTemplate !== undefined) {
            this.isTemplate = false;
        }
        else {
            this.snackbar.show("Please select a valid template.", "danger");

        }
    }

    public onImageSubmit() {
        if (this.selectedTemplate.featured_image !== undefined) {
            this.activeModal.close();
            this.sessionService.campaignTemplate = this.selectedTemplate;
            this.router.navigate(["campaign"]);
        }
        else {
            this.snackbar.show("Please select a valid image.", "danger");
        }
    }

    public onTemplateCancel() {
        this.activeModal.close();
    }
    public onCancel() {
        this.activeModal.close();
    }

    private getTemplateList() {
        this.templateService.getTemplate().subscribe(
            (data) => {
                const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
                let _templates = []
                months.forEach((month, index) => {
                    _templates.push(
                        {
                            month: month.toUpperCase(),
                            template: []
                        }
                    );
                    data[0].attributes.monthly.forEach(element => {
                        if (month.toLocaleLowerCase() == element.template_type.toLocaleLowerCase()) {
                            _templates[index].template.push(element)
                        }
                    });

                });
                this.monthly = _templates.filter(function (obj) {
                    return obj.template.length > 0;
                });
                this.templates = data[0].attributes;
                this.specialty = data[0].attributes.specialty;
                this.showLoader = false;
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }

    public selectedOption(template) {
        this.selectedTemplate = template;
    }

    public selectedImage(img) {
        this.selectedTemplate.featured_image = img;
        // this.selectedTemplate.featured_images = img;
    }

    selectedTab(tabName = "") {
        this.tabName = tabName;
    }
    onLoad() {
        this.loading = false;
    }

}
