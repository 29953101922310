import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "./session.service";

@Injectable()
export class OnboardingService {
    private handler = `/onboarding/emails/list`;
    private url = `${environment.uri}${this.handler}`;
    selectedGroup = "";
    constructor(private avcHttp: AvcHttp, private helper: Helper, private http: HttpClient, private sessionService: SessionService) {
        this.selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (this.selectedGroup !== "") {
            this.selectedGroup = this.selectedGroup.toLowerCase();
        }
    }

    public getOnboardingList(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteUser(users: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                invitecodes: users,
            });
            this.avcHttp.sendRequest("post", environment.uri + "/onboarding/delete", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public sendInvitations(users: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                emails: users,
            });
            this.avcHttp.sendRequest("post", environment.uri + "/onboarding/sendinvite", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public createUserOnboarding(formData: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                email: formData.email,
                firstname: formData.firstname,
                lastname: formData.lastname,
                gender: formData.gender,
                dob: formData.dob,
                postalcode: formData.postalcode,
                membershipid: formData.membershipid,
                // Set careteamid as undefined so it can be seralized out of the data if not set
                careteamid: formData.careteamid || undefined,
            });

            this.avcHttp.sendRequest("post", environment.uri + "/onboarding", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err.message);
                    observer.complete();
                }
            );
        });
    }

    public sendOnboardingEmail(email: string): Observable<any> {
        let data = JSON.stringify({
            emails: [email],
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + `/onboarding/sendinvite`, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err.message);
                    observer.complete();
                }
            );
        });
    }
    public sendOnboardingToAllSelected(emails: any): Observable<any> {
        let data = JSON.stringify({
            emails: emails,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + `/onboarding/sendinvite`, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err.message);
                    observer.complete();
                }
            );
        });
    }
    public getEmailIndex(email, list) {
        for (var i = 0; i < list.length; i++) {
            if (list[i] === email) {
                return i;
            }
        }
        return -1;
    }

    public onboarding(email): Observable<any> {
        let membershipid = this.sessionService.getCurrentUserMembershipId();
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", environment.uri + "/onboarding/recover/" + email + "?membershipid=" + this.selectedGroup).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err.message);
                    observer.complete();
                }
            );
        });
    }
    public importCsv(fileToUpload: File) {
        return new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            formData.append("file", fileToUpload, fileToUpload.name);
            let headers = new HttpHeaders({
                Authorization: "Bearer " + this.sessionService.currentUser?.attributes.token,
            });
            let options = {
                headers: headers,
            };
            return this.http.post(environment.uri + "/onboarding/import/csv", formData, options).subscribe(
                (body) => {
                    if (body["data"] !== undefined && body["data"][0].attributes !== undefined) {
                        resolve(body["data"][0].attributes);
                    } else {
                        reject("errors");
                    }
                },
                (err) => {
                    reject(err.error.errors);
                }
            );
        });
    }
    public mergeTempOnboarding() {
        return new Promise((resolve, reject) => {
            // .sendRequest("get", environment.uri + "/onboarding/import/" + this.sessionService.getCurrentUserMembershipId() + "?careteamid=" + this.sessionService.activeCareTeam.careteamid)
            // remove careteamid parameter because we're making this admin only.
            this.avcHttp.sendRequest("get", environment.uri + "/onboarding/import/" + this.sessionService.getCurrentUserMembershipId()).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        resolve(body.data);
                    } else {
                        reject(body.errors[0].detail);
                    }
                },
                (err) => {
                    reject(err.message);
                }
            );
        });
    }
}
