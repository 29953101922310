<div style="overflow-x: inherit;" class="modal-body pb-0">
    <h5 class="modal-title">Compare</h5>

    <div style="overflow-x: inherit;" class="modal-body pb-0">
        <div *ngIf="parent=='survey'" class="row mb-3 px-4">
            <div *ngFor="let benchmark of banchMarkoptions" class="col-6">
                <div class="form-check form-check-inline">
                    <input id={{benchmark.id}} (click)="changeBenchMark()" [value]="benchmark.value"
                        [(ngModel)]="selectedBanchMarkOption" class="form-check-input" name="benchmark" type="radio">
                    <label for={{benchmark.id}} class="form-check-label">{{benchmark.id}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="selectedBanchMarkOption=='practicebenchmar'" class="input-group mb-3 col">
                <select (change)="benchMarkValue($event)" [(ngModel)]="selectedBanchMarkValue" class="form-control">
                    <option value="">Please select</option>
                    <option *ngFor="let b of benchmarkData | banchMarkfilter:graphType" [value]="b.b">{{ b.b
                        }}
                    </option>
                </select>
            </div>
            <div *ngIf="selectedBanchMarkOption=='locationbenchmar'" class="input-group mb-3 col">
                <select (change)="benchMarkValue($event)" [(ngModel)]="selectedBanchMarkValue" class="form-control">
                    <option value="">Please select</option>
                    <option *ngFor="let b of benchmarkData | banchMarkfilter:'location'" [value]="b.b">{{ b.b }}
                    </option>
                </select>
            </div>

            <div *ngIf="selectedBanchMarkOption=='rolebenchmar'" class="input-group mb-3 col">
                <select (change)="benchMarkValue($event)" [(ngModel)]="selectedBanchMarkValue" class="form-control">
                    <option value="">Please select</option>
                    <option *ngFor="let b of benchmarkData | banchMarkfilter:'role'" [value]="b.b">{{ b.b }}
                    </option>
                </select>
            </div>

            <div *ngIf="selectedBanchMarkOption=='stateavg'" class="input-group mb-3 col">
                <select (change)="benchMarkValue($event)" [(ngModel)]="selectedBanchMarkValue" class="form-control">
                    <option value="">Please select</option>
                    <option *ngFor="let b of benchmarkData | banchMarkfilter:'state'" [value]="b.b">{{ b.b }}
                    </option>
                </select>
            </div>

        </div>
        <hr *ngIf="parent=='survey'">
        <div class="row">
            <div class="input-group mb-3 col">
                <select class="form-control" [(ngModel)]="selectedPractice" (change)="onPracticeChange($event)">
                    <option value="">ALL Practices</option>
                    <option *ngFor="let p of allPractices" [value]="p.practiceid">{{ p.b }}</option>
                </select>
            </div>
        </div>
        <div *ngIf="graphType==='line'" class="row">
            <div class="input-group mb-3 col">
                <div class="form-check form-check-inline">
                    <label class="form-check-label"><b>Compare:</b></label>
                </div>
                <div *ngFor="let option of options" class="form-check form-check-inline">
                    <input class="form-check-input" name="compare" type="radio" id={{option.name}}
                        (change)="selectedOptions(option.value)" [(ngModel)]="selectedOption" [value]="option.value">
                    <label class="form-check-label" for={{option.name}}>{{option.name}}</label>
                </div>

            </div>
        </div>


        <div class="row">
            <div [ngStyle]="{'display': selectedOption==='practice' ? 'block' : 'none' }" class="input-group mb-3 col">
                <ng-multiselect-dropdown (onDeSelect)="onItemDeSelect($event)" [(ngModel)]="selected_practice"
                    (onSelect)="onItemSelect($event,'practice')" style="width:100%" [data]="practices"
                    [settings]="practicedropdownSettings">
                </ng-multiselect-dropdown>
            </div>
            <div [ngStyle]="{'display': selectedOption==='provider' ? 'block' : 'none' }" class="input-group mb-3 col">
                <ng-multiselect-dropdown [(ngModel)]="selected_provider" (onSelect)="onItemSelect($event,'provider')"
                    style="width:100%" [data]="providers" [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div>
            <div [ngStyle]="{'display': selectedOption==='location' ? 'block' : 'none' }" class="input-group mb-3 col">
                <ng-multiselect-dropdown [(ngModel)]="selected_location" (onSelect)="onItemSelect($event,'location')"
                    style="width:100%" [data]="locations" [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div>
            <div [ngStyle]="{'display': selectedOption==='role' ? 'block' : 'none' }" class="input-group mb-3 col">
                <ng-multiselect-dropdown [(ngModel)]="selected_role" (onSelect)="onItemSelect($event,'role')"
                    style="width:100%" [data]="roles" [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div>
        </div>

        <div class="row filter">
            <div class="input-group col-12 mb-2">
                <div *ngFor="let s of selected_location;let i=index">
                    <b *ngIf="i==0">Location:</b>
                    <span class="help-block mr-2">
                        {{s.b}} <span *ngIf="i<selected_location.length-1"> |</span>
                    </span>
                </div>
            </div>
            <div class="input-group col-12 mb-2">
                <div *ngFor="let s of selected_practice;let i=index">
                    <b *ngIf="i==0">Practice:</b>
                    <span class="help-block mr-2">
                        {{s.b}} <span *ngIf="i<selected_practice.length-1"> |</span>
                    </span>
                </div>

            </div>
            <div class="input-group col-12 mb-2">
                <div *ngFor="let s of selected_provider;let i=index">
                    <b *ngIf="i==0">Provider:</b>
                    <span class="help-block mr-2">
                        {{s.b}} <span *ngIf="i<selected_provider.length-1"> |</span>
                    </span>
                </div>
            </div>
            <div class="input-group col-12 mb-2">
                <div *ngFor="let s of selected_role;let i=index">
                    <b *ngIf="i==0">Role:</b>
                    <span class="help-block mr-2">
                        {{s.b}} <span *ngIf="i<selected_role.length-1"> |</span>
                    </span>
                </div>
            </div>

        </div>


    </div>



</div>
<div class="modal-footer">

    <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
    <button [disabled]="selected_practice?.length==0 && selected_provider?.length==0 && selected_role?.length==0 && selected_location?.length==0 && _benchMarkValue==0 
        && selectedBanchMarkOption!='nationalbenchmar'
        && selectedBanchMarkOption!='lastmonthavg'
        && selectedBanchMarkOption!='lastyearavg'" (click)="onSubmit()" type="button"
        class="btn btn-primary">Done</button>
</div>