import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "view-options-modal",
    templateUrl: "./view-options-modal.component.html",
    styleUrls: ["./view-options-modal.component.scss"],
})
export class ViewOptionsModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private snackbar: SnackbarService, private waitingRoomService: WaitingRoomService) {}

    @Input() ViewOptionsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    error = "";
    isPatientDashboard: boolean = false;
    columns = [];
    ngOnInit() {
        let obj = this.ViewOptionsData.viewOptions.columns.sort(function (a, b) {
            var nameA = a.display.toLowerCase(),
                nameB = b.display.toLowerCase();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
        });
        this.columns = JSON.parse(JSON.stringify(this.ViewOptionsData.viewOptions.columns));
    }

    public onSave() {
        let storageId = "waiting-room-columns";
        if (this.ViewOptionsData.page === "WaitingRoom") {
            storageId = "waiting-room-columns";
        } else {
            storageId = "referral-room-columns";
        }
        let visibleColumns = this.columns.filter((x) => x.show === true);
        if (visibleColumns.length < 1) {
            this.error = "Select at least one option";
            return;
        }
        let viewOptions = [];
        let sortedColumns = this.columns.sort(function (a, b) {
            var orderA = a.order,
                orderB = b.order;
            if (orderA < orderB)
                //sort string ascending
                return -1;
            if (orderA > orderB) return 1;
            return 0; //default return value (no sorting)
        });
        let existingColumnsOptions = JSON.parse(localStorage.getItem(storageId));
        if (existingColumnsOptions) {
            let pageColumns = existingColumnsOptions.filter((x) => x.pageid === this.ViewOptionsData.pageid);
            if (pageColumns && pageColumns.length > 0) {
                pageColumns[0].columns = sortedColumns;
                localStorage.setItem(storageId, JSON.stringify(existingColumnsOptions));
            } else {
                let columnData = {
                    pageid: this.ViewOptionsData.pageid,
                    columns: sortedColumns,
                };
                existingColumnsOptions.push(columnData);
                localStorage.setItem(storageId, JSON.stringify(existingColumnsOptions));
            }
        } else {
            let columnData = {
                pageid: this.ViewOptionsData.pageid,
                columns: sortedColumns,
            };
            viewOptions.push(columnData);
            localStorage.setItem(storageId, JSON.stringify(viewOptions));
        }

        this.Response.emit({ success: true, message: "View Options Updated" });
        this.activeModal.close();
    }
    public onCancel() {
        this.activeModal.close();
    }
}
