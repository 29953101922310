<div class="modal-header">
  <h5 class="modal-title" id="confidentialityStatementTitle">
    <!-- {{title}} -->
    Please Accept to Continue
  </h5>
</div>
<div class="modal-body">
  <!-- {{message}} -->
  <p>All actions bound by Clio Health <a [href]="privacyPolicyLink" target="_blank">Privacy Policy</a> and <a [href]="termsOfServiceLink" target="_blank">Terms of Service</a>.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="onDecline()" aria-label="Decline confidentiality statement">{{declineBtn}}</button>
  <button type="button" class="btn btn-primary" (click)="onAccept()" aria-label="Accept confidentiality statement">{{acceptBtn}}</button>
</div>