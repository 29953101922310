<div class="container-fluid patient-billing">

    <div *ngIf="!isFavorite" class="tab">
        <a class="tablinks active" [routerLink]="['/patient-billing']">
            <img src="/assets/msx/favorites-white.svg"> Favorites
        </a>
        <a class="tablinks" [routerLink]="['/billing-data']">
            <img src="/assets/msx/surveydata-white.png"> Billing Data
        </a>
    </div>

    <div class="row mt-4">
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="specialtiesClaims===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="specialtiesClaims!==undefined"
                [reportData]="specialtiesClaims"></app-patient-billing-graph>
        </div>
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="locationsClaims===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="locationsClaims!==undefined"
                [reportData]="locationsClaims"></app-patient-billing-graph>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="specialtiesAvgBillAmount===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="specialtiesAvgBillAmount!==undefined"
                [reportData]="specialtiesAvgBillAmount"></app-patient-billing-graph>
        </div>
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="locationsAvgBillAmount===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="locationsAvgBillAmount!==undefined"
                [reportData]="locationsAvgBillAmount"></app-patient-billing-graph>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="specialtiesTotalBillAmount===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="specialtiesTotalBillAmount!==undefined"
                [reportData]="specialtiesTotalBillAmount"></app-patient-billing-graph>
        </div>
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="locationsTotalBillAmount===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="locationsTotalBillAmount!==undefined"
                [reportData]="locationsTotalBillAmount"></app-patient-billing-graph>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="diagnosesAmount===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="diagnosesAmount!==undefined"
                [reportData]="diagnosesAmount"></app-patient-billing-graph>
        </div>
        <div class="col-6">
            <app-loader class="graphloader" *ngIf="proceduresAmount===undefined"></app-loader>
            <app-patient-billing-graph *ngIf="proceduresAmount!==undefined"
                [reportData]="proceduresAmount"></app-patient-billing-graph>
        </div>
    </div>
</div>