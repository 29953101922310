<div class="modal-body">
    <div class="category-header">
        <label>Setup Two factor Authenication</label>
    </div>
    <form [formGroup]="twoFactorData" (ngSubmit)="onSubmit()">
        <div class="main-row">
            <div class="main-full">
                <!-- Step 1 -->
                <div class="d-flex py-2" *ngIf="Action !== 'disable'">
                    <div class="mr-3">
                        <div class="step-circle">1</div>
                    </div>
                    <div>
                        <h5 class="mb-1">Download the Authenticator App</h5>
                        <small class="text-muted">Install the authenticator app <a [href]="googleAuthenticatorLink"
                                target="_blank">Google Authenticator.</a>
                        </small>
                    </div>
                </div>

                <!-- Step 2 -->
                <div class="d-flex py-2" *ngIf="Action !== 'disable'">
                    <div class="mr-3">
                        <div class="step-circle">2</div>
                    </div>
                    <div>
                        <h5 class="mb-1">Scan this QR Code</h5>
                        <small class="text-muted">Scan the code below using the Google Authenticator app on your phone
                            or
                            mobile device.</small>
                    </div>
                </div>
                <div class="row" *ngIf="Action !== 'disable'">
                    <div *ngIf="codeImageData" class="col-12 d-flex justify-content-center mb-3">
                        <img [src]="codeImageData" />
                    </div>
                </div>

                <!-- Step 3 -->
                <div class="d-flex py-2" *ngIf="Action !== 'disable'">
                    <div class="mr-3">
                        <div class="step-circle">3</div>
                    </div>
                    <div>
                        <h5 class="mb-1">Enter your Authentication Code</h5>
                        <small class="text-muted">Enter the 6 digit PIN generated by the application.</small>
                        <input formControlName="pin" type="text" class="form-control mt-2" id="twoFactorPin"
                            aria-describedby="twoFactorPinHelp" placeholder="PIN" maxlength="6" minlength="6" required
                            autofocus ngbAutofocus />
                    </div>
                </div>

                <!-- Turn off 2FA-->
                <div class="d-flex py-2" *ngIf="Action === 'disable'">
                    <!-- <div class="mr-3">
                <div class="step-circle">3</div>
            </div> -->
                    <div>
                        <small class="text-muted">{{ modalInstructions }}</small>
                        <input formControlName="pin" type="text" class="form-control mt-2" id="twoFactorPin"
                            aria-describedby="twoFactorPinHelp" placeholder="PIN" maxlength="6" minlength="6" required
                            autofocus ngbAutofocus />
                    </div>
                </div>

                <ngb-alert *ngIf="errorMessage.length" [dismissible]="false" [type]="'danger'" class="mb-0">
                    {{ errorMessage }}
                </ngb-alert>
            </div>
        </div>
        <div class="main-row main-row-footer">
            <div class="main-left">
                <button type="button" id="closeBtn" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            </div>
            <div class="main-right">
                <button type="submit" class="btn btn-primary" [disabled]="verifyingPin">
                    {{ verifyingPin ? actionBtnInProgress : actionBtn }}
                    <span class="ml-2" *ngIf="verifyingPin"><i class="fas fa-circle-notch fa-spin-fast"></i></span>
                </button>
            </div>
        </div>
    </form>
</div>