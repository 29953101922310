<div class="container-fluid">
    <div *ngIf="isFavorite" class="filters row favorites">
        <!-- <label class="heading">
            {{favoriteList!==undefined? favoriteList?.name+' - ':""}}
            {{metricName}}
            &nbsp;{{chartTitle}}</label> -->

        <label class="heading">
            {{favoriteList!==undefined? favoriteList?.name:metricName+" "+chartTitle}}
        </label>


        <label *ngIf="getFilters()!=''" class="filters">
            <img src="./assets/msx/filter.svg"> <span>FILTER:</span>
            {{getFilters()}}


            <!--Age: {{filters?.age.displayText}}
            Gender: {{filters?.gender}}
            ICD-10: {{filters?.icdCode}}
            CPT: {{filters?.cptCode}}
            Specialty: {{filters?.specialtyDisplayText}}
            Survey Type: {{filters?.surveyTypeDisplayText}}
            -->

        </label>

        <div style="width: 100%;" *ngIf="isValid(selectedFactors) && isFavorite">
            <div *ngIf="selectedFactors!==null &&  selectedFactors?.selected_practice.length>0"
                class="filters row mt-2 ml-0">
                <span class="factors">Practice:</span>
                <span *ngFor="let name of selectedFactors.selected_practice;index as i">
                    {{name.b}} <span *ngIf="i<selectedFactors.selected_practice.length-1"> |</span>
                </span>
            </div>

            <div *ngIf="selectedFactors?.selected_provider.length>0" class="filters row mt-2 ml-0">
                <span class="factors">Provider:</span>
                <span *ngFor="let name of selectedFactors.selected_provider;index as i"
                    (click)="removeFilter(name.b,'provider')">
                    {{name.b}}<span *ngIf="i<selectedFactors.selected_provider.length-1"> |</span>
                </span>
            </div>
            <div *ngIf="selectedFactors?.selected_location.length>0" class="filters row mt-2 ml-0">
                <span class="factors">Location:</span>
                <span *ngFor="let name of selectedFactors.selected_location;index as i"
                    (click)="removeFilter(name.b,'location')">
                    {{name.b}}<span *ngIf="i<selectedFactors.selected_location.length-1"> |</span>
                </span>
            </div>
            <div *ngIf="selectedFactors?.selected_role.length>0" class="filters row mt-2 ml-0">
                <span class="factors">Role:</span>
                <span *ngFor="let name of selectedFactors.selected_role;index as i"
                    (click)="removeFilter(name.b,'role')">
                    {{name.b}}<span *ngIf="i<selectedFactors.selected_role.length-1"> |</span>
                </span>
            </div>


        </div>

    </div>
    <div *ngIf="!isFavorite && router.url!=='/visit-data'" class="tab">
        <a class="tablinks" [routerLink]="['/favorites']" [queryParams]="{type: 'survey'}">
            <img src="/assets/msx/favorites.svg"> Favorites
        </a>

        <a *ngIf="router.url!=='/visit-data'" class="tablinks active" [routerLink]="['/survey-data']">
            <img src="/assets/msx/surveydata-white.png"> Survey Data
        </a>
        <a *ngIf="router.url==='/visit-data'" class="tablinks active" [routerLink]="['/visit-data']">
            <img src="/assets/msx/surveydata-white.png"> Visit Data
        </a>
        <a class="tablinks" [routerLink]="['/msx/reports']">
            <img src="/assets/msx/reports.svg"> Reports
        </a>
    </div>

    <div *ngIf="router.url==='/visit-data'" class="tab">
        <a class="tablinks" [routerLink]="['/favorites']" [queryParams]="{type: 'visit'}">
            <img src="/assets/msx/favorites.svg"> Favorites
        </a>
        <a *ngIf="router.url!=='/visit-data'" class="tablinks active" [routerLink]="['/survey-data']">
            <img src="/assets/msx/surveydata-white.png"> Survey Data
        </a>
        <a *ngIf="router.url==='/visit-data'" class="tablinks active" [routerLink]="['/visit-data']">
            <img src="/assets/msx/surveydata-white.png"> Visit Data
        </a>
    </div>


    <div *ngIf="!showLoader" class="row leftDiv">

        <div *ngIf="url!=='/visit-data'" class="col-2 pr-0">

            <h4 [ngClass]="[isFavorite==true ? 'f-matric-name' : 'matric-name']">{{metricName}}</h4>
            <button *ngIf="!isFavorite" (click)="selectedMatric()" class="btn_filter">
                <img src="/assets/msx/changebutton.svg">
                Select</button><br>
        </div>
        <div *ngIf="url==='/visit-data'" class="col-2">
            <h4 [ngClass]="[isFavorite==true ? 'f-matric-name' : 'matric-name']">{{metricName}}</h4>
            <button *ngIf="!isFavorite" (click)="selectedVisitMatric()" class="btn_filter">
                <img src="/assets/msx/changebutton.svg">
                Select</button><br>
            <!-- <h4 [ngClass]="[isFavorite==true ? 'f-matric-name' : 'matric-name']">Visit Count</h4> -->
        </div>
        <div class="col-10">
            <!-- <div class="filters row">
                <button *ngIf="chartData.length==0" class="btn_filter grey" (click)="reset()">
                    <img src="/assets/msx/filter-white.png">RESET
                </button>
            </div> -->


            <div>
                <div *ngIf="!isFavorite" class="filters">
                    <h5 class="top-metric-name">{{metricName}}
                        &nbsp;{{chartTitle}}</h5>
                </div>

                <div class="filters row">
                    <div *ngIf="!isFavorite" class="col-6 p-0">
                        <button *ngIf="isValid(selectedFactors) && selectedFactors!==null  && selectedFactors?.selected_practice.length>0
                            || selectedFactors?.selected_provider.length>0
                            || selectedFactors?.selected_location.length>0
                            || selectedFactors?.selected_role.length>0
                            || filters.gender!=='ALL' || filters.cptCode!=='ALL'
                            || filters.icdCode!=='ALL' || filters.specialty!=='ALL'
                            || filters.surveyType!=='ALL' 
                            || filters.age.displayText!=='ALL'
                            " class="btn_filter grey" (click)="reset()">
                            <img src="/assets/msx/filter-white.png">RESET
                        </button>


                        <button *ngIf="!this.noShowPercentage" (click)="showFilters()" class="btn_filter">
                            <img src="/assets/msx/filter-white.png">FILTER
                        </button>
                    </div>

                    <div *ngIf="!isFavorite" class="col-6 text-right">
                        <a *ngIf="chartData.length>0 && !noData" class="btn-default" href="#"
                            (click)="$event.preventDefault();setFavorites()"><img src="/assets/msx/setfavorite.svg">Set
                            Favorite
                        </a>
                        <!-- ;setFavorites() -->
                        <!-- <a class="btn-default"  href="#">
                        <img src="/assets/msx/export.svg">Export
                    </a> -->
                    </div>
                </div>
                <div *ngIf="isValid(selectedFactors) && !isFavorite">
                    <div *ngIf="selectedFactors!==null &&  selectedFactors?.selected_practice.length>0"
                        class="filters row mt-2 mb-2">
                        <label class="mr-2">Practice:</label>
                        <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_practice"
                            (click)="removeFilter(name,'practice')">{{name.b}}
                            &nbsp;<i class="fa fa-times-circle"></i>
                        </button>
                    </div>

                    <div *ngIf="selectedFactors?.selected_provider.length>0" class="filters row mt-2 mb-2">
                        <label class="mr-2">Provider:</label>
                        <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_provider"
                            (click)="removeFilter(name.b,'provider')">{{name.b}}
                            &nbsp;<i class="fa fa-times-circle"></i>
                        </button>
                    </div>
                    <div *ngIf="selectedFactors?.selected_location.length>0" class="filters row mt-2 mb-2">
                        <label class="mr-2">Location:</label>
                        <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_location"
                            (click)="removeFilter(name.b,'location')">{{name.b}}
                            &nbsp;<i class="fa fa-times-circle"></i>
                        </button>
                    </div>
                    <div *ngIf="selectedFactors?.selected_role.length>0" class="filters row mt-2 mb-2">
                        <label class="mr-2">Role:</label>
                        <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_role"
                            (click)="removeFilter(name.b,'role')">{{name.b}}
                            &nbsp;<i class="fa fa-times-circle"></i>
                        </button>
                    </div>


                </div>
                <!-- 
                <div style="font-weight: bold;text-decoration: underline;" class="filters row">
                    {{favoriteList?.name}}
                </div> -->

                <div *ngIf="!isFavorite" class="filters row">
                    <label class="mt-2" *ngIf="filters.age.displayText!=='ALL' || filters.gender!=='ALL' || filters.cptCode!=='ALL'
                    || filters.icdCode!=='ALL' || filters.specialty!=='ALL'
                    || filters.surveyType!=='ALL'">
                        Applied Filters:&nbsp;
                    </label>
                    <button (click)="removeAppliedFilter('age')" *ngIf="filters.age.displayText!=='ALL'"
                        class="btn_filter grey">Age:
                        {{filters.age.displayText}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                    <button (click)="removeAppliedFilter('gender')" *ngIf="filters.gender!=='ALL'"
                        class="btn_filter grey">Gender:
                        {{filters.gender}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                    <button (click)="removeAppliedFilter('icdcode')" *ngIf="filters.icdCode!=='ALL'"
                        class="btn_filter grey">ICD-10:
                        {{filters.icdCode}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                    <button (click)="removeAppliedFilter('cptcode')" *ngIf="filters.cptCode!=='ALL'"
                        class="btn_filter grey">CPT:
                        {{filters.cptCode}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                    <button (click)="removeAppliedFilter('specialty')" *ngIf="filters.specialty!=='ALL'"
                        class="btn_filter grey">Specialty:
                        {{filters.specialtyDisplayText}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                    <button (click)="removeAppliedFilter('surveytype')" *ngIf="filters.surveyType!=='ALL'"
                        class="btn_filter grey">Survey Type:
                        {{filters.surveyTypeDisplayText}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>


                </div>

                <!-- <div class="filters row mt-3">
                    <span><strong>{{router.url!=='/visit-data'?metricName:'VISIT DATA'}}
                            &nbsp;</strong>{{chartTitle}}</span>
                </div> -->


                <div class="position-relative" *ngIf="chartData.length>0 && !noData">
                    <!-- <button (click)="filterBy('Practice')" class="btn-graph-filter">Monthly for Practice</button>
                    <button (click)="filterBy('Provider')" class="btn-graph-filter">Recently by Provider</button>
                    <button (click)="filterBy('Location')" class="btn-graph-filter">Recently by Location</button>
                    <button class="btn-graph-filter">Report Card</button> -->
                    <highcharts-chart [callbackFunction]="chartCallback" *ngIf="chartOptions && graphType=='line'"
                        [Highcharts]="Highcharts" style="width: 98%; display: block; overflow: hidden;padding:10px 0;"
                        [options]="chartOptions" [oneToOne]="oneToOneFlag" [(update)]="updateFlag"></highcharts-chart>
                    <highcharts-chart [callbackFunction]="chartCallback" *ngIf="chartOptions && graphType=='column'"
                        [Highcharts]="Highcharts" style="width: 98%; display: block; overflow: hidden;padding:10px 0;"
                        [options]="chartOptions" [oneToOne]="true" [(update)]="updateFlag"></highcharts-chart>


                    <button *ngIf="!isFavorite && router.url!=='/visit-data'" class="btn_filter"
                        (click)="compareGraph()">
                        <img src="/assets/msx/compare.svg">&nbsp;COMPARE
                    </button>
                </div>
                <div class="noData" *ngIf="chartData.length==0 || noData">
                    <label>No data available for the current selection.</label>
                </div>
                <div [ngClass]="[isFavorite==true ? 'row graph_footer mb-0' : 'row graph_footer mb-5']">
                    <div *ngIf="!allowPagination" class="col-md-3 p-0">
                        <div class="spinner-border" role="status">
                            <!-- <span class="visually-hidden">Loading...</span> -->
                        </div>
                    </div>
                    <div *ngIf="allowPagination" class="col-md-3 p-0">
                        <div *ngIf="chartData.length>0 && !noData">
                            <button [className]="page==0  ? 'not-allowed' : ''" (click)="previous()"><i
                                    class="fa fa-chevron-left" aria-hidden="true"></i></button>
                            <button [className]="pageCount-1==page? 'not-allowed' : ''" (click)="next()"><i
                                    class="fa fa-chevron-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <div class="col-md-4">

                        <h4 [ngClass]="[isFavorite==true ? 'f-matric-name' : 'matric-name']">{{getLableName()}}
                            &nbsp;<a *ngIf="!isFavorite && getLableName()!='Provider'" (click)="xAxisFilter()"
                                class="btn_filter">
                                <img src="/assets/msx/changebutton.svg">Select</a>
                        </h4>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>

</div>