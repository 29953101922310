import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { SessionService } from "../../services/session.service";
import { HttpClient } from "@angular/common/http";

declare var Twilio: any;
declare var window: any;

// Attach the Tracks to the DOM.
function attachTracks(tracks, container) {
    tracks.forEach(function (track) {
        container.appendChild(track.attach());
    });
}

// Attach the Participant's Tracks to the DOM.
function attachParticipantTracks(participant, container) {
    const tracks = getTracks(participant);
    attachTracks(tracks, container);
}

// Detach the Tracks from the DOM.
function detachTracks(tracks) {
    tracks.forEach(function (track) {
        track.detach().forEach(function (detachedElement) {
            detachedElement.remove();
        });
    });
}

// Detach the Participant's Tracks from the DOM.
function detachParticipantTracks(participant) {
    const tracks = getTracks(participant);
    detachTracks(tracks);
}

// Get the Participant's Tracks.
function getTracks(participant) {
    return Array.from(participant.tracks.values())
        .filter((publication: any) => publication.track)
        .map((publication: any) => publication.track);
}

// Activity log.
function log(message) {
    const logDiv = document.getElementById("log");
    logDiv.innerHTML += "<p>&gt;&nbsp;" + message + "</p>";
    logDiv.scrollTop = logDiv.scrollHeight;
}

@Component({
    selector: "app-video-chat",
    templateUrl: "./video-chat.component.html",
    styleUrls: ["./video-chat.component.scss"],
})
export class VideoChatComponent implements OnInit {
    activeRoom;
    previewTracks;
    identity;

    _userId = "";
    roomName = "test"; //this.auth.currentUser.userId;
    token; //="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzRhYjIzYWM2ZmRiOGNjNDc4NTZjY2Q1YjI1N2I3ZGI5LTE1ODQ2MDYwNTMiLCJpc3MiOiJTSzRhYjIzYWM2ZmRiOGNjNDc4NTZjY2Q1YjI1N2I3ZGI5Iiwic3ViIjoiQUM4ZjA3YTc0MTA4NWIxMGQxZWNhMGYzOTlhYWUzMTJiNCIsImV4cCI6MTU4NDYwOTY1MywiZ3JhbnRzIjp7ImlkZW50aXR5IjoibW9iaWxlYXBwIiwidmlkZW8iOnsicm9vbSI6IlRlc3RSb29tIn19fQ._G6ZhcjriR2Y514IQA7dAxJ9BJL-jxeC6-6ZwBeNQX0";
    hideJoinRoom = false;
    hideLeaveRoom = true;
    constructor(private http: HttpClient, private sessionService: SessionService) {}

    ngOnInit() {
        this._userId = this.sessionService.currentUser?.id;
    }

    fetch() {
        if (this.token) {
            return Promise.resolve();
        }

        let url = environment.uri + "/communications/twilio/token?roomid=" + this.roomName + "&identityname=" + this.identity;
        return this.http
            .get(url)
            .toPromise()
            .then((data: any) => {
                let jsonData = data;
                this.token = jsonData.data[0].attributes.token;
            });
    }

    buttonPreview() {
        // this.getPermissions().then(() => {
        const localTracksPromise = this.previewTracks ? Promise.resolve(this.previewTracks) : Twilio.Video.createLocalTracks();
        let errHandler = (err) => {
            console.error("Unable to access local media", err);
            log("Unable to access Camera and Microphone");
        };
        localTracksPromise.then(this.setLocalStream, errHandler);
    }

    setLocalStream = (tracks) => {
        this.previewTracks = tracks;
        (<any>window).previewTracks = this.previewTracks;
        const previewContainer = document.getElementById("local-media");
        if (!previewContainer.querySelector("video")) {
            attachTracks(tracks, previewContainer);
        }
    };

    // Successfully connected!
    roomJoined = (room) => {
        this.activeRoom = room;
        (<any>window).room = this.activeRoom;

        log(`Joined as '${this.identity}'`);
        this.hideJoinRoom = true;
        this.hideLeaveRoom = false;

        // Attach LocalParticipant's Tracks, if not already attached.
        const previewContainer = document.getElementById("local-media");
        if (!previewContainer.querySelector("video")) {
            attachParticipantTracks(room.localParticipant, previewContainer);
        }

        // Attach the Tracks of the Room's Participants.
        room.participants.forEach((participant) => {
            log(`Already in Room: '${participant.identity}'`);
            const _previewContainer = document.getElementById("remote-media");
            attachParticipantTracks(participant, _previewContainer);
        });

        // When a Participant joins the Room, log the event.
        room.on("participantConnected", (participant) => {
            log(`Joining: '${participant.identity}'`);
        });

        // When a Participant's Track is subscribed to, attach it to the DOM.
        room.on("trackSubscribed", (track, publication, participant) => {
            log(`Subscribed to ${participant.identity}'s track: ${track.kind}`);
            const _previewContainer = document.getElementById("remote-media");
            attachTracks([track], _previewContainer);
        });

        // When a Participant's Track is unsubscribed from, detach it from the DOM.
        room.on("trackUnsubscribed", (track, publication, participant) => {
            log(`Unsubscribed from ${participant.identity}'s track: ${track.kind}`);
            detachTracks([track]);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on("participantDisconnected", (participant) => {
            log(`RemoteParticipant '${participant.identity}' left the room`);
            detachParticipantTracks(participant);
        });

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        room.on("disconnected", () => {
            log("Left");
            if (this.previewTracks) {
                this.previewTracks.forEach((track) => track.stop());
            }
            detachParticipantTracks(room.localParticipant);
            room.participants.forEach(detachParticipantTracks);
            this.activeRoom = null;
            this.hideJoinRoom = false;
            this.hideLeaveRoom = true;
        });
    };

    joinRoom() {
        if (!this.roomName) {
            alert("Please enter a room name.");
            return;
        }

        log(`Joining room '${this.roomName}'...`);

        const connectOptions: any = {
            name: this.roomName,
            logLevel: "debug",
        };

        if (this.previewTracks) {
            // console.log(this.previewTracks);
            connectOptions.tracks = this.previewTracks;
        }

        Promise.all([this.fetch()]).then(() => {
            Twilio.Video.connect(this.token, connectOptions).then(this.roomJoined, (err) => {
                log("Could not connect to Twilio: " + err.message);
            });
        });
    }
    leaveRoom() {
        log("Leaving room...");
        this.activeRoom.disconnect();
    }
}
