import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class ProviderService {
    private url = environment.msx_uri;
    constructor(private avcHttp: AvcHttp, private helper: Helper) {}
    public providerDetails(userId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/providers/detailsby/" + userId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public appointments(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/appointment/list").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    uri: string = environment.uri + "/user/userlist";

    public getProviderByRole(roleId: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.uri + "?roleid=" + roleId, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
}
