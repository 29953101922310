import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "./session.service";

@Injectable()
export class MembersService {
    private handler = `/msx`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService, private http: HttpClient) {}

    public getFilterOptions(): Observable<any> {
        let id = localStorage.getItem("selectedGroup") || "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest("get", this.url + "/GetFilterOptions/V2?GroupID=" + id.toLocaleLowerCase() + "&options=" + encodeURI("gender,age,provider,location,specialty,cptcode,lastvisits"), {}, {})
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    public saveFilterOptions(name, options): Observable<any> {
        let id = localStorage.getItem("selectedGroup") || "";
        debugger;
        let data = JSON.stringify({
            name: name,
            description: name,
            gender: options.genders
                .filter((g) => g.checked)
                .map((obj) => obj.id)
                .toString(),
            age: options.age
                .filter((g) => g.checked)
                .map((obj) => obj.id)
                .toString(),
            specialty_id: options.specialties
                .filter((g) => g.checked)
                .map((obj) => obj.id)
                .toString(),
            location_id: options.locations
                .filter((g) => g.checked)
                .map((obj) => obj.id)
                .toString(),
            provider_id: options.providers
                .filter((g) => g.checked)
                .map((obj) => obj.id)
                .toString(),
            cpt_code: options.cptCodes
                .filter((g) => g.checked)
                .map((obj) => obj.id)
                .toString(),
            group_id: id.toLocaleLowerCase(),
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/campaigns/members/group", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getMemberList(): Observable<any> {
        let id = localStorage.getItem("selectedGroup") || "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/campaigns/members/list/?groupId=" + id.toLocaleLowerCase() + "&limit=1000000", {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getGroups(): Observable<any> {
        let id = localStorage.getItem("selectedGroup") || "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/campaigns/members/group/?groupId=" + id.toLocaleLowerCase() + "&limit=1000000", {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteMember(id): Observable<any> {
        let data = JSON.stringify({
            member_id: id.toLocaleLowerCase(),
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/campaigns/members", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public deleteMemberList(id): Observable<any> {
        let data = JSON.stringify({
            member_group_id: id.toLocaleLowerCase(),
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/campaigns/members/group", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }

    public uploadMembers(fileToUpload: File, groupname = "") {
        let groupid = localStorage.getItem("selectedGroup") || "";

        return new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            formData.append("file", fileToUpload, fileToUpload.name);
            let headers = new HttpHeaders({
                Authorization: "Bearer " + this.sessionService.currentUser?.attributes.token,
            });
            let options = {
                headers: headers,
            };
            return this.http.post(environment.uri + "/msx/campaigns/members/import?groupId=" + groupid + "&groupName=" + groupname, formData, options).subscribe(
                (body) => {
                    if (body["data"] !== undefined && body["data"][0].attributes !== undefined) {
                        resolve(body["data"][0].attributes);
                    } else {
                        reject("errors");
                    }
                },
                (err) => {
                    reject(err.error.errors);
                }
            );
        });
    }

    public addEditMember(member: any, selectedGroups = []): Observable<any> {
        debugger;
        let data = JSON.stringify({
            firstname: member.fName,
            lastname: member.lName,
            email: member.email,
            phonenumber: member.phone,
            gender: member.gender,
            group_id: localStorage.getItem("selectedGroup") || "",
            member_group_ids: selectedGroups.toString(),
            status: "Active",
            dob: member.birthdate,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/campaigns/members", data).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        });
    }
}
