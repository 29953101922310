import { Component, OnInit } from '@angular/core';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  supportEmail: String = "";
  supportEmailMailTo: String = "";

  constructor() {
    this.supportEmail = environment.support_email;
    this.supportEmailMailTo = `mailto:${this.supportEmail}`;
  }

  ngOnInit(): void {
  }

}
