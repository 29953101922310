import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChatImageModalComponent } from "../../modals/chat-image-modal/chat-image-modal.component";
import { PatientService } from "../../services/patients.service";
import { SmartHeightDirective } from "../../directives/smart-height.directive";
import { SnackbarService } from "../snackbar/snackbar.service";

@Component({
    selector: "app-media-library",
    templateUrl: "./media-library.component.html",
    styleUrls: ["./media-library.component.scss"],
})
export class MediaLibraryComponent implements OnInit {
    public media_filter: any = [
        {
            type: "Member Uploaded",
        },
        {
            type: "Team Uploaded",
        },
        {
            type: "Show All",
        },
    ];
    public exension = "";
    mediaFiles = [];
    uploadToMediaLibrary = false;
    url: any;
    @ViewChild(SmartHeightDirective, { static: false }) vc: SmartHeightDirective;
    // vc: any = { render: () => { } }
    @ViewChild("fileInput") fileInput: any;
    @Input() patientId = "";
    @Input() careteamId = "";

    constructor(private snackbar: SnackbarService, private patientService: PatientService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.getLibrary();
    }
    public getLibrary() {
        return this.patientService.getLibrary(this.careteamId, this.patientId).subscribe(
            (library) => {
                this.mediaFiles = library[0].attributes.list;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public attachment(uploadToMedia = true) {
        this.uploadToMediaLibrary = uploadToMedia;
        let element = document.getElementById("filePicker");
        element.click();
    }
    public handleFileSelect(evt) {
        let files = evt.target.files;
        let file = files[0];
        if (file.size > 10506200) {
            this.snackbar.show("File size should be less than 10MB.", "danger");
            return false;
        }
        if (this.uploadToMediaLibrary) {
            if (files && file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let m = {
                        metadata: {
                            extension: file.name.split(".")[1],
                            sizeInKb: file.size,
                            actual_file_name: file.name,
                        },
                    };
                    this.url = reader.result;
                    // Re-render message history window height
                    this.vc.render();
                    this.showImage(reader.result, file.name.split(".")[1], m, file);
                    if (this.fileInput) {
                        this.fileInput.nativeElement.value = "";
                    }
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };
            }
        }
    }
    public showImage(image, fileExtension, m: any = {}, file = "") {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
        modalRef.componentInstance.fileExtension = fileExtension;
        modalRef.componentInstance.MetaData = m.metadata;
        modalRef.componentInstance.libraryId = m.lmid === undefined ? "" : m.lmid;
        modalRef.componentInstance.patientId = this.patientId;
        modalRef.componentInstance.careTeamId = this.careteamId;
        modalRef.componentInstance.file = file;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.getLibrary();
            }
        });
    }
}
