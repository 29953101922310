<ul ngbNav #nav="ngbNav" class="nav-tabs mt-2">
    <li [ngbNavItem]="1">
        <a ngbNavLink>Mobile OS</a>
        <ng-template ngbNavContent>
            <div id="platform-chart-container">
                <highcharts-chart *ngIf="osPieChart" [(update)]="updateFlag1" [Highcharts]="Highcharts" [options]="osPieChart"></highcharts-chart>
            </div>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
        <a ngbNavLink>Mobile Version</a>
        <ng-template ngbNavContent>
            <highcharts-chart
                *ngIf="versionsChart"
                style="width: 90%; display: block"
                [(update)]="updateFlag2"
                [oneToOne]="true"
                [Highcharts]="Highcharts"
                [options]="versionsChart"
            ></highcharts-chart>
        </ng-template>
    </li>
    <li [ngbNavItem]="3">
        <a ngbNavLink>Users</a>
        <ng-template ngbNavContent>
            <highcharts-chart *ngIf="usersChart" [(update)]="updateFlag3" [Highcharts]="Highcharts" style="width: 90%; display: block" [options]="usersChart"></highcharts-chart>
            <div class="ml-1 h4">Unique Users (last {{ selectedDaysValue }} days): {{ totalusers }}</div>
        </ng-template>
    </li>
    <select class="form-control rangeselect" [(ngModel)]="selectedDaysValue" (change)="getAnalytics()">
        <option value="90">90 days</option>
        <option value="60">60 days</option>
        <option value="30">30 days</option>
        <option value="7">7 days</option>
    </select>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
