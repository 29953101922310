<div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Links</h5>
        <button type="button" class="close" (click)="onCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center align-items-center">
            <app-loader></app-loader>
        </div>

        <div *ngIf="!showLoader" class="row">
            <div class="col-12 row">
                <div class="col-12">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs col-12 row">
                        <li ngbNavItem>
                            <a ngbNavLink>Links</a>
                            <ng-template ngbNavContent>
                                <div class="container-fluid">
                                    <table class="table table-striped">
                                        <tr *ngFor="let a of findapplication(apps, 'link')">
                                            <td style="width: 20px;" *ngIf="userId !== ''">
                                                <div class="custom-control custom-checkbox">
                                                    <input (change)="selectedApps(a.appid, $event)"
                                                        [checked]="a.ordered" class="custom-control-input"
                                                        type="checkbox" />
                                                    <label class="custom-control-label p-0"> </label>
                                                </div>
                                            </td>
                                            <td class="card-icon-td">
                                                <img class="card-icon" *ngIf="a?.imageurl !== ''"
                                                    src="{{ a.imageurl }}" />
                                            </td>
                                            <td>
                                                <h3>{{ a.title }}</h3>
                                                <p>
                                                    <span class="source">Source: </span>
                                                    <span class="source-type">
                                                        <span class="source-data">{{ a.linktype }}</span></span>
                                                </p>
                                                <p>{{ a.description }}</p>
                                            </td>
                                        </tr>
                                    </table>
                                    <label *ngIf="userAppCount === 0"> There are no links to assign. </label>
                                </div>
                            </ng-template>
                        </li>
                        <!-- <li ngbNavItem>
                            <a (click)="getapplication(apps, 'application')" ngbNavLink>Apps</a>
                            <ng-template ngbNavContent>
                                <div class="container-fluid">
                                    <table class="appsTable">
                                        <tbody *ngFor="let item of linkTypes">
                                            <ng-container>
                                                <tr>
                                                    <td style="width: 20px;" *ngIf="userId !== ''">
                                                        <div class="custom-control custom-checkbox">
                                                            <input (change)="selectedTelerx(item.telerxid, $event)"
                                                                [checked]="item.ordered" class="custom-control-input"
                                                                type="checkbox" />
                                                            <label class="custom-control-label p-0"> </label>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="userId !== ''" colspan="2">
                                                        <b>{{ item.name }}</b>

                                                    </td>
                                                    <td *ngIf="userId === ''" colspan="3">
                                                        <b>{{ item.name }}</b>
                                                    </td>
                                                </tr>
                                                <tr class="trApps" *ngFor="let a of item.groupItem">
                                                    <br />

                                                    <td class="app-card-icon-td">
                                                        <img class="card-icon" *ngIf="a?.imageurl !== ''"
                                                            src="{{ a.imageurl }}" />
                                                    </td>
                                                    <td>
                                                        <h3>{{ a.title }}</h3>
                                                        <p>
                                                            <span class="source">Source: </span>
                                                            <span class="source-type">
                                                                <span class="source-data">{{ a.linktype }}</span></span>
                                                        </p>
                                                        <p>{{ a.description }}</p>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                        <label *ngIf="linkTypes?.length === 0"> There are no links to assign. </label>
                                    </table>
                                </div>
                            </ng-template>
                        </li> -->
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
            <ngb-alert *ngIf="errorMessage.length" [dismissible]="false" [type]="'danger'" class="mb-0">
                {{ errorMessage }}
            </ngb-alert>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="userId !== ''" class="btn btn-primary" (click)="onAssignApp()">Update</button>
    </div>
</div>