import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { IncidentModalComponent } from "../../modals/incident-modal/incident-modal.component";

export interface SearchElements {
    date: string;
    status: string;
}

@Component({
    selector: "incident-listing-modal",
    templateUrl: "./incident-listing-modal.component.html",
    styleUrls: ["./incident-listing-modal.component.scss"],
})
export class IncidentListingModalComponent implements OnInit {
    public incidentData: any = {};
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        public tableService: TableService
    ) {
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
        this.tableService.searchOnColumn = "";
        this.tableService.pageSize = 100;
        this.tableService.searchTerm = "";
    }

    @Input() IncidentsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    currentPatient: any = {};
    incidents: any = [];
    patientId: string = "";
    careteamId: string = "";
    public displayedColumns = [];
    statusFilter = new UntypedFormControl("");
    filters = [];
    public disableAddtoTriage = false;
    showLoader = false;
    filterText = "";

    ngOnInit() {
        this.displayedColumns = [
            { displayname: "Date", columnname: "date" },
            { displayname: "Time", columnname: "date" },
            { displayname: "ICD10", columnname: "icdcodes" },
            { displayname: "CPT", columnname: "cptcodes" },
            { displayname: "Status", columnname: "status" },
            { displayname: "", columnname: "" },
        ];
        this.patientId = this.IncidentsData.patientId;
        this.careteamId = this.IncidentsData.careteamId;
        this.loadIncidents(this.patientId);
    }
    public onCancel() {
        this.Response.emit({ success: true, data: this.incidents });
        this.activeModal.close();
    }
    public loadIncidents(patientId) {
        this.showLoader = true;
        return this.patientService.getSymptomsfromWaitingLists(patientId, this.careteamId).subscribe(
            (data) => {
                if (data[0].attributes.requestlist && data[0].attributes.requestlist.length > 0) {
                    let incidentData = [];
                    let incidentsArray = data[0].attributes.requestlist;
                    incidentsArray.sort(function (x, y) {
                        let a = new Date(x.createdat),
                            b = new Date(y.createdat);
                        return b.getTime() - a.getTime();
                    });
                    let appointmentList = data[0].attributes.requestlist.filter((x) => (x.requesttype === "appointment" || x.requesttype === "symptoms") && x.requestdata?.symptoms);
                    appointmentList.forEach(function (incident) {
                        let row = {};
                        if (incident.requestdata) {
                            let categories = [];
                            let symptoms = incident?.requestdata?.symptoms;
                            let cpt = incident?.requestdata?.cpt;
                            let icdCodes = [];
                            let generalSymptoms = [];
                            let other = [];
                            let duration = [];
                            Object.keys(symptoms).forEach((key) => {
                                categories.push({
                                    key: key,
                                    value: symptoms[key],
                                });
                                let icdcode = symptoms[key].reasons.map(function (elem) {
                                    return elem.icdcode;
                                });
                                icdcode.forEach((element) => {
                                    icdCodes.push(element ? element : "");
                                });
                                let generalSymptom = [];
                                if (symptoms[key]["other"]) {
                                    other.push(symptoms[key]["other"]);
                                } else {
                                    other.push("-");
                                }
                                let durationValue = "-";
                                if (symptoms[key]["duration"]) {
                                    durationValue =
                                        symptoms[key]["duration"].since +
                                        " " +
                                        (parseInt(symptoms[key]["duration"].since) > 1 ? symptoms[key]["duration"].sinceUnit : symptoms[key]["duration"].sinceUnit.slice(0, -1));
                                    duration.push(durationValue);
                                } else {
                                    duration.push(durationValue);
                                }
                                let exData = "";
                                if (symptoms[key]["other"]) {
                                    exData = "Other: " + symptoms[key]["other"];
                                }
                                if (durationValue !== "-") {
                                    if (exData !== "") {
                                        exData += ", ";
                                    }
                                    exData += "Since: " + durationValue;
                                }

                                if (exData !== "") {
                                    exData = " (" + exData + ")";
                                }
                                if (symptoms[key].reasons && symptoms[key].reasons.length > 0) {
                                    generalSymptom = symptoms[key].reasons.map(function (elem) {
                                        return (key ? key + " - " : "") + elem.generalsymptoms + exData;
                                    });
                                } else {
                                    generalSymptom.push(key + (exData === "" ? "" : " -" + exData));
                                }

                                generalSymptom.forEach((element) => {
                                    generalSymptoms.push(element ? element : "");
                                });
                            });
                            row["icdcodes"] = icdCodes;
                            row["generalsymptoms"] = generalSymptoms;
                            row["symptomsother"] = other;
                            row["symptomsduration"] = duration;
                            let cptcodes = [];
                            let cptexams = [];
                            if (incident?.requestdata?.cpt) {
                                cptcodes = incident?.requestdata?.cpt.map(function (elem) {
                                    return elem.code;
                                });
                                cptexams = incident?.requestdata?.cpt.map(function (elem) {
                                    return elem.exam;
                                });
                            }
                            row["cptcodes"] = cptcodes;
                            row["cptexams"] = cptexams;
                            row["categories"] = categories;
                            row["cpt"] = cpt;
                            row["other"] = incident.requestdata.other;
                            row["sentUserMessage"] = incident.requestdata.sentUserMessage;
                        }
                        row["userid"] = incident.userid;
                        row["wruid"] = incident.wruid;
                        row["date"] = incident.createdat;
                        row["status"] = incident.status;
                        row["requesttype"] = incident.requesttype;
                        row["requestcreated"] = incident.requestcreated;
                        incidentData.push(row);
                    });
                    this.incidents = incidentData;
                    this.tableService.DATA = incidentData;
                    this.statusFilter.valueChanges.subscribe((positionFilterValue) => {
                        this.tableService.searchOnColumn = positionFilterValue;
                    });
                    this.tableService.page = 1;
                    this.showLoader = false;
                    this.sorting("date", "desc");
                } else {
                    this.tableService.DATA = [];
                    this.tableService.page = 1;
                    this.showLoader = false;
                    this.sorting("date", "desc");
                }
            },
            (err) => {
                this.tableService.DATA = [];
                this.tableService.page = 1;
                this.showLoader = false;
                this.sorting("date", "desc");
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    onSort({ column, direction }: any) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction);
    }
    sorting(column, direction) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.page = 1;
        this.tableService.searchOn = "date";
    }
    public onEditIncident(incident) {
        this.incidentData = {
            action: "edit",
            careteamId: this.careteamId,
            patientId: this.patientId,
            ICDList: this.IncidentsData.ICDList,
            CPTList: this.IncidentsData.CPTList,
            incident: incident,
            gender: this.IncidentsData.gender,
        };
        const modalRef = this.modalService.open(IncidentModalComponent, { size: "lg", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.IncidentData = this.incidentData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.message != "") {
                this.snackbar.show(receivedEntry.message);
            }
            this.loadIncidents(this.patientId);
        });
    }
    onRefresh() {
        this.tableService.searchTerm = "";
        this.filterText = "";
    }
}
