<div class="modal-body">
    <div class="category-header">
        <label>{{ AssessmentType }}</label>

        <div class="filter-section pl-0">
            <!-- <p class="header-text">This functionality allows administrators to define specific words that will not be allowed in chat</p> -->

            <div class="filter-section-left">Date: {{ date | date: "MM/dd/yyyy" }}</div>
            <div class="filter-section-right text-right">Score: {{ score }}</div>
        </div>

        <table class="table table-striped datatable" style="text-align: left">
            <thead>
                <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of assessment">
                    <td>{{ a.questionText }}</td>
                    <td>{{ a.answerText }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="main-row">
        <p *ngIf="error" class="text-danger">{{ error }}</p>
    </div>
    <div class="main-row main-row-footer">
        <button type="button" class="btn btn-secondary float-right" (click)="onCancel()">Close</button>
    </div>
</div>
