<div class="modal-body">
    <div class="category-header">
        <!-- <label>Women's Health History</label> -->
    </div>
    <div class="main-row header-margin">
        <div class="main-full">
            <form [formGroup]="additionalForm">
                <div class="row visit-category-row">
                    <div class="col-lg-6 visit-category-columns">
                        <div class="checklist-item">
                            <label>How Long Have You Had These Symptoms?</label>
                        </div>
                    </div>
                    <div class="col-lg-6 visit-category-columns">
                        <div class="checklist-item">
                            <div class="column-left">
                                <input
                                    class="form-control"
                                    type="number"
                                    min="1"
                                    maxlength="4"
                                    [ngClass]="{ error: isFieldValid('since') }"
                                    formControlName="since"
                                    placeholder="Enter number"
                                    type="number"
                                />
                            </div>
                            <div class="column-right">
                                <select class="custom-select form-control" [ngClass]="{ error: isFieldValid('sinceUnit') }" formControlName="sinceUnit">
                                    <option value="">Please Select</option>
                                    <option value="Days">Days</option>
                                    <option value="Weeks">Weeks</option>
                                    <option value="Months">Months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="others">
                    <div class="others-label">
                        <label>Additional Comments</label>
                    </div>
                    <div class="others-text">
                        <textarea formControlName="other" type="text" class="others-textarea form-control" placeholder="Please describe" [ngClass]="{ error: isFieldValid('other') }"> </textarea>
                    </div>
                </div>
            </form>
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
    </div>
    <div class="main-row">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSubmit()">Continue</button>
        </div>
    </div>
</div>
