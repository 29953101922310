<div class="modal-body">
    <h5 class="modal-title" id="exampleModalLongTitle">{{heading}}</h5>
    <div class="row">
        <label for="graphName" class="col-sm-2 col-form-label">Name:</label>
        <div class="col-sm-10 pl-0">
            <input [disabled]="delete" type="text" [(ngModel)]="name" class="form-control" id="graphName"
                placeholder="Graph Name">
        </div>
    </div>
    <div class="text-center mt-4">
        <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
        <button *ngIf="delete==false" (click)="onSubmit()" type="button" class="btn btn-primary">Set</button>
        <button *ngIf="delete==true" (click)="onSubmit()" type="button" class="btn btn-primary">Delete</button>
    </div>
</div>
