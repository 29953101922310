import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Helper } from "../../../providers/helper";

@Component({
    selector: "social-information-modal",
    templateUrl: "./social-information-modal.component.html",
    styleUrls: ["./social-information-modal.component.scss"],
})
export class SocialInformationModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private helper: Helper) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    socialForm: UntypedFormGroup;
    error = "";
    public druguse: boolean = false;
    ngOnInit() {
        this.socialForm = this.fb.group({
            tobaccouse: [this.SummaryData.summary.socialhistory.tobaccouse ? this.SummaryData.summary.socialhistory.tobaccouse : "", []],
            alcoholuse: [this.SummaryData.summary.socialhistory.alcoholuse ? this.SummaryData.summary.socialhistory.alcoholuse : "", []],
            drugdetails: [this.SummaryData.summary.socialhistory.drugdetails ? this.SummaryData.summary.socialhistory.drugdetails : "", []],
            caffeineuse: [this.SummaryData.summary.socialhistory.caffeineuse ? this.SummaryData.summary.socialhistory.caffeineuse : "", []],
            sexuallyactive: [this.SummaryData.summary.socialhistory.sexuallyactive ? this.SummaryData.summary.socialhistory.sexuallyactive : "", []],
            exerciseFrequency: [this.SummaryData.summary.socialhistory.exerciseFrequency ? this.SummaryData.summary.socialhistory.exerciseFrequency : "", []],
            exerciseIntensity: [this.SummaryData.summary.socialhistory.exerciseIntensity ? this.SummaryData.summary.socialhistory.exerciseIntensity : "", []],
            exerciseDuration: [this.SummaryData.summary.socialhistory.exerciseDuration ? this.SummaryData.summary.socialhistory.exerciseDuration : "", []],
        });
        this.druguse = this.SummaryData.summary.socialhistory.druguse;
        if (!this.druguse) {
            this.socialForm.controls["drugdetails"].setValue("");
        }
    }
    /**
     * Returns true if form control is valid after being touched
     * @param key
     */
    isFieldValid(key) {
        return this.socialForm.get(key).invalid && this.socialForm.get(key).touched;
    }
    public onSave() {
        this.socialForm.markAllAsTouched();
        if (this.socialForm.valid) {
            this.SummaryData.summary.socialhistory.tobaccouse = this.helper.undefinedIfEmpty(this.socialForm.get("tobaccouse").value);
            this.SummaryData.summary.socialhistory.alcoholuse = this.helper.undefinedIfEmpty(this.socialForm.get("alcoholuse").value);
            this.SummaryData.summary.socialhistory.drugdetails = this.helper.undefinedIfEmpty(this.socialForm.get("drugdetails").value);
            this.SummaryData.summary.socialhistory.caffeineuse = this.helper.undefinedIfEmpty(this.socialForm.get("caffeineuse").value);
            this.SummaryData.summary.socialhistory.sexuallyactive = this.helper.undefinedIfEmpty(this.socialForm.get("sexuallyactive").value);
            this.SummaryData.summary.socialhistory.exerciseFrequency = this.helper.undefinedIfEmpty(this.socialForm.get("exerciseFrequency").value);
            this.SummaryData.summary.socialhistory.exerciseIntensity = this.helper.undefinedIfEmpty(this.socialForm.get("exerciseIntensity").value);
            this.SummaryData.summary.socialhistory.exerciseDuration = this.helper.undefinedIfEmpty(this.socialForm.get("exerciseDuration").value);
            this.SummaryData.summary.socialhistory.druguse = this.helper.undefinedIfEmpty(this.druguse);
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }
    toggleDrugUse(action) {
        this.druguse = action ? true : false;

        if (this.druguse === true) {
            this.socialForm.controls.drugdetails.setValidators([]);
            this.socialForm.controls.drugdetails.updateValueAndValidity();
        } else {
            this.socialForm.controls["drugdetails"].setValue("");
            this.socialForm.controls.drugdetails.setValidators([]);
            this.socialForm.controls.drugdetails.updateValueAndValidity();
        }
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
