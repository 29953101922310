import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { SessionService } from "../../services/session.service";
import { PracticeService } from "../../services/practice.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../services/table.service";
import { Observable } from "rxjs";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocationModalComponent } from "../../modals/location-modal/location-modal.component";
import { ManageProvidersModalComponent } from "../../modals/manage-providers-modal/manage-providers-modal.component";

@Component({
    selector: "app-location",
    templateUrl: "./location.component.html",
    styleUrls: ["./location.component.scss"],
})
export class LocationComponent implements OnInit {
    @ViewChild("input") input: ElementRef;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    page = 1;
    pageSize = 10;
    public pageIndex = 1;
    List = [];
    show = false;
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    displayedColumns = [
        { displayname: "location name", columnname: "practicename" },
        { displayname: "address 1", columnname: "address1" },
        { displayname: "city", columnname: "city" },
        { displayname: "state", columnname: "state" },
        // { displayname: "country", columnname: "country" },
        { displayname: "ZIP Code", columnname: "postalcode" },
        { displayname: "phone", columnname: "phonenumber" },
        { displayname: "default", columnname: "" },
        { displayname: "actions", columnname: "" },
    ];

    constructor(
        private snackbar: SnackbarService,
        private practiceService: PracticeService,
        private sessionService: SessionService,
        private modalService: NgbModal,
        public tableService: TableService
    ) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }

    ngOnInit(): void {
        this.getList();
    }

    getList() {
        // console.log(this._activeCareTeam);
        return this.practiceService.get().subscribe(
            (data) => {
                this.List = data[0].attributes.list;
                this.tableService.DATA = data[0].attributes.list;
                this.sorting("practicename", "asc");
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public onPagination(page: any) {
        this.tableService.page = page;
    }
    onSort({ column, direction }: any) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction);
    }
    sorting(column, direction) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.page = 1;
        this.tableService.searchOn = "practicename";
    }

    onDelete(practice) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.practiceService.delete(practice.ptid).subscribe(
                    (data) => {
                        this.snackbar.show("Deleted successfully");
                        this.getList();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
        });
    }

    savePractice(action, l) {
        const modalRef = this.modalService.open(LocationModalComponent, { size: "xl", backdrop: "static" });
        modalRef.componentInstance.action = action;
        modalRef.componentInstance.locationData = l;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show(receivedEntry.message);
                this.getList();
            }
        });
    }

    setPageSize() {
        this.tableService.pageSize = this.pageSize;
    }
    reload() {
        this.tableService.searchTerm = "";
    }
    manageProviders(location) {
        const modalRef = this.modalService.open(ManageProvidersModalComponent, { size: "xl", backdrop: "static" });
        modalRef.componentInstance.locationData = location;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show(receivedEntry.message);
                this.getList();
            }
        });
    }
}
