<div class="container-fluid">
    <div class="row mt-3 col-12">
        <br />
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink>TeleRemedy Settings</a>
                <ng-template ngbNavContent>
                    <form #container [formGroup]="settingForm" class="formcontainer" (ngSubmit)="saveChanges()">
                        <div class="container-fluid">
                            <div class="col-7 float-left">
                                <div style="margin-bottom: -8px" class="row mt-3">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Membership Name:</label>
                                            <input type="text"
                                                [ngClass]="{ error: settingForm.controls['name'].invalid === true }"
                                                formControlName="name" required class="form-control" maxlength="245" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Membership Description:</label>
                                            <textarea class="form-control" maxlength="245"
                                                [ngClass]="{ error: settingForm.controls['description'].invalid === true }"
                                                formControlName="description" required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Admin Email:</label>
                                            <input type="text" readonly class="form-control"
                                                formControlName="adminemail" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Onboarding Code Prefix:</label>
                                            <input type="text" class="form-control"
                                                formControlName="onboardingcodeprefix" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Profile Required:</label>
                                            <br /><span class="smaller">Determines if users of your teleremedy will be
                                                prompted with a goal to complete their profile.</span>
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="profilerequired" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Request Appointments:</label>
                                            <br /><span class="smaller"> Determines if users of your teleremedy may
                                                request appointments using the waiting room system.</span>
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="enablerequest" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>TeleRemedy Type:</label>
                                            <select class="form-control" required formControlName="telerxtype">
                                                <option value="application">Application</option>
                                                <option value="private">Private</option>
                                                <option value="service">Service</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'service'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>National Provider Identifier (NPI):</label>
                                            <input type="text" class="form-control" maxlength="10"
                                                [ngClass]="{ error: settingForm.controls['npi'].invalid === true }"
                                                formControlName="npi" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'service'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Specialty Type(s):</label>
                                            <div class="row">
                                                <table>
                                                    <tr *ngFor="let s of selectSpecialties" style="height: 30px">
                                                        <td style="border-bottom: 0">{{ s.name }}</td>
                                                        <td style="border-bottom: 0"><i
                                                                class="fa fa-trash-alt mr-3 cursor-pointer"
                                                                (click)="removeSpecialty(s.spid)"
                                                                title="Remove Member"></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="border-bottom: 0">
                                                            <select class="form-control" formControlName="specialty">
                                                                <option *ngFor="let s of specialties" [value]="s.spid">
                                                                    {{ s.name }}</option>
                                                            </select>
                                                        </td>
                                                        <td style="border-bottom: 0"><i
                                                                class="fa fa-plus-circle mr-3 cursor-pointer"
                                                                style="font-size: 20px" (click)="addSpecialty()"></i>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'application'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Dashboard Extension URL:</label>
                                            <input type="text"
                                                [ngClass]="{ error: settingForm.controls['providerdashboardurl'].invalid === true }"
                                                formControlName="providerdashboardurl" class="form-control"
                                                maxlength="255" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'application'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Callback URL:</label>
                                            <input type="text"
                                                [ngClass]="{ error: settingForm.controls['callbackeventurl'].invalid === true }"
                                                formControlName="callbackeventurl" class="form-control"
                                                maxlength="255" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'application'">
                                  <div class="col col-9">
                                      <div class="form-group">
                                          <label>Admin URL:</label>
                                          <input
                                              type="text"
                                              [ngClass]="{ error: settingForm.controls['admindashboardurl'].invalid === true }"
                                              formControlName="admindashboardurl"
                                              class="form-control"
                                              maxlength="255"
                                          />
                                      </div>
                                  </div>
                              </div> -->
                                <div class="row">
                                    <div class="col col-9">
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 float-left mt-3">
                                <img *ngIf="membership" class="logoImg" [src]="membership.logourl"
                                    (error)="setDefaultPic()" />
                                <br />
                                <a (click)="$event.stopPropagation(); onEditImage()"
                                    class="float-left editimage">Edit</a>
                                <br />
                                <div *ngIf="isImageEdit" style="width: 200px">
                                    <input type="file" class="btn btn-primary float-left"
                                        style="width: 250px; margin-bottom: 20px; text-align: left" name="Choose File"
                                        (change)="chooseImageFile($event)" accept=".jpg,.jpeg,.png" />
                                    <button *ngIf="isValidImg" [disabled]="btnImgDisabled" (click)="uploadImageFile()"
                                        class="btn btn-primary float-left">Upload</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Members</a>
                <ng-template ngbNavContent>
                    <app-loader style="position: absolute; top: 200px; right: 45%; z-index: 100" class="loader"
                        *ngIf="!members"></app-loader>

                    <div *ngIf="members" class="filter-section">
                        <div class="filter-section-left">
                            <i (click)="reload()" style="cursor: pointer; font-size: 20px"
                                class="fa fa-redo-alt left-chevron"></i><input class="filter-input" name="searchTerm"
                                [(ngModel)]="tableService.searchTerm" placeholder="Search..." />
                        </div>

                        <div class="filter-section-right">
                            <select class="selectpicker form-control float-right role-ctrl" [(ngModel)]="roleFilter"
                                [ngModelOptions]="{ standalone: true }" (input)="applyFilterByRole($event.target.value)"
                                style="width: 220px">
                                <option value="-1">All Roles</option>
                                <option *ngFor="let role of roles" [value]="role.name">{{ role.displayname }}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="members" class="container-fluid row col-12 p-0 m-0">
                        <div class="container-fluid pr-0">
                            <table *ngIf="members" class="table table-striped datatable">
                                <thead>
                                    <tr>
                                        <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                            *ngFor="let c of displayedColumns" sortable="{{ c.columnname }}"
                                            (sort)="onSort($event)">
                                            {{ c.displayname }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let m of resultList$ | async">
                                        <td>
                                            <span style="max-width: 250px; word-break: break-all">{{ m.email }}</span>
                                        </td>
                                        <td>
                                            &nbsp;<span style="max-width: 250px">{{ m.fullname }}</span>
                                        </td>
                                        <td>
                                            <span style="max-width: 250px">{{ m.careteamname }}</span>
                                        </td>
                                        <td>
                                            <span style="max-width: 250px">{{ m.rolename ? roleName(m.rolename) : ""
                                                }}</span>
                                        </td>
                                        <td>
                                            <i title="Edit"
                                                (click)="$event.preventDefault(); goToUserManagement(m.email)"
                                                class="fa fa-pen graycolor mr-3 cursor-pointer ml-2" aria-hidden="true">
                                            </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between p-2">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [pageSize]="pageSize"
                                    [(page)]="pageIndex" [maxSize]="5" [rotate]="true"
                                    (pageChange)="onPagination($event)"></ngb-pagination>
                                <select class="custom-select pagination"
                                    style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                                    [(ngModel)]="pageSize" (ngModelChange)="setPageSize()">
                                    <option [ngValue]="10">10 items per page</option>
                                    <option [ngValue]="50">50 items per page</option>
                                    <option [ngValue]="100">100 items per page</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <button (click)="back()" type="button" class="btn btn-secondary">Back</button>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Subscribed Teleremedies</a>
                <ng-template ngbNavContent>
                    <form #container>
                        <div class="teleremedy-header">
                            <div class="header-left">
                                <h6>SUBSCRIBED BY</h6>
                            </div>
                            <div class="header-right">
                                <button *ngIf="teleRxType !== 'private'" type="submit" (click)="onSubscribing('to')"
                                    class="btn btn-primary">ADD SUBSCRIPTION</button>
                            </div>
                        </div>
                        <div class="container-fluid row col-12 m-0 p-0">
                            <table *ngIf="members" class="table table-striped datatable">
                                <thead>
                                    <tr>
                                        <th style="width: 25%" [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                            *ngFor="let c of subscribedToColumns" sortable="{{ c.columnname }}">
                                            {{ c.displayname }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let s of subscribedTo">
                                        <td style="width: 25%">
                                            <span>{{ s?.name }}</span>
                                        </td>
                                        <td style="width: 25%">
                                            <span>{{ s?.description }}</span>
                                        </td>
                                        <td style="width: 25%">
                                            <span>{{ teleRxName }} {{ s?.subscribed_ststus }} {{ s?.name }} </span>
                                        </td>
                                        <td style="display: flex">
                                            <button (click)="unsub(s, 'to')" class="btn btn-primary">Unsubscribe</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div *ngIf="!subscribedTo || subscribedTo.length === 0" class="no-record">
                                <p>No records found</p>
                            </div>
                        </div>
                        <div class="teleremedy-header">
                            <div class="header-left">
                                <h6>SUBSCRIBED TO</h6>
                            </div>
                            <div class="header-right">
                                <button type="submit" (click)="onSubscribing('by')" class="btn btn-primary">ADD
                                    SUBSCRIPTION</button>
                            </div>
                            
                        </div>
                        <div class="container-fluid row col-12 m-0 p-0">
                            <div class="container-fluid row col-12 m-0 p-0">
                                <table *ngIf="members" class="table table-striped datatable">
                                    <thead>
                                        <tr>
                                            <th style="width: 25%"
                                                [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                                *ngFor="let c of subscribedByColumns" sortable="{{ c.columnname }}">
                                                {{ c.displayname }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let s of subscribedBy">
                                            <td style="width: 25%">
                                                <span>{{ s?.name }}</span>
                                            </td>
                                            <td style="width: 25%">
                                                <span>{{ s?.description }}</span>
                                            </td>
                                            <td style="width: 25%">
                                                <span>{{ teleRxName }} {{ s?.subscribed_ststus }} {{ s?.name }}</span>
                                            </td>
                                            <td style="display: flex">
                                                <button (click)="unsub(s, 'by')"
                                                    class="btn btn-primary">Unsubscribe</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="!subscribedBy || subscribedBy.length === 0" class="no-record">
                                    <p>No records found</p>
                                </div>
                            </div>
                        </div>
                        <button (click)="back()" type="button" class="btn btn-secondary">Back</button>
                    </form>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>