<div class="container-fluid">
    <div class="row mb-2 col-12">
        <span class="d-flex">
            <b>Filter: </b> Scores Below:&nbsp;<b>{{showAllResult?'10':
                escalate_keywords.scores}}</b>&nbsp;Keywords:&nbsp;
            <b>{{escalate_keywords.keyword==""?"all":escalate_keywords.keyword}}</b>
            &nbsp; <input [ngModel]="allResult" (change)="showAll($event)" type="checkbox">&nbsp;<b>Ignore
                Filters</b>
            <br>
        </span>
    </div>
    <div *ngIf="sentiments!=''" class="row mb-2 col-12">
        <span class="d-flex">
            Sentiments:&nbsp;<b>{{sentiments}}</b>
        </span>
    </div>
    <div class="row mb-2">

        <div class="input-group col-8">

            <div style="display: flex;padding-left: 0px;" class="col-8 pr-0">
                <a [routerLink]="['escalate-keywords']">
                    <button class="btn-line"><img class="mr-2" src="../../../assets/msx/criteria.svg"> Service Recovery
                        Criteria</button>
                </a>
                <input (keyup)="trimValue()" [(ngModel)]="searchTerm" style="margin-left: -14px;height: 35px"
                    type="text" class="form-control search" placeholder="Search" />
            </div>
            <div class="col-4 pl-0 pr-0">
                <button style="padding:8px;" (click)="search()" class="btn btn-primary ml-1">Search</button>
                <button style="padding:8px;" (click)="clear()" class="btn btn-secondary ml-1">Clear</button>
            </div>
        </div>

        <div class="input-group col-4 float-right" style="align-items: center;
        justify-content: center;">
            <label class="mr-3 mt-1">Filter by:</label>
            <select [ngModel]="status" (change)="filterByStstus($event.target.value)" class="form-control">
                <option value="all">All</option>
                <option selected value="0">New</option>
                <option value="1">In-Progress</option>
                <option value="2">Closed</option>
                <!-- <option value="3">Open</option> -->
            </select>
        </div>
    </div>
    <table *ngIf="resultsLength>0" class="table table-striped datatable">
        <thead>
            <tr>
                <th [ngClass]="c === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                    width="{{ (c === 'providerfullname') ? '150px' : '' }}"
                    sortable="{{ (c === 'actions' || c=== 'status'  || c=== 'providercomment'  || c=== 'practicecomment') ? '' : c }}"
                    (sort)="onSort($event)">
                    {{
                    displayName(c) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of resultList$ | async">
                <td class="cursor-pointer" *ngFor="let c of displayedColumns; let i = index">

                    <div (click)="openServiceModal(p)">
                        <span style="width: 200px;" *ngIf="c === 'commentdate'">
                            {{(p[c]) | date: "MM-dd-yyyy , hh:mm a" }}
                        </span>
                        <span *ngIf="c === 'status'">
                            <span *ngIf="p[c]==0" class="badge bg-success">New</span>
                            <span *ngIf="p[c]==1" class="badge bg-primary">In-Progress</span>
                            <span *ngIf="p[c]==2" style="color:white" class="badge bg-secondary">Closed</span>
                            <span *ngIf="p[c]==3" class="badge bg-warning">Open</span>
                        </span>
                        <span *ngIf="c === 'lowestscore'">
                            {{ p[c] }}
                        </span>
                        <span *ngIf="c === 'providerfullname'">
                            {{ p[c] }}
                        </span>
                        <span *ngIf="c === 'practicecomment'">
                            {{ getSortString(p[c]) }}
                        </span>
                        <span *ngIf="c === 'providercomment'">
                            {{ getSortString(p[c]) }}
                        </span>
                        <span *ngIf="c === 'locationname'">
                            {{ p[c] }}
                        </span>
                    </div>
                    <div style="width:130px;" *ngIf="c === 'actions'">
                        <!-- {{p.clio|json}} -->
                        <img (click)="openServiceModal(p,'sm')" class="mr-2 cursor-pointer"
                            src="../../../assets/msx/statusmenu.svg">
                        <img *ngIf="p.clio" (click)="onViewPatient(p)" class="cursor-pointer"
                            src="../../../assets/msx/chat.svg">
                        <!-- <button>Move To Triage</button> -->
                    </div>
                </td>
                <td>

                </td>

            </tr>
        </tbody>
    </table>

    <div *ngIf="resultsLength>0" class="pagination justify-content-between align-items-center">
        <ngb-pagination [collectionSize]="resultsLength" [pageSize]="pageSize" [(page)]="pageIndex" [maxSize]="5"
            [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>
        <div>
            <label class="mr-3 mt-1">Items per page:</label>
            <select class="custom-select form-control" [(ngModel)]="pageSize" (ngModelChange)="setPageSize()">
                <option [ngValue]="10">10</option>
                <option [ngValue]="50">50</option>
                <option [ngValue]="100">100</option>
            </select>
        </div>

    </div>

    <div *ngIf="resultsLength==0">
        No records found
    </div>

    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>

</div>