import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BnNgIdleService } from "bn-ng-idle";
import { Router } from "@angular/router";
import { AnalyticsService } from "../app/services/analytics.service";
import { environment } from "../environments/environment";
import { SessionService } from "../app/services/session.service";
import { AuthService } from "./services/auth.service";

@Component({
    moduleId: module.id,
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "app",
    templateUrl: "app.component.html",
})
export class AppComponent {
    constructor(
        public activeModal: NgbActiveModal,
        private authService: AuthService,
        private bnIdle: BnNgIdleService,
        private sessionService: SessionService,
        public router: Router,
        private analyticsService: AnalyticsService
    ) {
        this.bnIdle.startWatching(environment.idle_time).subscribe((res) => {
            if (res && !location.href.toLowerCase().includes("/login")) {
                this.logoutUser();
            }
        });

        if (location.href.toLowerCase().includes("/provider.")) {
            environment.dashboard_name = "Provider Dashboard";
            document.title = "Clio " + environment.dashboard_name;
        }
    }

    public logoutUser() {
        let currentUser = this.sessionService.currentUser;
        let data = [];
        try {
            this.analyticsService.recordEvent("providerdashboard_event", "session_timeout", data, currentUser.id).subscribe(
                (data) => {
                    this.authService.logout().then(() => {
                        // this is hacky but we don't have an easy way to clear active modals.
                        window.location.reload();
                    });
                },
                (err) => {
                    console.log(err);
                    this.authService.logout().then(() => {
                        // this is hacky but we don't have an easy way to clear active modals.
                        window.location.reload();
                    });
                }
            );
        } catch (e) {
            this.authService.logout().then(() => {
                // this is hacky but we don't have an easy way to clear active modals.
                window.location.reload();
            });
        }
    }

}
