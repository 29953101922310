<!-- For use in new chat window component -->
<div *ngIf="relative" class="dropdown contextmenu relative-patent" tabindex="0" [@fadeInOut]>
    <br />
    <a *ngFor="let t of data; let i = index" class="dropdown-item">
        <label style="width: 100%">
            <input name="{{ i }}" [(ngModel)]="t.selected" type="checkbox" />
            {{ t.tag }}
        </label>
    </a>
    <div class="saveTags">
        <button (click)="saveTags()" class="btn btn-primary">Save</button>
    </div>
</div>