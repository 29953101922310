import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";

@Component({
    selector: "symptom-other-modal",
    templateUrl: "./symptom-other-modal.component.html",
    styleUrls: ["./symptom-other-modal.component.scss"],
})
export class SymptomOtherModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private patientService: PatientService, private snackbar: SnackbarService, private modalService: NgbModal) {}

    @Input() SymptomsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    checklist: Array<any> = [];
    public error = "";
    public symptoms: any = {};
    additionalForm: UntypedFormGroup;

    ngOnInit() {
        this.symptoms = this.SymptomsData.symptoms;
        this.additionalForm = this.fb.group(
            {
                since: [this.symptoms.since, [Validators.required]],
                sinceUnit: [this.symptoms.sinceUnit ? this.symptoms.sinceUnit : "Days", [Validators.required]],
                other: [this.symptoms.other],
            },
            { updateOn: "blur" }
        );
    }
    isFieldValid(key) {
        return this.additionalForm.get(key).invalid && this.additionalForm.get(key).touched;
    }

    onSubmit() {
        if (this.additionalForm.valid) {
            this.symptoms["since"] = this.additionalForm.get("since").value;
            this.symptoms["sinceUnit"] = this.additionalForm.get("sinceUnit").value;
            this.symptoms["other"] = this.additionalForm.get("other").value;
            this.Response.emit({ success: true, message: "", action: this.SymptomsData.action, wruid: this.SymptomsData.wruid, symptoms: this.symptoms, gotocomponent: "SymptomConfirm" });
            this.activeModal.close();
        } else {
            this.additionalForm.markAllAsTouched();
            this.error = "Please enter the number of days you've had these symptoms.";
        }
    }

    public onCancel() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.activeModal.close();
            }
        });
    }
}
