import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { WaitingRoomService } from "../../../../services/waiting-room.service";
import { ChatModalComponent } from "../../../../modals/chat-modal/chat-modal.component";
@Component({
    selector: "service-recovery-modal",
    templateUrl: "./service-recovery-modal.component.html",
    styleUrls: ["./service-recovery-modal.component.scss"],
})
export class ServiceRecoveryModal implements OnInit {
    constructor(
        private activeModal: NgbActiveModal,
        private msxReportService: MSXReportService,
        private snackbar: SnackbarService,
        private formBuilder: UntypedFormBuilder,
        public waitingRoomService: WaitingRoomService,
        private modalService: NgbModal
    ) { }
    @Input() servicerecoveryData: any;
    @Input() size = "";
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    serviceRecoveryForm: UntypedFormGroup;
    submitted = false;
    statusCodes = [
        {
            value: 0,
            text: 'New'
        },
        {
            value: 1,
            text: 'In-Progress'
        },
        {
            value: 2,
            text: 'Closed'
        }
    ]
    ngOnInit() {
        this.serviceRecoveryForm = this.formBuilder.group({
            // watingrooms: ["", Validators.required],
            status: [this.servicerecoveryData.status || "0", Validators.required],
            reason: [this.servicerecoveryData.note || ""]
        })
        let service = {
            status: this.servicerecoveryData.status,
            isopen: true,
        }
        return this.waitingRoomService.updateServiceRecovery(service, this.servicerecoveryData).subscribe(
            (data) => {
            },
            (err) => {
            }
        );
    }
    get form() {
        return this.serviceRecoveryForm.controls;
    }


    public onCancel() {
        this.activeModal.close();
    }

    public addToServiceRecovery() {
        this.submitted = true;
        if (this.serviceRecoveryForm.valid) {
            return this.waitingRoomService.updateServiceRecovery(this.serviceRecoveryForm.value, this.servicerecoveryData).subscribe(
                (data) => {
                    //this.servicerecoveryData.note = this.serviceRecoveryForm.value.reason;
                    //this.servicerecoveryData.status = this.serviceRecoveryForm.value.status;
                    // this.servicerecoveryData.eventHistory[0].note = this.serviceRecoveryForm.value.status;
                    this.Response.emit({ proceed: this.servicerecoveryData });
                    this.activeModal.close();
                },
                (err) => {
                    //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        }
    }
    public convertoUStime(date) {
        let _date = date.replace("T00:00:00.000Z", "")
        // var myDate = new Date(date);
        // var timezone_delay = -myDate.getTimezoneOffset() * 60 * 1000;
        // return new Date(myDate.getTime() + timezone_delay);
        let myDate = _date.split("-")
        return myDate[1] + "/" + myDate[2].substring(0, 2) + "/" + myDate[0]
    }

    public onViewPatient(p: any) {
        //p.patientid = p.practicepatientid;
        p.patientid = p.clio && p.clio.userid ? p.clio.userid : null;
        p.careteamid = p.clio && p.clio.careteamid ? p.clio.careteamid : null;
        const modalRef = this.modalService.open(ChatModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.currentPatient = p;
        modalRef.componentInstance.patients = [];
        return false;
        //modalRef.componentInstance.Response.subscribe((receivedEntry) => { });
    }

    public getValue(val) {
        if (val != '' && val != null)
            return true;
        else
            return false
    }
}
