<div class="modal-header">
    <h5 class="modal-title">{{selectedIndex==-1?"Add":"Update"}} Schedule</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="modal-body-form" [formGroup]="scheduleForm" (ngSubmit)="addToSchedule()">
        <div class="row">
            <div class="form-group col-12">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && form.name.errors }">
                <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
                    <div *ngIf="form.name.errors">Name is required.</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-3">
                <label>Start Date</label>
                <input [min]="minDate" [attr.disabled]="form.fullDay.value? '' : null" #startDate type="date"
                    class="form-control" formControlName="startDate"
                    [ngClass]="{ 'is-invalid': submitted && form.startDate.errors }">
                <div *ngIf="submitted && form.startDate.errors" class="invalid-feedback">
                    <div *ngIf="form.startDate.errors">Start Date is required.</div>
                </div>
            </div>
            <div class="form-group col-3">
                <label>End Date</label>
                <input [min]="minDate" [attr.disabled]="form.fullDay.value? '' : null" type="date" class="form-control"
                    formControlName="endDate" [ngClass]="{ 'is-invalid': submitted && form.endDate.errors }">
                <div *ngIf="submitted && form.endDate.errors" class="invalid-feedback">
                    <div *ngIf="form.endDate.errors">End Date is required.</div>
                </div>
            </div>
            <div class="form-group col-2">
                <label>Start Time</label>
                <select [attr.disabled]="form.fullDay.value? '' : null" class="custom-select form-control"
                    formControlName="startTime" [ngClass]="{ 'is-invalid': submitted && form.startTime.errors }">
                    <option value="">Select</option>
                    <option value="00:00">12.00 AM</option>
                    <option value="00:30">12.30 AM</option>
                    <option value="01:00">01.00 AM</option>
                    <option value="01:30">01.30 AM</option>
                    <option value="02:00">02.00 AM</option>
                    <option value="02:30">02.30 AM</option>
                    <option value="03:00">03.00 AM</option>
                    <option value="03:30">03.30 AM</option>
                    <option value="04:00">04.00 AM</option>
                    <option value="04:30">04.30 AM</option>
                    <option value="05:00">05.00 AM</option>
                    <option value="05:30">05.30 AM</option>
                    <option value="06:00">06.00 AM</option>
                    <option value="06:30">06.30 AM</option>
                    <option value="07:00">07.00 AM</option>
                    <option value="07:30">07.30 AM</option>
                    <option value="08:00">08.00 AM</option>
                    <option value="08:30">08.30 AM</option>
                    <option value="09:00">09.00 AM</option>
                    <option value="09:30">09.30 AM</option>
                    <option value="10:00">10.00 AM</option>
                    <option value="10:30">10.30 AM</option>
                    <option value="11:00">11.00 AM</option>
                    <option value="11:30">11.30 AM</option>
                    <option value="12:00">12.00 PM</option>
                    <option value="12:30">12.30 PM</option>
                    <option value="13:00">01.00 PM</option>
                    <option value="13:30">01.30 PM</option>
                    <option value="14:00">02.00 PM</option>
                    <option value="14:30">02.30 PM</option>
                    <option value="15:00">03.00 PM</option>
                    <option value="15:30">03.30 PM</option>
                    <option value="16:00">04.00 PM</option>
                    <option value="16:30">04.30 PM</option>
                    <option value="17:00">05.00 PM</option>
                    <option value="17:30">05.30 PM</option>
                    <option value="18:00">06.00 PM</option>
                    <option value="18:30">06.30 PM</option>
                    <option value="19:00">07.00 PM</option>
                    <option value="19:30">07.30 PM</option>
                    <option value="20:00">08.00 PM</option>
                    <option value="20:30">08.30 PM</option>
                    <option value="21:00">09.00 PM</option>
                    <option value="21:30">09.30 PM</option>
                    <option value="22:00">10.00 PM</option>
                    <option value="22:30">10.30 PM</option>
                    <option value="23:00">11.00 PM</option>
                    <option value="23:30">11.30 PM</option>
                </select>
                <div *ngIf="submitted && form.startTime.errors" class="invalid-feedback">
                    <div *ngIf="form.startTime.errors">Start Time is required.</div>
                </div>
            </div>
            <div class="form-group col-2">
                <label>End Time</label>
                <select [attr.disabled]="form.fullDay.value? '' : null" class="custom-select form-control"
                    formControlName="endTime" [ngClass]="{ 'is-invalid': submitted && form.endTime.errors }">
                    <option value="">Select</option>
                    <option value="00:00">12.00 AM</option>
                    <option value="00:30">12.30 AM</option>
                    <option value="01:00">01.00 AM</option>
                    <option value="01:30">01.30 AM</option>
                    <option value="02:00">02.00 AM</option>
                    <option value="02:30">02.30 AM</option>
                    <option value="03:00">03.00 AM</option>
                    <option value="03:30">03.30 AM</option>
                    <option value="04:00">04.00 AM</option>
                    <option value="04:30">04.30 AM</option>
                    <option value="05:00">05.00 AM</option>
                    <option value="05:30">05.30 AM</option>
                    <option value="06:00">06.00 AM</option>
                    <option value="06:30">06.30 AM</option>
                    <option value="07:00">07.00 AM</option>
                    <option value="07:30">07.30 AM</option>
                    <option value="08:00">08.00 AM</option>
                    <option value="08:30">08.30 AM</option>
                    <option value="09:00">09.00 AM</option>
                    <option value="09:30">09.30 AM</option>
                    <option value="10:00">10.00 AM</option>
                    <option value="10:30">10.30 AM</option>
                    <option value="11:00">11.00 AM</option>
                    <option value="11:30">11.30 AM</option>
                    <option value="12:00">12.00 PM</option>
                    <option value="12:30">12.30 PM</option>
                    <option value="13:00">01.00 PM</option>
                    <option value="13:30">01.30 PM</option>
                    <option value="14:00">02.00 PM</option>
                    <option value="14:30">02.30 PM</option>
                    <option value="15:00">03.00 PM</option>
                    <option value="15:30">03.30 PM</option>
                    <option value="16:00">04.00 PM</option>
                    <option value="16:30">04.30 PM</option>
                    <option value="17:00">05.00 PM</option>
                    <option value="17:30">05.30 PM</option>
                    <option value="18:00">06.00 PM</option>
                    <option value="18:30">06.30 PM</option>
                    <option value="19:00">07.00 PM</option>
                    <option value="19:30">07.30 PM</option>
                    <option value="20:00">08.00 PM</option>
                    <option value="20:30">08.30 PM</option>
                    <option value="21:00">09.00 PM</option>
                    <option value="21:30">09.30 PM</option>
                    <option value="22:00">10.00 PM</option>
                    <option value="22:30">10.30 PM</option>
                    <option value="23:00">11.00 PM</option>
                    <option value="23:30">11.30 PM</option>
                </select>
                <div *ngIf="submitted && form.endTime.errors" class="invalid-feedback">
                    <div *ngIf="form.endTime.errors">End Time is required.</div>
                </div>
            </div>
            <div style="padding-top: 36px; padding-left: 20px;" class="form-group col-2">
                <input formControlName="fullDay" (click)="allDay(startDate)"
                    [attr.disabled]="!form.startDate.value? '' : null" class="form-check-input" type="checkbox" />
                <label class="form-check-label">
                    All Day
                </label>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <label>Chatbot Message</label>
                <textarea class="form-control" rows="4" formControlName="message"
                    [ngClass]="{ 'is-invalid': submitted && form.message.errors }"></textarea>
                <div *ngIf="submitted && form.message.errors" class="invalid-feedback">
                    <div *ngIf="form.message.errors">Message is required.</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button (click)="onCancel()" type="button" class="btn btn-secondary form-control">Cancel</button>
            </div>
            <div class="col-6">
                <button type="submit" class="btn btn-primary form-control">Save</button>
            </div>
        </div>
    </form>
</div>