import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SnackbarService } from '../../../../../shared/snackbar/snackbar.service';
import { MediaService } from '../../../../../services/media.service';
import { TableService } from "../../../../../services/table.service";
import { SortableHeaderDirective } from "../../../../../directives/sortable.directive";
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../../../../../app/modals/warning-modal/warning-modal.component';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, OnDestroy {
  resultList$: Observable<any[]>;
  total$: Observable<number>;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  constructor(private snackbar: SnackbarService,
    private mediaService: MediaService,
    public tableService: TableService,
    private modalService: NgbModal,
    public router: Router
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 50;
    this.tableService.searchTerm = "";
  }
  displayedColumns = [
    { displayname: "Last Review", columnname: "last_review_date" },
    { displayname: "Practice", columnname: "practice_name" },
    { displayname: "Location", columnname: "location_name" },
    { displayname: "Total Reviews", columnname: "total_reviews" },
    { displayname: "Negative Reviews", columnname: "negative_reviews" },
    { displayname: "Score Average", columnname: "avg_score" },
    { displayname: "Action", columnname: "" },
  ];
  reviews = [];
  showLoader = true;
  ngOnInit(): void {
    this.getData();
  }

  public getData() {
    this.mediaService.GetSocialMediaAverages().subscribe(
      (data) => {
        this.showLoader = false;
        this.reviews = data[0].attributes.data.list;
        this.tableService.DATA = this.reviews;
        this.sorting("date", "desc");
      },
      (err) => {
        this.showLoader = false;
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }
  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
    this.tableService.searchOn = "date";
  }

  public onDelete(smid) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.Header = false;
    modalRef.componentInstance.OKButton = "Delete";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.mediaService.delete(smid).subscribe(
          (data) => {
            this.getData();
          },
          (err) => {
            this.snackbar.show("error occurred please try again later.", "danger");
          }
        );
      }
    });
  }

  public onEdit(activity) {
    //this.router.navigate(["/patient/details/"], { queryParams: { id: user.userid } });
    this.router.navigate(["/media/edit-link/"], { queryParams: { locationid: activity.locationid, practiceid: activity.practiceid } });

  }
  public addNew() {
    this.router.navigate(["/media/add-link/"]);

  }

  public getClass(score) {
    if (score < 5) {
      return "bg-danger";
    }
    else if (score > 5 && score < 7) {
      return "bg-warning";
    }
    else {
      return "bg-success";
    }
  }

  ngOnDestroy() {
    this.tableService.DATA = [];
  }
}
