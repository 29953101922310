import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { GoalsService } from "../../services/goals.service";
import { AppsService } from "../../services/apps.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-goal-modal",
    templateUrl: "./goal-modal.component.html",
    styleUrls: ["./goal-modal.component.scss"],
})
export class GoalModalComponent implements OnInit {
    @Input() PatientId: String;
    @Input() Goal: any;
    @Input() ExistingGoal = [];
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    isEditing: boolean = false;
    goalCategories: any = [];
    goalTypes: any = [];
    userApps: any = [];
    goalMonths: any = [];
    goalDays: any = [];
    goalHours: any = [];
    goalMins: any = [];
    frequencies: any = [
        { label: "Monday", id: "MON", selected: true },
        { label: "Tuesday", id: "TUE", selected: true },
        { label: "Wednesday", id: "WED", selected: true },
        { label: "Thursday", id: "THU", selected: true },
        { label: "Friday", id: "FRI", selected: true },
        { label: "Saturday", id: "SAT", selected: true },
        { label: "Sunday", id: "SUN", selected: true },
    ];
    frequencytypes: any = [
        { label: "Weekly", id: "Weekly", selected: false },
        { label: "Monthly", id: "Monthly", selected: false },
        { label: "Yearly", id: "Yearly", selected: false },
        { label: "Once", id: "Once", selected: false },
    ];
    goaltimes: any = [{ hours: "", mins: "", time: "", timevalue: "", timename: "" }];
    scheduletimes: any = [{ day: "", month: "" }];
    hideDataType: Boolean = true;
    hideDataType2: Boolean = true;
    hideTarget: Boolean = true;
    hideTarget2: Boolean = true;
    goalForm: UntypedFormGroup;
    dt: Date = new Date();
    fromDate = { year: this.dt.getFullYear(), month: this.dt.getMonth() + 1, day: this.dt.getDate() };
    toDate = { year: this.dt.getFullYear(), month: this.dt.getMonth() + 1, day: this.dt.getDate() };
    minDate = { year: this.dt.getFullYear(), month: this.dt.getMonth() + 1, day: this.dt.getDate() };
    startdate = { year: this.dt.getFullYear(), month: this.dt.getMonth() + 1, day: this.dt.getDate() };
    minEndDate = { year: this.dt.getFullYear(), month: this.dt.getMonth() + 1, day: this.dt.getDate() };
    goaltypeid = "";
    goaltypetext = "";
    frequencytype = "";
    errStartDate = "";
    notifyprovider = false;
    allowearlyentry = false;
    isSubmitted = false;
    error: string;
    errorTime: string;
    ModalTitle = "Add Goal";
    invalidFrequencies = false;
    Target1 = "Target 1";
    Target2 = "Target 2";
    Caption1 = "Caption";
    Caption2 = "Caption 2";
    isTargeted = false;
    isOnce = false;

    isCompletion = false;
    constructor(private activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private goalsService: GoalsService, private appsService: AppsService, private sessionService: SessionService) { }

    ngOnInit() {
        this.isEditing = this.Goal ? true : false;
        this.loadGoalCategories();
        this.loadGoalTypes();
        this.getUserApps();
        this.loadMonths();
        this.loadDays();
        this.loadHours();
        this.loadMins();

        this.goalForm = this.fb.group({
            goalname: ["", Validators.required],
            patientId: [this.PatientId, Validators.required],
            startDate: [{ value: "", disabled: this.isEditing }, Validators.required],
            endDate: ["", null],
            goalTarget: [""],
            gdcid: ["", Validators.required],
            frequencytype: ["", Validators.required],
            frequencies: ["", Validators.required],
            datatype: [""],
            datatype2: [""],
            goaltypeid: ["", Validators.required],
            associatedapp: [{ value: "", disabled: this.isEditing }],
            goalid: [""],
            notes: [""],
            notifyprovider: [""],
            allowearlyentry: [""],
            target1: [""],
            target2: [""],
            scheduledate: [""],
        });

        // Edit an existing goal
        if (this.Goal) {
            this.ModalTitle = "Update Goal";
            let datatype = this.Goal.datatype.split(",");
            let fromDate = this.Goal.startdate.split("T")[0];
            let toDate = this.Goal.enddate.split("T")[0];
            let _fromDate = { year: parseInt(fromDate.split("-")[0]), month: parseInt(fromDate.split("-")[1]), day: parseInt(fromDate.split("-")[2]) };
            this.startdate = _fromDate;
            var dateStartDate = new Date(this.startdate.year, this.startdate.month - 1, this.startdate.day);
            dateStartDate.setDate(dateStartDate.getDate() + 1);
            this.minEndDate = { year: dateStartDate.getFullYear(), month: dateStartDate.getMonth() + 1, day: dateStartDate.getDate() };
            let _toDate = { year: parseInt(toDate.split("-")[0]), month: parseInt(toDate.split("-")[1]), day: parseInt(toDate.split("-")[2]) };
            this.goalForm.patchValue({
                goalname: this.Goal.goalname,
                patientId: this.Goal.userid,
                startDate: _fromDate,
                endDate: _toDate,
                goalTarget: "",
                gdcid: this.Goal.gdcid,
                frequencytype: this.Goal.frequencytype,
                frequencies: this.Goal.frequencies,
                goaltimeid: this.Goal.goaltimeid,
                datatype: datatype[0] === undefined ? "" : datatype[0],
                datatype2: datatype[1] === undefined ? "" : datatype[1],
                goaltypeid: this.Goal.goaltypeid,
                associatedapp: this.Goal.associatedapp.replace("app://", ""),
                goalid: this.Goal.goalid,
                notes: this.Goal.notes,
                notifyprovider: this.Goal.notifyprovider,
                allowearlyentry: this.Goal.allowearlyentry,
                target1: this.Goal.target1,
                target2: this.Goal.target2,
                scheduledate: this.Goal.scheduledate,
            });

            this.frequencytype = this.Goal.frequencytype;
            this.isOnce = this.frequencytype == "Once" ? true : false;
            this.notifyprovider = this.Goal.notifyprovider;
            this.allowearlyentry = this.Goal.allowearlyentry;
            this.frequencies = this.frequencies.map((day) => {
                day.selected = this.Goal.frequencies.includes(day.id);
                return day;
            });
            this.goaltypeid = this.Goal.goaltypeid;
            this.goaltypetext = this.Goal.goaltypename;
            this.minDate = { year: 1700, month: 1, day: 1 };
            this.changeGoalType(this.Goal.goaltypeid, this.Goal.goaltypename);

            //Goal Times
            let goalTimes = this.Goal.goalformattedtimes.split(",");
            let goalTimesOld = this.Goal.goaltimes.split(",");
            let goalTimeNames = this.Goal.goaltimename.split(",");
            let existingGoalTimes = [];
            goalTimes.forEach((element, index) => {
                let hours = element.split(":")[0];
                let mins = element.split(":")[1].split(" ")[0];
                let time = element.split(":")[1].split(" ")[1];
                let goalTimesOldtime = goalTimesOld[index];
                existingGoalTimes.push({
                    hours: hours.toString().length === 1 ? "0" + hours.toString() : hours,
                    mins: mins.toString().length === 1 ? "0" + mins.toString() : mins,
                    time: time,
                    timevalue: element,
                    timevalueold: goalTimesOldtime,
                    timename: goalTimeNames[index],
                });
            });
            this.goaltimes = existingGoalTimes;

            //Get Schedule Dates
            if (this.Goal.scheduledate) {
                if (this.Goal.frequencytype === "Monthly") {
                    let scheduleDates = this.Goal.scheduledate.split(",");
                    let existingScheduleDates = [];
                    scheduleDates.forEach((element, index) => {
                        if (!isNaN(element)) {
                            existingScheduleDates.push({ day: element, month: "" });
                        }
                    });
                    this.scheduletimes = existingScheduleDates;
                } else if (this.Goal.frequencytype === "Yearly") {
                    let scheduleDates = this.Goal.scheduledate.split(",");
                    let existingScheduleDates = [];
                    scheduleDates.forEach((element, index) => {
                        let day = element.split("-")[1];
                        let month = element.split("-")[0];
                        if (!isNaN(day) && !isNaN(month)) {
                            existingScheduleDates.push({ day: element.split("-")[1], month: element.split("-")[0] });
                        }
                    });
                    this.scheduletimes = existingScheduleDates;
                }
            }
        }
    }

    loadMonths() {
        var list = [];
        for (var i = 1; i <= 12; i++) {
            list.push((i < 10 ? "0" : "") + "" + i.toString());
        }
        this.goalMonths = list;
    }

    loadDays() {
        var list = [];
        for (var i = 1; i <= 28; i++) {
            list.push((i < 10 ? "0" : "") + "" + i.toString());
        }
        this.goalDays = list;
    }
    loadHours() {
        var list = [];
        for (var i = 1; i <= 12; i++) {
            list.push((i < 10 ? "0" : "") + "" + i.toString());
        }
        this.goalHours = list;
    }
    loadMins() {
        var list = [];
        list.push("00");
        list.push("30");
        this.goalMins = list;
    }

    isFieldValid(key) {
        return this.goalForm.get(key).invalid && this.goalForm.get(key).touched;
    }

    addNewTime() {
        this.goaltimes.push({ hours: "", mins: "", time: "", timevalue: "", timename: "" });
    }

    addNewSchedule() {
        this.scheduletimes.unshift({ day: "", month: "" });
    }

    deleteTime(index) {
        this.goaltimes.splice(index, 1);
    }

    deleteSchedule(index) {
        this.scheduletimes.splice(index, 1);
    }
    onChangeStartDate() {
        this.goalForm.patchValue({ startDate: this.startdate });
        var dateStartDate = new Date(this.startdate.year, this.startdate.month - 1, this.startdate.day);
        dateStartDate.setDate(dateStartDate.getDate() + 1);
        this.minEndDate = { year: dateStartDate.getFullYear(), month: dateStartDate.getMonth() + 1, day: dateStartDate.getDate() };
    }

    changeFrequency(id) {
        this.frequencytype = id;
        this.errorTime = "";
        this.goalForm.get("frequencytype").patchValue(this.frequencytype);
        if (this.frequencytype !== "Weekly") {
            this.goaltimes = [{ hours: "", mins: "", time: "", timevalue: "", timename: "" }];
        }
        if (this.frequencytype !== "Monthly" && this.frequencytype !== "Yearly") {
            this.scheduletimes = [{ day: "", month: "" }];
        }
        this.isOnce = this.frequencytype == "Once" ? true : false;
    }
    validateTime() {
        if (this.frequencytype === "Weekly") {
            //Check Duplicates
            let map = {};
            let invalid = false;
            for (let i = 0; i < this.goaltimes.length; i++) {
                if (this.goaltimes[i].timevalue === "") {
                    continue;
                }
                if (map[this.goaltimes[i].timevalue]) {
                    invalid = true;
                    break;
                }
                map[this.goaltimes[i].timevalue] = true;
            }
            if (invalid) {
                return false;
            }
            return true;
        }
    }
    validateSchedule() {
        //Check Duplicates
        let map = {};
        let invalid = false;
        for (let i = 0; i < this.scheduletimes.length; i++) {
            let key = "";
            if (this.frequencytype === "Yearly") {
                if (this.scheduletimes[i].day === "" || this.scheduletimes[i].month === "") {
                    continue;
                }
                key = this.scheduletimes[i].day + " " + this.scheduletimes[i].month;
            } else {
                if (this.scheduletimes[i].day === "") {
                    continue;
                }
                key = this.scheduletimes[i].day;
            }
            if (map[key]) {
                invalid = true;
                break;
            }
            map[key] = true;
        }
        if (invalid) {
            return false;
        }
        return true;
    }
    Validate(field) {
        if (this.goalForm.get(field).value == null || this.goalForm.get(field).value < 0) {
            this.goalForm.controls[field].setErrors({ incorrect: true });
        }
    }
    public onCancel() {
        this.activeModal.close();
    }

    public onKeydown() {
        return false;
    }

    public changeGoalType(value, selectedText) {
        this.goaltypeid = value;
        this.isCompletion = false;
        // this.goalForm.get("datatype").patchValue("");
        // this.goalForm.get("datatype2").patchValue("");
        this.goaltypetext = selectedText;
        // if (this.goaltypetext === "Single Target" && this.frequencytype === "Weekly") {
        this.goaltimes = [{ hours: "", mins: "", time: "", timevalue: "", timename: "" }];
        //}
        this.goalForm.patchValue({ goaltypeid: value });
        const datatypeControl = this.goalForm.get("datatype");
        const datatypeControl2 = this.goalForm.get("datatype2");
        const target1Control = this.goalForm.get("target1");
        const target2Control = this.goalForm.get("target2");
        if (selectedText.toLocaleLowerCase() === "single target") {
            this.Target1 = "Target";
            this.Caption1 = "Caption";
            target1Control.setValidators([Validators.required]);
            target2Control.setValidators(null);
            datatypeControl.setValidators([Validators.required]);
            datatypeControl2.setValidators(null);
            this.hideTarget = false;
            this.hideTarget2 = true;
            this.hideDataType = false;
            this.hideDataType2 = true;
            this.isTargeted = true;
        } else if (selectedText.toLocaleLowerCase() === "double target") {
            this.Target1 = "Systolic";
            this.Target2 = "Diastolic";
            this.goalForm.get("datatype").patchValue("Systolic");
            this.goalForm.get("datatype2").patchValue("Diastolic");
            target1Control.setValidators([Validators.required]);
            target2Control.setValidators([Validators.required]);
            datatypeControl.setValidators([Validators.required]);
            datatypeControl2.setValidators([Validators.required]);
            this.hideTarget = false;
            this.hideTarget2 = false;
            this.hideDataType = true;
            this.hideDataType2 = true;
            this.isTargeted = true;
        } else if (selectedText.toLocaleLowerCase() === "single numeric") {
            target1Control.setValidators(null);
            target2Control.setValidators(null);
            datatypeControl.setValidators([Validators.required]);
            datatypeControl2.setValidators(null);
            this.hideTarget = true;
            this.hideTarget2 = true;
            this.hideDataType = false;
            this.hideDataType2 = true;
            this.isTargeted = true;
            this.isCompletion = true;
        } else if (selectedText.toLocaleLowerCase() === "double numeric") {
            target1Control.setValidators(null);
            target2Control.setValidators(null);
            datatypeControl.setValidators([Validators.required]);
            datatypeControl2.setValidators([Validators.required]);
            this.hideTarget = true;
            this.hideTarget2 = true;
            this.hideDataType = true;
            this.hideDataType2 = true;
            this.goalForm.get("datatype").patchValue("Systolic");
            this.goalForm.get("datatype2").patchValue("Diastolic");
            this.isTargeted = true;
            this.isCompletion = true;
        } else if (selectedText.toLocaleLowerCase() === "completion") {
            this.isCompletion = true;
            target1Control.setValidators(null);
            target2Control.setValidators(null);
            datatypeControl.setValidators(null);
            datatypeControl2.setValidators(null);
            this.hideTarget = true;
            this.hideTarget2 = true;
            this.hideDataType = true;
            this.hideDataType2 = true;
            this.isTargeted = true;
        } else {
            target1Control.setValidators(null);
            target2Control.setValidators(null);
            datatypeControl.setValidators(null);
            datatypeControl2.setValidators(null);
            this.hideTarget = true;
            this.hideTarget2 = true;
            this.hideDataType = true;
            this.hideDataType2 = true;
            this.isTargeted = false;
        }
        target1Control.updateValueAndValidity();
        target2Control.updateValueAndValidity();
        datatypeControl.updateValueAndValidity();
        datatypeControl2.updateValueAndValidity();
    }

    private loadGoalCategories() {
        this.goalsService.getGoalCategories().subscribe(
            (data) => {
                this.goalCategories = data;
            },
            (err) => {
                console.error(err);
            }
        );
    }

    private loadGoalTypes() {
        this.goalsService.getGoalTypes().subscribe(
            (data) => {
                this.goalTypes = data;
            },
            (err) => {
                console.error(err);
            }
        );
    }

    public getUserApps() {
        this.appsService.getUserApps(this.PatientId).subscribe(
            (data) => {
                let suggested = data[0].attributes.suggested;
                let all = data[0].attributes.global;
                suggested.forEach((e) => this.userApps.push({ appid: e.appid, title: e.title }));
                all.forEach((e) => this.userApps.push({ appid: e.appid, title: e.title }));
            },
            (err) => {
                // TODO: show error message
                console.error(err[0].detail);
            }
        );
    }

    public onSave() {
        this.isSubmitted = true;
        this.goalForm.markAllAsTouched();
        this.goalForm.patchValue({ startDate: this.startdate });
        let startDate = this.goalForm.getRawValue().startDate;
        let endDate = this.goalForm.getRawValue().endDate;

        if (!this.hideTarget && (this.goalForm.getRawValue().target1 == null || this.goalForm.getRawValue().target1 < 0)) {
            this.goalForm.controls.target1.setErrors({ incorrect: true });
        }
        if (!this.hideTarget2 && (this.goalForm.getRawValue().target2 == null || this.goalForm.getRawValue().target2 < 0)) {
            this.goalForm.controls.target2.setErrors({ incorrect: true });
        }
        if (this.frequencytype === "Once") {
            var dateStartDate = new Date(startDate.year, startDate.month - 1, startDate.day);
            dateStartDate.setDate(dateStartDate.getDate() + 1);
            let getEndDate = { year: dateStartDate.getFullYear(), month: dateStartDate.getMonth() + 1, day: dateStartDate.getDate() };
            this.goalForm.patchValue({ endDate: getEndDate });
            let _endDate = this.goalForm.get("endDate");
            _endDate.setValidators(null);
            _endDate.updateValueAndValidity();
        } else {
            let _endDate = this.goalForm.get("endDate");
            _endDate.setValidators(null);
            _endDate.updateValueAndValidity();
        }
        if (new Date(startDate.year, startDate.month - 1, startDate.day).getTime() > new Date(endDate.year, endDate.month - 1, endDate.day).getTime()) {
            // TODO: show error
            this.error = "End Date should not be less then start date.";
            this.goalForm.controls.endDate.setErrors({ incorrect: true });
            return false;
        }
        let invalidTime = false;
        this.goaltimes.forEach((element, index) => {
            if (
                element.hours == "" ||
                parseInt(element.hours) === undefined ||
                parseInt(element.hours) > 12 ||
                parseInt(element.hours) < 1 ||
                element.mins == "" ||
                parseInt(element.mins) === undefined ||
                (parseInt(element.mins) !== 30 && parseInt(element.mins) !== 0) ||
                element.time === ""
            ) {
                invalidTime = true;
                return;
            }
            let hours = parseInt(element.hours);
            if (element.time === "PM" && hours < 12) {
                hours = hours + 12;
            } else if (element.time === "AM" && hours === 12) {
                hours = hours - 12;
            }
            element.timevalue =
                (hours.toString().length === 1 ? "0" + hours.toString() : hours.toString()) +
                ":" +
                (element.mins.toString().length === 1 ? "0" + element.mins.toString() : element.mins.toString()) +
                ":00";
            let time = parseInt(element.timevalue.substring(0, 2));
            if (time >= 4 && time < 12) {
                element.timename = "morning";
            } else if (time >= 12 && time < 17) {
                element.timename = "afternoon";
            } else if (time >= 17 && time < 20) {
                element.timename = "evening";
            } else {
                element.timename = "bedtime";
            }
        });
        //Frequencies
        let frequencies = this.goalForm.get("frequencies");
        if (this.frequencytype === "Weekly") {
            let strfrequencies = "";
            this.invalidFrequencies = true;
            if (this.frequencies.length > 0) {
                this.frequencies.forEach((element) => {
                    if (element.selected) {
                        this.invalidFrequencies = false;
                        strfrequencies += element.id + ",";
                    }
                });
                strfrequencies = strfrequencies.substring(0, strfrequencies.length - 1);
                this.goalForm.patchValue({ frequencies: strfrequencies });
                this.goalForm.patchValue({ scheduledate: null });
                frequencies.setValidators([Validators.required]);
                frequencies.updateValueAndValidity();
            }
        } else if (this.frequencytype === "Yearly") {
            let scheduleDate = "";

            //Sort Days
            this.scheduletimes.sort(function (a, b) {
                if (a.month === "" || a.day === "" || b.month === "" || b.day === "") {
                    return 1;
                }
                let status = parseInt(a.month) > parseInt(b.month) ? true : parseInt(a.month) === parseInt(b.month) ? (parseInt(a.day) > parseInt(b.day) ? true : false) : false;
                return status ? 1 : -1;
            });
            this.scheduletimes.forEach((time, index) => {
                scheduleDate = scheduleDate + (index > 0 ? "," : "") + time.month + "-" + time.day;
            });

            this.goalForm.patchValue({ frequencies: null });
            this.goalForm.patchValue({ scheduledate: scheduleDate });
            frequencies.setValidators(null);
            frequencies.updateValueAndValidity();
            //validate
            let hasInvalidRecords = this.scheduletimes.filter((x) => x.day === "" || x.month === "");

            if (!this.validateSchedule()) {
                if (!this.goalForm.valid || hasInvalidRecords.length > 0 || this.scheduletimes.length === 0 || invalidTime) {
                    this.error = "Please provide mandatory fields. Goal must not have duplicate schedules.";
                } else {
                    this.error = "Goal must not have duplicate schedules.";
                }
                return false;
            } else if (hasInvalidRecords.length > 0 || this.scheduletimes.length === 0) {
                this.error = "Please provide mandatory fields.";
                return false;
            }
        } else if (this.frequencytype === "Once") {
            let scheduleDate = (startDate.month < 10 ? "0" + startDate.month : startDate.month) + "-" + (startDate.day < 10 ? "0" + startDate.day : startDate.day);
            this.goalForm.patchValue({ frequencies: null });
            this.goalForm.patchValue({ scheduledate: scheduleDate });
            frequencies.setValidators(null);
            frequencies.updateValueAndValidity();
        } else if (this.frequencytype === "Monthly") {
            let scheduleDate = "";

            //Sort Days
            this.scheduletimes.sort(function (a, b) {
                if (a.day === "" || b.day === "") {
                    return 1;
                }
                let status = parseInt(a.day) > parseInt(b.day) ? true : false;
                return status ? 1 : -1;
            });

            this.scheduletimes.forEach((time, index) => {
                scheduleDate = scheduleDate + (index > 0 ? "," : "") + time.day;
            });
            this.goalForm.patchValue({ frequencies: null });
            this.goalForm.patchValue({ scheduledate: scheduleDate });
            frequencies.setValidators(null);
            frequencies.updateValueAndValidity();
            //validate
            let hasInvalidRecords = this.scheduletimes.filter((x) => x.day === "");
            if (!this.validateSchedule()) {
                if (!this.goalForm.valid || hasInvalidRecords.length > 0 || this.scheduletimes.length === 0 || invalidTime) {
                    this.error = "Please provide mandatory fields. Goal must not have duplicate schedules.";
                } else {
                    this.error = "Goal must not have duplicate schedules.";
                }
                return false;
            } else if (hasInvalidRecords.length > 0 || this.scheduletimes.length === 0) {
                this.error = "Please provide mandatory fields.";
                return false;
            }
        }
        //Goal Times

        if (this.goaltimes.length > 0) {
            if (this.frequencytype === "Weekly" && !this.validateTime()) {
                if (!this.goalForm.valid || invalidTime) {
                    this.error = "Please provide mandatory fields. Goal must not have duplicate schedules.";
                } else {
                    this.error = "Goal must not have duplicate schedules.";
                }
                return false;
            } else if (invalidTime) {
                this.error = "Please provide mandatory fields.";
                return false;
            }
        }
        this.goaltimes.sort(function (a, b) {
            let x = parseInt(a.timevalue.replace(":", ""));
            let y = parseInt(b.timevalue.replace(":", ""));
            return x - y;
        });
        this.goalForm.patchValue({ frequencytype: this.frequencytype });
        this.goalForm.patchValue({ notifyprovider: this.notifyprovider });
        this.goalForm.patchValue({ allowearlyentry: this.allowearlyentry });
        if (this.ExistingGoal.includes(this.goalForm.getRawValue().goalname)) {
            this.error = "Goal name already exist.";
            return false;
        }
        if (this.goalForm.valid) {
            this.goaltimes.forEach((element) => {
                element.timevalueold = !element.timevalueold || element.timevalueold === "" ? element.timevalue : element.timevalueold;
            });
            this.goalsService.assignGoalToPatient(this.goalForm.getRawValue(), this.goaltimes, this.isEditing).subscribe(
                (data) => {
                    // Return response
                    this.Response.emit(data[0].attributes);
                    this.activeModal.close();
                },
                (err) => {
                    this.error = err[0].detail;
                }
            );
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }
}
