<div class="modal-body">
    <div class="category-header">
        <label>{{ PasswordExpired === true ? "You password has been expired. Please change." : "Change Password"
            }}</label>
    </div>
    <form [formGroup]="passwordForm">
        <div class="main-row">
            <div class="main-full">
                <div class="row">
                    <div class="input-group mb-3 col">
                        <p>
                            Please select a secure password. This is an administrative account with elevated privileges.
                            We strongly suggest the use of a randomly generated password and password
                            management software like <a href="https://lastpass.com/" target="_blank">LastPass.</a>
                        </p>
                        <p>
                            Please contact
                            <a href="mailto:support@medstatix.com">support</a>
                            with any questions.
                        </p>
                        <p class="full-width">
                            <label class="light-gray mb-0">Password Requirements:</label>
                            <small class="form-text text-muted">Minimum: 8 characters </small>
                            <small class="form-text text-muted">Required: 1-Upper Case, 1-Lower Case, 1-Symbol,
                                1-Number</small>
                            <small class="form-text text-muted">Invalid: Spaces/Whitespace </small>
                        </p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Old Password:</label>
                    <input ngbAutofocus formControlName="oldPassword" type="password" required class="form-control"
                        autofocus />
                </div>
                <div class="form-group">
                    <label>New Password:</label>
                    <input formControlName="newPassword" type="password" required class="form-control" />
                </div>
                <div class="form-group">
                    <label>Confirm Password:</label>
                    <input formControlName="confirmPassword" type="password" required class="form-control" />
                </div>

                <ngb-alert *ngIf="errorMessage.length" [dismissible]="false" [type]="'danger'" class="mb-0">
                    {{ errorMessage }}
                </ngb-alert>
            </div>
        </div>
        <div class="main-row">
            <div class="main-left">
                <button *ngIf="!PasswordExpired" type="button" class="btn btn-secondary"
                    (click)="onCancel()">Cancel</button>
            </div>
            <div class="main-right">
                <button type="button" class="btn btn-primary" (click)="onSave()">Change Password</button>
            </div>
        </div>
    </form>
</div>