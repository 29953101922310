import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'underscore';
import * as Highcharts from "highcharts";
declare var require: any
const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)
@Component({
  selector: 'app-patient-billing-graph',
  templateUrl: './patient-billing-graph.component.html',
  styleUrls: ['./patient-billing-graph.component.scss']
})
export class PatientBillingGraphComponent implements OnInit {
  @Input() reportData: any;
  constructor() { }
  colorArray = ['#08c86c', '#ff930a', '#232bc8', '#ffbf0a', '#ff480a'];
  public chartOptions: any;
  Highcharts: typeof Highcharts = Highcharts;
  page = 0;
  pageCount = 0;
  perPage = 10;
  ngOnInit(): void {
    // if (this.reportData.key1 === "Location") {
    this.reportData.categories = this.reportData.categories.map(element => element.toUpperCase());
    // }
    this.loadReport(this.reportData)
    this.pageCount = Math.ceil(this.reportData.data.length / this.perPage)
  }

  chartSettings() {
    this.chartOptions = {
      chart: {
        type: "column",
        width: 350,
        plotBorderColor: '#C3C3C3',
        plotBorderWidth: 2,
        plotBorderRadius: 20,
      },

      colors: this.colorArray,
      credits: {
        enabled: false
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      lang: {
        noData: "No data available.",
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#303030'
        }
      },
      legend: {
        enabled: false
      },
      xAxis: {
      },
      yAxis: {
        title: {
          text: null
        },
        plotLines: [
          {
            color: '#24B6FF',
            width: 3,
            value: this.reportData.nationalAverage.amount / this.reportData.nationalAverage.totalvisits,
            zIndex: 5,
            label: {
              useHTML: true,
              text: '<div  class="chartbenchMark">' + this.reportData.nationalAverage[this.reportData.key1] + '</div>',
              align: 'right',
              allowOverlap: false,
              //x: 100,
              // x: 10, //shifts right
              y: -15
            }
          },
          {
            color: '#A5A5A5',
            width: 3,
            value: this.reportData.allPracticesAverage.amount / this.reportData.allPracticesAverage.totalvisits,
            zIndex: 5,
            label: {
              useHTML: true,
              text: '<div  class="allPractices">' + this.reportData.allPracticesAverage[this.reportData.key1] + '</div>',
              align: 'right',
              allowOverlap: true,
              // x: 50,
              // x: 10, //shifts right
              y: -15
            }
          }
        ]
      },
      tooltip: {
        valueSuffix: '%',
        useHTML: true,
        shared: true,
        formatter: function () {
          var title = this.points[0].key;
          var tooltip = '<table>';
          //  var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
          this.points.forEach(element => {
            tooltip += '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + title + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + Highcharts.numberFormat(element.y, 2) + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
          });
          return tooltip + '</table>';
        }
      },
      plotOptions: {
        series: {
          pointWidth: 20
        }
      }
    }
  }

  loadReport(reportData) {
    this.chartSettings();
    let _data = _.first(_.rest(reportData.data, (this.page || 0) * this.perPage), this.perPage);
    let _categories = _.first(_.rest(reportData.categories, (this.page || 0) * this.perPage), this.perPage);

    this.chartOptions.series = [];
    this.chartOptions.series = [{
      data: _data
    }]
    this.chartOptions.xAxis['categories'] = _categories
  }

  previous() {
    if (this.page > 0) {
      this.page--;
      this.loadReport(this.reportData)
    }
  }

  next() {
    if (this.page < this.pageCount - 1) {
      this.page++;
      this.loadReport(this.reportData)
    }

  }
}
