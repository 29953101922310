import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";

@Component({
    selector: "symptom-category-modal",
    templateUrl: "./symptom-category-modal.component.html",
    styleUrls: ["./symptom-category-modal.component.scss"],
})
export class SymptomCategoryModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private patientService: PatientService, private snackbar: SnackbarService, private modalService: NgbModal) {}

    @Input() SymptomsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    checklist: Array<any> = [];
    public others: string = "";
    public temperature: number;
    public error = "";
    public symptoms: any = {};

    ngOnInit() {
        this.symptoms = this.SymptomsData.symptoms;
        this.checklist = [
            {
                name: "Eye, Ear, Nose and Throat",
                component: "SymptomEnt",
            },
            {
                name: "Obstetric/Gynecological",
                component: "SymptomObGyn",
            },
            {
                name: "Gastrointestinal",
                component: "SymptomGas",
            },
            {
                name: "Musculoskeletal",
                component: "SymptomMusculo",
            },
            {
                name: "General",
                component: "SymptomGeneral",
            },
            {
                name: "Skin",
                component: "SymptomSkin",
            },
            {
                name: "Heart and Lung",
                component: "SymptomHeartLung",
            },
            {
                name: "Urologic",
                component: "SymptomUro",
            },
            {
                name: "Neurological/Psychological",
                component: "SymptomNeuroPsycho",
            },
        ];
    }

    onCategorySelection(component) {
        this.Response.emit({ success: true, message: "", action: this.SymptomsData.action, wruid: this.SymptomsData.wruid, symptoms: this.symptoms, gotocomponent: component });
        this.activeModal.close();
    }

    public onCancel() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.activeModal.close();
            }
        });
    }
}
