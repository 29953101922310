import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from 'underscore';
import * as Highcharts from "highcharts";
import { MSXReportService } from "../../services/msx_report.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { Router } from "@angular/router";
@Component({
  selector: 'app-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss']
})
export class MetricComponent implements OnInit {
  @Input() metric: any;
  @Input() isSurvayGraph = false;
  @Output("refreshMetricsList") refreshMetricsList: EventEmitter<any> = new EventEmitter();
  public chrtData = [];
  public chartOptions: any;
  Highcharts: typeof Highcharts = Highcharts;
  page = 0;
  pageCount = 0;
  chart;
  showLoader = false;
  lowSample = 5

  constructor(
    private http: HttpClient,
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
  ) {
  }
  _data = [];
  _categories = [];
  benchmarkName = "National Average";
  benchmark = [];
  reportData: any;
  ngOnInit(): void {
    this.getReportData();
  }
  public getReportData() {
    this.showLoader = true;
    this.msxReportService.getChartData(this.metric.metricid).subscribe(
      (data) => {
        this.reportData = data[0].attributes.data;
        this.loadReport()
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  roundN(n, name) {
    if (name.match(/NATIONAL AVERAGE/i)) return "";
    if (n >= 1e7) return Math.round(n / 1e6) + 'MN';
    if (n >= 1e6) return Math.round(n / 1e5) / 10 + 'MN';
    if (n >= 1e4) return Math.round(n / 1e3) + 'KN';
    if (n >= 1e3) return Math.round(n / 1e2) / 10 + 'KN';
    return n + 'N';
  }

  loadReport() {
    this.showLoader = false;
    this.benchmark = _.where(this.reportData.chartBenchmarks, { benchmarkName: 'National Average' })

    let data = _.where(this.reportData.chartSeries, { seriesId: '740ed927-15c0-4ba9-b971-cd873fb0ae73' })


    let providers = _.where(this.reportData.chartSeries, { seriesId: '740ed927-15c0-4ba9-b971-cd873fb0ae73' }).map(m => {
      var latter = _.findWhere(m.seriesData, { x: this.reportData.chartLatterX } || { y: null, n: 0 })
      var former = _.findWhere(m.seriesData, { x: this.reportData.chartFormerX } || { y: null, n: 0 })
      return {
        name: m.seriesName,
        latterY: latter == undefined ? null : latter.y,
      };
    }).filter(p => { return (p.latterY != null || p.latterY != null); })
    _.sortBy(p => { return 100 - p.latterY; }).values();
    var latterSeries = [];
    for (var i = 0; i < providers.length; i++) {
      latterSeries.push({ category: providers[i].name, y: providers[i].latterY })
    }
    this.chartSettings();
    this.chartOptions.series = [];
    var maxDocsInXaxis = 5;
    var perPage = Math.ceil(_.pluck(providers, 'name').length / Math.ceil(_.pluck(providers, 'name').length / maxDocsInXaxis));
    if (this.reportData.chartLatterX > 1e3) {
      this.chartOptions.series.push(
        {
          data: _.first(_.rest(latterSeries, (this.page || 0) * perPage), perPage),
          name: Highcharts.dateFormat('%B %Y', this.reportData.chartLatterX),
          legendIndex: 2,
          color: '#00BD00',
          showInLegend: false

        });
    }
    this.chartOptions.xAxis['categories'] = _.chain(providers).pluck('name').rest((this.page || 0) * perPage).first(perPage).value(); //_(providers).pluck('name');
    this.pageCount = Math.ceil(_.pluck(providers, 'name').length / perPage);
  }

  chartSettings() {
    this.chartOptions = {
      chart: {
        type: 'line',
        marginTop: 20,
        spacingBottom: 20,
        zoomType: 'x',
        height: 350,
        marginRight: this.isSurvayGraph ? 0 : 100,
        plotBorderColor: '#C3C3C3',
        plotBorderWidth: 2,
        plotBorderRadius: 20,
      },

      credits: {
        enabled: false
      },
      title: {
        align: 'left',
        useHTML: true,
        text: null,
        y: 1,
        style: {
          fontSize: '10px',
        }
      },
      subtitle: {
        align: 'right',
        useHTML: true,
        verticalAlign: 'top',
        x: -3,
        y: 7
      },
      xAxis: {
        minRange: 1,
        labels: {
          rotation: -30,
          align: 'right'
        }
      },
      yAxis: {
        labels: {
          format: '{value}%'
        },
        title: {
          text: this.metric.name
        },
        plotLines: [
          {
            color: '#24B6FF',
            width: 2,
            value: this.benchmark[0].benchmarkValue,
            zIndex: 5,
            label: {
              useHTML: true,
              text: '<div class="chartbenchMark"> ' + this.benchmark[0].benchmarkName + '</div>',
              align: 'right',
              allowOverlap: false,
              x: 100,
            }
          }
        ],
        tickInterval: 5
      },
      lang: {
        noData: "No data available for the current selection."
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#303030'
        }
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal'
      },
      tooltip: {
        valueSuffix: '%',
        useHTML: true,
        shared: true,
        formatter: function () {
          var title = this.points[0].key;
          var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
          this.points.forEach(element => {
            tooltip += '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + element.y + '%' + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
          });
          return tooltip + '</table>';
        }
      }
    }
  }

  previous() {
    if (this.page > 0) {
      this.page--;
      this.loadReport();
    }
  }

  next() {
    if (this.page < this.pageCount - 1) {
      this.page++;
      this.loadReport();
    }

  }

  delete() {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Yes";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        var metrics = [];
        metrics = JSON.parse(localStorage.getItem('metrics')) || []
        if (!_.contains(metrics, this.metric.metricid)) {
          metrics.push(this.metric);
          localStorage.setItem("metrics", JSON.stringify(metrics));
        }
        this.activeModal.close();
        this.refreshMetricsList.emit();
      }
    });
  }

  checklocalStorageDeletedGraph() {
    var metrics = [];
    metrics = JSON.parse(localStorage.getItem('metrics')) || []
  }
}
