import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import * as Highcharts from "highcharts";

@Injectable({
    providedIn: "root",
})
export class ChartService {
    readonly BLOOD_PRESSURE_GOAL_TYPE_ID = "427e8564-71b1-4d82-9ce1-707589071c58";

    pipe = new DatePipe("en-US");
    constructor() { }
    getCategories(categories) {
        return categories;
    }
    public drawGraph(item, type = "line", showDatatype = false) {
        let response = [];
        let datatype = [];
        let graphdata = [];
        let goaltypename = "";
        let goaltypeid = "";
        let categories: any;
        item.list.forEach((element) => {
            categories = element.graphreport2.dateRange.length > 0 ? this.getCategories(element.graphreport2.dateRange) : categories;
            goaltypename = element.goaltype.goaltypename;

            goaltypeid = element.goaltype.goaltypeid;
            datatype = element.datatype.split(",");

            if (element.goaltype.goaltypename == "Double Numeric" || element.goaltype.goaltypename == "Double Target") {
                let _data = [];
                _data.push(element.graphreport2.data1);
                _data.push(element.graphreport2.data2);
                graphdata.push({
                    name: element.gtimeformat,
                    data: _data,
                });
            } else {
                graphdata.push({
                    name: element.gtimeformat,
                    data: element.graphreport2.data1,
                });
            }
        });
        if (goaltypename === "Double Numeric" || goaltypename === "Double Target") {
            //rearrange data for graph
            let graph = [];
            let name = [];

            graphdata.forEach((element, i) => {
                graph.push({
                    name: element.name + " (" + datatype[0] + ")",
                    data: element.data[0],
                });
                graph.push({
                    name: element.name + " (" + datatype[1] + ")",
                    data: element.data[1],
                });
            });
            //create graph
            let graphData = [];
            graphData.push(graph);

            if (goaltypename == "Double Numeric" || goaltypename == "Double Target") {
                graphData.forEach((element, j) => {
                    element["chartObject"] = this.returnBloodPressureChart(element, categories);
                    response.push(element);
                });
            } else {
                graphData.forEach((element, j) => {
                    element["chartObject"] = this.returnDoubleChart(element, categories, name, "", type);
                    response.push(element);
                });
            }
        } else {
            let element = [];
            element["chartObject"] = this.returnLineChart(graphdata, categories, type);
            response.push(element);
        }
        if (goaltypename.includes("Target")) {
            response.forEach((chartItem, i) => {
                response[i].chartObject.yAxis = {
                    plotLines: [
                        {
                            color: "#c3c3c3",
                            width: 2,
                            value: i === 0 ? item.list[0].target1 : 0,
                            dashStyle: "Dot",
                            label: {
                                text: datatype[0],
                                align: "left",
                            },
                            zIndex: 10,
                        },
                        {
                            color: "#c3c3c3",
                            width: 2,
                            value: i === 0 ? item.list[0].target2 : 0,
                            dashStyle: "Dot",
                            label: {
                                text: datatype[1],
                                align: "left",
                            },
                            zIndex: 5,
                        },
                    ],
                    min: 0,
                    max: i === 0 ? Math.max(Math.max(...item.list[0].graphreport.data1, ...item.list[0].graphreport2.data1) + 5, Math.max(item.list[0].target1 + 5, item.list[0].target2 + 5)) : 200,

                };
            });
        }
        return response;
    }

    private returnDoubleChart(seriesData: any, categories: any, name, title, type): Highcharts.Options {
        let data = [];
        seriesData.forEach((element, j) => {
            let _data = {
                name: element.name,
                data: element.data,
            };
            data.push(_data);
        });

        return {
            title: {
                text: title,
            },
            credits: { enabled: false },
            chart: {
                type: type,
                height: 250,
                width: null,
                // backgroundColor: "#fff",
                // borderColor: "#B1DEF4",
                // borderWidth: 2,
                // borderRadius: 5,
            },
            exporting: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: null,
                },
            },
            xAxis: {
                categories: categories,
            },
            plotOptions: {
                line: {
                    connectNulls: true,
                    label: {
                        connectorAllowed: false,
                    },
                    marker: {
                        enabled: true,
                        symbol: "circle",
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                    },
                },
            },
            series: data,
        };
    }

    private returnLineChart(seriesData: any, categories: any, type): Highcharts.Options {
        return {
            title: {
                text: "",
            },
            credits: { enabled: false },
            chart: {
                type: type,
                height: 250,
                width: null,
                marginLeft: 100,
                // backgroundColor: "#fff",
                // borderColor: "#B1DEF4",
                // borderWidth: 2,
                // borderRadius: 5,
            },
            exporting: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: null,
                },
            },
            xAxis: {
                categories: categories,
                margin: 0,
            },
            plotOptions: {
                line: {
                    connectNulls: true,
                    label: {
                        connectorAllowed: false,
                    },
                    marker: {
                        enabled: true,
                        symbol: "circle",
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                    },
                },
            },
            series: seriesData,
        };
    }

    private returnBloodPressureChart(seriesData: any, categories: any): Highcharts.Options {
        let chartData = [];

        for (let i = 0; i < seriesData[0].data.length; i++) {
            let series = {
                color: "#ff0000",
                name: seriesData[0].data[i] !== 0 && seriesData[1].data[i] !== 0 ? seriesData[0].name + " " + seriesData[1].name : "",
                enableMouseTracking: true,
                tooltip: {
                    enabled: true,
                    pointFormat: seriesData[0].name + ": <strong>" + seriesData[0].data[i] + "</strong> <br />" + seriesData[1].name + ": <strong>" + seriesData[1].data[i] + "</strong>",
                },
                data: [
                    { x: i, y: seriesData[0].data[i] },
                    { x: i, y: seriesData[1].data[i] },
                ],
                marker: {
                    enabled: true,
                },
                opacity: 1,
            };
            if (seriesData[0].data[i] === 0 && seriesData[1].data[i] === 0) {
                series.tooltip.enabled = false;
                series.marker.enabled = false;
                series.enableMouseTracking = false;
                series.opacity = 0;
            }

            chartData.push(series);
        }

        return {
            title: {
                text: "",
            },
            credits: { enabled: false },
            legend: {
                enabled: false,
            },
            chart: {
                type: "line",
                height: 250,
                width: null,
                // backgroundColor: "#fff",
                // borderColor: "#B1DEF4",
                // borderWidth: 2,
                // borderRadius: 5,
            },
            exporting: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: null,
                },
            },

            xAxis: {
                categories: categories,
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                        enabled: false,
                    },
                    marker: {
                        symbol: "circle",
                        enabled: true,
                        fillColor: "#FFFFFF",
                        lineWidth: 2,
                        lineColor: null, // inherit from series
                    },
                },
            },
            series: chartData,
        };
    }
}
