import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { CareTeamService } from "../../services/careteam.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { ActivatedRoute } from "@angular/router";
import { PatientService } from "../../services/patients.service";
import { UsersService } from "../../services/users.service";
import { ClinicalSummary } from "./models/ClinicalSummary";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommunicationService } from "../../services/communications.service";
import { NotesModalComponent } from "../../modals/notes-modal/notes-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppsService } from "../../services/apps.service";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { PrescriptionModalComponent } from "../../modals/prescription-modal/prescription-modal.component";
import { PrescriptionViewModalComponent } from "../../modals/prescription-view-modal/prescription-view-modal.component";
import { SummaryViewModalComponent } from "../../modals/summary-view-modal/summary-view-modal.component";
import { SummaryCategoriesModalComponent } from "../../modals/summary-category/summary-categories-modal/summary-categories-modal.component";
import { WaitingRoomMoveModalComponent } from "../../modals/waiting-room-move-modal/waiting-room-move-modal.component";
import { WaitingRoomReferModalComponent } from "../../modals/waiting-room-refer-modal/waiting-room-refer-modal.component";
import { Subject, interval } from "rxjs";
import { SessionService } from "../../services/session.service";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MessagingService } from "../../services/messaging.service";
import { VideoChatModalComponent } from "../../modals/video-chat-modal/video-chat-modal.component";
import { Helper } from "../../providers/helper";

import {
    PersonalInformation,
    ContactInformation,
    EmergencyContact,
    HealthInsuranceInformation,
    PastMedicalHistory,
    PastSurgicalHistory,
    WomenHealthHistory,
    MedicationAllergies,
    FoodAllergies,
    EnvironmentalAllergies,
    SocialHistory,
    // Allergies,
    Vaccines,
    FamilyHistory,
    Prescriptions,
    Medications,
    MoodWellBeing,
    Covid19Vaccination,
} from "./models/SummaryDetails";
import { ViewChild } from "@angular/core";
@Component({
    selector: "patient-dashboard",
    templateUrl: "./patient-dashboard.component.html",
    styleUrls: ["./patient-dashboard.component.css"],
})
export class PatientDashboardComponent implements OnInit {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public summaryData: any = { summary: {} };
    chat_order = 1;
    symptom_order = 2;
    clinical_order = 3;
    goal_order = 4;
    public patientData: any = { patient: {} };
    public patientReferData: any = { patient: {} };

    phq9score = 0;
    gad7Score = 0;
    phq9Date = "";
    gad7Date = "";
    constructor(
        public careTeamService: CareTeamService,
        private route: ActivatedRoute,
        public sessionService: SessionService,
        private patientService: PatientService,
        private usersService: UsersService,
        private waitingRoomService: WaitingRoomService,
        private snackbar: SnackbarService,
        private communication: CommunicationService,
        private modalService: NgbModal,
        private appsService: AppsService,
        private messagingService: MessagingService,
        public activeModal: NgbActiveModal,
        private helper: Helper,
    ) { }

    @Input() patientId: string;
    @Input() wrid: string;
    @Input() wruid: string;
    @Input() patient: any;
    @ViewChild("main_container", { static: false }) containerElement: ElementRef;
    @ViewChild("left_container", { static: false }) leftContainerElement: ElementRef;

    userAttributes: any = {};
    lastlogin = undefined;
    userDetails: any = {};
    clinicalSummaryModel = undefined;
    notes: any = [];
    activeCareTeam = this.sessionService.activeCareTeam;
    patients: any = [];
    currentPatient: any = {};
    links: any = {};
    componentDestructionNotifier = new Subject();
    dob: any;
    firstName: string;
    lastName: string;
    maidenName: string;
    gender: string;
    public displayOrder = [];
    roomName = "";
    waitingRoomTime = undefined;
    waitingRooms: any = [];
    imageURLFront: string;
    imageURLBack: string;
    imageURLVaccination: string;
    imageURLPhotoID: string;
    public tabOrderSettings = this.gettabOrderSettings();
    hasWallet = false;
    _blurStatus = true;
    public gettabOrderSettings() {
        try {
            return this.sessionService.patientDashboardTabSetting.tabOrderSettings;
        } catch (e) {
            return [];
        }
    }
    ngOnInit() {
        this.patient["patientId"] = this.patient.userid
        this.patient["patientid"] = this.patient.userid
        this.currentPatient = this.patient;
        if (!this.patientId) {
            this.patientId = this.route.snapshot.paramMap.get("id");
        }
        if (this.route.snapshot.queryParamMap.get("wrid") || this.wrid) {
            this.wrid = this.wrid ? this.wrid : this.route.snapshot.queryParamMap.get("wrid");
            this.wruid = this.wruid ? this.wruid : this.route.snapshot.queryParamMap.get("wruid");
            this.getWaitingRoomPatientDetails(this.wrid);
            this.getWaitingRooms();
        }

        // this.currentPatient = { patientid: this.patientId };
        // this.currentPatient.authorname = this.patient.fullname;
        this.getSettings();
        this.getDetails();
        this.getClinicalSummary();
        this.getPrescribedApps();
        this.getPatientNotes();
        this.displayOrder = this.tabOrderSettings;

        document.addEventListener("UPDATE_HEADER", (event: any) => {
            this.getSettings();
            this.getDetails();
            this.getClinicalSummary();
            this.setUpHeader();
        });
    }
    getWaitingRoomPatientDetails(wrid) {
        return this.waitingRoomService.getWaitingRoomPatientDetails(wrid, this.activeCareTeam["careteamid"], this.patientId).subscribe(
            (data) => {
                this.roomName = data[0].attributes.waitingroom.roomname;
                if (data[0].attributes.waitingroomrequest.createdat) {
                    let createDate = new Date(data[0].attributes.waitingroomrequest.createdat);
                    var seconds = Math.abs(Date.now() - createDate.getTime()) / 1000;
                    var minutes = Math.floor(seconds / 60);
                    var hours = Math.floor(minutes / 60);
                    var days = Math.floor(hours / 24);
                    minutes = minutes - hours * 60;
                    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
                    this.waitingRoomTime = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
                    //Update waiting time after 30 seconds
                    interval(30000).subscribe((x) => {
                        var seconds = Math.abs(Date.now() - createDate.getTime()) / 1000;
                        var minutes = Math.floor(seconds / 60);
                        var hours = Math.floor(minutes / 60);
                        var days = Math.floor(hours / 24);
                        minutes = minutes - hours * 60;
                        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
                        this.waitingRoomTime = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
                    });
                }
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    setUpHeader() {
        this.clinicalSummaryModel.phq9 = this.clinicalSummaryModel.phq9 || [];
        this.clinicalSummaryModel.gad7 = this.clinicalSummaryModel.gad7 || [];
        if (this.clinicalSummaryModel.phq9.phq9 != undefined && this.clinicalSummaryModel.phq9.phq9.length > 0) {
            this.clinicalSummaryModel.phq9.phq9.at(-1).details.forEach((element) => {
                this.phq9score += element.score;
            });
            this.phq9Date = this.clinicalSummaryModel.phq9.phq9.at(-1).date;
        }
        if (this.clinicalSummaryModel.gad7.gad7 != undefined && this.clinicalSummaryModel.gad7.gad7.length > 0) {
            this.clinicalSummaryModel.gad7.gad7.at(-1).details.forEach((element) => {
                this.gad7Score += element.score;
            });
            this.gad7Date = this.clinicalSummaryModel.gad7.gad7.at(-1).date;
        }
        this.clinicalSummaryModel;

        let summaryDOB = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.dob !== ""
                    ? this.clinicalSummaryModel.personalinformation.dob
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.dob = summaryDOB ? summaryDOB : this.userAttributes.dob;
        let summaryFirstName = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.firstname !== ""
                    ? this.clinicalSummaryModel.personalinformation.firstname
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.firstname = summaryFirstName ? summaryFirstName : this.userAttributes.firstname;
        let summaryLastName = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.lastname !== ""
                    ? this.clinicalSummaryModel.personalinformation.lastname
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.lastname = summaryLastName ? summaryLastName : this.userAttributes.lastname;

        let summaryMaidenName = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.maidenname !== ""
                    ? this.clinicalSummaryModel.personalinformation.maidenname
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.maidenname = summaryMaidenName ? summaryMaidenName : this.userAttributes.maidenname;

        let summaryGender = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.gender !== ""
                    ? this.clinicalSummaryModel.personalinformation.gender
                    : null
                : null
            : null;
        this.gender = summaryGender ? summaryGender : this.userAttributes.gender;

        let summaryMRN = this.clinicalSummaryModel
            ? this.clinicalSummaryModel.personalinformation
                ? this.clinicalSummaryModel.personalinformation.mrn !== ""
                    ? this.clinicalSummaryModel.personalinformation.mrn
                    : null
                : null
            : null;
        this.clinicalSummaryModel.personalinformation.mrn = summaryMRN ? summaryMRN : this.userAttributes.mrn;
        this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname =
            !this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname || this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname == ""
                ? this.clinicalSummaryModel.personalinformation.firstname
                : this.clinicalSummaryModel.healthinsuranceinformation.cardholderfirstname;
        this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname =
            !this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname || this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname == ""
                ? this.clinicalSummaryModel.personalinformation.lastname
                : this.clinicalSummaryModel.healthinsuranceinformation.cardholderlastname;
    }

    getSettings() {
        return this.patientService.getUserSetting(this.patientId).subscribe(
            (data) => {
                this.userAttributes = data[0].attributes;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    getClinicalSummary() {
        return this.patientService.getClinicalSummary(this.patientId).subscribe(
            (data) => {
                let profileSettings = data[0].attributes.profileSettings;
                let personalinformationData = profileSettings ? profileSettings.personalinformation : null;
                let personalInformationModel = new PersonalInformation(
                    profileSettings ? (personalinformationData ? personalinformationData.firstname : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.middlename : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.lastname : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.maidenname : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.mrn : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.gender ? personalinformationData.gender : "") : "") : "",
                    profileSettings ? (personalinformationData ? personalinformationData.dob : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.maritalstatus ? personalinformationData.maritalstatus : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.children ? personalinformationData.children : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.grandchildren ? personalinformationData.grandchildren : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.livingarrangement ? personalinformationData.livingarrangement : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.employment ? personalinformationData.employment : "") : "") : "",
                    profileSettings ? (personalinformationData ? (personalinformationData.photoid ? personalinformationData.photoid : "") : "") : ""
                );

                let contactData = profileSettings ? profileSettings.contactinformation : null;
                let contactInformationModel = new ContactInformation(
                    profileSettings ? (contactData ? contactData.phone1 : "") : "",
                    profileSettings ? (contactData ? (contactData.phone1type ? contactData.phone1type : "") : "") : "",
                    profileSettings ? (contactData ? contactData.phone2 : "") : "",
                    profileSettings ? (contactData ? (contactData.phone2type ? contactData.phone2type : "") : "") : "",
                    profileSettings ? (contactData ? contactData.phone3 : "") : "",
                    profileSettings ? (contactData ? (contactData.phone3type ? contactData.phone3type : "") : "") : "",
                    profileSettings ? (contactData ? contactData.address1 : "") : "",
                    profileSettings ? (contactData ? contactData.address2 : "") : "",
                    profileSettings ? (contactData ? contactData.city : "") : "",
                    profileSettings ? (contactData ? contactData.state : "") : "",
                    profileSettings ? (contactData ? contactData.zip : "") : ""
                );

                let emergencycontactData = profileSettings ? profileSettings.emergencycontact : null;
                let emergencycontactModel = new EmergencyContact(
                    profileSettings ? (emergencycontactData ? emergencycontactData.name : "") : "",
                    profileSettings ? (emergencycontactData ? emergencycontactData.phonenumber1 : "") : "",
                    profileSettings ? (emergencycontactData ? (emergencycontactData.relation ? emergencycontactData.relation : "") : "") : ""
                );
                let healthInsuranceData = profileSettings ? profileSettings.healthinsuranceinformation : null;
                let healthInsurancModel = new HealthInsuranceInformation(
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.cardholderfirstname : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.cardholderlastname : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.idnumber : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.groupnumber : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.companyname : "") : "",
                    profileSettings ? (healthInsuranceData ? healthInsuranceData.company : null) : null,
                    profileSettings ? (healthInsuranceData ? (healthInsuranceData.imagefront ? healthInsuranceData.imagefront : "") : "") : "",
                    profileSettings ? (healthInsuranceData ? (healthInsuranceData.imageback ? healthInsuranceData.imageback : "") : "") : ""
                );

                let pastmedicalhistoryData = profileSettings ? profileSettings.pastmedicalhistory : null;
                let pastmedicalhistoryModel = new PastMedicalHistory(
                    profileSettings ? (pastmedicalhistoryData ? (pastmedicalhistoryData.other ? pastmedicalhistoryData.other : "") : "") : "",
                    profileSettings ? (pastmedicalhistoryData ? pastmedicalhistoryData.historyrecords : null) : null
                );

                let pastsurgicalhistoryData = profileSettings ? profileSettings.pastsurgicalhistory : null;
                let pastsurgicalhistoryModel = new PastSurgicalHistory(
                    profileSettings ? (pastsurgicalhistoryData ? (pastsurgicalhistoryData.other ? pastsurgicalhistoryData.other : "") : "") : "",
                    profileSettings ? (pastsurgicalhistoryData ? pastsurgicalhistoryData.historyrecords : null) : null
                );
                let womenhealthhistoryData = profileSettings ? profileSettings.womenhealthhistory : null;
                let womenhealthhistoryModel = new WomenHealthHistory(
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.firstperiod : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.periodgap : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.periodduration : "") : "",
                    profileSettings ? (womenhealthhistoryData ? (womenhealthhistoryData.periodflow ? womenhealthhistoryData.periodflow : "") : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.totalpregnancies : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.fulltermbirths : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.miscarriages : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.abortions : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.vaginalbirths : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.csections : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.premature : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.menopauseage : "") : "",
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.menopausal : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.birthcontrol : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.pregnancy : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.birthcontroltype : null) : null,
                    profileSettings ? (womenhealthhistoryData ? womenhealthhistoryData.pregnancycomplications : null) : null,
                    profileSettings ? (womenhealthhistoryData ? (womenhealthhistoryData.otherbirthcontrol ? womenhealthhistoryData.otherbirthcontrol : "") : "") : "",
                    profileSettings ? (womenhealthhistoryData ? (womenhealthhistoryData.otherpregnancycomplications ? womenhealthhistoryData.otherpregnancycomplications : "") : "") : ""
                );

                let medicationallergiesData = profileSettings ? profileSettings.medicationallergies : null;
                let medicationallergiesModel = new MedicationAllergies(
                    profileSettings ? (medicationallergiesData ? (medicationallergiesData.other ? medicationallergiesData.other : "") : "") : "",
                    profileSettings ? (medicationallergiesData ? medicationallergiesData.allergies : null) : null
                );

                let foodallergiesData = profileSettings ? profileSettings.foodallergies : null;
                let foodallergiesModel = new FoodAllergies(
                    profileSettings ? (foodallergiesData ? (foodallergiesData.other ? foodallergiesData.other : "") : "") : "",
                    profileSettings ? (foodallergiesData ? foodallergiesData.allergies : null) : null
                );

                let environmentalallergiesData = profileSettings ? profileSettings.environmentalallergies : null;
                let environmentalallergiesModel = new EnvironmentalAllergies(
                    profileSettings ? (environmentalallergiesData ? (environmentalallergiesData.other ? environmentalallergiesData.other : "") : "") : "",
                    profileSettings ? (environmentalallergiesData ? environmentalallergiesData.allergies : null) : null
                );

                let socialhistoryData = profileSettings ? profileSettings.socialhistory : null;
                let socialhistoryModel = new SocialHistory(
                    profileSettings ? (socialhistoryData ? (socialhistoryData.tobaccouse ? socialhistoryData.tobaccouse : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.alcoholuse ? socialhistoryData.alcoholuse : "") : "") : "",
                    profileSettings ? (socialhistoryData ? socialhistoryData.druguse : null) : null,
                    profileSettings ? (socialhistoryData ? (socialhistoryData.drugdetails ? socialhistoryData.drugdetails : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.caffeineuse ? socialhistoryData.caffeineuse : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.exerciseFrequency ? socialhistoryData.exerciseFrequency : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.exerciseIntensity ? socialhistoryData.exerciseIntensity : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.exerciseDuration ? socialhistoryData.exerciseDuration : "") : "") : "",
                    profileSettings ? (socialhistoryData ? (socialhistoryData.sexuallyactive ? socialhistoryData.sexuallyactive : "") : "") : ""
                );

                // let allergiesData = profileSettings ? profileSettings.allergies : null;
                // let allergiesModel = new Allergies(
                //     profileSettings ? (allergiesData ? (allergiesData.medication ? allergiesData.medication : false) : false) : false,
                //     profileSettings ? (allergiesData ? (allergiesData.food ? allergiesData.food : false) : false) : false,
                //     profileSettings ? (allergiesData ? (allergiesData.environmental ? allergiesData.environmental : false) : false) : false
                // );
                let vaccinesData = profileSettings ? profileSettings.vaccines : null;
                let vaccinesModel = new Vaccines(profileSettings ? (vaccinesData ? vaccinesData.vaccines : null) : null);

                let familyhistoryData = profileSettings ? profileSettings.familyhistory : null;
                let familyhistoryDataModel = new FamilyHistory(
                    profileSettings ? (familyhistoryData ? familyhistoryData.history : null) : null,
                    profileSettings ? (familyhistoryData ? familyhistoryData.other : null) : null
                );

                let prescriptionsData = profileSettings ? profileSettings.prescriptions : null;
                let prescriptionsModel = new Prescriptions(profileSettings ? (prescriptionsData ? prescriptionsData.medications : null) : null);

                let moodWellBeingData = profileSettings ? profileSettings.moodWellBeing : null;
                let moodWellBeingModel = new MoodWellBeing(
                    profileSettings ? (moodWellBeingData ? (moodWellBeingData.mood ? moodWellBeingData.mood : "") : "") : "",
                    profileSettings ? (moodWellBeingData ? (moodWellBeingData.chronicIllness ? moodWellBeingData.chronicIllness : "") : "") : "",
                    profileSettings ? (moodWellBeingData ? (moodWellBeingData.pain ? moodWellBeingData.pain : "") : "") : ""
                );

                let covid19VaccinationData = profileSettings ? profileSettings.covid19vaccination : null;
                let covid19VaccinationModel = new Covid19Vaccination(
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.vaccinationstatus : "") : "",
                    profileSettings ? (covid19VaccinationData ? (covid19VaccinationData.vaccinationcard ? covid19VaccinationData.vaccinationcard : "") : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1manufacturer : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1expirationdate : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1lotnumber : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1date : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose1facility : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2manufacturer : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2expirationdate : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2lotnumber : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2date : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose2facility : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3manufacturer : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3expirationdate : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3lotnumber : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3date : "") : "",
                    profileSettings ? (covid19VaccinationData ? covid19VaccinationData.dose3facility : "") : ""
                );
                this.clinicalSummaryModel = new ClinicalSummary(
                    personalInformationModel,
                    contactInformationModel,
                    emergencycontactModel,
                    healthInsurancModel,
                    pastmedicalhistoryModel,
                    pastsurgicalhistoryModel,
                    womenhealthhistoryModel,
                    medicationallergiesModel,
                    foodallergiesModel,
                    environmentalallergiesModel,
                    socialhistoryModel,
                    // allergiesModel,
                    vaccinesModel,
                    familyhistoryDataModel,
                    prescriptionsModel,
                    moodWellBeingModel,
                    covid19VaccinationModel,
                    profileSettings.moodassessment,
                    profileSettings.stressassessment,
                    profileSettings.sleepassessment
                );
                this.setUpHeader();
                if (this.clinicalSummaryModel.personalinformation.photoid) {
                    this.getPhotoIDImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imagefront) {
                    this.getFrontImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imageback) {
                    this.getBackImageURL();
                }
                if (this.clinicalSummaryModel.covid19vaccination.vaccinationcard) {
                    this.getVaccinationImageURL();
                }
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public getFrontImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.healthinsuranceinformation.imagefront).subscribe(
            (data) => {
                this.imageURLFront = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getBackImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.healthinsuranceinformation.imageback).subscribe(
            (data) => {
                this.imageURLBack = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getVaccinationImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.covid19vaccination.vaccinationcard).subscribe(
            (data) => {
                this.imageURLVaccination = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public getPhotoIDImageURL() {
        return this.patientService.getImage(this.clinicalSummaryModel.personalinformation.photoid).subscribe(
            (data) => {
                this.imageURLPhotoID = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public getDetails() {
        return this.usersService.userDetails(this.patientId).subscribe(
            (data) => {
                this.userDetails = data[0].attributes;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public onViewEditClinicalSummary() {
        let summaryModel = JSON.parse(JSON.stringify(this.clinicalSummaryModel));
        this.summaryData = {
            action: "edit",
            patientId: this.patientId,
            summary: summaryModel,
            userAttributes: this.userAttributes,
        };
        const modalRef = this.modalService.open(SummaryViewModalComponent, { size: "lg", centered: true, backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.viewSummaryModalResponse(receivedEntry);
        });
    }
    public viewSummaryModalResponse(event) {
        if (event.success && event.action === "edit") {
            let summaryModel = JSON.parse(JSON.stringify(this.clinicalSummaryModel));
            this.summaryData = {
                action: "edit",
                patientId: this.patientId,
                summary: summaryModel,
                userAttributes: this.userAttributes,
            };
            const modalRef = this.modalService.open(SummaryCategoriesModalComponent, { size: "lg", centered: true, backdrop: "static" });
            modalRef.componentInstance.SummaryData = this.summaryData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.clinicalSummaryModel = receivedEntry.updatedSummaryData;
                if (this.clinicalSummaryModel.personalinformation.photoid) {
                    this.getPhotoIDImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imagefront) {
                    this.getFrontImageURL();
                }
                if (this.clinicalSummaryModel.healthinsuranceinformation.imageback) {
                    this.getBackImageURL();
                }
                if (this.clinicalSummaryModel.covid19vaccination.vaccinationcard) {
                    this.getVaccinationImageURL();
                }
                this.setUpHeader();
                this.snackbar.show(receivedEntry.message);
            });
        }
    }

    back() {
        this.Response.emit({ success: true, message: "", action: "close" });
        this.activeModal.close();
    }

    public getThreadList() {
        this.communication.getThreadList().subscribe(
            (data) => {
                if (data[0].attributes.length > 0) {
                    this.patients = data[0].attributes;
                    // NOTE: Mitch 2020/07/17 - Disabled auto selection of first patient to preserve
                    //       unread message count badge
                    // if (this.patients.length > 0) {
                    //   this.currentPatient = this.patients[0];
                    // }
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public openMainNotesWindow(e) {
        e.stopPropagation();
        const modalRef = this.modalService.open(NotesModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.notes = this.notes;
        modalRef.componentInstance._patientid = this.patientId;
        modalRef.componentInstance.patient = this.currentPatient;

        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.notes.notetext = receivedEntry.note;
            if (receivedEntry.success) {
                this.getPatientNotes();
            }
        });
    }

    public getPrescribedApps() {
        return this.patientService.getUserLinks(this.patientId).subscribe(
            (data) => {
                this.links = data.attributes;
                this.links = this.links.suggested.concat(this.links.global);
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public getPatientNotes() {
        return this.patientService.getPatientNotes(this.patientId, this.activeCareTeam.careteamid).subscribe(
            (data) => {
                if (data.attributes.notes.length > 0) {
                    this.notes = data.attributes.notes.filter(function (e) {
                        return e.status === "active";
                    });
                } else {
                    this.notes = [];
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public onDelete(app) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this app?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.appsService.unassignUser(this.patientId, app.appid).subscribe(
                    (data) => {
                        this.snackbar.show("Application removed for member.");
                        this.getPrescribedApps();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public onAddPrescription() {
        let newMedication = new Medications("", "", "", "", "", "", null, "", null, "", null);
        this.summaryData = {
            action: "add",
            patientId: this.patientId,
            summary: this.clinicalSummaryModel,
            medication: newMedication,
        };
        const modalRef = this.modalService.open(PrescriptionModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show("Prescription Added for patient.");
                this.getPrescribedApps();
            } else {
                this.snackbar.show(receivedEntry.message, "danger");
            }
        });
    }
    public onEditPrescription(medication, index, e) {
        e.stopPropagation();
        this.summaryData = {
            action: "edit",
            patientId: this.patientId,
            summary: this.clinicalSummaryModel,
            medication: medication,
            index: index,
        };
        const modalRef = this.modalService.open(PrescriptionModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show("Prescription Updated for patient.");
            } else {
                this.snackbar.show(receivedEntry.message, "danger");
            }
        });
    }
    public onViewPrescription(medication, e) {
        e.stopPropagation();

        this.summaryData = {
            action: "view",
            medication: medication,
        };
        const modalRef = this.modalService.open(PrescriptionViewModalComponent, { size: "lg", windowClass: "modal-center", backdrop: "static" });
        modalRef.componentInstance.SummaryData = this.summaryData;
    }
    public onDeletePrescription(index, e) {
        e.stopPropagation();

        this.clinicalSummaryModel.prescriptions.medications.splice(index, 1);
        this.addUpdateSummary();
        this.snackbar.show("Prescription deleted for patient.");
    }
    public addUpdateSummary() {
        let updatedData = this.clinicalSummaryModel;
        let userId = this.patientId;
        return this.patientService.updateSummary(updatedData, userId).subscribe(
            (data) => {
                // console.log(data);
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public videoChat() {
        const date = new Date();
        const modalRef = this.modalService.open(VideoChatModalComponent, { size: "lg", backdrop: "static" });
        this.currentPatient.careteamid = this.activeCareTeam.careteamid;
        modalRef.componentInstance.RoomName = this.currentPatient.patientid + "-" + date.getTime();
        modalRef.componentInstance.Identity = this.activeCareTeam.careteamname;
        modalRef.componentInstance.Patient = this.currentPatient;
    }
    public getWaitingRooms() {
        return this.waitingRoomService.getWaitingRooms(this.activeCareTeam["careteamid"]).subscribe(
            (data) => {
                let waitingRooms = data[0].attributes.list;
                this.waitingRooms = waitingRooms.filter((x) => x.wrid !== this.wrid);
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public onMovePatient() {
        this.patientData = {
            action: "move",
            page: "PatientDashboard",
            wruid: this.wruid,
            fromwrid: this.wrid,
            waitingRooms: this.waitingRooms,
            patientid: this.patientId,
        };
        const modalRef = this.modalService.open(WaitingRoomMoveModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.PatientData = this.patientData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.moveModalResponse(receivedEntry);
        });
    }
    public moveModalResponse(event) {
        if (event.success && event.action === "move") {
            this.wrid = event.towrid;
            this.getWaitingRoomPatientDetails(event.towrid);
            this.snackbar.show(event.message);
        } else if (event.success && event.action === "resolved") {
            this.snackbar.show(event.message);
        } else if (event.success && event.action === "refer") {
            let membershipid = this.sessionService.getCurrentUserMembershipId();
            this.patientReferData = {
                action: "refer",
                page: "PatientDashboard",
                wruid: this.wruid,
                wrid: this.wrid,
                membershipid: membershipid,
                patientId: this.patientId,
            };
            const modalRef = this.modalService.open(WaitingRoomReferModalComponent, { windowClass: "modal-small-center", backdrop: "static" });
            modalRef.componentInstance.PatientReferData = this.patientReferData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.referModalResponse(receivedEntry);
            });
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    public referModalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    public getDisplayOrder(panel) {
        if (this.displayOrder.find((o) => o.label === panel)) {
            return this.displayOrder.find((o) => o.label === panel).order;
        }
    }
    public isOpenedTab(name) {
        this.helper.isOpenedTab(name);
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            this.tabOrderSettings.forEach((element, index) => {
                element.order = index + 1;
            });
            let tabSettings = this.sessionService.patientDashboardTabSetting;
            tabSettings.tabOrderSettings = this.tabOrderSettings;
            this.sessionService.patientDashboardTabSetting = tabSettings;
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    blur(status) {
        this._blurStatus = !status;
        if (status) {
            this.containerElement.nativeElement.setAttribute("class", "blur");
            this.leftContainerElement.nativeElement.setAttribute("class", "blur left-col");
        } else {
            this.containerElement.nativeElement.setAttribute("class", null);
            this.leftContainerElement.nativeElement.setAttribute("class", "left-col");
        }
    }
}
