<div class="modal-header">
    <h5 class="modal-title">Incidents</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="container-fluid modal-body">
    <div class="p-3">
        <div class="filter-section">
            <div class="filter-section-left">
                <i style="cursor: pointer; font-size: 20px" class="fa fa-redo-alt left-chevron"
                    (click)="onRefresh()"></i><input class="filter-input" name="searchTerm"
                    [(ngModel)]="tableService.searchTerm" placeholder="Search..." />
            </div>
        </div>
        <app-loader style="position: absolute; top: 200px; right: 45%; z-index: 100" class="loader" *ngIf="showLoader">
        </app-loader>
        <div *ngIf="!showLoader" class="table-incidents">
            <table class="table table-striped datatable">
                <thead>
                    <tr>
                        <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                            sortable="{{ (c.displayname === 'action' || c.displayname === 'Time' || c.displayname === 'ICD10' || c.displayname === 'CPT') ? '' : c.columnname }}"
                            (sort)="onSort($event)">
                            {{ c.displayname }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let s of resultList$ | async">
                        <td style="width:8%">
                            <span>{{ s?.date | date: "shortDate"}}</span>
                        </td>
                        <td style="width:6%">
                            <span>{{ s?.date | date: "shortTime"}}</span>
                        </td>
                        <td style="width:50%">
                            <div class="icd-rows" *ngFor="let reason of s?.generalsymptoms; let i = index">
                                <div class="incident-key">
                                    {{ s?.icdcodes[i] ? s?.icdcodes[i] : 'user' }}
                                </div>
                                <div class="incident-values" [innerHTML]="s?.generalsymptoms[i]">
                                </div>
                            </div>
                            <div class="icd-rows" *ngIf="s?.other">
                                <span>Other: {{ s?.other }}</span>
                            </div>

                        </td>
                        <td style="width:25%">
                            <div class="cpt-rows" *ngFor="let reason of s?.cptcodes; let i = index">
                                <div class="cpt-key">
                                    {{ s?.cptcodes[i] }}
                                </div>
                                <div class="cpt-values">
                                    {{ s?.cptexams[i] }}
                                </div>
                            </div>
                        </td>
                        <td style="width:8%">
                            <span>{{ s?.status }}</span>
                        </td>
                        <td style="width:3%">
                            <i (click)="onEditIncident(s); $event.stopPropagation();" class="fa fa-edit editincident"
                                aria-hidden="true"></i>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                    [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

                <select class="custom-select pagination"
                    style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                    [(ngModel)]="tableService.pageSize">
                    <option [ngValue]="5">5 items per page</option>
                    <option [ngValue]="50">50 items per page</option>
                    <option [ngValue]="100">100 items per page</option>
                </select>
            </div>
        </div>
    </div>
</div>