import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { CareTeamService } from "../services/careteam.service";
import { SessionService } from "../services/session.service";

@Injectable({
    providedIn: "root",
})
export class CareTeamsResolverService implements Resolve<any> {
    constructor(private careTeamService: CareTeamService, private sessionService: SessionService) {}

    resolve(): Observable<any> | Observable<never> {
        if (!(this.sessionService.isAdmin() || this.sessionService.isSuperAdmin())) {
            let userdata = this.sessionService.currentUser;
            return this.careTeamService.getCareTeamsByProvider(userdata["id"]);
        }
    }
}
