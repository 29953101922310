import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ChartService } from "../../services/chart.service";
import * as Highcharts from "highcharts";
import { GoalsService } from "../../../../src/app/services/goals.service";

@Component({
    selector: "app-show-goals-modal",
    templateUrl: "./show-goals-modal.component.html",
    styleUrls: ["./show-goals-modal.component.css"],
})
export class ShowGoalsModalComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;
    @Input() GoalsData: any;
    @Input() PatientId: string = "";
    @Input() showAllGoals: boolean = false;
    public goals: any;
    public _goaldata = [];
    public _graphData = [];
    public complianceGraphOptions = [];
    public datapointtype = "daily";
    constructor(public activeModal: NgbActiveModal, private chartService: ChartService, private goalsService: GoalsService) {}

    ngOnInit(): void {
        this.loadGoals();
    }

    public onCancel() {
        this.activeModal.close();
    }

    loadGoals(showDatatype = true) {
        let goals = this.GoalsData;
        this.goals = [];
        if (goals.length > 0) {
            for (let goal of goals) {
                goal.list.sort(function (a, b) {
                    let x = parseInt(a.gtime.replace(":", ""));
                    let y = parseInt(b.gtime.replace(":", ""));
                    return x - y;
                });
                //let _complianceGraphData = this.createDataForColumnGraph(goal.complianceGraph, goal);
                let goaltimename = goal.list.map((e) => e.goaltime.goaltimename).join(",");
                this.goals.push({
                    goalname: goal.goalname,
                    startdate: goal.list[0].startdate,
                    enddate: goal.list[0].enddate,
                    goaltarget: goal.list[0].goaltarget,
                    status: goal.list[0].graphenabled,
                    goalid: goal.list[0].goalid,
                    userid: goal.list[0].userid,
                    chart: this.chartService.drawGraph(goal, "column", showDatatype),
                    goaltypename: goal.list[0].goaltype.goaltypename,
                    // goaltimename: goaltimename.substring(0, goaltimename.length - 1),
                    goaltimename: goaltimename,
                    frequencies: goal.list[0].frequencies,
                    datatype: goal.list[0].datatype,
                    gdcid: goal.list[0].gdcid,
                    goaltypeid: goal.list[0].goaltypeid,
                    goaltimeid: goal.goaltime.goaltimeid,
                    associatedapp: goal.list[0].associatedapp != null ? goal.list[0].associatedapp.replace("app://", "") : "",
                    // complianceGraph: _complianceGraphData,
                });
            }
            this.goals.sort((a, b) => (a.goalname.toLocaleLowerCase() > b.goalname.toLocaleLowerCase() ? 1 : -1));
        }
        setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
        }, 500);
    }

    public createDataForColumnGraph(complianceGraph, goal) {
        let _data: any = [];
        let categories: any;
        goal.list.forEach((element) => {
            if (element.graphreport2.categories.length >= 7) {
                categories = this.chartService.getCategories(element.graphreport2.dateRange.slice(Math.max(element.graphreport2.categories.length - 7, 1)));
            } else {
                categories = this.chartService.getCategories(element.graphreport2.dateRange);
            }
        });
        let __data = [];
        let __categories = [];
        categories.forEach((element) => {
            let index = goal.complianceGraph.dateRange.findIndex((x) => x === element);
            __data.push(goal.complianceGraph.data[index]);
            __categories.push(goal.complianceGraph.categories[index]);
        });
        for (let i = 0; i < __categories.length; i++) {
            _data.push({ date: __categories[i], left: (i + 1) * 13, height: __data[i] });
        }
        return _data;
    }
    public getGraph(event) {
        return this.goalsService.goalByPatient(this.PatientId, this.showAllGoals ? "" : this.GoalsData[0].goalname, event.target.value).subscribe(
            (data) => {
                if (data[0].attributes) {
                    this.GoalsData = data[0].attributes || [];
                    let showDatatype = true;
                    if (event.target.value == "daily" || event.target.value == "monthly") {
                        this.loadGoals();
                    } else {
                        this.loadGoals(false);
                    }
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }
}
