<!--  -->
<div class="container-fluid">
    <div class="row mt-3 col-12">
        <div class="container-fluid">
            <div class="p-3">
                <div class="row">
                    <div class="col-5 form-group">
                        <label>Email:</label>
                        <input [(ngModel)]="email" type="text" class="form-control" (keyup.enter)="searchByEmail()" />
                    </div>
                    <div class="col-3">
                        <input [disabled]="email === ''" (click)="searchByEmail()" type="button" value="Search" style="margin-top: 24px" class="btn btn-primary" />
                    </div>
                  
                </div>
            </div>
            <div class="p-3">
                <div *ngIf="user" class="row form-group">
                    <div class="col-12">
                        <span class="subheader">user details</span>
                        <table class="table table-hover mt-3" style="text-align: left">
                            <thead>
                                <tr>
                                    <th scope="col">User Id</th>
                                    <th scope="col">First Name</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Role(s)</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ user.userid }}</td>
                                    <td>{{ user.firstname }}</td>
                                    <td>{{ user.lastname }}</td>
                                    <td>{{ roleName(user.userRoles?.join(",")) }}</td>
                                    <td>
                                        <input type="button" class="btn btn-primary ml-2" (click)="getanalytics(user.userid)" value="Analytics" />                                    
                                        <div style="float: left" *ngIf="user?.userRoles">
                                            <input *ngIf="user.userRoles.includes('user')" type="button" class="btn btn-primary ml-2" (click)="invite()" value="Invite" />
                                        </div>
                                        <input type="button" class="btn btn-primary ml-2" (click)="delete(user.userid)" value="Delete" />
                                        <input *ngIf="!user?.lockoutenabled" (click)="disable(user.userid)"  type="button" value="Block" class="btn btn-primary ml-2" />
                                        <input *ngIf="user?.lockoutenabled" (click)="enable(user.userid)"  type="button" value="Unblock" class="btn btn-success ml-2" />
                                   
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div *ngIf="user?.telerxs" class="row">
                    <div class="col-12 form-group">
                        <span class="subheader">Teleremedies</span>
                        <table class="table table-hover mt-3" style="text-align: left">
                            <thead>
                                <tr>
                                    <th scope="col">Telerx Id</th>
                                    <th scope="col">Membership Id</th>
                                    <th scope="col">Telerx Type</th>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let t of user.telerxs">
                                    <td>{{ t.telerxid }}</td>
                                    <td>{{ t.membershipid }}</td>
                                    <td>{{ t.telerxtype }}</td>
                                    <td>{{ t.name }}</td>
                                    <td>
                                        <input
                                            *ngIf="clio_careNav.toLocaleLowerCase() !== t.membershipid"
                                            type="button"
                                            class="btn btn-primary"
                                            value="Unsubscribe"
                                            (click)="removeUserFromTeleRx(t.telerxid)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="user?.userRoles">
                <div *ngIf="user.userRoles?.includes('user')" class="p-3">
                    <div class="row">
                        <div class="col col-4">
                            <div class="form-group">
                                <label>TeleRemedy Type:</label>
                                <select [(ngModel)]="selectedType" (change)="getTeleRx($event)" class="form-control">
                                    <option value="">Please select</option>
                                    <option value="application">Application</option>
                                    <option value="service">Service</option>
                                </select>
                            </div>
                        </div>
                        <div class="col col-4">
                            <div class="form-group">
                                <label>TeleRemedies</label>
                                <select [disabled]="teleremedies.length === 0" [(ngModel)]="selectedTeleRxId" (change)="selectedTeleRx($event)" class="form-control">
                                    <option value="">Please select</option>
                                    <option [value]="t.telerxid" *ngFor="let t of teleremedies">{{ t.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col col-2">
                            <div class="form-group">
                                <label>&nbsp;</label>

                                <input style="margin-top: 22px" *ngIf="selectedTeleRxId" type="button" class="btn btn-primary" (click)="addUserToTeleRx()" value="Add" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
