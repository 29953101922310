<div class="modal-body">
    <h5 class="modal-title">Send a Test</h5>

    <form [formGroup]="campaignForm">
        <div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center align-items-center">
            <app-loader></app-loader>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Email address:</label>
            </div>
            <div class="col-9">
                <input ngbAutofocus formControlName="email" type="text" required class="form-control" autofocus
                    [ngClass]="{ 'is-invalid': submitted && form.email.errors }" placeholder="email@email.com" />
            </div>
        </div>
        <div class="text-center">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            <button [disabled]="showLoader" type="button" class="btn btn-primary" (click)="onSave()">Send</button>
        </div>
    </form>



</div>