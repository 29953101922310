<div class="container-fluid p-0">
    <div class="row">
        <div class="col-6">
            <div id="patientSearch" class="search-div">
                <input type="text" placeholder="Search Member" [(ngModel)]="search" class="form-control search" />
                <img src="/assets/msx/search.svg">
            </div>
        </div>
    </div>

    <div class="inbox_msg">
        <div class="inbox_people">

            <div class="inbox_chat scroll" smartHeight="calc(100vh - (#navbar + #patientSearch + #prioritySearch))">
                <div #patientList
                    [ngClass]="{ active: thread?.patientid === currentPatient?.patientid, chat_list: thread !== null }"
                    *ngFor="let thread of patients | grdFilter: { authorname: search }:false | grdFilter: { priority: priority_search['tag'] }:false | orderByReadMessages; let i = index">
                    <!-- | orderBy: 'provider_unread_messages_count':false:'datetimeutc':false; -->
                    <div *ngIf="thread" (click)="setCurrentPatient(thread)" class="chat_people">
                        <span *ngIf="thread.provider_unread_messages_count !== 0"
                            class="badge badge-danger unread-count">{{ thread.provider_unread_messages_count }}</span>

                        <div class="chat_img">
                            <img [src]="thread.patient_avatar !== null ? thread.patient_avatar : '../../assets/images/doctor.png'"
                                alt="" (error)="onError()" />
                        </div>
                        <div class="chat_ib">
                            <div class="chat-right">
                                <!-- <h5>
                                    <span class="chat_date">{{ thread.datetimeutc | date: "MMM d, h:mm:ss a" }} </span>
                                </h5> -->
                            </div>
                            <div class="chat-left">
                                <h5>{{ thread.authorname }}</h5>
                            </div>
                            <p
                                [innerHTML]="thread.message !== null ? (thread.message| filterMessage: thread.message): thread.messagetype">
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="search" class="row advanced-search">
                    <div class="col-12" style="text-align: center; padding-top: 30px">
                        <a class="btn btn-primary" href="" (click)="$event.preventDefault(); patientSearch()">Search
                            More</a>
                        &nbsp;<a class="btn btn-primary" href=""
                            (click)="$event.preventDefault(); resetSearch()">Reset</a>
                    </div>
                    <app-loader style="margin: 0px auto" *ngIf="showSearchLoader"></app-loader>
                    <!-- <img style="height: 25px" *ngIf="showSearchLoader" style="margin: 0px auto" [src]="environment.loadingImg" /> -->
                    <div *ngIf="noResultFound" style="text-align: center; margin: 10px"
                        class="col-12 card card-body bg-light">No Result Found.</div>
                </div>
            </div>
        </div>
        <app-chat-window [patient]="currentPatient" [patients]="patients" (updateThreads)="getThreadList()">
        </app-chat-window>
    </div>
</div>