import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { AnalyticsService } from "../services/analytics.service";
import { CommunicationService } from "../../app/services/communications.service";
import { UsersService } from "./users.service";
import { SessionService } from "./session.service";

@Injectable()
export class AuthService {
    private handler = "/user/";
    constructor(
        private avcHttp: AvcHttp,
        private helper: Helper,
        private analyticsService: AnalyticsService,
        private communicationService: CommunicationService,
        private userService: UsersService,
        private sessionService: SessionService
    ) { }

    public login(username: string, password: string, pin: string = "", login_with_MedStatix): Observable<any> {
        if (username === undefined || password === undefined) {
            return Observable.throw("Please provide credentials");
        } else {
            return new Observable((observer: Observer<any>) => {
                let headers = {
                    Authorization: "Basic " + window.btoa(username + ":" + password + ":" + pin),
                };
                let url = "";
                if (login_with_MedStatix) {
                    url = `${environment.uri}${this.handler}msxlogin?sourcetype=clio`;
                } else {
                    url = `${environment.uri}${this.handler}login`;
                }

                this.avcHttp.sendRequest("post", url, {}, headers, true).subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            this.sessionService.currentUser = body.data[0];
                            this.recordEvent("login_success", username + " Logged In Successfully!.");
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            this.recordEvent("login_fail", username + " failed to login.");
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
            });
        }
    }

    public sendReset(email: string): Observable<any> {
        let url = `${environment.uri}${this.handler}`;
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", url + email + "/password/forgot/v2", {}, {}, true).subscribe(
                (data) => {
                    // if(data === "user context or refreshtoken missing"){
                    //   observer.error(data);
                    // }
                    observer.next(data);
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public recordEvent(category: string, username: string) {
        let currentUser = this.sessionService.currentUser;
        if (currentUser != null) {
            let userId = currentUser.id;
            let data = [];
            this.analyticsService.recordEvent("providerdashboard_event", category, data, userId, username).subscribe(
                (data) => {
                    // console.log("saved");
                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }
    public logout(): Promise<any> {
        let messagePromise;
        let pushUser = JSON.parse(localStorage.getItem("pushUser"));
        if (pushUser !== null) {
            messagePromise = new Promise((resolve, reject) => {
                this.communicationService.pushUnSubscribe(pushUser.token, pushUser.careteamid).subscribe(
                    (data) => {
                        console.log("user unsubscribed");
                        resolve(null);
                    },
                    (error) => {
                        console.log(error[0].detail);
                        resolve(null);
                    }
                );
            });
        } else {
            messagePromise = Promise.resolve();
        }

        return messagePromise.then(() => {
            this.recordEvent("LogOut", this.sessionService.currentUser?.attributes.email);
            this.sessionService.clearLocalStorageAndSession();
        });
    }

    public getTwoFactorCode(): Observable<any> {
        let currentUser = this.sessionService.currentUser;
        return new Observable((observer: Observer<any>) => {
            let url = `${environment.uri}/msx/TwoFactorRegister/v2?email=${encodeURIComponent(currentUser.attributes.email)}`;
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data[0].attributes.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public enableTwoFactorAuth(pin): Observable<any> {
        let currentUser = this.sessionService.currentUser;
        let data = JSON.stringify({
            email: currentUser.attributes.email,
            pin: pin,
        });
        return new Observable((observer: Observer<any>) => {
            let url = `${environment.uri}/msx/TwoFactorEnable/v2`;
            this.avcHttp.sendRequest("post", url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        // Update current user data
                        currentUser.attributes.twofactorenabled = true;
                        this.sessionService.currentUser = currentUser;

                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public disableTwoFactorAuth(pin): Observable<any> {
        let currentUser = this.sessionService.currentUser;
        let data = JSON.stringify({
            email: currentUser.attributes.email,
            pin: pin,
        });
        return new Observable((observer: Observer<any>) => {
            let url = `${environment.uri}/msx/TwoFactorDisable/v2`;
            this.avcHttp.sendRequest("post", url, data, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        // Update current user data
                        currentUser.attributes.twofactorenabled = false;

                        this.sessionService.currentUser = currentUser;
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    private parseJson(str) {
        let data;

        try {
            data = JSON.parse(str);
        } catch (e) {
            data = str;
        }

        return data;
    }
    public fetchFormData(submissionId) {
        return new Promise((resolve, reject) => {
            let url = environment.forms_server_uri + "/forms?submissionid=" + submissionId;
            this.avcHttp.sendRequest("GET", url).subscribe(
                (data) => {
                    if (data.status === 401) {
                        console.log("Error");
                    } else {
                        let res = this.parseJson(data.response);
                        if (data.status >= 200 && data.status <= 300) {
                            if (res.data && res.data[0] && res.data[0].attributes && res.data[0].attributes[0]) resolve(res.data[0].attributes[0]);
                        } else {
                            reject(res.errors);
                        }
                    }
                },
                (err) => {
                    reject(err);
                    console.log("Unexpected error occured! Please try again...", err);
                }
            );
        });
    }

    public getUserSessionKey(): Observable<any> {
        let currentUser = this.sessionService.currentUser;
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/account/session/" + currentUser.id;
            this.avcHttp.sendRequest("get", url, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        // Update current user data
                        currentUser.attributes.twofactorenabled = false;
                        this.sessionService.currentUser = currentUser;
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public onboarding(Authorization): Observable<any> {
        if (Authorization === undefined) {
            window.location.href = "login";
        } else {
            return new Observable((observer: Observer<any>) => {
                let headers = {
                    Authorization: Authorization,
                };
                let url = `${environment.uri}${this.handler}login`;

                this.avcHttp.sendRequest("post", url, {}, headers, true).subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            this.sessionService.currentUser = body.data[0];
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
            });
        }
    }

    public auto_login(tokenid: string = "", pin = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/user/login/" + tokenid;
            if (pin !== "") {
                url = environment.uri + "/user/login/" + tokenid + "?pin=" + pin;
            }
            this.avcHttp.sendRequest("get", url, {}, {}, true).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        this.sessionService.currentUser = body.data[0];
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public get_current_user_by_token(token: string = ""): Promise<boolean> {
        let headers = {
            Authorization: "Bearer " + token,
        };
        let url = `${environment.uri}${this.handler}`;

        return new Promise<boolean>((resolve, reject) => {
            this.avcHttp.sendRequest("GET", url, {}, headers, true).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data).data;
                    // localStorage.setItem("token", data[0].attributes.token);

                    if (!data) {
                        resolve(false);
                    }

                    this.sessionService.setCurrentUserRole(data[0].attributes.roles);
                    this.sessionService.currentUser = data[0];

                    //explicitly set token as it's not returned by this endpoint but is expected to be here
                    this.sessionService.currentUser.attributes.token = token;

                    return this.userService.getUserSetting(data[0].id).subscribe(
                        (settingsData) => {
                            this.sessionService.currentUserSettings = settingsData[0].attributes;

                            if (!(this.sessionService.isAdmin() || this.sessionService.isSuperAdmin())) {
                                let _defaultCareTeam;
                                if (this.sessionService.currentUserSettings.currentcareteamid) {
                                    _defaultCareTeam = data[0].attributes.careteam.careteams.find((e) => e.careteamid === this.sessionService.currentUserSettings.currentcareteamid);
                                }
                                if (!_defaultCareTeam) {
                                    _defaultCareTeam = data[0].attributes.careteam.careteams.find((e) => e.defaultcareteam === true);
                                }
                                if (!_defaultCareTeam) {
                                    _defaultCareTeam = data[0].attributes.careteam.careteams[0];
                                }

                                this.sessionService.activeCareTeam = _defaultCareTeam;
                            }

                            resolve(true);
                        },
                        (err) => {
                            resolve(true);
                        }
                    );
                },
                (error) => {
                    resolve(false);
                }
            );
        });
    }

    public validatePwdLink(userId, token): Observable<any> {
        let currentUser = this.sessionService.currentUser;
        return new Observable((observer: Observer<any>) => {
            let url = environment.uri + "/account/reset-password/" + userId + "/v2?token=" + token;
            this.avcHttp.sendRequest("get", url, {}, {}, true).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public resetPassword(formData, tokenid, userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                tokenid: tokenid,
                newpassword: formData.password,
                confirmpassword: formData.confirmPassword

            });

            this.avcHttp.sendRequest("post", environment.uri + "/account/resetPassword/" + userId, data, {}, true).subscribe(
                (data) => {
                    data = this.helper.ParseToJson(data);
                    if (data.data !== undefined && data.data[0]) {
                        observer.next(data.data[0]);
                        observer.complete();
                    } else {
                        observer.error(data.errors);
                        observer.complete();
                    }
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }
}
