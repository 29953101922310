<div class="modal-body">
    <div class="category-header">
        <label>{{ Context.edit ? "Edit Employer" : "Add Employer" }}</label>
    </div>
    <div class="roomname">
        <label>Employer Name</label>
        <input type="text" [(ngModel)]="employerName" class="form-control" />
    </div>
    <p *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <div class="form-group">
        <button type="button" class="btn btn-primary move-buttons" (click)="Context.edit ? update() : onAdd()">{{ Context.edit ? "Update" : "Add" }}</button>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onCancel()">Cancel</button>
    </div>
</div>

<!-- <div class="modal-content">
    <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">{{ Context.edit ? "Edit Employer" : "Add Employer" }}</h5>
        <button type="button" class="close" (click)="onCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                Employer Name:
                <input type="text" [(ngModel)]="employerName" class="form-control mt-2" />
            </div>
        </div>
        <ngb-alert *ngIf="errorMessage.length" [dismissible]="false" [type]="'danger'" class="mb-0">
            {{ errorMessage }}
        </ngb-alert>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="Context.edit ? update() : onAdd()">{{ Context.edit ? "Update" : "Add" }}</button>
        <button type="button" id="closeBtn" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    </div>
</div> -->
