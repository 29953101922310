<div class="modal-content">
	<div class="modal-header bg-primary text-white">
		<h5 class="modal-title">Add New Onboarding</h5>
		<button type="button" class="close" (click)="onCancel()" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col col-12">
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span class="input-group-text">Email:</span>
					</div>
					<input class="form-control" [(ngModel)]="user.email" />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-6">
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span class="input-group-text">First Name:</span>
					</div>
					<input class="form-control" [(ngModel)]="user.firstname" />
				</div>
			</div>
			<div class="col col-6">
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span class="input-group-text">Last Name:</span>
					</div>
					<input class="form-control" [(ngModel)]="user.lastname" />
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col col-6">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">Gender:</span>
					</div>
					<select class="selectpicker form-control" [(ngModel)]="user.gender">
						<option value="male">Male</option>
						<option value="female">Female</option>
					</select>
				</div>
			</div>
			<div class="col col-6">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">Date of Birth:</span>
					</div>
					<input type="date" class="form-control" [(ngModel)]="user.dob" />
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col col-6">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">ZIP Code:</span>
					</div>
					<input class="form-control" [(ngModel)]="user.postalcode" />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-12">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">Care Team:</span>
					</div>
					<select class="selectpicker form-control" [(ngModel)]="user.careteamid">
						<option value="">Choose a Care Team</option>
						<option *ngFor="let careteam of careTeams" [value]="careteam.careteamid">{{
							careteam.careteamname }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<ngb-alert *ngIf="errmsg.length" [dismissible]="false" [type]="'danger'" class="mb-0">
			{{errmsg}}
		</ngb-alert>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="onAddUser()">Add</button>
		<button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
	</div>
</div>