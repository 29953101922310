import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class EmployersService {
    private url = environment.uri;
    careteamId = "";
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) {}

    public getEmployers(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/settings/employer/master").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public getImprovement(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/settings/improvement/master").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public addEmployer(name): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                membershipid: this.sessionService.getCurrentUserMembershipId(),
                employername: name,
            });
            this.avcHttp.sendRequest("post", this.url + "/settings/employer/master", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteEmployer(name): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                membershipid: this.sessionService.getCurrentUserMembershipId(),
                employername: name,
            });
            this.avcHttp.sendRequest("delete", this.url + "/settings/employer/master", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public updateEmployer(oldName, newName): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                membershipid: this.sessionService.getCurrentUserMembershipId(),
                employernameold: oldName,
                employernamenew: newName,
            });
            this.avcHttp.sendRequest("put", this.url + "/settings/employer/master", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
}
