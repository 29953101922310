import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class CareTeamService {
    private url = environment.uri;
    careteamId = "";
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) { }

    public getPatients(name = "", limit = 1000000, pageno = "1", orderby = "firstname", order = "asc"): Observable<any> {
        let careTeam = this.sessionService.activeCareTeam;
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        if (selectedGroup !== "") {
            selectedGroup = selectedGroup.toLowerCase();
        }
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest(
                    "get",
                    this.url + "/careteam/patient/list?careteamid=" + careTeam.careteamid + "&orderby=" + orderby + "&order=" + order + "&limit="
                    + limit + "&pageno=" + pageno + "&name=" + name + "&membershipid=" + selectedGroup
                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public getCareTeams(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/careteam").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public getCareTeamsByProvider(userid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/careteam/provider/" + userid).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public assignPatientToCareTeam(patientId: any, careTeamIds): Observable<any> {
        let data = JSON.stringify({
            careteamid: careTeamIds,
            userid: patientId,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/careteam/patient", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public assignProviderToCareTeam(providerid, careTeamIds): Observable<any> {
        // this should support multiple careteam ids
        let data = JSON.stringify({
            careteamids: careTeamIds,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/careteam/provider/" + providerid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getCareteams(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/careteam", {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public deleteCareTeam(careteamid: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", environment.uri + "/careteam" + "/" + careteamid).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public updateCareteam(careTeam: any): Observable<any> {
        let data = JSON.stringify({
            careteamname: careTeam.careteamname,
            address1: careTeam.address1,
            address2: careTeam.address2,
            city: careTeam.city,
            state: careTeam.state,
            postalcode: careTeam.postalcode,
            country: "US",
            lat: careTeam.lat,
            lon: careTeam.lng,
            grouptype: "production",
            covidtype: careTeam.covidtype,
            covidfacility: careTeam.covidfacility,
            defaultcareteam: careTeam.defaultcareteam,
            phonenumber: careTeam.phonenumber,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", environment.uri + "/careteam" + "/" + careTeam.careteamid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public createCareteam(careTeam: any): Observable<any> {
        let data = JSON.stringify({
            careteamname: careTeam.careteamname,
            address1: careTeam.address1,
            address2: careTeam.address2,
            city: careTeam.city,
            state: careTeam.state,
            postalcode: careTeam.postalcode,
            country: "US",
            lat: careTeam.lat,
            lon: careTeam.lng,
            covidtype: careTeam.covidtype,
            covidfacility: careTeam.covidfacility,
            defaultcareteam: careTeam.defaultcareteam,
            phonenumber: careTeam.phonenumber,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/careteam", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public assignToCareTeam(careteamid: string, providers: any): Observable<any> {
        let data = JSON.stringify({
            careteamid: careteamid,
            providerids: providers,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", environment.uri + "/careteam" + "/provider", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public unAssignToCareTeam(careteamid: any, providers: any): Observable<any> {
        let data = JSON.stringify({
            careteamid: careteamid,
            providerids: providers,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", environment.uri + "/careteam" + "/provider/remove", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.errors === undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getCareteamProviders(careteamid: string = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/careteam" + "/provider/list?careteamid=" + careteamid, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.next(body.errors[0].detail);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
}
