import { Component, Input, OnInit } from '@angular/core';
import { MSXReportService } from "../../../services/msx_report.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
import * as __ from 'lodash';

@Component({
  selector: 'app-patient-visits-data',
  templateUrl: './patient-visits-data.component.html',
  styleUrls: ['./patient-visits-data.component.scss']
})
export class PatientVisitsDataComponent implements OnInit {
  visitsData: any;
  specialitiesData: any;
  PatientVisitsSettings = {};
  SpecialitiesSettings = {};
  selectedGroup = "";
  @Input() isFavorite = false;
  constructor(
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.selectedGroup = localStorage.getItem("selectedGroup") || ""
    this.getSpecialities();
    this.getPatientVisits();
  }

  public getPatientVisits() {
    this.PatientVisitsSettings = {
      title: "Total Visits vs. Last Year",
      xaxisLabel: "Months",
      graphType: "column"
    }
    this.msxReportService.GetVisitYearData(this.selectedGroup).subscribe(
      (data) => {
        this.visitsData = data[0].attributes.data;
        this.visitsData = __.orderBy(this.visitsData, 'date', 'asc');

        let selectedPractices = []
        _.where(this.visitsData, { name: 'Selected Practices' }).forEach(element => {
          selectedPractices.push([element.date, element.count])
        });
        let allPractices = []
        _.where(this.visitsData, { name: 'All Practices' }).forEach(element => {
          allPractices.push([element.date, element.count])
        });


        let _data = [{
          type: 'column',
          name: 'Selected Practices',
          data: selectedPractices
        },
        {
          type: 'line',
          name: 'All Practices',
          data: allPractices
        }
        ]

        // let categories = _.chain(this.visitsData).pluck('date').value();
        this.visitsData = {
          charType: "demo",
          chartBenchmarks: [],
          chartMetric: "",
          chartSeries: _data,
          chartTitle: "",
          //categories: categories
        }
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

  public getSpecialities() {
    this.SpecialitiesSettings = {
      title: "Total Visits for All Specialties (Month to Date)",
      xaxisLabel: "Specialty",
      graphType: "column"
    }
    this.msxReportService.getVisitWithSpecialities(this.selectedGroup).subscribe(
      (data) => {
        this.specialitiesData = data[0].attributes.data;


        let categories = _.chain(this.specialitiesData).pluck('specialty').value();
        let selectedPractices = []
        _.where(this.specialitiesData, { practice: 'Selected Practices' }).forEach(element => {
          selectedPractices.push([element.specialty, element.count])
        });
        let allPractices = []
        _.where(this.specialitiesData, { practice: 'All Practices' }).forEach(element => {
          allPractices.push([element.specialty, element.count])
        });
        let _data = [{
          type: 'column',
          name: 'Selected Practices',
          data: selectedPractices
        }
          ,
        {
          type: 'line',
          name: 'All Practices',
          data: allPractices
        }
        ]


        this.specialitiesData = {
          charType: "demo",
          chartBenchmarks: [],
          chartMetric: "",
          chartSeries: _data,
          chartTitle: "",
          categories: categories,
          name: "specialty",
        }

      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }

}
