/******************************************************************************
avc-http.ts

Author: Ravi

Description: This library provides HTTP Ajax helper functions.

Copyright (C) 2015-2017 Aspire Ventures 

This program is copyrighted software: you can redistribute it and/or
modify it under the terms of the Aspire General Community License as
published by Aspire Ventures, either version 1 of the License, or (at 
your option) any later version. 

This program is distributed in the hope that it will be useful, but 
WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.See the 
Aspire General Community License for more details.

You should have received a copy of the Aspire General Community 
License along with this program. If not, see: 
<http://ACP.aspirevc.com/licenses/>.
******************************************************************************/
import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SessionService } from "../services/session.service";

@Injectable()
export class AvcHttp {
    constructor(private http: HttpClient, private sessionService: SessionService) { }
    /*
 method - GET, PUT, POST etc.
 headers - Object containing any headers to be sent
 url - Full url to acccess
 data - payload to send (if not a string, it is coverted to JSON)
 returns a {Observable}
 */
    public refreshHandler = "/user/refresh";
    public sendRequest(method: string, url: string): any;
    public sendRequest(method: string, url: string, data: any, anonymous?: boolean): Observable<HttpResponse>;
    public sendRequest(method: string, url: string, data: any, headers: any, anonymous?: boolean): Observable<HttpResponse>;
    public sendRequest(method: string, url: string, data: any, headers: any, anonymous?: boolean, type?: string, isFile?: boolean, responseType?: string): Observable<HttpResponse>;
    public sendRequest(method: string, url?: string, data: any = "", headers?: any, anonymous: boolean = false, type: string = "", isFile: boolean = false, responseType = "") {
        let currentUser = this.sessionService.currentUser;
        if (anonymous) {
            return this.processRequest(method, headers, url, data, anonymous, type, isFile);
        } else if (!anonymous && currentUser == null) {
            return new Observable((observer) => {
                observer.next("user context or refreshtoken missing");
                observer.complete();
            });
        } else if (currentUser.attributes.token.length > 0 && this.isValidToken(currentUser.attributes.token)) {
            return this.processRequest(method, headers, url, data, anonymous, type, isFile);
        } else {
            // Refreshed token and retry request
            return this.refreshAuthenticationToken(method, headers, url, data, anonymous, type);
        }
    }

    private refreshAuthenticationToken(method, headers, url, data, anonymous, type): any {
        var currentUser = this.sessionService.currentUser;
        if (currentUser === undefined || currentUser.attributes.refreshtoken === undefined || currentUser.attributes.refreshtoken === "") {
            return Observable.throw("user context or refreshtoken missing");
        }
        return new Observable((observer: Observer<any>) => {
            this.http
                .get(`${environment.uri}${this.refreshHandler}`, {
                    headers: {
                        Authorization: `Bearer ${currentUser.attributes.refreshtoken}`,
                        "Content-Type": "application/json",
                    },
                })
                .subscribe(
                    (body: any) => {
                        if ((body as any).data !== undefined && (body as any).data[0].attributes !== undefined) {
                            //set new token to current user
                            this.sessionService.currentUser = body.data[0];

                            this.processRequest(method, headers, url, data, anonymous, type).subscribe((data) => {
                                observer.next(data);
                                observer.complete();
                            });
                        } else {
                            observer.next("error");
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(new Error(err));
                        observer.complete();
                    }
                );
        });
    }

    private isValidToken(token: string) {
        if (token === undefined) {
            return false;
        } else {
            let base64Url = token.split(".")[1];
            let base64 = base64Url.replace("-", "+").replace("_", "/");
            let tokenObj = JSON.parse(window.atob(base64));
            let currentDateTime = new Date();
            let timeDiff = Math.abs(tokenObj.exp.valueOf() - currentDateTime.valueOf());
            //get time difference between tokenExp and current time
            let lifeTimeInPercentage = (new Date().getTime() / 1000 - tokenObj.iat.valueOf()) / (tokenObj.exp.valueOf() - tokenObj.iat.valueOf());
            if (lifeTimeInPercentage * 100 > 95) {
                return false;
            }
            return true;
        }
    }

    private processRequest(method: string, headers: any, url: string, data: any, anonymous: boolean, type: string = "", isFile: boolean = false, responseType = ""): any {
        var req: any;
        var currentObj = this;
        return new Observable((observer: Observer<any>) => {
            if (typeof method !== "string") {
                observer.next(new Error("AVC.http: sendRequest failed: METHOD not specified"));
                observer.complete();
            }
            method = method.toUpperCase();
            if (typeof url !== "string") {
                observer.next(new Error("AVC.http: sendRequest failed: URL not specified"));
                observer.complete();
            }
            /* headers and data can be empty but provide defaults */
            headers = headers || {};
            req = new XMLHttpRequest();
            req.open(method, url, true);
            //* set any requested headers by iterating the headers object */
            for (var key in headers) {
                if (headers.hasOwnProperty(key)) {
                    req.setRequestHeader(key, headers[key]);
                }
            }
            if (!isFile) {
                if (typeof data === "string") {
                    data = data || "";
                } else {
                    data = JSON.stringify(data);
                }
                //adding Bearer tokens.
                req.setRequestHeader("Content-Type", "application/json");
            }

            if (isFile && responseType === "blob") {
                req.responseType = 'blob' as 'json';
            }

            if (!anonymous) {
                let currentUser = this.sessionService.currentUser;
                if (currentUser.attributes.token.length > 0) {
                    // console.log("AVC-HTTPS");
                    // console.log(currentUser.attributes);
                    req.setRequestHeader("Authorization", `Bearer ${currentUser.attributes.token}`);
                } else {
                    observer.error("unauthorized access or no user");
                    observer.complete();
                }
            }
            /* set up onload handler */
            if (type === "file") {
                req.responseType = "arraybuffer";
            }
            req.onload = function () {
                observer.next(req);
                observer.complete();
            };
            /* set up onerror handler */
            req.onerror = function () {
                observer.next(req);
                observer.complete();
            };
            req.send(data);
        });
    }
}

export class HttpResponse {
    public data: any;
    public err: any;
    public response: any;
    public errors: any;
    public status: any;
}
