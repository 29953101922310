<div class="modal-body">
    <div class="header">
        <label>View Options</label>
    </div>
    <div class="view-columns">
        <div class="column-list" *ngFor="let item of columns; let i = index">
            <input id="{{ item.name }}" type="checkbox" [(ngModel)]="columns[i].show" [ngModelOptions]="{ standalone: true }" />
            <label for="{{ item.name }}" class="column-label">{{ item.display }}</label>
            <hr />
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
        <div class="button-view">
            <button type="button" class="btn btn-primary button-blue" (click)="onSave()">Save</button>
        </div>
        <div class="button-view">
            <button type="button" class="btn btn-secondary button-grey" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</div>
