import { Component, OnInit } from "@angular/core";
import { CareTeamService } from "../../../services/careteam.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { PatientService } from "../../../services/patients.service";
import { EmployersService } from "../../../services/employer.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { UsersService } from "../../../services/users.service";
import { SessionService } from "../../../services/session.service";
import { RoleService } from "../../../services/role.service";
import { CheckboxItem } from "../../../shared/checkbox-group/checkbox-item";
import * as passwordGenerator from "generate-password-browser";
import { TeleRXService } from "../../../services/teleRX.service";
import { OnboardingService } from "../../../services/onboarding.service";

@Component({
    selector: "app-details",
    templateUrl: "./details.component.html",
    styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
    public employers = [];
    public patientId: string = "";
    public careteams = new Array<CheckboxItem>();
    public selectedcareteamids = new Array<string>();
    public futureDateError: boolean;
    public roles = [];
    public patientRole: string = "";
    public userData: any = {};
    public userSettings: any = {};
    public isNew: boolean = false;
    private roleChanged = false;
    private careteamChanged = false;
    private nocareteamid: string = "";

    // Random password generator options for new user with role "user"
    private randomPasswordOptions = {
        length: 12,
        numbers: true,
        uppercase: true,
        strict: true,
    };

    constructor(
        private careTeamService: CareTeamService,
        private snackbar: SnackbarService,
        private patientService: PatientService,
        private employersService: EmployersService,
        private route: ActivatedRoute,
        public sessionService: SessionService,
        public router: Router,
        private usersService: UsersService,
        public roleService: RoleService,
        private onboardingService: OnboardingService,
        private teleRXService: TeleRXService
    ) { }
    async ngOnInit() {
        this.patientId = this.route.snapshot.queryParamMap.get("id");
        this.isNew = !this.patientId;

        await this.getEmployers();

        if (this.sessionService.isAdmin() || this.sessionService.isProviderAdmin()) {
            await this.getCareTeams();
            await this.getRoles();
        }
        if (this.patientId) {
            await this.getUserSettings(this.patientId);
            await this.getUserDetails(this.patientId);
        } else {
            this.patientRole = this.roleService.ROLE_ID_USER;
        }
    }

    private getRoles(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.roleService.getRoles().subscribe(
                (data) => {
                    this.roles = data;

                    resolve();
                },
                (err) => {
                    console.log(err);
                    reject();
                }
            );
        });
    }

    private getCareTeams(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.careTeamService.getCareTeams().subscribe(
                (data) => {
                    if (data[0].attributes.length > 0) {
                        this.careteams = data[0].attributes.map((el) => {
                            if (el.careteamname.toLowerCase() === "no care team") {
                                this.nocareteamid = el.careteamid;
                            }
                            return new CheckboxItem(el.careteamid, el.careteamname, false);
                        });
                    }

                    resolve();
                },
                (err) => {
                    console.log(err);
                    reject();
                }
            );
        });
    }

    public getEmployers(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.employersService.getEmployers().subscribe(
                (data) => {
                    if (data[0].attributes.length > 0) {
                        this.employers = data[0].attributes;
                    }
                    resolve();
                },
                (err) => {
                    console.log(err[0].detail, 1);
                    reject();
                }
            );
        });
    }

    public getUserSettings(id): Promise<void> {
        return new Promise((resolve, reject) => {
            this.patientService.getUserSetting(id).subscribe(
                (data) => {
                    this.userSettings = data[0].attributes;
                    this.userSettings.bmi = (
                        isNaN(this.userSettings.weight / this.userSettings.height) ? 0 : (this.userSettings.weight / this.userSettings.height / this.userSettings.height) * 730
                    ).toFixed(1);
                    resolve();
                },
                (err) => {
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    reject();
                }
            );
        });
    }

    public getUserDetails(id): Promise<void> {
        return new Promise((resolve, reject) => {
            this.usersService.userDetails(id).subscribe(
                (data) => {
                    this.userData = data[0].attributes ? data[0].attributes : {};

                    this.userData["userid"] = id;

                    if (this.sessionService.isAdmin() || this.sessionService.isProviderAdmin()) {
                        this.patientRole = this.roleService.ROLE_ID_USER;
                        let role = this.roleService.ROLE_ID_PROVIDER;
                        this.patientRole = this.userData.roles.find((e) => e === "provider") ? role : this.patientRole;
                        role = this.roleService.ROLE_ID_PROVIDER_ADMIN;
                        this.patientRole = this.userData.roles.find((e) => e === "superProvider") ? role : this.patientRole;
                        role = this.roleService.ROLE_ID_ADMIN;
                        this.patientRole = this.userData.roles.find((e) => e === "admin") ? role : this.patientRole;

                        // user role is treated differently than other roles so you can't switch from or to it

                        if (this.patientRole === this.roleService.ROLE_ID_USER) {
                            // if user role can't changed so exclude others
                            this.roles = this.roles.filter((el) => el.id === this.roleService.ROLE_ID_USER);
                        } else {
                            // if not user role can't change to user
                            this.roles = this.roles.filter((el) => el.id !== this.roleService.ROLE_ID_USER);
                        }
                    }

                    // user role only returns 1 care team
                    if ((this.sessionService.isAdmin() || this.sessionService.isProviderAdmin()) && this.patientRole === this.roleService.ROLE_ID_USER) {
                        this.selectedcareteamids = [this.userData.careteam];

                        this.careteams.forEach((ct) => {
                            ct.checked = this.userData.careteam.includes(ct.value);
                        });
                    } else if ((this.sessionService.isAdmin() || this.sessionService.isProviderAdmin()) && this.patientRole !== this.roleService.ROLE_ID_ADMIN) {
                        // provider and provider admin role can have multiple teams
                        let cids = this.userData.careteam.careteams.map((el) => el.careteamid);
                        this.selectedcareteamids = cids;

                        this.careteams.forEach((ct) => {
                            ct.checked = cids.includes(ct.value);
                        });
                    }
                    resolve();
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                    reject();
                }
            );
        });
    }

    public async onSaveProfile() {
        if (this.isNew) {
            if (this.userData.email === undefined || this.userData.email.trim() === "") {
                this.snackbar.show("Email is required.", "danger");
                return false;
            }

            // eslint-disable-next-line no-useless-escape
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(this.userData.email.trim()).toLowerCase())) {
                this.snackbar.show("A valid email is required.", "danger");
                return false;
            }

            if ((this.sessionService.isAdmin() || this.sessionService.isProviderAdmin()) && this.selectedcareteamids.length === 0 && this.patientRole !== this.roleService.ROLE_ID_ADMIN) {
                this.snackbar.show("Careteam is required.", "danger");
                return false;
            }
        }

        if (this.sessionService.isAdmin() || this.sessionService.isProviderAdmin()) {
            if (this.selectedcareteamids.includes(this.nocareteamid) && this.selectedcareteamids.length > 1) {
                this.snackbar.show("If No Care Team is selected, no other careteams can be selected.", "danger");
                return false;
            }
        }

        if (this.userSettings.firstname === undefined || this.userSettings.firstname.trim() === "") {
            this.snackbar.show("First Name is required.", "danger");
            return false;
        }
        if (this.userSettings.lastname === undefined || this.userSettings.lastname.trim() === "") {
            this.snackbar.show("Last Name is required.", "danger");
            return false;
        }

        // Cast booleans
        for (let key in this.userSettings) {
            if (this.userSettings[key] === "true") this.userSettings[key] = true;
            if (this.userSettings[key] === "false") this.userSettings[key] = false;
        }

        try {
            // add member if new
            if (this.isNew) {
                this.userData.firstname = this.userSettings.firstname;
                this.userData.lastname = this.userSettings.lastname;
                this.userData.password = passwordGenerator.generate(this.randomPasswordOptions) + "!";

                // console.log("adding user");
                this.patientId = await this.addUser();
                this.userData.userid = this.patientId;

                // console.log("assign care team");
                await this.assignCareTeam(this.patientId);

                // console.log("assign telerx");
                await this.addUserToTeleRx(this.patientId);
            } else {
                if (this.roleChanged && (this.sessionService.isAdmin() || this.sessionService.isProviderAdmin())) {
                    // console.log("updating role");
                    await this.updateUserRole();
                }

                if (this.careteamChanged && (this.sessionService.isAdmin() || this.sessionService.isProviderAdmin())) {
                    // console.log("updating careteam");
                    await this.updateUserCareTeamAndTeleRX(this.patientId);
                }
            }

            // console.log("update phone");
            if (this.userData.phonenumber) {
                await this.updatePhoneNumber();
            }
            // console.log("update settings");
            await this.updateUserSettings(this.patientId);

            if (this.isNew && this.patientRole === this.roleService.ROLE_ID_USER) {
                // console.log("set onboarding");

                await this.onboarding(this.userData.email);
            }

            // console.log("done");
            if (this.isNew) {
                this.snackbar.show("Member has been added successfully.");
            } else {
                this.snackbar.show("Member updated successfully.");
            }
            // localStorage.setItem("search_name", this.userSettings.lastname);
            this.router.navigate(["/patients"]);
        } catch (ex) {
            console.log(ex);
            this.snackbar.show(Array.isArray(ex) ? ex[0].detail : ex ? ex : "Unable to add member.", "danger");
        }
    }

    public updateBMI() {
        this.userSettings.bmi = (isNaN(this.userSettings.weight / this.userSettings.height) ? 0 : (this.userSettings.weight / this.userSettings.height / this.userSettings.height) * 730).toFixed(1);
    }

    public minimumAge(): string {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var data = new Date(year - 13, month, day);
        return new Date(data).toISOString().split("T")[0];
    }

    public checkDateValidity(evt): void {
        if (evt.target.value !== "") {
            const mxDate = new Date(this.minimumAge());
            const inputDate = new Date(evt.target.value);
            this.userSettings.dob = evt.target.value;
            if (inputDate > mxDate) {
                this.futureDateError = true;
            }
            else {
                this.futureDateError = false;
            }
        }
    }

    public onBack() {
        this.router.navigate(["patients"]);
    }

    public blockSpecialChar(e) {
        var k = e.keyCode;
        return (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || (k >= 48 && k <= 57);
    }

    public getRoleById(id) {
        const r = this.roles.find((el) => el.id === id);

        if (r) {
            return r.name;
        } else {
            return "";
        }
    }

    onCareTeamChange(value) {
        this.selectedcareteamids = value;
        this.careteamChanged = true;
    }

    onRoleChange(value) {
        this.roleChanged = true;
        // if (this.getRoleById(this.patientRole) === "user") {
        //     this.careteams.forEach((el) => {
        //         el.checked = false;
        //         this.selectedcareteamids = [];
        //     });
        // }
    }

    private addUser(): Promise<any> {
        let options: any = {};
        options.onboarding = false;
        if (this.patientRole === this.roleService.ROLE_ID_USER) {
            options.onboarding = true;
        } else {
            options.onboarding = false;
        }

        return new Promise((resolve, reject) => {
            this.usersService.create(this.userData, this.patientRole).subscribe(
                (data) => {
                    resolve(data[0].id);
                },
                (err) => {
                    console.log(err);
                    reject(err[0].detail);
                }
            );
        });
    }

    private async updateUserCareTeamAndTeleRX(userId): Promise<any> {
        if (this.patientRole === this.roleService.ROLE_ID_USER) {
            return new Promise((resolve, reject) => {
                // Add careteam
                this.careTeamService
                    .assignPatientToCareTeam(
                        userId,
                        this.sessionService.isAdmin() || this.sessionService.isProviderAdmin() ? this.selectedcareteamids[0] : this.sessionService.activeCareTeam.careteamid
                    )
                    .subscribe(
                        (data) => resolve(undefined),
                        (err) => reject(err[0].detail)
                    );
            });
        } else if (this.patientRole === this.roleService.ROLE_ID_ADMIN) {
            // assign user to telerx
            Promise.resolve(undefined);
        } else if (this.patientRole === this.roleService.ROLE_ID_PROVIDER_ADMIN || this.patientRole === this.roleService.ROLE_ID_PROVIDER) {
            return new Promise((resolve, reject) => {
                // Add careteam
                this.careTeamService
                    .assignProviderToCareTeam(
                        userId,
                        this.sessionService.isAdmin() || this.sessionService.isProviderAdmin() ? this.selectedcareteamids : [this.sessionService.activeCareTeam.careteamid]
                    )
                    .subscribe(
                        (data) => resolve(undefined),
                        (err) => reject(err[0].detail)
                    );
            });
        }
    }

    private onboarding(email): Promise<any> {
        // console.log(user);
        return new Promise((resolve, reject) => {
            // Add careteam
            this.onboardingService.onboarding(email).subscribe(
                (data) => {
                    resolve(null);
                },
                (err) => reject(err[0].detail)
            );
        });
    }

    private addUserToTeleRx(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.teleRXService.addUserTelerx(userId).subscribe(
                (data) => resolve(null),
                (err) => reject(err[0].detail)
            );
        });
    }

    private updateUserSettings(userid): Promise<any> {
        return new Promise((resolve, reject) => {
            this.patientService.updateUserSettings(this.userSettings, userid).subscribe(
                (data) => resolve(null),
                (err) => reject(err[0].detail)
            );
        });
    }

    public updatePhoneNumber(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.usersService.userPhoneNumber(this.userData).subscribe(
                (data) => resolve(null),
                (err) => reject(err)
            );
        });
    }

    private updateUserRole(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.usersService.updateUserRole(this.patientId, this.patientRole).subscribe(
                (data) => resolve(null),
                (err) => reject(err[0].detail)
            );
        });
    }

    private assignCareTeam(userId): Promise<any> {
        if (this.patientRole === this.roleService.ROLE_ID_USER) {
            return new Promise((resolve, reject) => {
                // Add careteam
                this.careTeamService
                    .assignPatientToCareTeam(
                        userId,
                        this.sessionService.isAdmin() || this.sessionService.isProviderAdmin() ? this.selectedcareteamids[0] : this.sessionService.activeCareTeam.careteamid
                    )
                    .subscribe(
                        (data) => resolve(undefined),
                        (err) => reject(err[0].detail)
                    );
            });
        } else if (this.patientRole === this.roleService.ROLE_ID_ADMIN) {
            // assign user to telerx
            return new Promise((resolve, reject) => {
                resolve(undefined);
            });
        } else if (this.patientRole === this.roleService.ROLE_ID_PROVIDER_ADMIN || this.patientRole === this.roleService.ROLE_ID_PROVIDER) {
            return new Promise((resolve, reject) => {
                // Add careteam
                this.careTeamService
                    .assignProviderToCareTeam(
                        userId,
                        this.sessionService.isAdmin() || this.sessionService.isProviderAdmin() ? this.selectedcareteamids : [this.sessionService.activeCareTeam.careteamid]
                    )
                    .subscribe(
                        (data) => resolve(undefined),
                        (err) => reject(err[0].detail)
                    );
            });
        } else if (this.patientRole === this.roleService.ROLE_ID_ADMIN) {
            return Promise.resolve();
        }
    }

    public showTitle() {
        if ((this.sessionService.isAdmin() && this.getRoleById(this.patientRole) === "provider") || this.getRoleById(this.patientRole) === "superProvider") {
            return true;
        }
    }

}
