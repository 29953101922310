<div class="modal-header text-white">
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div style="text-align: center" class="modal-body">
    <img style="max-width: 100%" [src]="UserData" *ngIf="fileExtension !== 'pdf'; else notImage" />
    <ng-template #notImage>
        <i style="font-size: 40px; margin: 20px 40px" class="fa fa-paperclip" aria-hidden="true"></i>
    </ng-template>
</div>
<div *ngIf="libraryId !== '' && MetaData !== undefined" class="modal-footer">
    <input type="text" [(ngModel)]="MetaData.actual_file_name" class="form-control col-6" />
    <a>
        <button (click)="updateFileName()" type="button" class="btn btn-primary">Update File Name</button>
    </a>
    <a download [href]="UserData" *ngIf="fileExtension !== 'pdf'; else openPdf">
        <button type="button" class="btn btn-secondary">Download</button>
    </a>

    <ng-template #openPdf>
        <a download [href]="UserData" target="_blank">
            <button type="button" class="btn btn-secondary">Download</button>
        </a>
    </ng-template>

    <a *ngIf="libraryId === ''">
        <button (click)="uploadLibrary()" type="button" class="btn btn-secondary">Upload</button>
    </a>
</div>
<div *ngIf="libraryId === '' && MetaData !== undefined" class="modal-footer">
    <input type="text" [(ngModel)]="MetaData.actual_file_name" class="form-control col-6" />

    <a>
        <button (click)="uploadLibrary()" type="button" class="btn btn-secondary">Upload</button>
    </a>
</div>
<div *ngIf="libraryId === '' && MetaData === undefined" class="modal-footer">
    <a download [href]="UserData" target="_blank">
        <button type="button" class="btn btn-secondary">Download</button>
    </a>
</div>
<app-snackbar></app-snackbar>
