import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { VideoCallService } from "../../services/video-call.service";
declare var Twilio: any;
declare var window: any;
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "../../services/session.service";

// Attach the Tracks to the DOM.
function attachTracks(tracks, container, isLocal = false) {
    tracks.forEach(function (track) {
        container.appendChild(track.attach());
        if (track.kind === "video") {
            container.setAttribute("class", "active");
            var vid = container.querySelector("video");

            if (isLocal) {
                setTimeout(() => {
                    vid.style.width = "100%";
                    if (container.id !== "local-media") {
                        vid.style.height = "100%";
                    }
                    vid.style.overflowX = "hidden";
                }, 1000);
            } else {
                // vid.style.marginLeft = "calc((100vw - " + vid.scrollWidth + "px) / 2)";
                vid.style.width = "100%";
                if (container.id !== "local-media") {
                    vid.style.height = "100%";
                }
            }
        }
    });
}

// Attach the Participant's Tracks to the DOM.
function attachParticipantTracks(participant, container) {
    const tracks = getTracks(participant);
    attachTracks(tracks, container);
}

// Detach the Tracks from the DOM.
function detachTracks(tracks) {
    tracks.forEach(function (track) {
        track.detach().forEach(function (detachedElement) {
            detachedElement.remove();
        });
    });
}

// Detach the Participant's Tracks from the DOM.
function detachParticipantTracks(participant) {
    const tracks = getTracks(participant);
    detachTracks(tracks);
}

// Get the Participant's Tracks.
function getTracks(participant) {
    return Array.from(participant.tracks.values())
        .filter((publication: any) => publication.track)
        .map((publication: any) => publication.track);
}

// Activity log.
function log(message) {
    const logDiv = document.getElementById("log");
    //logDiv.style.display = "block";
    logDiv.innerHTML += "<p>&gt;&nbsp;" + message + "</p>";
    logDiv.scrollTop = logDiv.scrollHeight;
}

// Clear log.
function clearLog() {
    const logDiv = document.getElementById("log");
    logDiv.innerHTML = "<p></p>";
    logDiv.scrollTop = logDiv.scrollHeight;
    logDiv.style.display = "none";
}

@Component({
    selector: "video-chat-modal",
    templateUrl: "./video-chat-modal.component.html",
    styleUrls: ["./video-chat-modal.component.scss"],
})
export class VideoChatModalComponent implements OnInit {
    @Input() RoomName: String;
    @Input() Identity: String;
    @Input() Patient: any;
    muted: boolean = false;
    @Output() readonly Response = new EventEmitter<any>();
    activeRoom;
    previewTracks;
    _connectOptions: any;

    _userId = "";
    token;
    hideJoinRoom = false;
    hideLeaveRoom = true;
    Provider;
    actiontype: string = "";
    calling = false;
    call_initializing = false;
    public error: string = "";
    callStartAt: any;
    callEndAt: any;

    constructor(private sessionService: SessionService, private videoService: VideoCallService, private activeModal: NgbActiveModal) {}

    ngOnInit() {
        document.addEventListener("callActiontype", (data) => {
            this.actiontype = data["detail"];
            if (this.actiontype === "accepted") {
                //this.connectCall();
            } else if (this.actiontype !== "") {
                if (this.actiontype === "declined") {
                    this.error = "Video Call Declined!";
                } else if (this.actiontype === "busy") {
                    this.error = "Busy on another call!";
                } else if (this.actiontype === "completed") {
                    this.error = "Video Call End!";
                } else {
                    this.error = this.actiontype;
                }
                //  clearLog();
            }
        });

        this._userId = this.sessionService.currentUser?.id;
        this.Provider = this.sessionService.currentUser;
        this.joinRoom();
    }

    setLocalStream = (tracks) => {
        this.previewTracks = tracks;
        (<any>window).previewTracks = this.previewTracks;
        const previewContainer = document.getElementById("local-media");
        if (!previewContainer.querySelector("video")) {
            attachTracks(tracks, previewContainer);
        }
    };

    // Successfully connected!
    roomJoined = (room) => {
        this.call_initializing = false;
        this.activeRoom = room;
        (<any>window).room = this.activeRoom;
        // clearLog();
        // log(`Joined as '${this.Identity}'`);
        this.hideJoinRoom = true;
        this.hideLeaveRoom = false;

        // Attach LocalParticipant's Tracks, if not already attached.
        const previewContainer = document.getElementById("local-media");
        if (!previewContainer.querySelector("video")) {
            attachParticipantTracks(room.localParticipant, previewContainer);
        }

        // Attach the Tracks of the Room's Participants.
        room.participants.forEach((participant) => {
            // log(`Already in Room: '${participant.identity}'`);
            const _previewContainer = document.getElementById("remote-media");
            attachParticipantTracks(participant, _previewContainer);
        });

        // When a Participant joins the Room, log the event.
        room.on("participantConnected", (participant) => {
            //  log(`Joining: '${participant.identity}'`);
            this.calling = false;
        });

        // When a Participant's Track is subscribed to, attach it to the DOM.
        room.on("trackSubscribed", (track, publication, participant) => {
            // log(`Subscribed to ${participant.identity}'s track: ${track.kind}`);
            this.calling = false;
            const _previewContainer = document.getElementById("remote-media");
            attachTracks([track], _previewContainer);
        });

        // When a Participant's Track is unsubscribed from, detach it from the DOM.
        room.on("trackUnsubscribed", (track, publication, participant) => {
            //log(`Unsubscribed from ${participant.identity}'s track: ${track.kind}`);
            detachTracks([track]);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on("participantDisconnected", (participant) => {
            // log(`RemoteParticipant '${participant.identity}' left the room`);
            this.error = `RemoteParticipant '${participant.identity}' left the room`;
            detachParticipantTracks(participant);
            // this.leaveRoom();
            if (this.activeRoom !== undefined) {
                this.activeRoom.disconnect();
            }
            this.onCancel();
        });

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        room.on("disconnected", () => {
            log("Left");
            this.error = "Call Disconnected.";
            if (this.previewTracks) {
                this.previewTracks.forEach((track) => track.stop());
            }
            detachParticipantTracks(room.localParticipant);
            room.participants.forEach(detachParticipantTracks);
            this.activeRoom = null;
            this.hideJoinRoom = false;
            this.hideLeaveRoom = true;
        });
    };
    connectCall() {
        Twilio.Video.connect(this.token, this._connectOptions).then(this.roomJoined, (err) => {
            //log("Could not connect to Twilio: " + err.message);
            this.call_initializing = false;
            this.error = "Could not connect to Twilio: " + err.message;
        });
    }
    joinRoom() {
        if (!this.RoomName) {
            alert("Please enter a room name.");
            return;
        }
        this.call_initializing = true;
        //  log(`Joining room '${this.RoomName}'...`);

        const connectOptions: any = {
            name: this.RoomName,
            logLevel: "debug",
        };

        if (this.previewTracks) {
            connectOptions.tracks = this.previewTracks;
        }
        this.videoService
            .getTwilioToken(this.RoomName, this.Identity)
            .toPromise()
            .then(
                (data) => {
                    this.token = data.attributes.token;
                    this.videoService
                        .startCall(this.Patient, this.Provider, this.token, this.RoomName, this.Identity)
                        .toPromise()
                        .then(
                            (data) => {
                                this.callStartAt = new Date();
                                this.calling = true;
                                this.connectCall();
                            },
                            (error) => {
                                this.calling = false;
                                this.error = error[0].detail;
                            }
                        );
                },
                (error) => {}
            );
    }
    leaveRoom(actiontype = "") {
        if (this.activeRoom !== undefined) {
            this.activeRoom.disconnect();
            this.activeRoom = null;
        }
        this.onCancel();
        let callDuration = 0;
        try {
            this.callEndAt = new Date();
            let seconds = (this.callEndAt.getTime() - this.callStartAt.getTime()) / 1000;
            callDuration = parseInt(seconds.toFixed());
        } catch (e) {
            console.log(e);
        }
        this.videoService
            .actions(this.Patient, this.Provider, actiontype, callDuration)
            .toPromise()
            .then(
                (data) => {
                    //log(data.attributes.message);
                },
                (error) => {
                    this.calling = false;
                    this.error = error[0].detail;
                }
            );
    }
    public onCancel() {
        this.activeModal.close();
    }
    public muteToggle() {
        if (this.muted === false) {
            this.activeRoom.localParticipant.audioTracks.forEach((trackMap) => {
                trackMap.track.disable();
            });

            this.muted = true;
        } else {
            this.activeRoom.localParticipant.audioTracks.forEach((trackMap) => {
                trackMap.track.enable();
            });

            this.muted = false;
        }
    }
}
