import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AvcValidators } from '../../providers/avc-validators';
import { environment } from "../../../environments/environment";
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private avcValidators: AvcValidators, private authService: AuthService, private router: Router) { }
  public environment = environment;
  userId = "";
  token = "";
  pwdForm: UntypedFormGroup;
  submitted = false;
  showError = false;
  msg = "";
  error: any = [];
  showPwdForm = false;
  showSuccess = false;
  heading = "";
  btnText = "";
  ngOnInit(): void {
    if (this.router.url.includes("/create-password")) {
      this.heading = "Create Password"
      this.btnText = "Create Password"
    }
    else {
      this.heading = "Change Password"
      this.btnText = "Change Password"
    }

    this.pwdForm = this.formBuilder.group({
      password: ["", [Validators.required, this.avcValidators.ValidatePassword]],
      confirmPassword: ["", Validators.required]
    },
      {
        validator: this.ConfirmedValidator('password', 'confirmPassword')
      }

    );

    this.userId = this.route.snapshot.params.userId
    this.token = this.route.snapshot.queryParams.token
    this.validateLink();
  }
  get form() {
    return this.pwdForm.controls;
  }
  public submit() {
    this.showSuccess = false;
    this.showError = false;
    this.submitted = true;
    if (this.pwdForm.valid) {
      this.authService.resetPassword(this.pwdForm.value, this.token, this.userId).subscribe(
        (data) => {
          this.showSuccess = true;
          if (this.router.url.includes("/create-password")) {
            this.msg = "Your account was successfully created. Please use the button below to log in to your account."
          }
          else {
            this.msg = data.attributes.message
          }
        },
        (err) => {
          this.msg = err[0].title;
          this.error = err[0];
          this.showError = true
        }
      );
    }
  }
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  validateLink() {
    this.authService.validatePwdLink(this.userId, this.token).subscribe(
      (data) => {
        this.showPwdForm = true;
      },
      (err) => {
        this.error = err[0];
        this.showError = true
      }
    );
  }


}
