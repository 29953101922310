<!-- <div class="modal-header bg-primary text-white">
    <h5 class="modal-title" *ngIf="CareTeamData.action === 'create'">Create Care Team</h5>
    <h5 class="modal-title" *ngIf="CareTeamData.action === 'edit'">Edit Care Team</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
    <div class="category-header">
        <label *ngIf="CareTeamData.action === 'create'">Create Care Team</label>
        <label *ngIf="CareTeamData.action === 'edit'">Edit Care Team</label>
    </div>
    <div class="main-row">
        <div class="main-left">
            <div *ngIf="CareTeamData.action === 'edit'" class="form-group">
                <label>Care Team ID</label>
                <input readonly type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.careteamid" />
            </div>
            <div class="form-group">
                <label>Care Team Name</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.careteamname" />
            </div>
            <div class="form-group">
                <label>Address1</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.address1" />
            </div>
            <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.city" />
            </div>
            <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.phonenumber" />
            </div>
        </div>
        <div class="main-right">
            <div class="form-group divCareTeam">
                <!-- <div class="custom-control custom-switch">
                    <input [(ngModel)]="CareTeamData.careteam.defaultcareteam" type="checkbox" class="custom-control-input" id="carenav" />
                    <label class="custom-control-label" for="carenav">Default</label>
                </div> -->

                <label>Default</label>
                <input [(ngModel)]="CareTeamData.careteam.defaultcareteam" class="chkCareTeam" type="checkbox" />
            </div>
            <div class="form-group">
                <label>Address2</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.address2" />
            </div>
            <div class="form-group">
                <label>State</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.state" />
            </div>
            <div class="form-group">
                <label>ZIP Code</label>
                <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.postalcode" />
            </div>
        </div>
        <!-- <div *ngIf="CareTeamData.action !== 'edit'" class="main-row-postal">
            <label>ZIP Code</label>
            <input type="text" class="form-control" [(ngModel)]="CareTeamData.careteam.postalcode" />
        </div> -->
    </div>

    <div class="main-row">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onUpdateCareTeam()">Update</button>
        </div>
    </div>
</div>