<div class="modal-body">
    <div class="prescription-left">
        <img (click)="showImage(imageURL)" [src]="imageURL" (error)="onError()" />
    </div>
    <div class="prescription-right">
        <div class="prescription-modules">
            <span class="medication-label">Info</span>
            <span class="medication-text">{{ SummaryData.medication.medicationname }}</span>
            <span class="medication-text">{{ SummaryData.medication.dosage }}</span>
            <span class="medication-text">{{ SummaryData.medication.frequency }}</span>
        </div>
        <hr />
        <div class="prescription-modules">
            <span class="medication-label">Added by</span>
            <span class="medication-text">{{ SummaryData.medication.addedby ? SummaryData.medication.addedby : "N/A"
                }}</span>
            <span class="medication-text">{{ SummaryData.medication.addedon ? (SummaryData.medication.addedon | date:
                "MM/dd/yyyy") : "N/A" }}</span>
        </div>
        <hr />
    </div>
    <div class="prescription-cancel">
        <button type="button" class="close" (click)="onCancel()" aria-label="Close">
            <i class="fa fa-window-close graycolor" aria-hidden="true"></i>
            <!-- <span aria-hidden="true">&times;</span> -->
        </button>
    </div>
</div>