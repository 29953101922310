<!--  -->
<div class="container-fluid">
    <div class="row mt-3 col-12">
        <br />
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
                <a ngbNavLink>TeleRemedy Settings</a>
                <ng-template ngbNavContent>
                    <form #container [formGroup]="settingForm" class="formcontainer" (ngSubmit)="saveChanges()">
                        <div class="container-fluid">
                            <div class="col-7 float-left">
                                <div style="margin-bottom: -8px" class="row mt-3">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Membership Name:</label>
                                            <input type="text"
                                                [ngClass]="{ error: settingForm.controls['name'].invalid === true }"
                                                formControlName="name" required class="form-control" maxlength="245" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Membership Description:</label>
                                            <textarea class="form-control" maxlength="500"
                                                [ngClass]="{ error: settingForm.controls['description'].invalid === true }"
                                                formControlName="description" required></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="membership ? membership.carenav : false">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Profile Required:</label>
                                            <br /><span class="smaller">Determines if users of your teleremedy will be
                                                prompted with a goal to complete their profile.</span>
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="profilerequired" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="membership ? membership.carenav : false">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Request Appointments:</label>
                                            <br /><span class="smaller">Determines if users of your teleremedy may
                                                request appointments using the Request Appointment Applet.</span>
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="enablerequest" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="membership ? membership.carenav : false">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Enable Chat Bot:</label>
                                            <br /><span class="smaller"> Determines if the automated chat bot is used in
                                                chat.</span>
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="chatbot" />
                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="membership ? membership.carenav : false" class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Additional Forms</label> <br />
                                            <div class="row">
                                                <div class="col col-1"><input type="checkbox" class="form-control"
                                                        style="width: 20px" formControlName="phq9" /></div>
                                                <div class="col col-11"><span class="smaller"
                                                        style="margin-top: 16px; display: block">Patient Health
                                                        Questionnaire-9 (PHQ-9)</span></div>
                                            </div>
                                            <div class="row">
                                                <div class="col col-1"><input type="checkbox" class="form-control"
                                                        style="width: 20px" formControlName="gad7" /></div>
                                                <div class="col col-11"><span class="smaller"
                                                        style="margin-top: 16px; display: block">General Anxiety
                                                        Disorder-7 (GAD-7)</span></div>
                                            </div>
                                            <div class="row">
                                                <div class="col col-1"><input type="checkbox" class="form-control"
                                                        style="width: 20px" formControlName="sleepassessment" /></div>
                                                <div class="col col-11"><span class="smaller"
                                                        style="margin-top: 16px; display: block">Sleep
                                                        Assessment</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>TeleRemedy Type:</label>
                                            <select class="form-control custom-select"
                                                [attr.disabled]="(membership ? membership.carenav : false) ? '' : null"
                                                required formControlName="telerxtype">
                                                <option value="application">Application</option>
                                                <option value="private">Private</option>
                                                <option value="service">Service</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'service'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>National Provider Identifier (NPI):</label>
                                            <input type="text" class="form-control" maxlength="10"
                                                [ngClass]="{ error: settingForm.controls['npi'].invalid === true }"
                                                formControlName="npi" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row"
                                    *ngIf="!(membership ? membership.carenav : false) && settingForm.controls['telerxtype'].value === 'service' && sessionService.isAdmin()">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Provider Search Enabled:</label>
                                            <br /><span class="smaller">Determines if your teleremedy will be listed in
                                                provider searches.</span>
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="searchenabled" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="(membership ? membership.carenav : true)">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Enable User Self Goals:</label>
                                            <!-- <br /><span class="smaller">Determines if your teleremedy will be listed in
                                            provider searches.</span> -->
                                            <input type="checkbox" class="form-control" style="width: 20px"
                                                formControlName="enableUserSelfGoals" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'service'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Specialty Type(s):</label>
                                            <div class="row">
                                                <table>
                                                    <tr *ngFor="let s of selectSpecialties" style="height: 30px">
                                                        <td style="border-bottom: 0">{{ s.display }}</td>
                                                        <td style="border-bottom: 0">
                                                            <i *ngIf="!(membership ? membership.carenav : false)"
                                                                class="fa fa-trash-alt mr-3 cursor-pointer"
                                                                (click)="removeSpecialty(s.spid)"
                                                                title="Remove Member"></i>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="!(membership ? membership.carenav : false)">
                                                        <td style="border-bottom: 0">
                                                            <select class="form-control custom-select"
                                                                formControlName="specialty">
                                                                <option *ngFor="let s of specialties" [value]="s.spid">
                                                                    {{ s.display }}</option>
                                                            </select>
                                                        </td>
                                                        <td style="border-bottom: 0"><i
                                                                class="fa fa-plus-circle mr-3 cursor-pointer"
                                                                style="font-size: 20px" (click)="addSpecialty()"></i>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'application'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Dashboard Extension URL:</label>
                                            <input type="text"
                                                [ngClass]="{ error: settingForm.controls['providerdashboardurl'].invalid === true }"
                                                formControlName="providerdashboardurl" class="form-control"
                                                maxlength="255" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'application'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Callback URL:</label>
                                            <input type="text"
                                                [ngClass]="{ error: settingForm.controls['callbackeventurl'].invalid === true }"
                                                formControlName="callbackeventurl" class="form-control"
                                                maxlength="255" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row" *ngIf="settingForm.controls['telerxtype'].value === 'application'">
                                    <div class="col col-9">
                                        <div class="form-group">
                                            <label>Admin URL:</label>
                                            <input
                                                type="text"
                                                [ngClass]="{ error: settingForm.controls['admindashboardurl'].invalid === true }"
                                                formControlName="admindashboardurl"
                                                class="form-control"
                                                maxlength="255"
                                            />
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row submit">
                                    <div class="col col-9">
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 float-left mt-3">
                                <img class="logoImg" [src]="teleRx.logourl" (error)="setDefaultPic()" />
                                <br />
                                <a (click)="$event.stopPropagation(); onEditImage()"
                                    class="float-left editimage">Edit</a>
                                <br />
                                <div *ngIf="isImageEdit" style="width: 200px">
                                    <input type="file" class="btn btn-primary float-left"
                                        style="width: 250px; margin-bottom: 20px; text-align: left" name="Choose File"
                                        (change)="chooseImageFile($event)" accept=".jpg,.jpeg,.png" />
                                    <button *ngIf="isValidImg" [disabled]="btnImgDisabled" (click)="uploadImageFile()"
                                        class="btn btn-primary float-left">Upload</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </li>
            <li *ngIf="!show_workflow_for_carenav_only ||(show_workflow_for_carenav_only && (membership ? membership.carenav : true))"
                ngbNavItem>
                <a ngbNavLink>Waiting Rooms</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="p-3">
                            <div class="row">
                                <div class="col-5">
                                    <span class="span_heading">Workflow</span>
                                </div>
                                <!-- <div class="col-7">
                                    <button (click)="newRoom()" class="float-right btn-primary btn"><i
                                            class="fas fa-plus-circle"></i>&nbsp;New Room</button>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-12 waitingRoomWorkflowSettings">
                                    <div class="mt-2" *ngFor="let w of waitingRoomWorkflowSettings">
                                        <input id="{{w.value}}" type="checkbox" [value]='w.value'
                                            [(ngModel)]="w.selected" (change)="onWorkFlowChange($event)"
                                            class="custom-control-input" />
                                        <label for="{{w.value}}" class="custom-control-label" for="appointment">
                                            {{w.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-3">
                            <div class="row">
                                <div class="col-5">
                                    <span class="span_heading">Waiting rooms</span>
                                </div>
                                <div class="col-7">
                                    <button (click)="newRoom()" class="float-right btn-primary btn"><i
                                            class="fas fa-plus-circle"></i>&nbsp;New Room</button>
                                </div>
                            </div>

                            <div #activeList="cdkDropList" cdkDropList cdkDropListOrientation="vertical"
                                [cdkDropListData]="waitingRooms" (cdkDropListDropped)="waitingRoomDrop($event)">
                                <div class="row">
                                    <div class="col-12">
                                        <table class="table table-hover mt-3">
                                            <thead>
                                                <tr>
                                                    <th style="width: 90%">Name</th>
                                                    <th style="text-align: center">Order</th>
                                                </tr>
                                            </thead>
                                            <tr cdkDrag *ngFor="let waiting of waitingRooms">
                                                <td>{{ waiting.roomname }}</td>
                                                <td style="text-align: center">
                                                    <i class="fa fa-arrows-alt mr-3 cursor-pointer"></i>
                                                    <i *ngIf="waiting.membershipid !== '00000000-0000-0000-0000-000000000000'"
                                                        (click)="editRoom(waiting)"
                                                        class="fa fa-pen mr-3 cursor-pointer"></i>
                                                    <i *ngIf="waiting.membershipid !== '00000000-0000-0000-0000-000000000000'"
                                                        (click)="delete(waiting)"
                                                        class="fa fa-trash-alt mr-3 cursor-pointer"></i>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <!-- <div class="col-6"></div> -->
                                </div>
                            </div>
                        </div>
                        <div class="p-3">
                            <span class="span_heading">Member Dashboard Panels</span>
                            <div #activeList="cdkDropList" cdkDropList cdkDropListOrientation="vertical"
                                [cdkDropListData]="tabOrderSettings" (cdkDropListDropped)="drop($event)">
                                <table style="width: 100%" class="table table-hover mt-3">
                                    <thead>
                                        <tr>
                                            <th style="width: 90%">Name</th>
                                            <th style="text-align: center">Order</th>
                                        </tr>
                                    </thead>
                                    <tr cdkDrag *ngFor="let tab of tabOrderSettings">
                                        <td>{{ tab.text }}</td>
                                        <td style="text-align: center">
                                            <i class="fa fa-arrows-alt mr-3 cursor-pointer"></i>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Employers</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <app-employer></app-employer>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Auto Reply</a>
                <ng-template ngbNavContent>
                    <app-auto-reply></app-auto-reply>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Onboarding</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <br />
                        <div class="form-group">
                            You can share the following URL or QR Code with your user base to allow them to onboard
                            directly to your teleremedy in the Clio GO mobile app. <br />
                        </div>
                        <div class="form-group">
                            <label>URL</label>
                            <br />
                            <a href="{{ joinURL }}" target="_new">{{ joinURL }}</a><i class="fas fa-copy"
                                title="Copy to clipboard" style="margin-left: 10px; color: #0A529E; cursor: copy"
                                (click)="copyOnboardURL()"></i>
                        </div>

                        <div class="form-group">
                            <label>QR code</label>

                            <ngx-qrcode elementType="url" [value]="joinURL" cssClass="aclass" errorCorrectionLevel="L">
                            </ngx-qrcode>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a (click)="clickfilter()" ngbNavLink>Chat Filter</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="p-3">
                            <p class="header-text">This functionality allows administrators to define specific words
                                that will not be allowed in chat</p>
                            <div class="filter-section">
                                <div *ngIf="chatFilters.length" class="filter-section-left">
                                    <i style="cursor: pointer; font-size: 20px" class="fa fa-redo-alt left-chevron"
                                        (click)="onRefresh()"></i><input class="filter-input" [(ngModel)]="filterText"
                                        name="searchTerm" [(ngModel)]="tableService.searchTerm"
                                        placeholder="Search..." />
                                </div>
                                <div class="filter-section-right">
                                    <button (click)="onReset('removeall')" class="float-right btn-primary btn"><i
                                            class="far fa-trash-alt"></i>&nbsp;Remove All</button>
                                    <button (click)="onReset('reset')" class="spacing float-right btn-primary btn"><i
                                            class="fas fa-redo"></i>&nbsp;Reset to Default</button>
                                    <button (click)="newWord()" class="spacing float-right btn-primary btn"><i
                                            class="fas fa-plus-circle"></i>&nbsp;Add Word</button>
                                </div>
                            </div>
                            <app-loader style="position: absolute; top: 200px; right: 45%; z-index: 100" class="loader"
                                *ngIf="showLoader"></app-loader>
                            <div class="table-badfilter">
                                <table *ngIf="chatFilters.length" class="table table-striped datatable">
                                    <thead>
                                        <tr>
                                            <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'"
                                                *ngFor="let c of displayedColumns" sortable="{{ c.columnname }}"
                                                (sort)="onSort($event)">
                                                {{ c.displayname }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of resultList$ | async">
                                            <td style="vertical-align: middle; max-width: 200px">
                                                <ngb-highlight [result]="p.badword" [term]="tableService.searchTerm">
                                                </ngb-highlight>
                                            </td>
                                            <td style="vertical-align: middle; max-width: 200px">
                                                <i class="fas fa-times mr-3 cursor-pointer" (click)="onDelete(p)"
                                                    title="Delete"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div *ngIf="chatFilters.length" class="d-flex justify-content-between p-2">
                                    <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                                        [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true">
                                    </ngb-pagination>

                                    <select class="custom-select pagination"
                                        style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                                        [(ngModel)]="tableService.pageSize">
                                        <option [ngValue]="10">10 items per page</option>
                                        <option [ngValue]="50">50 items per page</option>
                                        <option [ngValue]="100">100 items per page</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem *ngIf="sessionService.isAdmin()">
                <a ngbNavLink>Bulk Upload</a>
                <ng-template ngbNavContent>
                    <form>
                        <div class="container-fluid">
                            <br />
                            <div>
                                <p>
                                    This functionality will allow you to mass onboard users from a CSV file. The CSV
                                    file must have all of the following fields. If optional, there still must be a
                                    blank field:
                                </p>
                                <ul>
                                    <li>FirstName (required)</li>
                                    <li>LastName (required)</li>
                                    <li>BirthDate (optional, format mm/dd/yyyy)</li>
                                    <li>Gender (optional, value must be male, female or other)</li>

                                    <li>PostalCode (optional)</li>
                                    <li>Email (required)</li>
                                    <li>CardNumber (optional, must be assigned by Clio Health team)</li>
                                    <li>Careteamid (optional, must be assigned by Clio Health team)</li>
                                    <li>Employer (optional)</li>
                                    <li>SendOnboardingEmail (optional, value is 0 for no or 1 for yes)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <!-- <div style="text-align: right; padding: 0px" class="col-12 mb-3">
                                <button type="submit" class="btn btn-primary">Save</button>
                            </div> -->
                            <div style="margin-top: 30px; padding-right: 0px; padding: 0px"
                                class="row mt-3 mr-0 col-12">
                                <div style="text-align: right; padding: 0px" class="col-12 mb-3">
                                    <!-- <button type="submit" class="btn btn-primary">Save</button> -->
                                    <input type="file" class="btn btn-primary float-left" name="Choose File"
                                        (change)="chooseFile($event)" accept=".csv" />
                                    <button *ngIf="isValidCsv" [disabled]="btnDisabled" (click)="uploadBulkFile()"
                                        class="btn btn-primary float-right">Upload</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </li>

        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>