<div class="container">

    <header class="header">

        <h1 class="title">Edit Text</h1>

    </header>
    <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
    <br><br>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()"> Cancel </button>
        <button type="button" class="btn btn-primary" (click)="onOK()"> Done </button>
    </div>
</div>