<div class="container-fluid">

    <div *ngIf="!showLoader">
        <div class="row">
            <div class="col-lg-12 chartPrintTitle">{{metric.name}} (Month to Date)</div>
            <div *ngIf="!isSurvayGraph" class="col-lg-3 move">
                <a href="#" (click)="$event.preventDefault();delete()">
                    <img src="../../../assets/images/delete.svg">
                </a>
                <a href="#" (click)="$event.preventDefault();previous()">
                    <img src="../../../assets/images/move.svg">
                </a>
            </div>
        </div>
        <div class="row">
            <highcharts-chart *ngIf="chartOptions" [Highcharts]="Highcharts"
                style="width: 100%; display: block; overflow: hidden" [options]="chartOptions"></highcharts-chart>

            <label>Provider</label>
        </div>
        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right">
               <a href="#" (click)="$event.preventDefault();previous()"
                    [className]="page==0 ? 'previous' : 'next'"><i
                    class="fa fa-chevron-left" aria-hidden="true"></i></a>
                <a href="#" (click)="$event.preventDefault();next()"
                    [className]="pageCount-1==page? 'previous' : 'next'"><i
                    class="fa fa-chevron-right" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>

    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 45%;"></app-loader>
</div>