import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "chat-image-modal",
    templateUrl: "./chat-image-modal.component.html",
    styleUrls: ["./chat-image-modal.component.scss"],
})
export class ChatImageModalComponent {
    constructor(public activeModal: NgbActiveModal, public patientService: PatientService, private snackbar: SnackbarService) {}

    @Input() UserData: any;
    @Input() fileExtension: string = "";
    @Input() MetaData: any;
    @Input() libraryId = "";
    @Input() patientId = "";
    @Input() careTeamId = "";
    @Input() file: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    modalTitle = "Create Member";
    saveBtnText = "Create";
    errorMessage = "";

    imageMetaData: any;

    public onCancel() {
        this.activeModal.close();
    }

    public updateFileName() {
        return this.patientService.updateFileName(this.libraryId, this.MetaData).subscribe(
            (library) => {
                this.snackbar.show("Name updated successfully.", "success");
                this.Response.emit({ proceed: true });
                this.activeModal.close();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public uploadLibrary() {
        const formData = new FormData();
        formData.append("file", this.file, this.file.name);
        return this.patientService.uploadLibrary(formData, this.patientId, this.careTeamId, this.MetaData.actual_file_name).subscribe(
            (uploadData) => {
                setTimeout(() => {
                    this.Response.emit({ proceed: true });
                }, 1000);
                this.activeModal.close();
                // this.getLibrary();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
}
