import { Component, Input, Output, EventEmitter } from "@angular/core";
import { CareTeamService } from "../../services/careteam.service";
import { Helper } from "../../providers/helper";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "careTeam-modal",
    templateUrl: "./careTeam-modal.component.html",
    styleUrls: ["./careTeam-modal.component.scss"],
})
export class CareTeamModalComponent {
    constructor(private careTeamService: CareTeamService, private helper: Helper, private activeModal: NgbActiveModal) { }

    @Input() CareTeamData: any = {};
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    public onUpdateCareTeam() {
        this[this.CareTeamData.action === "edit" ? "updateCareTeam" : "newCareTeam"]()
            .then((msg) => {
                this.Response.emit({ success: true, message: msg });
                this.activeModal.close();
            })
            .catch((err) => {
                this.Response.emit({ success: false, message: err });
                this.activeModal.close();
            });
    }

    private updateCareTeam(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.careTeamService.updateCareteam(this.CareTeamData.careteam).subscribe(
                (data) => {
                    resolve("Care Team Updated");
                },
                (error) => {
                    reject(error[0].detail == undefined ? error : error[0].detail);
                }
            );
        });
    }

    private newCareTeam(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.careTeamService.createCareteam(this.CareTeamData.careteam).subscribe(
                (data) => {
                    resolve("Care Team Added");
                },
                (error) => {
                    reject(error[0].detail !== undefined ? error[0].detail : error);
                }
            );
        });
    }

    public onCancel() {
        this.activeModal.close();
    }
}
