<div *ngIf="getDashboardModules('experience_survey')" class="container-fluid">
    <!--<div class="row">-->

    <div class="tab">
        <button class="tablinks border-radius" (click)="scheduleReport()">
            <i class="far fa-clock" aria-hidden="true"></i>&nbsp;
            Schedule Report</button>
        <a style="border-radius: 5px 0px 0px 5px;" (click)="showGraph('survey')" class="tablinks"
            [class.active]="current_active === 'survey'">
            <img *ngIf="current_active!=='survey'" src="/assets/msx/favorites.svg">
            <img *ngIf="current_active==='survey'" src="/assets/msx/favorites-white.svg">Patient Experience
        </a>
        <a (click)="showGraph('visit')" class="tablinks" [class.active]="current_active === 'visit'">

            <img *ngIf="current_active!=='visit'" src="/assets/msx/favorites.svg">
            <img *ngIf="current_active==='visit'" src="/assets/msx/favorites-white.svg">Visit
        </a>
        <a (click)="showGraph('billing')" class="tablinks" [class.active]="current_active === 'billing'">
            <img *ngIf="current_active!=='billing'" src="/assets/msx/favorites.svg">
            <img *ngIf="current_active==='billing'" src="/assets/msx/favorites-white.svg">Billing
        </a>
        <!-- <button (click)="showGraph('insights')" class="tablinks"
                [class.active]="current_active === 'insights'">
                <img *ngIf="current_active!=='insights'" src="/assets/msx/insights.svg">
                <img *ngIf="current_active==='insights'" src="/assets/msx/insights-white.svg">Insights</button> -->


    </div>
    <!-- <div style="justify-content: flex-end;" class="row">
        <button *ngIf="showDeleteAll" (click)="deleteAll()" class="btn mr-2">Delete All</button>
        <button *ngIf="showRestore" (click)="restoreDefault()" class="btn">Restore Defaults</button>
    </div> -->

    <div *ngIf="current_active==='survey'&& showDeleteAll && favoriteList?.length==0" class="row">
        <div class="card">
            <div class="card-body">
                <app-survey-data [_type]="'survey'"></app-survey-data>
            </div>
        </div>
    </div>

    <div *ngIf="current_active==='survey' && favoriteList?.length>0" class="row">
        <div class="card" *ngFor="let f of favoriteList">
            <div class="card-body">
                <div style="display: inline" class="row">
                    <a class="icon-href" href="#" (click)="$event.preventDefault();delete(f)">
                        <img src="../../../assets/images/delete.svg">
                    </a>
                </div>
                <app-survey-data [_favoriteList]="f" [_type]="'survey'"></app-survey-data>
            </div>
        </div>
    </div>


    <div *ngIf="current_active==='visit'" class="row insights">
        <div class="card" *ngFor="let f of favoriteList">
            <div class="card-body">
                <div style="display: inline" class="row">
                    <a class="icon-href" href="#" (click)="$event.preventDefault();delete(f)">
                        <img src="../../../assets/images/delete.svg">
                    </a>
                </div>
                <app-survey-data [_favoriteList]="f" [_type]="'visit'"></app-survey-data>
            </div>
        </div>
        <div *ngIf="favoriteList.length===0" class="card">
            <div class="card-body">
                <app-survey-data style="width:100%;" [_type]="'visit'"></app-survey-data>
            </div>
        </div>
    </div>
    <div *ngIf="current_active==='billing'" class="row insights">

        <div class="card" *ngFor="let f of favoriteList">
            <div class="card-body">
                <div style="display: inline" class="row">
                    <a class="icon-href" href="#" (click)="$event.preventDefault();delete(f)">
                        <img src="../../../assets/images/delete.svg">
                    </a>
                </div>

                <app-billing-data [_favoriteList]="f"></app-billing-data>

            </div>
        </div>
        <div *ngIf="favoriteList.length===0" class="card">
            <div class="card-body">
                <app-billing-data [_type]="'favorite'" style="width:100%;"></app-billing-data>
            </div>
        </div>
    </div>
</div>



<!-- <div *ngIf="current_active==='insights'" class="row insights">
        <div class="card mb-2">
            <div class="card-body">
                TBD
            </div>
        </div>
    </div>


    <p *ngIf="metrics.length===0 && showMsg ==true" class="helptext">You don't have any favorite list yet. Please go to
        <a [routerLink]="['/patient-experience']">
            Patient
            Experience
        </a> and select favorite List.
    </p> -->


<app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>