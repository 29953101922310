import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class MediaService {
    private handler = `/msx`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) { }

    public save(authResponse, long_lived_access_token, form, selectedFBPages, smid = ""): Observable<any> {
        let pages = [];
        selectedFBPages.forEach(element => {
            pages.push(
                {
                    page_id: element.id,
                    config: {
                        page_name: element.name,
                        page_token: element.access_token,
                        userid: authResponse.userID,
                        access_token: authResponse.accessToken,
                        long_lived_access_token: long_lived_access_token
                    }
                })
        });
        let data = JSON.stringify({
            locationid: form.location.locationid,
            practiceid: form.practice.practiceid,
            providerid: form.provider.providerid,
            socialmediatype: "facebook",
            pages: pages
            // socialconfig: {
            //     socialLinks: [
            //         {
            //             facebook: {
            //                 enable: form.facebook,
            //                 pages: pages,
            //                 userid: authResponse.userID,
            //                 access_token: authResponse.accessToken,
            //                 long_lived_access_token: long_lived_access_token
            //             }
            //         },
            //         {
            //             yelp: {
            //                 enable: true,
            //                 pages: [
            //                     {
            //                         "page_id": "mangrove-property-management-north-palm-beach-3",
            //                         "page_name": "North Palm Beach"
            //                     }
            //                 ]
            //             }
            //         }
            //     ]
            // }
        })
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/SocialMedia/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public GetSocialMediaReviews(status): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        // if (selectedGroup !== "") {
        //     selectedGroup = selectedGroup.toLowerCase();
        // }
        let practiceid = "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/SocialMediaReviews/v2?GroupID=" + selectedGroup + "&PracticeID=" + practiceid + "&page=1&limit=100&filterBy=" + status).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public Ignored(smrid, isignored): Observable<any> {
        let data = JSON.stringify({
            smrid: smrid,
            isignored: !isignored
        })
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/SocialMediaReviews/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public GetSocialMediaAverages(): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        // if (selectedGroup !== "") {
        //     selectedGroup = selectedGroup.toLowerCase();
        // }
        let practiceid = "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/SocialMedia/v2?GroupID=" + selectedGroup + "&PracticeID=" + practiceid + "&page=1&limit=100&filterBy=" + status).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public delete(smgroupid): Observable<any> {
        let data = JSON.stringify({
            smgroupid: smgroupid,
            isdeleted: true
        })
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/SocialMedia/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public GetSocialMedia(smid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/SocialMedia/v2/" + smid).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public addResponses(smrid, responses): Observable<any> {
        let data = JSON.stringify({
            smrid: smrid,
            responses: responses
        })
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/SocialMediaReviews/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public GetProviders(locationId, practiceId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/SocialMedia/v2/" + locationId + "/" + practiceId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }


    public savePlace(form, placeId = "", placeName = "", googleUser): Observable<any> {
        let pages = [];

        let data = JSON.stringify({
            locationid: form.location.locationid,
            practiceid: form.practice.practiceid,
            providerid: form.provider.providerid,
            socialmediatype: "google",
            pages: [
                {
                    "page_id": placeId,
                    "config": {
                        "page_name": placeName,
                        "userid": googleUser.id,
                        "access_token": googleUser.idToken,
                    }
                }
            ]

        })
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/SocialMedia/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public negativeReviewsCount(): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/SocialMediaReviews/Count/v2?GroupID=" + selectedGroup + "&filterBy=All").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }


}
