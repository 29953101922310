import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { Helper } from "../../../providers/helper";

@Component({
    selector: "contact-information-modal",
    templateUrl: "./contact-information-modal.component.html",
    styleUrls: ["./contact-information-modal.component.scss"],
})
export class ContactInformationModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private helper: Helper) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    contactForm: UntypedFormGroup;
    emergencyForm: UntypedFormGroup;
    error = "";
    ngOnInit() {
        this.contactForm = this.fb.group({
            phone1: [this.SummaryData.summary.contactinformation.phone1, [Validators.pattern(/^\+?[\d]+[(-.\s]?\d{3}[)-.\s]?\d+$/)]],
            phone1type: [this.SummaryData.summary.contactinformation.phone1type ? this.SummaryData.summary.contactinformation.phone1type : "", []],
            phone2: [this.SummaryData.summary.contactinformation.phone2, [Validators.pattern(/^\+?[\d]+[(-.\s]?\d{3}[)-.\s]?\d+$/)]],
            phone2type: [this.SummaryData.summary.contactinformation.phone2type ? this.SummaryData.summary.contactinformation.phone2type : "", []],
            phone3: [this.SummaryData.summary.contactinformation.phone3, [Validators.pattern(/^\+?[\d]+[(-.\s]?\d{3}[)-.\s]?\d+$/)]],
            phone3type: [this.SummaryData.summary.contactinformation.phone3type ? this.SummaryData.summary.contactinformation.phone3type : "", []],
            address1: [this.SummaryData.summary.contactinformation.address1, []],
            address2: [this.SummaryData.summary.contactinformation.address2, []],
            city: [this.SummaryData.summary.contactinformation.city, []],
            state: [this.SummaryData.summary.contactinformation.state, []],
            zip: [this.SummaryData.summary.contactinformation.zip, []],
        });
        this.emergencyForm = this.fb.group({
            name: [this.SummaryData.summary.emergencycontact.name, []],
            phonenumber1: [this.SummaryData.summary.emergencycontact.phonenumber1, [Validators.pattern(/^\+?[\d]+[(-.\s]?\d{3}[)-.\s]?\d+$/)]],
            relation: [this.SummaryData.summary.emergencycontact.relation ? this.SummaryData.summary.emergencycontact.relation : "", []],
        });
    }
    /**
     * Returns true if form control is valid after being touched
     * @param key
     */
    isFieldValid(key) {
        return this.contactForm.get(key).invalid && this.contactForm.get(key).touched;
    }
    isEmergencyFieldValid(key) {
        return this.emergencyForm.get(key).invalid && this.emergencyForm.get(key).touched;
    }
    public onSave() {
        this.contactForm.markAllAsTouched();
        this.emergencyForm.markAllAsTouched();
        if (this.contactForm.valid && this.emergencyForm.valid) {
            this.SummaryData.summary.contactinformation.phone1 = this.helper.undefinedIfEmpty(this.contactForm.get("phone1").value);
            this.SummaryData.summary.contactinformation.phone1type = this.helper.undefinedIfEmpty(this.contactForm.get("phone1type").value);
            this.SummaryData.summary.contactinformation.phone2 = this.helper.undefinedIfEmpty(this.contactForm.get("phone2").value);
            this.SummaryData.summary.contactinformation.phone2type = this.helper.undefinedIfEmpty(this.contactForm.get("phone2type").value);
            this.SummaryData.summary.contactinformation.phone3 = this.helper.undefinedIfEmpty(this.contactForm.get("phone3").value);
            this.SummaryData.summary.contactinformation.phone3type = this.helper.undefinedIfEmpty(this.contactForm.get("phone3type").value);
            this.SummaryData.summary.contactinformation.address1 = this.helper.undefinedIfEmpty(this.contactForm.get("address1").value);
            this.SummaryData.summary.contactinformation.address2 = this.helper.undefinedIfEmpty(this.contactForm.get("address2").value);
            this.SummaryData.summary.contactinformation.city = this.helper.undefinedIfEmpty(this.contactForm.get("city").value);
            this.SummaryData.summary.contactinformation.state = this.helper.undefinedIfEmpty(this.contactForm.get("state").value);
            this.SummaryData.summary.contactinformation.zip = this.helper.undefinedIfEmpty(this.contactForm.get("zip").value);
            this.SummaryData.summary.emergencycontact.name = this.helper.undefinedIfEmpty(this.emergencyForm.get("name").value);
            this.SummaryData.summary.emergencycontact.phonenumber1 = this.helper.undefinedIfEmpty(this.emergencyForm.get("phonenumber1").value);
            this.SummaryData.summary.emergencycontact.relation = this.helper.undefinedIfEmpty(this.emergencyForm.get("relation").value);
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
