// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAlBca-f-PLc-2HHlahrqQ7S-R2H_VTkvE",
        authDomain: "clio-mpath.firebaseapp.com",
        databaseURL: "https://clio-mpath.firebaseio.com",
        projectId: "clio-mpath",
        storageBucket: "clio-mpath.appspot.com",
        messagingSenderId: "932920306000",
        appId: "1:932920306000:web:74fff9a66f9d3447b0e455",
    },
    firebase_us: {
        apiKey: "AIzaSyBWMgurIcO9zU6ombXNTi9W-huM3o6flsc",
        authDomain: "clio-health-go.firebaseapp.com",
        databaseURL: "https://clio-health-go.firebaseio.com",
        projectId: "clio-health-go",
        storageBucket: "clio-health-go.appspot.com",
        messagingSenderId: "239918166489",
        appId: "1:239918166489:web:610c7a14ad4db2b20aa9c8",
    },

    // msx_uri: "https://msx.clio-dev.com",
    // forms_server_uri: "https://forms.clio-dev.com",

    msx_uri: "https://msx.clio-dev.com",
    forms_server_uri: "https://forms.clio-dev.com",

    cliotomsx: "https://adminv2-dev.medstatix.com",


    uri: "https://apexapi-dev.medstatix.com",
    graph_uri: "https://apexapi-dev.medstatix.com/msx",

    //uri: "https://api-apex.medstatix.com",
    //graph_uri: "https://api-apex.medstatix.com/msx",




    idle_time: 300,

    login_message: {
        title: "",
        message: "",
    },

    support_email: "support@medstatix.com",
    dashboard_name: "apex Relationship Management",

    chat_message_polling_time_interval: 30000,
    allow_roles: ["provider", "superAdmin", "admin", "superProvider"],
    clio_careNav: "966A2E68-0BFD-46C5-82BB-E208D248CE4A",
    show_workflow_for_carenav_only: false,

    favorite_graphs: [
        {
            "surveyid": "B153A8F4 - 96A2- 46FE-9228 - 61BC627A33FA",
            "metricid": "24F40572 - 283A - 4FC3 - B478 - D1D6876FBB55",
            "metricname": "Aware of Other Care",
            "metricgroupid": "539BFB39 - 85CD - 47BD - 8523 - 5AD15F55C995",
            "metricgroupname": "Provider Communication", "name": "Aware of Other Care",
            "isFavorite": null, "selectedGroup": "9FFFD53A - 51CC - 44F1 - B52A-0661A9FE6CAB"
        },
        {
            "surveyid": "B153A8F4 - 96A2 - 46FE - 9228 - 61BC627A33FA",
            "metricid": "0323D09C - D02C - 42AD - 995B - 13FE6016B359",
            "metricname": "Balancing Computer Use",
            "metricgroupid": "539BFB39 - 85CD - 47BD - 8523 - 5AD15F55C995",
            "metricgroupname": "Provider Communication", "name": "Balancing Computer Use",
            "isFavorite": null, "selectedGroup": "9FFFD53A - 51CC - 44F1 - B52A-0661A9FE6CAB"
        }]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error',  // Included with Angular CLI.
