<div class="roomname" *ngIf="roomName">
    <a style="cursor: pointer" [routerLink]="['/waiting-room']">PATIENT ESCALATIONS</a>
    >
    {{ roomName }}
</div>
<div class="container-fluid">
    <div class="row mb-3">
        <div class="col-5">
            <div class="search-div">
                <input type="text" class="form-control search" [(ngModel)]="tableService.searchTerm" placeholder="Search..."  />
                 <img (click)="onRefresh()" src="/assets/msx/search.svg">
            </div>
        </div>
        <div class="col-1 pl-0">
            <div *ngIf="showLoader"  class="h-100 d-flex flex-column justify-content-center">
                <div class="spinner-border text-primary"
                        role="status">
                        <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="roomName">
            <button (click)="$event.stopPropagation(); onViewOptions()" class="float-right btn-line mb-1"><i
                    class="fas fa-list-alt"></i> View Options</button>
            <select style="cursor: pointer" *ngIf="isViewAll" [formControl]="statusFilter"
                class="form-control filter-select">
                <option value="" selected><i class="fas fa-list-alt"></i> View All</option>
                <option *ngFor="let item of viewAllDropdown.sort()" [value]="item">{{ item | titlecase }}</option>
            </select>
        </div>
    </div>
</div>


<div *ngIf="patients?.length" class="container-fluid">
    <table class="table table-striped datatable">
        <thead>
            <tr>
                <th [ngClass]="c === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                    sortable="{{ (c === 'actions' || c === 'chiefcomplaint') ? '' : c }}" (sort)="onSort($event)">{{
                    displayName(c) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of resultList$ | async">
                <td class="cursor-pointer" *ngFor="let c of displayedColumns; let i = index">
                    <span (click)="onPatientDashboard(p)" *ngIf="c === 'createdat'">
                        {{ p[c] | date: "MM/dd/yyyy, hh:mm a" }}
                    </span>
                    <span (click)="onPatientDashboard(p)" *ngIf="c === 'age'">
                        {{ p[c] === 0 ? "" : p[c] }}
                    </span>
                    <span [innerHTML]="p[c]" (click)="onPatientDashboard(p)" *ngIf="c === 'timing' || c === 'status'">
                        <!-- -- {{ p[c] | uppercase }} -->
                    </span>
                    <span (click)="onPatientDashboard(p)" *ngIf="c !== 'timing' || c !== 'status'">
                        {{ getReason(p,c) }}
                    </span>
                    <!-- <span (click)="onPatientDashboard(p)" *ngIf="c !== 'timing' || c !== 'status'">
                        {{ p.requestdata.otherDetails?.apmt_reason | uppercase }}
                    </span> -->
                    <div (click)="onPatientDashboard(p)"
                        *ngIf="c !== 'timing' && c !== 'status' && c !== 'age' && c !== 'createdat'" [innerHTML]="p[c]">
                    </div>
                    <span class="actions-cell" *ngIf="c === 'actions'">
                        <img *ngIf="p.status!==undefined && p.status.length>2 && p.status && p.status !== 'resolved' && !p.status.includes('referred')"
                        class="fa fa-arrows-alt cursor-pointer mr-3" (click)="onMovePatient(p)" title="Move Member" src="/assets/msx/statusmenu.svg">
                        <img *ngIf="p.status!==undefined && p.status.length>2 && p.status && p.status !== 'resolved' && !p.status.includes('referred')"
                        class="fa fa-comment-alt cursor-pointer mr-3" (click)="onViewPatient(p)" title="View Chat" src="/assets/msx/chat.svg">
                        <i *ngIf="p.status!==undefined && p.status.length>2 && p.status && p.status.includes('referred') && p.referralstatus === 'pending'"
                            class="fas fa-times cursor-pointer mr-3" (click)="onCancel(p)" title="Cancel Referral"></i>
                        <i *ngIf="p.status!==undefined && p.status.length>2 && p.notes || (isViewAll && p.referralnotes && p.status.includes('referred'))"
                            class="fas fa-sticky-note cursor-pointer mr-3" (click)="onNotes(p)" title="View Notes"></i>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="patients?.length > 0" class="d-flex justify-content-between p-2">
        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
            [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

        <select class="custom-select pagination"
            style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
            [(ngModel)]="tableService.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
        </select>
    </div>
</div>

<div class="no-record" *ngIf="roomName !== '' && (!patients || patients.length === 0)">No records found</div>