import { Component, OnInit } from "@angular/core";
import { EmployersService } from "../../services/employer.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEmployerModalComponent } from "../../modals/add-employer-modal/add-employer-modal.component";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";

@Component({
    selector: "app-employer",
    templateUrl: "./employer.component.html",
    styleUrls: ["./employer.component.scss"],
})
export class EmployerComponent implements OnInit {
    constructor(private employersService: EmployersService, private snackbar: SnackbarService, private modalService: NgbModal) {}
    public Employers = [];
    ngOnInit() {
        this.getEmployer();
    }
    public getEmployer() {
        this.employersService.getEmployers().subscribe(
            (data) => {
                this.Employers = data[0].attributes;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public addEmployer() {
        const modalRef = this.modalService.open(AddEmployerModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.Context = { edit: false, employer: {} };
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }

    public onDeleteEmployer(employer) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete the " + employer.employername + "?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.employersService.deleteEmployer(employer.employername).subscribe(
                    (data) => {
                        this.snackbar.show(data[0].attributes.data);
                        this.getEmployer();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
        });
    }

    public modalResponse(event) {
        if (event.success) {
            this.getEmployer();
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    public editEmployer(employer) {
        const modalRef = this.modalService.open(AddEmployerModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.Context = { edit: true, employer: employer };
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }
}
