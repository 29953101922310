import { Component, OnInit } from "@angular/core";
import { CareTeamService } from "../../../services/careteam.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CareTeamModalComponent } from "../../../modals/careTeam-modal/careTeam-modal.component";
import { WarningModalComponent } from "../../../modals/warning-modal/warning-modal.component";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";

// import 'rxjs/add/operator/map';
@Component({
    selector: "app-careteam",
    templateUrl: "./careteam.component.html",
    styleUrls: ["./careteam.component.scss"],
})
export class CareteamComponent implements OnInit {
    constructor(private careTeamService: CareTeamService, private modalService: NgbModal, private snackbar: SnackbarService) {}

    public careteams: any;
    public showCareTeamForm = false;
    ngOnInit() {
        this.loadData();
    }

    public loadData() {
        this.careTeamService.getCareteams().subscribe(
            (data) => {
                this.careteams = data[0].attributes;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    public onNewCareTeam() {
        const modalRef = this.modalService.open(CareTeamModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.CareTeamData = { action: "create", careteam: { careteamname: "", address1: "", address2: "", city: "", state: "", postalcode: "" } };
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }

    public onEditCareTeam(careteam) {
        const modalRef = this.modalService.open(CareTeamModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.CareTeamData = { action: "edit", careteam: Object.assign({}, careteam) };
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }

    public onDeleteCareTeam(careteam) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete Care Team " + careteam.careteamname + " and all associated data?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.careTeamService.deleteCareTeam(careteam.careteamid).subscribe(
                    (data) => {
                        this.modalResponse({ success: true, message: "Care Team deleted" });
                    },
                    (err) => {
                        this.modalResponse({ success: false, message: err.message });
                    }
                );
            }
        });
    }

    public modalResponse(event) {
        if (event.success) {
            this.loadData();
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
        if (event.reset) {
            this.loadData();
        }
    }
}
