<div class="modal-body">
    <div class="category-header">

    </div>
    <div class="body">
        <p class="service_recovery">
            <span>Service Recovery
                ID:</span>{{message.additionaldata.surveydata.servicerecoveryid}}<br>
            <span>Patient
                ID:</span>{{message.additionaldata.surveydata.practicepatientid}}<br>
            <span>Patient Name:</span>{{message.additionaldata.surveydata.name}}<br>
            <span>Patient
                Phone:</span>{{message.additionaldata.surveydata.phone}}<br>
            <span>Patient
                Email:</span>{{message.additionaldata.surveydata.email}}<br>
            <span>Visit
                date:</span>{{message.additionaldata.surveydata.visitdate}}<br>
            <span>Provider:</span>{{message.additionaldata.surveydata.fullname}}<br>
            <span>Location:</span>{{message.additionaldata.surveydata.name}}<br>

        </p>


        <div class="others">
            <div class="others-label">
                <label>Internal Note</label>
            </div>
            <div class="others-text">
                <textarea type="text" class="others-textarea form-control" placeholder="Please describe">
                </textarea>
            </div>
        </div>
    </div>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary">Done</button>
        </div>
    </div>
</div>