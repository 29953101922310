import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "environmentalallergies-modal",
    templateUrl: "./environmentalallergies-modal.component.html",
    styleUrls: ["./environmentalallergies-modal.component.scss"],
})
export class EnvironmentalAllergiesModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    error = "";
    public checklist: Array<any> = [];
    public others: string = "";

    ngOnInit() {
        this.checklist = [
            {
                name: "Animals",
                value: false,
                subset: [
                    {
                        name: "Cat",
                        value: false,
                    },
                    {
                        name: "Dog",
                        value: false,
                    },
                    {
                        name: "Feathers",
                        value: false,
                    },
                    {
                        name: "House Dust Mite",
                        value: false,
                    },
                    {
                        name: "Insect Sting",
                        value: false,
                    },
                ],
            },
            {
                name: "Chemicals",
                value: false,
                subset: [
                    {
                        name: "Cosmetics",
                        value: false,
                    },
                    {
                        name: "Formaldehyde",
                        value: false,
                    },
                    {
                        name: "Fungicide",
                        value: false,
                    },
                    {
                        name: "Perfume",
                        value: false,
                    },
                    {
                        name: "Photographic Developers",
                        value: false,
                    },
                ],
            },
            {
                name: "Metals",
                value: false,
                subset: [
                    {
                        name: "Chromium",
                        value: false,
                    },
                    {
                        name: "Cobalt",
                        value: false,
                    },
                    {
                        name: "Gold",
                        value: false,
                    },
                    {
                        name: "Nickel",
                        value: false,
                    },
                ],
            },
            {
                name: "Mold",
                value: false,
            },
            {
                name: "Plants",
                value: false,
                subset: [
                    {
                        name: "Pollen",
                        value: false,
                    },
                    {
                        name: "Trees",
                        value: false,
                    },
                    {
                        name: "Weeds",
                        value: false,
                    },
                ],
            },
            {
                name: "Other",
                value: false,
                subset: [
                    {
                        name: "Latex",
                        value: false,
                    },
                    {
                        name: "Semen",
                        value: false,
                    },
                ],
            },
        ];

        this.setupForm();
    }
    setupForm() {
        let pmh = this.SummaryData.summary.environmentalallergies.allergies;

        if (pmh && pmh.length > 0) {
            pmh.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = true;

                        if (item.subset && item.subset.length > 0) {
                            if (c.subset && c.subset.length > 0) {
                                item.subset.forEach((isub) => {
                                    c.subset.forEach((csub) => {
                                        if (isub.name === csub.name) {
                                            csub.value = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            });
        }
        if (this.SummaryData.summary.environmentalallergies.other && this.SummaryData.summary.environmentalallergies.other.trim().length > 0) {
            this.others = this.SummaryData.summary.environmentalallergies.other;
        }
    }

    isChecklistValid(): boolean {
        let isValid = true;
        for (let i = 0; i < this.checklist.length; i++) {
            if (this.checklist[i].value === true) {
                if (this.checklist[i].subset && this.checklist[i].subset.length > 0) {
                    let selectedSub = this.checklist[i].subset.filter((i) => i.value === true);
                    if (selectedSub.length === 0) {
                        isValid = false;
                        if (this.checklist[i].name === "Other" && this.others.trim().length > 0) {
                            isValid = true;
                        }
                        if (!isValid) {
                            break;
                        }
                    }
                }
            }
        }

        if (!isValid) {
            this.error = "Please make sure sub-categories are also selected.";
        }

        return isValid;
    }

    getSelectedData() {
        let selectedData = [];

        this.checklist.forEach((item) => {
            if (item.value === true) {
                let i = item;

                if (item.subset) {
                    let s = [];

                    item.subset.forEach((sub) => {
                        if (sub.value === true) {
                            s.push(sub);
                        }
                    });

                    i.subset = s;
                }

                selectedData.push(i);
            }
        });
        return selectedData;
    }
    public onSave() {
        if (this.isChecklistValid()) {
            this.SummaryData.summary.environmentalallergies = { allergies: this.getSelectedData() };

            if (this.others.trim().length > 0) {
                this.SummaryData.summary.environmentalallergies["other"] = this.others;
            }
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary, section: "environmental" });
            this.activeModal.close();
        }
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary, section: "environmental" });
        this.activeModal.close();
    }
}
