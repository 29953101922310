<div class="modal-content">
    <div class="modal-header text-white">
        <h5 class="modal-title">
            Change Care Team
        </h5>
        <button type="button" class="close" (click)="onCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="input-group mb-3 col">
                <div class="input-group-prepend">
                    <span class="input-group-text">Careteam:</span>
                </div>
                <div *ngFor="let c of careteams"></div>
                <select [(ngModel)]="currentCareteam" required class="form-control">
                    <option *ngFor="let c of careteams" [ngValue]="c">{{ c.careteamname }}</option>
                </select>
            </div>
        </div>

        <ngb-alert *ngIf="errorMessage.length" [dismissible]="false" [type]="'danger'" class="mb-0">
            {{ errorMessage }}
        </ngb-alert>
    </div>
    <div class="modal-footer">
        <button (click)="onSubmit()" type="button" class="btn btn-primary">Change Care Tseam</button>
        <button type="button" id="closeBtn" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    </div>
</div>
