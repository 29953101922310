<div class="modal-body">
    <div class="category-header">
        <label>Environmental Allergies</label>
        <p>Please check all that apply.</p>
    </div>
    <div class="main-row">
        <div class="row checklist-row">
            <div class="col-lg-12 checklist-columns" *ngFor="let item of checklist; let i = index">
                <div class="check-item-main">
                    <input
                        class="radio-checklist"
                        type="checkbox"
                        name="checklist"
                        id="checkbox_{{ i }}"
                        [(ngModel)]="checklist[i].value"
                    />
                    <label for="checkbox_{{ i }}" class="radio-checkbox-label"> {{ item.name }} </label>
                </div>
                <div class="subset" *ngIf="item.subset" [hidden]="!item.value">
                    <div class="check-subitem-main" *ngFor="let subitem of checklist[i].subset; let j = index">
                        <input
                            class="radio-checklist"
                            type="checkbox"
                            name="checklist"
                            id="checkbox_{{ i }}_{{ j }}"
                            [(ngModel)]="checklist[i].subset[j].value"
                        />
                        <label for="checkbox_{{ i }}_{{ j }}" class="radio-checkbox-label"> {{ subitem.name }} </label>
                    </div>
                </div>
                <div *ngIf="item.name === 'Other'" [hidden]="!item.value" class="subset others">
                    <div class="others-text">
                        <textarea
                            [(ngModel)]="others"
                            type="text"
                            class="others-textarea form-control"
                            placeholder="Please describe"
                        >
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <p *ngIf="error" class="text-danger">{{ error }}</p>
    </div>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>    
    </div>
</div>