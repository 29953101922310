<div class="container-fluid p-0">
    <div class="inbox_msg">

        <!-- <div *ngIf="markAsUnreadButton" id="messageFilter" [ngClass]="{ 'scroll-shadow': showScrollShadow }">
            <div style="padding-right: 0px;" class="col-12 py-1">
                <button *ngIf="!showSelectionMessage" (click)="markAsUnread()"
                    class="btn btn-primary float-right ml-1 btnunread">Mark Last Message As Unread</button>
            </div>
        </div> -->

        <div *ngIf="!showForPatientDashBoard && showFilters" id="messageFilter"
            [ngClass]="{ 'scroll-shadow': showScrollShadow }">
            <div class="input-group col-3 py-3 float-left" style="margin-top: -4px">
                <div class="input-group-prepend">
                    <span class="input-group-text">Filter:</span>
                </div>
                <select [(ngModel)]="channel" class="form-control custom-select" (change)="scrollDown()">
                    <option value="">--ALL--</option>
                    <option *ngFor="let t of non_priorities_tags" [ngValue]="t">
                        {{ t.tag }}
                    </option>
                </select>
            </div>
            <div *ngIf="this.currentPatient.patientid" style="margin-top: -4px" class="col-9 py-3">
                <button (click)="openPatientDashboard()" class="btn btn-primary float-right ml-1">View Member</button>
                <button (click)="onAddSymptoms()" class="btn btn-primary float-right ml-1">New Case</button>
                <button *ngIf="!showSelectionMessage" (click)="markAsUnread()"
                    class="btn btn-primary float-right ml-1 btnunread">Mark Last Message As Unread</button>
                <!-- <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-primary" id="dropdownMenuButton" ngbDropdownToggle>Action</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <button (click)="openPatientDashboard()" ngbDropdownItem>View Member</button>
                        <button (click)="onAddSymptoms()" ngbDropdownItem>Create New Case</button>
                        <button *ngIf="!showSelectionMessage" (click)="markAsUnread()" ngbDropdownItem>Mark Last Message As Unread</button>
                    </div>
                </div> -->
            </div>
            <!-- <div *ngIf="!showSelectionMessage" class="col-4 py-3"></div> -->
            <!-- <div *ngIf="this.currentPatient.patientid" class="col-3 py-3">
                <button (click)="openPatientDashboard()" class="btn btn-primary float-right">View Member</button>
            </div> -->
            <!--
              <div class="input-group col-4 py-3 float-left">
                  <div class="input-group-prepend">
                      <span class="input-group-text">Priority:</span>
                  </div>
               <select (change)="updatePriority()" [(ngModel)]="priority" class="form-control custom-select">
                      <option disabled value="">NONE</option>
                      <option>ELEVATED</option>
                      <option>LOW</option>
                      <option>MODERATE</option>
                      <option>URGENT</option>
                  </select>
              </div>
              -->

            <div style="display: block; position: relative; text-align: right"
                class="input-group col-3 py-3 float-left">
                <!-- <a [routerLink]="['/video-chat']"> -->
                <!-- <button (click)="videoChat()" class="btn btn-success call"><i class="fas fa-video"></i></button> -->
            </div>
            <div class="col-4 py-3 float-left">
                <!-- <button (click)="loadMore()" class="btn btn-light float-right gray">load more <i class="fa fa-arrow-circle-up" aria-hidden="true"></i></button> -->
            </div>

            <!-- Loading more messages banner -->
            <div *ngIf="loadingMoreMessages" class="loading-messages-alert">
                <div class="text-center py-3">Loading more messages</div>
            </div>
        </div>
        <div *ngIf="showSearch" class="container search-text">
            <div class="row">
                <div class="col-sm">
                    Search result for: {{searchtext}}
                </div>
                <div style="color: #0A529E;" class="col-sm">
                    Found {{searchFound.length}} {{searchFound.length<2?"message":"messages"}} &nbsp; <a
                        *ngIf="current_index!==0" href="#" (click)="$event.preventDefault(); scrollToPrevious()">
                        <i href="#" class="fa fa-arrow-circle-left"></i>
                        </a>
                        <a *ngIf="current_index===0 && searchFound.length!==0">
                            <i style="color: gray;" class="fa fa-arrow-circle-left"></i>
                        </a>
                        &nbsp;
                        <a *ngIf="current_index!==searchFound.length-1 && searchFound.length!==0" href="#"
                            (click)="$event.preventDefault(); scrollToNext()">
                            <i class="fa fa-arrow-circle-right"></i>
                        </a>
                        <a *ngIf="current_index===searchFound.length-1 && searchFound.length!==0">
                            <i style="color: gray;" class="fa fa-arrow-circle-right"></i>
                        </a>
                        &nbsp;
                        <a href="#" (click)="$event.preventDefault();hideSearch()">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </a>
                </div>
            </div>
        </div>
        <div *ngIf="showchatWindow">
            <!-- Message history window -->
            <div class="row">
                <div [ngClass]="showForPatientDashBoard ? 'col-8' : 'col-full'" #messageHistory id="scrollMe"
                    class="msg_history" smartHeight="{{
                        showForPatientDashBoard || !showFilters
                            ? windoHeight
                            : 'calc(73vh - (#globalAlert + #navbar + #messageFilter + #messageInput + #patientManageInfo + #patientManageTabs + #messageAttachmentContainer))'
                    }}" smartLoadMore (changed)="smartLoadMore($event)" (scroll)="onMessagesScroll($event)">
                    <!-- Messages -->
                    <div *ngFor="let message of Messages | chanelType: channel">
                        <!-- <app-chat-bubble [message]="message" [patient]="currentPatient" [tags]="tags"></app-chat-bubble> -->

                        <!-- Member Message -->
                        <ng-container *ngIf="message.authorid === patientId">
                            <div [attr.id]="message.id"
                                [ngClass]=" message.searchAvailable?'search_found':'search_not_found'"
                                class="patient_msg">
                                <div class="chat_img">
                                    <img [src]="(message?.patient_avatar !== null && message?.patient_avatar!==undefined) ? message.patient_avatar : '../../assets/images/doctor.png'"
                                        alt="" />
                                </div>
                                <div [ngClass]="{'active_message': message.id === selected_message,'searchAvailable':message.searchAvailable}"
                                    [ngStyle]="{ width: showForPatientDashBoard ? '90%' : '320px' }" class="msg">
                                    <i *ngIf="message.messagetype === 'text-goal'" style="color: #0d32ff !important"
                                        class="fa fa-trophy fa_custom fa-2x" aria-hidden="true"></i>

                                    <!-- <a (click)="showImage(message.attachments[0], message.fileExtension)" *ngIf="message.messagetype === 'attachment' || message.messagetype === 'text-attachment'">
                                --
                                <img
                                    style="max-width: 50%; cursor: pointer;"
                                    *ngIf="(message.messagetype === 'attachment' || message.messagetype === 'text-attachment') && message.status === 11"
                                    [src]="msg.attachments[0] | safe: 'url'"
                                />
                            </a> -->
                                    <a (click)="showImage(message.attachments[0], message.fileExtension)"
                                        *ngIf="message.messagetype === 'attachment' || message.messagetype === 'text-attachment'">
                                        <img style="max-width: 90%; cursor: pointer" *ngIf="
                                                (message.messagetype === 'attachment' || message.messagetype === 'text-attachment') && message.status !== 11 && message.fileExtension !== 'pdf';
                                                else notImage
                                            " [src]="message.attachments[0] | safe: 'url'"
                                            (load)="imageLoaded($event)" />
                                        <ng-template #notImage>
                                            <i style="font-size: 20px; margin: 10px 15px; cursor: pointer"
                                                class="fa fa-paperclip" aria-hidden="true"></i>
                                        </ng-template>
                                    </a>
                                    <p *ngIf="message.message"
                                        [innerHTML]="message.message | filterMessage: message | urlify | safe: 'html'">
                                    </p>


                                    <p class="service_recovery"
                                        *ngIf="message.additionaldata && message.additionaldata.surveydata">
                                        <span>Service Recovery
                                            ID:</span>{{message.additionaldata.surveydata.servicerecoveryid}}<br>
                                        <span>Patient
                                            ID:</span>{{message.additionaldata.surveydata.practicepatientid}}<br>
                                        <span>Patient Name:</span>{{message.additionaldata.surveydata.name}}<br>
                                        <span>Patient
                                            Phone:</span>{{message.additionaldata.surveydata.phone}}<br>
                                        <span>Patient
                                            Email:</span>{{message.additionaldata.surveydata.email}}<br>
                                        <span>Visit
                                            date:</span>{{message.additionaldata.surveydata.visitdate}}<br>
                                        <span>Provider:</span>{{message.additionaldata.surveydata.fullname}}<br>
                                        <span>Location:</span>{{message.additionaldata.surveydata.name}}<br>
                                        <button *ngIf="!showLoading" (click)="openServiceRecovery(message)" class="btn">
                                            <img src="/assets/msx/servicerecovery.svg">
                                            Open in Service Recovery
                                        </button>
                                        <button *ngIf="showLoading" class="btn btn-primary" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>


                                    </p>
                                    <span class="time_date">{{ message.datetimeutc | date: "MMM d,y, h:mm:ss a" }} by
                                        {{
                                        message.authorname }}</span>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Provider Message -->
                        <ng-container *ngIf="message.authorid !== patientId">
                            <div [attr.id]="message.id" class="provider_msg"
                                [ngClass]="message.searchAvailable?'search_found':'search_not_found'"
                                [ngClass]="{'deleted_msg': message.status === -1}">
                                <div class="chat_img">
                                    <img [src]="message?.author_avatar === null || message?.author_avatar === undefined? '../../assets/images/doctor.png' : message.author_avatar"
                                        (error)="onError()" alt="" />
                                    <div *ngIf="message.tags!=='*ChatBot'"
                                        (click)="onDeleteContextMenu(message, $event)" class="symbol small author_img">
                                        ...</div>
                                    <div>
                                        <ng-container *ngIf="message.isDeleteContextMenuOpen">
                                            <app-contextmenu [showDelete]="true" [message]="message">
                                            </app-contextmenu>
                                        </ng-container>
                                    </div>
                                </div>
                                <div [ngClass]="{'active_message': message.id === selected_message,'searchAvailable':message.searchAvailable}"
                                    [ngClass]="{'deleted_message': message.status === -1}"
                                    [ngStyle]="{ width: showForPatientDashBoard ? '90%' : '320px' }"
                                    style="position: relative" class="msg">
                                    <i *ngIf="message.messagetype === 'text-goal'" style="color: #0d32ff !important"
                                        class="fa fa-trophy fa_custom fa-2x" aria-hidden="true"></i>
                                    <a (click)="showImage(message.attachments[0], message.fileExtension)"
                                        *ngIf="message.messagetype === 'attachment' || message.messagetype === 'text-attachment'">
                                        <img style="max-width: 90%; cursor: pointer"
                                            *ngIf="message.messagetype === 'attachment' && message.status !== 11 && message.fileExtension !== 'pdf'; else notImage"
                                            [src]="message.attachments[0] | safe: 'url'" (load)="imageLoaded($event)" />

                                        <ng-template #notImage>
                                            <i style="font-size: 20px; margin: 10px 15px; cursor: pointer"
                                                class="fa fa-paperclip" aria-hidden="true"></i>
                                        </ng-template>
                                    </a>
                                    <p [innerHTML]="message.message | filterMessage: message | urlify | safe: 'html'">
                                    </p>
                                    <span class="time_date">{{ message.datetimeutc | date: "MMM d,y, h:mm:ss a" }} by
                                        {{
                                        message.authorname }}</span>
                                    <div class="readat">
                                        <span class="time_date">{{ message.readat | date: "MMM d,y, h:mm:ss a"
                                            }}</span>
                                        &nbsp; <i *ngIf="message?.readat && message.readat !== null"
                                            class="fas fa-check-double"></i>
                                    </div>

                                    <div *ngIf="showContextOption(message.tags)"
                                        (click)="onContextMenu(message, $event)" class="symbol small">⋮</div>

                                    <!-- Message tags context menu -->
                                    <ng-container *ngIf="message.isContextMenuOpen">
                                        <app-addremove-tags (updateTags)="updateTags(message)" [messageId]="message.id"
                                            [data]="menuData" [relative]="true">
                                        </app-addremove-tags>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="isLoadingMessages"
                        class="h-100 d-flex flex-column justify-content-center align-items-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="mt-2">Loading messages</div>
                    </div>

                    <div *ngIf="showSelectionMessage"
                        class="h-100 d-flex flex-column justify-content-center align-items-center">
                        <div class="mt-2">Please select a member from the left to chat.</div>
                    </div>
                </div>
                <div *ngIf="showForPatientDashBoard" class="col-4 pl-0 pr-0">
                    <app-media-library [patientId]="patientId" [careteamId]="careTeamId"></app-media-library>
                </div>
                <a *ngIf="!showSelectionMessage" class="search-btn" href="#"
                    (click)="$event.preventDefault(); showSearchBox()">
                    <i class="fas fa-search"></i>
                </a>

            </div>
            <!-- <div style="height: 10px" class="row"></div> -->
            <!-- Unread messages floating alert (similar to how slack does it)-->
            <!-- <div *ngIf="showUnreadMessagesAlert" (click)="scrollDown()"
      class="alert alert-primary unread-messages-alert clickable">
      {{ unreadMessageCount }} unread messages
    </div> -->

            <div id="messageInput" [class.provider_only]="providerOnly" [class.patient_included]="!providerOnly"
                class="type_msg">
                <!-- <div class="form-group">
              <input id="ajax_search_input" class="span2" id="prependedInput" type="text" placeholder="Username" />
              <span class="label label-danger" id="#danger">Danger</span>
          </div> -->

                <!-- Unread messages floating alert (similar to how slack does it)-->
                <div *ngIf="showUnreadMessagesAlert" (click)="scrollDown(false, true)"
                    class="alert alert-primary unread-messages-alert clickable">{{ unreadMessageCount }} unread
                    messages
                </div>

                <div *ngIf="!hideProviderOnly" style="position: relative" class="input_msg_write">
                    <div style="border-radius: 5px;" class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="provideronly" [(ngModel)]="providerOnly"
                            [disabled]="isLoadingMessages || showSelectionMessage" />
                        <label [ngStyle]="{ color: providerOnly ? '#C3C3C3' : '' }"
                            class="custom-control-label patientIncluded" for="provideronly">Patient Included</label>
                        <label [ngStyle]="{ color: providerOnly ? '' : '#C3C3C3' }"
                            class="custom-control-label providerOnly" for="provideronly">Provider Only</label>
                    </div>
                    <div (click)="disableContextMenu()" oncontextmenu="return false;">
                        <div *ngIf="contextmenu === true">
                            <app-contextmenu (selectedTag)="getSelectedTag($event)" [data]="contextmenuData"
                                [searchKey]="searchKey" [x]="contextmenuX + 100" [y]="contextmenuY + 20">
                            </app-contextmenu>
                        </div>
                    </div>

                    <div class="write_msg_container">
                        <textarea #messageBox [ngStyle]="{ 'padding-left': selected_tag.length ? '100px' : '' }"
                            [(ngModel)]="_message" (input)="inputMessage($event)"
                            (keydown)="getCaretPos(messageBox, $event)" class="write_msg" placeholder="Type a message"
                            style="resize: none;color: black;" [disabled]="isLoadingMessages || showSelectionMessage"
                            spellcheck="true" autosize [minRows]="1">
                        </textarea>
                        <button *ngIf="!showSelectionMessage" (click)="attachment()" class="msg_attachment"
                            type="button" [disabled]="isLoadingMessages || showSelectionMessage">
                            <i [ngStyle]="{ color: providerOnly ? '#0A529E' : '#FFA19A' }" class="fas fa-images"
                                title="attachment" aria-hidden="true"></i>&nbsp;
                        </button>
                        <button *ngIf="!showSelectionMessage" (click)="videoChat()" class="msg_video">
                            <i class="fa fa-video" title="video chat" aria-hidden="true"></i>
                        </button>
                        <div style="display: none">
                            <label for="chatfilePicker"></label><br />
                            <input onclick="this.value = null" (change)="handleFileSelect1($event)" type="file"
                                id="chatfilePicker" />
                        </div>
                    </div>

                    <div>
                        <button style="background: transparent;" (click)="postMessage()" class="msg_send_btn"
                            type="button" [disabled]="!_message.length">
                            <i [ngStyle]="{ color: _message.length ? '#fff' : '#DDD' }" class="fa fa-paper-plane"
                                title="message" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div id="messageAttachmentContainer">
                <div *ngIf="is_attachment_msg" class="row">
                    <div class="attachment type_msg">
                        <div class="type_msg" style="width: 200px">
                            <div *ngIf="isImageAttachment">
                                <img style="width: 100%" *ngIf="is_attachment_msg" [src]="url"
                                    (load)="imageLoaded($event)" />
                            </div>

                            <button [disabled]="showLoading" (click)="removeAttachment()" class="msg_remove_attachment"
                                type="button">
                                <i class="fa fa-times" title="remove" aria-hidden="true"></i>
                            </button>
                            <app-loader *ngIf="showLoading"></app-loader>
                            <!-- <img style="height: 25px" *ngIf="showLoading" [src]="environment.loadingImg" /> -->

                            <div style="text-align: center" *ngIf="!isImageAttachment">
                                <i style="font-size: 50px; margin: 15px 45px" class="fa fa-paperclip"
                                    aria-hidden="true"></i>
                                {{ fileName }}
                            </div>
                        </div>
                        <button (click)="sendAttachment()" [disabled]="showLoading" class="msg_send_attachment"
                            type="button"><i class="fa fa-paper-plane" title="send" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<app-snackbar></app-snackbar>