import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SnackbarService } from '../../../../../shared/snackbar/snackbar.service';
import { MediaService } from '../../../../../services/media.service';
import { TableService } from "../../../../../services/table.service";
import { SortableHeaderDirective } from "../../../../../directives/sortable.directive";
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../../../../../app/modals/warning-modal/warning-modal.component';
import { ReviewModalComponent } from './review-modal/review-modal.component';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit, OnDestroy {

  resultList$: Observable<any[]>;
  total$: Observable<number>;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  constructor(private snackbar: SnackbarService,
    private mediaService: MediaService,
    public tableService: TableService,
    private modalService: NgbModal
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "provider_name";
    this.tableService.pageSize = 50;
    this.tableService.searchTerm = "";
  }
  reviews = [];
  displayedColumns = [
    { displayname: "Date", columnname: "createdat" },
    { displayname: "Comment", columnname: "review" },
    { displayname: "Provider", columnname: "provider_name" },
    { displayname: "Platform", columnname: "socialmedia" },
    { displayname: "Action", columnname: "" },
  ];
  filterBy = "all";
  showLoader = true;
  search = "";
  ngOnInit(): void {
    this.getData();
  }
  public getData(status = "all") {
    this.mediaService.GetSocialMediaReviews(status).subscribe(
      (data) => {
        this.reviews = data[0].attributes.data.reviews;
        this.tableService.DATA = this.reviews;
        this.sorting("date", "desc");
        this.showLoader = false;
      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
        this.showLoader = false;

      }
    );
  }

  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }
  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
    this.tableService.searchOn = "socialmedia";
  }
  public filterByStstus(status = "all") {
    this.filterBy = status;
    this.getData(status);
  }

  public onIgnored(smrid, isignored) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = !isignored ? "Ignore this comment?" : "clear this comment?";
    modalRef.componentInstance.Header = false;
    modalRef.componentInstance.OKButton = !isignored ? "Ignore" : "clear";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.mediaService.Ignored(smrid, isignored).subscribe(
          (data) => {
            this.getData(this.filterBy);
          },
          (err) => {
            this.snackbar.show("error occurred please try again later.", "danger");
          }
        );
      }
    });
  }

  public onReview(reviews) {
    const modalRef = this.modalService.open(ReviewModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
    modalRef.componentInstance.Review = reviews;
    // modalRef.componentInstance.Header = false;
    // modalRef.componentInstance.OKButton = !isignored ? "1Ignore" : "clear";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      this.getData(this.filterBy);
      if (receivedEntry.proceed) {
        // this.mediaService.Ignored(smrid, isignored).subscribe(
        //   (data) => {
        //     this.getData();
        //   },
        //   (err) => {
        //     this.snackbar.show("error occurred please try again later.", "danger");
        //   }
        // );
      }
    });
  }

  public Search(name: any): void {

  }
  clearSearch() {
    this.Search(this.search);
  }
  ngOnDestroy() {
    this.tableService.DATA = [];
  }

}
