import { Component, OnInit } from "@angular/core";
import { AppsService } from "../../services/apps.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProviderAppModalComponent } from "../../modals/providerapp-modal/providerapp-modal.component";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-apps",
    templateUrl: "./apps.component.html",
    styleUrls: ["./apps.component.scss"],
})
export class AppsComponent implements OnInit {
    constructor(private apps: AppsService, private sessionService: SessionService, private snackbar: SnackbarService, private modalService: NgbModal) {}
    public appList: any;
    ngOnInit() {
        this.loadApps();
    }

    public loadApps() {
        this.apps.getProviderApps().subscribe(
            (data) => {
                this.appList = data[0].attributes.apps;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public showApp(url, title) {
        url = url.replace("~CHID~", this.sessionService.currentUser?.id !== null ? this.sessionService.currentUser?.id : "");
        url = url.replace("~EMAIL~", this.sessionService.currentUser?.attributes.email !== null ? this.sessionService.currentUser?.attributes.email : "");
        url = url.replace("~CTID~", this.sessionService.activeCareTeam.careteamid !== null ? this.sessionService.activeCareTeam.careteamid : "");
        url = url.replace("~AUTH~", this.sessionService.currentUser?.id !== null ? this.sessionService.currentUser?.attributes.token : "");

        const modalRef = this.modalService.open(ProviderAppModalComponent, { size: "xl", windowClass: "modal-large", backdrop: "static" });
        modalRef.componentInstance.appUrl = url;
        modalRef.componentInstance.appTitle = title;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) this.snackbar.show(receivedEntry.message);
            else this.snackbar.show(receivedEntry.message, "danger");
        });
    }
}
