<div class="container-fluid p-5" style="padding-top: 10px !important">
    <div class="row">
        <div style="display: block" class="input-group col-12">
            <a [routerLink]="['/patients']" class="btn btn-primary float-right">Members</a>
        </div>
    </div>
    <div class="row mt-3">
        <div>
            <p>This functionality will allow you to mass onboard users from a CSV file. The CSV file must have all of
                the following fields. If optional, there still must be a blank field:</p>
            <ul>
                <li>email (required)</li>
                <li>firstname (required)</li>
                <li>lastname (required)</li>
                <li>DOB (required)</li>
                <li>gender (male/female, optional)</li>

                <li>ZIP Code (optional)</li>
                <li>employer (optional)</li>
                <li>send onboarding email (0/1, optional)</li>
            </ul>
        </div>
    </div>
    <div style="margin-top: 30px; padding-right: 0px; padding: 0px" class="row mt-3 mr-0 col-12">
        <div style="text-align: right; padding: 0px" class="col-12 mb-3">
            <!-- <button type="submit" class="btn btn-primary">Save</button> -->
            <input type="file" class="btn btn-primary float-left" name="Choose File" (change)="chooseFile($event)"
                accept=".csv" />
            <button *ngIf="isValidCsv" [disabled]="btnDisabled" (click)="uploadFile()"
                class="btn btn-primary float-right">Upload</button>
        </div>
    </div>
</div>