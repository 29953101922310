import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";
import { SymptomCategoryModalComponent } from "../../../modals/symptom-category/symptom-category-modal/symptom-category-modal.component";
import { SymptomENTModalComponent } from "../../../modals/symptom-category/symptom-ent-modal/symptom-ent-modal.component";
import { SymptomGasModalComponent } from "../../../modals/symptom-category/symptom-gas-modal/symptom-gas-modal.component";
import { SymptomHeartLungModalComponent } from "../../../modals/symptom-category/symptom-heartlung-modal/symptom-heartlung-modal.component";
import { SymptomNeuroModalComponent } from "../../../modals/symptom-category/symptom-neuro-modal/symptom-neuro-modal.component";
import { SymptomMusculoModalComponent } from "../../../modals/symptom-category/symptom-musculo-modal/symptom-musculo-modal.component";
import { SymptomObGynModalComponent } from "../../../modals/symptom-category/symptom-ob-gyn-modal/symptom-ob-gyn-modal.component";
import { SymptomSkinModalComponent } from "../../../modals/symptom-category/symptom-skin-modal/symptom-skin-modal.component";
import { SymptomUroModalComponent } from "../../../modals/symptom-category/symptom-uro-modal/symptom-uro-modal.component";
import { SymptomOtherModalComponent } from "../../../modals/symptom-category/symptom-other-modal/symptom-other-modal.component";
import { SymptomConfirmModalComponent } from "../../../modals/symptom-category/symptom-confirm-modal/symptom-confirm-modal.component";

@Component({
    selector: "symptom-general-modal",
    templateUrl: "./symptom-general-modal.component.html",
    styleUrls: ["./symptom-general-modal.component.scss"],
})
export class SymptomGeneralModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private patientService: PatientService, private snackbar: SnackbarService, private modalService: NgbModal) {}

    @Input() SymptomsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    checklist: Array<any> = [];
    public others: string = "";
    public temperature: number;
    public error = "";
    public symptoms: any = {};
    private feverSelected: boolean = false;
    public symptomData: any = { symptom: {}, userAttributes: {} };

    ngOnInit() {
        this.symptoms = this.SymptomsData.symptoms;
        this.checklist = [
            {
                name: "Abdominal Pain",
                value: false,
            },
            {
                name: "Runny Nose",
                value: false,
            },
            {
                name: "Back Pain",
                value: false,
            },
            {
                name: "Shortness of Breath",
                value: false,
            },
            {
                name: "Chills/Shivering",
                value: false,
            },
            {
                name: "Sore Throat",
                value: false,
            },
            {
                name: "Cough",
                value: false,
            },
            {
                name: "Sweating",
                value: false,
            },
            {
                name: "Dizziness/Vertigo",
                value: false,
            },
            {
                name: "Tiredness/Fatigue",
                value: false,
            },
            {
                name: "Fever",
                value: false,
                temperature: true,
            },
            {
                name: "Weakness",
                value: false,
            },
            {
                name: "Painful or Swollen Lymph Node or Gland",
                value: false,
            },
            {
                name: "Weight Loss",
                value: false,
            },
        ];
        this.setupForm();
    }
    setupForm() {
        let gen = this.symptoms.general;

        if (gen && gen.reasons && gen.reasons.length > 0) {
            gen.reasons.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = true;

                        if (item.name === "Fever") {
                            this.temperature = parseInt(item.value);
                        }

                        if (item.subset && item.subset.length > 0) {
                            if (c.subset && c.subset.length > 0) {
                                item.subset.forEach((isub) => {
                                    c.subset.forEach((csub) => {
                                        if (isub.name === csub.name) {
                                            csub.value = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            });
        }
        if (this.symptoms.general && this.symptoms.general.other && this.symptoms.general.other.trim().length > 0) {
            this.others = this.symptoms.general.other;
        }
    }
    changeTriggered(k, v) {
        if (k === "Fever") {
            this.feverSelected = v;
        }
    }

    getSelectedData() {
        let selectedData = [];

        this.checklist.forEach((item) => {
            if (item.value === true) {
                let i = { name: item.name, value: item.value };

                if (item.subset) {
                    let s = [];

                    item.subset.forEach((sub) => {
                        if (sub.value === true) {
                            s.push(sub);
                        }
                    });

                    i["subset"] = s;
                }
                selectedData.push(i);
            }
        });

        return selectedData;
    }

    onSubmit() {
        let selection = this.getSelectedData();

        if ((this.feverSelected && this.temperature !== null && this.temperature > 89 && this.temperature < 111) || !this.feverSelected) {
            if (selection.length > 0 || this.others.length > 0) {
                this.symptoms["general"] = {
                    reasons: selection,
                };

                if (this.temperature) {
                    for (let i = 0; i < this.symptoms.general.reasons.length; i++) {
                        if (this.symptoms.general.reasons[i].name === "Fever") {
                            this.symptoms.general.reasons[i].value = this.temperature;
                        }
                    }
                }

                if (this.others.trim().length > 0) {
                    this.symptoms.general["other"] = this.others;
                }
                //
                this.SymptomsData.symptoms = this.symptoms;
                this.openCategoryModal(true, "", false);
                this.activeModal.close();
            } else {
                this.error = "Please select/enter atleast one reason for visit.";
            }
        } else {
            this.error = "Please enter a valid temperature value between 90 to 110.";
        }
    }

    public openCategoryModal(success, message, isSaved) {
        if (success && !isSaved && message === "") {
            this.symptomData = this.SymptomsData;
            const modalRef = this.modalService.open(SymptomCategoryModalComponent, { size: "lg", backdrop: "static" });
            modalRef.componentInstance.SymptomsData = this.symptomData;
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.symptomCategoryModalResponse(receivedEntry);
            });
        } else if (success && isSaved) {
            this.Response.emit({ success: true, message: message });
            this.activeModal.close();
        } else {
            this.Response.emit({ success: true, message: message });
            this.activeModal.close();
        }
    }

    public symptomCategoryModalResponse(event) {
        if (event.success) {
            this.symptomData = {
                action: event.action,
                symptoms: event.symptoms,
                currentIndex: 0,
                userAttributes: this.SymptomsData.userAttributes,
                careteamId: this.SymptomsData.careteamId,
                patientId: this.SymptomsData.patientId,
                wruid: event.wruid,
            };
            if (event.gotocomponent === "SymptomGeneral") {
                const modalRef = this.modalService.open(SymptomGeneralModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomEnt") {
                const modalRef = this.modalService.open(SymptomENTModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomGas") {
                const modalRef = this.modalService.open(SymptomGasModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomHeartLung") {
                const modalRef = this.modalService.open(SymptomHeartLungModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomNeuroPsycho") {
                const modalRef = this.modalService.open(SymptomNeuroModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomObGyn") {
                const modalRef = this.modalService.open(SymptomObGynModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomMusculo") {
                const modalRef = this.modalService.open(SymptomMusculoModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomSkin") {
                const modalRef = this.modalService.open(SymptomSkinModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomUro") {
                const modalRef = this.modalService.open(SymptomUroModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            } else if (event.gotocomponent === "SymptomOther") {
                const modalRef = this.modalService.open(SymptomOtherModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.symptomCategoryModalResponse(receivedEntry);
                });
            } else if (event.gotocomponent === "SymptomConfirm") {
                const modalRef = this.modalService.open(SymptomConfirmModalComponent, { size: "lg", backdrop: "static" });
                modalRef.componentInstance.SymptomsData = this.symptomData;
                modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                    this.openCategoryModal(receivedEntry.success, receivedEntry.message, receivedEntry.isSaved);
                });
            }
        }
    }

    public onCancel() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.activeModal.close();
            }
        });
    }
}
