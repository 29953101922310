import { Component, Output, EventEmitter, Input } from "@angular/core";
import { OnboardingService } from "../../services/onboarding.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "onboard-modal",
    templateUrl: "./onboard-modal.component.html",
    styleUrls: ["./onboard-modal.component.scss"],
})
export class OnboardModalComponent {
    public user: any = {
        email: "",
        firstname: "",
        lastname: "",
        gender: "",
        postalcode: "",
        dob: "",
        careteamid: "",
    };

    public errmsg = "";

    constructor(private onboardingService: OnboardingService, public activeModal: NgbActiveModal) {}

    @Input() careTeams: any = [];
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    public onAddUser() {
        this.errmsg = "";
        if (this.user.email !== "") {
            this.onboardingService.createUserOnboarding(this.user).subscribe(
                (data) => {
                    this.Response.emit({ success: true, message: "New member added to onboarding list" });
                    this.activeModal.close();
                },
                (err) => {
                    this.errmsg = err;
                }
            );
        } else {
            this.errmsg = "Email is required";
        }
    }

    public onCancel() {
        this.activeModal.close();
    }
}
