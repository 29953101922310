import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { ChatImageModalComponent } from "../chat-image-modal/chat-image-modal.component";

@Component({
    selector: "prescription-view-modal",
    templateUrl: "./prescription-view-modal.component.html",
    styleUrls: ["./prescription-view-modal.component.scss"],
})
export class PrescriptionViewModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private patientService: PatientService, private modalService: NgbModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    imageURL: string = "../../../assets/images/bottle.png";
    ngOnInit() {
        this.getImageURL();
    }
    public getImageURL() {
        return this.patientService.getImage(this.SummaryData.medication.image).subscribe(
            (data) => {
                if (data[0].attributes.presignedUrl !== undefined) {
                    this.imageURL = data[0].attributes.presignedUrl;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
    public onCancel() {
        this.activeModal.close();
    }
    public onError() {
        return "../../../assets/images/bottle.png";
    }
    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }
}
