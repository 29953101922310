import { Directive, ElementRef, Input, HostListener, AfterViewInit, AfterViewChecked, OnInit } from "@angular/core";

@Directive({
    selector: "[smartHeight]",
})
export class SmartHeightDirective implements OnInit, AfterViewInit, AfterViewChecked {
    // class name to match height
    @Input() smartHeight: any;
    @Input() watch: string;

    ids: string[] = [];
    elements: any = {};

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.ids = this.smartHeight.match(/#(\w+)\b/gi);
    }

    ngAfterViewInit() {
        if (this.ids) {
            this.elements = this.ids.reduce((elements, id) => {
                elements[id] = document.getElementById(id.slice(1));
                return elements;
            }, {});
        }
    }

    ngAfterViewChecked() {
        this.render();
    }

    @HostListener("window:resize") onResize() {
        this.render();
    }

    render() {
        this.setHeight(this.el.nativeElement, this.smartHeight);
    }

    private setHeight(parent: HTMLElement, smartHeight: string) {
        if (!parent) return;

        let heightCalc = smartHeight.toString();
        for (let id in this.elements) {
            if (this.elements[id]) {
                heightCalc = heightCalc.replace(id, `${this.elements[id].offsetHeight}px`);
            } else {
                heightCalc = heightCalc.replace(id, "0px");
            }
        }

        // Update the parent height
        parent.style.height = heightCalc;
    }
}
