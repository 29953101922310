<div class="modal-body">
    <div class="category-header">
        <label>Women's Health History</label>
    </div>
    <form class="modal-body-form" [formGroup]="womenhealthForm">
        <div class="main-row">
            <div class="main-full">
                <div class="form-group">
                    <label>Age at First Period</label>
                    <input (blur)="Validate('firstperiod')" [ngClass]="{ error: isFieldValid('firstperiod') }" formControlName="firstperiod" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['firstperiod'].errors && womenhealthForm.controls['firstperiod'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Days Between Periods</label>
                    <input (blur)="Validate('periodgap')" [ngClass]="{ error: isFieldValid('periodgap') }" formControlName="periodgap" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['periodgap'].errors && womenhealthForm.controls['periodgap'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Period Duration in Days</label>
                    <input (blur)="Validate('periodduration')" [ngClass]="{ error: isFieldValid('periodduration') }" formControlName="periodduration" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['periodduration'].errors && womenhealthForm.controls['periodduration'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Flow</label>
                    <select formControlName="periodflow" [ngClass]="{ error: isFieldValid('periodflow') }" class="custom-select form-control">
                        <option value="">Please Select</option>
                        <option value="Light">Light</option>
                        <option value="Moderate">Moderate</option>
                        <option value="Heavy">Heavy</option>
                    </select>               
                    <div class="inp-err" *ngIf="womenhealthForm.controls['periodflow'].errors && womenhealthForm.controls['periodflow'].touched">
                        <span class="error-msg"> Please select a value. </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Do you use birth control?</label>
                    <div class="woman-content-row">
                        <div class="woman-radio">
                            <input id="birthControl_Yes" type="radio" class="radio-buttons" name="birthControl" value="true" (change)="toggleBirthControl(true)" [checked]="birthControl === true" />
                            <label for="birthControl_Yes" class="radio-name">Yes</label>
                            <input id="birthControl_No" type="radio" class="radio-buttons" name="birthControl" value="false" (change)="toggleBirthControl(false)" [checked]="birthControl === false" />
                            <label for="birthControl_No" class="radio-name">No</label>                       
                        </div>
                    </div>
                </div>
                <div class="form-group woman-content-row" [hidden]="!birthControl">
                    <div class="check-item" *ngFor="let item of checklist; let i = index">
                        <div class="check-item-main">
                            <input class="radio-checklist" id="checkbox_birthcontrol{{ i }}" type="checkbox" name="checklist" [(ngModel)]="checklist[i].value" [ngModelOptions]="{ standalone: true }" />
                            <label for="checkbox_birthcontrol{{ i }}" class="radio-checkbox-label"> {{ item.name }} </label>
                            
                        </div>
                        <div class="others" *ngIf="item.name === 'Other'" [hidden]="!item.value">
                            <div class="others-text">
                                <textarea [(ngModel)]="others" type="text" class="others-textarea form-control" placeholder="Please describe" [ngModelOptions]="{ standalone: true }"> </textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Have you ever been pregnant?</label>
                    <div class="woman-content-row">
                        <div class="woman-radio">
                            <input id="pregnancy_Yes" type="radio" class="radio-buttons" name="preg=nancy" value="true" (change)="togglePregnancy(true)" [checked]="pregnancy === true" />                    
                            <label for="pregnancy_Yes" class="radio-name">Yes</label>
                            <input id="pregnancy_No" type="radio" class="radio-buttons" name="pregnancy" value="false" (change)="togglePregnancy(false)" [checked]="pregnancy === false" />
                            <label for="pregnancy_No" class="radio-name">No</label>                       
                        </div>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Total Pregnancies</label>                
                    <input (blur)="Validate('totalpregnancies')" [ngClass]="{ error: isFieldValid('totalpregnancies') }" formControlName="totalpregnancies" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['totalpregnancies'].errors && womenhealthForm.controls['totalpregnancies'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Full-term births</label>                
                    <input (blur)="Validate('fulltermbirths')" [ngClass]="{ error: isFieldValid('fulltermbirths') }" formControlName="fulltermbirths" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['fulltermbirths'].errors && womenhealthForm.controls['fulltermbirths'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Premature births</label>                
                    <input (blur)="Validate('premature')" [ngClass]="{ error: isFieldValid('premature') }" formControlName="premature" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['premature'].errors && womenhealthForm.controls['premature'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Miscarriages</label>                
                    <input (blur)="Validate('miscarriages')" [ngClass]="{ error: isFieldValid('miscarriages') }" formControlName="miscarriages" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['miscarriages'].errors && womenhealthForm.controls['miscarriages'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Abortions</label>                
                    <input (blur)="Validate('abortions')" [ngClass]="{ error: isFieldValid('abortions') }" formControlName="abortions" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['abortions'].errors && womenhealthForm.controls['abortions'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Vaginal Births</label>                
                    <input (blur)="Validate('vaginalbirths')" [ngClass]="{ error: isFieldValid('vaginalbirths') }" formControlName="vaginalbirths" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['vaginalbirths'].errors && womenhealthForm.controls['vaginalbirths'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>
                <div class="form-group" [hidden]="!pregnancy">
                    <label>C-Section births</label>                
                    <input (blur)="Validate('csections')" [ngClass]="{ error: isFieldValid('csections') }" formControlName="csections" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['csections'].errors && womenhealthForm.controls['csections'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>           
                <div class="form-group" [hidden]="!pregnancy">
                    <label>Pregnancy Complications?</label>
                    <div class="woman-content-row">
                        
                    </div>
                </div>
                <div class="form-group woman-content-row" [hidden]="!pregnancy">
                    <div class="check-item" *ngFor="let item of checklist2; let i = index">
                        <div class="check-item-main">
                            <input class="radio-checklist" id="checkbox_pregnancy{{ i }}" type="checkbox" name="checklist2" [(ngModel)]="checklist2[i].value" [ngModelOptions]="{ standalone: true }" />
                            <label for="checkbox_pregnancy{{ i }}" class="radio-checkbox-label"> {{ item.name }} </label>
                            
                        </div>
                        <div class="others" *ngIf="item.name === 'Other'" [hidden]="!item.value">
                            <div class="others-text">
                                <textarea [(ngModel)]="others2" type="text" class="others-textarea form-control" placeholder="Please describe" [ngModelOptions]="{ standalone: true }"> </textarea>                            
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Have you reached menopause?</label>
                    <div class="woman-content-row">
                        <div class="woman-radio">
                            <input type="radio" id="menopausal_Yes" class="radio-buttons" name="menopausal" value="true" (change)="toggleMenopausal(true)" [checked]="menopausal === true" />
                            <label for="menopausal_Yes" class="radio-name">Yes</label>
                            <input type="radio" id="menopausal_No" class="radio-buttons" name="menopausal" value="false" (change)="toggleMenopausal(false)" [checked]="menopausal === false" />
                            <label for="menopausal_No" class="radio-name">No</label>                                    
                        </div>
                    </div>
                </div>
                <div class="form-group" [hidden]="!menopausal">
                    <label>Age at Onset of Menopause</label>                
                    <input (blur)="Validate('menopauseage')" [ngClass]="{ error: isFieldValid('menopauseage') }" formControlName="menopauseage" type="number" class="form-control" />
                    <div class="inp-err" *ngIf="womenhealthForm.controls['menopauseage'].errors && womenhealthForm.controls['menopauseage'].touched">
                        <span class="error-msg"> Field is required with a valid numeric value. </span>
                    </div>
                </div>   
            </div>     
        </div>
    </form>
    <div class="main-row">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>    
    </div>
</div>
