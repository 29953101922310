<div class="main-body">
    <div class="main-header">
        <div class="header-left">
            <span>PATIENT ESCALATIONS</span>
        </div>
    </div>
    <div class="main-content">
        <div *ngIf="waitingRooms.length" class="container-fluid">
            <table class="table table-striped datatable">
                <thead>
                    <tr>
                        <!-- <th [ngClass]="c === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                            sortable="{{ (c === 'actions') ? '' : c }}" (sort)="onSort($event)">{{
                            displayName(c) }}</th> -->
                        <th *ngFor="let c of displayedColumns">{{
                            displayName(c) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let room of waitingRooms">
                        <td style="text-transform: uppercase;">
                            {{ room.name }}
                        </td>
                        <td>
                            {{ room.counts.new }}
                        </td>
                        <!-- <td>
                            {{ room.counts.open }}
                        </td> -->
                        <td>
                            {{ room.counts.inProgress }}
                        </td>
                        <!-- <td>
                            {{ room.counts.onhold }}
                        </td> -->
                        <td>
                            {{ room.counts.closed }}
                        </td>
                    </tr>


                </tbody>
            </table>
        </div>
    </div>
    <app-loader class="loader" *ngIf="showLoader"></app-loader>
</div>