<div class="modal-header bg-primary text-white">
    <h5 class="modal-title">GOALS</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="flex" *ngFor="let g of goals; let i = index">
        <div class="row col-12 m-0 p-0" *ngFor="let c of g.chart">
            <div class="col-12">
                <div class="col-12 row">
                    <div class="col-9">
                        <b class="goalname">{{ g.goalname }}</b>
                    </div>
                    <div *ngIf="i === 0" class="col-3">
                        <select [(ngModel)]="datapointtype" class="form-control" (change)="getGraph($event)">
                            <option value="daily">Daily</option>
                            <option value="monthly">Monthly</option>
                            <option value="3 month">3 Months</option>
                            <option value="6 month">6 Months</option>
                            <option value="yearly">Yearly</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <highcharts-chart [Highcharts]="Highcharts" style="width: 100%; display: block; overflow: hidden"
                        [options]="c.chartObject"></highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>