import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SpecialtyService } from "../../services/specialty.service";
import { CommunicationService } from "../../services/communications.service";
import { SessionService } from "../../services/session.service";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { PatientService } from "../../services/patients.service";

export interface Procedure {
    code: string;
    details: string;
    name: string;
    stcid: string;
}

@Component({
    selector: "waiting-room-refer-modal",
    templateUrl: "./waiting-room-refer-modal.component.html",
    styleUrls: ["./waiting-room-refer-modal.component.scss"],
})
export class WaitingRoomReferModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private snackbar: SnackbarService,
        private waitingRoomService: WaitingRoomService,
        private specialtyService: SpecialtyService,
        private communication: CommunicationService,
        private sessionService: SessionService,
        private route: ActivatedRoute,
        private patientService: PatientService
    ) {}

    @Input() PatientReferData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    noTeleRXMessage = "";
    error = "";
    showLoading = false;
    teleremedies: any = [];
    teleRxBySpeciality: any = [];
    referForm: UntypedFormGroup;
    specialties: any = [];
    membershipId = "";
    search = "";
    searchTeleRx = "";
    showSpecialtiesList = true;
    showPhysicianList = false;
    showTeleRxList = false;
    notes = "";
    selectedTeleRxList = [];
    selectedProviderList = [];
    selectedSpeciality: any = null;
    selectedTeleRX: any = null;
    selectedPhysician: any = null;
    sendToChat = false;
    recentReferral = [];
    showReferral = false;
    providers = [];
    public enableSpeciality = false;
    public eligible = false;
    public procedure = "Telemedicine";
    public elQuerySuccess = false;
    public elQueryError = false;
    public elQueryLoading = false;
    public elQueryErrorMessage = "";
    public patientInfo: any = {};
    public errordetail: string = "";

    control = new UntypedFormControl({ name: "Telemedicine", code: "E37" });
    procedures: Procedure[];
    filteredProcedures: Observable<Procedure[]>;
    selectedProcedure = "";

    ngOnInit() {
        this.referForm = this.fb.group(
            {
                notes: [""],
            },
            { updateOn: "blur" }
        );
        this.getSpecialties();

        this.filteredProcedures = this.control.valueChanges.pipe(
            startWith(""),
            map((value) => (typeof value === "string" ? value : value.name)),
            map((name) => (name ? this._filter(name) : this.procedures.slice()))
        );

        this.getPatientName();

        this.getServiceTypeCodes();

        // this.membershipId = this.PatientReferData.membershipid;
    }

    getPatientName() {
        return this.patientService.getClinicalSummary(this.PatientReferData.patientId).subscribe((data) => {
            let profileSettings = data[0].attributes.profileSettings;
            let personalinformationData = profileSettings ? profileSettings.personalinformation : null;
            this.patientInfo.firstname = personalinformationData?.firstname;
            this.patientInfo.middlename = personalinformationData?.middlename;
            this.patientInfo.lastname = personalinformationData?.lastname;

            // if not in summary, get it from settings
            if (!this.patientInfo.firstname) {
                return this.patientService.getUserSetting(this.PatientReferData.patientId).subscribe(
                    (data) => {
                        this.patientInfo.firstname = data[0].attributes?.firstname;
                        this.patientInfo.middlename = data[0].attributes?.middleName;
                        this.patientInfo.lastname = data[0].attributes?.lastname;
                    },
                    (err) => {}
                );
            }
        });
    }

    isFieldValid(key) {
        return this.referForm.get(key).invalid && this.referForm.get(key).touched;
    }
    public onResolve() {}
    public onCancel() {
        this.activeModal.close();
    }
    public resetSpecialtiesList() {
        this.search = "";
        this.searchTeleRx = "";
        this.showSpecialtiesList = true;
        this.showTeleRxList = false;
    }
    public resetTeleRxList() {
        this.searchTeleRx = "";
        this.showTeleRxList = true;
    }
    public onBack() {
        this.search = "";
        this.searchTeleRx = "";
        this.noTeleRXMessage = "";
        this.selectedTeleRxList = [];
        this.showSpecialtiesList = true;
        this.showTeleRxList = false;
        this.showPhysicianList = false;
        this.sendToChat = false;
        this.selectedPhysician = null;
        this.selectedTeleRX = null;
        this.selectedSpeciality = null;
    }
    public onBackRecent() {
        this.noTeleRXMessage = "";
        this.search = "";
        this.searchTeleRx = "";
        this.selectedTeleRxList = [];
        this.showSpecialtiesList = true;
        this.sendToChat = false;
        this.showReferral = false;
        this.selectedPhysician = null;
        this.selectedTeleRX = null;
        this.selectedSpeciality = null;
    }
    public reset() {
        this.search = "";
        this.searchTeleRx = "";
        this.noTeleRXMessage = "";
        this.showSpecialtiesList = true;
        this.sendToChat = false;
        this.showPhysicianList = false;
        this.showReferral = false;
        this.selectedPhysician = null;
        this.selectedTeleRX = null;
        this.selectedSpeciality = null;
    }
    public onContinue() {
        this.sendToChat = true;
    }
    public onKeydown(event) {
        this.selectedSpeciality = null;
    }
    public selectSpeciality(s) {
        this.selectedSpeciality = s;
    }
    public selectTeleRX(t) {
        this.selectedTeleRX = t;
    }
    public selectTeleRXInPhysician(t) {
        this.selectedTeleRX = t;
        this.checkEligibility();
    }
    public selectPhysician(p) {
        this.selectedPhysician = p;
        this.checkEligibility();
    }
    public openNotesScreenPhysician() {
        this.showSpecialtiesList = false;
        this.showPhysicianList = false;
        this.sendToChat = true;
    }
    public openNotesScreen() {
        this.showReferral = false;
        this.sendToChat = true;
    }
    public getSpecialties() {
        return this.specialtyService.getAllSpecialties().subscribe(
            (data) => {
                this.specialties = data;
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public getTeleRxBySpeciality(isPhysician) {
        return this.specialtyService.getTeleRxBySpeciality(this.selectedSpeciality.spid).subscribe(
            (data) => {
                this.noTeleRXMessage = "";
                this.search = this.selectedSpeciality.name;
                this.teleRxBySpeciality = data;
                this.showSpecialtiesList = false;
                this.showTeleRxList = true;
                this.showReferral = true;
                if (data.length === 0) {
                    this.noTeleRXMessage = "There are no " + this.selectedSpeciality.name + " specialties currently in the Clio Healthcare Network";
                }
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public selectedTeleRx(t) {
        let index = this.selectedTeleRxList.findIndex((x) => x.name === t.name);
        if (index === -1) {
            this.selectedTeleRxList.push(t);
        } else {
            this.selectedTeleRxList.splice(index, 1);
        }
    }
    public getRecentReferral() {
        return this.specialtyService.getRecentReferral(this.selectedSpeciality.spid, this.PatientReferData.patientId).subscribe(
            (data) => {
                this.selectedTeleRxList = [];
                this.search = this.selectedSpeciality.name;
                this.recentReferral = data;
                this.teleRxBySpeciality = data;
                this.showReferral = true;
                this.showSpecialtiesList = false;
                this.sendToChat = false;
                if (data.length === 0) {
                    this.noTeleRXMessage = "There are no " + this.selectedSpeciality.name + " specialties currently in the Clio Healthcare Network";
                }
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public getProviderBySpeciality() {
        return this.specialtyService.getProviderBySpeciality(this.selectedSpeciality.spid).subscribe(
            async (data) => {
                this.selectedTeleRxList = [];
                this.search = this.selectedSpeciality.name;
                this.showSpecialtiesList = false;
                this.showPhysicianList = true;
                this.providers = [];
                this.providers = data;
                if (this.providers.length === 0) {
                    this.noTeleRXMessage = "No providers found.";
                }
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public getServiceTypeCodes() {
        return this.specialtyService.getServiceTypeCodes().subscribe(
            async (data) => {
                this.procedures = data.attributes.list;
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public onSendToChat() {
        let teleRxName = "";
        this.selectedTeleRxList.forEach((element, index) => {
            teleRxName = teleRxName + (index === 0 ? "" : ", ") + element.name;
        });
        let message = "Your Care Navigator has chosen the following providers for your follow up care: " + teleRxName;

        this.communication
            .post(this.sessionService.activeCareTeam.careteamid, this.PatientReferData.patientId, message, "", [], false, this.selectedTeleRxList, this.PatientReferData.wruid)

            .subscribe(
                (data) => {
                    let referdata = {
                        cwruid: this.PatientReferData.wruid,
                        suggestedtelerx: this.selectedTeleRxList,
                    };
                    return this.waitingRoomService.referPatientToTeleRx(referdata).subscribe(
                        (response) => {
                            this.Response.emit({ success: true, message: "Member referred successfully", action: "refer" });
                            this.activeModal.close();
                        },
                        (err) => {
                            this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                        }
                    );
                },
                (error) => {
                    this.snackbar.show(error[0].detail, "danger");
                }
            );
    }
    public onRefer(userid, membershipid) {
        let data = {
            cwruid: this.PatientReferData.wruid,
            tomembershipid: membershipid,
            toproviderid: userid,
            requestdata: {
                notes: this.notes,
            },
        };
        return this.waitingRoomService.referPatient(data).subscribe(
            (data) => {
                this.Response.emit({ success: true, message: "Member referred successfully", action: "refer" });
                this.activeModal.close();
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    getDateInYYYYMMDD(dt) {
        // year
        let yyyy = "" + dt.getFullYear();

        // month
        let mm = "0" + (dt.getMonth() + 1); // prepend 0 // +1 is because Jan is 0
        mm = mm.substr(mm.length - 2); // take last 2 chars

        // day
        let dd = "0" + dt.getDate(); // prepend 0
        dd = dd.substr(dd.length - 2); // take last 2 chars

        return yyyy + "" + mm + "" + dd;
    }
    public setProcedure(p) {
        this.selectedProcedure = p.name;
        this.control.value.code = p.code;
        this.control.value.name = p.name;
    }
    public checkEligibility() {
        this.elQueryLoading = true;
        this.elQuerySuccess = false;
        this.elQueryError = false;

        let serviceCode = this.control.value.code;

        if (!serviceCode) {
            this.elQueryLoading = false;
            this.elQuerySuccess = false;
            this.elQueryError = true;
            this.elQueryErrorMessage = "Please select a service.";
            return;
        }

        let dos = this.getDateInYYYYMMDD(new Date());
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        let endDos = this.getDateInYYYYMMDD(endDate);
        this.membershipId = this.selectedTeleRX.membershipid;
        let data = {
            userid: this.PatientReferData.patientId,
            npimethod: "telerx",
            membershipid: this.membershipId,
            encounter: {
                beginningdateofservice: dos,
                enddateofservice: endDos,
                servicetypecodes: [serviceCode],
            },
        };
        return this.specialtyService.checkEligibility(data).subscribe(
            (response) => {
                this.procedure = this.control.value?.name;
                this.eligible = true;
                this.elQueryLoading = false;
                this.elQuerySuccess = true;
                this.elQueryError = false;
            },
            (err) => {
                this.elQueryLoading = false;
                this.elQuerySuccess = false;
                this.elQueryError = true;
                this.elQueryErrorMessage = err[0].title;
                // todo: remove
                //this.elQueryErrorMessage += ". " + err[0].detail?.map((e) => e.description).join(" ");

                // todo: remove
                //console.error(err[0]);
            }
        );
    }

    displayFn(p: Procedure): string {
        return p && p.name ? p.name : "";
    }

    private _filter(name: string): Procedure[] {
        const filterValue = name.toLowerCase();
        return this.procedures.filter((option) => option.name.toLowerCase().includes(filterValue));
    }
}
