<div class="container-fluid">
    <div class="tab">
        <a class="tablinks active" [routerLink]="['/patient-experience']">
            <img src="/assets/msx/favorites-white.svg"> Favorites
        </a>
        <a class="tablinks" [routerLink]="['/survey-data']">
            <img src="/assets/msx/surveydata.png"> Survey Data
        </a>
        <a class="tablinks" [routerLink]="['/msx/reports']">
            <img src="/assets/msx/reports.svg"> Reports
        </a>
        <!-- <a class="tablinks" [routerLink]="['/reports']">
            <img src="/assets/msx/reports.svg"> Reports
        </a> -->
        <!-- <a class="tablinks">
            <img src="/assets/msx/admin.svg"> Administration
        </a> -->
    </div>

    <div class="filter-bar">
        <div class="container">
            <div class="report-filters">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Surveys</label>
                            <select (change)="getSurvey()" class="form-control" id="survey"
                                [(ngModel)]="selectedSurvey">
                                <option *ngFor="let m of surveys?.metricGroups| orderBy: 'surveyname'" [ngValue]="m.surveyid
                                ">{{ m.surveyname }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Metrics</label>
                            <select [(ngModel)]="graphId" (change)="selectedGraph()" class="form-control" id="survey">
                                <option value="">Please select a metric</option>
                                <option *ngFor="let m of surveys?.metrics | orderBy: 'metricname'" [ngValue]="m.metricid
                                        ">{{ m.metricname }}</option>
                            </select>


                            <!-- <ng-select (change)="selectedGraph()" [(ngModel)]="graphId" [items]="metricsGroup"
                                bindLabel="metricname" bindValue="metricid" groupBy="metricgroupname"
                                [searchable]="false">
                                <ng-template ng-optgroup-tmp let-item="item">
                                    {{item.metricgroupname || 'Unnamed group'}}
                                </ng-template>
                                <ng-template ng-optgroup-tmp let-item="item">
                                    {{item.country || 'Unnamed group'}}
                                </ng-template>
                            </ng-select> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4">
        <div class="row">
            <div *ngFor="let g of graphs" class="col-lg-6">
                <div class="card mb-2">
                    <div class="card-body">
                        <app-metric-graph [metric]="g"></app-metric-graph>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p *ngIf="graphs.length===0" class="helptext">Please select from the above list to populate graph.</p>
</div>