import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Helper } from "../../providers/helper";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-onboarding-login",
    templateUrl: "./onboarding-login.component.html",
    styleUrls: ["./onboarding-login.component.scss"],
})
export class OnboardingLoginComponent implements OnInit {
    constructor(private auth: AuthService, private helper: Helper, private sessionService: SessionService) {}

    public loading = true;
    ngOnInit() {
        try {
            this.sessionService.clearLocalStorageAndSession();
            this.sessionService.setCurrentUserRole(["provider"]);
            const Authorization = this.helper.getCookie("Authorization");
            this.auth.onboarding(Authorization).subscribe(
                (data) => {
                    localStorage.setItem("token", data[0].attributes.token);
                    this.helper.deleteCookie("Authorization");
                    window.location.href = "home";
                },
                (error) => {
                    this.loading = false;
                    window.location.href = "login";
                }
            );
        } catch (e) {
            window.location.href = "login";
        }
    }
}
