import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "vaccines-modal",
    templateUrl: "./vaccines-modal.component.html",
    styleUrls: ["./vaccines-modal.component.scss"],
})
export class VaccinesModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    public checklist: Array<any> = [];
    public others: string;
    error = "";
    ngOnInit() {
        this.checklist = [
            {
                name: "Flu (In the Last Year)",
                value: "",
            },
            {
                name: "Hepatitis B",
                value: "",
            },
            {
                name: "Herpes",
                value: "",
            },
            {
                name: "Meningitis",
                value: "",
            },
            {
                name: "MMR",
                value: "",
            },
            {
                name: "Pneumonia",
                value: "",
            },
            {
                name: "Shingles",
                value: "",
            },
            {
                name: "Tetanus (In the Last 10 Years)",
                value: "",
            },
        ];
        this.setupForm();
    }
    setupForm() {
        let vaccines = this.SummaryData.summary.vaccines.vaccines;

        if (vaccines && vaccines.length > 0) {
            vaccines.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = item.value;
                    }
                });
            });
        }
    }

    getSelectedData(): Array<any> {
        let selectedData = [];

        this.checklist.forEach((item) => {
            if (item.value.length > 0) {
                let i = { name: item.name, value: item.value };
                selectedData.push(i);
            }
        });
        return selectedData;
    }
    onSave() {
        let data = this.getSelectedData();
        this.SummaryData.summary.vaccines = { vaccines: data };

        if (this.others) {
            this.SummaryData.summary.vaccines["other"] = this.others;
        }
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }

    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
