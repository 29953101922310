import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from 'underscore';
import * as Highcharts from "highcharts";
import { MSXReportService } from "../../../services/msx_report.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../../modals/warning-modal/warning-modal.component";
import { Router } from "@angular/router";

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {
  colorArray = ['#08c86c', '#ff930a', '#232bc8', '#ffbf0a', '#ff480a'];
  @Input() graphData: any;
  @Input() settings: any;


  @Output("refreshMetricsList") refreshMetricsList: EventEmitter<any> = new EventEmitter();
  public chartOptions: any;
  Highcharts: typeof Highcharts = Highcharts;
  page = 0;
  pageCount = 0;
  chart;
  showLoader = false;
  lowSample = 5
  reportData: any;
  constructor(
    private http: HttpClient,
    private msxReportService: MSXReportService,
    private snackbar: SnackbarService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,

  ) { }
  _data = [];
  _categories = [];
  perPage = 5;
  benchmarkName = "National Average";
  benchmark = [];
  ngOnInit(): void {
    this.loadReport();
  }


  loadReport() {
    this.showLoader = false;
    let _data = [];
    let practices = [];
    this.chartSettings();
    this.chartOptions.series = [];


    let allPractice = _.first(_.rest(this.graphData.chartSeries[1].data, (this.page || 0) * this.perPage), this.perPage)
    let selectedPractices = _.first(_.rest(this.graphData.chartSeries[0].data, (this.page || 0) * this.perPage), this.perPage)



    _data = [];
    //   _data.push(this.graphData.chartSeries[0])
    _data.push({
      data: selectedPractices,
      type: this.graphData.chartSeries[0].type,
      name: this.graphData.chartSeries[0].name
    })

    _data.push({
      data: allPractice,
      type: this.graphData.chartSeries[1].type,
      name: this.graphData.chartSeries[1].name
    })
    this.chartOptions.series = _data;

    // this.chartOptions.series.push(
    //   {
    //     data: _.first(_.rest(this.graphData.chartSeries[1].data, (this.page || 0) * this.perPage), this.perPage),
    //     showInLegend: false

    //   });

    if (this.graphData.name === "specialty") {
      this.chartOptions.xAxis['categories'] = _.first(_.rest(this.graphData.categories, (this.page || 0) * this.perPage), this.perPage);
    }

    //this.chartOptions.xAxis['categories'] = this.graphData.categories;
    //this.graphData.categories;
    this.pageCount = Math.ceil(this.graphData.chartSeries[1].data.length / this.perPage);
  }

  chartSettings() {
    let _xAxis: any;
    if (this.graphData.name === "specialty") {
      _xAxis = {
        type: 'datetime',
        labels: {
          format: '{value:%b %e}',
          // rotation: -30,
          align: 'right'
        }
      }
    }
    else {
      _xAxis = {
        type: 'datetime',
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          format: "{value:%b  '%y}",
          //rotation: -30,
          align: 'right'
        }
      }
    }



    this.chartOptions = {
      chart: {
        type: this.settings.graphType,
        marginTop: 20,
        spacingBottom: 30,
        zoomType: 'x',
        // height: 350, // 220 , 450 for 1+
        marginRight: 180,
        // width: 400
      },
      colors: this.colorArray,
      credits: {
        enabled: false
      },
      title: {
        align: 'left',
        useHTML: true,
        text: null,
        // text: '<div class="chartPrintTitle"> ' + this.reportData.seriesName + '</div>',
        y: 1,
        style: {
          fontSize: '10px',
        }
      },
      subtitle: {
        align: 'right',
        useHTML: true,
        //text: '<div class="chartRedDotMessage" style="font-weight:bold;font-size:smaller;">Red ' + ((multiMetric || columnChart) ? 'outline' : 'dot') + '(s) have collected very few responses and may not be accurate.</div>',
        verticalAlign: 'top',
        x: -3,
        y: 7
      },
      xAxis: _xAxis,
      yAxis: {
        labels: {
          format: '{value}'
        },
        title: {
          text: 'Visit Count',
          style: {
            fontWeight: '600px',
            fontSize: '16px',
            lineHeight: '18px',
            fontFamily: 'Poppins, "Helvetica Neue", sans-serif',
            color: '#2D2D2D'
          }
        },
        tickInterval: 5
      },
      lang: {
        noData: "No data available for the current selection."
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#303030'
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: -50,
        y: 120,
        symbolPadding: 0,
        symbolWidth: 0.1,
        symbolHeight: 0.1,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function () {
          return '<div style="margin:8px"><span style="float:left; margin-left:10px"></span><span style="float:right;padding:2px;color:white;background-color:' + this.color + '">' + this.name + '</span></div>';
        },
        itemStyle: {
          color: '#ffffff',
          fontSize: '10px',

        }
      },
      tooltip: {
        valueSuffix: '%',
        useHTML: true,
        shared: true,
        formatter: function () {
          var title = Highcharts.dateFormat('%b %e %Y', this.points[0].key);
          var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
          this.points.forEach(element => {
            if (typeof element.key === 'string') {
              tooltip += '<tr><td colspan="2">' + element.key + '</td></tr>' +
                '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + element.y + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';
            }
            else {
              tooltip += '<tr><td' + ((element.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + element.y + ((element.n < this.lowSample && element.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>' + ((element.n > 0) ? '<td align="right"><span style="font-size:8px;">' + this.roundN(element.point.n, element.series.name) + '</span></td>' : '') + '</tr>';

            }

          });
          return tooltip + '</table>';
        }
      }
    }
  }

  previous() {
    if (this.page > 0) {
      this.page--;
      this.loadReport();
    }
  }

  next() {
    if (this.page < this.pageCount - 1) {
      this.page++;
      this.loadReport();
    }
  }
}
