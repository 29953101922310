import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
import { Router } from "@angular/router";
@Component({
    selector: "visits-filters-modal",
    templateUrl: "./visits-filters-modal.component.html",
    styleUrls: ["./visits-filters-modal.component.scss"],
})
export class VisitsFiltersModalComponent implements OnInit {
    constructor(
        private activeModal: NgbActiveModal,
        private msxReportService: MSXReportService,
        private snackbar: SnackbarService,
        public router: Router
    ) { }
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    _careLength = "ALL";
    _reasonForVisit = "ALL";
    careLengthQuestion = {
        value: "ALL",
        displayText: "ALL",
        questionId: "ALL"
    };
    reasonForVisitQuestion = {
        value: "ALL",
        displayText: "ALL",
        questionId: "ALL"
    };
    cptCode = [];
    icd10Code = [];
    specialty = [];
    surveytypes = [];
    @Input() metricid = "";
    ageGroup = [
        {
            value: "ALL",
            displayText: "ALL",
            minAge: 0,
            maxAge: 100
        },
        {
            value: "18",
            displayText: "18-24",
            minAge: 18,
            maxAge: 24
        },
        {
            value: "45",
            displayText: "45-60",
            minAge: 45,
            maxAge: 60
        },
        {
            value: "-18",
            displayText: "Under 18",
            minAge: 0,
            maxAge: 18
        },
        {
            value: "25",
            displayText: "25-44",
            minAge: 25,
            maxAge: 44
        },
        {
            value: "60",
            displayText: "Over 60",
            minAge: 60,
            maxAge: 100
        }
    ]
    genders = [
        {
            value: "ALL",
            displayText: "ALL"
        },
        {
            value: "F",
            displayText: "Female"
        },
        {
            value: "M",
            displayText: "Male"
        },
        {
            value: "O",
            displayText: "Other"
        }
    ]
    careLength = [
        {
            value: "ALL",
            displayText: "ALL",
            questionId: "ALL"
        },
        {
            value: "05abbb50-26bf-4753-aa30-128007d780b3",
            displayText: "6 months to 1 year",
            questionId: "99de57c7-16bc-4dc4-857e-8411c0597404"
        },
        {
            value: "33b9f6d5-0cc3-42a1-8f20-dfba1a4645d6",
            displayText: "Over 5 years",
            questionId: "99de57c7-16bc-4dc4-857e-8411c0597404"
        },
        {
            value: "1e208fb0-3714-4587-ba82-de5f67a40798",
            displayText: "First Visit",
            questionId: "99de57c7-16bc-4dc4-857e-8411c0597404"
        },

        {
            value: "6ef8244f-cf77-459f-982a-db1f15e75d1a",
            displayText: "1-3 years",
            questionId: "99de57c7-16bc-4dc4-857e-8411c0597404"
        },
        {
            value: "e2ff1aa7-1a4f-46be-978c-979d423c2747",
            displayText: "Less than 6 months",
            questionId: "99de57c7-16bc-4dc4-857e-8411c0597404"
        },
        {
            value: "d7e94354-cf82-40d6-95f1-5f626be1fe2e",
            displayText: "3-5 years",
            questionId: "99de57c7-16bc-4dc4-857e-8411c0597404"
        }
    ]
    reasonForVisit = [
        {
            value: "ALL",
            displayText: "ALL",
            questionId: "ALL"
        },
        {
            value: "9fa19948-3818-4e18-9cc2-a59e742542e4",
            displayText: "Second opinion",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        },
        {
            value: "a7dc7777-3bf8-4cd1-b4aa-c99d261ea465",
            displayText: "Unscheduled/walk in",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        },
        {
            value: "88d84d6a-c15d-4850-b80d-94fef8421638",
            displayText: "Wellness/anual visit",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        },

        {
            value: "afad558f-49e0-4ef4-a470-9a39b88decd8",
            displayText: "Pre-surgery appt",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        },
        {
            value: "f44b2265-580f-43a9-a076-5708b8850c70",
            displayText: "Other",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        },
        {
            value: "6e349ae1-5222-418a-9bf3-c206c4db2d90",
            displayText: "New condition/issue",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        },
        {
            value: "74063255-c5b9-4f52-ad77-54e6cd72fc02",
            displayText: "Post discharge",
            questionId: "98a537b1-2dbf-40c6-919c-0c9758e40db2"
        }
    ]
    @Input() _filters = {
        age: this.ageGroup[0],
        gender: "ALL",
        cptCode: "ALL",
        icdCode: "ALL",
        specialty: "ALL",
        surveyType: "ALL",
        specialtyDisplayText: "",
        surveyTypeDisplayText: ""
    }
    filters: any;
    @Input() parent = "";
    showLoader = false;
    ngOnInit() {
        this.filters = Object.assign({}, this._filters);
        this.filters.age = _.filter(this.ageGroup, {
            value: this.filters.age.value
        })[0]
        if (this.parent === "survey") {
            this.getICDData();
        }
    }
    public onSubmit() {
        if (this.parent === "survey") {
            if (this.surveytypes !== undefined) {
                this.filters.surveyTypeDisplayText = _.filter(this.surveytypes, {
                    id: this.filters.surveyType
                })[0].b
            }
            if (this.specialty !== undefined) {
                this.filters.specialtyDisplayText = _.filter(this.specialty, {
                    id: this.filters.specialty
                })[0].b
            }
        }
        let parameters = {
            filters: this.filters,
        }
        this.Response.emit({
            parameters: parameters
        });
        this.activeModal.close();
        this.activeModal.close();
    }

    public onCancel() {
        this.activeModal.close();
    }
    public onReset() {
        this.filters = {
            age: this.ageGroup[0],
            gender: "ALL",
            cptCode: "ALL",
            icdCode: "ALL",
            specialty: "ALL",
            surveyType: "ALL",
            specialtyDisplayText: "",
            surveyTypeDisplayText: ""

        }
    }



    public getICDData() {
        this.showLoader = true;
        let metricid = "00000000-0000-0000-0000-000000000000";
        if (this.parent == "survry") {
            metricid = this.metricid
        }
        let _data = {
            id: "ALL",
            b: "ALL"
        }
        this.msxReportService.GetICDData(this.router.url, metricid).subscribe(
            (data) => {
                this.showLoader = false;
                let reportData = data[0].attributes.data.reportData;
                this.cptCode = reportData.cptCode;
                if (reportData.cptCode) {
                    this.cptCode.splice(0, 0, "ALL");
                }
                this.icd10Code = reportData.icd10Code;
                if (reportData.icd10Code) {
                    this.icd10Code.splice(0, 0, "ALL");
                }
                this.specialty = reportData.specialty;
                if (reportData.specialty) {
                    this.specialty.splice(0, 0, _data);
                }
                this.surveytypes = reportData.surveytypes;
                if (reportData.surveytypes) {
                    this.surveytypes.splice(0, 0, _data);
                }
            },
            (err) => {
                this.showLoader = false;
                this.snackbar.show("error occurred please try again later.", "danger");
            }
        );
    }
}
