import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TeleRXService } from "../../../services/teleRX.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { SpecialtyService } from "../../../services/specialty.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../../modals/warning-modal/warning-modal.component";
import { SubscribeTeleRXModalComponent } from "../../../modals/subscribe-telerx-modal/subscribe-telerx-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { RoleService } from "../../../services/role.service";
import { TableService } from "../../../services/table.service";
import { Observable } from "rxjs";
import { SortableHeaderDirective } from "../../../directives/sortable.directive";

@Component({
    selector: "app-telerx-detail",
    templateUrl: "./telerx-detail.component.html",
    styleUrls: ["./telerx-detail.component.css"],
})
export class TelerxDetailComponent implements OnInit {
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    constructor(
        private fb: UntypedFormBuilder,
        private snackbar: SnackbarService,
        private teleRxservice: TeleRXService,
        private specialtyService: SpecialtyService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private teleRXService: TeleRXService,
        private route: ActivatedRoute,
        private router: Router,
        private roleService: RoleService,
        public tableService: TableService
    ) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }
    public subscribeData: any = {};
    public telerxid = "";
    public membership: any;
    public teleRxName = "";
    public subscribedBy: any;
    public subscribedTo: any;
    public subscribedByAll: any;
    public subscribedToAll: any;
    public isImageEdit = false;
    public selectSpecialties = [];
    public specialties = [];
    public isValidImg = false;
    public imageFileToUpload: any;
    public btnImgDisabled = false;
    public displayedColumns = [];
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    public subscribedToColumns = [];
    public subscribedByColumns = [];
    public settingForm = this.fb.group({
        name: ["", Validators.required],
        description: ["", Validators.required],
        telerxtype: ["", Validators.required],
        providerdashboardurl: [""],
        callbackeventurl: [""],
        npi: ["", Validators.maxLength(10)],
        profilerequired: [false],
        enablerequest: [false],
        specialty: [""],
        adminemail: [""],
        onboardingcodeprefix: [""],
        searchenabled: [true],
    });
    members: any;
    public roles: any = [];
    roleFilter = "all";
    subscribedStatus = "";
    teleRxSearch = "";
    memberSearch = "";
    telerxData: any = [];
    teleRxType = "";
    page = 1;
    pageSize = 10;
    public pageIndex = 1;
    List = [];
    ngOnInit(): void {
        this.tableService.pageSize = 10;
        this.displayedColumns = [
            { displayname: "email", columnname: "email" },
            { displayname: "name", columnname: "fullname" },
            { displayname: "careteam name", columnname: "careteamname" },
            { displayname: "role", columnname: "rolename" },
            { displayname: "action", columnname: "" },
        ];
        this.subscribedToColumns = [
            { displayname: "name", columnname: "name" },
            { displayname: "description", columnname: "description" },
            { displayname: "subscribed status", columnname: "subscribed_to" },
            { displayname: "unsubscribe", columnname: "action" },
        ];
        this.subscribedByColumns = [
            { displayname: "name", columnname: "name" },
            { displayname: "description", columnname: "description" },
            { displayname: "subscribed status", columnname: "subscribed_to" },
            { displayname: "unsubscribe", columnname: "action" },
        ];

        this.telerxid = this.route.snapshot.paramMap.get("id");
        this.teleRXService.get(this.telerxid).subscribe(
            (data) => {
                this.membership = data[0].type.membership;
                let _name = this.membership["membershipname"].replace("TELERX-", "");
                this.settingForm.patchValue({
                    name: _name,
                    description: this.membership.description,
                    telerxtype: this.membership.telerxtype,
                    providerdashboardurl: this.membership.providerdashboardurl,
                    callbackeventurl: this.membership.callbackeventurl,
                    npi: this.membership.npi,
                    profilerequired: this.membership.profilerequired,
                    enablerequest: this.membership.enablerequest,
                    adminemail: this.membership.adminemail,
                    onboardingcodeprefix: this.membership.onboardingcodeprefix,
                    searchenabled: this.membership.searchenabled,
                });
                this.getTeleRxMembers();
                this.teleRemedies(this.telerxid);
                this.getSpecialties();
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );

        this.roleService.getRoles().subscribe(
            (data) => {
                this.roles = data;
            },
            (err) => console.log(err)
        );
        this.getTeleRX();
    }
    public teleRemedies(telerxid) {
        this.teleRXService
            .getSubscribedTeleRx(telerxid)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.teleRxType = data[0].type.details.telerxtype;
                    this.teleRxName = data[0].type.details.name;
                    this.subscribedByAll = data[0].type.details.subscribedTo.map((obj) => ({ ...obj, subscribed_ststus: "subscribed to" }));
                    this.subscribedToAll = data[0].type.details.subscribedFrom.map((obj) => ({ ...obj, subscribed_ststus: "subscribed by" }));
                    this.subscribedBy = this.subscribedByAll.filter((x) => x.status == true);
                    this.subscribedTo = this.subscribedToAll.filter((x) => x.status == true);
                },
                (err) => {
                    this.snackbar.show(err[0].detail !== undefined ? err[0].detail : err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
    }

    setDefaultPic() {
        this.membership.logourl = "assets/images/no-image.png";
    }
    public onEditImage() {
        this.isImageEdit = true;
    }

    public saveChanges() {
        if (this.settingForm.valid) {
            this.teleRxservice.update(this.settingForm.value, this.membership.telerxid).subscribe(
                (data) => {
                    if (this.settingForm.value.telerxtype === "service") {
                        this.specialtyService
                            .postSpecialties(
                                this.selectSpecialties.map((el) => el.spid),
                                this.membership.membershipid
                            )
                            .subscribe(
                                (data) => {
                                    this.snackbar.show("Updated Successfully", "success");
                                },
                                (err) => {
                                    this.snackbar.show(err[0].detail, "danger");
                                }
                            );
                    } else {
                        this.snackbar.show(data[0].type.message, "success");
                    }
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        } else {
            this.snackbar.show("Please provide mandatory fields.", "danger");
        }
    }
    ///TeleRx Members
    public getTeleRxMembers() {
        return this.teleRXService.getTeleRxMembers(this.membership.telerxid).subscribe(
            (data) => {
                this.members = data[0].attributes.list;
                this.tableService.DATA = data[0].attributes.list;
                this.sorting("fullname", "asc");
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    sorting(column, direction) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.page = 1;
        this.tableService.searchOn = "fullname";
    }

    public onPatientDashboard(user) { }
    public back() {
        this.router.navigate(["telerx"]);
    }
    public goToUserManagement(email) {
        this.router.navigate(["/admin"], {
            queryParams: {
                email: email,
            },
        });
    }

    public unsub(t, type) {
        let telerxid = "";
        let membershipid = "";
        if (type == "by") {
            telerxid = t.telerxid;
            membershipid = this.membership.membershipid;
        } else {
            telerxid = this.telerxid;
            membershipid = t.membershipid;
        }
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to unsubscribe?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.teleRXService.removeMemberToTeleRemedy(telerxid, membershipid).subscribe(
                    (data) => {
                        this.activeModal.close();
                        this.snackbar.show(data[0].type.message);
                        this.teleRemedies(this.telerxid);
                        this.getTeleRxMembers();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
        });
    }
    applyFilter(value: string) {
        this.members.filter = value.trim().toLocaleLowerCase();
    }
    applyTeleRxFilter(value: string) {
        //  this.subscribedTeleRemedie.filter = value.trim().toLocaleLowerCase();
    }
    applyFilterByRole(value: string) {
        return this.teleRXService.getTeleRxMembers(this.membership.telerxid, value).subscribe(
            (data) => {
                this.members = data[0].attributes.list;
                this.tableService.DATA = data[0].attributes.list;
                this.sorting("fullname", "asc");
                this.reload();
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public roleName(val) {
        let role = val.split(",");
        let _roleName = "";
        role.forEach((e, i) => {
            _roleName += this.roleService.getDisplayName(e) + ",";
        });
        return _roleName.replace(/,\s*$/, "");
    }

    public getSpecialties() {
        return this.specialtyService.getAllSpecialties().subscribe(
            (data) => {
                this.specialties = data;
                return this.specialtyService.getSpecialties(this.membership.membershipid).subscribe(
                    (data) => {
                        this.selectSpecialties = data;
                    },
                    (err) => {
                        //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    public addSpecialty() {
        const sp = this.specialties.find((el) => el.spid === this.settingForm.value.specialty);
        if (sp && !this.selectSpecialties.find((el) => el.spid === sp.spid)) {
            this.selectSpecialties.push(sp);
        }
    }

    public removeSpecialty(id) {
        this.selectSpecialties = this.selectSpecialties.filter((el) => el.spid !== id);
    }
    public chooseImageFile($event: any) {
        const file = $event.srcElement.files;
        const fileExtension = file[0].name.substr(file[0].name.lastIndexOf(".") + 1).toLowerCase();
        if (!["png", "jpg", "jpeg"].includes(fileExtension)) {
            this.snackbar.show("Please choose valid image file", "danger");
            this.isValidImg = false;
        } else {
            this.imageFileToUpload = file[0];
            this.btnImgDisabled = false;
            this.isValidImg = true;
        }
    }
    public async uploadImageFile() {
        try {
            this.btnImgDisabled = true;
            const result = await this.teleRxservice.uploadLogo(this.imageFileToUpload, this.membership.membershipid);

            this.membership.logourl = result;

            this.snackbar.show("Uploaded logo.", "success");

            this.btnImgDisabled = false;
            this.isValidImg = false;
        } catch (error) {
            console.log(error);
            this.snackbar.show(error[0].detail, "danger");
            this.btnImgDisabled = false;
            this.isValidImg = false;
        }
    }
    public preventInput(event) {
        let value = this.settingForm.value.npi;
        if (value >= 9999999999) {
            event.preventDefault();
            this.settingForm.patchValue({ npi: value.toString().substring(0, 10) });
        }
    }
    public getTeleRX() {
        this.teleRXService.getTeleRx().subscribe(
            (data) => {
                this.telerxData = data[0].attributes;
            },
            (err) => {
                //
            }
        );
    }
    public onSubscribing(action) {
        let filteredTelerx = [];
        if (action == "to") {
            let subscribedToList = this.subscribedTo.map((x) => x.telerxid);
            filteredTelerx = this.telerxData.filter((x) => !subscribedToList.includes(x.telerxid) && x.telerxid != this.telerxid && x.telerxtype != "private");
        } else {
            let subscribedByList = this.subscribedBy.map((x) => x.telerxid);
            filteredTelerx = this.telerxData.filter((x) => !subscribedByList.includes(x.telerxid) && x.telerxid != this.telerxid && x.telerxtype != "private");
        }
        this.subscribeData = {
            action: action,
            telerxList: filteredTelerx,
            telerxid: this.telerxid,
            subscribedByAll: this.subscribedByAll,
            subscribedToAll: this.subscribedToAll,
            membershipid: this.membership.membershipid,
        };
        const modalRef = this.modalService.open(SubscribeTeleRXModalComponent, { size: "sm", windowClass: "modal-small-center", backdrop: "static" });
        modalRef.componentInstance.SubscribeData = this.subscribeData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.subscribeModalResponse(receivedEntry);
        });
    }
    public subscribeModalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
            this.teleRemedies(this.telerxid);
            this.getTeleRxMembers();
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    setPageSize() {
        this.tableService.pageSize = this.pageSize;
    }
    reload() {
        this.tableService.searchTerm = "";
        this.tableService.page = 1;
    }
    public onPagination(page: any) {
        this.tableService.page = page;
    }
    onSort({ column, direction }: any) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction);
    }
}
