export class Configuration {
    constructor(
        public enable: boolean,
        public enableFor: string,
        public afterHoursSettings: any,
        public defaultMessage: string,
        public schedules: any,
        public typicalKewords: any,

    ) { }
}
