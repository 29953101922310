import { Injectable } from "@angular/core";
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of, EMPTY } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { GoalsService } from "../../../services/goals.service";

@Injectable({
    providedIn: "root",
})
export class GoalFrequenciesResolverService implements Resolve<any> {
    constructor(private router: Router, private goalsService: GoalsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        return this.goalsService.getGoalFrequencies().pipe(
            mergeMap((data) => {
                if (data) {
                    return of(data);
                } else {
                    // id not found
                    this.router.navigate(["/"]);
                    return EMPTY;
                }
            })
        );
    }
}
