import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class GoalsService {
    private url = environment.uri;

    constructor(private avcHttp: AvcHttp, private helper: Helper) { }

    public goalByPatient(userId, goalname = "", datapointtype = ""): Observable<any> {
        let datapoints = 14;
        if (datapointtype == "") {
            datapointtype = "daily";
        } else if (datapointtype == "monthly") {
            datapointtype = "daily";
            datapoints = 30;
        } else if (datapointtype == "3 month") {
            datapoints = 12;
            datapointtype = "weekly";
        } else if (datapointtype == "6 month") {
            datapoints = 24;
            datapointtype = "weekly";
        } else if (datapointtype == "yearly") {
            datapoints = 12;
            datapointtype = "monthly";
        } else {
            datapoints = 14;
        }

        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest(
                    "get",
                    this.url +
                    "/goals/list/v2/" +
                    userId +
                    "?timezone=" +
                    Intl.DateTimeFormat().resolvedOptions().timeZone +
                    "&goalname=" +
                    encodeURIComponent(goalname) +
                    "&datapointtype=" +
                    datapointtype +
                    "&datapoints=" +
                    datapoints +
                    "&membershipid=" +
                    localStorage.getItem("selectedGroup") || ""
                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }

    public addGoals(form, userId, careteamid): Observable<any> {
        let data = JSON.stringify({
            careteamid: careteamid,
            patientid: userId,
            goals: [
                {
                    goal: form.value.goal1,
                    actionurl: form.value.action1,
                },
                {
                    goal: form.value.goal2,
                    actionurl: form.value.action2,
                },
                {
                    goal: form.value.goal3,
                    actionurl: form.value.action3,
                },
            ],
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/goals", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public assignGoalToPatient(goal, goalTimes, isEditing = false): Observable<any> {
        let _datatype = goal.datatype + (goal.datatype2 !== "" ? "," + goal.datatype2 : "");
        let data = JSON.stringify({
            gdcid: goal.gdcid,
            datatype: _datatype,
            scheduletype: goal.frequencytype,
            scheduledate: goal.scheduledate,
            goaltypeid: goal.goaltypeid,
            goalname: goal.goalname,
            userid: goal.patientId,
            goaltarget: "",
            startdate: goal.startDate == null ? null : goal.startDate.year + "-" + goal.startDate.month + "-" + goal.startDate.day,
            enddate: goal.endDate === "" ? null : goal.endDate.year + "-" + goal.endDate.month + "-" + goal.endDate.day,
            graphenabled: false,
            associatedapp: goal.associatedapp !== "" ? "app://" + goal.associatedapp : "",
            goalid: goal.goalid,
            notes: goal.notes,
            notifyprovider: goal.notifyprovider,
            allowearlyentry: goal.allowearlyentry,
            target1: goal.target1,
            target2: goal.target2,
            frequencies: goal.frequencies,
            gtime: goalTimes,
            isnewgoal: !isEditing,
            membershipid: localStorage.getItem("selectedGroup") || ""
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/goals/v2", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public goallist(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/goals/groups/").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteGoal(goalname, userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/goals/" + encodeURIComponent(goalname) + "/" + userId + "").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public graphStatus(goal): Observable<any> {
        let data = JSON.stringify({
            graphenabled: goal.status,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/goals/" + goal.goalname + "/" + goal.userid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    private requestResolver(method, uri, data = {}) {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest(method, uri, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.data[0]) {
                        observer.next(body.data[0].attributes);
                        observer.complete();
                    } else if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getGoalCategories(): Observable<any> {
        return this.requestResolver("get", `${this.url}/goals/categories`);
    }

    public getGoalFrequencies(): Observable<any> {
        return this.requestResolver("get", `${this.url}/goals/frequencies`);
    }

    public getGoalTypes(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.requestResolver("get", `${this.url}/goals/types`).subscribe(
                (body) => {
                    // // temporary filter because the mobile app doesn't support this yet
                    // body = body.filter((el) => el.goaltypename.toLowerCase() !== "single target");
                    observer.next(body);
                    observer.complete();
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }

    public getGoalTimes(): Observable<any> {
        return this.requestResolver("get", `${this.url}/goals/times`);
    }

    public getGoalDataTypes(id): Observable<any> {
        return this.requestResolver("get", `${this.url}/goals/datatypes/${id}`);
    }

    public complianceGraph(userId: string) {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/goals/graph/" + userId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
    public goalByCareteam(careteamId, scheduletype = "", orderby, order): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest(
                    "get",
                    this.url +
                    "/goals/list/careteam/v1/" +
                    careteamId +
                    "?timezone=" +
                    Intl.DateTimeFormat().resolvedOptions().timeZone +
                    "&scheduletype=" +
                    scheduletype +
                    "&orderby=" +
                    orderby +
                    "&order=" +
                    order
                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
}
