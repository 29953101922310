<div class="modal-body">
    <div class="category-header">
        <label>New Room</label>
    </div>

    <div class="roomname">
        <label>Room Name</label>
        <input type="text" [(ngModel)]="roomname" class="form-control" maxlength="90" />
    </div>
    <!-- <p *ngIf="error" class="text-danger">{{ error }}</p> -->
    <div class="form-group">
        <button *ngIf="action === 'add'" [disabled]="roomname === ''" type="button" class="btn btn-primary move-buttons" (click)="onSave()">Create</button>
    </div>
    <div class="form-group">
        <button *ngIf="action === 'update'" [disabled]="roomname === ''" type="button" class="btn btn-primary move-buttons" (click)="updateRoom()">Update</button>
    </div>

    <div class="form-group">
        <button type="button" class="btn btn-secondary move-buttons-grey" (click)="onCancel()">Cancel</button>
    </div>
</div>
<app-snackbar></app-snackbar>
