<div class="modal-body">
    <div class="row">
        <h5 class="modal-title">Filter</h5>
    </div>
    <div class="filter">
        <h4>Age Group</h4>
        <div class="row">
            <div *ngFor="let a of ageGroup" class="col-4">
                <div class="form-check">
                    <input [(ngModel)]="filters.age" class="form-check-input" type="radio" name="age" id=a_{{a.value}}
                        [value]="a">
                    <label class=" form-check-label" for=a_{{a.value}}>
                        {{a.displayText}}
                    </label>
                </div>
            </div>
        </div>

        <hr>
        <h4>Gender</h4>
        <div class="row">
            <div *ngFor="let g of genders" class="col-4">
                <div class="form-check">
                    <input [(ngModel)]="filters.gender" class="form-check-input" type="radio" name="gender"
                        id={{g.value}} value={{g.value}}>
                    <label class="form-check-label" for={{g.value}}>
                        {{g.displayText}}
                    </label>
                </div>
            </div>
        </div>


        <!-- <h4>Care Length</h4>
        <div class="row">
            <div *ngFor="let c of careLength" class="col-4">
                <div class="form-check">
                    <input (change)="selectedCareLength(c)" [(ngModel)]="_careLength" class="form-check-input"
                        type="radio" name="careLength" id={{c.value}} value={{c.value}}>
                    <label class="form-check-label" for={{c.value}}>
                        {{c.displayText}}
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <h4>Reason for Visit</h4>
        <div class="row">
            <div *ngFor="let r of reasonForVisit" class="col-4">
                <div class="form-check">
                    <input (change)="selectedreasonForVisit(r)" [(ngModel)]="_reasonForVisit" class="form-check-input"
                        type="radio" name="reasonForVisit" id={{r.value}} value={{r.value}}>
                    <label class="form-check-label" for={{r.value}}>
                        {{r.displayText}}
                    </label>
                </div>
            </div>
        </div>
        <hr> -->
        <div>
            <hr>
            <h4 *ngIf="icd10Code">ICD-10 Code</h4>
            <div class="row">
                <div *ngFor="let i of icd10Code" class="col-4">
                    <div *ngIf="i =='ALL'" class="form-check">
                        <input [(ngModel)]="filters.icdCode" class="form-check-input" type="radio" name="icdCode"
                            id="ICD-10" value={{i}}>
                        <label class="form-check-label" for="ICD-10">
                            {{i}}
                        </label>
                    </div>
                    <div *ngIf="i !=='ALL'" class="form-check">
                        <input [(ngModel)]="filters.icdCode" class="form-check-input" type="radio" name="icdCode"
                            id={{i}} value={{i}}>
                        <label class="form-check-label" for={{i}}>
                            {{i}}
                        </label>
                    </div>
                </div>
            </div>
            <hr *ngIf="icd10Code">
            <h4 *ngIf="cptCode">CPT Code</h4>
            <div class="row">
                <div *ngFor="let c of cptCode" class="col-4">
                    <div *ngIf="c =='ALL'" class="form-check">
                        <input [(ngModel)]="filters.cptCode" class="form-check-input" type="radio" name="cptCode"
                            id="CPT" value={{c}}>
                        <label class="form-check-label" for="CPT">
                            {{c}}
                        </label>
                    </div>
                    <div *ngIf="c !=='ALL'" class="form-check">
                        <input [(ngModel)]="filters.cptCode" class="form-check-input" type="radio" name="cptCode"
                            id={{c}} value={{c}}>
                        <label class="form-check-label" for={{c}}>
                            {{c}}
                        </label>
                    </div>
                </div>
            </div>
            <hr *ngIf="cptCode">
            <h4 *ngIf="specialty || specialty?.length==2">Specialty</h4>
            <div class="row">
                <div *ngFor="let s of specialty" class="col-4">
                    <div *ngIf="s.id !=='ALL'" class="form-check">
                        <input [(ngModel)]="filters.specialty" class="form-check-input" type="radio" name="specialty"
                            id={{s.id}} value={{s.id}}>
                        <label class="form-check-label" for={{s.id}}>
                            {{s.b}}
                        </label>
                    </div>
                    <div *ngIf="s.id =='ALL'" class="form-check">
                        <input [(ngModel)]="filters.specialty" class="form-check-input" type="radio" name="specialty"
                            id='Specialty' value={{s.id}}>
                        <label class="form-check-label" for='Specialty'>
                            {{s.b}}
                        </label>
                    </div>
                </div>
            </div>
            <hr *ngIf="specialty|| specialty?.length==2">
            <h4 *ngIf="surveytypes|| surveytypes?.length==2">Survey Type</h4>
            <div class="row">

                <div *ngFor="let s of surveytypes" class="col-4">
                    <div *ngIf="s.id !=='ALL'" class="form-check">
                        <input [(ngModel)]="filters.surveyType" class="form-check-input" type="radio" name="survey"
                            id={{s.id}} value={{s.id}}>
                        <label class="form-check-label" for={{s.id}}>
                            {{s.b}}
                        </label>
                    </div>
                    <div *ngIf="s.id =='ALL'" class="form-check">
                        <input [(ngModel)]="filters.surveyType" class="form-check-input" type="radio" name="survey"
                            id='Survey' value={{s.id}}>
                        <label class="form-check-label" for='Survey'>
                            {{s.b}}
                        </label>
                    </div>
                </div>
            </div>
            <hr *ngIf="surveytypes|| surveytypes?.length==2">
            <!-- <h4>Median Household income*</h4>
        <div class="row">
            <div *ngFor="let g of genders" class="col-4">
                <div class="form-check">
                    <input (change)="selectedGender(g)" [(ngModel)]="gender" class="form-check-input" type="radio"
                        name="gender" id={{g.value}} value={{g.value}}>
                    <label class="form-check-label" for={{g.value}}>
                        {{g.displayText}}
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <h4>Education*</h4>
        <div class="row">
            <div *ngFor="let g of genders" class="col-4">
                <div class="form-check">
                    <input (change)="selectedGender(g)" [(ngModel)]="gender" class="form-check-input" type="radio"
                        name="gender" id={{g.value}} value={{g.value}}>
                    <label class="form-check-label" for={{g.value}}>
                        {{g.displayText}}
                    </label>
                </div>
            </div>
        </div>
        <hr> -->
        </div>
    </div>
    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>

</div>
<div class="modal-footer">
    <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
    <button (click)="onReset()" type="button" class="btn btn-secondary">Reset</button>
    <button (click)="onSubmit()" type="button" class="btn btn-primary">Done</button>
</div>