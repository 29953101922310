import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Helper } from "../../providers/helper";
import { SessionService } from "../../services/session.service";
import { PatientService } from "../../services/patients.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { IncidentModalComponent } from "../../modals/incident-modal/incident-modal.component";
import { IncidentListingModalComponent } from "../../modals/incident-listing-modal/incident-listing-modal.component";
import { MessagingService } from "../../services/messaging.service";

@Component({
    selector: "app-incident-window",
    templateUrl: "./incident-window.component.html",
    styleUrls: ["./incident-window.component.scss"],
})
export class IncidentWindowComponent implements OnInit, OnChanges {
    constructor(
        private messagingService: MessagingService,
        public sessionService: SessionService,
        private helper: Helper,
        private modalService: NgbModal,
        private patientService: PatientService,
        private snackbar: SnackbarService
    ) { }
    @Input() patient: any;
    @Input() patientDashboardSetting: any;
    @Output() readonly myEvent = new EventEmitter();
    public incidentData: any = {};
    public incidentsData: any = {};
    @Input() gender: string;
    currentPatient: any = {};
    incidents: any = [];
    ICDList: any = [];
    CPTList: any = [];
    patientGender = "";
    patientId: string = "";
    activeCareTeam = this.sessionService.activeCareTeam;
    disableEditIncident = false;
    public displayedColumns = [];
    ngOnInit() {
        this.displayedColumns = [
            { displayname: "Date", columnname: "Date", width: "10%" },
            { displayname: "Time", columnname: "Time", width: "10%" },
            { displayname: "ICD10", columnname: "ICD10", width: "30%" },
            { displayname: "CPT", columnname: "CPT", width: "30%" },
            // { displayname: "Other", columnname: "Other" },
            // { displayname: "Duration", columnname: "Duration" },
            { displayname: "Status", columnname: "Status", width: "10%" },
            { displayname: "", columnname: "", width: "10%" },
        ];
        this.currentPatient = this.patient;
        this.patientId = this.currentPatient.patientid;
        this.loadIncidents(this.patientId);
        this.getCPTCodes();
    }
    ngOnChanges() {
        if (this.patientGender != this.gender) {
            this.patientGender = this.gender;
            this.ICDList = [];
            this.getICDCodes();
        }
    }
    public isOpenedTab(name) {
        this.helper.isOpenedTab(name);
    }
    public loadIncidents(patientId) {
        return this.patientService.getSymptomsfromWaitingLists(patientId, this.currentPatient.careteamid).subscribe(
            (data) => {
                if (data[0].attributes.requestlist && data[0].attributes.requestlist.length > 0) {
                    let incidentData = [];
                    let incidentsArray = data[0].attributes.requestlist;
                    incidentsArray.sort(function (x, y) {
                        let a = new Date(x.createdat),
                            b = new Date(y.createdat);
                        return b.getTime() - a.getTime();
                    });
                    let appointmentList = data[0].attributes.requestlist.filter((x) => (x.requesttype === "appointment" || x.requesttype === "symptoms") && x.requestdata?.symptoms).slice(0, 5);
                    appointmentList.forEach(function (incident) {
                        let row = {};
                        if (incident.requestdata) {
                            let categories = [];
                            let symptoms = incident?.requestdata?.symptoms;
                            let cpt = incident?.requestdata?.cpt;
                            let icdCodes = [];
                            let generalSymptoms = [];
                            let other = [];
                            let duration = [];
                            Object.keys(symptoms).forEach((key) => {
                                categories.push({
                                    key: key,
                                    value: symptoms[key],
                                });
                                let icdcode = symptoms[key].reasons.map(function (elem) {
                                    return elem.icdcode;
                                });
                                icdcode.forEach((element) => {
                                    icdCodes.push(element ? element : "");
                                });
                                let generalSymptom = [];
                                if (symptoms[key]["other"]) {
                                    other.push(symptoms[key]["other"]);
                                } else {
                                    other.push("-");
                                }
                                let durationValue = "-";
                                if (symptoms[key]["duration"]) {
                                    durationValue =
                                        symptoms[key]["duration"].since +
                                        " " +
                                        (parseInt(symptoms[key]["duration"].since) > 1 ? symptoms[key]["duration"].sinceUnit : symptoms[key]["duration"].sinceUnit.slice(0, -1));
                                    duration.push(durationValue);
                                } else {
                                    duration.push(durationValue);
                                }
                                let exData = "";
                                if (symptoms[key]["other"]) {
                                    exData = "Other: " + symptoms[key]["other"];
                                }
                                if (durationValue !== "-") {
                                    if (exData !== "") {
                                        exData += ", ";
                                    }
                                    exData += "Since: " + durationValue;
                                }

                                if (exData !== "") {
                                    exData = " (" + exData + ")";
                                }
                                if (symptoms[key].reasons && symptoms[key].reasons.length > 0) {
                                    generalSymptom = symptoms[key].reasons.map(function (elem) {
                                        return (key ? key + " - " : "") + elem.generalsymptoms + exData;
                                    });
                                } else {
                                    generalSymptom.push(key + (exData === "" ? "" : " -" + exData));
                                }

                                generalSymptom.forEach((element) => {
                                    generalSymptoms.push(element ? element : "");
                                });
                            });
                            row["icdcodes"] = icdCodes;
                            row["generalsymptoms"] = generalSymptoms;
                            row["symptomsother"] = other;
                            row["symptomsduration"] = duration;
                            let cptcodes = [];
                            let cptexams = [];
                            if (incident?.requestdata?.cpt) {
                                cptcodes = incident?.requestdata?.cpt.map(function (elem) {
                                    return elem.code;
                                });
                                cptexams = incident?.requestdata?.cpt.map(function (elem) {
                                    return elem.exam;
                                });
                            }
                            row["cptcodes"] = cptcodes;
                            row["cptexams"] = cptexams;
                            row["categories"] = categories;
                            row["cpt"] = cpt;
                            row["other"] = incident.requestdata.other;
                            row["sentUserMessage"] = incident.requestdata.sentUserMessage;
                        }
                        row["userid"] = incident.userid;
                        row["wruid"] = incident.wruid;
                        row["date"] = incident.createdat;
                        row["status"] = incident.status;
                        row["requesttype"] = incident.requesttype;
                        row["requestcreated"] = incident.requestcreated;
                        incidentData.push(row);
                    });
                    // console.log(incidentData);
                    this.incidents = incidentData;
                } else {
                    this.incidents = [];
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    // public getICDCodes() {
    //     this.patientService.getSymptoms(this.patientGender).subscribe(
    //         (data) => {
    //             if (data.attributes.list.length > 0) {
    //                 this.ICDList = data.attributes.list;
    //                 this.ICDList.forEach(function (e) {
    //                     e["keywords"] = (e["icdcode"] ? e["icdcode"] : "") + " - " + e["specificbodyparts"] + " - " + e["generalsymptoms"];
    //                 });
    //             } else {
    //                 this.ICDList = [];
    //             }
    //         },
    //         (err) => {
    //             this.snackbar.show(err[0].detail, "danger");
    //         }
    //     );
    // }

    public getICDCodes() {
        this.patientService.getICDCodes().subscribe(
            (data) => {
                if (data.attributes.list.length > 0) {
                    this.ICDList = data.attributes.list;
                    // console.log(this.ICDList);
                    this.ICDList.forEach(function (e) {
                        e["keywords"] = (e["code"] ? e["code"] : "") + " - " + e["description"];
                    });
                } else {
                    this.ICDList = [];
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public getCPTCodes() {
        this.patientService.getCPTCodes().subscribe(
            (data) => {
                if (data.attributes.list.length > 0) {
                    this.CPTList = data.attributes.list;
                    this.CPTList.forEach(function (e) {
                        e["keywords"] = (e["code"] ? e["code"] : "") + " " + e["exam"];
                    });
                } else {
                    this.CPTList = [];
                }
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public onAddIncident() {
        this.incidentData = {
            action: "new",
            careteamId: this.activeCareTeam.careteamid,
            patientId: this.patientId,
            ICDList: this.ICDList,
            CPTList: this.CPTList,
            gender: this.patientGender,
            incidents: {},
        };
        const modalRef = this.modalService.open(IncidentModalComponent, { size: "lg", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.IncidentData = this.incidentData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.message != "") {
                this.snackbar.show(receivedEntry.message);
            }
            this.loadIncidents(this.patientId);
        });
    }
    public onEditIncident(incident) {
        this.incidentData = {
            action: "edit",
            careteamId: this.activeCareTeam.careteamid,
            patientId: this.patientId,
            ICDList: this.ICDList,
            CPTList: this.CPTList,
            incident: incident,
            gender: this.patientGender,
        };
        const modalRef = this.modalService.open(IncidentModalComponent, { size: "lg", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.IncidentData = this.incidentData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.message != "") {
                this.snackbar.show(receivedEntry.message);
            }
            this.loadIncidents(this.patientId);
            this.messagingService.getThreads();
        });
    }
    public onShowIncidents() {
        let incidentsData = {
            action: "list",
            careteamId: this.activeCareTeam.careteamid,
            patientId: this.patientId,
            ICDList: this.ICDList,
            CPTList: this.CPTList,
            gender: this.patientGender,
            incidents: {},
        };
        const modalRef = this.modalService.open(IncidentListingModalComponent, { size: "xl", windowClass: "modal-medium", backdrop: "static" });
        modalRef.componentInstance.IncidentsData = incidentsData;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.incidents = receivedEntry.data.slice(0, 5);
        });
    }
}
