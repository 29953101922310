import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WaitingRoomService } from "../../services/waiting-room.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
@Component({
    selector: "app-waiting-room-add-modal",
    templateUrl: "./waiting-room-add-modal.component.html",
    styleUrls: ["./waiting-room-add-modal.component.css"],
})
export class WaitingRoomAddModalComponent implements OnInit {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    @Input() room: any;
    @Input() tabOrderSettings: any;
    @Input() waitingRooms: any;
    @Input() action = "add";

    public roomname = "";

    constructor(public activeModal: NgbActiveModal, private waitingRoomService: WaitingRoomService, private snackbar: SnackbarService) {}
    ngOnInit(): void {
        if (this.action !== "add") {
            this.roomname = this.room.roomname;
        }
    }
    public onCancel() {
        this.activeModal.close();
    }
    public onSave() {
        if (this.roomname.trim() !== "") {
            return this.waitingRoomService.addWaitingRooms(this.roomname).subscribe(
                (data) => {
                    this.waitingRooms.splice(this.waitingRooms.length - 2, 0, data[0].attributes.room);
                    this.roomname = "";
                    this.activeModal.close();
                    this.snackbar.show("Room Created Successfully.", "success");
                    let updateRoomEvent = new CustomEvent("UPDATE_ROOM", {});
                    document.dispatchEvent(updateRoomEvent);
                },
                (err) => {
                    this.snackbar.show(err, "danger");
                }
            );
        } else {
            this.snackbar.show("Room name is required.", "danger");
        }
    }

    public updateRoom() {
        if (this.roomname.trim() !== "") {
            return this.waitingRoomService.updateWaitingRooms(this.room, "active", this.roomname).subscribe(
                (data) => {
                    this.room.roomname = this.roomname;
                    this.roomname = "";
                    this.snackbar.show("Room Updated Successfully.", "success");
                    this.activeModal.close();
                    let updateRoomEvent = new CustomEvent("UPDATE_ROOM", {});
                    document.dispatchEvent(updateRoomEvent);
                },
                (err) => {
                    this.snackbar.show(err, "danger");
                }
            );
        } else {
            this.snackbar.show("Room name is required.", "danger");
        }
    }
}
