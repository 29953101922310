<div id="remote-media"></div>
<div style="padding: 20px" id="controls" text-center>
    <div id="preview">
        <!-- <p class="instructions">Hi there!</p> -->
        <div id="local-media"></div>
        <!-- <button class="btn btn-primary" (click)="buttonPreview()">Preview My Camera</button> -->
    </div>
    <div id="room-controls">
        <label>Name:</label>
        <input type="text" placeholder="Enter your name" name="identity" [(ngModel)]="identity" />
        <button class="btn btn-primary" (click)="joinRoom()" [hidden]="hideJoinRoom">Join Room</button>
        <button class="btn btn-primary" (click)="leaveRoom()" [hidden]="hideLeaveRoom">Leave Room</button>
    </div>
    <div id="log"></div>
</div>
