import { Component } from "@angular/core";
import { OnboardingService } from "../../../services/onboarding.service";
import { TeleRXService } from "../../../services/teleRX.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";

import { SessionService } from "../../../services/session.service";

@Component({
    selector: "app-bulkupload",
    templateUrl: "./bulkupload.component.html",
    styleUrls: ["./bulkupload.component.scss"],
})
export class BulkuploadComponent {
    constructor(private snackbar: SnackbarService, private onboarding: OnboardingService, private teleRxservice: TeleRXService, private sessionService: SessionService) {}
    public btnDisabled = false;
    public isValidCsv = false;
    public fileToUpload: any;

    public chooseFile($event: any) {
        const file = $event.srcElement.files;
        const fileExtension = file[0].name.substr(file[0].name.lastIndexOf(".") + 1);
        if (fileExtension !== "csv") {
            this.isValidCsv = false;
            this.snackbar.show("Please choose valid csv file", "danger");
        } else {
            this.fileToUpload = file[0];
            this.isValidCsv = true;
            this.btnDisabled = false;
        }
    }

    public async uploadFile() {
        try {
            this.btnDisabled = true;
            const postCsv = await this.onboarding.importCsv(this.fileToUpload);
            const mergeTempOnboarding = await this.onboarding.mergeTempOnboarding();
            // const subscribedTeleRx = this.sessionService.currentUser?.attributes.subscribedTeleRx;
            // for (let index = 0; index < subscribedTeleRx.length; index++) {
            //     const addMemberToTeleRemedy = await this.teleRxservice.addMemberToTeleRemedy(subscribedTeleRx[index].telerxid);
            // }

            this.snackbar.show(mergeTempOnboarding[0].attributes.data, "success");
            this.btnDisabled = false;
            this.isValidCsv = false;
        } catch (error) {
            this.snackbar.show(error[0].detail, "danger");
            this.btnDisabled = false;
            this.isValidCsv = false;
        }
    }
}
