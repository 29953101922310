<div style="display: flex" class="search-div">
    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text">Member:</span>
        </div>
        <input type="text" class="search-ctrl" [(ngModel)]="searchText" placeholder="Enter the Member Name" (keyup.enter)="onEnter()" />
        <button (click)="searchMembers()" [disabled]="searchText.length === 0" class="btn btn-primary search-btn">Search</button>
        <button (click)="clearSearch()" class="btn btn-secondary search-btn">Reset</button>
    </div>
</div>
