import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OnboardingService } from "../../../services/onboarding.service";
import { TeleRXService } from "../../../services/teleRX.service";
import { UsersService } from "../../../services/users.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../../modals/warning-modal/warning-modal.component";
import { environment } from "../../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { RoleService } from "../../../services/role.service";
@Component({
    selector: "app-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private userService: UsersService,
        private onboarding: OnboardingService,
        private teleRxService: TeleRXService,
        private snackbar: SnackbarService,
        private route: ActivatedRoute,
        private router: Router,
        private roleService: RoleService
    ) {}

    public email = "";
    public user: any;
    public teleremedies = [];
    public selectedTeleRxId = "";
    public selectedType = "";
    public clio_careNav = environment.clio_careNav;
    public disableUser = false;
    ngOnInit(): void {
        this.route.queryParams.subscribe((res) => {
            this.email = res.email;
            if (this.email == undefined && res.user != undefined) {
                this.email = encodeURI(res.user).replace("%20", "+");
                this.disableUser = true;
            }
            if (this.email !== undefined && this.email !== "") {
                this.searchByEmail();
            }
        });
    }

    public searchByEmail() {
        this.userService.getUserByEmail(this.email.trim()).subscribe(
            (data) => {
                this.user = data[0].attributes;
                this.selectedType = "";
                this.selectedTeleRxId = "";
                this.teleremedies = [];
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public invite() {
        this.onboarding.onboarding([this.email.trim()]).subscribe(
            (data) => {
                this.snackbar.show("Invitation Sent Successfully!");
            },
            (err) => {
                this.snackbar.show("an error occurred please try again.", "danger");
            }
        );
    }

    public delete(userId) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this user?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.userService.delete(userId, 1).subscribe(
                    (data) => {
                        this.user = null;
                        this.teleremedies = [];
                        this.snackbar.show("user has been deleted successfully.");
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public getTeleRx(event) {
        this.selectedTeleRxId = "";
        this.teleremedies = [];
        if (this.selectedType !== "") {
            this.teleRxService.getTeleRxByType(this.selectedType).subscribe(
                (data) => {
                    this.teleremedies = data[0].attributes;
                    this.user.telerxs.forEach((element) => {
                        this.teleremedies = this.teleremedies.filter(function (el) {
                            return el.telerxid !== element.telerxid;
                        });
                    });
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }
    }
    public selectedTeleRx(event) {
        this.selectedTeleRxId = event.target.value;
    }
    public addUserToTeleRx() {
        this.teleRxService.addUserTelerx(this.user.userid, this.selectedTeleRxId).subscribe(
            (data) => {
                this.searchByEmail();
                this.snackbar.show(data[0].type.message);
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    public removeUserFromTeleRx(teleRxId) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to unsubscribe this user?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.teleRxService.removeUserFromTeleRemedy(teleRxId, this.user.userid).subscribe(
                    (data) => {
                        this.searchByEmail();
                        this.selectedTeleRxId = "";
                        this.snackbar.show("user unsubscribed successfully.");
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public roleName(val) {
        let role = val.split(",");
        let _roleName = "";
        role.forEach((e, i) => {
            _roleName += this.roleService.getDisplayName(e) + ",";
        });
        return _roleName.replace(/,\s*$/, "");
    }
    public getanalytics(userid) {
        this.router.navigate(["/analytics-listing/"], { queryParams: { id: userid } });
    }
    public disable(userId) {
        let newBlacklist = [
            {
                userid: userId,
                ipaddress: "",
            },
        ];
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to disable this user?";
        modalRef.componentInstance.OKButton = "Disable";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.userService.addBlacklist(newBlacklist).subscribe(
                    (data) => {
                        this.searchByEmail();
                        this.snackbar.show("user has been disable successfully.");
                    },
                    (err) => {
                        this.snackbar.show(err.errors[0].detail, "danger");
                    }
                );
            }
        });
    }
    public enable(userId) {
        let newBlacklist = [
            {
                userid: userId,
                ipaddress: "",
            },
        ];
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to enable this user?";
        modalRef.componentInstance.OKButton = "Enable";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.userService.addBlacklist(newBlacklist, "DELETE").subscribe(
                    (data) => {
                        this.searchByEmail();
                        this.snackbar.show("user has been enabled successfully.");
                    },
                    (err) => {
                        this.snackbar.show(err.errors[0].detail, "danger");
                    }
                );
            }
        });
    }
}
