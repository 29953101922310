<nav class="navbar navbar-dark bg-dark">
    <ul class="navbar nav">
        <a class="navbar-brand ml-2" style="color: white">Clio {{ environment.dashboard_name }}</a>
    </ul>
</nav>


<div class="container">
    <br><br>
    <div *ngIf="showPwdForm && !showSuccess" class="d-flex justify-content-center h-100">
        <div style="height: 50%" class="card">
            <div class="card-header"><br>
                <h4>{{heading}}</h4>
                <p>Please select a secure password. This is an administrative account with maximum privileges. We
                    strongly suggest the
                    use of a randomly generated password and password management software like <a
                        href="https://lastpass.com/">LastPass</a></p>
                <p>Please contact <a href="mailto:support@medstatix.com">support</a> with any questions.</p>
                <p>
                    <span><strong>Password Requirements:</strong> </span><br>
                    <span>Minimum: 8 characters </span><br>
                    <span>Required: 1-Upper Case, 1-Lower Case, 1-Symbol, 1-Number </span><br>
                    <span>Invalid: Spaces/Whitespace </span>
                </p>
            </div>
            <form [formGroup]="pwdForm" (ngSubmit)="submit()">
                <div class="card-body">
                    <div class="input-group form-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                        </div>
                        <input formControlName="password" type="password" class="form-control secure-text"
                            name="password" id="password" placeholder="New Password"
                            [ngClass]="{ 'is-invalid': submitted && form.password.errors }">
                        <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
                            <div *ngIf="form.password.errors">Valid New Password is required.</div>
                        </div>
                    </div>
                    <div class="input-group form-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                        </div>
                        <input formControlName="confirmPassword" type="password" class="form-control secure-text"
                            name="confirmPassword" id="confirmPassword" placeholder="Confirm Password"
                            [ngClass]="{ 'is-invalid': submitted && form.confirmPassword.errors }">
                        <div *ngIf="submitted && form.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="form.confirmPassword.errors.required">Confirm Password is required.</div>

                        </div>
                        <div *ngIf="submitted && form.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="form.confirmPassword.errors.confirmedValidator">New Password and Confirm
                                Password
                                must
                                be match.</div>
                        </div>
                    </div>

                    <div>
                        <button type="submit" class="btn btn-primary float-right">
                            <span>{{btnText}}</span>
                        </button>
                    </div>
                </div>
            </form>
            <br>
            <div class="card-footer">
                <div class="d-flex justify-content-center links">
                    <p>Clio GO, LLC, 100 N. Queen St., 3rd Floor, Lancaster, PA 17603</p>
                    <br>
                    <p>
                        &nbsp; <a href="https://clio.health/privacy-policy/" target="_blank">Privacy Policy</a>
                        &nbsp; <a href="https://www.clio.health/terms-of-use/" target="_blank">Terms of Use</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showError" class="alert alert-danger" role="alert">
        <h2>{{error.title}}</h2>
        <p>{{error.detail?.message}} <a [href]="error.detail?.supportemail">support</a></p>
    </div>
    <div *ngIf="showSuccess" class="alert alert-success" role="alert">
        <h1>{{heading == "Create Password"?"Account Created":"Your Account Has Been Updated"}}</h1>
        {{msg}}
        <br>
        <a [routerLink]="['/']"><br>
            <button class="btn btn-primary">Log In</button>
        </a>
    </div>
</div>