import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { ChangeDetectorRef, Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { CareTeamService } from "../../services/careteam.service";
import { CommunicationService } from "../../services/communications.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PasswordChangeModalComponent } from "../../modals/password-change-modal/password-change-modal.component";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { ConfidentialityStatementModalComponent } from "../../modals/confidentiality-statement-modal/confidentiality-statement-modal.component";
import { AuthService } from "../../services/auth.service";
import { TwoFactorAuthSetupComponent } from "../../modals/two-factor-auth-setup/two-factor-auth-setup.component";
import { CareTeamChangeModalComponent } from "../../modals/careteam-change-modal/careteam-change-modal.component";
import { TeleRXService } from "../../services/teleRX.service";
import { environment } from "../../../environments/environment";
import { SessionService } from "../../services/session.service";
import { PatientService } from "../../services/patients.service";
import * as firebase from "firebase/messaging";

import { WaitingRoomService } from "../../services/waiting-room.service";
import { take, takeUntil } from "rxjs/operators";
import { MessagingService } from "../../services/messaging.service";
import { Subject } from "rxjs";
import { MenuItems } from "../../_models/menuItems";
import { UserSettingsModalComponent } from "../../modals/user-settings-modal/user-settings-modal.component";
import { EntityModalComponent } from "../MSX/modals/entity-modal/entity-modal.component";
import { MSXReportService } from "../../services/msx_report.service";
import * as _ from 'underscore';
import { MediaService } from "../../services/media.service";
@Component({
    selector: "app-pagelayout",
    templateUrl: "./pagelayout.component.html",
    styleUrls: ["./pagelayout.component.scss"],
})
export class PageLayoutComponent implements OnInit, OnDestroy {
    public _opened: boolean = true;
    public username: string;
    public isSuperAdmin = false;
    public careteams: any = [];
    public _activeCareTeam: any;
    public collapsed = false;
    public isSuperProvider = false;
    public environment = environment;

    private mobileQuery: MediaQueryList;
    private userdata: string;
    public user: any = {};
    public teleRemedie = [];
    public sessionid: string = "";
    public unreadMessagesCount: number;
    public negativeReview: number;
    public profilPicUrl: string = "";
    // public campaignURL = "";
    private windowResizeFinished;

    public messagingAlertText: string = "";
    public showMessagingAlert: boolean = false;
    public isTelerxTypeService = false;
    public userRole = this.sessionService.getCurrentUserRole();

    public showTwoFactorAuthReminder: boolean = false;
    public twoFactorAuthReminderText: String =
        "Hey there, we noticed that you do not have two-factor authentication enabled. Please enable two-factor authentication to add a second layer of security to your account.";

    public waitingRooms = [];
    componentDestructionNotifier = new Subject();
    public showManagement = false;
    public _blurStatus = true;
    public heading = "";
    entities = [];
    selectedGroup = "";
    showLoader = false;
    msxAdmin = false;
    sessionId = "";
    @ViewChild("sidebar", { static: false }) sidebar: ElementRef;
    @ViewChild("main_container", { static: false }) containerElement: ElementRef;
    // eslint-disable-next-line
    @HostListener("window:resize", ["$event"])
    onResize(event) {
        clearTimeout(this.windowResizeFinished);
        this.windowResizeFinished = setTimeout(() => {
            // Rerender the sidebar container size when window size changes
            (this.sidebar as any).triggerRerender();
        }, 100);
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public router: Router,
        private careTeamService: CareTeamService,
        private communicationService: CommunicationService,
        private modalService: NgbModal,
        private snackbar: SnackbarService,
        private route: ActivatedRoute,
        public sessionService: SessionService,
        private authService: AuthService,
        private teleRXService: TeleRXService,
        private patientService: PatientService,
        private waitingRoomService: WaitingRoomService,

        private messagingService: MessagingService,
        public menuItems: MenuItems,
        private msxReportService: MSXReportService,
        private mediaService: MediaService,
    ) {
        this.mobileQuery = media.matchMedia("(max-width: 600px)");
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        // this.campaignURL = environment.campaignManagerServerUri;
    }

    public _toggleSidebar() {
        this._opened = !this._opened;
        //this.helper.changeMenuState(this._opened);
    }

    private _mobileQueryListener: () => void;

    @HostListener("document:visibilitychange", ["$event"])
    handleVisibilityChange(event: any): void {
        if (!document.hidden) {
            this.countUnreadMessages();
        }
    }

    async ngOnInit() {
        this.heading = location.pathname.split("/")[1].replace("-", " ");
        if (location.pathname === "/patientoutreach") {
            this.heading = "patient engagement";
        }
        if (location.pathname.includes("campaign?id=")) {
            this.heading = "campaign";
        }
        const groups = await this.getGroups();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.heading = event.url.split("/")[1].replace("-", " ");
                if (this.heading === "chat") {
                    this.heading = "Patient Communication";
                }
                if (this.heading === "waiting-room") {
                    this.heading = "";
                }
                if (this.heading === "favorites?type=survey") {
                    this.heading = "survey data";
                }
                if (this.heading === "favorites?type=survey&graph=default") {
                    this.heading = "survey data";
                }
                if (this.heading === "favorites?type=visit") {
                    this.heading = "visit data";
                }
                if (this.heading === "favorites?type=visit&graph=default") {
                    this.heading = "visit data";
                }
                if (this.heading === "favorites?type=billing") {
                    this.heading = "billing data";
                }
                if (this.heading === "favorites?type=billing&graph=default") {
                    this.heading = "billing data";
                }
                if (this.heading === "schedule report") {
                    this.heading = "administration";
                }
                if (this.heading.includes("campaign?id=")) {
                    this.heading = "campaign";
                }
                if (this.heading === "patientoutreach") {
                    this.heading = "patient engagement";
                }

            }

        });

        this.isPasswordExpired();
        this.teleRemedies();
        this.username = this.sessionService.currentUser?.attributes.email;
        this.profilPicUrl = this.sessionService.currentUserSettings.profilepic;
        this.userdata = this.sessionService.currentUser;
        this.user = this.userdata;
        if (this.user.attributes.membership.telerx && this.user.attributes.membership.telerx.telerxtype === "service") {
            this.isTelerxTypeService = true;
        }
        this.isSuperProvider = this.sessionService.isProviderAdmin();

        // Get careteams from resolver
        if (!this.sessionService.isAdmin() && !this.sessionService.isSuperAdmin()) {
            this.careteams = this.route.snapshot.data["careteams"][0].attributes.careteams;
        }

        // Set the active careteam
        this._activeCareTeam = !(this.sessionService.isAdmin() || this.sessionService.isSuperAdmin()) ? this.sessionService.activeCareTeam : "";

        if (this.mobileQuery.matches) this._opened = false;
        else this._opened = true;
        for (let role of this.userdata["attributes"].roles) {
            if (role === "superAdmin") {
                this.isSuperAdmin = true;
            }
        }


        if (!this.sessionService.isAdmin() && !this.sessionService.isSuperAdmin()) {
            // Load resolved route data
            this.route.data.subscribe((data) => {
                // Display login notice
                if (data.notice.statement.message && (!data.notice.acceptance || data.notice.statement.version > data.notice.acceptance.version)) {
                    this.openConfidentialityStatmentModal(data.notice.statement);
                }
            });

            // Initialize messaging
            this.initializeMessaging();

            // Listen for message read events and update the unread messages count
            // NOTE: we do not need to remove this event listener on component destory because
            //       it will only even be setup once on page load.
            document.addEventListener("UPDATE_UNREAD_MESSAGE_COUNT", (event: any) => {
                // console.log('Update unread message count:', event.detail)
                if (event.detail && event.detail.reload) {
                    setTimeout(() => {
                        this.countUnreadMessages();
                    }, 500);
                }
            });

            document.addEventListener("UPDATE_ROOM", (event: any) => {
                this.getWaitingRooms();
            });

            document.addEventListener("UPDATE_PROFILE_IMAGE", (event: any) => {
                this.profilPicUrl = this.sessionService.currentUserSettings.profilepic;
            });


        }
        // Load resolved route data

        this.route.data.subscribe((data) => {
            // Display login notice
            if (data.notice !== undefined && data.notice.statement.message && (!data.notice.acceptance || data.notice.statement.version > data.notice.acceptance.version)) {
                this.openConfidentialityStatmentModal(data.notice.statement);
            }
        });

        if (!this.sessionService.isAdmin() && !this.sessionService.isSuperAdmin()) {
            // Initialize messaging
            //this.initializeMessaging();
            // Get Waiting Rooms
            this.getWaitingRooms();
        }

        // Check if two-factor auth is enabled for this user
        if (!this.user.attributes.twofactorenabled) {
            let _reminderDismissedTime = localStorage.getItem("twoFactorAuthReminderDismissed");
            if (isNaN(Number.parseInt(_reminderDismissedTime))) {
                // this.showTwoFactorAuthReminder = true;
            } else {
                let currentTime = new Date().getTime();
                let Difference_In_Time = currentTime - parseInt(_reminderDismissedTime);
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                if (Difference_In_Days > 14) {
                    //this.showTwoFactorAuthReminder = true;
                    localStorage.setItem("twoFactorAuthReminderDismissed", new Date().getTime().toString());
                }
            }
        }
        this.countUnreadMessages();
        this.getNegativeReviewsCount();

        if (this.messagingService.isPushMessagingDisabled) {
            // Subscribe to thread updates
            this.messagingService.latestUnreadCountPull.pipe(takeUntil(this.componentDestructionNotifier)).subscribe(
                (threads: any) => {
                    // Load messages and merge new ones
                    this.countUnreadMessages();
                },
                (err) => {
                    console.error(err);
                }
            );
        }
    }
    getWaitingRooms() {
        // console.log(this._activeCareTeam);
        return this.waitingRoomService.getWaitingRooms(this._activeCareTeam["careteamid"]).subscribe(
            (data) => {
                // console.log(data);
                this.waitingRooms = data[0].attributes.list.filter((x) => x.status === "active");
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }
    getNegativeReviewsCount() {
        return this.mediaService.negativeReviewsCount().subscribe(
            (data) => {
                this.negativeReview = data[0].attributes.data.negative;
            },
            (err) => {
                //this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    ngOnDestroy(): void {
        // Remove listeners
        this.mobileQuery.removeListener(this._mobileQueryListener);

        // Notify component is being destroyed
        this.componentDestructionNotifier.next();
        this.componentDestructionNotifier.complete();
    }

    public isPasswordExpired() {
        let userdata = this.sessionService.currentUser;
        if (userdata.attributes.isexpired) {
            this.changePassword(true);
        }
    }
    public unSubscribePush() {
        let pushUser = JSON.parse(localStorage.getItem("pushUser"));
        if (pushUser !== null) {
            this.communicationService
                .pushUnSubscribe(pushUser.token, pushUser.careteamid)
                .pipe(take(1))
                .subscribe(
                    (data) => { },
                    (error) => {
                        console.log(error[0].detail);
                    }
                );
        }
    }
    logout(): void {
        this.authService.logout().then(() => {
            this.router.navigate(["login"]);
        });

        // // Notify the logout event subject
        // this.utilities.logoutEvent.next();
        // this.utilities.logoutEvent.complete();
    }

    /**
     * Get the number of unread message and subscribe to or begind polling messages
     */
    private initializeMessaging() {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        if (selectedGroup !== "") {
            this._activeCareTeam = { careteamid: selectedGroup.toLowerCase() }
            let fcmNotSupported =
                "Reduced Experience Warning: Real time messaging is not supported and will instead poll for new messages periodically. This is because either browser does not support it, or notifications are blocked or ports 5228-5230 are blocked.";
            if (!this.sessionService.isAdmin()) {
                // Count the total number of unread messages to display in the navbar
                //this.countUnreadMessages();

                // Check if messaging is supported and notifications are allowed
                if (!firebase.isSupported()) {
                    if (!localStorage.getItem("dismissedMessagingAlert")) {
                        // this.messagingAlertText = "Cloud messaging is not supported in this browser";
                        console.log(fcmNotSupported);
                        //disable ui
                        //this.showMessagingAlert = true;
                    }
                } else if (Notification.permission === "denied") {
                    if (!localStorage.getItem("dismissedMessagingAlert")) {
                        // this.messagingAlertText = "Real time messaging requires push notifications to work. Please enable notifications.";
                        console.log(fcmNotSupported);

                        //disable ui
                        //this.showMessagingAlert = true;
                    }
                } else if (Notification.permission === "default") {
                    // Request push notification permission from the browser
                    this.messagingService.requestPermission(this._activeCareTeam, true);
                } else {
                    // Request push notification permission from the browser
                    this.messagingService.requestPermission(this._activeCareTeam, true);
                    localStorage.removeItem("dismissedMessagingAlert");
                }

                // Always listen for updates on the "currentMessage" subject
                this.messagingService.currentMessage.pipe(takeUntil(this.componentDestructionNotifier)).subscribe(
                    (data: any) => {
                        if (data !== null && data.data.type === "chat") {
                            if (data) {
                                let msg = JSON.parse(data.data.data);
                                // Increment unread message count
                                if (msg.patientid === msg.authorid && !this.router.url.includes(`/${msg.patientid}/chat`)) {
                                    this.unreadMessagesCount = this.unreadMessagesCount += 1;
                                } else {
                                    if (this.user.id !== msg.authorid && !this.router.url.includes(`/${msg.patientid}/chat`)) {
                                        this.unreadMessagesCount = this.unreadMessagesCount += 1;
                                    }
                                }
                            }
                        }
                    },
                    (err) => {
                        console.error(err);
                        if (err.denied) {
                            console.log(fcmNotSupported);
                            //disable ui
                            //this.showMessagingAlert = true;
                        }
                    }
                );

                // Listen for incoming messages from this subject if push enabled
                // if (this.messagingService.isPushMessagingDisabled) {
                //     // Subscribe to the polling subject
                //     this.messagingService.latestCareTeamPull.pipe(takeUntil(this.componentDestructionNotifier)).subscribe(
                //         (data: any) => {
                //             if (data) {
                //                 let unreadCount = data.patient_to_provider_unread_message;
                //                 // Do not include current patient chat messages in unread count
                //                 for (let report of data.goalreport) {
                //                     if (this.router.url.includes(`/${report.userid}/chat`)) {
                //                         unreadCount = unreadCount - report.provider_unread_message;
                //                     }
                //                 }
                //                 this.unreadMessagesCount = unreadCount;
                //             }
                //         },
                //         (err) => {
                //             console.error(err);
                //             this.snackbar.show("Error: Failed to pull careteam report data", "danger");
                //         }
                //     );
                // }
            }
        }
    }
    getUserInitials() {
        return this.username;
    }

    public getCareTeams() {
        this.careTeamService
            .getCareTeamsByProvider(this.userdata["id"])
            .pipe(take(1))
            .subscribe(
                (data) => {
                    if (data[0].attributes.careteams.length > 0) {
                        this.careteams = data[0].attributes.careteams;
                        // if (this.sessionService.activeCareTeam == null) {
                        //     this.sessionService.activeCareTeam = this.careteams[0];
                        // }
                    }
                    this._activeCareTeam = this.sessionService.activeCareTeam;

                    if (this._activeCareTeam.covidtype === undefined || this._activeCareTeam.covidtype == null) {
                        this._activeCareTeam.covidtype = "NONE";
                    }
                },
                (err) => {
                    console.log(err[0].detail, 1);
                }
            );
    }

    public activeCareTeam(careTeam) {
        if (careTeam.careteamname.toLowerCase() === "no care team") {
            return false;
        } else {
            this.unSubscribePush();
            this.initializeMessaging();
            this.sessionService.activeCareTeam = careTeam;

            this.patientService.updateUserSettings({ currentcareteamid: careTeam.careteamid }, this.sessionService.currentUser.id).subscribe(
                (_data) => {
                    window.location.reload();
                },
                (err) => {
                    window.location.reload();
                }
            );
        }
    }

    public isDisabled(careTeam) {
        if (careTeam.careteamname.toLowerCase() === "no care team") {
            return true;
        } else {
            return false;
        }
    }

    changePassword(isPasswordExpired = false) {
        const modalRef = this.modalService.open(PasswordChangeModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.PasswordExpired = isPasswordExpired;
        modalRef.componentInstance.Response.subscribe(
            (data) => {
                this.modalResponse({ success: true, message: "Password changed" });
                if (isPasswordExpired) {
                    this.router.navigate(["login"]);
                }
            },
            (err) => {
                this.modalResponse({ success: false, message: err.message });
            }
        );
    }
    changeCareTeam(isPasswordExpired = false) {
        const modalRef = this.modalService.open(CareTeamChangeModalComponent, { backdrop: "static" });
        modalRef.componentInstance.careteams = this.careteams;
        modalRef.componentInstance.Response.subscribe(
            (data) => {
                this.modalResponse({ success: true, message: "Password changed" });
                if (isPasswordExpired) {
                    this.router.navigate(["login"]);
                }
            },
            (err) => {
                this.modalResponse({ success: false, message: err.message });
            }
        );
    }

    openConfidentialityStatmentModal(notice) {
        const modalRef = this.modalService.open(ConfidentialityStatementModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Notice = notice;
        modalRef.componentInstance.Response.subscribe(
            (data) => {
                let _data = data;
                // TODO: save acceptance to user object
            },
            (err) => {
                this.modalResponse({ success: false, message: err.message });
            }
        );
    }

    public onEnableTwoFactorAuth() {
        this.authService.getTwoFactorCode().subscribe((data) => {
            const modalRef = this.modalService.open(TwoFactorAuthSetupComponent, { backdrop: "static" });
            modalRef.componentInstance.CodeData = data;
            modalRef.componentInstance.Response.subscribe((res) => {
                this.modalResponse(res);
                this.user = res.user;
            });
        });
    }

    public teleRemedies() {
        this.teleRXService
            .list()
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.teleRemedie = data[0].attributes.filter((teleremedy) => {
                        return teleremedy.membershipid !== this.sessionService.getCurrentUserMembershipId();
                    });
                    this.teleRemedie.forEach((t) => {
                        if (t.issubscribed && t.isenabled && t.providerdashboardurl !== null && t.providerdashboardurl !== "") {
                            this.showManagement = true;
                        }
                    });
                },
                (err) => {
                    this.snackbar.show(err[0].detail !== undefined ? err[0].detail : err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
    }

    public onDisableTwoFactorAuth() {
        const modalRef = this.modalService.open(TwoFactorAuthSetupComponent, { backdrop: "static" });
        modalRef.componentInstance.Action = "disable";
        modalRef.componentInstance.Response.subscribe((res) => {
            this.modalResponse(res);
            this.user = res.user;
        });
    }

    public modalResponse(event) {
        if (event.success) {
            this.snackbar.show(event.message);
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }

    /**
     * Counts unread messages by getting careteam stats
     */
    private countUnreadMessages() {
        if (!this.sessionService.isAdmin() && !this.sessionService.isSuperAdmin()) {
            this.communicationService
                .getMessageUnreadcount()
                .pipe(take(1))
                .subscribe((data) => {
                    let message = data[0].attributes;
                    this.unreadMessagesCount = parseInt(message.unreadcount);
                });
        }
    }

    public closeMessagingSupportAlert() {
        this.showMessagingAlert = false;
        localStorage.setItem("dismissedMessagingAlert", "1");
    }

    blur(status) {
        this._blurStatus = !status;
        if (status) {
            this.containerElement.nativeElement.setAttribute("class", "blur");
        } else {
            this.containerElement.nativeElement.setAttribute("class", null);
        }
    }

    closeTwoFactorAuthReminder(enable) {
        this.showTwoFactorAuthReminder = false;
        localStorage.setItem("twoFactorAuthReminderDismissed", new Date().getTime().toString());
        if (enable) {
            this.onEnableTwoFactorAuth();
        }
    }
    public updateUserSettings() {
        const modalRef = this.modalService.open(UserSettingsModalComponent, { size: "lg", windowClass: "modal-lg-center", centered: true, backdrop: "static" });

        modalRef.componentInstance.Response.subscribe((res) => {
            this.modalResponse(res);
            this.user = res.user;
        });
    }

    public activeStateCheck(url) {
        const testurl = decodeURIComponent(this.router.url.replace("/session/", ""));
        return testurl === url;
    }

    public getEntity() {

        const modalRef = this.modalService.open(EntityModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.entities = this.entities;
    }

    async getGroups() {
        this.showLoader = false;
        return new Promise((resolve, reject) => {
            this.msxReportService.GetEntity().subscribe(
                (data) => {
                    this.entities = data[0].attributes.data.groups;
                    this.sessionService.activeCareTeam = this.entities[0].defaultCareTeam;
                    let selectedGroup = localStorage.getItem("selectedGroup") || ""
                    if (this.entities.length === 1) {
                        localStorage.setItem("selectedGroupName", this.entities[0].name);
                        //localStorage.setItem("selectedGroup", this.entities[0].groupid);
                        this.selectedGroup = localStorage.getItem("selectedGroupName");
                    }
                    else {
                        this.entities.forEach(element => {
                            if (element.groupid === selectedGroup) {
                                localStorage.setItem("selectedGroupName", element.name);
                                this.selectedGroup = localStorage.getItem("selectedGroupName");
                            }
                        });
                    }

                    if (this.entities.length > 1 && selectedGroup == "") {
                        this.getEntity();
                    }
                    else if (this.entities.length == 1 && selectedGroup == "") {
                        localStorage.setItem("selectedGroup", this.entities[0].groupid);
                    }
                    resolve(this.entities);
                    this.showLoader = false;
                },
                (err) => {
                    this.snackbar.show("error occurred please try again later.", "danger");
                    this.showLoader = false;
                    reject();
                }
            );
        });
    }

    public getDashboardModules(name = "") {
        if (this.sessionService.currentUser.attributes.sessionid != undefined || this.sessionService.currentUser.attributes != null) {
            this.sessionId = this.sessionService.currentUser.attributes.sessionid;
        }
        if (this.sessionService.currentUser.attributes.msxinfo !== undefined) {
            this.sessionService.currentUser.attributes.msxInfo = this.sessionService.currentUser.attributes.msxinfo;
        }
        if (this.sessionService.currentUser.attributes.msxInfo !== undefined &&
            this.sessionService.currentUser.attributes.msxInfo.dashboardModules !== undefined) {
            // console.log(this.sessionService.currentUser.attributes.msxInfo.roles[0].rolename)
            if (this.sessionService.currentUser.attributes.msxInfo.roles[0].rolename === "MsxAdmin") {
                this.msxAdmin = true;
            }
            else {
                this.msxAdmin = false;
            }
            return this.sessionService.currentUser.attributes.msxInfo.dashboardModules.includes(name)
        }
        else {
            return true;
        }
    }
}
