import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "filter",
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, tag: string = "tag"): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter((it) => {
            return it[tag].toLowerCase().includes(searchText);
        });
    }
}
