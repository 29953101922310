<div class="modal-body">
    <h5 class="modal-title">Metric</h5>
    <div>
        <label class="group_name"> Billing</label>
        <div *ngFor="let m of metrics" class="form-check">
            <input [checked]="m.value==selected" (change)="selectedMetric(m)" class="form-check-input" type="radio"
                name="metric" id="{{m.value}}">
            <label class="form-check-label" for="{{m.value}}">
                {{m.label}}
            </label>
        </div>
    </div>
    <app-loader class="loader" *ngIf="showLoader"></app-loader>
</div>
<div class="modal-footer">
    <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
    <button (click)="onSubmit()" type="button" class="btn btn-primary">Done</button>
</div>