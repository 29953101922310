import { Injectable } from "@angular/core";
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of, EMPTY } from "rxjs";
import { mergeMap, take } from "rxjs/operators";

import { ReportService } from "../../../services/report.service";
import { StatsService } from "../../../services/stats.service";
import { SessionService } from "../../../services/session.service";
import { Helper } from "../../../providers/helper";
@Injectable({
    providedIn: "root",
})
export class StatsResolverService implements Resolve<any> {
    constructor(private reportService: ReportService, private router: Router, private sessionService: SessionService, private statsService: StatsService, private helper: Helper) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        if (!(this.sessionService.isAdmin() || this.sessionService.isSuperAdmin())) {
            let id = "";
            if (this.sessionService.activeCareTeam == null) {
                id = this.sessionService.currentUser?.attributes.careteam.careteams[0].careteamid;
            } else {
                id = this.sessionService.activeCareTeam.careteamid;
            }
            try {
                return this.reportService.getCareTeamStats(id).pipe(
                    // take(1),
                    mergeMap((stats) => {
                        if (stats) {
                            return of(stats);
                        } else {
                            // id not found
                            this.router.navigate(["/"]);
                            return EMPTY;
                        }
                    })
                );
            } catch (err) {
                console.error(err);
            }
        } else {
            let user = this.sessionService.currentUser;
            let id = user.attributes.membership.membershipid;

            return this.statsService.getAdminStats(id).pipe(
                take(1),
                mergeMap((stats) => {
                    if (stats) {
                        return of(stats);
                    } else {
                        // id not found
                        this.router.navigate(["/"]);
                        return EMPTY;
                    }
                })
            );
        }
    }
}
