import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AnalyticsService } from "../../../services/analytics.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { ActivatedRoute } from "@angular/router";
import { SortableHeaderDirective } from "../../../directives/sortable.directive";
import { TableService } from "../../../services/table.service";
import { Observable } from "rxjs";
@Component({
    selector: "app-analytics-listing",
    templateUrl: "./analytics-listing.component.html",
    styleUrls: ["./analytics-listing.component.css"],
})
export class AnalyticsListingComponent implements OnInit {
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    error = "";
    analytics: any;
    showLoader = false;
    searchByName = "";
    useridFilter: any;
    eventsFilter = "all";
    displayedColumns = [
        { displayname: "date time", columnname: "datetimeutc" },
        { displayname: "membership id", columnname: "membershipid" },
        { displayname: "device id", columnname: "deviceid" },
        { displayname: "app version", columnname: "appversion" },
        { displayname: "category", columnname: "category" },
        { displayname: "name", columnname: "name" },
        { displayname: "app name", columnname: "appname" },
        { displayname: "analytics data", columnname: "analyticsdata" },
    ];
    constructor(private analyticsService: AnalyticsService, private route: ActivatedRoute, private snackbar: SnackbarService, public tableService: TableService) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParamMap.get("id")) {
            this.useridFilter = this.route.snapshot.queryParamMap.get("id");
            this.eventsFilter = "user";
        }
        this.getAnalytics();
    }
    public getAnalytics() {
        this.showLoader = true;
        this.error = "";
        let selectedEvent = this.eventsFilter;
        let selectedUserId = this.useridFilter?.toString().trim();
        if (this.eventsFilter != "user") {
            selectedUserId = "00000000-0000-0000-0000-000000000000";
        }
        this.analyticsService.getAnalytics(selectedEvent, selectedUserId).subscribe(
            (data) => {
                this.analytics = data[0].attributes.records;
                this.tableService.DATA = data[0].attributes.records;
                this.tableService.page = 1;
                this.tableService.searchOn = "";

                this.showLoader = false;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
                this.error = "No data found";
                this.analytics = [];
                this.showLoader = false;
            }
        );
    }
    public search() {
        this.getAnalytics();
    }
    public clear() {
        this.useridFilter = "";
        this.eventsFilter = "all";
        this.getAnalytics();
    }
}
