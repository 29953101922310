import { Component, OnInit, ViewChild, ViewChildren, OnDestroy, ElementRef, HostListener, QueryList } from "@angular/core";
import { CareTeamService } from "../../services/careteam.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { PatientService } from "../../services/patients.service";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users.service";
import { PatientDashboardModalComponent } from "../../modals/patient-dashboard-modal/patient-dashboard-modal.component";
import { PasswordResetModalComponent } from "../../modals/password-reset-modal/password-reset-modal.component";
import { Router } from "@angular/router";
import { ReportService } from "../../services/report.service";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MessagingService } from "../../services/messaging.service";

import { SessionService } from "../../services/session.service";
import { RoleService } from "../../services/role.service";
import { SortableHeaderDirective, SortEvent } from "../../directives/sortable.directive";

@Component({
    selector: "app-patient",
    templateUrl: "./patient.component.html",
    styleUrls: ["./patient.component.scss"],
})
export class PatientComponent implements OnInit, OnDestroy {
    @ViewChild("input") input: ElementRef;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

    page = 1;
    pageSize = 10;

    keyword = "fullname";
    public placeholder: string = "Search by Member Name";
    user = [];
    patients = [];
    _user;
    searchText: string;
    public userData: any = { user: {} };
    public providerMemberShipId = "";
    public isSuperProvider = false;
    public isAdmin = false;
    displayedColumns = [
        { displayname: "name", columnname: "fullname" },
        { displayname: "email", columnname: "email" },
        { displayname: "messages", columnname: "unread_message" },
        { displayname: "action", columnname: "" },
    ];
    public resultsLength = 50;
    public pageNo = 1;

    public NoRecordsFound = false;
    public pageIndex = 1;
    // Track open subscriptions
    componentDestructionNotifier = new Subject();
    public roles: any = [];
    roleFilter: any = 'all';
    sortby = "";
    orderBy = "asc";
    search = "";
    searchTimeout: any = null;
    showLoader = false;
    constructor(
        private careTeamService: CareTeamService,
        private snackbar: SnackbarService,
        private patientService: PatientService,
        private roleService: RoleService,
        private sessionService: SessionService,
        private modalService: NgbModal,
        private usersService: UsersService,
        public router: Router,
        private reportService: ReportService,
        private messagingService: MessagingService
    ) { }
    @HostListener("document:visibilitychange", ["$event"])
    handleVisibilityChange(event: any): void {
        if (!document.hidden) {
            this.searchPatient(this.searchText);
        }
    }
    ngOnInit() {
        //persist page size

        let page_size = localStorage.getItem("page_size") == null || localStorage.getItem("page_size") === "null" ? "10" : localStorage.getItem("page_size");
        this.pageSize = parseInt(page_size);
        this.pageIndex = 0;
        let userdata = this.sessionService.currentUser;
        this.providerMemberShipId = userdata.attributes.membership.membershipid;
        this.isSuperProvider = this.sessionService.isProviderAdmin();
        this.isAdmin = this.sessionService.isAdmin();

        if (this.isAdmin) {
            this.displayedColumns = [
                { displayname: "name", columnname: "fullname" },
                { displayname: "email", columnname: "email" },
                { displayname: "careteam", columnname: "careteamname" },
                { displayname: "role", columnname: "role" },
                { displayname: "action", columnname: "action" },
            ];

            this.roleService.getRoles().subscribe(
                (data) => {
                    this.roles = data;
                },
                (err) => console.log(err)
            );
        }

        this.searchText = localStorage.getItem("search_name") == null || localStorage.getItem("search_name") === "null" ? "" : localStorage.getItem("search_name");
        this.search = this.searchText;
        this.searchPatient(this.searchText);

        // Always listen for updates on the "currentMessage" subject
        this.messagingService.currentMessage.pipe(takeUntil(this.componentDestructionNotifier)).subscribe((data: any) => {
            if (data) {
                let msg = JSON.parse(data.data.data);
                for (let patient of this.patients) {
                    if (patient.userid === msg.patientid) {
                        if (msg.authorid === patient.userid) {
                            patient.report.provider_unread_message += 1;
                        } else {
                            patient.report.patient_unread_message += 1;
                        }
                    }
                }
            }
        });

        // Listen for incoming push messages if enabled
        if (this.messagingService.isPushMessagingDisabled) {
            // Subscribe to the polling subject
            this.messagingService.latestCareTeamPull.pipe(takeUntil(this.componentDestructionNotifier)).subscribe(
                (data: any) => {
                    if (data) {
                        // Merge updated patient report data
                        let reports = data.goalreport;
                        while (reports.length) {
                            let report = reports.shift();
                            for (let patient of this.patients) {
                                if (patient.userid === report.userid) {
                                    patient.report = report;
                                }
                            }
                        }
                    }
                },
                (err) => {
                    console.error(err);
                    this.snackbar.show("Error: Failed to pull careteam report data", "danger");
                }
            );
        }
        setTimeout(() => {
            this.defaultSortOrder("fullname", "asc");
        }, 1000);
    }
    ngOnDestroy() {
        // Notify component is being destroyed
        this.componentDestructionNotifier.next();
        this.componentDestructionNotifier.complete();
    }

    private mergePatientReports() {
        let id = this.sessionService.activeCareTeam.careteamid;
        this.reportService
            .getCareTeamStats(id)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    let reports = data.attributes[0].goalreport;
                    while (reports.length) {
                        let report = reports.shift();
                        for (let patient of this.patients) {
                            if (patient.userid === report.userid) {
                                patient.report = report;
                            }
                        }
                    }
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    public onDelete(patient) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this patient?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.usersService.deleteFromMemberShip(patient.userid).subscribe(
                    (data) => {
                        this.snackbar.show("User has been deleted successfully.");
                        this.getPatientList();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                        this.getPatientList();
                    }
                );

                // this.usersService.deleteFromMemberShip(patient.userid).subscribe(
                //     (data) => {
                //         this.usersService.unsubscribeUser(patient.userid).subscribe(
                //             (data) => {
                //                 this.snackbar.show(data[0].type.message);
                //                 this.getPatientList();
                //             },
                //             (err) => {
                //                 this.snackbar.show(err[0].detail, "danger");
                //                 this.getPatientList();
                //             }
                //         );
                //     },
                //     (err) => {
                //         this.snackbar.show(err[0].detail, "danger");
                //         this.getPatientList();
                //     }
                // );
            }
        });
    }

    public onEditProfile(user) {
        this._user = user;
        this.patientService.getUserSetting(user.userid).subscribe(
            (data) => {
                this.user = Object.assign(user, data[0].attributes);
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
            }
        );
    }

    public getPatientDetails(item) {
        return "<b>" + item.fullname + "</b> <i>(" + item.email + ")</i>";
    }

    public onAddUser() {
        // if (this.sessionService.currentUser.attributes.membership?.msxTeleRx && this.patients.length > 4) {
        //     this.snackbar.show("Clio is limited to 5 users with a trial account.  Please contact sales for more information.", "danger");
        //     return false;
        // } else {
        this.router.navigate(["/patient/details/"], { queryParams: { id: undefined } });
        // }
    }

    public modalResponse(event) {
        if (event.success) {
            if (event.message != "") {
                this.snackbar.show(event.message);
            }
            this.searchPatient(this.searchText);
        } else {
            this.snackbar.show(event.message, "danger");
        }
        if (event.reset) {
            this.searchPatient(this.searchText);
        }
    }

    public onSendOnboardingEmail(user) {
        const modalRef = this.modalService.open(PasswordResetModalComponent, { backdrop: "static" });
        modalRef.componentInstance.UserEmail = user.email;
        modalRef.componentInstance.UserRole = "user";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }

    public onResetPassword(user) {
        const modalRef = this.modalService.open(PasswordResetModalComponent, { backdrop: "static" });
        modalRef.componentInstance.UserEmail = user.email;
        modalRef.componentInstance.UserRole = "notuser";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            this.modalResponse(receivedEntry);
        });
    }

    public editUser(user) {
        this.router.navigate(["/patient/details/"], { queryParams: { id: user.userid } });
    }

    public getPatientList(name = "", limit = this.pageSize, pageno = 1, orderby = this.sortby, order = this.orderBy) {
        this.showLoader = true;
        if (name !== "") {
            localStorage.setItem("search_name", name);
        }
        let _pageno = localStorage.getItem("page_no") == null || "" ? "1" : localStorage.getItem("page_no");
        this.pageIndex = parseInt(_pageno);

        if (!this.isAdmin) {
            this.careTeamService.getPatients(name, limit, "" + _pageno, orderby, order).subscribe(
                (data) => {
                    if (data[0].attributes.list.length > 0) {
                        this.NoRecordsFound = false;
                        this.resultsLength = data[0].attributes.total_records;
                        this.patients = data[0].attributes.list;
                        this.patients = this.patients.map((p) => {
                            p.fullname = `${p.lastname}, ${p.firstname}`;
                            return p;
                        });
                        this.mergePatientReports();
                    } else {
                        this.NoRecordsFound = true;
                        this.patients = [];
                    }
                    this.showLoader = false;
                    // this.getServerData({ pageIndex: this.pageIndex });
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                    this.showLoader = false;
                }
            );
        } else {
            // console.log(`getAllUsers(${name}, ${limit}, ${pageno}, ${orderby}, ${order}, ${this.roleFilter === "-1" ? undefined : this.roleFilter})`);
            this.usersService.getAllUsers(name, limit, pageno, orderby, order, this.roleFilter === "all" ? undefined : this.roleFilter).subscribe(
                (data) => {
                    if (data[0].attributes.list.length > 0) {
                        this.NoRecordsFound = false;
                        this.resultsLength = data[0].attributes.total_records;
                        this.patients = data[0].attributes.list;
                        this.patients = this.patients.map((p) => {
                            p.fullname = `${p.lastname}, ${p.firstname}`;
                            return p;
                        });
                    } else {
                        this.NoRecordsFound = true;
                    }
                    this.showLoader = false;
                },
                (err) => {
                    this.snackbar.show(err[0].detail, "danger");
                }
            );
        }
    }

    public patientSearch(event: any) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.showLoader = true;
            this.searchPatient(event.target.value);
        }, 500);
    }
    public searchPatient(name: any): void {
        // localStorage.setItem("page_no", "1");
        //localStorage.setItem("page_size", "10");
        this.searchText = name;
        if (name === "") {
            this.getPatientList();
        } else {
            localStorage.setItem("page_no", "1");
            this.getPatientList(name, this.pageSize, 1, "fullname", this.orderBy);
        }
    }

    public filterByRole(val) {
        this.roleFilter = val;
        this.searchPatient(this.searchText);
    }

    public roleName(val) {
        return this.roleService.getDisplayName(val);
    }

    public onPatientDashboard(user) {
        if (!this.sessionService.isAdmin()) {
            const modalRef = this.modalService.open(PatientDashboardModalComponent, { backdrop: "static", windowClass: "custom-class" });
            modalRef.componentInstance.patientId = user.userid;
            modalRef.componentInstance.patient = user;
            // modalRef.componentInstance.UserRole = "notuser";
            modalRef.componentInstance.Response.subscribe((receivedEntry) => {
                this.modalResponse(receivedEntry);
            });
        }
    }

    public onPagination(page: any) {
        localStorage.setItem("page_size", this.pageSize.toString());
        localStorage.setItem("page_no", page);
        //persist page size

        let page_size = localStorage.getItem("page_size") == null || localStorage.getItem("page_size") === "null" ? "10" : localStorage.getItem("page_size");
        this.pageSize = parseInt(page_size);
        this.getPatientList(this.searchText, this.pageSize, page, this.sortby, this.orderBy);
    }
    onSort({ column, direction }: SortEvent) {
        //  this.paginator.pageIndex = 0;
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            }
        });
        this.sortby = column;
        this.orderBy = direction;
        if (this.sortby == "unread_message") {
            this.sortby = "patientmsgcount";
        }
        if (this.sortby == "role") {
            this.sortby = "rolename";
        }
        this.getPatientList(this.searchText, this.pageSize, 1, this.sortby, direction);
    }
    setPageSize() {
        let page = localStorage.getItem("page_no") == null || "" ? "1" : localStorage.getItem("page_no");
        localStorage.setItem("page_size", this.pageSize.toString());
        this.getPatientList(this.searchText, parseInt(localStorage.getItem("page_size")), parseInt(page), this.sortby, this.orderBy);
    }

    defaultSortOrder(column, direction) {
        this.headers.forEach((header) => {
            if (header.sortable == column) {
                header.direction = direction;
            }
        });
    }
    clearSearch() {
        // localStorage.setItem("search_name", "")
        // this.search = "";
        this.searchPatient(this.search);
    }
}
