<div class="container-fluid">

    <div *ngIf="isFavorite" class="filters row favorites">


        <label *ngIf="(router.url=='/home' || router.url.includes('/favorites'))" class="heading">

            {{favoriteList!==undefined? favoriteList?.name:metricName+" "+chartTitle}}

        </label>
        <label *ngIf="(router.url!='/home' && !router.url.includes('/favorites'))" class="heading">

            {{favoriteList!==undefined? favoriteList?.name:metricName+" "+chartTitle}}
            {{router.url!=='/visit-data'?metricName:'VISIT DATA'}}
        </label>
        <!-- <label class="filters">
            <img src="./assets/msx/filter.svg"> <span>FILTER:</span>
        </label> -->

        <ng-container *ngIf="isFavorite">
            <div *ngIf="selectedFactors!==null &&  selectedFactors?.selected_practice.length>0"
                class="filters row mt-2 ml-0">
                <span class="factors">Practice:</span>
                <span *ngFor="let name of selectedFactors.selected_practice ;index as i">
                    {{name.b}} <span *ngIf="i<selectedFactors.selected_practice.length-1"> |</span>
                </span>
            </div>

            <div *ngIf="selectedFactors?.selected_provider.length>0" class="filters row mt-2 ml-0">
                <span class="factors">Provider:</span>
                <span *ngFor="let name of selectedFactors.selected_provider ;index as i"
                    (click)="removeFilter(name.b,'provider')">
                    {{name.b}} <span *ngIf="i<selectedFactors.selected_provider.length-1"> |</span>
                </span>
            </div>
            <div *ngIf="selectedFactors?.selected_location.length>0" class="filters row mt-2 ml-0">
                <span class="factors">Location:</span>
                <span *ngFor="let name of selectedFactors.selected_location ;index as i"
                    (click)="removeFilter(name.b,'location')">
                    {{name.b}} <span *ngIf="i<selectedFactors.selected_location.length-1"> |</span>
                </span>
            </div>
            <div *ngIf="selectedFactors?.selected_role.length>0" class="filters row mt-2 ml-0">
                <span class="factors">Role:</span>
                <span *ngFor="let name of selectedFactors.selected_role ;index as i"
                    (click)="removeFilter(name.b,'role')">
                    {{name.b}} <span *ngIf="i<selectedFactors.selected_role.length-1"> |</span>
                </span>
            </div>


        </ng-container>

    </div>


    <div *ngIf="!isFavorite" class="tab">
        <!-- <a class="tablinks" [routerLink]="['/patient-billing']">
            <img src="/assets/msx/favorites.svg"> Favorites
        </a> -->
        <a class="tablinks" [routerLink]="['/favorites']" [queryParams]="{type: 'billing'}">
            <img src="/assets/msx/favorites.svg"> Favorites
        </a>
        <a class="tablinks active" [routerLink]="['/billing-data']">
            <img src="/assets/msx/surveydata-white.png"> Billing Data
        </a>
        <!-- <select (change)="datapoints($event)" style="height: 43px;">
            <option>7 Days</option>
            <option>14 Days</option>
            <option>Daily</option>
            <option selected>Weekly</option>
            <option>Monthly</option>
        </select> -->
    </div>
    <div *ngIf="!showLoader" class="row leftDiv">
        <div class="col-2 pr-0">
            <h4 [ngClass]="[isFavorite==true ? 'f-matric-name' : 'matric-name']">{{metricName}}</h4>
            <button *ngIf="!isFavorite" (click)="selectedMatric()" class="btn_filter">
                <img src="/assets/msx/changebutton.svg">
                Select</button><br>
        </div>
        <div class="col-10">
            <div *ngIf="chartData.length>0">
                <div *ngIf="!isFavorite" class="filters">
                    <h5 class="top-metric-name">{{metricName}} &nbsp;{{chartTitle}}</h5>
                </div>
                <div *ngIf="!isFavorite" class="filters row">
                    <div class="col-6 p-0">
                        <button *ngIf="selectedFactors?.selected_practice.length>0
                        || selectedFactors?.selected_provider.length>0
                        || selectedFactors?.selected_location.length>0
                        || selectedFactors?.selected_role.length>0" class="btn_filter grey" (click)="reset()">
                            <img src="/assets/msx/filter-white.png">RESET
                        </button>
                        <!-- <button class="btn_filter" (click)="compareGraph()">
                            <img src="/assets/msx/compare.svg">&nbsp;COMPARE
                        </button> -->
                    </div>

                    <div class="col-6 text-right">
                        <a *ngIf="!noData" class="btn-default" href="#"
                            (click)="$event.preventDefault();setFavorites()"><img src="/assets/msx/setfavorite.svg">Set
                            Favorite
                        </a>
                        <!-- <a class="btn-default"  href="#">
                        <img src="/assets/msx/export.svg">Export
                    </a> -->
                    </div>
                </div>
                <div *ngIf="selectedFactors?.selected_practice.length>0 && !isFavorite" class="filters row mt-2">
                    <label class="mr-2">Practice:</label>
                    <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_practice"
                        (click)="removeFilter(name,'practice')">{{name.bucketname}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                </div>
                <div *ngIf="selectedFactors?.selected_provider.length>0 && !isFavorite" class="filters row mt-2">
                    <label class="mr-2">Provider:</label>
                    <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_provider"
                        (click)="removeFilter(name.bucketname,'provider')">{{name.bucketname}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                </div>
                <div *ngIf="selectedFactors?.selected_location.length>0 && !isFavorite" class="filters row mt-2">
                    <label class="mr-2">Location:</label>
                    <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_location"
                        (click)="removeFilter(name.bucketname,'location')">{{name.bucketname}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                </div>
                <div *ngIf="selectedFactors?.selected_role.length>0 && !isFavorite" class="filters row mt-2">
                    <label class="mr-2">Role:</label>
                    <button class="btn_filter grey" *ngFor="let name of selectedFactors.selected_role"
                        (click)="removeFilter(name.bucketname,'role')">{{name.bucketname}}
                        &nbsp;<i class="fa fa-times-circle"></i>
                    </button>
                </div>


                <div>
                    <div class="position-relative" *ngIf="chartData.length>0 && !noData">
                        <ng-container *ngIf="graphType=='line'">
                            <highcharts-chart *ngIf="!noData && chartOptions" [Highcharts]="Highcharts"
                                style="width: 98%; display: block; overflow: hidden;padding: 10px;"
                                [options]="chartOptions" [oneToOne]="oneToOneFlag"
                                [(update)]="updateFlag"></highcharts-chart>
                        </ng-container>
                        <ng-container *ngIf="graphType=='column'">
                            <highcharts-chart *ngIf="!noData && chartOptions" [Highcharts]="Highcharts"
                                style="width: 98%; display: block; overflow: hidden;padding: 10px;"
                                [options]="chartOptions" [oneToOne]="oneToOneFlag"
                                [(update)]="updateFlag"></highcharts-chart>
                        </ng-container>

                        <ng-container *ngIf="graphType=='line'">
                            <button *ngIf="!isFavorite" class="btn_filter" (click)="compareGraph()">
                                <img src="/assets/msx/compare.svg">&nbsp;COMPARE
                            </button>
                        </ng-container>


                        <div *ngIf="noData" class="noData">
                            <label>No data available for the current selection.</label>
                        </div>
                    </div>

                    <div [ngClass]="[isFavorite==true ? 'row graph_footer mb-0' : 'row graph_footer mb-5']">
                        <div class="col-lg-3 p-0">
                            <div *ngIf="!noData">
                                <button href="#" (click)="$event.preventDefault();previous()"
                                    [className]="page==0 ? 'previous' : 'next'"><i class="fa fa-chevron-left"
                                        aria-hidden="true"></i></button>
                                <button href="#" (click)="$event.preventDefault();next()"
                                    [className]="pageCount-1==page? 'previous' : 'next'"><i class="fa fa-chevron-right"
                                        aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <h4 style="margin-left:45px;"
                                [ngClass]="[isFavorite==true ? 'f-matric-name' : 'matric-name']">{{(metricName=="Bill
                                Amount Per Provider" || metricName=="Bill Amount Per Visit")?"Provider":"Months"}}
                                <!-- &nbsp;<a (click)="xAxisFilter()" class="btn_filter">
                                <img src="/assets/msx/changebutton.svg"> Select</a> -->
                            </h4>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="chartData.length==0 || noData">
                <label class="noData">No data available for the current selection.</label>
            </div>
        </div>



    </div>
    <app-loader *ngIf="showLoader" style="position: absolute;top: 40%;left: 55%;"></app-loader>
</div>