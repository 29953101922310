import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
    selector: "service-recovery-modal",
    templateUrl: "./service-recovery-modal.component.html",
    styleUrls: ["./service-recovery-modal.component.scss"],
})
export class ServiceRecoveryModalComponent implements OnInit {

    constructor(private activeModal: NgbActiveModal) { }
    @Input() message: any;
    ngOnInit() {
        let message = this.message;
    }


    public onCancel() {
        this.activeModal.close();
    }
}
