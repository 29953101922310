<div class="modal-body">
    <div class="category-header">
        <label>Family History</label>
    </div>
    <div class="main-row">
        <div class="row checklist-row">
            <div class="inp-items col-lg-12 checklist-columns">
                <div class="checkboxes header-labels">
                    <div class="chkbox-label">
                        <label></label>
                    </div>
                    <div class="chkbox-1">
                        <label>Father</label>
                    </div>
                    <div class="chkbox-2">
                        <label>Mother</label>
                    </div>
                    <div class="chkbox-3">
                        <label>Maternal<br />Grandparent</label>
                    </div>
                    <div class="chkbox-4">
                        <label>Paternal<br />Grandparent</label>
                    </div>
                </div>
            </div>
            <div class="inp-items col-lg-12 checklist-columns" *ngFor="let item of checklist; let i = index">
                <div class="checkboxes" [ngClass]="i % 2 === 0 ? 'grey-background' : 'white-background'">
                    <div class="chkbox-label">
                        <label>{{ item.name }}</label>
                    </div>
                    <div class="chkbox-1">
                        <input class="checkbox-input" id="check_{{ i }}_father" [(ngModel)]="item.value.Father" type="checkbox" [checked]="item.value.Father === true" />
                    </div>
                    <div class="chkbox-2">
                        <input class="checkbox-input" id="check_{{ i }}_mother" [(ngModel)]="item.value.Mother" type="checkbox" [checked]="item.value.Mother === true" />
                    </div>
                    <div class="chkbox-3">
                        <input class="checkbox-input" id="check_{{ i }}_mgp" [(ngModel)]="item.value['Maternal Grandparent']" type="checkbox" [checked]="item.value['Maternal Grandparent'] === true" />
                    </div>
                    <div class="chkbox-4">
                        <input class="checkbox-input" id="check_{{ i }}_pgp" [(ngModel)]="item.value['Paternal Grandparent']" type="checkbox" [checked]="item.value['Paternal Grandparent'] === true" />
                    </div>
                </div>
            </div>
            <div class="others checklist-columns">
                <div class="others-label">
                    <label>Other</label>
                </div>
                <div class="others-text">
                    <textarea [(ngModel)]="others" type="text" class="form-control others-textarea" placeholder="Please describe"> </textarea>
                </div>
            </div>
            <p *ngIf="error" class="text-danger">{{ error }}</p>
        </div>
    </div>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>
    </div>
</div>
