import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AppsService } from "../../services/apps.service";
import { CareTeamService } from "../../services/careteam.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "../../services/session.service";
import { TeleRXService } from "../../services/teleRX.service";
import { Observable } from "rxjs";
import { SortableHeaderDirective } from "../../directives/sortable.directive";
import { TableService } from "../../../app/services/table.service";
import { GoalsService } from "../../services/goals.service";
import { WarningModalComponent } from "../../modals/warning-modal/warning-modal.component";
import { AppsModalComponent } from "../../modals/apps-modal-v2/apps-modal-v2.component";

@Component({
    selector: "orders",
    templateUrl: "./orders.component.html",
    styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
    globalLinks = [];
    publiclinks = [];
    searchByName = "";
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

    displayedColumns = [
        { displayname: "name", columnname: "fullname" },
        { displayname: "link", columnname: "" },
        { displayname: "action", columnname: "" },
    ];

    collectionSize = 0;
    pageSize = 10;
    pageIndex = 1;
    page = 1;
    showLoader = false;
    currentPatient: any = {};
    searchTimeout: any = null;
    searchTerm = "";
    membership_subscription = [];
    constructor(
        private appsService: AppsService,
        private sessionService: SessionService,
        private careTeamService: CareTeamService,
        private snackbar: SnackbarService,
        private modalService: NgbModal,
        private teleRXService: TeleRXService,
        public tableService: TableService,
        public goalsService: GoalsService,
        public activeModal: NgbActiveModal
    ) {
        // this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }
    public patients: any;
    public scheduletype = "";
    ngOnInit() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.getapps();
        }, 200);
    }

    onSort({ column, direction }: any, serverSideSorting = true) {
        // this.paginator.pageIndex = 0;
        this.sorting(column, direction, true);
    }
    sorting(column, direction, serverSideSorting = true) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.tableService.sortColumn = column;
        this.tableService.sortDirection = direction;
        this.tableService.searchOn = "link";
        if (serverSideSorting) {
            this.getapps(column, direction);
            this.tableService.page = 1;
        } else {
            this.tableService.page = 1;
        }
    }
    public getapps(orderby = "", order = "asc") {
        this.showLoader = true;
        let patientGoals = [];
        return this.appsService.getCareteamApps(this.sessionService.activeCareTeam.careteamid, this.searchTerm).subscribe(
            (data) => {
                if (data[0].attributes && data[0].attributes.users) {
                    this.patients = data[0].attributes.users;
                    var filter = [1]
                    this.patients.map(result => {
                        result.links = result.links.filter(link => link.linktype != 'global')
                        return result
                    })
                    if (orderby == "") {
                        let patientsHavingLinks = [];
                        let patientsHavingNoLinks = [];
                        this.patients.forEach(element => {
                            if (element.links.length) {
                                patientsHavingLinks.push(element)
                            }
                            else {
                                patientsHavingNoLinks.push(element)
                            }
                        });
                        this.patients = [];
                        this.patients = patientsHavingLinks.concat(patientsHavingNoLinks);
                    }
                    //this.patients.sort((a, b) => (a.links.length < b.links.length ? 1 : -1));
                    this.globalLinks = data[0].attributes.globalLinks;
                    this.publiclinks = data[0].attributes.publiclinks;
                    this.tableService.DATA = this.patients;
                    this.membership_subscription = this.patients[0]?.links;

                    this.sorting(orderby, order, false);
                }

                this.showLoader = false;
            },
            (err) => {
                this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                this.showLoader = false;
            }
        );
    }
    public reload() {
        this.getapps();
    }
    public showDelete(l) {
        if (l.linktype != undefined && l.linktype.toUpperCase() == "ASSIGNED") {
            return true;
        } else {
            return false;
        }
    }
    public onUnAssignApp(userId, appid) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to unassigne this app?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.appsService.unassignUser(userId, appid).subscribe(
                    (data) => {
                        this.snackbar.show("Application removed for member.");
                        this.getapps();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
                this.activeModal.close();
            }
        });
    }

    public onAddApp(userId) {
        // this.appData = {};
        const modalRef = this.modalService.open(AppsModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.userId = userId;
        modalRef.componentInstance.membership_subscription = this.membership_subscription;
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.success) {
                this.snackbar.show("Action completed successfully.");
                this.getapps();
            } else {
                this.snackbar.show(receivedEntry.message, "danger");
            }
        });
    }
    public searchLink(event: any) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.getapps(event.target.value);
        }, 500);
    }
}
