<div class="modal-body">
    <h5 class="modal-title">Duplicate Campaign</h5>

    <form [formGroup]="campaignForm">
        <div class="form-row">
            <div class="col-3">
                <label>New Name:</label>
            </div>
            <div class="col-9 pl-0">
                <input ngbAutofocus formControlName="newName" type="text" required class="form-control" autofocus
                        [ngClass]="{ 'is-invalid': submitted && form.newName.errors }" placeholder="Campaign Name" />
            </div>
        </div>
        <div class="text-center">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
        </div>
    </form>

</div>