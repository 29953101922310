import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
@Component({
    selector: "schedule-report-modal",
    templateUrl: "./schedule-report-modal.component.html",
    styleUrls: ["./schedule-report-modal.component.scss"],
})
export class ScheduleReportModalComponent implements OnInit {
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    @Input() userId = "";
    constructor(private activeModal: NgbActiveModal,
        private msxReportService: MSXReportService,
        private snackbar: SnackbarService,
        private formBuilder: FormBuilder,
    ) { }
    scheduleReportForm: FormGroup;
    submitted = false;
    surveyData = true;
    visitData = true;
    billingData = true;
    sendToMe = true;
    frequencies = ["Monthly", "Quartely", "Annually"]
    ngOnInit() {
        this.scheduleReportForm = this.formBuilder.group({
            reportName: ['', Validators.required],
            recipients: ['', Validators.required],
            frequency: ['monthly', Validators.required],
        });

        this.msxReportService.getEmailschedules(this.userId).subscribe(
            (data) => {
                let value = _.find(data[0].type, { settingname: 'favoriteemailschedules' });
                if (value != undefined) {
                    value = JSON.parse(value.settingvalue);
                    this.visitData = false;
                    this.surveyData = false;
                    this.billingData = false;
                    value.favorites.forEach(element => {
                        if (element == "surveyData") {
                            this.surveyData = true;
                        }
                        if (element == "visitData") {
                            this.visitData = true;
                        }
                        if (element == "billingData") {
                            this.billingData = true;
                        }

                    });
                    this.sendToMe = value.sendtoMe;
                    this.scheduleReportForm = this.formBuilder.group({
                        reportName: [value.reportName, Validators.required],
                        recipients: [value.recipients, Validators.required],
                        frequency: [value.frequency, Validators.required],
                    });
                }
            },
            (err) => {
                console.log(err)
                this.snackbar.show(err[0].title, "danger");
            }
        );



    }

    get f() { return this.scheduleReportForm.controls; }

    public onSubmit() {
        let favorites = [];
        this.submitted = true;
        if (this.scheduleReportForm.invalid) {
            this.snackbar.show("Please provide required values.", "danger");
            return false;
        }
        if (this.surveyData) {
            favorites.push("surveyData")
        }
        if (this.visitData) {
            favorites.push("visitData")
        }
        if (this.billingData) {
            favorites.push("billingData")
        }
        if (favorites.length == 0) {
            this.snackbar.show("Please select favorites to report.", "danger");
            return false;
        }
        let report = {
            reportName: this.scheduleReportForm.value.reportName,
            favorites: favorites,
            frequency: this.scheduleReportForm.value.frequency,
            sendtoMe: this.sendToMe,
            recipients: this.scheduleReportForm.value.recipients,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        this.msxReportService.saveEmailschedules(report, this.userId).subscribe(
            (data) => {
                this.activeModal.close();
                this.snackbar.show(`Favorite email schedules ${this.userId ? 'updated' : 'added'}  successfully.`, "success");
            },
            (err) => {
                this.snackbar.show(err, "danger");
            }
        );


    }

    public onCancel() {

        this.activeModal.close();
    }


}
