<div class="modal-body">
    <div class="panel-content-modal">
        <div class="category-header">
            <label>Click a Category to Edit</label>
        </div>
        <div class="summary-category-row">
            <div class="summary-category-left">
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('personal')">Personal Information</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('contact')">Contact Information</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('insurance')">Health Insurance Information</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('pastmedical')">Past Medical History</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('pastsurgical')">Past Surgical History</button>
                </div>
                <div class="summary-category-row"
                    [hidden]="!(SummaryData.summary.personalinformation.gender === 'female')">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('womenhealth')">Women's Health History</button>
                </div>
                <div [hidden]="!SummaryData.summary.phq9" class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('phq9')">Depression Analysis</button>
                </div>
                <div [hidden]="!SummaryData.summary.gad7" class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('gad7')">Stress Analysis</button>
                </div>
                <div [hidden]="!SummaryData.summary.gad7" class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('sleepassessment')">Sleep Assessment</button>
                </div>
            </div>
            <div class="summary-category-right">
                <!-- <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button" (click)="onCategorySelection('allergies')">Allergies</button>
                </div> -->
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('medicationalallergies')">Medication Allergies</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('foodallergies')">Food Allergies</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('environmentallergies')">Environmental Allergies</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('social')">Social History</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('familyhistory')">Family History</button>
                </div>
                <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('vaccines')">Vaccines</button>
                </div>
                <!-- <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button" (click)="onCategorySelection('mood')">Mood & Well-being</button>
                </div> -->
                <!-- <div class="summary-category-row">
                    <button type="button" class="btn btn-menu category-button"
                        (click)="onCategorySelection('covid19')">COVID 19</button>
                </div> -->
            </div>
        </div>
        <div class="summary-category-row footer-buttons">
            <div class="summary-category-left">
                <button type="button" class="btn btn-secondary cancel-button" (click)="onCancel()">Cancel</button>
            </div>
            <div class="summary-category-right">
                <button type="button" class="btn btn-primary save-button" (click)="onSaveChanges()">Save
                    Changes</button>
            </div>
        </div>
    </div>
</div>