import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
import { AvcHttp } from "../../providers/avc-http";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-confidentiality-statement-modal",
    templateUrl: "./confidentiality-statement-modal.component.html",
    styleUrls: ["./confidentiality-statement-modal.component.scss"],
})
export class ConfidentialityStatementModalComponent implements OnInit {
    @Input() Notice: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    title: string;
    message: string;

    acceptBtn: string = "Accept";
    declineBtn: string = "Decline";

    privacyPolicyLink: string = "https://clio.health/privacy-policy";
    termsOfServiceLink: string = "https://clio.health/terms-of-service";

    constructor(private activeModal: NgbActiveModal, private sessionService: SessionService, private avcHttp: AvcHttp) {}

    ngOnInit() {
        this.title = this.Notice.title;
        this.message = this.Notice.message;
    }

    public onAccept() {
        let acceptance = {
            version: this.Notice.version,
            acceptedAt: new Date().getTime(),
        };

        let data = JSON.stringify({
            userid: this.sessionService.currentUser?.id,
            settings: [
                {
                    settingname: "tosaccepted",
                    settingvalue: "true",
                },
            ],
        });

        this.avcHttp.sendRequest("post", environment.uri + "/settings", data).subscribe(
            (data) => {
                // console.log("accepted");
            },
            (error) => {
                console.log(error);
            }
        );
        this.activeModal.close();
    }

    public onDecline() {
        this.activeModal.close();
        // Redirect to login
        if (window.location.href.indexOf("login") === -1) {
            window.location.href = "login";
        }
    }
}
