<form [formGroup]="serviceRecoveryForm" (ngSubmit)="addToServiceRecovery()">
    <div class="modal-body">

        <div *ngIf="size=='sm'" class="row">
            <div class="col-12">
                <label>Status</label>
                <select formControlName="status" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form.status.errors }">
                    <option *ngFor="let s of statusCodes" [value]="s.value">{{ s.text }}</option>
                </select>
            </div>
            <div class="mb-2 col-12">
                <label>Reason</label>
                <textarea formControlName="reason" rows="5" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && form.reason.errors }">

            </textarea>
            </div>
        </div>


        <div *ngIf="size==''" class="row">
            <h5 class="modal-title">Service Recovery</h5>
            <div class="col-4 d-flex align-items-center">
                <ngx-avatar class="mr-2 avatar" [src]="" [name]="servicerecoveryData?.patientfirstname" size="34"
                    [round]="true" bgColor="#0A529E"> </ngx-avatar>
                <h4>
                    {{servicerecoveryData?.patientfirstname+" "+servicerecoveryData?.patientlastname}}
                </h4>
            </div>
            <div style="justify-content: flex-end;" class="col-8 d-flex align-items-center">
                <span class="pr-2">Status</span>
                <select formControlName="status" style="width:50%;padding:8px 4px;
                margin-right: 10px;" class="form-control pr-2"
                    [ngClass]="{ 'is-invalid': submitted && form.status.errors }">
                    <option *ngFor="let s of statusCodes" [value]="s.value">{{ s.text }}</option>
                </select>

                <button *ngIf="servicerecoveryData?.clio" (click)="onViewPatient(servicerecoveryData)"
                    style="padding: 4px 15px" class="btn btn-primary">
                    <img style="margin: 1px -6px;" class="menuIcon" src="/assets/menuIcons/careteamchat.svg"> Chat
                </button>

            </div>
        </div>
        <div *ngIf="size==''" class="filter p-3">

            <div class="view-columns">

                <div class="row pt-0">
                    <label class="bold">Service Recovery ID:</label> {{servicerecoveryData?.servicerecoveryid}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Patient ID:</label> {{servicerecoveryData?.practicepatientid}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Patient Name:</label> {{servicerecoveryData?.patientfirstname+"
                    "+servicerecoveryData?.patientlastname}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Patient Phone:</label>{{servicerecoveryData?.mobile}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Patient Email:</label> {{servicerecoveryData?.email}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Visit date:</label>{{convertoUStime(servicerecoveryData?.visitdate)}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Provider:</label> {{servicerecoveryData?.providerfullname}}
                </div>
                <div class="row pt-0">
                    <label class="bold">Location:</label> {{servicerecoveryData?.locationname}}
                </div>
                <div class="row">
                    <label class="bold">Patient comment on {{servicerecoveryData?.commentdate |
                        date: "EEEE, MMMM d, y h:mm:ss a" }}</label>
                </div>
                <div *ngFor="let c of servicerecoveryData?.comments" class="row pt-0">
                    <label class="bold">{{c.name}}:</label> {{c.comment}}
                </div>


                <div class="row pt-0">
                    <h4 class="pl-0">Internal Note</h4>
                    <textarea formControlName="reason" rows="5" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.reason.errors }">
            </textarea>
                </div>
                <div class="row">
                    <h4 class="pl-0">History</h4>
                </div>
                <div class="row">
                    <div class="col-4 pl-0">Status</div>
                    <div class="col-8 pl-0">Date & Internal Note</div>
                </div>
                <ng-container *ngFor="let s of servicerecoveryData.eventHistory">
                    <div *ngIf="s.eventname!=='ry Dashboard'" class="row">
                        <div class="col-4 pl-0">
                            <label class="bold">
                                {{s.eventname}}
                            </label>
                        </div>
                        <div class="col-8 pl-0">
                            <label class="bold">
                                {{s?.eventdate |
                                date: "MM/dd/y h:mm a"}} by {{s.username}}
                            </label>
                            <span>{{s.note}}</span>
                        </div>

                    </div>
                </ng-container>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button (click)="onCancel()" type="button" class="btn btn-secondary">Cancel</button>
        <button type="submit" class="btn btn-primary">Done</button>
    </div>
</form>