<div class="container-fluid p-0">
    <button *ngIf="showBrowserBtn" (click)="checkBrowserPermission()" class="btn btn-primary mt-3 ml-3">Check Browser Permission</button>
    <div id="browserPermissionlog"></div>
    <button *ngIf="showFCMBtn" (click)="textFCM()" class="btn btn-primary mt-3 ml-3">Check FCM Connection</button>
    <div id="fcmPermissionlog"></div>
    <div *ngIf="loading" class="loading-spinner-container ml-5">
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <button (click)="returnDashboard()" class="btn btn-primary mt-3 ml-3">Return to Dashboard</button>
</div>
