import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { AvcHttp } from "../providers/avc-http";
import { environment } from "../../environments/environment";
import { Helper } from "../providers/helper";

@Injectable()
export class AnalyticsService {
    private handler = `/analytics`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper) {}
    public recordEvent(category: string, name: string, data: any, userId?: string, analyticsdata = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let timestamp = new Date().toISOString();
            let eventData = JSON.stringify({
                userid: userId,
                category: category,
                name: name,
                Attributes: data,
                DateTimeUTC: timestamp,
                appname: "clio",
                analyticsdata: analyticsdata,
            });
            if (userId !== null) {
                this.avcHttp.sendRequest("post", this.url, eventData).subscribe(
                    (body) => {
                        if (body.status >= 200 && body.status < 300) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error("error");
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
            } else {
                let queryString = "appname=clio&category=" + category + "&name=" + name + "&analyticsdata=" + analyticsdata + "";
                this.avcHttp.sendRequest("get", this.url + "/view?" + queryString, {}, {}, true).subscribe(
                    (body) => {
                        if (body.status >= 200 && body.status < 300) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error("error");
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
            }
        });
    }
    public getAnalytics(eventType, userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/list?eventtype=" + eventType + "&userid=" + userId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getReport(recordtype, startdate?, enddate?, membershipid?, timezone?): Observable<any> {
        let queryurl = this.url + `/report?recordtype=${recordtype}`;

        queryurl += startdate ? `&startdate=${startdate}` : "";
        queryurl += enddate ? `&enddate=${enddate}` : "";
        queryurl += membershipid ? `&membershipid=${membershipid}` : "";
        queryurl += timezone ? `&timezone=${timezone}` : "";
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", queryurl).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
