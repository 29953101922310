import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { TeleRXService } from "../../../services/teleRX.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarningModalComponent } from "../../../modals/warning-modal/warning-modal.component";
import { Router } from "@angular/router";
import { SortableHeaderDirective } from "../../../directives/sortable.directive";
import { TableService } from "../../../services/table.service";
import { Observable } from "rxjs";
@Component({
    selector: "app-telerx-listing",
    templateUrl: "./telerx-listing.component.html",
    styleUrls: ["./telerx-listing.component.css"],
})
export class TelerxListingComponent implements OnInit {
    resultList$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
    displayedColumns = [
        { displayname: "name", columnname: "name" },
        { displayname: "description", columnname: "description" },
        { displayname: "type", columnname: "telerxtype" },
        { displayname: "email", columnname: "adminemail" },
        { displayname: "action", columnname: "" },
    ];

    teleRX: any;
    showLoader = false;
    searchByName = "";
    constructor(private teleRXService: TeleRXService, private snackbar: SnackbarService, private modalService: NgbModal, private router: Router, public tableService: TableService) {
        this.tableService.searchTerm = "";
        this.resultList$ = tableService.resultList$;
        this.total$ = tableService.total$;
    }

    ngOnInit(): void {
        this.getTeleRX();
    }

    public getTeleRX() {
        this.showLoader = true;
        this.teleRXService.getTeleRx().subscribe(
            (data) => {
                this.teleRX = data[0].attributes;
                this.tableService.DATA = data[0].attributes;
                this.tableService.searchOn = "name";
                this.showLoader = false;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
                this.showLoader = false;
            }
        );
    }

    public modalResponse(event) {
        if (event.success) {
            this.getTeleRX();
        } else {
            this.snackbar.show(event.message, "danger");
        }
    }
    public onDelete(t) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this telerx?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.teleRXService.delete(t.telerxid).subscribe(
                    (data) => {
                        this.snackbar.show(data[0].type.message);
                        this.getTeleRX();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }
    public onEdit(t) {
        this.router.navigate(["telerx/details/" + t.telerxid]);
    }
    public clear() {
        this.searchByName = "";
        this.tableService.searchTerm = "";
    }
}
