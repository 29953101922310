import { Component, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-two-factor-autologin-auth-setup",
    templateUrl: "./two-factor-autologin-auth-setup.component.html",
    styleUrls: ["./two-factor-autologin-auth-setup.component.scss"],
})
export class TwoFactorAutoLoginAuthSetupComponent {
    // Step 1: Install and authenticator app like Google Authenticator, Authy, Duo Mobile or 1Password.
    public googleAuthenticatorLink: String = "https://apps.apple.com/us/app/google-authenticator/id388497605";
    public authyLink: String = "https://apps.apple.com/us/app/twilio-authy/id494168017";
    public duoMobileLink: String = "https://apps.apple.com/us/app/duo-mobile/id422663827";

    @Input() CodeData;
    @Input() Action: String;
    @Output() readonly Response = new EventEmitter<any>();

    constructor(private fb: UntypedFormBuilder, private sessionService: SessionService, private activeModal: NgbActiveModal, private authService: AuthService, private router: Router) {}

    public modalTitle: string = "Two-Factor Authentication";
    public actionBtn: string = "Login";
    public actionBtnInProgress: string = "Verifing";

    private functionName: string = "enableTwoFactorAuth";

    public codeImageData: string = "";
    public pin: string = "";
    public errorMessage: string = "";
    public verifyingPin: boolean = false;
    public modalInstructions = "";

    public twoFactorData = this.fb.group({
        pin: ["", Validators.required],
    });

    public onSubmit() {
        this.errorMessage = "";
        if (this.twoFactorData.valid) {
            this.verifyingPin = true;

            this.authService["auto_login"](this.CodeData, this.twoFactorData.value.pin).subscribe(
                (data) => {
                    let currentUser = this.sessionService.currentUser;
                    this.Response.emit({
                        success: true,
                        message: `Two-Factor auth ${this.Action === "disable" ? "disabled" : "enabled"}`,
                        user: currentUser,
                    });
                    this.activeModal.close();
                },
                (err) => {
                    this.errorMessage = err[0].title || err[0].message || "";
                    this.verifyingPin = false;
                }
            );
        } else {
            this.errorMessage = "Please enter the 6 digit PIN.";
        }
    }

    public onCancel() {
        this.activeModal.close();
        this.router.navigate(["login"]);
    }
}
