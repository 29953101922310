<div class="modal-header text-white">
    <h5 class="modal-title">{{ appTitle }}</h5>
    <button type="button" class="close" (click)="onCancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <iframe frameborder="0" allowfullscreen width="100%" height="550" [src]="url" style="height: 80vh;"></iframe>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
</div> -->
