<div class="container-fluid page-not-found-bg">
    <div class="row justify-content-center align-items-center h-100">
        <div class="d-flex flex-column align-items-center page-not-found-content">
            <h1 class="display-1"></h1>
            <h1>You Are Unsubscribed</h1>
            <h5 class="page-not-found-details">We are so sorry to loose you,but we totally understand.</h5>

            <h5 class="page-not-found-details">If you require
                additional support please contact</h5>
            <a class="text-light" [href]="supportEmailMailTo">{{ supportEmail }} </a>
        </div>
    </div>
</div>