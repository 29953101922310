import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import * as Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import HC_Data from "highcharts/modules/export-data";
HC_exporting(Highcharts);
offlineExporting(Highcharts);
HC_Data(Highcharts);
declare var require: any
const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)
@Injectable({
    providedIn: "root",
})

export class MSXChartService {
    constructor() { }


    chartSettings(showPercentage = false, graphType = "line", url = "", banchMark = 0, selectedBanchMark = "", groupAvg = 0, items = 0, benchMarkCount = 0, noShowPercentage = false, selectedBanchMarkOption = "") {
        let pointWidth = 0;
        let rotation = -30;
        let _max = null;
        let type = "datetime";
        if (graphType === "column") {
            pointWidth = 25;
            type = "category";
            rotation = -65;
        }
        if (url === "/survey-data") {
            _max = 100;
        }
        return {
            chart: {
                renderTo: 'container',
                type: graphType,
                marginTop: 20,
                spacingBottom: 30,
                zoomType: 'x',
                marginRight: 200,
                plotBorderColor: '#C3C3C3',
                plotBorderWidth: 2,
                plotBorderRadius: 20,
            },
            credits: {
                enabled: false
            },
            title: {
                align: 'left',
                useHTML: true,
                text: null,
                //  text: '<div class="chartPrintTitle"> ' + seriesName + ' </div>',
                // text: this.metric.metricGroupName
                //   + '(Month to Date)',
                y: 1,
                style: {
                    fontSize: '10px',
                }
            },
            subtitle: {
                align: 'right',
                useHTML: true,
                //text: '<div class="chartRedDotMessage" style="font-weight:bold;font-size:smaller;">Red ' + ((multiMetric || columnChart) ? 'outline' : 'dot') + '(s) have collected very few responses and may not be accurate.</div>',
                verticalAlign: 'top',
                x: -3,
                y: 7
            },
            xAxis: {
                type: type,
                exporting: {
                    enabled: true,
                    csv: {
                        itemDelimiter: ';'
                    }
                },
                labels: {
                    rotation: rotation,
                    align: 'right',
                    // formatter: function () {
                    //     var ret = this.value,
                    //         len = ret.length;
                    //     if (len > 20) {
                    //         ret = ret.slice(0, 20) + '...';
                    //     }
                    //     return ret;
                    // }
                }
            },

            plotOptions: {
                series: {
                    pointWidth: pointWidth,
                    pointPadding: 0,
                    groupPadding: 0.08,
                    events: {
                        legendItemClick: function (e) {
                            if (e.target.name != "National Average") {
                                this.visible ? 'visible' : 'hidden';

                            }
                            else {
                                e.preventDefault();
                            }

                        }
                    }
                }

            },

            yAxis: {
                // ceiling: 350,
                max: _max,
                labels: {
                    format: '{value}'
                },
                title: {
                    text: null
                },
                plotLines: [
                    {
                        color: '#24B6FF',
                        width: 2,
                        value: _.round((banchMark == 0 ? null : banchMark), 2),
                        zIndex: 5,

                        // label: {

                        //     formatter: function () {
                        //         return '<div style="margin:8px 0px;display: flex;"><span style="max-width:170px;text-overflow: ellipsis; overflow: hidden;padding:2px;color:white;background-color:#24B6FF">' + (selectedBanchMarkOption === "stateavg" ? "State Average: " + selectedBanchMark : selectedBanchMark) + '</span></div>';
                        //     },
                        //     useHTML: true,
                        //     align: 'right',
                        //     allowOverlap: false,
                        //     // style:{
                        //     //     margineft:'100px'
                        //     // }
                        //     x: 120
                        // }
                    },

                ],
                tickInterval: 5
            },
            lang: {
                noData: "No data available."
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: '#303030'
                }
            },
            legend: {
                floating: true,
                align: 'right',
                itemMarginBottom: 5,
                x: 5,
                itemMarginLeft: 1,
                verticalAlign: 'top',
                layout: 'vertical',
                symbolPadding: 0,
                symbolWidth: 0.1,
                symbolHeight: 0.1,
                symbolRadius: 0,
                useHTML: true,
                width: 180,
                labelFormatter: function () {
                    if (!this.visible) {
                        return '<div style="margin:8px 0px;display: flex; max-width:150px"><span style="float:left;"></span><span style="max-width:145px; overflow: hidden;float:right;padding:4px;border-radius: 2px;color:white;background-color:' + this.color + '">' + this.name.substring(0, 17) + (this.name != "National Average" ? (this.visible ? '<i class="ml-1 mr-1 fa fa-eye"></i>' : '<i class="ml-1 mr-1 fa fa-eye-slash"></i>') : '' + '</span></div>');
                    }
                    else {
                        return '<div style="margin:8px 0px;display: flex; max-width:150px"><span style="float:left;"></span><span style="max-width:145px; overflow: hidden;float:right;padding:4px;border-radius: 2px;color:white;background-color:' + this.color + '">' + this.name.substring(0, 17) + (this.name != "National Average" ? (this.visible ? '<i class="ml-1 mr-1 fa fa-eye"></i>' : '<i class="ml-1 mr-1 fa fa-eye-slash"></i>') : '' + '</span></div>');
                    }
                },
                itemStyle: {
                    color: '#ffffff',
                    fontSize: '10px',
                }
            },

            tooltip: {
                valueSuffix: '%',
                useHTML: true,
                shared: true,

                formatter: function () {
                    if (url === "/survey-data") {

                        var title = graphType == "column" ? this.points[0].key : Highcharts.dateFormat('%b %y', this.points[0].key);
                        var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title + '</span></td></tr>';
                        this.points.forEach(element => {

                            let N = "";
                            if (element.point.n > 0) {
                                if (element.series.name.match(/NATIONAL AVERAGE/i)) {
                                    N = "";
                                }
                                else {
                                    if (element.point.n == 10) {
                                        element.point.n = benchMarkCount;
                                    }
                                    if (element.point.n >= 1e7)
                                        N = Math.round(element.point.n / 1e6) + 'MN';
                                    else if (element.point.n >= 1e6)
                                        N = Math.round(element.point.n / 1e5) / 10 + 'MN';
                                    else if (element.point.n >= 1e4)
                                        N = Math.round(element.point.n / 1e3) + 'KN';
                                    else if (element.point.n >= 1e3)
                                        N = Math.round(element.point.n / 1e2) / 10 + 'KN';
                                    else
                                        N = element.point.n + 'N';
                                }

                            }


                            let _y = element.y;
                            if (element.y < 100) {
                                _y = Highcharts.numberFormat(element.y, 2)
                            }
                            tooltip += '<tr>'
                                + '<td' + ((element.point.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + _y + ' %' + ((element.point.n < this.lowSample && element.point.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + '</span></td>'
                                + ((element.point.n > 0) ? '<td align="right"><span style="font-size:8px;">' + N + '</span></td>' : '')
                                + '</tr>';
                        });
                        if (banchMark > 0) {
                            let N;
                            if (benchMarkCount == 0) {
                                N = "";
                            }
                            else {
                                if (benchMarkCount >= 1e7)
                                    N = Math.round(benchMarkCount / 1e6) + 'MN';
                                else if (benchMarkCount >= 1e6)
                                    N = Math.round(benchMarkCount / 1e5) / 10 + 'MN';
                                else if (benchMarkCount >= 1e4)
                                    N = Math.round(benchMarkCount / 1e3) + 'KN';
                                else if (benchMarkCount >= 1e3)
                                    N = Math.round(benchMarkCount / 1e2) / 10 + 'KN';
                                else
                                    N = benchMarkCount + 'N';
                            }
                            if (graphType !== "line") {
                                tooltip += '<tr>'
                                    + '<td ><span style="font-weight:bold;font-size:12px;color">' + selectedBanchMark + ' Benchmark :  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + banchMark + '%</td>'
                                    + '<td align="right"><span style="font-size:8px;">' + N + '</span></td>'
                                    + '</tr>';
                            }
                        }
                        // if (groupAvg > 0) {
                        //     tooltip += '<tr>'
                        //         + '<td colspan="2"><span style="font-weight:bold;font-size:12px;color">Group Average :  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + groupAvg + '%</td>'
                        //         + '</tr>';
                        // }
                        return tooltip + '</table>';
                    }
                    else {

                        var title1 = graphType == "column" ? this.points[0].key : Highcharts.dateFormat('%b %e', this.points[0].key);
                        var tooltip = '<table><tr><td colspan="2"><span style="font-weight:bold;font-size:12px;text-decoration:underline;">' + title1 + '</span></td></tr>';
                        this.points.forEach(element => {
                            let N = "";
                            if (element.point.n > 0) {
                                if (element.series.name.match(/NATIONAL AVERAGE/i)) {
                                    N = "";
                                }
                                else {
                                    if (element.point.n >= 1e7)
                                        N = Math.round(element.point.n / 1e6) + 'MN';
                                    else if (element.point.n >= 1e6)
                                        N = Math.round(element.point.n / 1e5) / 10 + 'MN';
                                    else if (element.point.n >= 1e4)
                                        N = Math.round(element.point.n / 1e3) + 'KN';
                                    else if (element.point.n >= 1e3)
                                        N = Math.round(element.point.n / 1e2) / 10 + 'KN';
                                    else
                                        N = element.point.n + 'N';
                                }

                            }
                            tooltip += '<tr><td' + ((element.point.n > 0) ? '' : ' colspan="2"') + '><span style="font-weight:bold;font-size:12px;color:' + element.series.color + '">' + element.series.name + ':  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + element.y + ((element.point.n < this.lowSample && element.point.n > 0 && !element.series.options.isBenchmark) ? '<span style="color:red;">*</span>' : '') + (element.series.name === "NSP" ? "%" : "") + '</span></td>' + ((element.point.n > 0) ? '<td align="right"><span style="font-size:8px;">' + N + '</span></td>' : '') + '</tr>';
                        });
                        if (banchMark > 0) {
                            let N;
                            if (benchMarkCount == 0) {
                                N = "";
                            }
                            else {
                                if (benchMarkCount >= 1e7)
                                    N = Math.round(benchMarkCount / 1e6) + 'MN';
                                else if (benchMarkCount >= 1e6)
                                    N = Math.round(benchMarkCount / 1e5) / 10 + 'MN';
                                else if (benchMarkCount >= 1e4)
                                    N = Math.round(benchMarkCount / 1e3) + 'KN';
                                else if (benchMarkCount >= 1e3)
                                    N = Math.round(benchMarkCount / 1e2) / 10 + 'KN';
                                else
                                    N = benchMarkCount + 'N';
                            }
                            tooltip += '<tr>'
                                + '<td ><span style="font-weight:bold;font-size:12px;color">' + selectedBanchMark + ' Benchmark :  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + banchMark + '%</td>'
                                + '<td align="right"><span style="font-size:8px;">' + N + '</span></td>'
                                + '</tr>';
                        }
                        if (groupAvg > 0 && url != "/visit-data") {
                            tooltip += '<tr>'
                                + '<td colspan="2"><span style="font-weight:bold;font-size:12px;color">Group Average :  </span>&nbsp;<span style="font-weight:bold;font-size:12px;">' + groupAvg + '%</td>'
                                + '</tr>';
                        }
                        return tooltip + '</table>';
                    }

                }
            },

        }

    }

}

