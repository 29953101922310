import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { AvcValidators } from "../../../providers/avc-validators";
import { Helper } from "../../../providers/helper";
import { PatientService } from "../../../services/patients.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { ChatImageModalComponent } from "../../chat-image-modal/chat-image-modal.component";
import { DatePipe } from "@angular/common";

@Component({
    selector: "personal-information-modal",
    templateUrl: "./personal-information-modal.component.html",
    styleUrls: ["./personal-information-modal.component.scss"],
})
export class PersonalInformationModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private helper: Helper,
        private avcValidators: AvcValidators,
        private patientService: PatientService,
        private snackbar: SnackbarService,
        private modalService: NgbModal,
        private datePipe: DatePipe
    ) { }

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    personalForm: UntypedFormGroup;
    error = "";
    imageURL: string;
    filename: string;
    filedata: string;
    isInvalidImage: boolean = false;
    ngOnInit() {
        this.personalForm = this.fb.group({
            firstname: [this.SummaryData.summary.personalinformation.firstname],
            middlename: [this.SummaryData.summary.personalinformation.middlename], //, [Validators.pattern('[a-zA-Z0-9 "]*')]
            lastname: [this.SummaryData.summary.personalinformation.lastname],
            maidenname: [this.SummaryData.summary.personalinformation.maidenname],
            mrn: [this.SummaryData.summary.personalinformation.mrn, []],
            gender: [this.SummaryData.summary.personalinformation.gender ? this.SummaryData.summary.personalinformation.gender : "", []],
            dob: [this.datePipe.transform(this.SummaryData.summary.personalinformation.dob === undefined ? "" : this.SummaryData.summary.personalinformation.dob, 'yyyy-MM-dd'), [, this.validateAge]],
            maritalstatus: [this.SummaryData.summary.personalinformation.maritalstatus ? this.SummaryData.summary.personalinformation.maritalstatus : "", []],
            children: [this.SummaryData.summary.personalinformation.children ? this.SummaryData.summary.personalinformation.children : "", []],
            grandchildren: [this.SummaryData.summary.personalinformation.grandchildren ? this.SummaryData.summary.personalinformation.grandchildren : "", []],
            livingarrangement: [this.SummaryData.summary.personalinformation.livingarrangement ? this.SummaryData.summary.personalinformation.livingarrangement : "", []],
            employment: [this.SummaryData.summary.personalinformation.employment ? this.SummaryData.summary.personalinformation.employment : "", []],
            photoid: [this.SummaryData.summary.personalinformation.photoid],
        });
        if (this.SummaryData.summary.personalinformation.photoid) {
            this.getPhotoID();
        }
    }
    /**
     * Returns true if form control is valid after being touched
     * @param key
     */
    isFieldValid(key) {
        return this.personalForm.get(key).invalid && this.personalForm.get(key).touched;
    }
    public onSave() {
        this.personalForm.markAllAsTouched();
        if (this.personalForm.valid) {
            this.SummaryData.summary.personalinformation.firstname = this.helper.undefinedIfEmpty(this.personalForm.get("firstname").value);
            this.SummaryData.summary.personalinformation.middlename = this.helper.undefinedIfEmpty(this.personalForm.get("middlename").value);
            this.SummaryData.summary.personalinformation.lastname = this.helper.undefinedIfEmpty(this.personalForm.get("lastname").value);
            this.SummaryData.summary.personalinformation.maidenname = this.helper.undefinedIfEmpty(this.personalForm.get("maidenname").value);
            this.SummaryData.summary.personalinformation.mrn = this.helper.undefinedIfEmpty(this.personalForm.get("mrn").value);
            this.SummaryData.summary.personalinformation.gender = this.helper.undefinedIfEmpty(this.personalForm.get("gender").value);
            this.SummaryData.summary.personalinformation.dob = this.helper.undefinedIfEmpty(this.personalForm.get("dob").value);
            this.SummaryData.summary.personalinformation.maritalstatus = this.helper.undefinedIfEmpty(this.personalForm.get("maritalstatus").value);
            this.SummaryData.summary.personalinformation.children = this.helper.undefinedIfEmpty(this.personalForm.get("children").value);
            this.SummaryData.summary.personalinformation.grandchildren = this.helper.undefinedIfEmpty(this.personalForm.get("grandchildren").value);
            this.SummaryData.summary.personalinformation.livingarrangement = this.helper.undefinedIfEmpty(this.personalForm.get("livingarrangement").value);
            this.SummaryData.summary.personalinformation.employment = this.helper.undefinedIfEmpty(this.personalForm.get("employment").value);

            if (this.filedata && this.filedata !== "") {
                const formData = new FormData();
                formData.append("file", this.personalForm.get("photoid").value, this.filename);
                return this.patientService.uploadFile(formData).subscribe(
                    (uploadData) => {
                        this.SummaryData.summary.personalinformation.photoid = uploadData.attributes.libraryid;
                        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
                        this.activeModal.close();
                    },
                    (err) => {
                        this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                    }
                );
            }
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        } else {
            this.error = "Please provide mandatory fields.";
        }
    }

    getAge(dateString) {
        var diff_ms = Date.now() - new Date(dateString).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    validateAge = (c: UntypedFormControl) => {
        let diffInYears = this.getAge(c.value);

        return diffInYears >= 13
            ? null
            : {
                ValidateAge: {
                    valid: false,
                },
            };
    };
    public getPhotoID() {
        return this.patientService.getImage(this.SummaryData.summary.personalinformation.photoid).subscribe(
            (data) => {
                this.imageURL = data[0].attributes.presignedUrl;
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
    // Image Preview
    showPreview(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.personalForm.patchValue({
            photoid: file,
        });
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.filename = file.name;
                this.filedata = reader.result.toString().substr(reader.result.toString().indexOf(",") + 1);
                this.imageURL = reader.result as string;
                this.isInvalidImage = false;
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    }

    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
    public showImage(image) {
        const modalRef = this.modalService.open(ChatImageModalComponent, { size: "lg", backdrop: "static" });
        modalRef.componentInstance.UserData = image;
    }

    public minimumAge(): string {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var data = new Date(year - 13, month, day);
        return new Date(data).toISOString().split("T")[0];
    }
}
