<div class="modal-body">
    <div class="category-header">
        <label>Incident Billing</label>
    </div>
    <div class="main-row">
        <div *ngIf="currentInvoice" class="main-right invoice-data" id="print-section">
            <iframe frameBorder="0" width="100%" height="90%" (load)="resizeIframe($event)" seamless [srcdoc]="currentInvoice.pdfhtml | safe: 'html'"></iframe>
        </div>
    </div>
    <div class="main-row main-row-footer">
        <div class="footer-buttons">
            <button type="button" class="btn btn-secondary footer" (click)="onCancel()">Close</button>
        </div>
        <div class="footer-buttons">
            <button type="button" class="btn btn-primary" [disabled]="!generateInvoice || isClosed || this.BillingIncidentData.isSentUserMessage" (click)="onInvoiceGenerate()">Generate Invoice</button>
        </div>
        <div class="footer-buttons">
            <button type="button" [disabled]="!currentInvoice" class="btn btn-primary footer" printTitle="Incident Billing" printSectionId="print-section" ngxPrint>Print</button>
        </div>
    </div>
</div>
