import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../services/auth.service";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-two-factor-auth-setup",
    templateUrl: "./two-factor-auth-setup.component.html",
    styleUrls: ["./two-factor-auth-setup.component.scss"],
})
export class TwoFactorAuthSetupComponent implements OnInit {
    // Step 1: Install and authenticator app like Google Authenticator, Authy, Duo Mobile or 1Password.
    public googleAuthenticatorLink: String = "https://apps.apple.com/us/app/google-authenticator/id388497605";
    public authyLink: String = "https://apps.apple.com/us/app/twilio-authy/id494168017";
    public duoMobileLink: String = "https://apps.apple.com/us/app/duo-mobile/id422663827";

    @Input() CodeData;
    @Input() Action: String;
    @Output() readonly Response = new EventEmitter<any>();

    constructor(private fb: UntypedFormBuilder, private sessionService: SessionService, private activeModal: NgbActiveModal, private authService: AuthService) {}

    public modalTitle: string = "Setup Two-Factor Authentication";
    public modalInstructions: string = "Scan the code below using an authentication app on your phone or mobile device.";
    public actionBtn: string = "Enable";
    public actionBtnInProgress: string = "Verifing";

    private functionName: string = "enableTwoFactorAuth";

    public codeImageData: string = "";
    public pin: string = "";
    public errorMessage: string = "";
    public verifyingPin: boolean = false;

    public twoFactorData = this.fb.group({
        pin: ["", Validators.required],
    });

    ngOnInit() {
        this.codeImageData = this.CodeData;

        if (this.Action === "disable") {
            this.modalTitle = "Disable Two-Factor Authentication";
            this.modalInstructions = "To turn off two-factor authentication please enter the 6 digit PIN from your authenticator app below.";
            this.actionBtn = "Disable";
            this.functionName = "disableTwoFactorAuth";
        }
    }

    public onSubmit() {
        this.errorMessage = "";
        if (this.twoFactorData.valid) {
            this.verifyingPin = true;

            this.authService[this.functionName](this.twoFactorData.value.pin).subscribe(
                (data) => {
                    let currentUser = this.sessionService.currentUser;
                    this.Response.emit({
                        success: true,
                        message: `Two-Factor auth ${this.Action === "disable" ? "disabled" : "enabled"}`,
                        user: currentUser,
                    });
                    this.activeModal.close();
                },
                (err) => {
                    this.errorMessage = err[0].title || err[0].message || "";
                    this.verifyingPin = false;
                }
            );
        } else {
            this.errorMessage = "Please enter the 6 digit PIN.";
        }
    }

    public onCancel() {
        this.activeModal.close();
    }
}
