<div class="modal-body">
    <div class="category-header">
        <label>Allergies</label>
    </div>
    <div class="main-row">
        <div class="row checklist-row">
            <div class="col-lg-12 checklist-columns">
                <label>Medication allergies?</label>
                <div class="summary-radio">
                    <input type="radio" id="medication_yes" class="radio-buttons" name="medication" value="true" (change)="toggleRadio(true, 'medication')" [checked]="medication === true" />
                    <label for="medication_yes" class="radio-name">Yes</label>
                    <input type="radio" id="medication_no" class="radio-buttons extra-space" name="medication" value="false" (change)="toggleRadio(false, 'medication')" [checked]="medication === false" />
                    <label for="medication_no" class="radio-name">No</label>
                </div>
            </div>
            <div class="col-lg-12 checklist-columns">
                <label>Food allergies?</label>
                <div class="summary-radio">
                    <input type="radio" id="food_yes" class="radio-buttons" name="food" value="true" (change)="toggleRadio(true, 'food')" [checked]="food === true" />
                    <label for="food_yes" class="radio-name">Yes</label>
                    <input type="radio" id="food_no" class="radio-buttons extra-space" name="food" value="false" (change)="toggleRadio(false, 'food')" [checked]="food === false" />
                    <label for="food_no" class="radio-name">No</label>
                </div>
            </div>
            <div class="col-lg-12 checklist-columns">
                <label>Environmental allergies?</label>
                <div class="summary-radio">
                    <input type="radio" id="environmental_yes" class="radio-buttons" name="environmental" value="true" (change)="toggleRadio(true, 'environmental')" [checked]="environmental === true" />
                    <label for="environmental_yes" class="radio-name">Yes</label>
                    <input
                        type="radio"
                        id="environmental_no"
                        class="radio-buttons extra-space"
                        name="environmental"
                        value="false"
                        (change)="toggleRadio(false, 'environmental')"
                        [checked]="environmental === false"
                    />
                    <label for="environmental_no" class="radio-name">No</label>
                </div>
            </div>
            <p *ngIf="error" class="text-danger">{{ error }}</p>
        </div>
    </div>
    <div class="main-row main-row-footer">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>    
    </div>
</div>
