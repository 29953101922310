<div class="container-fluid">
    <div class="row">
        <div class="input-group col-6 mb-3"></div>
        <div style="display: block; text-align: right" class="input-group col-6 mb-3"></div>
    </div>
    <div class="row">
        <div class="col-5">
            <!-- <app-member-search (onsearchMembers)="searchPatient($event)"></app-member-search> -->
            <!-- <div style="display: flex" class="search-div">
                <div class="input-group mb-3 ml-3">
                    <i (click)="clearSearch()" style="cursor: pointer; font-size: 20px;margin-top: 12px;"
                        class="fa fa-redo-alt left-chevron"></i>
                    <div class="input-group-prepend">
                        <span class="input-group-text">Filter:</span>
                    </div>
                    <input type="text" class="search-ctrl" (keyup)="patientSearch($event)" [(ngModel)]="search" />
                    <div *ngIf="showLoader"
                        class="ml-2 h-100 d-flex flex-column justify-content-center align-items-center">
                        <div style="height: 24px;width: 24px;" class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="search-div">
                <input type="text" class="form-control search" (keyup)="patientSearch($event)" [(ngModel)]="search"
                    placeholder="Search..." />
                <img (click)="clearSearch()" src="/assets/msx/search.svg">
            </div>
        </div>
        <div class="col-1 pl-0">
            <div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div *ngIf="isAdmin" style="display: block; text-align: right; vertical-align: middle"
            class="input-group col-6">
            <div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Role:</span>
                    </div>
                    <select class="selectpicker form-control float-right role-ctrl custom-select" #filterUsers
                        [(ngModel)]="roleFilter" aria-describedby="_roleFilter"
                        (input)="filterByRole($event.target.value)">
                        <option value="all">All Roles</option>
                        <option *ngFor="let role of roles" [value]="role.name">{{ role.displayname }}</option>
                    </select>
                    <button (click)="onAddUser()" class="btn btn-primary ml-1">Add New</button>
                </div>
            </div>
        </div>
        <div *ngIf="!isAdmin" style="display: block; text-align: right; vertical-align: middle"
            class="input-group col-6">
            <button (click)="onAddUser()" class="btn btn-primary ml-1">Add New</button>
        </div>
        <!-- <div style="display: block; text-align: right; vertical-align: middle" class="input-group col-2"> -->
        <!-- <a *ngIf="isSuperProvider" [routerLink]="['/bulkupload']" class="btn btn-primary">Bulk Upload</a>&nbsp; -->

        <!-- </div> -->
    </div>

    <div *ngIf="!NoRecordsFound">
        <table class="table-striped datatable">
            <thead>
                <tr>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                        sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of patients">
                    <td class="cursor-pointer" (click)="onPatientDashboard(p)">{{ p.fullname }}</td>
                    <td style="text-transform: lowercase;" class="cursor-pointer" (click)="onPatientDashboard(p)">{{
                        p.email }}</td>
                    <td *ngIf="isAdmin" (click)="onPatientDashboard(p)">{{ p.careteamname }}</td>
                    <td *ngIf="isAdmin">{{ roleName(p.rolename) }}</td>
                    <td *ngIf="!isAdmin">
                        <div class="notifications-container position-relative">
                            <div [routerLink]="['/chat', p.userid]" *ngIf="p.provider_unread_message"
                                class="notification cursor-pointer" (click)="$event.stopPropagation()">
                                <i class="fas fa-2x fa-comment-dots members-icon"></i>
                                <span class="num provider-unread-badge">{{ p.provider_unread_message }}</span>
                            </div>
                            <div [routerLink]="['/chat', p.userid]" *ngIf="p.patient_unread_message"
                                class="notification cursor-pointer" (click)="$event.stopPropagation()">
                                <i class="fas fa-2x fa-comment-dots members-icon"></i>
                                <span class="num">{{ p.patient_unread_message }}</span>
                            </div>
                        </div>
                    </td>
                    <td nowrap="true">
                        <!--<i *ngIf="!isAdmin || p.rolename === 'user'" class="fa fa-envelope mr-3 cursor-pointer"
                            (click)="onSendOnboardingEmail(p)" title="Send Onboarding Email"></i>
                        <i class="fa fa-edit mr-3 cursor-pointer" (click)="editUser(p)" title="View Member"></i>-->
                        <img *ngIf="!isAdmin || p.rolename === 'user'" class="fa fa-envelope mr-3 cursor-pointer"
                            (click)="onSendOnboardingEmail(p)" title="Send Onboarding Email"
                            src="/assets/msx/invite.svg">

                        <img class="fa fa-edit mr-3 cursor-pointer" (click)="editUser(p)" title="View Member"
                            src="/assets/msx/edit.svg">

                        <i *ngIf="isAdmin && p.rolename !== 'user'" class="fa fa-key mr-3 cursor-pointer"
                            (click)="onResetPassword(p)" title="Reset Password"></i>
                        <i style="color: #707070;" class="fa fa-trash mr-3 cursor-pointer" (click)="onDelete(p)"
                            title="Remove Member"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="resultsLength" [pageSize]="pageSize" [(page)]="pageIndex" [maxSize]="5"
                [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>

            <select class="custom-select pagination"
                style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important" [(ngModel)]="pageSize"
                (ngModelChange)="setPageSize()">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>
    </div>

    <div *ngIf="NoRecordsFound">No records found</div>
</div>