<div class="modal-body">
    <div class="category-header">
        <label>Contact Information</label>
    </div>
    <div class="main-row">
        <div class="main-left">
            <form class="modal-body-form" [formGroup]="contactForm">
                <div class="form-group">
                    <label>Phone Number 1</label>
                    <div class="phone-section">
                        <div class="phone-section-number">
                            <input maxlength="14" [ngClass]="{ error: isFieldValid('phone1') }" formControlName="phone1"
                                type="text" class="form-control" />
                        </div>
                        <div class="phone-section-type">
                            <select formControlName="phone1type" [ngClass]="{ error: isFieldValid('phone1type') }"
                                class="custom-select form-control">
                                <option value="">Select</option>
                                <option value="Mobile">Mobile</option>
                                <option value="Home">Home</option>
                                <option value="Work">Work</option>
                            </select>
                        </div>
                        <div class="inp-err"
                            *ngIf="contactForm.controls['phone1'].errors && contactForm.controls['phone1'].touched">
                            <span class="error-msg"
                                *ngIf="contactForm.controls['phone1'].hasError('required') || contactForm.controls['phone1'].hasError('ValidateWhiteSpace')">
                                Phone is required.
                            </span>
                            <span class="error-msg"
                                *ngIf="contactForm.controls['phone1'].hasError('maxlength') && !contactForm.controls['phone1'].hasError('required')">
                                Phone can be max 14 characters.
                            </span>
                            <span class="error-msg" *ngIf="
                                    contactForm.controls['phone1'].hasError('pattern') && !contactForm.controls['phone1'].hasError('maxlength') && !contactForm.controls['phone1'].hasError('required')
                                ">
                                Please enter a valid phone number.
                            </span>
                        </div>
                        <div class="inp-err"
                            *ngIf="contactForm.controls['phone1type'].errors && contactForm.controls['phone1type'].touched">
                            <span class="error-msg" *ngIf="contactForm.controls['phone1type'].hasError('required')">
                                Phone Type is required. </span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Phone Number 2</label>
                    <div class="phone-section">
                        <div class="phone-section-number">
                            <input maxlength="14" [ngClass]="{ error: isFieldValid('phone2') }" formControlName="phone2"
                                type="text" class="form-control" />
                        </div>
                        <div class="phone-section-type">
                            <select formControlName="phone2type" [ngClass]="{ error: isFieldValid('phone2type') }"
                                class="custom-select form-control">
                                <option value="">Select</option>
                                <option value="Mobile">Mobile</option>
                                <option value="Home">Home</option>
                                <option value="Work">Work</option>
                            </select>
                        </div>
                        <div class="inp-err"
                            *ngIf="contactForm.controls['phone2'].errors && contactForm.controls['phone2'].touched">
                            <span class="error-msg"
                                *ngIf="contactForm.controls['phone2'].hasError('required') || contactForm.controls['phone2'].hasError('ValidateWhiteSpace')">
                                Phone is required.
                            </span>
                            <span class="error-msg"
                                *ngIf="contactForm.controls['phone2'].hasError('maxlength') && !contactForm.controls['phone2'].hasError('required')">
                                Phone is can be max 14 characters.
                            </span>
                            <span class="error-msg" *ngIf="
                                    contactForm.controls['phone2'].hasError('pattern') && !contactForm.controls['phone2'].hasError('maxlength') && !contactForm.controls['phone2'].hasError('required')
                                ">
                                Please enter a valid phone number.
                            </span>
                        </div>
                        <div class="inp-err"
                            *ngIf="contactForm.controls['phone2type'].errors && contactForm.controls['phone2type'].touched">
                            <span class="error-msg" *ngIf="contactForm.controls['phone2type'].hasError('required')">
                                Phone Type is required. </span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Phone Number 3</label>
                    <div class="phone-section">
                        <div class="phone-section-number">
                            <input maxlength="14" [ngClass]="{ error: isFieldValid('phone3') }" formControlName="phone3"
                                type="text" class="form-control" />
                        </div>
                        <div class="phone-section-type">
                            <select formControlName="phone3type" [ngClass]="{ error: isFieldValid('phone3type') }"
                                class="custom-select form-control">
                                <option value="">Select</option>
                                <option value="Mobile">Mobile</option>
                                <option value="Home">Home</option>
                                <option value="Work">Work</option>
                            </select>
                        </div>
                        <div class="inp-err"
                            *ngIf="contactForm.controls['phone3'].errors && contactForm.controls['phone3'].touched">
                            <span class="error-msg"
                                *ngIf="contactForm.controls['phone3'].hasError('required') || contactForm.controls['phone3'].hasError('ValidateWhiteSpace')">
                                Phone is required.
                            </span>
                            <span class="error-msg"
                                *ngIf="contactForm.controls['phone3'].hasError('maxlength') && !contactForm.controls['phone3'].hasError('required')">
                                Phone is can be max 14 characters.
                            </span>
                            <span class="error-msg" *ngIf="
                                    contactForm.controls['phone3'].hasError('pattern') && !contactForm.controls['phone3'].hasError('maxlength') && !contactForm.controls['phone3'].hasError('required')
                                ">
                                Please enter a valid phone number.
                            </span>
                        </div>
                        <div class="inp-err"
                            *ngIf="contactForm.controls['phone3type'].errors && contactForm.controls['phone3type'].touched">
                            <span class="error-msg" *ngIf="contactForm.controls['phone3type'].hasError('required')">
                                Phone Type is required. </span>
                        </div>
                    </div>
                </div>
            </form>
            <form class="modal-body-form" [formGroup]="emergencyForm">
                <div class="category-header-black">
                    <label class="category-header-black-label">Emergency Contact</label>
                </div>
                <div class="form-group">
                    <label>Name</label>
                    <input maxlength="100" [ngClass]="{ error: isEmergencyFieldValid('name') }" formControlName="name"
                        type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="emergencyForm.controls['name'].errors && emergencyForm.controls['name'].touched">
                        <span class="error-msg"
                            *ngIf="emergencyForm.controls['name'].hasError('required') || emergencyForm.controls['name'].hasError('ValidateWhiteSpace')">
                            Name is required. </span>
                        <span class="error-msg"
                            *ngIf="emergencyForm.controls['name'].hasError('maxlength') && !emergencyForm.controls['name'].hasError('required')">
                            Name is can be max 100 characters.
                        </span>
                        <span class="error-msg"
                            *ngIf="emergencyForm.controls['name'].hasError('pattern') && !emergencyForm.controls['name'].hasError('maxlength') && !emergencyForm.controls['name'].hasError('required')">
                            Name can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Phone Number</label>
                    <input maxlength="14" [ngClass]="{ error: isEmergencyFieldValid('phonenumber1') }"
                        formControlName="phonenumber1" type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="emergencyForm.controls['phonenumber1'].errors && emergencyForm.controls['phonenumber1'].touched">
                        <span class="error-msg"
                            *ngIf="emergencyForm.controls['phonenumber1'].hasError('required') || emergencyForm.controls['phonenumber1'].hasError('ValidateWhiteSpace')">
                            Phone is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="emergencyForm.controls['phonenumber1'].hasError('maxlength') && !emergencyForm.controls['phonenumber1'].hasError('required')">
                            Phone is can be max 14 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                emergencyForm.controls['phonenumber1'].hasError('pattern') &&
                                !emergencyForm.controls['phonenumber1'].hasError('maxlength') &&
                                !emergencyForm.controls['phonenumber1'].hasError('required')
                            ">
                            Please enter a valid phone number.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Relation</label>
                    <select formControlName="relation" [ngClass]="{ error: isEmergencyFieldValid('relation') }"
                        class="custom-select form-control">
                        <option value="">Select</option>
                        <option value="spouse">Spouse</option>
                        <option value="partner">Partner</option>
                        <option value="parent">Parent</option>
                        <option value="guardian">Guardian</option>
                        <option value="child">Child</option>
                        <option value="other">Other</option>
                    </select>
                    <div class="inp-err"
                        *ngIf="emergencyForm.controls['relation'].errors && emergencyForm.controls['relation'].touched">
                        <span class="error-msg" *ngIf="emergencyForm.controls['relation'].hasError('required')"> Field
                            is required. </span>
                    </div>
                </div>
            </form>
        </div>
        <div class="main-right">
            <form class="modal-body-form" [formGroup]="contactForm">
                <div class="form-group">
                    <label>Address 1</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('address1') }" formControlName="address1"
                        type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="contactForm.controls['address1'].errors && contactForm.controls['address1'].touched">
                        <span class="error-msg"
                            *ngIf="contactForm.controls['address1'].hasError('required') || contactForm.controls['address1'].hasError('ValidateWhiteSpace')">
                            Address is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['address1'].hasError('maxlength') && !contactForm.controls['address1'].hasError('required')">
                            Address is can be max 100 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                contactForm.controls['address1'].hasError('pattern') &&
                                !contactForm.controls['address1'].hasError('maxlength') &&
                                !contactForm.controls['address1'].hasError('required')
                            ">
                            Address can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Address 2</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('address2') }" formControlName="address2"
                        type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="contactForm.controls['address2'].errors && contactForm.controls['address2'].touched">
                        <span class="error-msg"
                            *ngIf="contactForm.controls['address2'].hasError('required') || contactForm.controls['address2'].hasError('ValidateWhiteSpace')">
                            Address is required.
                        </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['address2'].hasError('maxlength') && !contactForm.controls['address2'].hasError('required')">
                            Address is can be max 100 characters.
                        </span>
                        <span class="error-msg" *ngIf="
                                contactForm.controls['address2'].hasError('pattern') &&
                                !contactForm.controls['address2'].hasError('maxlength') &&
                                !contactForm.controls['address2'].hasError('required')
                            ">
                            Address can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group city">
                    <label>City</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('city') }" formControlName="city"
                        type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="contactForm.controls['city'].errors && contactForm.controls['city'].touched">
                        <span class="error-msg"
                            *ngIf="contactForm.controls['city'].hasError('required') || contactForm.controls['city'].hasError('ValidateWhiteSpace')">
                            City is required. </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['city'].hasError('maxlength') && !contactForm.controls['city'].hasError('required')">
                            City is can be max 100 characters.
                        </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['city'].hasError('pattern') && !contactForm.controls['city'].hasError('maxlength') && !contactForm.controls['city'].hasError('required')">
                            City can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>State</label>
                    <input maxlength="100" [ngClass]="{ error: isFieldValid('state') }" formControlName="state"
                        type="text" class="form-control" />
                    <div class="inp-err"
                        *ngIf="contactForm.controls['state'].errors && contactForm.controls['state'].touched">
                        <span class="error-msg"
                            *ngIf="contactForm.controls['state'].hasError('required') || contactForm.controls['state'].hasError('ValidateWhiteSpace')">
                            State is required. </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['state'].hasError('maxlength') && !contactForm.controls['state'].hasError('required')">
                            State is can be max 100 characters.
                        </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['state'].hasError('pattern') && !contactForm.controls['state'].hasError('maxlength') && !contactForm.controls['state'].hasError('required')">
                            State can only contain letters.
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>ZIP Code</label>
                    <input maxlength="10" [ngClass]="{ error: isFieldValid('zip') }" formControlName="zip" type="text"
                        class="form-control" />
                    <div class="inp-err"
                        *ngIf="contactForm.controls['zip'].errors && contactForm.controls['zip'].touched">
                        <span class="error-msg"
                            *ngIf="contactForm.controls['zip'].hasError('required') || contactForm.controls['zip'].hasError('ValidateWhiteSpace')">
                            ZIP Code is required. </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['zip'].hasError('maxlength') && !contactForm.controls['zip'].hasError('required')">
                            ZIP Code is can be max 10 characters.
                        </span>
                        <span class="error-msg"
                            *ngIf="contactForm.controls['zip'].hasError('pattern') && !contactForm.controls['zip'].hasError('maxlength') && !contactForm.controls['zip'].hasError('required')">
                            ZIP Code can only contain letters.
                        </span>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="main-row">
        <div class="main-left">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
        </div>
        <div class="main-right">
            <button type="button" class="btn btn-primary" (click)="onSave()">Done</button>
        </div>
    </div>
</div>