import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../../services/patients.service";
import { UntypedFormBuilder } from "@angular/forms";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../../warning-modal/warning-modal.component";

@Component({
    selector: "symptom-confirm-modal",
    templateUrl: "./symptom-confirm-modal.component.html",
    styleUrls: ["./symptom-confirm-modal.component.scss"],
})
export class SymptomConfirmModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private fb: UntypedFormBuilder, private patientService: PatientService, private snackbar: SnackbarService, private modalService: NgbModal) {}

    @Input() SymptomsData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    checklist: Array<any> = [];
    public others: string = "";
    public keys: Array<any> = [];
    public error = "";
    public symptoms: any = {};
    public disabled = false;
    public disableAddtoTriage = false;

    ngOnInit() {
        this.symptoms = this.SymptomsData.symptoms;
        if (this.symptoms) {
            let k = Object.keys(this.symptoms);
            let c = k.indexOf("other");

            if (c >= 0) {
                k.splice(c, 1);
            }
            this.keys = k;
        }
        if (this.SymptomsData.action === "new" || (this.symptoms.requesttype === "symptoms" && !this.symptoms.requestcreated)) {
            this.disableAddtoTriage = false;
        } else {
            this.disableAddtoTriage = true;
        }
    }
    onMakeChanges() {
        this.Response.emit({ success: true, message: "", action: this.SymptomsData.action, wruid: this.SymptomsData.wruid, symptoms: this.symptoms });
        this.activeModal.close();
    }

    onSubmit() {
        this.disabled = true;
        if (this.SymptomsData.action === "new") {
            let model = {
                userid: this.SymptomsData.patientId,
                careteamid: this.SymptomsData.careteamId,
                requestdata: this.symptoms,
                requestcreated: false,
            };
            return this.patientService.submitSymptoms(model, "put").subscribe(
                (data) => {
                    this.Response.emit({
                        success: true,
                        message: "Symptom added successfully",
                        action: this.SymptomsData.action,
                        wruid: this.SymptomsData.wruid,
                        symptoms: this.symptoms,
                        isSaved: true,
                    });
                    this.activeModal.close();
                },
                (err) => {
                    this.disabled = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            let model = {
                userid: this.SymptomsData.patientId,
                careteamid: this.SymptomsData.careteamId,
                wruid: this.SymptomsData.wruid,
                requestdata: this.symptoms,
                requestcreated: false,
            };
            return this.patientService.submitSymptoms(model, "put").subscribe(
                (data) => {
                    this.Response.emit({
                        success: true,
                        message: "Symptom updated successfully",
                        action: this.SymptomsData.action,
                        wruid: this.SymptomsData.wruid,
                        symptoms: this.symptoms,
                        isSaved: true,
                    });
                    this.activeModal.close();
                },
                (err) => {
                    this.disabled = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        }
    }
    onAddToTriage() {
        this.disableAddtoTriage = true;
        if (this.SymptomsData.action === "new") {
            let model = {
                userid: this.SymptomsData.patientId,
                careteamid: this.SymptomsData.careteamId,
                requestdata: this.symptoms,
                requesttype: "appointment",
            };
            return this.patientService.submitSymptoms(model, "post").subscribe(
                (data) => {
                    this.Response.emit({
                        success: true,
                        message: "Symptom added successfully",
                        action: this.SymptomsData.action,
                        wruid: this.SymptomsData.wruid,
                        symptoms: this.symptoms,
                        isSaved: true,
                    });
                    this.activeModal.close();
                },
                (err) => {
                    this.disabled = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        } else {
            let model = {
                userid: this.SymptomsData.patientId,
                careteamid: this.SymptomsData.careteamId,
                requestdata: this.symptoms,
                requesttype: "appointment",
            };
            return this.patientService.submitSymptoms(model, "post").subscribe(
                (data) => {
                    let modelPut = {
                        userid: this.SymptomsData.patientId,
                        careteamid: this.SymptomsData.careteamId,
                        wruid: this.SymptomsData.wruid,
                        requestdata: this.symptoms,
                        requestcreated: true,
                    };
                    return this.patientService.submitSymptoms(modelPut, "put").subscribe(
                        (data) => {
                            this.Response.emit({
                                success: true,
                                message: "Symptom updated successfully",
                                action: this.SymptomsData.action,
                                wruid: this.SymptomsData.wruid,
                                symptoms: this.symptoms,
                                isSaved: true,
                            });
                            this.activeModal.close();
                        },
                        (err) => {
                            this.disabled = false;
                            this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                        }
                    );
                },
                (err) => {
                    this.disabled = false;
                    this.snackbar.show(err[0] !== undefined ? err[0].detail : err.detail, "danger");
                }
            );
        }
    }

    public onCancel() {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to cancel?";
        modalRef.componentInstance.OKButton = "Yes";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                this.activeModal.close();
            }
        });
    }
}
