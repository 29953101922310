import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { TeleRXService } from "../../services/teleRX.service";
import { TwoFactorAutoLoginAuthSetupComponent } from "../../modals/two-factor-autologin-auth-setup/two-factor-autologin-auth-setup.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "app-auto-login",
    templateUrl: "./auto-login.component.html",
    styleUrls: ["./auto-login.component.scss"],
})
export class AutoLoginComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private router: Router,
        private authService: AuthService,
        private teleRXService: TeleRXService,
        private modalService: NgbModal
    ) {}
    public tokenId = "";
    public teleRemedie = [];
    ngOnInit() {
        this.sessionService.setCurrentUserRole(["provider"]);
        this.route.paramMap.subscribe((params: any) => {
            this.tokenId = params.params.tokenid;
        });
        if (this.route.snapshot.queryParams["twofactorenabled"] !== undefined && this.route.snapshot.queryParams["twofactorenabled"] === "true") {
            this.onEnableTwoFactorAuth();
            return false;
        } else {
            this.login();
        }
    }
    public login(pin = "") {
        this.authService.auto_login(this.tokenId, pin).subscribe(
            (data) => {
                localStorage.setItem("token", this.tokenId);
                this.router.navigate(["home"]);
                this.teleRemedies();
            },
            (error) => {
                if (error[0] && error[0].detail === "Two Factor Authentication failed for user.") {
                    this.onEnableTwoFactorAuth();
                    return false;
                } else {
                    this.sessionService.clearLocalStorageAndSession();
                    this.router.navigate(["login"]);
                }
            }
        );
    }
    public teleRemedies() {
        this.teleRXService.list().subscribe(
            (data) => {
                this.teleRemedie = data[0].attributes.filter(function (teleremedy) {
                    return teleremedy.membershipid !== this.sessionService.getCurrentUserMembershipId();
                });

                this.teleRemedie.forEach((t) => {
                    if (t.issubscribed && t.isenabled && t.providerdashboardurl !== null && t.providerdashboardurl !== "") {
                        if (t.name.toLowerCase() === "clio go pass") {
                            const url = "/session/" + encodeURIComponent(t.providerdashboardurl);
                            window.location.href = url;
                            // this.router.navigate([url]);
                        }
                    }
                });
                //if clio go pass not found
                //  this.router.navigate(["home"]);
            },
            (err) => {
                this.router.navigate(["home"]);
            }
        );
    }

    public onEnableTwoFactorAuth() {
        const modalRef = this.modalService.open(TwoFactorAutoLoginAuthSetupComponent, { backdrop: "static" });
        modalRef.componentInstance.CodeData = this.tokenId;
        modalRef.componentInstance.Response.subscribe((res) => {
            this.modalResponse(res);
        });
    }
    public modalResponse(event) {
        if (event.success) {
            this.router.navigate(["home"]);
        } else {
            this.router.navigate(["login"]);
        }
    }
}
