import { Component, Output, EventEmitter, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientService } from "../../services/patients.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { WarningModalComponent } from "../warning-modal/warning-modal.component";
import { SessionService } from "../../services/session.service";

@Component({
    selector: "notes-modal",
    templateUrl: "./notes-modal.component.html",
    styleUrls: ["./notes-modal.component.scss"],
})
export class NotesModalComponent {
    constructor(
        public activeModal: NgbActiveModal,
        private snackbar: SnackbarService,
        private modalService: NgbModal,
        private sessionService: SessionService,
        private patientService: PatientService
    ) { }

    @Input() notes: any = {};
    @Input() _patientid = "";
    @Input() patient: any = {};
    @Input() shownote = true;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    //  public _noteFromDB: any;
    public note = "";
    public onCancel() {
        // if (this.content !== "" && this._noteFromDB !== this.content) {
        // this.Response.emit({ success: true, note: this.content });
        // }
        this.activeModal.close();
    }
    public delete(note) {
        const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
        modalRef.componentInstance.Message = "Are you sure you want to delete this note?";
        modalRef.componentInstance.OKButton = "Delete";
        modalRef.componentInstance.Response.subscribe((receivedEntry) => {
            if (receivedEntry.proceed) {
                return this.patientService.deletePatientNotes(note.noteid, note.userid).subscribe(
                    (data) => {
                        this.snackbar.show(data.attributes.message, "success");
                        this.getPatientNotes();
                    },
                    (err) => {
                        this.snackbar.show(err[0].detail, "danger");
                    }
                );
            }
        });
    }

    public getPatientNotes() {
        let activeCareTeam = this.sessionService.activeCareTeam;
        return this.patientService.getPatientNotes(this._patientid, this.patient.careteamid).subscribe(
            (data) => {
                if (data.attributes.notes.length > 0) {
                    this.shownote = true;
                    this.notes = data.attributes.notes.filter(function (e) {
                        return e.status === "active";
                    });
                } else {
                    this.notes = { notetext: "" };
                }
                this.Response.emit({ success: true, note: "" });
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }

    public savePatientNote() {
        let activeCareTeam = this.sessionService.activeCareTeam;
        this.patientService.savePatientNotes(this._patientid, this.patient.careteamid, this.note).subscribe(
            (data) => {
                this.note = "";
                this.snackbar.show("Note saved!");
                this.getPatientNotes();
            },
            (err) => {
                this.snackbar.show(err[0].detail, "danger");
            }
        );
    }
}
