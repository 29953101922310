import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
import { CommunicationService } from "../../services/communications.service";

@Component({
  selector: "app-search-chat-modal",
  templateUrl: "./search-chat-modal.component.html",
  styleUrls: ["./search-chat-modal.component.scss"],
})
export class SearchChatModalComponent {
  constructor(private activeModal: NgbActiveModal, public communication: CommunicationService) { }

  @Output() readonly Response: EventEmitter<any> = new EventEmitter();
  @Input() patientid: string = "";
  public searchText = "";
  public onOK() {
    this.Response.emit({ proceed: true, searchtext: this.searchText });
    this.activeModal.close();
  }

  public onCancel() {
    this.Response.emit({ proceed: false, searchtext: this.searchText });
    this.activeModal.close();
  }

  public searchChat() {
    this.communication
      .getMessages(this.patientid, "", "", "", this.searchText)
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (data[0].attributes.length > 0) {
            // All message history loaded

          } else {

          }
        },
        (error) => {
          console.error(error);
          //  this.snackbar.show(error[0].detail, "danger");
        }
      );
  }
}


