import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";

@Component({
    selector: "view-notes-modal",
    templateUrl: "./view-notes-modal.component.html",
    styleUrls: ["./view-notes-modal.component.scss"],
})
export class ViewNotesModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private snackbar: SnackbarService) {}

    @Input() NotesData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();

    error = "";
    ngOnInit() {
        this.sortData();
    }

    sortData() {
        if (this.NotesData.patient.notes) {
            this.NotesData.patient.notes = this.NotesData.patient.notes.sort((a, b) => {
                return <any>new Date(b.datetimeutc) - <any>new Date(a.datetimeutc);
            });
        }
    }
    public onCancel() {
        this.activeModal.close();
    }
}
