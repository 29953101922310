import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "details-modal",
    templateUrl: "./details-modal.component.html",
    styleUrls: ["./details-modal.component.scss"],
})
export class phq9DetailsModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}
    @Input() SummaryData: any;
    @Input() AssessmentType = "";
    public assessment: any;
    public others: string;
    public score = 0;
    public date = "";
    error = "";
    ngOnInit() {
        this.setupForm();
    }
    setupForm() {
        this.assessment = this.SummaryData.details;
        this.date = this.SummaryData.date;
        this.SummaryData.details.forEach((element) => {
            this.score += element.score;
        });
    }
    onSave() {}

    public onCancel() {
        this.activeModal.close();
    }
}
