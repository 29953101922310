import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "pastmedical-information-modal",
    templateUrl: "./pastmedical-information-modal.component.html",
    styleUrls: ["./pastmedical-information-modal.component.scss"],
})
export class PastMedicalInformationModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    error = "";
    public checklist: Array<any> = [];
    public others: string = "";

    ngOnInit() {
        this.checklist = [
            {
                name: "Anemia",
                value: false,
            },
            {
                name: "Anxiety",
                value: false,
            },
            {
                name: "Arthritis",
                value: false,
            },
            {
                name: "Asthma",
                value: false,
            },
            {
                name: "Bipolar",
                value: false,
            },
            {
                name: "Blood Clot",
                value: false,
            },
            {
                name: "Cancer treatment",
                value: false,
            },
            {
                name: "Colon Disease",
                value: false,
            },
            {
                name: "Congestive Heart Failure",
                value: false,
            },
            {
                name: "COPD/Emphysema",
                value: false,
            },
            {
                name: "Dementia",
                value: false,
            },
            {
                name: "Depression",
                value: false,
            },
            {
                name: "Diabetes",
                value: false,
                subset: [
                    {
                        name: "Type 1",
                        value: false,
                    },
                    {
                        name: "Type 2",
                        value: false,
                    },
                    {
                        name: "Insipidus",
                        value: false,
                    },
                ],
            },
            {
                name: "Enlarged Prostate",
                value: false,
            },
            {
                name: "Heart Attack (MI)",
                value: false,
            },
            {
                name: "Hepatitis",
                value: false,
            },
            {
                name: "High cholesterol (Hyperlipidemia)",
                value: false,
            },
            {
                name: "High Blood Pressure (Hypertension)",
                value: false,
            },
            {
                name: "Irritable Bowel Syndrome (IBS)",
                value: false,
            },
            {
                name: "Kidney Disease",
                value: false,
            },
            {
                name: "Kidney Stones",
                value: false,
            },
            {
                name: "Liver Disease",
                value: false,
            },
            {
                name: "Migraine",
                value: false,
            },
            {
                name: "Osteoporosis",
                value: false,
            },
            {
                name: "Parkinson’s Disease",
                value: false,
            },
            {
                name: "Pulmonary Embolism",
                value: false,
            },
            {
                name: "Reflux/GERD",
                value: false,
            },
            {
                name: "Schizophrenia",
                value: false,
            },
            {
                name: "Seizures/Epilepsy",
                value: false,
            },
            {
                name: "Skin Disease",
                value: false,
            },
            {
                name: "Stroke (TIA/CVA)",
                value: false,
            },
            {
                name: "Thyroid Disease",
                value: false,
            },
        ];
        this.setupForm();
    }
    setupForm() {
        let pmh = this.SummaryData.summary.pastmedicalhistory.historyrecords;

        if (pmh && pmh.length > 0) {
            pmh.forEach((item) => {
                this.checklist.forEach((c, i) => {
                    if (item.name === c.name) {
                        c.value = true;

                        if (item.subset && item.subset.length > 0) {
                            if (c.subset && c.subset.length > 0) {
                                item.subset.forEach((isub) => {
                                    c.subset.forEach((csub) => {
                                        if (isub.name === csub.name) {
                                            csub.value = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            });
        }
        if (this.SummaryData.summary.pastmedicalhistory.other && this.SummaryData.summary.pastmedicalhistory.other.trim().length > 0) {
            this.others = this.SummaryData.summary.pastmedicalhistory.other;
        }
    }

    isChecklistValid(): boolean {
        let isValid = true;
        this.checklist.forEach((item) => {
            if (item.value === true) {
                if (item.subset && item.subset.length > 0) {
                    let selectedSub = item.subset.filter((i) => i.value === true);
                    if (selectedSub.length === 0) {
                        isValid = false;
                    }
                }
            }
        });

        if (!isValid) {
            this.error = "Please make sure sub-categories are also selected.";
        }

        return isValid;
    }

    getSelectedData() {
        let selectedData = [];

        this.checklist.forEach((item) => {
            if (item.value === true) {
                let i = item;

                if (item.subset) {
                    let s = [];

                    item.subset.forEach((sub) => {
                        if (sub.value === true) {
                            s.push(sub);
                        }
                    });

                    i.subset = s;
                }

                selectedData.push(i);
            }
        });

        return selectedData;
    }

    public onSave() {
        if (this.isChecklistValid()) {
            this.SummaryData.summary.pastmedicalhistory = { historyrecords: this.getSelectedData() };

            if (this.others.trim().length > 0) {
                this.SummaryData.summary.pastmedicalhistory["other"] = this.others;
            }
            this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
            this.activeModal.close();
        }
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
}
