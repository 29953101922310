import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class ReportService {
    private url = environment.uri;
    careteamId = "";
    constructor(private avcHttp: AvcHttp, private helper: Helper) {}

    public getReportByType(reportData): Observable<any> {
        let fromdate = reportData.startDate.year + "-" + reportData.startDate.month + "-" + reportData.startDate.day;
        let todate = reportData.endDate.year + "-" + reportData.endDate.month + "-" + reportData.endDate.day;
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest(
                    "get",
                    this.url + "/stats/reports/" + reportData.reportType + "?careteamid=" + reportData.careteamid + "&fromdate=" + fromdate + "&todate=" + todate + "&employer=" + reportData.employer
                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public getMembersReport(reportData, telerxid): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp
                .sendRequest(
                    "get",
                    this.url +
                        "/telerx/userlist/" +
                        telerxid +
                        "?limit=100000&pageno=1&careteamid=" +
                        reportData.careteamid +
                        "&employer=" +
                        reportData.employer +
                        "&onboardingstatus=" +
                        reportData.onboarded +
                        "&type=" +
                        reportData.role
                )
                .subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.next(err);
                        observer.complete();
                    }
                );
        });
    }
    public getCareTeamStats(id): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let url = this.url + "/stats/report/" + id;
            this.avcHttp.sendRequest("get", url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined && body.data[0]) {
                        observer.next(body.data[0]);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getReportByProvider(reportData): Observable<any> {
        let fromdate = reportData.startDate.year + "-" + reportData.startDate.month + "-" + reportData.startDate.day;
        let todate = reportData.endDate.year + "-" + reportData.endDate.month + "-" + reportData.endDate.day;
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/stats/reports/provider?providerid=" + reportData.providerid + "&fromdate=" + fromdate + "&todate=" + todate).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            );
        });
    }
}
