<div style="position: relative;padding-left: 10px;" class="filter-section">
    <div class="filter-section-left" style="display: flex;">
        <i (click)="reload()" style="cursor: pointer; font-size: 20px;margin-top: 8px;"
            class="fa fa-redo-alt left-chevron"></i><input style="margin-left: -2px;" [disabled]="showLoader"
            class="filter-input" [(ngModel)]="searchByName" name="searchTerm" [(ngModel)]="tableService.searchTerm"
            placeholder="Search..." />
        <div *ngIf="showLoader" style="margin-top: 8px;"
            class="ml-2 h-100 d-flex flex-column justify-content-center align-items-center">
            <div style="height: 24px;width: 24px;" class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- <div class="checkbox">
            <label><input type="checkbox" value="" />Search from all users</label>
        </div> -->
    </div>
    <div class="filter-section-right">
        <select [(ngModel)]="scheduletype" (change)="filterByScheduletype($event.target.value)"
            class="filter-input float-right mr-2">
            <option value="">--All--</option>
            <option value="Once">Once</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
        </select>
    </div>

</div>
<div class="container-fluid">
    <table *ngIf="patients" class="table table-striped datatable">
        <thead>
            <tr>
                <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                    sortable="{{ c.columnname }}" (sort)="onSort($event)">{{ c.displayname }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of resultList$ | async">
                <td style="max-width: 200px; padding-top: 7px">
                    <span class="goalname">
                        <ngb-highlight [result]="p.username" [term]="tableService.searchTerm"></ngb-highlight>
                    </span>
                </td>

                <td>
                    <table *ngFor="let g of p.goals">
                        <tr>
                            <td>{{ g.goalname }}</td>
                        </tr>
                    </table>
                </td>
                <td style="width: 20%;">
                    <table *ngFor="let g of p.goals">
                        <tr>
                            <td *ngIf="!g.nogoals">
                                <div class="status">
                                    {{ g.adherence }}%
                                </div>
                                <div class="progress">
                                    <div [ngClass]="[g.adherence > 80 ? 'barGreen' : g.adherence <= 80 && g.adherence > 60 ? 'barYellow' : 'barRed']"
                                        class="progress-bar bar" role="progressbar" aria-valuemin="0"
                                        aria-valuemax="100" style="padding: 0px 1px"
                                        [ngStyle]="{'width':g.adherence+'%'}">

                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
                <td>
                    <table *ngFor="let g of p.goals">
                        <tr>
                            <td>{{ g.lastentrytime === "" || g.lastentrytime === null ? "--" : (g.lastentrytime | date:
                                "MM/dd/yyyy, hh:mm a") }}</td>
                        </tr>
                    </table>
                </td>
                <!-- <td>
                    <table *ngFor="let g of p.goals">
                        <tr>
                            <td>{{ g.nextentrydate === "" || g.nextentrydate === null ? "--" : (g.nextentrydate | date: "MM/dd/yyyy") }}</td>
                        </tr>
                    </table>
                </td> -->
                <td>
                    <table *ngFor="let g of p.goals; let i = index">
                        <tr>
                            <td style="display: inline-flex" *ngIf="!g.nogoals">
                                <i title="Show Graph" (click)="showGoals(g)"
                                    class="mr-3 fas fa-chart-bar cursor-pointer" aria-hidden="true"></i>
                                <i title="Edit Goal" (click)="onEditGoal(g)"
                                    class="fa fa-pen graycolor mr-3 cursor-pointer" aria-hidden="true"> </i>
                                <i title="Delete Goal" (click)="onDeleteGoal(g)" class="fa fa-trash graycolor"
                                    aria-hidden="true"></i>
                                <i *ngIf="i === 0" title="Add New Goal" (click)="$event.stopPropagation(); onAddGoal(g)"
                                    class="fas fa-plus-circle graycolor mx-3" aria-hidden="true"></i>
                            </td>
                            <td style="display: inline-flex" *ngIf="g.nogoals">
                                <i title="Add New Goal" (click)="$event.stopPropagation(); onAddGoal(g)"
                                    class="fas fa-plus-circle graycolor mr-3" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="patients" class="d-flex justify-content-between p-2">
        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
            [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

        <select class="custom-select pagination"
            style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
            [(ngModel)]="tableService.pageSize">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="50">50 items per page</option>
            <option [ngValue]="100">100 items per page</option>
        </select>
    </div>
</div>