import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "allergies-modal",
    templateUrl: "./allergies-modal.component.html",
    styleUrls: ["./allergies-modal.component.scss"],
})
export class AllergiesModalComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}

    @Input() SummaryData: any;
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    error = "";
    public checklist: Array<any> = [];
    public others: string = "";
    public medication: boolean = false;
    public food: boolean = false;
    public environmental: boolean = false;
    ngOnInit() {
        this.medication = this.SummaryData.summary.allergies.medication;
        this.food = this.SummaryData.summary.allergies.food;
        this.environmental = this.SummaryData.summary.allergies.environmental;
    }
    toggleRadio(action, type) {
        this[type] = action;
    }
    public onSave() {
        this.SummaryData.summary.allergies.food = this.food;
        this.SummaryData.summary.allergies.medication = this.medication;
        this.SummaryData.summary.allergies.environmental = this.environmental;
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary });
        this.activeModal.close();
    }
    public onCancel() {
        this.Response.emit({ success: true, message: "", action: "edit", updatedSummaryData: this.SummaryData.summary, isCancel: true });
        this.activeModal.close();
    }
}
