<div class="modal-body">
    <h5 class="modal-title">Add Member</h5>

    <form [formGroup]="memberForm">
        <div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center align-items-center">
            <app-loader></app-loader>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Email:</label>
            </div>
            <div class="col-9">
                <input formControlName="email" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.email.errors }" placeholder="email@email.com" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>First name:</label>
            </div>
            <div class="col-9">
                <input formControlName="fName" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.fName.errors }" placeholder="first name" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Last name:</label>
            </div>
            <div class="col-9">
                <input formControlName="lName" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.lName.errors }" placeholder="last name" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Phone:</label>
            </div>
            <div class="col-9">
                <input formControlName="phone" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.phone.errors }" placeholder="(123) 456-7890" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Birthdate:</label>
            </div>
            <div class="col-9">
                <input formControlName="birthdate" type="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.birthdate.errors }" placeholder="mm/dd/yyyy" />
            </div>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Gender:</label>
            </div>
            <div class="col-9">
                <select formControlName="gender" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.gender.errors }">
                    <option value="">Select</option>
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                    <option value="O">Other</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="col-3 pr-0">
                <label>Add to group:</label>
            </div>
            <div class="col-9">
                <div *ngFor="let g of groups; let i = index" class="form-group form-check">
                    <input [value]="g.member_group_id" [checked]="g.selected" (change)="onCheckChange($event)" type="checkbox" class="form-check-input" id="{{ g.member_group_id }}" />
                    <label style="color: #2d2d2d !important" class="form-check-label" for="{{ g.member_group_id }}">{{ g.fullname }}</label>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            <button [disabled]="showLoader" type="button" class="btn btn-primary" (click)="onSave()">Send</button>
        </div>
    </form>
</div>
