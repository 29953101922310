import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MSXReportService } from "../../../../services/msx_report.service";
import { SnackbarService } from "../../../../shared/snackbar/snackbar.service";
import * as _ from 'underscore';
import { Router } from "@angular/router";
@Component({
    selector: "entity-modal",
    templateUrl: "./entity-modal.component.html",
    styleUrls: ["./entity-modal.component.scss"],
})
export class EntityModalComponent implements OnInit {
    constructor(private activeModal: NgbActiveModal,
        private msxReportService: MSXReportService,
        private snackbar: SnackbarService,
        private router: Router
    ) { }
    @Input() entities = [];
    @Output() readonly Response: EventEmitter<any> = new EventEmitter();
    selected = "";
    showLoader = true;
    selectedGroup = "";
    ngOnInit() {

    }
    public selectedMetric(m) {
        this.selected = m.metricid;
    }
    public onSubmit() {

        let oldGroup = localStorage.getItem("selectedGroup")

        localStorage.setItem("selectedGroup", this.selectedGroup);
        this.Response.emit({ selectedGroup: this.selectedGroup });
        this.activeModal.close();
        this.msxReportService.changeGroup(this.selectedGroup);
        localStorage.removeItem('sort_options');
        localStorage.removeItem('page_size');
        localStorage.removeItem('page_no');



        this.msxReportService.saveFavoriteList({}, false).subscribe(
            (data) => {
                let _data = data;
                if (this.router.url.includes("media/")) {
                    window.location.href = "/media/activity";
                }
                else {
                    window.location.reload();
                }
            },
            (err) => {
                this.snackbar.show("error occurred please try again later.", "danger");
                window.location.reload();
            }
        );


    }
    public selectedEntity(e) {
        this.selectedGroup = e.target.value;
        let selectElementText = e.target['options']
        [e.target['options'].selectedIndex].text;
        localStorage.setItem("selectedGroupName", selectElementText);

    }

    public onCancel() {
        this.activeModal.close();
    }

}
