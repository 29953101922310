<div class="modal-body">
    <div class="category-header">
        <label>Settings</label>
    </div>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink>Profile</a>
            <ng-template ngbNavContent>
                <form class="modal-body-form" [formGroup]="userSettingsForm">
                    <div class="main-row header-nav">
                        <div class="main-left">
                            <div class="form-group" *ngIf="showTitle">
                                <label>Title</label>
                                <input maxlength="100" formControlName="title" type="text" class="form-control"
                                    required />
                                <div class="inp-err"
                                    *ngIf="userSettingsForm.controls['title'].errors && userSettingsForm.controls['title'].touched">
                                    <span class="error-msg"
                                        *ngIf="userSettingsForm.controls['title'].hasError('required') || userSettingsForm.controls['title'].hasError('ValidateWhiteSpace')">
                                        Title is required.
                                    </span>
                                    <span class="error-msg"
                                        *ngIf="userSettingsForm.controls['title'].hasError('maxlength') && !userSettingsForm.controls['title'].hasError('required')">
                                        First Name is can be max 100 characters.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>First Name</label>
                                <input maxlength="100" formControlName="firstname" required type="text"
                                    class="form-control" />
                                <div class="inp-err"
                                    *ngIf="userSettingsForm.controls['firstname'].errors && userSettingsForm.controls['firstname'].touched">
                                    <span class="error-msg"
                                        *ngIf="userSettingsForm.controls['firstname'].hasError('required') || userSettingsForm.controls['firstname'].hasError('ValidateWhiteSpace')">
                                        First Name is required.
                                    </span>
                                    <span class="error-msg"
                                        *ngIf="userSettingsForm.controls['firstname'].hasError('maxlength') && !userSettingsForm.controls['firstname'].hasError('required')">
                                        First Name is can be max 100 characters.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Last Name</label>
                                <input maxlength="100" formControlName="lastname" required type="text"
                                    class="form-control" />
                                <div class="inp-err"
                                    *ngIf="userSettingsForm.controls['lastname'].errors && userSettingsForm.controls['lastname'].touched">
                                    <span class="error-msg"
                                        *ngIf="userSettingsForm.controls['lastname'].hasError('required') || userSettingsForm.controls['lastname'].hasError('ValidateWhiteSpace')">
                                        Last Name is required.
                                    </span>
                                    <span class="error-msg"
                                        *ngIf="userSettingsForm.controls['lastname'].hasError('maxlength') && !userSettingsForm.controls['lastname'].hasError('required')">
                                        Last Name can be max 100 characters.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <input maxlength="100" formControlName="email" type="text" class="form-control"
                                    readonly="true" />
                            </div>
                        </div>
                        <div class="main-right">
                            <div class="form-group" *ngIf="showTitle">
                                <label>Profile Pic</label>
                                <div class="image-block">
                                    <div class="imagePreview">
                                        <input type="file" accept="image/*" (change)="showPreview($event)" />
                                        <button [ngClass]="isInvalidImage ? 'uploadbtnDirty' : 'uploadbtn'">
                                            <img class="photoid-image"
                                                [src]="url ? url : '../../../assets/images/add-image-health.png'" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="main-row">
                    <div class="main-left">
                        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
                    </div>
                    <div class="main-right">
                        <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
                    </div>
                </div>
            </ng-template>
        </li>
        <!-- <li ngbNavItem>
            <a ngbNavLink>Security</a>
            <ng-template ngbNavContent>
                <div class="main-row header-nav">
                    <div class="main-left" style="margin-top: 10px">
                        <label>Two-Factor Authentication</label>
                    </div>
                    <div class="main-right">
                        <button (click)="onEnableTwoFactorAuth()" *ngIf="!sessionService.currentUser.attributes?.twofactorenabled" class="btn btn-primary">Enable</button>
                        <button (click)="onDisableTwoFactorAuth()" *ngIf="sessionService.currentUser.attributes?.twofactorenabled" class="btn btn-primary">Disable</button>
                    </div>
                </div>
                <div class="main-row">
                    <div class="main-left" style="margin: 0 auto; float: none">
                        <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
                    </div>
                    <div class="main-right"></div>
                </div>
            </ng-template>
        </li> -->
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>

<app-snackbar></app-snackbar>