import { Injectable, PipeTransform } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { DecimalPipe } from "@angular/common";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { SortColumn, SortDirection } from "../directives/sortable.directive";

interface SearchResult {
    resultList: any[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    searchOn: string;
    searchOnColumn: string;
}

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

function sort(resultList: any[], column: SortColumn, direction: string): any[] {
    if (direction === "" || column === "") {
        return resultList;
    } else {
        if (resultList === undefined) {
            resultList = [];
        }
        return [...resultList].sort((a, b) => {
            const res = compare(a[column] != undefined ? String(a[column]).toLowerCase() : a[column], b[column] != undefined ? String(b[column]).toLowerCase() : b[column]);
            return direction === "asc" ? res : -res;
        });
    }
}

function matches(list: any, term: string, pipe: PipeTransform, searchOn, searchOnColumn) {
    if (list["icdcodes"] && list["generalsymptoms"]) {
        return (
            (list["date"] && list["date"].toLowerCase().includes(term.toLowerCase())) ||
            (list["status"] && list["status"]?.toLowerCase().includes(term.toLowerCase())) ||
            (list["icdcodes"] && list["icdcodes"]?.join().toLowerCase().includes(term.toLowerCase())) ||
            (list["generalsymptoms"] && list["generalsymptoms"]?.join().toLowerCase().includes(term.toLowerCase())) ||
            (list["cptcodes"] && list["cptcodes"]?.join().toLowerCase().includes(term.toLowerCase())) ||
            (list["cptexams"] && list["cptexams"]?.join().toLowerCase().includes(term.toLowerCase()))
        );
    } else if (searchOn == "link") {
        return list;
    } else if (searchOn != "" && searchOnColumn != "" && list["status"]) {
        return (
            (list[searchOn].toLowerCase().includes(term.toLowerCase()) || (list["chiefcomplaint"] && list["chiefcomplaint"].toLowerCase().includes(term.toLowerCase()))) &&
            list["status"].toLowerCase().includes(searchOnColumn)
        );
    } else if (searchOn === "severity") {
        return list[searchOn].toLowerCase().includes(term.toLowerCase()) || (list["keyword"].toLowerCase().includes(term.toLowerCase()));
    }
    else if (searchOn === "socialmedia" && term !== "") {
        return (
            (list["provider_name"].toLowerCase().includes(term.toLowerCase())) ||
            (list["review"].toLowerCase().includes(term.toLowerCase())) ||
            (list["socialmediatype"].toLowerCase().includes(term.toLowerCase())) ||
            (list["createdat"].toLowerCase().includes(term.toLowerCase()))
        );
    }
    else if (searchOn != "") {
        if (list[searchOn] === undefined) {
            return list;
        }
        else {
            return list[searchOn].toLowerCase().includes(term.toLowerCase()) || (list["chiefcomplaint"] && list["chiefcomplaint"].toLowerCase().includes(term.toLowerCase()));
        }
    }
    else {
        return list;
    }
}

@Injectable({ providedIn: "root" })
export class TableService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _resultList$ = new BehaviorSubject<any[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    DATA = [];

    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: "",
        sortColumn: "",
        sortDirection: "",
        searchOn: "",
        searchOnColumn: "",
    };

    constructor(private pipe: DecimalPipe) {
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            )
            .subscribe((result) => {
                this._resultList$.next(result.resultList);
                this._total$.next(result.total);
            });

        this._search$.next();
    }

    get resultList$() {
        return this._resultList$.asObservable();
    }
    get total$() {
        return this._total$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set searchOn(searchOn: string) {
        this._set({ searchOn });
    }
    set searchOnColumn(searchOnColumn: string) {
        this._set({ searchOnColumn });
    }

    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }
    set sortColumn(sortColumn: SortColumn) {
        this._set({ sortColumn });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm, searchOn, searchOnColumn } = this._state;
        // 1. sort
        let resultList = sort(this.DATA, sortColumn, sortDirection);

        // 2. filter
        if (resultList === undefined) {
            resultList = [];
        }
        resultList = resultList.filter((list) => matches(list, searchTerm, this.pipe, searchOn, searchOnColumn));
        const total = resultList.length;

        // 4. paginate
        resultList = resultList.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ resultList, total });
    }
}
