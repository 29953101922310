<div class="dropdown contextmenu" (keydown)="keyDown($event)" tabindex="0" [@fadeInOut] #contextmenu
    [ngStyle]="{ 'left.px': x * 2 }" style="bottom: 50px; left: 20px">
    <a *ngFor="let t of data | filter: searchKey; let i = index" [class.active]="i === arrowkeyLocation"
        class="dropdown-item" (click)="addTag(t.tag)">
        {{ t.tag }}
    </a>
</div>
<div *ngIf="showDelete" class="dropdown contextmenu delete-menu relative-patent delete_menu" tabindex="0" [@fadeInOut]>
    <a *ngIf="message.status!==-1" (click)="deleteMessage()" style="cursor:pointer" class="dropdown-item">
        <i style="color:gray" class="fa fa-trash" aria-hidden="true"></i>
        Delete
    </a>
    <a *ngIf="message.status==-1" (click)="resendMessage()" style="cursor:pointer" class="dropdown-item">
        <i style="color:gray" class="fa fa-redo-alt" aria-hidden="true"></i>
        Resend
    </a>
</div>