import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../../environments/environment";
import { SessionService } from "../../services/session.service";
import { UsersService } from "../../services/users.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { Helper } from "../../providers/helper";
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    moduleId: module.id,
    templateUrl: "login.component.html",
    styleUrls: ["./login.css"],
})
export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    isValidUser: boolean = false;
    loadingPage: boolean = true;
    twoFactorRequired: boolean = false;
    login_with_MedStatix: boolean = true;
    public environment = environment;

    constructor(
        private router: Router,
        private auth: AuthService,
        private userService: UsersService,
        private snackbar: SnackbarService,
        private helper: Helper,
        private sessionService: SessionService
    ) { }

    ngOnInit() {
        localStorage.clear();
        this.auth.logout();
        this.loadingPage = false;
    }

    login() {
        this.loading = true;
        try {
            if (this.model.email === undefined || this.model.email === "" || this.model.password === undefined || this.model.password === "") {
                this.snackbar.show("Please enter all fields.", "danger");
                this.loading = false;
                return false;
            }
            this.auth.login(this.model.email, this.model.password, this.model.pin, this.login_with_MedStatix).subscribe(
                (data) => {
                    localStorage.setItem("token", data[0].attributes.token);

                    if (data[0].attributes.msxinfo != undefined && data[0].attributes.msxinfo.groupId != undefined) {

                        localStorage.setItem("selectedGroup", data[0].attributes.msxinfo.groupId.toUpperCase());

                    }
                    if (data[0].attributes.msxinfo != undefined && data[0].attributes.msxinfo.dashboardModules != undefined
                        && data[0].attributes.msxinfo.dashboardModules.length == 0) {

                        this.snackbar.show("Your account doesn't have access to this dashboard. Please contact support@medstatix.com for access.", "danger");
                        this.loading = false;
                        return false;
                    }
                    if (data[0].attributes.isExpired) {
                        this.router.navigate(["/account/resetpassword"]);
                        return false;
                    }
                    if (data[0].attributes.twofactorenabled) {
                        this.loading = false;
                        this.twoFactorRequired = true;
                        return false;
                    }
                    if (data[0].attributes.twoFactorRegistered !== undefined && data[0].attributes.twoFactorRegistered) {
                        this.loading = false;
                        this.twoFactorRequired = false;
                        this.sessionService.currentUser.attributes.twofactorenabled = true;
                        data[0].attributes.twofactorenabled = true;
                    }

                    if (data[0].attributes.roles.some((item) => environment.allow_roles.includes(item))) {
                        this.isValidUser = true;
                        this.sessionService.setCurrentUserRole(data[0].attributes.roles);
                    }
                    if (!this.isValidUser) {
                        this.snackbar.show("You are not authorized to perform this action.", "danger");
                        this.loading = false;
                    } else {
                        this.sessionService.currentUser = data[0];

                        return this.userService.getUserSetting(data[0].id).subscribe(
                            (settingsData) => {
                                this.sessionService.currentUserSettings = settingsData[0].attributes;

                                if (!(this.sessionService.isAdmin() || this.sessionService.isSuperAdmin())) {
                                    let _defaultCareTeam;
                                    if (this.sessionService.currentUserSettings.currentcareteamid) {
                                        _defaultCareTeam = data[0].attributes.careteam.careteams.find((e) => e.careteamid === this.sessionService.currentUserSettings.currentcareteamid);
                                    }
                                    if (!_defaultCareTeam) {
                                        _defaultCareTeam = data[0].attributes.careteam.careteams.find((e) => e.defaultcareteam === true);
                                    }
                                    if (!_defaultCareTeam) {
                                        _defaultCareTeam = data[0].attributes.careteam.careteams[0];
                                    }

                                    this.sessionService.activeCareTeam = _defaultCareTeam;
                                }

                                if (!(this.sessionService.isAdmin() || this.sessionService.isSuperAdmin()) && data[0].attributes.careteam.careteams.length === 0) {
                                    this.snackbar.show("No care team assigned. Please contact to administrator.", "danger");
                                } else {
                                    this.helper.updateUserDashboardSetting();
                                    this.router.navigate(["home"]);
                                }
                            },
                            (err) => {
                                this.snackbar.show(err[0].detail, "danger");
                            }
                        );
                    }
                },
                (error) => {
                    if (error[0] && error[0].detail === "Two Factor Authentication failed for user.") {
                        if (this.twoFactorRequired) {
                            this.snackbar.show(typeof error.length !== "undefined" ? error[0].detail : error.detail, "danger");
                        }
                        this.twoFactorRequired = true;
                    } else {
                        this.snackbar.show(typeof error.length !== "undefined" ? error[0].detail : error.detail, "danger");
                    }
                    this.loading = false;
                }
            );
        } catch (e) { }
    }

    resetPassword() {
        if (!this.model.email) {
            this.snackbar.show("Enter your username to receive password reset instructions.", "danger");
        } else {
            let email = this.model.email;
            if (this.login_with_MedStatix === true) {
                email = email;
            }
            this.auth.sendReset(email).subscribe(
                (data) => {
                    this.snackbar.show(`Password reset instructions will be emailed if your submission matches our records.`);
                },
                (error) => {
                    this.snackbar.show(`Password reset instructions will be emailed if your submission matches our records.`);
                }
            );
        }
    }
}
