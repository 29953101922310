import { PipeTransform, Pipe } from "@angular/core";
import { environment } from "../../environments/environment";
import { SessionService } from "../services/session.service";
import * as _ from 'lodash';
@Pipe({ name: "keys" })
export class KeysPipe implements PipeTransform {
    transform(value: any, args: string[]): any {
        let keys = [];
        for (let key in value) {
            keys.push(key);
        }
        return keys;
    }
}

@Pipe({ name: "orderBy", pure: false })
export class OrderBy implements PipeTransform {
    transform(array: any, orderBy: any, asc = true, orderBy2?: any, asc2 = true) {
        if (typeof array !== "undefined" && array.length) {
            if (!orderBy || orderBy.trim() === "") {
                return array;
            }
            //ascending
            if (asc) {
                return Array.from(array).sort((item1: any, item2: any) => {
                    const res = this.orderByComparator(item1[orderBy], item2[orderBy]);
                    if (res === 0 && orderBy2 && orderBy2.trim() !== "") {
                        if (asc2) {
                            return this.orderByComparator(item1[orderBy2], item2[orderBy2]);
                        } else {
                            return this.orderByComparator(item2[orderBy2], item1[orderBy2]);
                        }
                    }
                    return res;
                });
            } else {
                //not asc
                return Array.from(array).sort((item1: any, item2: any) => {
                    const res = this.orderByComparator(item2[orderBy], item1[orderBy]);
                    if (res === 0 && orderBy2 && orderBy2.trim() !== "") {
                        if (asc2) {
                            return this.orderByComparator(item1[orderBy2], item2[orderBy2]);
                        } else {
                            return this.orderByComparator(item2[orderBy2], item1[orderBy2]);
                        }
                    }
                    return res;
                });
            }
        }
    }

    orderByComparator(a: any, b: any): number {
        if (a == null || typeof a === "undefined") {
            a = "";
        }
        if (b == null || typeof b === "undefined") {
            b = "";
        }
        if (a === 0) {
            a = "0";
        }
        if (b === 0) {
            b = "0";
        }
        if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
            //Isn't a number so lowercase the string to properly compare
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
        } else {
            //Parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return -1;
            if (parseFloat(a) > parseFloat(b)) return 1;
        }

        return 0; //equal each other
    }
}

@Pipe({
    name: "filterMessage",
    pure: false,
})
export class FilterMessagePipe implements PipeTransform {
    constructor(private sessionService: SessionService) { }
    transform(message: string, msg: any): any {
        let _message = [];
        if (this.sessionService.currentUser.attributes.membership.telerx.chatbot && message != null) {
            message = message.replace(/<a[^>]*>|<\/a>/g, "");
        }

        message = message !== null ? message : "";
        if (message !== undefined) {
            message = message.split("\n").join("<br />&nbsp;&nbsp;");
        }
        else {
            message = ""
        }

        if (msg !== undefined && typeof msg.tags !== undefined && msg.tags !== undefined && msg.tags !== null && msg.tags.length > 0) {
            return "<span style='font-size:14px' class='badge badge-pill badge-primary'>" + msg.tags.toUpperCase() + "</span>" + "&nbsp;&nbsp;" + message;
        } else {
            return message;
        }
    }
}

@Pipe({
    name: "picture",
    pure: false,
})
export class PicturePipe implements PipeTransform {
    constructor() { }
    transform(message: string, messages: any): any {
        if (messages.attachments !== null) {
            return "<img src=" + environment.uri + "/" + messages.attachments + ">";
        } else {
            return message;
        }
    }
}

@Pipe({
    name: "urlify",
})
export class UrlifyPipe implements PipeTransform {
    transform(text: any): any {
        if (text !== null) {
            var urlRegex = /(\b(https?|ftp|file|form-pdf):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
            text = text.replace("ihttp://", "http://");
            text = text.replace("ihttps://", "https://");
            return text.replace(urlRegex, function (url: string) {
                let anchored = '<a title="link" target="_blank" href="' + url + '">' + url + "</a>";
                if (url.startsWith("form-pdf://")) {
                    anchored = '<a  class="form-pdf" href="' + url + '">' + url + "</a>";
                }
                return anchored;
            });
        }
    }
}

@Pipe({
    name: "chanelType",
    pure: false,
})
export class ChanelTypePipe implements PipeTransform {
    transform(messages = [], chanelType?: any): any {
        let filterMessage = [];
        if (chanelType !== undefined && chanelType !== "") {
            for (let message of messages) {
                if (message["tags"] !== null && message["tags"] !== undefined && message["tags"].toLowerCase().includes(chanelType["tag"].toLowerCase())) {
                    filterMessage.push(message);
                }
            }
            return filterMessage;
        } else {
            return messages;
        }
    }
}

@Pipe({
    name: "exensionType",
    pure: false,
})
export class ExensionTypePipe implements PipeTransform {
    constructor(private sessionService: SessionService) { }
    transform(messages = [], exensionType?: any): any {
        let filterMessage = [];
        if (exensionType !== undefined && exensionType !== "") {
            for (let message of messages) {
                if (exensionType.type === "Team Uploaded") {
                    if (message["authorid"] !== message["userid"]) {
                        filterMessage.push(message);
                    }
                } else if (exensionType.type === "Member Uploaded") {
                    if (message["authorid"] === message["userid"]) {
                        filterMessage.push(message);
                    }
                } else {
                    filterMessage.push(message);
                }
            }
            return filterMessage;
        } else {
            return messages;
        }
    }
}
@Pipe({
    name: "menuItemsPipe",
})
export class MenuItemsPipe implements PipeTransform {
    constructor(private sessionService: SessionService) { }
    transform(menus = []): any {
        let menuItem = [];
        if (menus !== undefined && menus.length > 0) {
            for (let menu of menus) {
                if (menu["availableFor"] !== undefined && menu["availableFor"].includes(this.sessionService.currentUserRole)) {
                    menuItem.push(menu);
                }
            }
            return menuItem;
        } else {
            return menuItem;
        }
    }
}

@Pipe({ name: "round" })
export class RoundPipe implements PipeTransform {
    transform(input: number) {
        return Math.floor(input);
    }
}

@Pipe({ name: "orderByReadMessages", pure: false })
export class OrderByReadMessages implements PipeTransform {
    transform(array: any) {
        if (typeof array !== "undefined" && array.length) {
            let unReadMessages = [];
            let readMessages = [];
            array.forEach((element) => {
                if (element.provider_unread_messages_count > 0) {
                    unReadMessages.push(element);
                } else {
                    readMessages.push(element);
                }
            });
            const orderByPipe = new OrderBy();
            unReadMessages = orderByPipe.transform(unReadMessages, "datetimeutc", false);
            readMessages = orderByPipe.transform(readMessages, "datetimeutc", false);
            if (unReadMessages && unReadMessages != undefined) {
                return unReadMessages.concat(readMessages);
            }
            return readMessages;
        }
    }
}

@Pipe({
    name: 'banchMarkfilter'
})
export class BanchMarkFilterPipe implements PipeTransform {
    transform(items: any[], type = ''): any {
        if (!items || !type) {
            return items;
        }
        if (type === "line") {
            items.push({ f: '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8', b: 'Group Average', m: 0, n: 0 })
            return _.sortBy(_.uniqBy(_.filter(items, {
                f
                    : '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8',
            }), 'b'), ['b'])
        }
        if (type === "column") {
            items.push({ f: '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8', b: 'Group Average', m: 0, n: 0 })
            return _.sortBy(_.uniqBy(_.filter(items, {
                f
                    : '2EA635AB-A9FD-4A6A-B96A-F557DB026FD8',
            }), 'b'), ['b'])
        }
        if (type === "location") {
            return _.sortBy(_.uniqBy(_.filter(items, {
                f
                    : '3E2D7B7A-2FC9-4B3F-A112-902C3FB0925C',
            }), 'b'), ['b'])
        }
        if (type === "role") {
            return _.sortBy(_.uniqBy(_.filter(items, {
                f
                    : '0E5E868A-D26F-4593-A385-A8F935972E10'
            }), 'b'), ['b'])
        }
        if (type === "state") {
            return _.sortBy(_.uniqBy(_.filter(items, {
                f
                    : 'CA5DBC0C-339B-4461-BDD5-BDD86CDDD4EB',
            }), 'b'), ['b'])
        }

    }
}


